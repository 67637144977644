import React, {Component} from 'react';
import axios from 'axios';
// import jwtDecode from 'jwt-decode';
// import { Redirect } from 'react-router';
var googleAnalytics = require("../googleanalytics");
var redirectURL = require('../../redirectURL');

// Import authService

class UserMenu extends Component {
    constructor(props) {
		super(props);
		this.state = {
            welcomeMessage: ""
		}
	}
	logOut = async(e) => {
        e.preventDefault();
        var userloggparams = {
            token: localStorage.getItem('token'),
            userId:localStorage.getItem('userid'),
            logoutype:"manual"
        }
        await redirectURL.post("/manage/removeloggeduser",userloggparams)
        .then(async (resp) => {
            console.log("resp ", resp)
            var loginType = 0;
            if(localStorage.getItem("is_enmovil_transporter") == 1)
            {
                loginType = 1;
            }
            else if(localStorage.getItem("is_load_management") == 1){
                loginType = 2;
            }
            await localStorage.removeItem('token');
            await localStorage.removeItem('userid');
            await localStorage.removeItem('dept_code');
            await localStorage.removeItem('firstname');
            await localStorage.removeItem('lastname');
            await localStorage.removeItem('email');
            await localStorage.removeItem('is_admin');
            await localStorage.removeItem('is_global');
            await localStorage.removeItem('is_dept_admin');
            await localStorage.removeItem('usermenucodes');
            await localStorage.removeItem('loading_port_code');
            await localStorage.removeItem('loadingPointName');
            await localStorage.clear();
            sessionStorage.setItem('ref',null);
            //localStorage.setItem('menuText',[]);
            await delete axios.defaults.headers.common['Authorization'];
            this.setState({
                redirectToReferrer:''
            });
            console.log("logout successfully",localStorage.getItem('token'));
            
            window.location.reload();
            // await relaodpage(loginType);
        })

        if (googleAnalytics.page.enableGA) {
            let pageTitle = "Logout";
            let formAction = googleAnalytics.page.actionType.miscAction;
            let eventOptions = {
                "category": pageTitle,
                "action": formAction,
                "label": googleAnalytics.page.action.userLogout,
            }
            googleAnalytics.logEvent(eventOptions);
        }
       
    }
    setWelcomeMessage() {
        let firstName = localStorage.getItem("firstname");
        let lastName = localStorage.getItem("lastname");
        let fullName = localStorage.getItem("email");
        if (firstName !== "undefined" || lastName !== "undefined") {
            fullName = firstName + " " + lastName;
        }
        //this.setState({welcomeMessage: "Welcome " + fullName })
        this.setState({welcomeMessage: fullName })
    }
    componentDidMount() {
        this.setWelcomeMessage();
    }
    render() {
    	/*if(localStorage.getItem('token') == null || localStorage.getItem('token') == '')
		{
    		return <Redirect to="/" push={true} />
		}*/
        return (
            <li className="onhover-dropdown">
                <div className="media  align-items-center">
                    <img className="align-self-center pull-right mr-2" src={require("../../../assets/images/dashboard/user.png")} alt="header-user" />
                    <div className="media-body">
                        <h6 className="m-0 txt-dark f-16">
                            <i className="fa fa-angle-down pull-right ml-2"></i>
                        </h6>
                    </div>
                </div>
                <ul className="profile-dropdown onhover-show-div p-20" id="profileDropdown">
                    <li className="ellipsis">
                        <a href="#" title={this.state.welcomeMessage} id="hero-motors">
                            <i className="icon-user" id="hero-motors"></i>
                            {this.state.welcomeMessage}
                        </a>
                    </li>
                    {/* {(localStorage.getItem("email") != "airimports@maruti.co.in" && localStorage.getItem("is_load_management") != 1)? */}
                    {(localStorage.getItem("email") != "airimports@maruti.co.in")?
                     <li>
                        <a href="/profile/changepassword" title="Change Password" id="change-password">
                            <i className="icofont icofont-ui-password" id="change-password"></i>
                            Change Password
                        </a>
                    </li>
                    : ""}
                    <li>
                        <a href="#" onClick={this.logOut.bind(this)} title="Logout" id="logout">
                            <i className="icon-power-off" id="logout"></i>
                            Logout
                        </a>
                    </li>
                </ul>
            </li>
        );
    }
}

function relaodpage(loginType){
    if(loginType == 1)
    {
        window.location.href="/tptlogin";
    }
    else if(loginType == 2)
    {
        window.location.href="/optirun";
    }
    else
    {
        window.location.reload();
    }
}

export default UserMenu;




