/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _, { update } from "lodash";


import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import CSVFileValidator from 'csv-file-validator';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
//import Grid from '../layouts/gridnosidebarComponent';
import Modal from 'react-responsive-modal';
import Select from 'react-select';
import { getDDMMYYYY, 
	getDDMMYYYYHHMMSS, 
	getYYYYMMDD, 
	getYYYYMMDDHHMMSS, 
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD, 
	getHyphenDDMMYYYYHHMMSS, 
	getHyphenDDMMYYYY,
	getDDMMMYYYYHHMMDefault } from '../common/utils'; 
import 'react-vertical-timeline-component/style.min.css';
import DrawMap from '../common/drawmap';
import ConsignmentDrawMap from '../common/consignmentdrawmap';
import ComponentIndex from '../common/appendComponents';
import CustomDateComponent from '../common/dateFilterComponent';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
// import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import ProbableAction from "./probableDataComponent";

$.datetimepicker.setLocale('en');
var infoBox = require('../common/google-infowindow');
 
var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");
 
var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749','#F73E3E', '#B7B3B3' ];
var selectedColor;
var colorButtons = {};
var fencingLength=0;
var mapZoom=12;
var coordinates=[];
var geoFencingArea;
var containerslist=[];
export default class PRTConsignees extends Component {

	constructor(props){
		super(props);
		this.displayData=[];
		this.state={
			pageTitle: "",
			deptcode:"",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
			eventAction: null,
			forceCloseRowNode : "",
			allRowData: [],
			activeFilter: null,
			uploadDivWidth:'0%',
			sliderTranslate:'',
			sliderRouteTranslate:'',
			sliderCommentTranslate : "",
			tabsliderTranslate:'',
			timelineinfo:'',
			consignment_code:'',
			sliderSegmentTranslate : "",
			mapinfo:'',
			commentsRowData:[],
			dealer:'',
			maptruckno:'',
			loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			overly:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
			basicTitle:'',
			rownode:'',
			leg_no:'',
			basicType:"default",
			modules: AllModules,
			filterstatess:{},
      	    columnsdatas:{},
      	    rowgroupdcols:[],
			valuecolumns:[],
			activeconsignemnt:[],
			activetrucks:[],
			transitdelays:[],
			gpsdatana:[],
			overspeedtrucks:[],
			nightdrive:[],
			open: false,
			openforceclose: false,
      	    pivotmodeis:false,
			pivotcols:[],
			defTransitCoords:'',
			routeTruck:{}, 
			sidebarSubHeader:'',
			googelRoutes:'',
			plants:[],
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true,
				// pivot : true,
				enableValue: true,
    			// enableRowGroup: true,
      	    },
      	    rowData: null,
			  maprowData:null,
			rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
			  overlayNoRowsTemplate: "",
			  frameworkComponents: {
			},

      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,
				
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
			rowClassRules: {
				//"highlitRow": "data.transit_delay == 1",
				//"green":"data.status >= 3"

			},
			triptype:{"value":'all', "label":'All'},
			tripvalue:'',	
			consigner:[{"value":'all', "label":'All'}],
			originalData:[],
			isCounterDisplay:1,
			screenpage:'',
			pagetitle:'PD Information',
			countersjson:'',
			originalcountersjson:'',
			consigneecoords:'',
			consigner_coordinates:'',
			containerslist:[],
			forceclosedata:'',
			reached_dealer_time:moment.parseZone().format('YYYY-MM-DD'),
			reachedhh:'',
			reachedmm:'',
			reachedss:'00',
			left_dealer_time:moment.parseZone().format('YYYY-MM-DD'),
			lefthh:'',
			leftmm:'',
			leftss:'00',
			invoice_time:'',
			invoicehh:'',
			invoicemm:'',
			segementConsignmentCode : "",
			counter_screen : "",
			invoicess:'00',
			reasonforceclose:'',
			sliderTranslatesidebar:'',
			consignment_details:'',
			startDatetime:'',
			endDatetime:'',
			setProbableAccidentConfirm : "",
			showReasonModal : false,
			selectedRow : "",
			tolls : [],
			excelStyles : [
				{
					id : "dateFormat",
					dataType: 'dateTime',
					numberFormat: { format: "yyyy-mm-dd" }
				}
			],
			getRowHeight: function(params) {
				if(typeof params.data.dct_comment != 'undefined'){
					//console.log("pars ", params.data.dct_comment.length)
					return (
					//params.api.getSizesForCurrentTheme().rowHeight *
					(100 * Math.floor(params.data.dct_comment.length / 50))
					);
				}
				if(typeof params.data.vin_list != 'undefined'){
					//console.log("pars ", params.data.vin_list.toString().length)
					return (
					//params.api.getSizesForCurrentTheme().rowHeight *
					(100 * Math.floor(params.data.vin_list.toString().length/ 50))
					);
				}
				else if(typeof params.data.force_closure_reason != 'undefined'){
					//console.log("pars ", params.data.dct_comment.length)
					return (
					//params.api.getSizesForCurrentTheme().rowHeight *
					(100 * Math.floor(params.data.force_closure_reason.length / 50))
					);
				}
				else{
					return 30;
				}
			  },
			  defaultsdate:'',
			  defaultedate:'',
			  update_consignee_code:'',
			  update_latitude:'',
			  update_longitude:'',
			  usergridstate:[],
			  screenurl:"",
			  screentitle:"",
			  areaList : [],
			  cityList: [],
			  stateList : [],
			  zoneList: [],
			  consigneeCList: [],
			  consigneeTypeList : [],
			  plantCode : [],
			  cityListVal : '',
			  areaListVal: '',
			  sateListval : '',
			  zoneListVal: '',
			  consigneeCListVal : '',
			  consigneeTypeListVal: '',
			  plantCodeVal: '',
			  consigneeAreaCode: '',
			  consigneeNameVal:'',
			  openforceclose1:'',
			  cCodeTranshipmentVal : '',
			  cNameTranshipmentVal:"",
			  cCityTranshipmentVal: "",
			  cStateTranshipmentVal : "",
			  cZoneTranshipmentVal: "",
			  otherCCityClass: "show-n",
			  sliderForBulkUpload:"",
			  cvscontent:"",
		}

	}
	
	
	componentDidCatch(error, info)
	{
		console.log("Error here ", error)
		console.log("Error Info here ", info)
	}
	logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
	componentDidMount(){
		loadDateTimeScript();
		
		
		
		redirectURL.post("/dashboard/getPRTConsigneesData")
		.then((resp) => {
			var city_list = [{label:"Other" ,value : "Other"}]
			resp.data.consignee_cities.map(e => {
				city_list.push({label:e , value:e})
			})
			var state_list = []
			resp.data.consignee_states.map(e =>{
				state_list.push({label:e , value:e})	
			})
				this.setState({
					rowData:resp.data.records,
					cityList:city_list,
					zoneList : resp.data.zone_list,
					stateList:state_list
				});
		})

	};
	

	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			sliderSegmentTranslate : "",
			tabsliderTranslate:'',
			sliderTranslatesidebar:'',
			sliderForceCloseTranslate:'',
			sliderCommentTranslate:"",
			sliderForBulkUpload:'',
		});
		
	}



	closeAlert = () => {
        this.setState({
            show: false
        });
		if(this.state.basicType == "success"){
			window.location.reload()
		}
	}

	
	

	onOpenForceCloseModal = () => {
		this.setState({ openforceclose: true });
	 };
	
	 onCloseForceCloseModal = () => {
	   this.setState({ openforceclose: false });
	 };
	
	

	 changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
	}

	newFilter = (filterType, selOption) => {
		// console.log("filterType",filterType, selOption)
		if(filterType == "cityListVal" && selOption.label == "Other"){
			this.setState({
				otherCCityClass: "show-m"
			})
			$("#other_consignee_city").attr("required",true)
		}
		else{
			this.setState({
				otherCCityClass: "show-n"
			})
			$("#other_consignee_city").removeAttr("required",true)
		}
		this.setState({ [filterType]: selOption });
	}


	formUpdateConsigneeDetails = e =>{
		e.preventDefault()
		const {update_consignee_code,update_latitude,update_longitude,cityListVal,areaListVal,consigneeAreaCode,consigneeNameVal,sateListval,zoneListVal} = this.state
		if(cityListVal!='' && sateListval !=""&&zoneListVal!=""){
			let updateLatitude = $("#update_latitude").val();
			let updateLongitude = $("#update_longitude").val();
			var characterReg = /^\s*[.0-9,]+\s*$/;
			// console.log("tested",characterReg.test(updateLatitude))
			// console.log("tested",characterReg.test(updateLongitude))
			if(characterReg.test(updateLatitude) == true) {
				if(characterReg.test(updateLongitude) == true) {
						let updateConsigneeCode = $("#update_consignee_code").val();
						let update_consignee_name = $("#update_consignee_name").val();
						let update_consignee_address = $("#consigneeAddress").val();
						let update_cosnignee_pincode = $("#consignee_pincode").val();
						let update_emails = $("#update_emails").val();
						if([undefined, null , "" , "undefined"].includes(update_emails)){
							update_emails = ""
						}else{
							update_emails = update_emails.split(",")
							var emails = []
							update_emails.map(e=> {
								var email = e.trim()
								emails.push(email)
							})
							update_emails = emails
						}
						var requestparameters = {
							dept_code: 'LOG-PRT',
							consignee_code: updateConsigneeCode,
							lat: updateLatitude,
							lng: updateLongitude,
							cityListVal: cityListVal.value,
							zoneListVal: zoneListVal,
							sateListval: sateListval.value,
							update_consignee_name,
							update_consignee_address,
							update_cosnignee_pincode,
							update_emails,
							updated_by:localStorage.getItem('email')
						}

					
					
					if(requestparameters.cityListVal === "Other"){	
						let dataOfOtherCity = $("#other_consignee_city").val();		 
						requestparameters.cityListVal = dataOfOtherCity
					}
					console.log("requestparameters ", requestparameters)
					redirectURL.post("/consignments/updateconsigneedetails", requestparameters)
					.then((response) => {
						console.log("res ", response.data)
						if(response.data.status == "Success")
						{
							$("#update_latitude").val("");
							$("#update_longitude").val("");
							$("#update_consignee_code").val("");
							$("#consigneeAddress").val("");
							$("#consignee_pincode").val("");
							$("#update_consignee_name").val("");
							this.setState({
								cityListVal:"",
								areaListVal: '',
								sateListval : '',
								zoneListVal: '',							
								openforceclose:false,
								show:true,
								basicTitle:'Data Updated Successfully',
								basicType:"success",
								otherCCityClass: "show-n"
							});
						}
						else{
							$("#update_latitude").val("");
							$("#update_longitude").val("");
							$("#update_consignee_code").val("");
							$("#consigneeAddress").val("");
							$("#consignee_pincode").val("");
							$("#update_consignee_name").val("");
							this.setState({
								update_consignee_name : "",
								update_consignee_code:"",
								update_latitude:"",
								update_longitude:"",
								openforceclose:false,
								show:true,
								basicTitle:'Data not Updated',
								basicType:"danger"
							});
						}
						
					})
					.catch(function(e){
						console.log("error ",e)
					})
				}else{
					this.setState({
						basicTitle: "Please Enter Numeral And Dot For Longitude.",
						basicType: "warning",
						show: true
					})
				}
			}else{
				this.setState({
					basicTitle: "Please Enter Numeral And Dot For Latitude.",
					basicType: "warning",
					show: true
				})
			}
		}
		else{
			this.setState({
				basicTitle: "Please Select City, State, Zone",
				basicType: "warning",
				show: true
			})
		}
		
	}

	


	

	changeFileHandler = async (e) => {
        var config = {
            headers: [

                {
                    name: 'Consignee Code',
                    inputName: 'consignee_code',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'Consignee Name',
                    inputName: 'consignee_name',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'Consignee City',
                    inputName: 'consignee_city',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'Consingee State',
                    inputName: 'consignee_state',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'Consignee Address',
                    inputName: 'consignee_address',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'Consignee Pincode',
                    inputName: 'consignee_pincode',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'Consignee Lattitude',
                    inputName: 'consignee_lat',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'Consingee Longitude',
                    inputName: 'consignee_lng',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
				{
                    name: 'Zone',
                    inputName: 'zone',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
				{
                    name: 'Zone Code',
                    inputName: 'zone_code',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
				{
                    name: 'Emails',
                    inputName: 'emails',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },

            ]
        }

        var data = CSVFileValidator(e.target.files[0], config)
            .then(csvData => {
                console.log(csvData.data)
                this.setState({
                    cvscontent: csvData.data
                })
            })
            .catch(err => { })

        var out = new Promise(function (reject, resolve) {
            var reader = new FileReader();
            reader.onload = async function (e) {
                var contents = await e.target.result;
                resolve(contents);
            };
            var tt = reader.readAsText(e.target.files[0]);
        });

        this.setState({
            file: e.target.files[0]
        });

        if (e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
        }
        else {
            e.target.value = null;
            this.setState({
                uploadFile: '',
                show: true,
                basicType: 'danger',
                basicTitle: 'Please upload file having extensions .csv only.',
            });
        }


    }

	onSubmitBulkUpload = async (event) => {
        event.preventDefault()
        this.setState({
            loadshow: 'show-m',
            overly: 'show-m',
        })
        var csvData = this.state.cvscontent
        console.log(csvData.length, "lenght1729")
        if (csvData != undefined && csvData != '') {
            if (csvData.length > 0) {
                var csvcontent = []
                csvData.map(e => {
                    if (e.consignee_code != ""){
                        csvcontent.push(e)
                    }
                })
                console.log(csvcontent.length , "length1729")
                var params = {
                    csvcontent: csvcontent,
                    uploaded_by: localStorage.getItem('email'),
                }
                console.log(params)
                redirectURL.post('dashboard/bulkUploadPRTConsigneeData', params).then(response => {
                    if (response.data.status == 'success') {
                        this.setState({
                            show: true,
                            basicTitle: 'Data Updated Successfully',
                            basicType: "success",
                            loadshow: 'show-n',
                            overly: 'show-n',
                            sliderForMonthSob: "",
                        })
                    } else {
                        this.setState({
                            show: true,
                            basicTitle: 'Data not Updated',
                            basicType: "danger",
                            loadshow: 'show-n',
                            overly: 'show-n',
                            sliderForMonthSob: "",
                        })
                    }
                })
            }
        }
        else {
            this.setState({
                show: true,
                basicTitle: 'Choose file to continue',
                basicType: "danger",
            });
        }

    }

	onClickBulkUploadConsignee = () => {
		this.setState({
			sliderForBulkUpload:"slider-translate",
			overly:'show-m'
		})
	}

	editPRTConsigneeData = (params) => {
		if(params.colDef.field == 'edit_data'){
			console.log(params.data)

			this.setState({
				cityListVal:{label:params.data.consignee_city , value : params.data.consignee_city},
				zoneListVal:{label:params.data.zone , value : params.data.zone_code},
				sateListval:{label:params.data.consignee_state , value : params.data.consignee_state},
				openforceclose:true,

			})
			$("#update_consignee_code").val(params.data.consignee_code);
			$("#update_consignee_name").val(params.data.consignee_name);
			$("#consigneeAddress").val(params.data.consignee_address);
			$("#consignee_pincode").val(params.data.consignee_pincode);
			$("#update_latitude").val(params.data.consignee_lat);
			$("#update_longitude").val(params.data.consignee_lng);
			$("#update_emails").val(params.data.emails);
		}
	}
		
	render(){
		const modalStyles  = {
			width:'1300px !important',
		}
		const columnwithDefs = 
		[
			{
					field: 'edit_data',
					headerName: 'EDIT',
					colId: "_id",
					pinned: "left",
					width: 120,
					cellRenderer: function (params) {
						let basestring = params.data._id;
						let encryptedstring = window.btoa(basestring);
						var htmloption = '<a class="custom-btn label label-success"><i class="icofont icofont-edit"></i> Edit</a>';
						return htmloption;
					}
				},
                {
					headerName: "Cosignee Name",
					field: "consignee_name",
					width: 120,   
					resizable: true,
					// cellClass : "dateFormat",
				},
				{
					headerName: "Consignment Code",
					field: "consignee_code",
					//colId : "consignment_code",
					width: 120,
					editable:false,
					filter: "agSetColumnFilter",resizable: true,
					//cellRenderer : "agGroupCellRenderer"
				},
				{
					headerName: "Consignee Address",
					field: "consignee_address",
					width: 120, 
					filter: "agSetColumnFilter",
					resizable: true,
						          
				},  
				{
					headerName: "Consingee City",
					field: "consignee_city",
					width: 120,
                    filter: "agSetColumnFilter",  
					resizable: true,
				},
				
				{
					headerName: "Consignee State",
					field: "consignee_state",
					width: 150,
					filter: "agSetColumnFilter",
					cellRenderer:'',
					resizable: true,
					
				},
				{
					headerName: "Consignee Pincode",
					field: "consignee_pincode",
					width: 120,
					resizable: true,
					filter: "agSetColumnFilter",
					
				},
				{
					headerName: "Consignee Lattitude",
					field: "consignee_lat",
					width: 160,
					filter: "agSetColumnFilter",
					resizable: true,
				},
 	  	        {
		          headerName: "Consignee Longitude",
                  field: "consignee_lng",
                  width: 150,
                  filter: "agSetColumnFilter",
				  cellRenderer:'',
				  resizable: true   
				}, 
				{
					headerName: "Zone",
					field: "zone",
					width: 110,
					filter: "agSetColumnFilter",
					cellRenderer:'',resizable: true	          
				  },
				  {
					headerName: "Last Updated On",
					field: "updated_on",
					width: 110,
					filter: "agSetColumnFilter",
					valueGetter : function(params){
						if(params.data.updated_on!=undefined && params.data.updated_on!=""){
							return getHyphenYYYYMMDDHHMMSS(params.data.updated_on)
						}
					},
					cellRenderer:'',resizable: true	          
				  },
				  
			
		];
		return(
			<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
				 <div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
								<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle} </span>
									
								</h5>
				   			</div>				   			
		            		<div className="card-body pt-15px" >
								<div className="row">
									<div className="col-xl-12 col-lg-12 mb-10p">
                                            {/* 										
											<button className="float-right custom-btn btn-success" onClick={this.onClickBulkUploadConsignee}>
												<i className="icofont icofont-pencil"></i> Bulk Update Consignee
											</button> */}
										
										

										<button className="float-right custom-btn btn-warning" onClick={this.onOpenForceCloseModal}>
											<i className="icofont icofont-pencil"></i> Add PD Coordinates
										</button>
									</div>
								</div>
						
								 <div id="myGrid" style={{ height: "580px",width: "100%"}}  className="ag-theme-balham">
		          		          <AgGridReact
		          		            modules={this.state.modules}
		          		            columnDefs={columnwithDefs}
		          		            defaultColDef={this.state.defaultColDef}
									rowData={this.state.rowData}
									enableCharts={true}
									enableRangeSelection={true}
		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
		          		            onGridReady={this.onGridReady}
		          		            onGridState={this.onGridState}
		          		            statusBar={this.state.statusBar}
									sideBar={this.state.sideBar}
									paginationPageSize={this.state.paginationPageSize}
									pagination={true}
									frameworkComponents={this.state.frameworkComponents}
									detailCellRendererParams={this.state.detailCellRendererParams}
									stopEditingWhenGridLosesFocus={true}									
									enableCellChangeFlash={false}
									onCellClicked={this.editPRTConsigneeData.bind(this)}
									// onCellEditingStopped={this.onCellUpdateData.bind(this)}
									// onCellClicked={this.showInvalidModal.bind(this)}
									// rowSelection={this.state.rowSelection}
									// onRowSelected={this.onRowSelection.bind(this)}
									suppressRowClickSelection={true}
									// rowClassRules={this.state.rowClassRules}
									// onCellDoubleClicked={this.onRowClicked.bind(this)}
									gridOptions={{
										context: { componentParent: this }
									}}
									masterDetail={true}
									overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
									excelStyles= {this.state.excelStyles}
									// getRowHeight={this.state.getRowHeight}
									//editType={this.state.editType}
									//floatingFilter={true}
									//suppressCellFlash={true}
									// onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
    								// suppressCellSelection= {true}
		          		          />
									
									{/* 
									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
									</div> */}

		          		        </div>
				   			</div>
				   		</div>	
				   	</div>
					   
				 </div>
				{/* <div className={"slide-r " + (this.state.sliderForBulkUpload)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							Bulk Update Consignee
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
							<form method="POST" id='upform' className="theme-form col-xl-12 col-lg-12" onSubmit={this.onSubmitBulkUpload}>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Upload File *</label>
									<input
										type="file"
										name="uploadfile"
										className="form-control"
										onChange={this.changeFileHandler} />
								</div>

								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
								
								<div className="col-xl-12 col-lg-12 form-group">
									<a href={require("../../assets/json/bulk_upload_prt_consignee_data.csv")} target="_blank" className="btn btn-warning">Download Template</a>
								</div>
							</form>
						</div>
					</div>
				</div> */}
				
				
				
				 
                
                
				<div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            

				<Modal open={this.state.openforceclose} onClose={this.onCloseForceCloseModal} styles={  modalStyles}>
	                <div className={"row"} style={{padding:'5px 0px', width: '100%', overflow: 'hidden',zIndex:'9999',}} >
					<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formUpdateConsigneeDetails}>
						<div className="row form-group">
							<div className="col-xl-4 col-lg-4 form-group">
								<label className="col-xl-12 col-lg-12">Consignee Code</label>								
                                <input type="number" name="update_consignee_code"
								// value={this.state.update_consignee_code} 
								// onChange={this.changeHandler.bind(this)}
								placeholder="Consignee Code"  id="update_consignee_code" className="num-cls form-control" required />
							</div>

							<div className="col-xl-4 col-lg-4 form-group">
								<label className="col-xl-12 col-lg-12">Consignee Name</label>								
                                <input type="text" placeholder="Consignee Name" name="consigneeNameVal" 
								id="update_consignee_name"
								// value={this.state.consigneeNameVal}  
								// onChange={this.changeHandler.bind(this)} 
								className="form-control" required />
							
							</div>

							<div className="col-xl-4 col-lg-4 form-group">
								<label className="col-xl-12 col-lg-12">Consignee City</label>
								<Select
									className="border-radius-0"
									isMulti={false}
									id="selectedCity"
									style={{ borderRadius: "0px" }}
									value={this.state.cityListVal}
									onChange={this.newFilter.bind(this, "cityListVal")}
									options={this.state.cityList}
									placeholder={"Consignee City"}
									required
								/>
							</div>
						</div>
						<div className={"row form-group "+(this.state.otherCCityClass)}>
						<div className="col-xl-4 col-lg-4 form-group">
								<label className="col-xl-12 col-lg-12">Other Consignee City</label>								
                                <input type="text" placeholder="Other Consignee City" name="otherConsigneeCityVal" 
								id="other_consignee_city"
								// value={this.state.consigneeNameVal}  
								// onChange={this.changeHandler.bind(this)} 
								className="form-control"  />							
							</div>
						</div>
							<div className="row form-group">
								<div className="col-xl-4 col-lg-4 form-group">
									<label className="col-xl-12 col-lg-12">Consignee Address</label>
									<input type="text" placeholder="Consignee Address" name="Consignee Address" id="consigneeAddress" className="form-control"  />	
								</div>
								<div className="col-xl-4 col-lg-4 form-group">
									<label className="col-xl-12 col-lg-12">Consignee Pincode</label>
									<input type="text" placeholder="Consignee Pincode" name="Consignee Pincode" id="consignee_pincode" className="form-control"  />	
								</div>
								<div className="col-xl-4 col-lg-4 form-group">
									<label className="col-xl-12 col-lg-12">Zone Code</label>
									<Select
										className="border-radius-0"
										isMulti={false}
										id="selectedZone"
										style={{ borderRadius: "0px" }}
										value={this.state.zoneListVal}
										onChange={this.newFilter.bind(this, "zoneListVal")}
										options={this.state.zoneList}
										placeholder={"Zone Code"}
										required
									/>
								</div>
							</div> 
						
						<div className="row form-group">
							<div className="col-xl-4 col-lg-4 form-group">
								<label className="col-xl-12 col-lg-12">Consignee State</label>								
								<Select
									className="border-radius-0"
									isMulti={false}
									id="selectedState"
									style={{ borderRadius: "0px" }}
									value={this.state.sateListval}
									onChange={this.newFilter.bind(this, "sateListval")}
									options={this.state.stateList}
									placeholder={"Consignee State"}
									required
								/>
							</div>

							<div className="col-xl-4 col-lg-4 form-group">
								<label className="col-xl-12 col-lg-12">Latitude</label>								
                                <input type="text" placeholder="Latitude" name="update_latitude" id="update_latitude"
								// value={this.state.update_latitude} 
								//  onChange={this.changeHandler.bind(this)} 
								 className="form-control" required />
							
							</div>

							<div className="col-xl-4 col-lg-4 form-group">
								<label className="col-xl-12 col-lg-12">Longitude</label>
								<input type="text" placeholder="Longitude"  name="update_longitude" id="update_longitude"
								className="form-control" required />
							</div>
						</div>
							<div className="row form-group">
								<div className="col-xl-6 col-lg-6 form-group " >
									<label className="col-xl-12 col-lg-12">Emails</label>
									<input type="text" placeholder="Emails" name="emails" id="update_emails"
										className="form-control" />
								</div>
							</div>
							<div className="col-xl-12 col-lg-12 form-group text-center">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>
					</div>
				</Modal>
			</div>
              	
		);
	}
}




function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}                 
function timeConvert(n) {
	// var num = n/1000;
	// var hours = (num / (3600*24));
	// var rhours = Math.floor(hours);
	// var minutes = (n) / (60*60);
	// var rminutes = Math.round(minutes);

	var diffMs = n;
	var diffDays = Math.floor(diffMs / 86400000); // days
	var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
	var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
	

	return diffHrs + " hour(s) and " + (diffMins/60) + " minute(s).";
}                              
var currentinfowindow = null;
function getTimeInfo(marker,timedet,coords)
{
	var timeinfo = new window.google.maps.InfoWindow({
          content: "Time at this location: " + timedet
        });

	marker.addListener('click', function() {
		
				if (currentinfowindow != null) {
					currentinfowindow.close();
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				else
				{
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				
        });
}

function onSetCritical(params, status){
	//console.log("onSetCritical ", params);
	//console.log("onSetCritical value ", status);
	redirectURL.post('/consignments/setCriticalStatus',{
		params:params,
		is_critical:status
	})
		.then((response) => {
		
		//var records = JSON.parse(JSON.stringify(response)).data;
		console.log(response.data);
		});
		
}

function onSetProbableAccidentConfirm(params, status){
	//console.log("onPAVal ", params);
	//console.log("onPAVal value ", status);
	redirectURL.post('/consignments/setProbableAccidentConfirm',{
		params:params,
		probable_accident_confirm:status
	})
		.then((response) => {
		
		//var records = JSON.parse(JSON.stringify(response)).data;
		console.log(response.data);
		});
		
}


function secondsToString(seconds)
{
var numdays = Math.floor(seconds / 86400);
var numhours = Math.floor((seconds % 86400) / 3600);
var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
var numseconds = ((seconds % 86400) % 3600) % 60;
return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}

function secondsToDays(seconds)
{
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays;
}
function CheckForHtmlTag(){
	var rs = document.getElementById("invalid_reason");
	var reg =/<(.|\n)*?>/g; 
	if (reg.test(rs.value)) {
		var ErrorText = "Oops! HTML or Script is not allowed.";		
		rs.value=''
		//alert();
		return 1;
	}
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	//console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}

function loadDateTimeScript(){
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i',
		onShow:false
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false,
		onShow:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}


function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 


function GetSortAscOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

// $(document).on(".num-cls", function() {
//     this.value = this.value.replace(/\D\./g,'');
//     // this.value = this.value.replace(/[!#$%^*()+\=;'|<>\?a-zA-Z]+/g,"")
	

// });