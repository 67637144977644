
// import React, { Component } from 'react';
// import ReactDOM from 'react-dom';
// var redirectURL = require('../redirectURL');

 
// const ViewBillAction = (props) => {
//     const handleClick = (e) => {
//         e.stopPropagation();
//         //console.log("Overspeed ",props.data);
//         props.context.componentParent.onClickGenerateBill(props);
//     };
    

//     return (
//         <div>
// 			<button onClick={handleClick} className="custom-btn f12 btn btn-warning" title="View Bill" >View Bill</button>
            
//         </div>
//     );
// };

// export default ViewBillAction;


import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import SweetAlert from 'react-bootstrap-sweetalert';
import DateFormater from '../layouts/dateComponent';
import { getHyphenDDMMMYYYYHHMM } from '../common/utils';
import ReactHTMLTableToExcel from 'react-html-table-to-excel'; 
//import 'bootstrap/dist/css/bootstrap.min.css';  
var redirectURL = require('../redirectURL');
var selectRowItemFromAgGrid='';
var url=redirectURL;
var googleAnalytics = require("../common/googleanalytics");
export default class ViewGPSBillAction extends Component {

    constructor(props) {
        super(props);
        this.state = {
			pageTitle: "View Bill",
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            loadshow:"show-n",
            month:'',
            year:'',
            billdata:[]
		};
        
	}
    componentDidMount(){
        this.setState({
            loadshow:"show-m"
        })
        var s = this.props.location.search
        if(s != "")
        {
            var spl = s.split("?")
            var s1 = spl[1].split("&")
            var sorce = s1[0].split("=")
            var month = sorce[1]
            var sorcey = s1[1].split("=")
            var year = sorcey[1]
            var params ={
                month:month,
                year:year
            }
            redirectURL.post("/consignments/gpsbilldatabyparams",params)
            .then((response) => {
                console.log("response ",response.data)
                this.setState({
                    month:month,
                    year:year,
                    billdata:response.data,
                    loadshow:"show-n"
                })
            })
        }
    }

    render(){
        var billdata = this.state.billdata;
        var mns = ["JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE", "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"]
        var smonth = mns[parseInt(this.state.month)-1];
        var syear = this.state.year; 
        return ( 
            <div className="container-fluid">
					<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
                    <div className="row">
                        <div className="col-xl-12 col-lg-12">
                            <h4>View Bill</h4>
                        {(billdata.length > 0)?
                            <div className="card dashboardPanel">
                            
                            <table id="emp" class="table">                               
                                <tbody> 
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td colspan="4">&nbsp;</td>
                                    </tr> 
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td colspan="4" align="center" style={{fontSize:"22px", textAlign:"center"}}>
                                            {/* <img src={require("../../assets/icons/enmovil_logo.jpg")} /> */}
                                            Enmovil Solutions Private Limited.
                                        </td>
                                    </tr>
                                    <tr> 
                                        <td>&nbsp;</td>
                                        <td align="center" style={{fontSize:"18px", textAlign:"center"}} colspan="4">
                                            Plot#110, Flat 202, Kavuri Hills, 
                                            Hyderabad - 500038
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                        <td align="center" style={{fontSize:"18px", textAlign:"center"}}  colspan="4">ACTIVE VEHICLE SUMMARY for {smonth} {syear}</td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <th>Department</th>
                                        <th>Truck count as per Enmovil</th>
                                        <th>Truck count approved by MSIL</th>
                                        <th>Remarks</th>
                                    </tr>
                                        <td>&nbsp;</td>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>SND</td>
                                        <td>{billdata[0].primaryunique}</td>
                                        <td>{billdata[0].approvedtruckscnt}</td>
                                        <td></td>
                                    </tr>
                                    
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>Total Trucks</td>
                                        <td>
                                            {
                                                parseInt(billdata[0].primaryunique)
                                            }

                                        </td>
                                        <td>
                                        {
                                                parseInt(billdata[0].approvedtruckscnt)
                                            }
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td colspan="4">
                                        &nbsp;
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td colspan="2">Total trucks for billing</td>
                                        <td>{
                                                (parseInt(billdata[0].approvedtruckscnt) < 15000)?15000:(parseInt(billdata[0].approvedtruckscnt))
                                            }</td>
                                        <td>As per billing clause below</td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td colspan="4">
                                            *Billing clause as per Annexure C of legal agreement:
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td colspan="4">
                                            Chargeable for minimum 15,000 vehicles per month; As per actuals for &gt; 15,000 vehicles.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td colspan="4">
                                            Vehicle / Active-vehicle definition for billing purposes: Any truck that has at least one<br />
                                             consignment in a given month is considered as an "active vehicle" or "vehicle” for billing purposes.<br /> 
                                             Other modes (Railways, Ships or Flights) will be tracked but charged based on trucks used for first mile,<br />
                                             last mile, transshipment, as the case(s) may be.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td colspan="4">&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td colspan="4">&nbsp;</td>
                                    </tr>

                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>
                                            Department
                                        </td>
                                        <td>SPOC</td>
                                        <td>Approved On</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>SND G/M/GJ</td>
                                        <td>{billdata[0].primaryspoc}</td>
                                        <td>{(billdata[0].primaryspocapproveon != "")?getHyphenDDMMMYYYYHHMM(billdata[0].primaryspocapproveon):""}</td>
                                        <td></td>
                                    </tr>
                                    
                                </tbody>  

                                </table>
                                <div className="col-xl-12 col-lg-12" style={{marginBottom:"30px",textAlign:"center"}}>  
                                        <ReactHTMLTableToExcel  
                                            className="btn btn-info"  
                                            table="emp"  
                                            filename="BillReport"  
                                            sheet="Sheet"  
                                            buttonText="Export excel" />  
                                </div> 

                            </div>
                            :""}
                        </div>
                    </div>
                    <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box">
                        <div className="loader-box">
                            <div className="rotate dashed colored"></div>
                        </div>
                    </div>
                </div>
            </div>
                    
			);
		}
}


