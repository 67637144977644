import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';

import axios from 'axios';
import Multiselect from 'multiselect-dropdown-react';
import $ from 'jquery';
// import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

export default class LmBaseConfig extends Component {
 
    constructor(props) {
        super(props);

        this.state = {
			pageTitle: "Load Management Base Config",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
        	erroMessage:'',
            successMessage:'',
            show: false,
            basicTitle:'',
			basicType:"default",
			min_32_cmt : "",
			max_32_cmt : "",
			min_20_cmt : "",
			max_20_cmt : "",
			min_courier : "",
			nodes_per_truck : "",
			allowed_distance : "",
			rowId : "",
        };

	}
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
    componentDidMount = async () =>{
        loadDateTimeScript();
    	if (googleAnalytics.page.enableGA) {
			this.logPageView();
		}
    	await redirectURL.post("/lmconsignees/getLmBaseConfig").then((response)=>{
			console.log(response.data);
			this.setState({
				min_32_cmt : response.data[0].min_32_cmt,
				max_32_cmt : response.data[0].max_32_cmt,
				min_20_cmt : response.data[0].min_20_cmt,
				max_20_cmt : response.data[0].max_20_cmt,
				min_courier : response.data[0].min_courier,
				nodes_per_truck : response.data[0].nodes_per_truck,
				allowed_distance : response.data[0].allowed_distance,
				rowId :  response.data[0]._id
			})
		}).catch((e)=>{
			console.log(e);
		})
    }

    changeInputHandler = (event) => {
    	let name = event.target.name;
		let value = event.target.value;
    	this.setState({[name]:value});
    }

    closeAlert = () => {
        this.setState({
            show: false
        });
        window.location.reload();
    }
    
	formHandler = (event) => {
		event.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmitted,
			}
			googleAnalytics.logEvent(eventOptions);
        }
        var min_32_cmt = $("#min_32_cmt").val();
        var max_32_cmt = $("#max_32_cmt").val();
        var min_20_cmt = $("#min_20_cmt").val();
        var max_20_cmt = $("#max_20_cmt").val();
        var min_courier = $("#min_courier").val();
        var nodes_per_truck = $("#nodes_per_truck").val();
        var allowed_distance = $("#allowed_distance").val();
        var params = {
            "min_32_cmt" : min_32_cmt,
            "max_32_cmt" : max_32_cmt,
            "min_20_cmt" : min_20_cmt,
            "max_20_cmt" : max_20_cmt,
            "min_courier" : min_courier,
            "nodes_per_truck" : nodes_per_truck,
			"allowed_distance" : allowed_distance,
			"_id" : this.state.rowId
        };
        console.log(params);
        redirectURL.post("/lmconsignees/savelmbaseconfig",params).then((response) => {
            this.setState({
                basicTitle:"Success",
                basicType : "success",
                show : true
            });
            //window.location.reload();
        })
	}  
    
    render(){
        return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
			                <div className="card-header">
				       			<h5>
				       				<i className="icon-map cus-i"></i> <span>{this.state.pageTitle}</span>
				       			</h5>
				   			</div>
			   				<div className="row card-body">
				   			
							<form className="theme-form col-xl-12 col-lg-12" onSubmit={this.formHandler}>
                                <div className="row col-xl-12">
	                    			<div className="col-xl-4 col-lg-4">
					               		<div className="form-group">
					               			<label className="c-lbl">Min Capacity for 32 Feet Trucks (CMT) *:</label>
					               			<input type="text" name="min_32_cmt" id="min_32_cmt" onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="" value={this.state.min_32_cmt} required/>
					               		</div>
				               		</div>
                                    <div className="col-xl-4 col-lg-4">
					               		<div className="form-group">
					               			<label className="c-lbl">Max Capacity for 32 Feet Trucks (CMT) :</label>
					               			<input type="text" name="max_32_cmt" id="max_32_cmt" onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="" value={this.state.max_32_cmt}/>
					               		</div>
				               		</div>
                                    <div className="col-xl-4 col-lg-4">
					               		<div className="form-group">
					               			<label className="c-lbl">Min Capacity for 20 Feet Trucks (CMT) :</label>
					               			<input type="text" name="min_20_cmt" id="min_20_cmt" onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="" value={this.state.min_20_cmt}/>
					               		</div>
				               		</div>
                                </div>

                                <div class="hr-dashed"></div>

                                <div className="row col-xl-12">
                                    <div className="col-xl-3 col-lg-3">
					               		<div className="form-group">
					               			<label className="c-lbl">Max Capacity for 20 Feet Trucks (CMT):</label>
					               			<input type="text" name="max_20_cmt" id="max_20_cmt" onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="" value={this.state.max_20_cmt}/>
					               		</div>
				               		</div>
                                    <div className="col-xl-3 col-lg-3">
					               		<div className="form-group">
					               			<label className="c-lbl">Minimum Courier:</label>
					               			<input type="text" name="min_courier" id="min_courier" onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="" value={this.state.min_courier}/>
					               		</div>
				               		</div>
                                
	                    			<div className="col-xl-3 col-lg-3">
					               		<div className="form-group">
					               			<label className="c-lbl">Maximum Nodes:</label>
					               			<input type="text" name="nodes_per_truck" id="nodes_per_truck" onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="" value={this.state.nodes_per_truck}/>
					               		</div>
				               		</div>
                                    <div className="col-xl-3 col-lg-3">
					               		<div className="form-group">
                                            <label className="c-lbl">Max Distance:</label>
                                            <input type="text" name="allowed_distance" id="allowed_distance" onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="" value={this.state.allowed_distance}/>
					               		</div>
				               		</div>
                                </div>

								<div class="hr-dashed"></div>

                                <div className="row col-xl-12">
                                    <div className="col-xl-3 col-lg-3">
					           			
                                        <div className="form-group">
					               			<button type="submit" className="btn btn-success cs-btn" id="saveGeo">Submit</button>
					               			{/* <a href="/manage/geofences" className="btn btn-info cs-btn">Cancel</a> */}
					               			
					               		</div>
									</div>
                                </div>
				           		</form>
			           		</div>
		           		</div>
	           		</div>
                </div>
            </div>
        );
    }
}
function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_date').datetimepicker({
		//mask:'39-19-9999',
        format:'d-m-Y',
        minDate:true,
		timepicker:false,
    });
}