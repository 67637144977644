import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
// import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import Select from 'react-select';
import AnalyticsDashboardColumnDrilldown from "./analyticsdashboardcolumndrilldownComponent";
import AnalyticsDashboardColumn from "./analyticsdashboardcolumnComponent";
var redirectURL = require('../redirectURL');
var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");

export default class AnalyticsDashboard extends Component {
    constructor(props){
    	super(props);
    	
    	this.state={
    		pageTitle: "Secondary Payment Dashboard (MSIL)",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
            eventFormAction: "formAction",
            loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			overly:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            screens:[],
            startDate:"",
            endDate:"",
            defaultsdate:"",
            defaultedate:"",
            analyticcolumn:[],
            analyticcolumnlogs:[],
            dept_code:{"value":"all","label":"All"}
           
		
    	}
        this.onLoadDashboardData = this.onLoadDashboardData.bind(this);
    
    }
   
    componentDidMount = async () => {
        var sdate = moment.parseZone().subtract(1, 'days').format('YYYY-MM-DD');
        var edate = moment.parseZone().format('YYYY-MM-DD');
        this.setState({
            startDate:sdate,
            endDate:edate,
            defaultsdate:sdate,
            defaultedate:edate
        })
        var parameters={
            startDate:sdate,
            endDate:edate,
            dept_code:this.state.dept_code.value
        }
        var arr = []

        await redirectURL.post("/consignments/allmenus",parameters)
		.then((response) => {
            var records = response.data.records;
            var arr = []
            if(records.length > 0)
            {
                records.map((item) => {
                    arr[item.path] = item.name
                })
            }
           // console.log("arr ", arr)
            this.setState({
                screens:arr
            })
        })

        await this.onLoadDashboardData(parameters);
       
        
	};
    
    onLoadDashboardData = async (parameters) =>{
        this.setState({
            analyticcolumn:[]
        })
        var screens = this.state.screens;
       // console.log("screens ", screens)
		redirectURL.post("/consignments/analyticsdata",parameters)
		.then((response) => {
            var records = response.data.records;
          //  console.log("anaytics ", records);
            var columnchart = []
            var series = []
            var drills=[]
            var categories=[]
            if(records.length > 0)
            {
                var spaths = groupBy(records, rdata => rdata.screenpath);
                //console.log("spaths ",spaths)
                spaths.forEach((item,key) => {
                  //  console.log("Key ",key)
                    if(screens[key] != 'undefined' && screens[key] != undefined)
                    {
                        series.push({
                            name: key,
                            y: item.length,
                            drilldown: key
                        })
                        
                        categories.push(key);
                        var sdril = []
                        if(item.length > 0)
                        {
                            var users = groupBy(item, rdata => rdata.user_email);
                            //console.log("users ", users)
                            users.forEach((im,ky) => {
                                var email = ky;
                                sdril.push([email, im.length])
                            })
                        }
                        drills.push({
                            name: key,
                            id: key,
                            data: sdril
                        })

                    }
                    
                })
                
            }
            columnchart.push({
                series:series,
                drildown:drills,
                yaxistitle:"Total Views",
                categories:categories
            }) 
           // console.log("columnchart ", columnchart)
            this.setState({
                analyticcolumn:columnchart
            })
        })
        
        redirectURL.post("/consignments/userlogs",parameters)
        .then((response) => {
            var records = response.data.records;
            //console.log("records ", records)
            var userdata = []
            var categories =[]
            if(records.length > 0)
            {
                var users = groupBy(records, rdata => rdata.email);
                try{
                    users.forEach((user,key) => {
                        categories.push(key)
                        userdata.push({
                            name: key,
                            y: user.length
                        })
                    })
                }
                catch(e){}
            }
            console.log("userdata ",userdata)
            var cchart=[]
             cchart.push({
                series:userdata,
                yaxistitle:"Total",
                categories:categories
            })
            // console.log("userdata ", cchart)
            this.setState({
                analyticcolumnlogs:cchart
            })
           
        })
    }
	handlerStartDateTime = (event) => {
    	var d = new Date(event._d);
    	
    	var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
    	this.setState({
    		startDate:startdate
    	});
    }
	
    handlerEndDateTime  = (event) => {
    	var d = new Date(event._d);
    	
        var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate())
        this.setState({
    		endDate:edate
    	});
    }
    onClickGetAnalytics = async () => {

		if(this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN")
		{
			var sDate = this.state.defaultsdate;
		}
		else{
			var sDate = this.state.startDate;
		}
		if(this.state.endDate == '' || this.state.startDate == "NaN-NaN-NaN")
		{
			var eDate = this.state.defaultedate;
		}
		else{
			var eDate = this.state.endDate;
        }
        var parameters={
            startDate:sDate,
            endDate:eDate,
            dept_code:this.state.dept_code.value
        }
        this.onLoadDashboardData(parameters)
    }
    changeDeptHandler= dept_code =>{
		this.setState(
			{ dept_code }
        );		  
    }
    deptoptions(){
        var items=[];
        items.push(
            {"value":"all", "label":"All"},
            {"value":"AD", "label":"AD"},
            {"value":"SA", "label":"SA"},
            {"value":"SNDG", "label":"SNDG"},
            {"value":"LOG-PRT", "label":"LOG-PRT"},
            {"value":"LOG-TNP", "label":"LOG-TNP"},
            {"value":"LOG-SC", "label":"LOG-SC"}
        )
        return items;
    }
    render(){
        var analyticcolumn = this.state.analyticcolumn;
        var analyticcolumnlogs = this.state.analyticcolumnlogs;
        // console.log("analyticcolumnlogs ", analyticcolumnlogs)
        return (
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h5>
                                <i className="icofont icofont-dashboard-web cus-i"></i> <span> Analytics Dashboard</span>                                    
                            </h5>
                        </div>				   			
                        <div className="card-body">
                            <div className="row col-xl-12 col-lg-12">
                                <div className="col-xl-3 col-lg-3 form-group">
                                    <label>From Date</label>
                                    <Datetime value={this.state.startDate} 
                                    disableCloseOnClickOutside={false} 
                                    closeOnSelect={true} 
                                    inputProps={{ placeholder: 'From', name: 'startDate', autoComplete:'off' }}
                                    dateFormat="YYYY-MM-DD" 
                                    name="startDate" 
                                    onChange={this.handlerStartDateTime.bind(this)} />
                                   
                                </div>

                                <div className="col-xl-3 col-lg-3 form-group">
                                    <label>To Date</label>
                                    <Datetime value={this.state.endDate} 
                                    disableCloseOnClickOutside={false}
                                    closeOnSelect={true} 
                                    inputProps={{ placeholder: 'To', name: 'endDate', autoComplete:'off'}} 
                                    dateFormat="YYYY-MM-DD" 
                                    onChange={this.handlerEndDateTime.bind(this)} />
                                    
                                </div>
                                <div className="">
                                    <label>Select Department</label>
                                    <Select 
                                            placeholder={"All"} 
                                            closeMenuOnSelect={true} 
                                            value={this.state.dept_code} 
                                            className="border-radius-0" 
                                            onChange={this.changeDeptHandler.bind(this)} 
                                            style={{borderRadius:"0px"}} 
                                            options={this.deptoptions()} required
                                        />
                                </div>
                                <div className="form-group col-xl-3 col-lg-3">
                                    <button type="button" className="btn btn-success mt-20p" onClick={this.onClickGetAnalytics.bind(this)}>Submit</button>
                                </div>
                            </div>

                            <div className="row col-xl-12 col-lg-12">
                                <div className="row col-xl-12 col-lg-12 mb-40p">
                                    <div className="col-xl-12 col-lg-12">
                                        {(analyticcolumn.length > 0)?
                                            <AnalyticsDashboardColumnDrilldown
                                                title={"Screen wise data"}
                                                subtitle={"Users data based on screens"}
                                                context={this}
                                                series={analyticcolumn[0].series}
                                                drildown = {analyticcolumn[0].drildown}
                                                categories={analyticcolumn[0].categories}
                                                yaxistitle={analyticcolumn[0].yaxistitle}
                                            />
                                        :""}
                                        
                                    </div>
                                </div>
                                <div className="row col-xl-12 col-lg-12 mb-40p">
                                    <div className="col-xl-12 col-lg-12">
                                        {(analyticcolumnlogs.length > 0)?
                                            <AnalyticsDashboardColumn
                                                title={"User Logins"}
                                                subtitle={""}
                                                context={this}
                                                series={analyticcolumnlogs[0].series}
                                                categories={analyticcolumnlogs[0].categories}
                                                yaxistitle={analyticcolumnlogs[0].yaxistitle}
                                            />
                                        :""}
                                        
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}



function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}


function GetSortOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 




