import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import { getDDMMYYYY, getDDMMMYYYY,getHyphenDDMMMYYYY,
	getDDMMYYYYHHMMSS, 
	getYYYYMMDD, 
	getYYYYMMDDHHMMSS, 
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD, 
	getHyphenDDMMYYYYHHMMSS, 
	getHyphenDDMMYYYY,
    getDDMMMYYYYHHMMDefault } from '../common/utils';

import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
// import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import CSVFileValidator from 'csv-file-validator'
import Geofencemap from "./geofencemap";
$.datetimepicker.setLocale('en');    
var redirectURL = require('../redirectURL');
var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");


export default class GeofenceMap extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			showform:'show-m',
			loadshow:'show-n',
			showrefresh:'show-n',
			sliderTranslate:'',
			showdata:this.displayData,
			modules: AllModules,
        	alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            show1: false,
            basicTitle1:'',
            basicType1:"default",
			contentString:"testing",
			mwidth:"col-xl-12 col-lg-12",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
				//editable: true,
				resizable: true
			},
			rowData: [],
            frameworkComponents: {
            },
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
            context: { componentParent: this },
            rowClassRules: {
				"boxbgblue": "data.to_be_approved == 1"
			},
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			detailCellRendererParams:{},
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:200,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,
            pagetitle:"MLL Geofence Map",
            sliderRouteTranslate:'',
            file:'',
            csvcontent:[],
            startDate:"",
            endDate:"",
            defaultsdate:"",
            defaultedate:"",
            headerHeight:80,
            
        };
        this.onLoadData = this.onLoadData.bind(this);
        this.changeFileHandler = this.changeFileHandler.bind(this);
	}
	
    componentDidMount(){
        var sdate = moment().startOf('month').format('YYYY-MM-DD');
        //var sdate = "2020-09-23";
        var edate   = moment().endOf('month').format('YYYY-MM-DD');
        
        this.setState({
            startDate:sdate,
            endDate:edate,
            defaultsdate:sdate,
            defaultedate:edate
        })
        var mindate =  moment.parseZone().format('YYYY-MM-DD');
        var minactdate =  moment.parseZone(new Date(mindate)).subtract(1, 'days').format('YYYY-MM-DD');
       // console.log("path ", this.props.match.path)
		this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});

        var parameters = {
            startdate:sdate,
            enddate:edate
        }
        this.onLoadData(parameters)

	}
    
    onLoadData(parameters)
    {
		redirectURL.post("/consignments/mllgefencedata")
		.then((response) => {
            console.log("mllgefencedata ", response.data)
            var records = response.data;
            var geofences= response.data.geofences;
            var plants= response.data.plants;
            var consignees= response.data.consignees;    
            var mapinfo = {
                geofences:geofences,
                plants:plants,
                consignees:consignees
            }
            this.setState({
                mapinfo:mapinfo
            })
        })
       this.setState({
        loadshow:"show-n",
        overly:"show-n"
       })
    }
    
    
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
	}
	/*Alert Popups*/
	closeAlert = async () => {
        await this.setState({
            show: false
        });
        // await window.location.reload()
    }

	/*Alert Popups*/
	closeAlert1 = async () => {
        await this.setState({
            show1: false
        });
        //await window.location.reload()
    }
	hideAlert = () => {
        this.setState({
            alert: null
        });
	}
    
	onShowUploadDiv = () => {
		//console.log("Click Upload Button")
		this.setState({
			uploadDivWidth:'30%',
			sliderTranslate:"slider-translate",
			showDiv:'show-m',
			csvmessage:'',
			csverrmessage:'',
		});
    }
    
	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
		});
	}
	
    
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			sliderTranslatesidebar:'',
			sliderForceCloseTranslate:'',
            sliderRakeTranslate:'',
            sliderBulkRakeTranslate:"",
			bulkslide:'',
			sliderForceDestination:'',
            sliderBulkForceClose:"",

		});
		
    }
    changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
	}
   
    selectsources(){
		let items = [];    
		items.push({"value":"NSK","label":"Nasik"})
		// try{
		// 	if(this.state.transporters.length > 0)
		// 	{
		// 		this.state.transporters.map((item) =>{
		// 			items.push({"value":item.transporter_code,"label":item.firstname})
		// 		})
		// 	}
		// }
		// catch(e){

		// }
		
		return items;
	}
    
    
    onChangeLoadingStations(loading_station_name){
		this.setState(
			{ loading_station_name },
			() => console.log(`Option selected:`, this.state.loading_station_name)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
   
    onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
	
	};
	 onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
	 }

    
    onClickShowData = async () => {
		var sDate = this.state.startDate;
		var eDate = this.state.endDate;

		this.setState({
			startDate:sDate,
			endDate:eDate,
			loadshow:'show-m'
		});

		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedSelectConsigner,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		
    }


	changeFileHandler = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		this.setState({
			file:e.target.files[0]
		});
		//console.log("e.target.files[0].type ", e.target.files[0].type);

		// if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
		// {
		// }
		// else{
		// 	e.target.value = null;
		// 	this.setState({
		// 		uploadFile:'',
		// 		show: true, 
		// 		basicType:'danger', 
		// 		basicTitle:'Please upload file having extensions .csv only.',
		// 	});
		// }
		
	}

    onClickUploadRakePlanning = async (event) => {
        this.setState({
            sliderBulkRakeTranslate:"slider-translate",
            overly:'show-m'
        })
    }

    formBulkUpload = async(event) => {
        event.preventDefault()

        if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": "Rake Schedule",
				"action": "form",
				"label": "Rake Planning Bulk Upload",
			}
			googleAnalytics.logEvent(eventOptions);
        }
       
        // console.log("csvcontent ", this.state.csvcontent);
        // var reqparams = {
		// 	csvcontent:this.state.csvcontent,
		// 	source:this.state.sourceopt.value,
		// 	userid:localStorage.getItem("userid"),
		// 	username:localStorage.getItem("username"),
		// 	useremail:localStorage.getItem("email")
		// }
		var reqparams = new FormData()
		reqparams.append("file", this.state.file)
		reqparams.append("source", this.state.sourceopt.value)
		reqparams.append("userid", localStorage.getItem("userid"))
		reqparams.append("username", localStorage.getItem("username"))
		reqparams.append("useremail", localStorage.getItem("email"))

        redirectURL.post("/consignments/uploadmllsummary", reqparams)
        .then((response) => {
            this.setState({
                sliderRakeTranslate:"",
                overly:'show-n',
                transporter_code:"",
                transporter_name:"",
                csvcontent:[],
                loadshow:'show-n',
                uploadFile:'',
                file:'',
                show:true,
                basicTitle:"Successfully upload file",
                basicType:"success"
			})
        })
        .catch(function(e){
            console.log("Error ", e)
        })
		
    }

    onOpenModal = () => {
		this.setState({ open: true });
	 };
	
	 onCloseModal = () => {
		this.gridApi.deselectAll();
	   this.setState({ open: false });
     };
	
    render(){
       
        return (
            
    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
            <SweetAlert
	            show={this.state.show1}
	            type={this.state.basicType1}
	            title={this.state.basicTitle1}
	            onConfirm={this.closeAlert1}
	        >
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
                   <div className="col-xl-12 col-lg-12">
                        
		            	<div className="card">

			                <div className="card-header">
				       			<h5>
				       				<i className="icon-map cus-i"></i> <span>MLL Geofence Map  </span>
                                   
								</h5>
				   			</div>
				   			<div className="card-body row">
                               {/* <div className="row col-xl-12 col-lg-12 ">
                                    <form method="POST" className="col-xl-12 col-lg-12" onSubmit={this.onClickShowData.bind(this)} >
                                    <div className="col-xl-6 col-lg-6 form-group">
                                        <label className="col-xl-4 col-lg-4">Geofence:</label>
                                        <div className="col-xl-12 col-lg-12">
                                            <Select 
                                            placeholder={"Select Loading Station"}
                                            closeMenuOnSelect={true}
                                            onChange={this.onChangeLoadingStations.bind(this)} 
                                            className={"col-xl-12 col-lg-12 border-radius-0"}
                                            style={{borderRadius:"0px"}}
                                            options={this.selectsources()} />
							
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 form-group">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                    </div>
                                    </form>
                                </div> */}
                                <div className={"col-xl-12 col-lg-12 mb-10p"}>
                                   <Geofencemap 
                                        mapFor={"geofence"}
                                        mapinfo = {this.state.mapinfo}
                                    />
                                </div>   
                                
                              
                            </div>
				   		</div>	
				   	</div>
				 </div>

				

                <div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            
            </div>
              
        );
    }
}