import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import { getDDMMYYYY, getDDMMMYYYY,getHyphenDDMMMYYYY,
	getDDMMYYYYHHMMSS, 
	getYYYYMMDD, 
	getYYYYMMDDHHMMSS, 
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD, 
	getHyphenDDMMYYYYHHMMSS, 
	getHyphenDDMMYYYY,
    getDDMMMYYYYHHMMDefault } from '../common/utils';

import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
// import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import CSVFileValidator from 'csv-file-validator'

$.datetimepicker.setLocale('en');    
var redirectURL = require('../redirectURL');
var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");


export default class MllTrainUnLoadCheck extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			showform:'show-m',
			loadshow:'show-n',
			showrefresh:'show-n',
			sliderTranslate:'',
			showdata:this.displayData,
			modules: AllModules,
        	alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            show1: false,
            basicTitle1:'',
            basicType1:"default",
			contentString:"testing",
			mwidth:"col-xl-12 col-lg-12",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
				//editable: true,
				resizable: true
			},
			rowData: [],
            frameworkComponents: {
            },
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
            context: { componentParent: this },
            rowClassRules: {
				"boxbgblue": "data.to_be_approved == 1"
			},
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			detailCellRendererParams:{},
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:200,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,
            pagetitle:"MLL Train Load Check Sheet",
            sliderRouteTranslate:'',
            file:'',
            csvcontent:[],
            startDate:"",
            endDate:"",
            defaultsdate:"",
            defaultedate:"",
            headerHeight:80,
            formatno:"MLL/AOB/RW/01",
            date_of_placement:"",
            loading_station_name:"",
            sourceplants:["NSK"],
            destplants:["NSK"]
            
        };
        this.onLoadWagonData = this.onLoadWagonData.bind(this);
	}
	
    componentDidMount(){
        var sdate = moment().startOf('month').format('YYYY-MM-DD');
        //var sdate = "2020-09-23";
        var edate   = moment().endOf('month').format('YYYY-MM-DD');
        
        this.setState({
            startDate:sdate,
            endDate:edate,
            defaultsdate:sdate,
            defaultedate:edate
        })
        var mindate =  moment.parseZone().format('YYYY-MM-DD');
        var minactdate =  moment.parseZone(new Date(mindate)).subtract(1, 'days').format('YYYY-MM-DD');
       // console.log("path ", this.props.match.path)
		this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});

        var parameters = {
            startdate:sdate,
            enddate:edate
        }
        this.onLoadWagonData(parameters)

	}
    
    onLoadWagonData(parameters)
    {
		redirectURL.post("/consignments/mlltrainunloadings")
		.then((response) => {
            var records = response.data.records;
           this.setState({
                rowData:records,
           });
		})
       this.setState({
        loadshow:"show-n",
        overly:"show-n"
       })
    }
    
    
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
	}
	/*Alert Popups*/
	closeAlert = async () => {
        await this.setState({
            show: false
        });
        // await window.location.reload()
    }

	/*Alert Popups*/
	closeAlert1 = async () => {
        await this.setState({
            show1: false
        });
        //await window.location.reload()
    }
	hideAlert = () => {
        this.setState({
            alert: null
        });
	}
   
    onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
	
	};
	 onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
	 }

    render(){
        var columnwithDefs = [
			{
				headerName:"SNo",
				field:"s_no",
				width:80,
                resizable:true,
                editable:true, 
                valueGetter:function(params){
					try{
                       return (parseInt(params.node.id)+1)
                    }
                    catch(e){
                        return "";
                    }
				}
            },
    
            {
                headerName:"VIN No",
                field:"vin_no",
                width:150,
                resizable:true,
                editable:true,
                cellEditor: 'agSelectCellEditor',   
                cellEditorParams: {
                    values: this.state.fnrvins,
                }
            },
            {
                headerName:"Wagon No",
                field:"wagon_no",
                width:150,
                resizable:true,
                editable:true,
                cellEditor: 'agSelectCellEditor',   
                cellEditorParams: {
                    values: this.state.wagons,
                }   
                
            },
            {
                headerName:"Fuel Quantity As per SOP",
                field:"handover_ba_fuel_qty_sop",
                width:150,
                resizable:true,
                editable:true, 
                
            },
            
            {
                headerName:"KM reading At Station",
                field:"handover_ba_km_reading_at_station",
                width:150,
                resizable:true,
                editable:true, 
                
            },
            
            {
                headerName:"Key",
                field:"during_loading_key",
                width:150,
                resizable:true,
                editable:true   
                
            },
            {
                headerName:"Manual book",
                field:"manual_book",
                width:150,
                resizable:true,
                editable:true  
                
            },
            {
                headerName:"Tool Kit",
                field:"tool_kit",
                width:150,
                resizable:true,
                editable:true, 
                
            },
            {
                headerName:"Jack and Jack rod",
                field:"jack_rod",
                width:150,
                resizable:true,
                editable:true, 
                
            },
            {
                headerName:"Floor Mat",
                field:"floor_mat",
                width:150,
                resizable:true,
                editable:true, 
                
            },
            {
                headerName:"Audio Pannel",
                field:"audio_pannel",
                width:150,
                resizable:true,
                editable:true, 
                
            },
            {
                headerName:"Reflector",
                field:"reflector",
                width:150,
                resizable:true,
                editable:true, 
                
            },
            {
                headerName:"Other Tools",
                field:"other_tools",
                width:150,
                resizable:true,
                editable:true, 
                
            },
            {
                headerName:"Key Sealing",
                field:"key_sealing",
                width:150,
                resizable:true,
                editable:true, 
                
            },
            {
                headerName:"Other Damage Remarks",
                field:"during_loading_damge_remakers",
                width:200,
                resizable:true,
                editable:true,
                
            },
           
			
		]
       
       
        return (
            
    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
            <SweetAlert
	            show={this.state.show1}
	            type={this.state.basicType1}
	            title={this.state.basicTitle1}
	            onConfirm={this.closeAlert1}
	        >
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
                    {/* <div className="row col-xl-12 col-lg-12">
                     
                        <div className="col-xl-6 col-lg-6">
                            <div className="row">
                                <div className="col-xl-4 col-lg-4 form-group">
                                    <Datetime value={this.state.startDate} 
                                    disableCloseOnClickOutside={false} 
                                    closeOnSelect={true} 
                                    inputProps={{ placeholder: 'From Date', name: 'startDate', autoComplete:'off' }}
                                    dateFormat="YYYY-MM-DD" 
                                    name="startDate" 
                                    onChange={this.handlerStartDateTime.bind(this)} />
                                    
                                </div>

                                <div className="col-xl-4 col-lg-4 form-group">
                                    <Datetime value={this.state.endDate} 
                                    disableCloseOnClickOutside={false}
                                    closeOnSelect={true} 
                                    inputProps={{ placeholder: 'To Date', name: 'endDate', autoComplete:'off'}} 
                                    dateFormat="YYYY-MM-DD" 
                                    onChange={this.handlerEndDateTime.bind(this)} />
                                    
                                </div>
                                <div className="form-group col-xl-2 col-lg-2">
                                    <button type="button" className="btn btn-success" onClick={this.onClickFilter.bind(this)}>Submit</button>
                                </div>
                            </div>
                        </div>

                    </div> */}
            		<div className="col-xl-12 col-lg-12">
                        
		            	<div className="card">

			                <div className="card-header">
				       			<h5>
				       				<i className="icon-map cus-i"></i> <span>MLL Train Unloading Sheet  </span>
                                       <a href="/addmlltrainunloadchecksheet" className="float-right btn btn-warning float-right">Add Work Sheet </a>
                               
								</h5>
				   			</div>
				   			<div className="card-body row">
                               
                                <div className={"col-xl-12 col-lg-12 "}>
                                    <div id="myGrid" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnwithDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            headerHeight={this.state.headerHeight}
                                            frameworkComponents={this.state.frameworkComponents}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
											detailCellRendererParams={this.state.detailCellRendererParams}
											masterDetail={true}
                                        />
                                    </div>
                                </div>
                              
                            </div>
				   		</div>	
				   	</div>
				 </div>

			
            </div>
              
        );
    }
}
