/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import ReactDOM from "react-dom";
import SweetAlert from "react-bootstrap-sweetalert";

import Select from "react-select";
import _ from "lodash";

import MarkerClusterer from "@google/markerclusterer";
import Modal from "react-responsive-modal";
import axios from "axios";
import { AgGridReact } from "@ag-grid-community/react";
import { AllModules } from "@ag-grid-enterprise/all-modules";
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import CustomLoadingCellRenderer from "./customLoadingCellRenderer";
import {
    getDDMMYYYY,
    getYYYYMMDD,
    getDDMMYYYYHHMMSS,
    getHyphenDDMMMYYYYHHMM,
    getHyphenDDMMMYYYY,
} from "../common/utils";
import "react-vertical-timeline-component/style.min.css";
import DrawMap from "../common/drawmap";
import ConsignmentActions from "../manage/ConsignmentActionsComponent";
import ParkingoutCarrier from "./parkingcarrierbtn";
import $ from "jquery";
// import "jquery-ui/ui/core";
import "jquery-ui/ui/widgets/datepicker";
var infoBox = require("../common/google-infowindow");
var googleAnalytics = require("../common/googleanalytics");

var redirectURL = require("../redirectURL");
var moment = require("moment");

var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);

var marker;
var markers = [];
var circle;
var selectlat;
var selectlng;
var allcords = [];
var map;
var rad = function (x) {
    return (x * Math.PI) / 180;
};
var rlatitude;
var rlongitude;

var fencingLength = 0;
var geoFencingArea;

const decipher = (salt) => {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const applySaltToChar = (code) =>
        textToChars(salt).reduce((a, b) => a ^ b, code);
    return (encoded) =>
        encoded
            .match(/.{1,2}/g)
            .map((hex) => parseInt(hex, 16))
            .map(applySaltToChar)
            .map((charCode) => String.fromCharCode(charCode))
            .join("");
};
var decode = decipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

const cipher = (salt) => {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = (code) =>
        textToChars(salt).reduce((a, b) => a ^ b, code);
    try {
        return (text) =>
            text
                .split("")
                .map(textToChars)
                .map(applySaltToChar)
                .map(byteHex)
                .join("");
        // eslint-disable-next-line no-unreachable
    } catch (e) { }
};
var encode = cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

export default class UsersList extends Component {
    state = {
        pageTitle: "",
        eventGridAction: "gridAction",
        eventCounterAction: "counterAction",
        eventFormAction: "formAction",
        uploadDivWidth: "0%",
        sliderTranslate: "",
        sliderRouteTranslate: "",
        tabsliderTranslate: "",
        loadshow: "show-n",
        showDiv: "show-n",
        alertshow: "fade",
        alertmg: "show-n",
        alerterrmg: "show-n",
        overly: "show-n",
        alerterrshow: "fade",
        alert: null,
        show: false,
        basicTitle: "",
        basicType: "default",
        show1: false,
        basicTitle1: "",
        basicType1: "default",
        modules: AllModules,
        sidebarSubHeader: "",
        defTransitCoords: "",
        defaultColDef: {
            sortable: true,
            filter: true,
            resizable: true,
        },
        rowData: [],

        rowModelhighchartoptionsType: "enterprise",
        rowGroupPanelShow: "always",
        overlayNoRowsTemplate: "",
        frameworkComponents: {
            consignmentActions: ConsignmentActions,
            customLoadingCellRenderer: CustomLoadingCellRenderer,
        },

        animateRows: true,
        debug: true,
        showToolPanel: false,
        uppressAggFuncInHeader: true,

        childconfs: [],
        childrow: [],
        rowModelType: "serverSide",
        paginationPageSize: 50,
        cacheBlockSize: 10,
        maxBlocksInCache: 1,
        statusBar: {
            statusPanels: [
                {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left",
                },
                {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center",
                },
                { statusPanel: "agFilteredRowCountComponent" },
                { statusPanel: "agSelectedRowCountComponent" },
                { statusPanel: "agAggregationComponent" },
            ],
        },
        sideBar: {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel",
                },
            ],
        },
        detailCellRendererParams: {},
        rowClassRules: {
            // "highlitRow": "data.transit_delay == 1",
            // "green":"data.status >= 3"
        },
        startDate: "",
        endDate: "",
        mapinfo: "",
        defaultsdate: "",
        defaultedate: "",
        withdrawmodal: false,
        parkingyard: "show-m",
        truckinfo: "show-n",
        pact: "btn-warning",
        tact: "btn-defaultc",
        truckslist: [],
        truck_no: { value: "", label: "Select Truck" },
        dept_code: "",
        transporters: [],
        transporter_code: { value: "", label: "Select Transporter" },
        struckslist: [],
        struck_no: { value: "", label: "Select Truck" },
        radius: "5000",
        rlatitude: "",
        rlongitude: "",
        latitude: "",
        longitude: "",
        defaultradius: 0,
        coordinates: [],
        alltrucks: [],
        search_truck_no: "",
        search_transporter_name: "",
        search_gps_provider: "",
        search_speed: "",
        search_last_packet_time: "",
        search_gps_status: "",
        search_address: "",
        search_city: "",
        search_state: "",
        search_carrier_reported: "",
        reporttruck: 0,
        plants: [],
        plant_code: { value: "", label: "Select Parking Yard" },
        withdrawdata: "",
        withdraw_reason: "",
        gpsplant: "",
        gpsradius: 15,
        mwidth: "col-xl-5 col-lg-5",
        rowData1: [],
        nsearch: "show-n",
        selectedTabLat: "",
        loginplant: "",
        filteredData: [],
        consignment_code: '',

        showTrucksList: "show-n",
        manual_parking_out: "",

        parkingdata: "",
        slideerParkingOut: "",
    };



    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction =
                    googleAnalytics.page.actionType.gridAction +
                    " - " +
                    window.location.pathname;
                let eventCounterAction =
                    googleAnalytics.page.actionType.counterAction +
                    " - " +
                    window.location.pathname;
                let eventFormAction =
                    googleAnalytics.page.actionType.formAction +
                    " - " +
                    window.location.pathname;
                this.setState({
                    eventGridAction: eventGridAction,
                    eventCounterAction: eventCounterAction,
                    eventFormAction: eventFormAction,
                });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    };

    componentDidMount() {
        loadDateTimeScript();
        loadDatepicker();
        // var loguser = decode(localStorage.getItem("m"));
        // console.log("LOGUSER ", JSON.parse(loguser));
        // var loginplant = "";
        // try {
        //     var luser = JSON.parse(loguser);
        //     loginplant = luser.is_plant_code;
        //     // console.log("logplant", loginplant)
        //     this.setState({
        //         loginplant: loginplant,
        //         // consigner:[{"value":luser.is_plant_code,"label":luser.is_plant_name}]
        //     });
        // } catch (e) { }

        var sdate = moment
            .parseZone()
            .subtract(5, "days")
            .format("YYYY-MM-DD");
        var edate = moment.parseZone().format("YYYY-MM-DD");
        //console.log("Props ", this.props.match)
        // this.logPageView(); // Log page view to GA
        var urlpath;
        var hText = "(All)";
        this.setState({
            loadshow: "show-m",
        });
        // if (this.props.match.path == "/overspeedreport") {
        //     urlpath = "/dashboard/getOverspeedTriggers";
        //     var urlpath1 = "/dashboard/sndparkingslist";
        //     var urlpath2 = "/dashboard/snddeptplants";
        //     dpt = "SNDG";
        //     hText = "( Sales and Dispatch )";
        // } else if (this.props.match.path == "/prtparkingreport") {
        //     urlpath = "/dashboard/prtparkingreport";
        //     urlpath1 = "/dashboard/prtparkingslist";
        //     urlpath2 = "/dashboard/prtdeptplants";
        //     dpt = "LOG-PRT";
        //     hText = "( Spare Parts )";
        // } else {
        //     urlpath = "/dashboard/parkingreports";
        //     urlpath1 = "/dashboard/parkingslist";
        //     dpt = "all";
        // }
        // let pageTitle = "OverSpeed Report " + hText + "";
        let pageTitle = "Users List";

        let dpt = localStorage.getItem("dept_code")
        this.setState({
            dept_code: dpt,
            pageTitle: pageTitle,
            startDate: sdate,
            endDate: edate,
        });
        // console.log("deptC-de",dpt)
        var reqparams = {
            dept_code: dpt,
        };
        this.onLoadData(reqparams);
    }

    onLoadData = (reqparams) => {
    

        redirectURL.post("/dashboard/userslist", reqparams).then((response) => {
            var records = response.data
            // console.log("recordsarr", recordsarr);
            this.setState({
                rowData: records,
                
                loadshow: "show-n",
                overly: "show-n",
            });
        })
            .catch(function (error) {
                console.log(error);
            });
    };

    

    onGridReady = (params) => {
        this.gridApi = params.api;
        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };

    onGridState = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    };

    onClickHideAll() {
        this.setState({
            loadshow: "show-n",
            overly: "show-n",
            sliderRouteTranslate: "",
            tabsliderTranslate: "",
            slideAddManualForm: "",
            slideerParkingOut: "",
        });
    }
    closeAlert = async () => {
        await this.setState({
            show: false,
        });
        //await window.location.reload();
    };

    closeAlert1 = () => {
        this.setState({
            show1: false,
        });
    };
    handlerStartDateTime = (event, currentDate, selectedDate) => {
        //console.log("event ", event)
        //var d = new Date(event._d);
        var d = new Date(moment(event).format("YYYY-MM-DD"));
        //console.log("d ", d)
        var startdate =
            d.getFullYear() +
            "-" +
            (d.getMonth() + 1) +
            "-" +
            (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());
        // console.log("startdate ", startdate)
        this.setState({
            startDate: startdate,
        });
        //let value = event.target.value;
        //this.setState({'startDate':value});
    };

    handlerEndDateTime = (event, currentDate, selectedDate) => {
        //var d = new Date(event._d);
        var d = new Date(moment(event).format("YYYY-MM-DD"));
        // var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate())
        var enddate =
            d.getFullYear() +
            "-" +
            (d.getMonth() + 1) +
            "-" +
            (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

        this.setState({
            endDate: enddate,
        });
        //let value = event.target.value;
        //this.setState({'startDate':value});
    };
    formHandler = (event) => {
        event.preventDefault();
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pageTitle;
            let eventOptions = {
                category: pageTitle,
                action: this.state.eventFormAction,
                label: googleAnalytics.page.action.formSubmittedGetData,
            };
            googleAnalytics.logEvent(eventOptions);
        }
        this.setState({
            loadshow: "show-m",
        });
        //console.log("code ", this.state.dept_code)
        if (this.state.startDate != "" && this.state.endDate != "") {
            // var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
            var sdate = $("#startDate").val();
            var edate = $("#endDate").val();
            if (this.state.truck_no.value != "") {
                var truck_no = this.state.truck_no.value;
            } else {
                truck_no = "";
            }
            // if (this.state.geofence_name.value != "") {
            //     var geofence_name = this.state.geofence_name.value;
            // } else {
            //     geofence_name = "";
            // }
            var parameters = {
                dept_code: encode(this.state.dept_code),
                startDate: sdate,
                endDate: edate,
                truck_no: truck_no,
            };
            // console.log("Params ", parameters);
            this.onLoadFilterData(parameters);
        } else {
            this.setState({
                show1: true,
                basicTitle1: "Start date and end Date should  not be empty",
                basicType1: "danger",
            });
        }
    };

    handlerForm = (event) => {
        // console.log("Select", event.target.value);
        let name = event.target.name;
        let value = event.target.value;
        this.setState({ [name]: value });
    };
    onCloseUploadDiv = () => {
        this.setState({
            uploadDivWidth: "0%",
            sliderRouteTranslate: "",
            tabsliderTranslate: "",
            slideerParkingOut: "",
            showDiv: "show-n",
            overly: "show-n",
        });
    };
    onCloseRouteDiv = () => {
        this.setState({
            uploadDivWidth: "0%",
            sliderRouteTranslate: "",
            slideAddManualForm: "",
            slideerParkingOut: "",
            showDiv: "show-n",
            overly: "show-n",
        });
    };
    onCloseModal = () => {
        this.setState({ withdrawmodal: false });
    };

    onChangeTruckItem = (truck_no) => {
        this.setState({ truck_no },
            // () => console.log(`Name  Option selected:`, this.state.truck_no)
        );

        this.setState({
            errorMessage: "",
            successMessage: "",
        });
    }


    onChangeSearchTruckItem(struck_no) {
        this.setState({ struck_no },
            // () => console.log(`Name  Option selected:`, this.state.struck_no)
        );

        this.setState({
            errorMessage: "",
            successMessage: "",
        });
    }


    onClickTab(params) {
        if (params == "parking") {
            this.setState({
                pact: "btn-warning",
                tact: "btn-defaultc",
                parkingyard: "show-m",
                truckinfo: "show-n",
            });
        }
        if (params == "truck") {
            this.setState({
                pact: "btn-defaultc",
                tact: "btn-warning",
                parkingyard: "show-n",
                truckinfo: "show-m",
            });
        }
    }

    onShowInfo = (e) => {
        //	console.log(JSON.parse(e.target.id));
        this.setState({
            contentString: "Here Success",
        });
        var ourMarker = e.target.id;
        window.google.maps.event.trigger(ourMarker, "click");
        var infowindow = new window.google.maps.InfoWindow({
            content: "Here Success",
        });
        //	infowindow.open(map, marker);
    };
    changeHandler = (event) => {
        //console.log("Select", event.target.value);
        let name = event.target.name;
        let value = event.target.value;
        this.setState({ [name]: value });
    };
    onRowClicked = (e) => {
        if(e.colDef.field == "activate_user")
        {
            if(e.data.verified == 0 || e.data.login_attempts >= 5)
            {
                var baseTitle = "Account successfully activated";
                var params = {
                    email : e.data.email,
                    verified : 1,
                    login_attempts : 0,
                }
            }
            else
            {
                var baseTitle = "Account successfully Deactivated";
                var params = {
                    email : e.data.email,
                    verified : 0,
                    login_attempts : 0,
                }
            }            
            redirectURL.post("/dashboard/activateuser", params).then((response) => {
                this.setState({
                    show :true,
                    basicTitle: baseTitle,
                    basicType: 'success',
                    loadshow: "show-n",
                    overly: "show-n",
                });
                let dpt = localStorage.getItem("dept_code");
                // console.log("deptC-de",dpt)
                var reqparams = {
                    dept_code: dpt,
                };
                this.onLoadData(reqparams);
            })
        }
    }
    render() {
        var listData = [];
        try {
            this.state.filteredData.map(function (e) {
                listData.push(<li className="dropdown-truck-no">{e}</li>);
            });
        } catch (e) {
            console.log(e);
        }

        const modalStyles = {
            width: "600px !important",
        };

        const { show, basicType, basicTitle, alert, pageTitle, parkingyard, truck_no, truckslist, startDate, endDate, modules, defaultColDef, rowData, autoGroupColumnDef, statusBar, sideBar, paginationPageSize, frameworkComponents, detailCellRendererParams, rowClassRules, overlayNoRowsTemplate, sliderRouteTranslate, editType } = this.state;
        const columnwithDefs = [
            {
                headerName: "Firstname",
                field: "firstname",
                width: 140,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Lastname",
                field: "lastname",
                width: 140,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Email",
                field: "email",
                width: 200,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Password",
                field: "password_txt",
                width: 140,
                filter: true,
                resizable: true,
            },
           
            {
                headerName: "Department",
                field: "dept_code",
                width: 120,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Status",
                field: "verified",
                width: 100,
                filter: true,
                resizable: true,
                valueGetter:function(params){
                    if(params.data.verified == 1)
                    {
                        return "Active"
                    }
                    else if(params.data.verified == 0)
                    {
                        return "Inactive"
                    }
                    else{
                         return "N/A"
                    }                   
            }
            },
            {
                headerName: "Failed Login Attempts",
                field: "login_attempts",
                width: 160,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Action",
                field: "activate_user",
                width: 160,
                cellRenderer: function (params) {
                    if(params.data.verified == 0 || params.data.login_attempts >= 5)
                    {
                        var htmloption = '<button class="btn btn-primary" style="padding-top:0px">Activate</button>';
                        return htmloption;
                    }
                    else
                    {
                        var htmloption = '<button class="btn btn-danger" style="padding-top:0px">Deactivate</button>';
                        return htmloption; 
                    }
                },
                filter: false, resizable: true,


            }
            
        ];
        const dStyles = {
            width: this.props.width,
            height: "500px",
            marginLeft: this.props.marginLeft,
        };
        var showonlysnd = true;
        var showonlyprt = true;
        var hidecol = true;


        return (
            <div className="container-fluid">
                <SweetAlert
                    show={show}
                    type={basicType}
                    title={basicTitle}
                    onConfirm={this.closeAlert}
                ></SweetAlert>
                {alert}
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i>{" "}
                                    <span>{pageTitle} </span>
                                </h5>
                            </div>
                            <div className="card-body">
                                <div
                                    className={
                                        "row col-xl-12 col-lg-12 " + parkingyard
                                    }
                                >                                    
                                </div>
                                <div
                                    id="myGrid"
                                    style={{ height: "580px", width: "100%" }}
                                    className={"ag-theme-balham " + parkingyard}
                                >
                                    <AgGridReact
                                        modules={modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={defaultColDef}
                                        rowData={rowData}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        autoGroupColumnDef={autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={statusBar}
                                        sideBar={sideBar}
                                        paginationPageSize={paginationPageSize}
                                        pagination={true}
                                        frameworkComponents={frameworkComponents}
                                        detailCellRendererParams={
                                            detailCellRendererParams
                                        }
                                        //editType={editType}
                                        stopEditingWhenGridLosesFocus={true}
                                        //floatingFilter={true}
                                        //enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        rowClassRules={rowClassRules}
                                        onCellClicked={this.onRowClicked.bind(this)}
                                        gridOptions={{
                                            context: { componentParent: this },
                                        }}
                                        masterDetail={true}
                                        overlayNoRowsTemplate={overlayNoRowsTemplate}
                                    />
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>

                {sliderRouteTranslate != "" ? (
                    <div
                        className={"slide-r " + sliderRouteTranslate}
                        style={{ overflow: "auto" }}
                    >
                        <div className="slide-r-title">
                            <h4>
                                View Route
                                <span
                                    className="float-right closebtn"
                                    style={{ marginRight: "25px" }}
                                    onClick={this.onCloseRouteDiv.bind(this)}
                                >
                                    X
                                </span>
                            </h4>
                        </div>
                        <div className="slide-r-body" style={{ position: "relative" }}>
                            <div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
                                <DrawMap
                                    mapFor={"geofence"}
                                    truckno={this.state.maptruckno}
                                    mapinfo={this.state.mapinfo}
                                    defTransitCoords={this.state.defTransitCoords}
                                />
                                <div className="col-xl-12 col-lg-12 n-p-0">
                                    <div className="crm-numbers pb-0">
                                        <h3 className="subH">{this.state.sidebarSubHeader}</h3>
                                        <div className="col-xl-12 col-lg-12 row">
                                            <div className="col route-block">
                                                <label className="sidebar-label">Truck No</label>
                                                <div>{this.state.routeTruck.truck_no}</div>
                                            </div>
                                            {/* <div className="col route-block">
												<label className="sidebar-label">Transporter</label>
												<div>{(!isEmpty(maptransporter))?maptransporter:""}</div>
											</div> */}
                                            <div className="col route-block">
                                                <label className="sidebar-label">Start Time</label>
                                                <div>
                                                    {this.state.routeTruck.startTime != ""
                                                        ? getDDMMYYYYHHMMSS(this.state.routeTruck.startTime)
                                                        : "NA"}
                                                </div>
                                            </div>
                                            <div className="col route-block">
                                                <label className="sidebar-label">End Time</label>
                                                <div>
                                                    {this.state.routeTruck.endTime != ""
                                                        ? getDDMMYYYYHHMMSS(this.state.routeTruck.endTime)
                                                        : "NA"}
                                                </div>
                                            </div>

                                            <div className="col route-block">
                                                <label className="sidebar-label">Distance</label>
                                                <div>
                                                    {!isEmpty(this.state.mapinfo.route_details)
                                                        ? Math.round(
                                                            this.state.mapinfo.route_details.distance / 1000
                                                        )
                                                        : "0"}{" "}
                                                    Kms
                                                </div>
                                            </div>
                                            {/*<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.start_time):"-"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.end_time):"-"}</div>
											</div>
										*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )}

                <div
                    className={"overlay-part " + this.state.overly}
                    onClick={this.onClickHideAll.bind(this)}
                ></div>
                <div className={"dataLoadpage " + this.state.loadshow}></div>
                <div className={"dataLoadpageimg " + this.state.loadshow}>
                    <div class="loader-box">
                        <div class="loader-box">
                            <div class="rotate dashed colored"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function loadScript(url) {
    var index = window.document.getElementsByTagName("script")[0];
    var script = window.document.createElement("script");
    script.src = url;
    script.async = true;
    script.defer = true;
    index.parentNode.insertBefore(script, index);
}

function getPolygonCoords() {
    var len = geoFencingArea.getPath().getLength();
    // console.log(len);
    var htmlStr = "";
    var coordinates = [];
    for (var i = 0; i < len; i++) {
        // var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
        // coordinates.push()
        var strlatlng = geoFencingArea
            .getPath()
            .getAt(i)
            .toUrlValue(5);
        var spliting = strlatlng.split(",");
        var latlngd = {
            lat: parseFloat(spliting[0]),
            lng: parseFloat(spliting[1]),
        };
        coordinates.push(latlngd);
        // htmlStr += "new google.maps.LatLng(" + geoFencingArea.getPath().getAt(i).toUrlValue(5) + "), ";
        //Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
        //htmlStr += "" + geoFencingArea.getPath().getAt(i).toUrlValue(5);
    }
    // console.log("htmlStr", coordinates);
}
function ConvertSeconds(n) {
    var days = Math.round(parseInt(n / (24 * 3600)));

    n = n % (24 * 3600);
    var hours = Math.round(parseInt(n / 3600));

    n %= 3600;
    var minutes = Math.round(n / 60);

    n %= 60;
    var seconds = Math.round(n);

    return (
        days + " Day(s) " + hours + " Hrs " + minutes + " mins " + seconds + " secs"
    );
}

function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
    }
    return true;
}
function timeConvert(n) {
    var num = n;
    var hours = num / 3600;
    var rhours = parseInt(hours);
    var minutes = (num - rhours * 3600) / 60;
    var rminutes = Math.round(minutes);
    return rhours + " hour(s) and " + rminutes + " minute(s).";
}
function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}
function loadDatepicker() {
    $(".datepicker").datepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
    });
}

window.onload = function () {
    var today = new Date();
    var time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    $("#manual_reported_on").datetimepicker({
        mask: "39-19-9999 29:59",
        format: "d-m-Y H:i",
        onShow: false,
        step: 5,
        minTime: time,
    });
    $("#manual_parking_out").datetimepicker({
        mask: "39-19-9999 29:59",
        format: "d-m-Y H:i",
        onShow: false,
        step: 5,
        minTime: time,
    });

    $(".styles_modal__gNwvD").css({ width: "450px" });
};
document.addEventListener("turbolinks:before-render", () => {
    loadDateTimeScript();
});

function loadDateTimeScript() {
    var today = new Date();
    var time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    $("#manual_reported_on").datetimepicker({
        mask: "39-19-9999 29:59",
        format: "d-m-Y H:i",
        onShow: false,
        step: 5,
        minTime: time,
    });
    $("#manual_parking_out").datetimepicker({
        mask: "39-19-9999 29:59",
        format: "d-m-Y H:i",
        onShow: false,
        step: 5,
        minTime: time,
    });

    var index = window.document.getElementsByTagName("script")[1];
    var script = window.document.createElement("script");
    script.src =
        "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js";
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true;
    script.defer = true;
    index.parentNode.insertBefore(script, index);
}

function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor((seconds % (3600 * 24)) / 3600);
    var m = Math.floor((seconds % 3600) / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}

$(document).ready(function () {
    $(document).on("click", ".dropdown-truck-no", function () {
        var vals = $(this).text();
        $("#manual_truck").val(vals);
        $(".trucks-dropdown").removeClass("show-m");
        $(".trucks-dropdown").addClass("show-n");
    });

    $("body").on("click", function (e) {
        var container = $("#manual_truck");
        if (!container.is(e.target)) {
            try {
                if (
                    $(".trucks-dropdown")
                        .attr("class")
                        .includes("show-m") ||
                    $(".trucks-dropdown").attr("class") == "trucks-dropdown"
                ) {
                    $(".trucks-dropdown").removeClass("show-m");
                    $(".trucks-dropdown").addClass("show-n");
                }
            } catch (e) { }
        }
    });
});

function secondsToHM(seconds) {
    seconds = Number(seconds);
    // var d = Math.floor(seconds / (3600*24));
    // var h = Math.floor(seconds % (3600*24) / 3600);
    // var m = Math.floor(seconds % 3600 / 60);
    // var s = Math.floor(seconds % 60);

    // var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    // var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    // var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    // return dDisplay + hDisplay + mDisplay;

    var hh = Math.floor((seconds / 3600));
    var hrr = Math.floor(seconds - (hh * 3600));
    var mm = Math.floor(hrr / 60)
    if (mm < 10) {
        var mins = "0" + mm;
    }
    else {
        mins = mm;
    }

    if (hh < 10) {
        var hrs = "0" + hh;
    }
    else {
        hrs = hh;
    }
    return hrs + ":" + mins;
}