import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import CountUp from 'react-countup';


import _ from "lodash";

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
//import Grid from '../layouts/gridnosidebarComponent';
import Counters from './containercountersComponent';
import CustomLoadingCellRenderer from "../layouts/customLoadingCellRenderer";
import ConsignmentActions from '../manage/routemapComponent';
import ConsignmentNoActions from '../manage/routemapNoComponent';
import 'react-vertical-timeline-component/style.min.css';
import { getDDMMYYYY, getDDMMYYYYHHMMSS,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY } from '../common/utils'; 
import Breadcrumb from '../common/breadcrumb.component';

var moment = require('moment');
 
var redirectURL = require('../redirectURL');

var infoBox = require('../common/google-infowindow');

const fs = require('fs');
var googleAnalytics = require("../common/googleanalytics");

 
var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749','#F73E3E', '#B7B3B3' ];
var selectedColor;
var colorButtons = {};
var fencingLength=0;
var mapZoom=12;
var coordinates=[];
var geoFencingArea;

export default class DashboardContainer extends Component {

	constructor(props){
		super(props);
		this.state={
			pageTitle: "Container Dashboard",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			uploadDivWidth:'0%',
			sliderTranslate:'',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			timelineinfo:'',
			consignment_code:'',
			mapinfo:'',
			loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			alerterrshow:'fade',
			overly:'show-n',
			alert:null,
            show: false,
            basicTitle:'',
			basicType:"default",
			modules: AllModules,
			filterstatess:{},
      	    columnsdatas:{},
      	    rowgroupdcols:[],
			valuecolumns:[],
			transits:[],
			transitscnt:0,
      	    pivotmodeis:false,
			pivotcols:[],
			defTransitCoords:'',
			arrivingtoday:[],
			arrivingtodaycnt:0,
			nextdays:[],
			nextdayscnt:0,
			expectedoutport:[],
			overdueport:[],
			boedates:[],
			totalrowdatacnt:0,
			totalrecordscnt:0,
			icdarrivetoday:[],
			icdexpectedarrive:[],
			icdicdexpecteddepart:[],
			totalicdrecordscnt:0,
			msilexpectedarrive:[],
			msiloverdue:[],
			totalmsilrecordscnt:0,
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true,
				

      	    },
      	    rowData: [],
			headerHeight: 40,
			groupHeaderHeight:60,
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
			  frameworkComponents: {
                customLoadingCellRenderer: CustomLoadingCellRenderer,
                consignmentActions:ConsignmentActions,
				consignmentNoActions:ConsignmentNoActions
			},

      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			suppressAggFuncInHeader: true,
				
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:100,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			rowClassRules: {
				"highlitRow": "data.transit_delay == 1",
				"highlitRow": "data.sea_transit_delay == 1",
				"cellStyleGrid":"columnApi.columnController.columnDefs[0].headerName == 'High Sea Details'"
            },
            
            containerCounter:'',
			istabactive:'highseas',
			
			columnwithDefs : []
			
		}
	
		this.onShowRouteMap = this.onShowRouteMap.bind(this);
		//this.onTabClick = this.onTabClick.bind(this);
	}

	componentDidMount(){
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
		 /*Consignments List*/	
        redirectURL.post('/imports/containerCounters')
        .then(async (response) => {
            if(response.data.length > 0)
            {
                //console.log("Counters ",response.data);
                var highseas=0;
                var inport=0;
                var icd=0;
                var msil=0;
                await response.data.map((item) => {
                    if(item._id == "High Seas")
                    {
                        highseas +=item.count
                    }
                    if(item._id == "Port In" || item._id == "Arrived at Indian Port")
                    {
                        inport +=item.count
                    }
                    if(item._id == "In ICD Ghari")
                    {
                        icd +=item.count
                    }
                    if(item._id == "In Transit to MSIL")
                    {
                        msil +=item.count
                    }
                })
                var counterdata = {
                    highseas:highseas,
                    inport:inport,
                    icd:icd,
                    msil:msil,
                }
                this.setState({
                    containerCounter:counterdata
                })
               
            }
		})
		this.onTabClick(1);
      
	};
	logTabClickGA = (tabValue) => {
		let statusTexts = {
			"1": "High Seas",
			"2": "In Port",
			"3": "In ICD Garhi",
			"4": "In Transit to MSIL",
		}
		
		if (googleAnalytics.page.enableGA) {
			//let statusText = this.getStatusText(params);
			let eventOptions = {
				"category": this.state.pageTitle,
				"action": this.state.eventCounterAction,
				"label": statusTexts[tabValue],
			}
			googleAnalytics.logEvent(eventOptions);
		}
		
	}
	onTabClick = (tabvalue) =>{
       // tabvalue.preventDefault()
		console.log("IN onTabClick, tabvalue = ", tabvalue);
		this.logTabClickGA(tabvalue); // log click on tab to GA
		this.setState({
			loadshow:'show-m'
		});
        var status = '';
        if(tabvalue == 1)
        {
            status=["High Seas"];
            this.setState({
                istabactive:'highseas'
            });
        }

        if(tabvalue == 2)
        {
            status=["Arrived at Indian Port", "Port In"];
            this.setState({
                istabactive:'inport'
            });
        }

        if(tabvalue == 3)
        {
            status=["In ICD Ghari"];
            this.setState({
                istabactive:'icd'
            });
        }
        if(tabvalue == 4)
        {
            status=["In Transit to MSIL"];
            this.setState({
                istabactive:'msil'
            });
        }
        
        var sdata = {
            last_status:status
        }
	    redirectURL.post('/imports/consignmentsByStatus',sdata)
		    .then((response) => {
				var records = response.data;
				//console.log("IN componentDidMount records = ", records);

				/*High Seas Columns */
				if(tabvalue == 1)
				{
					var columnwithDefs = [
						{
							headerName: "Actions",
							//headerClass:"cellstylegrid",
							field: "map_available",
							width: 120,
							//pinned: 'left',
							
							cellRendererSelector:function(params){
								if(params.data.hasOwnProperty('map_available'))
								{
									if (params.data.map_available == 1)
									{
										return { component: 'consignmentActions'}
									}
									else
									{
										return { component: 'consignmentNoActions'}	
									}
								}
								else{
									return { component: 'consignmentNoActions'}
								}
							},
							filter: false,resizable: true,
							
							//cellClass: function(params) { return 'cellStyleGrid'; }
						},
					
						{
							headerName: "Container No",
							//headerClass:"cellstylegrid",
							field: "container_no",
							width: 150,
							//pinned: 'left',
							editable:false,
							filter: "agSetColumnFilter",resizable: true,
							//cellClass:'cellstylegrid'
						},
						{ 
							headerName: "Initial ETA",
							//headerClass:"cellstylegrid",
							field: "sea_initial_transit_eta",
							width: 180,
							//pinned: 'left',
							editable:false,
							filter: "agSetColumnFilter",resizable: true,
							//cellClass:'cellstylegrid',
							valueGetter:function(params){
								//console.log("params ", params);
								if (params.data.sea_initial_transit_eta != null)
								{
									return getHyphenDDMMMYYYY(params.data.sea_initial_transit_eta);
									//return params.data.msil_initial_transit_eta;
								}
								else
								{
									return "NA"
								}
			
							},
							//filter: "agDateColumnFilter",
							comparator: dateComparator1,
							// filterParams: {
							// 	browserDatePicker: true,
							// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
							// 	// provide comparator function
							// 	comparator: function(filteredDate,cellValue,secondDate) {
							// 		cellValue = cellValue.replace(/\//g,"-")
							// 		if(cellValue != "" && cellValue != " "){
							// 			cellValue = cellValue.split(" ")[0].split("-");
							// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
							// 			var cellDate = new Date(cellValue);
							// 			if(filteredDate.getMonth() < 10){
							// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
							// 			}
							// 			else{
							// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
							// 			}
							// 			var filteredDate = new Date(filterValue);
							// 			cellDate = cellDate.getTime();
							// 			filteredDate = filteredDate.getTime();
							// 			// console.log(cellDate,filteredDate);
							// 			// console.log(cellValue,filterValue);
							// 			if(cellDate === filteredDate)
							// 			{
							// 				return 0;
							// 			}
							// 			if(cellDate < filteredDate){
							// 				return -1;
							// 			}
							// 			if(cellDate > filteredDate)
							// 			{
							// 				return 1;
							// 			}
							// 		}
							// 	}
							// },

						},
						{
							headerName: "Revised ETA",
							//headerClass:"cellstylegrid",
							field: "sea_current_transit_eta",
							width: 180,
							//pinned: 'left',
							editable:false,
							filter: "agSetColumnFilter",
							resizable: true,
							
							valueGetter:function(params){
								//console.log("params ", params);
								if (params.data.sea_current_transit_eta != null)
								{
									return getHyphenDDMMMYYYY(params.data.sea_current_transit_eta);
								}
								else
								{
									return "NA"
								}
			
							},
							//filter: "agDateColumnFilter",
							comparator: dateComparator1,
							// filterParams: {
							// 	browserDatePicker: true,
							// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
							// 	// provide comparator function
							// 	comparator: function(filteredDate,cellValue,secondDate) {
							// 		cellValue = cellValue.replace(/\//g,"-")
							// 		if(cellValue != "" && cellValue != " "){
							// 			cellValue = cellValue.split(" ")[0].split("-");
							// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
							// 			var cellDate = new Date(cellValue);
							// 			if(filteredDate.getMonth() < 10){
							// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
							// 			}
							// 			else{
							// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
							// 			}
							// 			var filteredDate = new Date(filterValue);
							// 			cellDate = cellDate.getTime();
							// 			filteredDate = filteredDate.getTime();
							// 			// console.log(cellDate,filteredDate);
							// 			// console.log(cellValue,filterValue);
							// 			if(cellDate === filteredDate)
							// 			{
							// 				return 0;
							// 			}
							// 			if(cellDate < filteredDate){
							// 				return -1;
							// 			}
							// 			if(cellDate > filteredDate)
							// 			{
							// 				return 1;
							// 			}
							// 		}
							// 	}
							// },

						},
						{
							headerName: "PoL",
							//headerClass:"cellstylegrid",
							field: "searates_pol",
							width: 150,
							editable:false,
							filter: "agSetColumnFilter",resizable: true,
							//cellClass:'cellstylegrid',
							valueGetter:function(params){
								//console.log("params ", params);
								if (params.data.searates_pol != null)
								{
									return params.data.searates_pol;
								}
								else
								{
									return params.data.loading_port;
								}
			
							}
						},
						{
							headerName: "PoD",
							//headerClass:"cellstylegrid",
							field: "searates_pod",
							width: 120,
							editable:false,
							filter: "agSetColumnFilter",resizable: true,
							//cellClass:'cellstylegrid',
							valueGetter:function(params){
								//console.log("params ", params);
								if (params.data.searates_pod != null)
								{
									return params.data.searates_pod;
								}
								else
								{
									return params.data.discharge_port;
								}
							}
						},
						{
							headerName: "FF Code",
							//headerClass:"cellstylegrid",
							field: "freight_forwarder_code",
							width: 180,
							editable:false,
							filter: "agSetColumnFilter",resizable: true,
							//cellClass:'cellstylegrid'
						}	
					];
					var transits = [];
					var arrivingtoday = [];
					var nextdays = [];
					records.map((item) => {
						if(item.sea_transit_delay == 1)
						{
							transits.push(item)
						}
						
						var g1 = getDDMMYYYY(moment().format('DD-MM-YYYY'));
						var g2 = getDDMMYYYY(item.searates_eta_pod);
						
						var s1 = g1.split("-");
						var s2 = g2.split("-");
						//console.log("G1 ",g2)
						var gdate1 = new Date(s1[2],s1[1], s1[0]);
						var gdate2 = new Date(s2[2],s2[1], s2[0]);
						var gdate3 = gdate1.getTime() + (1*24*60*60*1000);
						var gdate4 = gdate1.getTime() + (3*24*60*60*1000);
						/*console.log("gdate1 ", gdate1.getTime())
						console.log(g1);
						console.log("gdate2 ", gdate2.getTime())
						console.log(g2);
						console.log("gdate3 ", gdate3)
						*/
						if(gdate2.getTime() == gdate1.getTime())
						{
							arrivingtoday.push(item)
						}

						if(gdate2.getTime() > gdate1.getTime() && gdate2.getTime() < gdate4)
						{
							nextdays.push(item)
						}

					});

					//console.log("arrivingtoday ", arrivingtoday)
					//console.log("nextdays ", nextdays)
					this.setState({
						rowData:records,
						columnwithDefs:columnwithDefs,
						loadshow:'show-n',
						transits:transits,
						arrivingtoday:arrivingtoday,
						nextdays:nextdays,
						transitscnt:transits.length,
						arrivingtodaycnt:arrivingtoday.length,
						nextdayscnt:nextdays.length,
						totalrowdatacnt:records.length
					});
				}
				else{

					/*IN PORT Columns */

					if(tabvalue == 2)
					{
						var columnwithDefs = [
							{
								headerName: "Actions",
								//headerClass:"cellstylegrid",
								field: "map_available",
								width: 120,
								//pinned: 'left',
								
								cellRendererSelector:function(params){
									if(params.data.hasOwnProperty('map_available'))
									{
										if (params.data.map_available == 1)
										{
											return { component: 'consignmentActions'}
										}
										else
										{
											return { component: 'consignmentNoActions'}	
										}
									}
									else{
										return { component: 'consignmentNoActions'}
									}
								},
								filter: false,resizable: true,
								// cellStyle:function(params){
								// 	return {background:"rgb(241, 251, 255)",color:"#333"}
								// }
								
								//cellClass: function(params) { return 'cellStyleGrid'; }
							},
						
							{
								headerName: "Container No",
								//headerClass:"cellstylegrid",
								field: "container_no",
								width: 150,
								//pinned: 'left',
								editable:false,
								filter: "agSetColumnFilter",resizable: true,
								//cellClass:'cellstylegrid'
							},
							{ 
								headerName: "Vessel Arrival On",
								//headerClass:"cellstylegrid",
								field: "inland_vessel_arrived_on",
								width: 180,
								//pinned: 'left',
								editable:false,
								filter: "agSetColumnFilter",resizable: true,
								//cellClass:'cellstylegrid',
								valueGetter:function(params){
									//console.log("params ", params);
									if (params.data.inland_vessel_arrived_on != null)
									{
										return getDDMMYYYY(params.data.inland_vessel_arrived_on);
										//return params.data.msil_initial_transit_eta;
									}
									else
									{
										return "NA"
									}
				
								}
							},
							{
								headerName: "SMTP Handover Date",
								//headerClass:"cellstylegrid",
								field: "inland_smtp_handover_date",
								width: 180,
								//pinned: 'left',
								editable:false,
								filter: "agSetColumnFilter",
								resizable: true,
								
								valueGetter:function(params){
									//console.log("params ", params);
									if (params.data.inland_smtp_handover_date != null)
									{
										return getDDMMYYYY(params.data.inland_smtp_handover_date);
									}
									else
									{
										return "NA"
									}
				
								}
							},
							{ 
								headerName: "Expected Port Out Today",
								//headerClass:"cellstylegrid",
								field: "inland_vessel_arrived_on",
								width: 180,
								//pinned: 'left',
								editable:false,
								filter: "agSetColumnFilter",resizable: true,
								//cellClass:'cellstylegrid',
								valueGetter:function(params){
									//console.log("params ", params);
									var g1 = getDDMMYYYY(moment().format('DD-MM-YYYY'));
									if(params.data.hasOwnProperty("inland_vessel_arrived_on"))
									{
										var g2 = getDDMMYYYY(params.data.inland_vessel_arrived_on);
									
										var s1 = g1.split("-");
										var s2 = g2.split("-");
										//console.log("G1 ",g2)
										var gdate1 = new Date(s1[2],s1[1], s1[0]);
										var gdate2 = new Date(s2[2],s2[1], s2[0]);
										var gdate3 = gdate1.getTime() + (1*24*60*60*1000);
										if(params.data.hasOwnProperty("inland_transit_mode"))
										{
											//console.log("Mode ",params.data.inland_transit_mode);
											
											if(params.data.inland_transit_mode == "RAIL")
											{																
												var gdate4 = gdate2.getTime() + (5*24*60*60*1000);										
												var returndate = new Date(gdate4);
												return getDDMMYYYY(returndate);		
											}
											if(params.data.inland_transit_mode == "ROAD")
											{			
												var gdate4 = gdate2.getTime() + (2*24*60*60*1000);										
												var returndate = new Date(gdate4);
												return getDDMMYYYY(returndate);
											}
											
										}
										else
										{						
											var gdate4 = gdate2.getTime() + (5*24*60*60*1000);
											var returndate = new Date(gdate4);
											return getDDMMYYYY(returndate);									
										}
									}
									else{
										return "NA";
									}
									

				
								}
							},
							{
								headerName: "BOE Date",
								//headerClass:"cellstylegrid",
								field: "inland_boe_date",
								width: 150,
								editable:false,
								filter: "agSetColumnFilter",resizable: true,
								//cellClass:'cellstylegrid',
								valueGetter:function(params){
									//console.log("params ", params);
									if (params.data.inland_boe_date != null)
									{
										return getDDMMYYYY(params.data.inland_boe_date);
									}
									else
									{
										return "NA";
									}
				
								}
							},
							{ 
								headerName: "Departure to GRFL",
								//headerClass:"cellstylegrid",
								field: "inland_departure_to_grfl_date",
								width: 180,
								//pinned: 'left',
								editable:false,
								filter: "agSetColumnFilter",resizable: true,
								//cellClass:'cellstylegrid',
								valueGetter:function(params){
									if(params.data.hasOwnProperty("inland_departure_to_grfl_date"))
									{
										return getDDMMYYYY(params.data.inland_departure_to_grfl_date);
									}
									else{
										return "NA";
									}									
								}
							}
						];
					}


					/*IN ICD GHARI Columns */

					if(tabvalue == 3)
					{
						var columnwithDefs = [
							{
								headerName: "Actions",
								//headerClass:"cellstylegrid",
								field: "map_available",
								width: 180,
								//pinned: 'left',
								
								cellRendererSelector:function(params){
									if(params.data.hasOwnProperty('map_available'))
									{
										if (params.data.map_available == 1)
										{
											return { component: 'consignmentActions'}
										}
										else
										{
											return { component: 'consignmentNoActions'}	
										}
									}
									else{
										return { component: 'consignmentNoActions'}
									}
								},
								filter: false,resizable: true,
								// cellStyle:function(params){
								// 	return {background:"rgb(241, 251, 255)",color:"#333"}
								// }
								
							},
						
							{
								headerName: "Container No",
								//headerClass:"cellstylegrid",
								field: "container_no",
								width: 220,
								//pinned: 'left',
								editable:false,
								filter: "agSetColumnFilter",resizable: true,
								//cellClass:'cellstylegrid'
							},
							
							{
								headerName: "GRFL Arrival",
								//headerClass:"cellstylegrid",
								field: "inland_grfl_arrival_date",
								width: 240,
								//pinned: 'left',
								editable:false,
								filter: "agSetColumnFilter",
								resizable: true,
								
								valueGetter:function(params){
									//console.log("params ", params);
									if (params.data.inland_grfl_arrival_date != null)
									{
										return getDDMMYYYY(params.data.inland_grfl_arrival_date);
									}
									else
									{
										return "NA"
									}
				
								}
							},
							{
								headerName: "COC Date",
								//headerClass:"cellstylegrid",
								field: "inland_coc_date",
								width: 220,
								editable:false,
								filter: "agSetColumnFilter",resizable: true,
								//cellClass:'cellstylegrid',
								valueGetter:function(params){
									//console.log("params ", params);
									if (params.data.inland_coc_date != null)
									{
										return getDDMMYYYY(params.data.inland_coc_date);
									}
									else
									{
										return "NA";
									}
				
								}
							},
							{
								headerName: "Handover Date",
								//headerClass:"cellstylegrid",
								field: "inland_handover_date",
								width: 220,
								editable:false,
								filter: "agSetColumnFilter",resizable: true,
								//cellClass:'cellstylegrid',
								valueGetter:function(params){
									//console.log("params ", params);
									if (params.data.inland_handover_date != null)
									{
										return getDDMMYYYY(params.data.inland_handover_date);
									}
									else
									{
										return "NA";
									}
								}
							}
						];
					}


					/*IN TRANSIT TO MSIL Columns */

					if(tabvalue == 4)
					{
						var columnwithDefs = [
							{
								headerName: "Actions",
								//headerClass:"cellstylegrid",
								field: "map_available",
								width: 180,
								//pinned: 'left',
								
								cellRendererSelector:function(params){
									if(params.data.hasOwnProperty('map_available'))
									{
										if (params.data.map_available == 1)
										{
											return { component: 'consignmentActions'}
										}
										else
										{
											return { component: 'consignmentNoActions'}	
										}
									}
									else{
										return { component: 'consignmentNoActions'}
									}
								},
								filter: false,resizable: true,
								// cellStyle:function(params){
								// 	return {background:"rgb(241, 251, 255)",color:"#333"}
								// }
								
								//cellClass: function(params) { return 'cellStyleGrid'; }
							},
						
							{
								headerName: "Container No",
								//headerClass:"cellstylegrid",
								field: "container_no",
								width: 220,
								//pinned: 'left',
								editable:false,
								filter: "agSetColumnFilter",resizable: true,
								//cellClass:'cellstylegrid'
							},
							{ 
								headerName: "Dispatch Date",
								//headerClass:"cellstylegrid",
								field: "inland_dispatch_date",
								width: 240,
								//pinned: 'left',
								editable:false,
								filter: "agSetColumnFilter",resizable: true,
								//cellClass:'cellstylegrid',
								valueGetter:function(params){
									//console.log("params ", params);
									if (params.data.hasOwnProperty("inland_dispatch_date") )
									{
										return getDDMMYYYY(params.data.inland_dispatch_date);
										//return params.data.msil_initial_transit_eta;
									}
									else
									{
										return "NA"
									}
				
								}
							},
							{
								headerName: "DO Validity",
								//headerClass:"cellstylegrid",
								field: "inland_do_validity",
								width: 220,
								//pinned: 'left',
								editable:false,
								filter: "agSetColumnFilter",
								resizable: true,
								valueGetter:function(params){
									//console.log("params ", params);
									if (params.data.hasOwnProperty("inland_do_validity") )
									{
										return getDDMMYYYY(params.data.inland_do_validity);
									}
									else
									{
										return "NA"
									}
				
								}
							},
							{
								headerName: "Empty Return Date",
								//headerClass:"cellstylegrid",
								field: "inland_empty_return_date",
								width: 220,
								editable:false,
								filter: "agSetColumnFilter",resizable: true,
								//cellClass:'cellstylegrid',
								valueGetter:function(params){
									//console.log("params ", params);
									if (params.data.hasOwnProperty("inland_empty_return_date") )
									{
										return getDDMMYYYY(params.data.inland_empty_return_date);
									}
									else
									{
										return "NA";
									}
				
								}
							},
							
						];
					}


					const containerNumbersFromImportsConsignments = records.map(obj => obj.container_no);
					//console.log("containerNumbersFromImportsConsignments = ", containerNumbersFromImportsConsignments);
					// prepare query to fetch records for matching container numbers
					let queryByContainerNumbers = {
						"container_no": { $in: containerNumbersFromImportsConsignments}
					};
					//fetch data from inlandcontainerimports
					redirectURL.post('/consignments/getinlandcontainerimportstrackingdetails', {"queryByContainerNumbers":queryByContainerNumbers})
					.then(async (inlandResponse) => {
						var inlandRecords = inlandResponse.data;
						
						//console.log("IN componentDidMount inlandRecords = ", inlandRecords);
						const inlandRecordsRewritten = inlandRecords.map(obj => {
							// prefix keys of inlandRecords to avoid any overwrite to consignment records
							Object.entries(obj).forEach(
								([key, value]) => {
									let newKey = 'inland_'+key;
										obj[newKey] = value;
										//console.log("IN componentDidMount MAPPING obj INNER = ", key, newKey, obj);
										delete(obj[key]) ; // remove original key after renaming
								}
							);

							//console.log("IN componentDidMount MAPPING obj = ", obj);
							return obj;

						});

						// Need a blank object with new keys to merge assign null values to grid columns
						// for container numbers found in importsconsignments but not found a match in inlandcontainerimports
						//console.log("inland",inlandRecordsRewritten[0])
						const inlandRecordsRewrittenBlank = Object.entries(inlandRecordsRewritten[0]).forEach(
							([key, value]) => {
								let blankObj = {};
								let newKey = 'inland_'+key;
								blankObj[newKey] = null; // create a blank object with same new keys
							}
						);
						//console.log("IN componentDidMount MAPPED inlandRecords = ", inlandRecords);
						//console.log("IN componentDidMount MAPPED inlandRecordsRewrite = ", inlandRecordsRewrite);
						// merge both results to prepare grid columns
						for (let i = 0; i < records.length; i++) {
							for (let j = 0; j < inlandRecordsRewritten.length; j++) {
								if (records[i].container_no === inlandRecordsRewritten[j].inland_container_no) {
									records[i] = Object.assign(records[i], inlandRecordsRewritten[j]);
								} else {
									// assign null values for no match
									records[i] = Object.assign(records[i], inlandRecordsRewrittenBlank);
								}
							}
						}
						//console.log("records ", records)
						var overdueport = [];
						var expectedoutport = [];
						var boedates=[];
						var icdarrivetoday=[];
						var icdexpectedarrive=[];
						var icdicdexpecteddepart=[];
						var msilexpectedarrive = [];
						var msiloverdue = [];
						await records.map((item) => {
							//console.log("ITEM ", Object.keys(item).length);
							if(tabvalue == 2)
							{
								/*Expected Out Port*/
								var g1 = getDDMMYYYY(moment().format('DD-MM-YYYY'));
								if(item.hasOwnProperty("inland_vessel_arrived_on"))
								{
									var g2 = getDDMMYYYY(item.inland_vessel_arrived_on);
								
									var s1 = g1.split("-");
									var s2 = g2.split("-");
									//console.log("G1 ",g2)
									var gdate1 = new Date(s1[2],s1[1], s1[0]);
									var gdate2 = new Date(s2[2],s2[1], s2[0]);
									var gdate3 = gdate1.getTime() + (1*24*60*60*1000);
									if(item.hasOwnProperty("inland_transit_mode"))
									{
										//console.log("Mode ",item.inland_transit_mode);
										
										if(item.inland_transit_mode == "RAIL")
										{																
											var gdate4 = gdate2.getTime() + (5*24*60*60*1000);										
											var returndate = new Date(gdate4);
											if(gdate1 == gdate4)
											{
												expectedoutport.push(item);	
											}
										}
										if(item.inland_transit_mode == "ROAD")
										{			
											var gdate4 = gdate2.getTime() + (2*24*60*60*1000);										
											var returndate = new Date(gdate4);
											if(gdate1 == gdate4)
											{
												expectedoutport.push(item);	
											}
										}
										
									}
									else
									{						
										var gdate4 = gdate2.getTime() + (5*24*60*60*1000);
										var returndate = new Date(gdate4);
										if(gdate1 == gdate4)
										{
											expectedoutport.push(item);	
										}							
									}
								}

								/*Port Overdue */
								if(item.hasOwnProperty("inland_departure_to_grfl_date"))
								{
									var g2 = getDDMMYYYY(item.inland_vessel_arrived_on);
								
									var dpt = getDDMMYYYY(item.inland_departure_to_grfl_date);
									var s1 = g1.split("-");
									var s2 = g2.split("-");
									var dpts2 = dpt.split("-");
									//console.log("G1 ",g2)
									var gdate1 = new Date(s1[2],s1[1], s1[0]);
									var gdate2 = new Date(s2[2],s2[1], s2[0]);
									var dpeture = new Date(dpts2[2],dpts2[1], dpts2[0]);
															
									var gdate4 = gdate2.getTime() + (5*24*60*60*1000);
									var returndate = new Date(gdate4);
									if(dpeture.getTime() >= gdate4)
									{
										overdueport.push(item);	
									}							
									
								}

								//console.log("expectedoutport ", expectedoutport)
								//console.log("overdueport ", overdueport)
								if(item.hasOwnProperty("inland_boe_date"))
								{
									console.log("inland_boe_date ", item.inland_boe_date)
								}
								else{
									//console.log("item ", item)
									boedates.push(item);
								}
							}
							
							/*ICD Garhi*/
							
							if(tabvalue == 3)
							{
								/*Expected Out Port*/
								var g1 = getDDMMYYYY(moment().format('DD-MM-YYYY'));
								if(item.hasOwnProperty("inland_departure_to_grfl_date"))
								{
									var g2 = getDDMMYYYY(item.inland_departure_to_grfl_date);
								
									var s1 = g1.split("-");
									var s2 = g2.split("-");
									//console.log("G1 ",g2)
									var gdate1 = new Date(s1[2],s1[1], s1[0]);
									var gdate2 = new Date(s2[2],s2[1], s2[0]);
									var gdate3 = gdate1.getTime() + (1*24*60*60*1000);
									if(item.hasOwnProperty("inland_transit_mode"))
									{
										//console.log("Mode ",item.inland_transit_mode);
										
										if(item.inland_transit_mode == "RAIL")
										{																
											var gdate4 = gdate2.getTime() + (5*24*60*60*1000);										
											if(gdate1 == gdate4)
											{
												icdexpectedarrive.push(item);	
											}
										}
										if(item.inland_transit_mode == "ROAD")
										{			
											var gdate4 = gdate2.getTime() + (3*24*60*60*1000);										
											if(gdate1 == gdate4)
											{
												icdexpectedarrive.push(item);	
											}
										}
										
									}
									else
									{						
										var gdate4 = gdate2.getTime() + (5*24*60*60*1000);
										if(gdate1 == gdate4)
										{
											icdexpectedarrive.push(item);	
										}							
									}
								}

								/*Arrival Today */
								if(item.hasOwnProperty("inland_grfl_arrival_date"))
								{
									var g2 = getDDMMYYYY(item.inland_grfl_arrival_date);
								
									var dpt = getDDMMYYYY(item.inland_grfl_arrival_date);
									var s1 = g1.split("-");
									var s2 = g2.split("-");
									var dpts2 = dpt.split("-");
									//console.log("G1 ",g2)
									var gdate1 = new Date(s1[2],s1[1], s1[0]);
									var gdate2 = new Date(s2[2],s2[1], s2[0]);
									var dpeture = new Date(dpts2[2],dpts2[1], dpts2[0]);
															
									var gdate4 = gdate2.getTime() + (5*24*60*60*1000);
									if(dpeture.getTime() == gdate1.getTime())
									{
										icdarrivetoday.push(item);	
									}							
									
								}

								/*Expected Departure */
								if(item.hasOwnProperty("inland_grfl_arrival_date"))
								{
									var g2 = getDDMMYYYY(item.inland_grfl_arrival_date);
								
									var dpt = getDDMMYYYY(item.inland_grfl_arrival_date);
									var s1 = g1.split("-");
									var s2 = g2.split("-");
									var dpts2 = dpt.split("-");
									//console.log("G1 ",g2)
									var gdate1 = new Date(s1[2],s1[1], s1[0]);
									var gdate2 = new Date(s2[2],s2[1], s2[0]);
									var dpeture = new Date(dpts2[2],dpts2[1], dpts2[0]);
															
									var gdate4 = dpeture.getTime() + (5*24*60*60*1000);
									if(dpeture.getTime() > gdate1.getTime())
									{
										icdicdexpecteddepart.push(item);
									}							
									
								}
								//console.log("expectedoutport ", expectedoutport)
								//console.log("overdueport ", overdueport)

							}


							/*Transit to MSIL*/
							
							if(tabvalue == 4)
							{
								/*Expected Dispatch*/
								var g1 = getDDMMYYYY(moment().format('DD-MM-YYYY'));
								if(item.hasOwnProperty("inland_grfl_arrival_date"))
								{
									var g2 = getDDMMYYYY(item.inland_grfl_arrival_date);
								
									var s1 = g1.split("-");
									var s2 = g2.split("-");
									//console.log("G1 ",g2)
									var gdate1 = new Date(s1[2],s1[1], s1[0]);
									var gdate2 = new Date(s2[2],s2[1], s2[0]);
									var gdate3 = gdate1.getTime() + (1*24*60*60*1000);
									if(item.hasOwnProperty("inland_transit_mode"))
									{
										//console.log("Mode ",item.inland_transit_mode);
										
										if(item.inland_transit_mode == "RAIL")
										{																
											var gdate4 = gdate2.getTime() + (4*24*60*60*1000);										
											if(gdate1 == gdate4)
											{
												msilexpectedarrive.push(item);	
											}
										}
										if(item.inland_transit_mode == "ROAD")
										{			
											var gdate4 = gdate2.getTime() + (2*24*60*60*1000);										
											if(gdate1 == gdate4)
											{
												msilexpectedarrive.push(item);	
											}
										}
										
									}
									else
									{						
										var gdate4 = gdate2.getTime() + (4*24*60*60*1000);
										if(gdate1 == gdate4)
										{
											msilexpectedarrive.push(item);	
										}							
									}
								}

								/*Overdue Dispatch */
								if(item.hasOwnProperty("inland_dispatch_date"))
								{
									var g2 = getDDMMYYYY(item.inland_dispatch_date);
								
									var dpt = getDDMMYYYY(item.inland_dispatch_date);
									var s1 = g1.split("-");
									var s2 = g2.split("-");
									var dpts2 = dpt.split("-");
									//console.log("G1 ",g2)
									var gdate1 = new Date(s1[2],s1[1], s1[0]);
									var gdate2 = new Date(s2[2],s2[1], s2[0]);
									var dpeture = new Date(dpts2[2],dpts2[1], dpts2[0]);
															
									var gdate4 = gdate2.getTime() + (5*24*60*60*1000);
									if(dpeture.getTime() > gdate1.getTime())
									{
										msiloverdue.push(item);	
									}							
									
								}

							
								//console.log("expectedoutport ", expectedoutport)
								//console.log("overdueport ", overdueport)

							}
							
						});

						//console.log("arrivingtoday ", arrivingtoday)
						//console.log("boedates ", boedates)
						if(tabvalue == 2)
						{
							this.setState({
								expectedoutport:expectedoutport,
								overdueport:overdueport,
								totalrecordscnt:records.length,
								boedates:boedates
							})
						}
						if(tabvalue == 3)
						{
							this.setState({
								icdarrivetoday:icdarrivetoday,
								icdexpectedarrive:icdexpectedarrive,
								icdicdexpecteddepart:icdicdexpecteddepart,
								totalicdrecordscnt:records.length
							});
						}
						if(tabvalue == 4)
						{
							this.setState({
								msilexpectedarrive:msilexpectedarrive,
								msiloverdue:msiloverdue,
								totalmsilrecordscnt:records.length
							});
						}
						this.setState({
							rowData:records,
							columnwithDefs:columnwithDefs,
							loadshow:'show-n',							
						});


					})
					.catch(function(error) {
						console.log(error);
					});
				}
				
            })
	}
	
	onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
	//    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	   
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
	}

	renderMap = () => {    	
		loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyBQUmk7hdajZEAYllxKhVPnUx5pXuK-j_Y&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
	}
	
	initMap = () => {
    	//console.log(this.state.allmarkers);
    	var currentwindow;
    	var jsondata = this.state.mapinfo;
    	var locations = jsondata.points;
        var arr = jsondata.route;
		//console.log("Here",arr);
		if(arr.length > 0)
		{
			var lt=arr[0].lat;
			var ln=arr[0].lng;
		}
		else{
			//console.log("defTransitCoords ", JSON.parse(this.state.defTransitCoords));
			var lt=JSON.parse(this.state.defTransitCoords).lat;
			var ln=JSON.parse(this.state.defTransitCoords).lng;
		}
    	 var mapOptions = {
    			zoom: 7,
				zoomControl: true,
				mapTypeControl: true,
				scaleControl: true,
				streetViewControl: true,
				rotateControl: true,
				fullscreenControl: true,
    	          labels:true,
    	          mapTypeControlOptions: {
    	            mapTypeIds: ['hybrid', 'roadmap'],
    	          },
    	          center: new window.google.maps.LatLng(lt,ln),
    	          mapTypeId: window.google.maps.MapTypeId.ROADMAP
    	        };
    	        
    	        var map = new window.google.maps.Map(document.getElementById('map'),
    	            mapOptions);

				var bounds = new window.google.maps.LatLngBounds();
				bounds.extend(new window.google.maps.LatLng(lt,ln));
    			 // Create our info window content
    			var currentinfowindow = null;
    	       var line=new window.google.maps.Polyline(
    	              {
    	                map:map,
    									strokeColor: '#157254',
    									strokeOpacity: 1.0,
    									strokeWeight: 2.5,
    	                		icons: [{
    	                          icon: {
    	                                  path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    	                                  strokeColor:'#ff8c52',
    	                                  fillColor:'#ff8c52',
    	                                  fillOpacity:1,
    									  strokeWeight: 2
    	                                },
    	                          repeat:'100px',
    	                          path:[]
    	                       }]
    	                 });

			if(arr.length > 0)
			{	
    		  for (let i = 0; i < arr.length; i++) {
    				var path=line.getPath().getArray();
    					
    						let latLng=new window.google.maps.LatLng(arr[i].lat,arr[i].lng);
    						path.push(latLng);
    	          			line.setPath(path);
    					

    				  
    		  
    	          //Change line color based on map type
    	        window.google.maps.event.addListener( map, 'maptypeid_changed', function() {
    	            if (map.getMapTypeId() =='hybrid' || map.getMapTypeId() =='satellite')
    	            {
    	                var line=new window.google.maps.Polyline(
    	                  {
    	                    map:map,
    	                    strokeColor: '#FFFFFF',
    	                    strokeOpacity: 1.0,
    	                    strokeWeight: 2.5,
    	                    icons: [{
    	                              icon: {
    	                                      path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    	                                      strokeColor:'#ff8c52',
    	                                      fillColor:'#ff8c52',
    	                                      fillOpacity:1,
    	                                      strokeWeight: 2
    	                                    },
    	                              repeat:'100px',
    	                              path:[]
    	                           }]
    	                     });                
    	            }
    	            else
    	            {
    	                var line=new window.google.maps.Polyline(
    	                  {
    	                    map:map,
    	                    strokeColor: '#157254',
    	                    strokeOpacity: 1.0,
    	                    strokeWeight: 2.5,
    	                    icons: [{
    	                              icon: {
    	                                      path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    	                                      strokeColor:'#ff8c52',
    	                                      fillColor:'#ff8c52',
    	                                      fillOpacity:1,
    	                                      strokeWeight: 2
    	                                    },
    	                              repeat:'100px',
    	                              path:[]
    	                           }]
    	                     });

    	            }

    	              /*for (i = 0; i < arr.length; i++) {
    	                    var path=line.getPath().getArray();
    	                    let latLng=new window.google.maps.LatLng(arr[i].lat,arr[i].lng);
    						path.push(latLng);
    	          			line.setPath(path);
    	                            //map.setCenter(latLng);
						  }
						*/
    	                            
    	             var x = map.getZoom();
    	             var c = map.getCenter();
    	             window.google.maps.event.trigger(map, 'resize');
    	             map.setZoom(x);
					 map.setCenter(c);
					 
					 var path=line.getPath().getArray();
					let latLng=new window.google.maps.LatLng(arr[i].lat,arr[i].lng);
					path.push(latLng);
					line.setPath(path);
				} );
				
				bounds.extend(new window.google.maps.LatLng(arr[i].lat,arr[i].lng));
    		  }

		  var infowindow = new window.google.maps.InfoWindow();
			var marker, l;
			  //console.log("locations.length ", locations.length)
			  var lastorigin='';
			if(locations.length > 0)
			{
				for (l = 0; l < locations.length; l++) {
					if(arr[0].lat != locations[l].coordinates.lat && arr[0].lng != locations[l].coordinates.lmg)
					{
						marker = new window.google.maps.Marker({
						position: new window.google.maps.LatLng(locations[l].coordinates.lat, locations[l].coordinates.lng),
						icon:require('../../assets/icons/boat.png'),
						map: map,
						});

						window.google.maps.event.addListener(marker, 'click', (function(marker, l) {
							return function() {

								var contentarr = []
								var headerTitle = locations[l].name+", "+locations[l].country;
								//var contentString = "<p class='maptitle'>"+"<B> "+locations[l].name+", "+locations[l].country+"</B></p>";
								var contentStringData='';
								if(locations[l].events.length > 0)
								{
									contentStringData = contentStringData+"<table class='mt-8p table table-border' style='background:#f5f5f5;border:1px solid #dee2e6'>";
									contentStringData = contentStringData + "<thead><tr><th>Date</th><th>Event</th><th>Transport Mode</th></tr></thead><tbody>";
									for(var e=0;e<locations[l].events.length;e++)
									{
										var typemode;
										if(locations[l].events[e].type == 1){
											typemode='Rail/Truck';
										}
										if(locations[l].events[e].type == 2){
											typemode='Air';
										}
										if(locations[l].events[e].type == 3){
											typemode='Sea';
										}
										if(locations[l].events[e].type == 4){
											typemode='Rail/Truck';
										}
										var d = locations[l].events[e].event_datetime_ist;
										var dat = d.split(" ");				
										
										contentStringData = contentStringData + "<tr><td class='custb'><span class='tbmap'><i class='icofont icofont-disc'></i> <span class='tdmapsp'>"+ dat[0];
										contentStringData = contentStringData + "</span></td><td>" + locations[l].events[e].description + "</span></td><td style='text-align: right'>" + typemode + "</span></td></tr>";
										
				
									}
									contentStringData = contentStringData+"</tbody></table>";
									
								}
								contentarr.push(contentStringData);
							//lastorigin=contentString;
							//console.log("1", headerTitle)
							var contentString = infoBox(marker.icon, headerTitle, contentarr, 'table')
				

							infowindow.setContent(contentString);
							currentwindow = infowindow;
							infowindow.open(map, marker);
							//console.log(marker.position.toJSON());
							//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
							}
						})(marker, l));
					}
					else{
						marker = new window.google.maps.Marker({
							position: new window.google.maps.LatLng(locations[l].coordinates.lat, locations[l].coordinates.lng),
							icon:require('../../assets/icons/start_port.png'),
							map: map,
							});
	
							window.google.maps.event.addListener(marker, 'click', (function(marker, l) {
								return function() {
									//var contentString = "<p class='maptitle'>"+"<B> "+locations[l].name+", "+locations[l].country+"</B></p>";
									var contentarr = []
									var headerTitle = locations[l].name+", "+locations[l].country;
									var contentStringData='';
									if(locations[l].events.length > 0)
									{
										contentStringData = contentStringData+"<table class='mt-8p table table-border' style='background:#f5f5f5;border:1px solid #dee2e6'>";
										contentStringData = contentStringData + "<thead><tr><th>Date</th><th>Event</th><th>Transport Mode</th></tr></thead><tbody>";
										for(var e=0;e<locations[l].events.length;e++)
										{
											var typemode;
											if(locations[l].events[e].type == 1){
												typemode='Rail/Truck';
											}
											if(locations[l].events[e].type == 2){
												typemode='Air';
											}
											if(locations[l].events[e].type == 3){
												typemode='Sea';
											}
											if(locations[l].events[e].type == 4){
												typemode='Rail/Truck';
											}
											var d = locations[l].events[e].event_datetime_ist;
											var dat = d.split(" ");											
											
											contentStringData = contentStringData + "<tr><td class='custb'><span class='tbmap'><i class='icofont icofont-disc'></i> <span class='tdmapsp'>"+ dat[0];
											contentStringData = contentStringData + "</span></td><td>" + locations[l].events[e].description + "</span></td><td style='text-align: right'>" + typemode + "</span></td></tr>";
	
										}
										contentStringData = contentStringData+"</tbody></table>";
										
									}
									contentarr.push(contentStringData);	
									//console.log("2", headerTitle)
									var contentString = infoBox(marker.icon, headerTitle, contentarr, 'table')
					
								infowindow.setContent(contentString);
								currentwindow = infowindow;
								infowindow.open(map, marker);
								//console.log(marker.position.toJSON());
								//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
								}
							})(marker, l));
					}
				}
			}
			if (jsondata.hasOwnProperty('last_known_location') == true && jsondata.last_known_location != null)
			{
		   	    marker = new window.google.maps.Marker({
					position: new window.google.maps.LatLng(jsondata.last_known_location.lat, jsondata.last_known_location.lng),
					icon:require('../../assets/icons/crane.png'),
					map: map,	
				 
			  });
			

		   window.google.maps.event.addListener(marker, 'click', (function(marker) {
		        return function() {
		        	//console.log("jsondata", jsondata);
					var contentarr = []
					var headerTitle = "Last known location";
					var contentStringData='';
					var typemode;
					if(jsondata.last_event.type == 1){
						typemode='Rail/Truck';
					}
					if(jsondata.last_event.type == 2){
						typemode='Air';
					}
					if(jsondata.last_event.type == 3){
						typemode='Sea';
					}
					if(jsondata.last_event.type == 4){
						typemode='Rail/Truck';
					}
					var d = jsondata.last_event.event_datetime_ist;
					var dat = d.split(" ");
					
					var contentStringData = contentStringData+"<table class='mt-8p table table-border' style='background:#f5f5f5;border:1px solid #dee2e6'>";
					contentStringData = contentStringData + "<thead><tr><th>Last Updated Date</th><th>Transport Mode</th></tr></thead><tbody>";
					
					contentStringData = contentStringData + "<tr><td class='custb'><span class='tbmap'><i class='icofont icofont-disc'></i> <span class='tdmapsp'>"+ jsondata.last_known_time;
					contentStringData = contentStringData + "</span></td><td style='text-align: right'>" + typemode + "</td></tr>";
					contentStringData = contentStringData+"</tbody></table>";	
					
					contentarr.push(contentStringData);	
					var contentString = infoBox(marker.icon, headerTitle, contentarr, 'table')
					

				  infowindow.setContent(contentString);
				  currentwindow = infowindow;
				  infowindow.open(map, marker);
				 // console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
				}
			})(marker));
			}
		}
		map.fitBounds(bounds);

    }

	onShowRouteMap(rownode){
		//console.log("rownode", rownode);
		if (googleAnalytics.page.enableGA) {
			let eventOptions = {
				"category": this.state.pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		if(rownode != 0)
		{
			var params = { 
				//container_no:"FSCU9842333"
				container_no:rownode.container_no
			}
			this.setState({
				loadshow:'show-m'
			})
			 redirectURL.post('/imports/containersearates',params)
			  .then((response) => {
			  
			   var records = JSON.parse(response.data.body);
				//console.log("Here ",records.status);
				if(records.status == 'Failure')
				{
					this.setState({
						show: true, 
						basicType:'danger', 
						basicTitle:"No data found",
							
					});
				}
				else{
					//console.log(records);
					this.setState({
						mapinfo:records,
						sliderRouteTranslate:"slider-translate-75p",
						consignment_code:rownode.container_no,
						show: false,
						overly:'show-m'
					});
					this.renderMap();
					
				}
				this.setState({
					loadshow:'show-n',
				})
			  /* this.setState({
					mapinfo:response.data,
					sliderRouteTranslate:"slider-translate-50p",
					consignment_code:rownode.truck_no
			   });
				   this.renderMap();
			
				*/
			  })
			  .catch(function (error) {
				console.log(error);
			  });
			  
		}
		
	}
	onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderRouteTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n'
		});
	}
	closeAlert = () => {
        this.setState({
			show: false,
			overly:'show-n'
        });
	}
	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:''
		});
		
	}
	handleActionClick(e){
		//console.log("High ", e)
		/*Transit Delays*/
		if(parseInt(e) == 2)
		{
			if(this.state.transits.length > 0)
			{
				this.setState({
					rowData:this.state.transits,
					
				})
			}

		}
		/*Arriing Today*/
		if(parseInt(e) == 1)
		{
			if(this.state.arrivingtoday.length > 0)
			{
				this.setState({
					rowData:this.state.arrivingtoday
				})
			}
		}
		/*Arriving Next 3 days*/
		if(parseInt(e) == 3)
		{
			if(this.state.nextdays.length > 0)
			{
				this.setState({
					rowData:this.state.nextdays
				})
			}
		}
	}

	handleActionTab2Click(e){
		//console.log("High ", e)
		/*Expected Port out Today*/
		if(parseInt(e) == 1)
		{
			if(this.state.expectedoutport.length > 0)
			{
				this.setState({
					rowData:this.state.expectedoutport
				})
			}
		}
		/*Overdue In Port*/
		if(parseInt(e) == 2)
		{
			if(this.state.overdueport.length > 0)
			{
				this.setState({
					rowData:this.state.overdueport,
					
				})
			}

		}
		
		/*BOE Date not update*/
		if(parseInt(e) == 3)
		{
			if(this.state.boedates.length > 0)
			{
				this.setState({
					rowData:this.state.boedates
				})
			}
		}
	}

	handleActionTab3Click(e){
		//console.log("High ", e)
		/*Expected Port out Today*/
		if(parseInt(e) == 1)
		{
			if(this.state.icdarrivetoday.length > 0)
			{
				this.setState({
					rowData:this.state.icdarrivetoday
				})
			}
		}
		/*Overdue In Port*/
		if(parseInt(e) == 2)
		{
			if(this.state.icdexpectedarrive.length > 0)
			{
				this.setState({
					rowData:this.state.icdexpectedarrive,
					
				})
			}

		}
		
		/*BOE Date not update*/
		if(parseInt(e) == 3)
		{
			if(this.state.icdicdexpecteddepart.length > 0)
			{
				this.setState({
					rowData:this.state.icdicdexpecteddepart
				})
			}
		}
	}

	handleActionTab4Click(e){
		//console.log("High ", e)
		/*Expected Port out Today*/
		if(parseInt(e) == 1)
		{
			if(this.state.msilexpectedarrive.length > 0)
			{
				this.setState({
					rowData:this.state.msilexpectedarrive
				})
			}
		}
		/*Overdue In Port*/
		if(parseInt(e) == 2)
		{
			if(this.state.msiloverdue.length > 0)
			{
				this.setState({
					rowData:this.state.msiloverdue,
					
				})
			}

		}
		
	}
	render(){
		
		const dStyles={
			width:this.props.width,
			height:'500px',
			marginLeft:this.props.marginLeft,

		}
		if(this.state.istabactive == 'highseas')
		{		
			var percent=0;
			//console.log(this.state.transits)
			if(this.state.totalrowdatacnt > 0 && this.state.totalrowdatacnt != '')
			{
				percent = Math.round((this.state.transits.length/this.state.totalrowdatacnt)*100);
				
			}

			var percent1=0;
			if(this.state.totalrowdatacnt > 0 && this.state.totalrowdatacnt != '')
			{
				percent1 = Math.round((this.state.arrivingtoday.length/this.state.totalrowdatacnt)*100);
				
			}
			var percent2=0;
			if(this.state.totalrowdatacnt > 0 && this.state.totalrowdatacnt != '')
			{
				percent2 = Math.round((this.state.nextdays.length/this.state.totalrowdatacnt)*100);
				
			}
		}
		if(this.state.istabactive == 'inport')
		{			
			var percent=0;
			if(this.state.totalrecordscnt > 0 && this.state.totalrecordscnt != '')
			{
				percent = Math.round((this.state.expectedoutport.length/this.state.totalrecordscnt)*100);
				
			}

			var percent1=0;
			if(this.state.totalrecordscnt > 0 && this.state.totalrecordscnt != '')
			{
				percent1 = Math.round((this.state.overdueport.length/this.state.totalrecordscnt)*100);
				
			}
			var percent2=0;
			if(this.state.totalrecordscnt > 0 && this.state.totalrecordscnt != '')
			{
				percent2 = Math.round((this.state.boedates.length/this.state.totalrecordscnt)*100);
				
			}
		}
		if(this.state.istabactive == 'icd')
		{
			var percent=0;
			if(this.state.totalicdrecordscnt > 0 && this.state.totalicdrecordscnt != '')
			{
				percent = Math.round((this.state.icdarrivetoday.length/this.state.totalicdrecordscnt)*100);
				
			}

			var percent1=0;
			if(this.state.totalrecordscnt > 0 && this.state.totalrecordscnt != '')
			{
				percent1 = Math.round((this.state.icdexpectedarrive.length/this.state.totalicdrecordscnt)*100);
				
			}
			var percent2=0;
			if(this.state.totalrecordscnt > 0 && this.state.totalrecordscnt != '')
			{
				percent2 = Math.round((this.state.icdicdexpecteddepart.length/this.state.totalicdrecordscnt)*100);
				
			}
			
		}
		if(this.state.istabactive == 'msil')
		{
			var percent=0;
			if(this.state.totalmsilrecordscnt > 0 && this.state.totalmsilrecordscnt != '')
			{
				percent = Math.round((this.state.msilexpectedarrive.length/this.state.totalmsilrecordscnt)*100);
				
			}

			var percent1=0;
			if(this.state.totalrecordscnt > 0 && this.state.totalrecordscnt != '')
			{
				percent1 = Math.round((this.state.msiloverdue.length/this.state.totalmsilrecordscnt)*100);
				
			}
			
			
		}
		return(
			<div className="container-fluid">
			<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
				
            	<div className="row">
                    <div className="col-xl-12 col-lg-12">
						<Breadcrumb parent="Container" title="Dashboard" />
                        {/*<h5>
                            <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Container Dashboard </span>
                        </h5>*/}
                    </div>
                    <div className="col-xl-12 col-lg-12">
                        <Counters counters={this.state.containerCounter} context={this} />
                    </div>
                    <div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            					   			
		            		<div className="card-body">
								<ul class="tabs-color nav nav-tabs">
									<li className="nav-item">
										<a className={"nav-link "+(this.state.istabactive == 'highseas'?"active ainfo tabhigh":"")} href="#" onClick={this.onTabClick.bind(this,1)}>
											<i className="icofont icofont-ship-alt f20"></i> High Seas
										</a>
									</li>

									<li className="nav-item">
										<a className={"nav-link "+(this.state.istabactive == 'inport'?"active binfo tabhigh":"")} href="#" onClick={this.onTabClick.bind(this,2)}><i className="icofont icofont-ship f20"></i> In Port</a>
									</li>

									<li className="nav-item">
										<a className={"nav-link "+(this.state.istabactive == 'icd'?"active cinfo tabhigh":"")} href="#" onClick={this.onTabClick.bind(this,3)}><i className="icofont icofont-ui-home f20"></i> In ICD Garhi</a>
									</li>

									<li className="nav-item">
										<a className={"nav-link "+(this.state.istabactive == 'msil'?"active dinfo tabhigh":"")} href="#" onClick={this.onTabClick.bind(this,4)}><i className="icofont icofont-truck f20"></i> In transit to MSIL</a>
									</li>
								</ul>
								<div className="tab-pane tabpad active col-xl-12 col-lg-12 col-sm-12">
									<div className="col-xl-12 col-lg-12 col-sm-12 beffect mt-20p p-0">
										<div className="card">
											<div className="card-body" style={{padding:"10px"}}>
												<div className="crm-numbers pb-0">
													
													{(this.state.istabactive == 'highseas')?
													<div className="row">
													
														<div className="col cirlce-d">
															<span className="f13"><i className="icofont icofont-ui-map f24 txt-info"></i><br /> Arriving Today</span>
															<h4 class="txt-info f20" onClick={this.handleActionClick.bind(this,1)}><span className="counter"><CountUp end={(this.state.arrivingtoday.length != '')?this.state.arrivingtoday.length:0}/></span></h4>
															<div className="progress-showcase">
																<div className="progress lg-progress-bar">
																	
																	<div className="progress-bar bg-info" role="progressbar" style={{width:percent1+'%'}} aria-valuenow={(this.state.arrivingtoday.length != '')?this.state.arrivingtoday.length:0} aria-valuemin="0" aria-valuemax={this.state.totalrowdatacnt}></div>
																</div>
															</div>
														</div>


														<div className="col cirlce-d">
															<span className="f13"><i className="icofont icofont-clock-time f24 txt-danger"></i><br /> Transit Delay</span>
															<h4 class="txt-danger f20" onClick={this.handleActionClick.bind(this,2)}><span className="counter"><CountUp end={(this.state.transits.length != '')?this.state.transits.length:0}/></span></h4>
															<div className="progress-showcase">
																<div className="progress lg-progress-bar">
																	
																	<div className="progress-bar bg-danger" role="progressbar" style={{width:percent+'%'}} aria-valuenow={(this.state.transits.length != '')?this.state.transits.length:0} aria-valuemin="0" aria-valuemax={this.state.totalrowdatacnt}></div>
																</div>
															</div>
														</div>


														<div className="col cirlce-d">
															<span className="f13"><i className="icofont icofont-map-pins f24 txt-success"></i><br /> Arriving Next 3 days</span>
															<h4 class="txt-success f20" onClick={this.handleActionClick.bind(this,3)}><span className="counter"><CountUp end={(this.state.nextdays.length != '')?this.state.nextdays.length:0}/></span></h4>
															<div className="progress-showcase">
																<div className="progress lg-progress-bar">
																	
																	<div className="progress-bar bg-success" role="progressbar" style={{width:percent2+'%'}} aria-valuenow={(this.state.nextdays.length != '')?this.state.nextdays.length:0} aria-valuemin="0" aria-valuemax={this.state.totalrowdatacnt}></div>
																</div>
															</div>
														</div>

													</div>
													:""}


													{(this.state.istabactive == 'inport')?
													<div className="row">
													
														<div className="col cirlce-d">
															<span className="f13"><i className="icofont icofont-ui-map f24 txt-info"></i><br /> Expected Port Out Today</span>
															<h4 class="txt-info f20" onClick={this.handleActionTab2Click.bind(this,1)}><span className="counter"><CountUp end={(this.state.expectedoutport.length != '')?this.state.expectedoutport.length:0}/></span></h4>
															<div className="progress-showcase">
																<div className="progress lg-progress-bar">
																	
																	<div className="progress-bar bg-info" role="progressbar" style={{width:percent1+'%'}} aria-valuenow={(this.state.expectedoutport.length != '')?this.state.expectedoutport.length:0} aria-valuemin="0" aria-valuemax={this.state.totalrecordscnt}></div>
																</div>
															</div>
														</div>


														<div className="col cirlce-d">
															<span className="f13"><i className="icofont icofont-clock-time f24 txt-danger"></i><br /> Overdue In Port</span>
															<h4 class="txt-danger f20" onClick={this.handleActionTab2Click.bind(this,2)}><span className="counter"><CountUp end={(this.state.overdueport.length != '')?this.state.overdueport.length:0}/></span></h4>
															<div className="progress-showcase">
																<div className="progress lg-progress-bar">
																	
																	<div className="progress-bar bg-danger" role="progressbar" style={{width:percent+'%'}} aria-valuenow={(this.state.overdueport.length != '')?this.state.overdueport.length:0} aria-valuemin="0" aria-valuemax={this.state.totalrecordscnt}></div>
																</div>
															</div>
														</div>

														<div className="col cirlce-d">
															
															<span className="f13"><i className="icofont icofont-ui-calendar f24 txt-primary"></i><br /> BOE date not update</span>
															<h4 class="txt-primary f20" onClick={this.handleActionTab2Click.bind(this,3)}><span className="counter"><CountUp end={(this.state.boedates.length != '')?this.state.boedates.length:0}/></span></h4>
															<div className="progress-showcase">
																<div className="progress lg-progress-bar">
																	
																	<div className="progress-bar bg-primary" role="progressbar" style={{width:percent2+'%'}} aria-valuenow={(this.state.boedates.length != '')?this.state.boedates.length:0} aria-valuemin="0" aria-valuemax={this.state.totalrecordscnt}></div>
																</div>
															</div>
														</div>

													</div>
													:""}

													{(this.state.istabactive == 'icd')?
													<div className="row">
													
														<div className="col cirlce-d">
															<span className="f13"><i className="icofont icofont-ui-map f24 txt-info"></i><br /> Arrive Today</span>
															<h4 class="txt-info f20" onClick={this.handleActionTab3Click.bind(this,1)}><span className="counter"><CountUp end={(this.state.icdarrivetoday.length != '')?this.state.icdarrivetoday.length:0}/></span></h4>
															<div className="progress-showcase">
																<div className="progress lg-progress-bar">
																	
																	<div className="progress-bar bg-info" role="progressbar" style={{width:percent1+'%'}} aria-valuenow={(this.state.icdarrivetoday.length != '')?this.state.icdarrivetoday.length:0} aria-valuemin="0" aria-valuemax={this.state.totalicdrecordscnt}></div>
																</div>
															</div>
														</div>


														<div className="col cirlce-d">
															<span className="f13"><i className="icofont icofont-delivery-time f24 txt-primary"></i><br /> Expected Arrival Today</span>
															<h4 class="txt-primary f20" onClick={this.handleActionTab3Click.bind(this,2)}><span className="counter"><CountUp end={(this.state.icdexpectedarrive.length != '')?this.state.icdexpectedarrive.length:0}/></span></h4>
															<div className="progress-showcase">
																<div className="progress lg-progress-bar">
																	
																	<div className="progress-bar bg-primary" role="progressbar" style={{width:percent+'%'}} aria-valuenow={(this.state.icdexpectedarrive.length != '')?this.state.icdexpectedarrive.length:0} aria-valuemin="0" aria-valuemax={this.state.totalicdrecordscnt}></div>
																</div>
															</div>
														</div>

														<div className="col cirlce-d">
															
															<span className="f13"><i className="icofont icofont-clock-time f24 txt-danger"></i><br /> Overdue</span>
															<h4 class="txt-danger f20" onClick={this.handleActionTab3Click.bind(this,3)}><span className="counter"><CountUp end={(this.state.icdicdexpecteddepart.length != '')?this.state.icdicdexpecteddepart.length:0}/></span></h4>
															<div className="progress-showcase">
																<div className="progress lg-progress-bar">
																	
																	<div className="progress-bar bg-danger" role="progressbar" style={{width:percent2+'%'}} aria-valuenow={(this.state.icdicdexpecteddepart.length != '')?this.state.icdicdexpecteddepart.length:0} aria-valuemin="0" aria-valuemax={this.state.totalicdrecordscnt}></div>
																</div>
															</div>
														</div>

													</div>
													:""}


													{(this.state.istabactive == 'msil')?
													<div className="row">
													
														<div className="col cirlce-d">
															<span className="f13"><i className="icofont icofont-delivery-time f24 txt-info"></i><br /> Expected Dispatch</span>
															<h4 class="txt-info f20" onClick={this.handleActionTab4Click.bind(this,1)}><span className="counter"><CountUp end={(this.state.msilexpectedarrive.length != '')?this.state.msilexpectedarrive.length:0}/></span></h4>
															<div className="progress-showcase">
																<div className="progress lg-progress-bar">
																	
																	<div className="progress-bar bg-info" role="progressbar" style={{width:percent1+'%'}} aria-valuenow={(this.state.msilexpectedarrive.length != '')?this.state.msilexpectedarrive.length:0} aria-valuemin="0" aria-valuemax={this.state.totalmsilrecordscnt}></div>
																</div>
															</div>
														</div>


														<div className="col cirlce-d">
															<span className="f13"><i className="icofont icofont-clock-time f24 txt-danger"></i><br /> Overdue</span>
															<h4 class="txt-danger f20" onClick={this.handleActionTab4Click.bind(this,2)}><span className="counter"><CountUp end={(this.state.msiloverdue.length != '')?this.state.msiloverdue.length:0}/></span></h4>
															<div className="progress-showcase">
																<div className="progress lg-progress-bar">
																	
																	<div className="progress-bar bg-danger" role="progressbar" style={{width:percent+'%'}} aria-valuenow={(this.state.msiloverdue.length != '')?this.state.msiloverdue.length:0} aria-valuemin="0" aria-valuemax={this.state.totalmsilrecordscnt}></div>
																</div>
															</div>
														</div>

													</div>
													:""}
												</div>
											</div>
										</div>
									</div>

									<div id="myGrid" style={{ height: "580px",width: "100%"}}  className="ag-theme-balham">
									
									
									<AgGridReact
										modules={this.state.modules}
										columnDefs={this.state.columnwithDefs}
										defaultColDef={this.state.defaultColDef}
										rowData={this.state.rowData}
										enableCharts={true}
										enableRangeSelection={true}
										autoGroupColumnDef={this.state.autoGroupColumnDef}
										onGridReady={this.onGridReady}
										onGridState={this.onGridState}
										statusBar={this.state.statusBar}
										sideBar={this.state.sideBar}
										paginationPageSize={this.state.paginationPageSize}
										pagination={true}
										frameworkComponents={this.state.frameworkComponents}
										detailCellRendererParams={this.state.detailCellRendererParams}
										//editType={this.state.editType}
										stopEditingWhenGridLosesFocus={true}
										floatingFilter={false}
										enableCellChangeFlash={true}
										suppressCellFlash={true}
										rowClassRules={this.state.rowClassRules}
										//onCellClicked={this.onRowClicked.bind(this)}
										gridOptions={{
											context: { componentParent: this }
										}}
										masterDetail={true}
										headerHeight={this.state.headerHeight}
										groupHeaderHeight={this.state.groupHeaderHeight}
									/>

										<div className={"dataLoadpage " +(this.state.loadshow)}>
										</div>
										<div className={"dataLoadpageimg " +(this.state.loadshow)}>
											<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
										</div>

									</div>
								</div>
				   			</div>
				   		</div>	
				   	</div>
				 </div>
				
				
				 {this.state.sliderRouteTranslate != ''?
					 <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								Container Routes #{this.state.consignment_code}
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
							
							<div className="row col-xl-12 col-lg-12">
								<img src={require('../../assets/icons/start_port.png')} className="mapico2" /> - Starting Point &nbsp;
								<img src={require('../../assets/icons/boat.png')} className="mapico1" /> - Port/Change Over location &nbsp; 
								<img src={require('../../assets/icons/crane.png')} className="mapico" /> - Last Known location &nbsp;
								<div id="map" className="" style={{width:'100%',height:"90vh",marginTop:"15px"}}></div>
							</div>
						</div>
					</div>
				:""	
				}
				
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            
            </div>
              	
		);
    }


    
    onShowConsignmentImportsEventsClick = async (params) => {
		//console.log(e);
		//console.log("IN onShowConsignmentImportsEventsClick params ",params);
	//http://localhost:3001/trackBlDeepSea/track?sealine=HPG&container=FSCU9842333&apiKey=O1TX-DPHE-WYN6-53MI
		if(params != 0)
		{
			var eventsData=[];
			// await redirectURL.post('trackBlDeepSea/getcontainertrackingdetails', {
            //     container: params.data.container_no,
            //     sealine: params.data.shipping_line_code,
            //     apiKey: "O1TX-DPHE-WYN6-53MI"
            // })
			// .then(async (response) =>{
			// 	console.log("Step 11 ",response.data)
				
				
			// 	eventsData = await parseEventsData(response.data);
			// 	//params.successCallback(eventsData);
					
			// 	console.log("Step 12 ",response.data)
				
			// });

			//console.log("Step 3 ",eventsData)
			
			await this.setState({
				detailCellRenderer: "<div>Test</div>",
				detailCellRendererParams:{
					detailGridOptions: {
						columnDefs: [							
							{ headerName: "Event",field: "description", filter:true,
								cellClass:function(params){
									if(params.data.event_completed == 1 && params.data.leg_delayed == 0)
									{
										return 'green';
									}
									else if(params.data.event_completed == 1 && params.data.leg_delayed == 1)
									{
										return 'red';
									}
									else if(params.data.event_completed == 0 && params.data.leg_delayed == 1)
									{
										return 'red';
									}
									else{
										return '';
									}
								},resizable: true
							},
							
							{ headerName: "Place",field: "pod", filter:true,
								cellClass:function(params){
									if(params.data.event_completed == 1 && params.data.leg_delayed == 0)
									{
										return 'green';
									}
									else if(params.data.event_completed == 1 && params.data.leg_delayed == 1)
									{
										return 'red';
									}
									else if(params.data.event_completed == 0 && params.data.leg_delayed == 1)
									{
										return 'red';
									}
									else{
										return '';
									}
								},resizable: true
							 },	
							{ 
								headerName: "Initial Estimated Date", field: "initial_event_date", filter:true ,
								cellClass:function(params){
									if(params.data.event_completed == 1 && params.data.leg_delayed == 0)
									{
										return 'green';
									}
									else if(params.data.event_completed == 1 && params.data.leg_delayed == 1)
									{
										return 'red';
									}
									else if(params.data.event_completed == 0 && params.data.leg_delayed == 1)
									{
										return 'red';
									}
									else{
										return '';
									}
								},
								valueGetter:function(params){
									return getDDMMYYYY(params.data.initial_event_date);
								},resizable: true,
								comparator: dateComparator,
								sort: "asc"
							},
							{ 
								headerName: "Previous Revised Date", field: "previous_event_date", filter:true ,
								cellClass:function(params){
									if(params.data.event_completed == 1 && params.data.leg_delayed == 0)
									{
										return 'green';
									}
									else if(params.data.event_completed == 1 && params.data.leg_delayed == 1)
									{
										return 'red';
									}
									else if(params.data.event_completed == 0 && params.data.leg_delayed == 1)
									{
										return 'red';
									}
									else{
										return '';
									}
								},
								valueGetter:function(params){
									return getDDMMYYYY(params.data.previous_event_date);
								},resizable: true
							},						
							{ 
								headerName: "Actual Event Date", field: "actual_event_date", filter:true ,
								cellClass:function(params){
									if(params.data.event_completed == 1 && params.data.leg_delayed == 0)
									{
										return 'green';
									}
									else if(params.data.event_completed == 1 && params.data.leg_delayed == 1)
									{
										return 'red';
									}
									else if(params.data.event_completed == 0 && params.data.leg_delayed == 1)
									{
										return 'red';
									}
									else{
										return '';
									}
								},
								valueGetter:function(params){
									return getDDMMYYYY(params.data.actual_event_date);
								},resizable: true
							},{ 
								headerName: "Trip Stage", field: "trip_stage", filter:true ,
								cellClass:function(params){
									if(params.data.event_completed == 1 && params.data.leg_delayed == 0)
									{
										return 'green';
									}
									else if(params.data.event_completed == 1 && params.data.leg_delayed == 1)
									{
										return 'red';
									}
									else if(params.data.event_completed == 0 && params.data.leg_delayed == 1)
									{
										return 'red';
									}
									else{
										return '';
									}
								},resizable: true,
								valueGetter:function(params){
									if(params.data.trip_stage == 1)
									{
										return 'High Seas';
									}

									if(params.data.trip_stage == 2)
									{
										return 'Port In';
									}

									if(params.data.trip_stage == 3)
									{
										return 'Port Out';
									}

									if(params.data.trip_stage == 4)
									{
										return 'ICD In';
									}

									if(params.data.trip_stage == 5)
									{
										return 'ICD Out';
									}
								}
							}
							
						],
										
					},
					getDetailRowData: async function(params) {
						//console.log("Step 4 eventsData =",eventsData);
						//console.log("Step 5 params = ",params);
						redirectURL.post('/trackBlDeepSea/imports', {
							container: params.data.container_no,
							sealine: params.data.shipping_line_code,
							apiKey: "O1TX-DPHE-WYN6-53MI"
						})
						.then(async (response) =>{
							//console.log("Event Step 1 ",response.data)
							//eventsData = await parseEventsData(response.data);
							params.successCallback(response.data);
						});
					},

					
					masterDetail: true,
				}
			});
			
			if(params.column.colId == 'sea_transit_delay' || params.column.colId == 'port_delay' || params.column.colId == 'rail_transit_delay' || params.column.colId == 'icd_delay' || params.column.colId == '_id')
			{
				//console.log("detailCellRendererParams 1111 = ", this.state.detailCellRendererParams, params.column.colId);
				params.node.setExpanded(!params.node.expanded);
			}
			else{
				//console.log("detailCellRendererParams 2222 = ", this.state.detailCellRendererParams, params.column.colId);
				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
			
		} 
		
	}
}
function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
                   
function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = Math.floor(hours);
	var minutes = (n) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}                              
var currentinfowindow = null;
function getTimeInfo(marker,timedet,coords)
{
	var timeinfo = new window.google.maps.InfoWindow({
          content: "Time at this location: " + timedet
        });

	marker.addListener('click', function() {
		
				if (currentinfowindow != null) {
					currentinfowindow.close();
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				else
				{
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				
        });
}

function parseEventsData2 (jsonData) {
    //console.info('parsing data IN parseEventsData');
   // console.info('parsing data IN parseConsignemntDetailsSafexpress', jsonData);
   
    let points = jsonData.points;
   // console.log('points = ', points);
    let eventList = [];
    let eventInfo = {};
    points.forEach(function(point) {
        eventInfo.name = point.name;
        eventInfo.country = point.country;
      // traversing events
      if (point.events) {
        //console.log('coming in events');
        point.events.forEach(function(eventObj) { 
            eventInfo.event_datetime_ist = eventObj.event_datetime_ist;
            eventInfo.description = eventObj.description;
        });
        eventList.push(eventInfo);
      } else {
        console.log('coming in ELSE point.events ');

      }
      
    });
    //console.info('parsing points');

    return eventList;
  }
  
async function parseEventsData (jsonData) {
//console.info('parsing data IN parseEventsData');
//console.info('parsing data IN parseConsignemntDetailsSafexpress', jsonData);

let points = jsonData.points;
// console.log('points = ', points);
let eventList = [];
let eventInfo = [];

points.forEach(function(point) {
	let pointName = point.name;
	let pointCountry = point.country;
	let pointTransportTypeName = point.transport_type_name;
	let pointTransportType = point.type;
	if (pointTransportType === 1 || pointTransportType === 4) {
		pointTransportTypeName = "Rail/Truck";
	}
	if (!pointTransportTypeName) {
		pointTransportTypeName = "Data unavailable"
	}
	// traversing events
	if (point.events) {
		//console.log('coming in events');
		point.events.forEach(function(eventObj) { 
			let eventDatetime = eventObj.event_datetime_ist;
			let eventDescription = eventObj.description;
			
			let initial_event_datetime_ist = eventObj.initial_event_datetime_ist;
            let previous_event_datetime_ist = eventObj.previous_event_datetime_ist;
			let actual_event_datetime_ist	= eventObj.actual_event_datetime_ist;
			

			eventInfo = {
				"name": pointName,
				"country": pointCountry,
				"event_datetime_ist": eventDatetime,
				"description": eventDescription,
				"transport_type_name": pointTransportTypeName,
				"initial_event_datetime_ist": initial_event_datetime_ist,
				"previous_event_datetime_ist": previous_event_datetime_ist,
				"actual_event_datetime_ist": actual_event_datetime_ist,
			}
			eventList.push(eventInfo);
		});
	} else {
		console.log('coming in ELSE point.events '); // do nothing
	}
});
//console.log('eventList', eventList);
return eventList;
}


function dateComparator(date1, date2) {
    var date1Number = monthToComparableNumber(date1);
    var date2Number = monthToComparableNumber(date2);

    if (date1Number===null && date2Number===null) {
        return 0;
    }
    if (date1Number===null) {
        return -1;
    }
    if (date2Number===null) {
        return 1;
    }

    return date1Number - date2Number;
}

// eg 29/08/2004 gets converted to 20040829
function monthToComparableNumber(date) {
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }

    var yearNumber = date.substring(6,10);
    var monthNumber = date.substring(3,5);
    var dayNumber = date.substring(0,2);

    var result = (yearNumber*10000) + (monthNumber*100) + dayNumber;
    return result;
}
function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}