import React, { Component } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';
import {
    getDDMMYYYY,
    getDDMMYYYYHHMMSS,
    getYYYYMMDD,
    getYYYYMMDDHHMMSS,
    getHyphenYYYYMMDDHHMMSS,
    getHyphenDDMMMYYYYHHMM,
    getHyphenYYYYMMDD,
    getHyphenDDMMYYYYHHMMSS,
    getHyphenDDMMYYYY,
    getDDMMMYYYYHHMMDefault
} from '../common/utils';
import Modal from 'react-responsive-modal';
import CSVFileValidator from 'csv-file-validator';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
// import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");


export default class CostSavingDashboard extends Component {
    constructor(props) {
        super(props);
        this.displayData = [];
        this.state = {
            deptcode: "",
            activeFilter: null,
            uploadDivWidth: '0%',
            timelineinfo: '',
            consignment_code: '',
            mapinfo: '',
            commentsRowData: [],
            dealer: '',
            maptruckno: '',
            loadshow: 'show-n',
            showDiv: 'show-n',
            alertshow: 'fade',
            alertmg: 'show-n',
            alerterrmg: 'show-n',
            overly: 'show-n',
            alerterrshow: 'fade',
            alert: null,
            show: false,
            basicTitle: '',
            show1: false,
            basicTitle1: '',
            basicType1:"default",
            rownode: '',
            leg_no: '',
            basicType: "default",
            modules: AllModules,
            filterstatess: {},
            columnsdatas: {},
            rowgroupdcols: [],
            valuecolumns: [],
            activeconsignemnt: [],
            activetrucks: [],
            transitdelays: [],
            gpsdatana: [],
            overspeedtrucks: [],
            nightdrive: [],
            open: false,
            openforceclose: false,
            pivotmodeis: false,
            pivotcols: [],
            defTransitCoords: '',
            routeTruck: {},
            sidebarSubHeader: '',
            googelRoutes: '',
            plants: [],
            bulkPrioritySlide: "",
            hideTransporterBtns: "show-m",
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
                pivot: true,
                enableValue: true,
                enableRowGroup: true,
            },
            rowData: [],
            maprowData: null,
            rowSelection: 'multiple',
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            overlayNoRowsTemplate: "",
            frameworkComponents: {

            },

            animateRows: true,
            debug: true,
            showToolPanel: false,
            uppressAggFuncInHeader: true,

            childconfs: [],
            childrow: [],
            rowModelType: "serverSide",
            paginationPageSize: 100,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            detailCellRendererParams: {},
            rowClassRules: {
                //"highlitRow": "data.transit_delay == 1",
                //"green":"data.status >= 3"

            },
            triptype: [],
            tripvalue: '',
            consigner: [],
            originalData: [],
            isCounterDisplay: 1,
            screenpage: '',
            pagetitle: 'Cost Savng Dashbaord',
            countersjson: '',
            originalcountersjson: '',
            consigneecoords: '',
            consigner_coordinates: '',
            containerslist: [],
            forceclosedata: '',
            reached_dealer_time: moment.parseZone().format('YYYY-MM-DD'),
            reachedhh: '',
            reachedmm: '',
            reachedss: '00',
            left_dealer_time: moment.parseZone().format('YYYY-MM-DD'),
            lefthh: '',
            leftmm: '',
            leftss: '00',
            invoice_time: '',
            invoicehh: '',
            invoicemm: '',
            segementConsignmentCode: "",
            counter_screen: "",
            priority_counter_display: 0,
            invoicess: '00',
            reasonforceclose: '',
            consignment_details: '',
            startDatetime: '',
            endDatetime: '',
            tolls: [],
            excelStyles: [
                {
                    id: "dateFormat",
                    dataType: 'dateTime',
                    numberFormat: { format: "yyyy-mm-dd" }
                }
            ],
            getRowHeight: function (params) {
                if (typeof params.data.dct_comment != 'undefined') {
                    //console.log("pars ", params.data.dct_comment.length)
                    return (
                        //params.api.getSizesForCurrentTheme().rowHeight *
                        (100 * Math.floor(params.data.dct_comment.length / 50))
                    );
                }
                if (typeof params.data.vin_list != 'undefined') {
                    //console.log("pars ", params.data.vin_list.toString().length)
                    return (
                        //params.api.getSizesForCurrentTheme().rowHeight *
                        (100 * Math.floor(params.data.vin_list.toString().length / 50))
                    );
                }
                else if (typeof params.data.force_closure_reason != 'undefined') {
                    //console.log("pars ", params.data.dct_comment.length)
                    return (
                        //params.api.getSizesForCurrentTheme().rowHeight *
                        (100 * Math.floor(params.data.force_closure_reason.length / 50))
                    );
                }
                else {
                    return 30;
                }
            },
            defaultsdate: '',
            defaultedate: '',
            movementtype: { "value": "ALL", "label": "All" },
            usergridstate: [],
            screenurl: "",
            screentitle: "",
            showumncr: 0,
            showummove: 0,
            usermanualncrmodal: false,
            usermanualmovemodal: false,
            slideuploadeway: "",
            cancelled_data: [],
            pod_received_data: [],
            trip_closed_by_pod: [],
            returnConsignments: [],
            tnpButtonTitle: "",
            tnpReturnConsignmentsVisibilty: false,
            consBtn: "btn-danger",
            returnConsBtn: "btn-default",
            truckReportedDate: [],
            filterCities: [],
            filterStates: [],
            filterClusters: [],
            filterZones: [],
            filterTransporters: [],
            beforeNewFilterRowData: [],
            beforeNewFiltercountersjson: [],
            flCity: "",
            flState: "",
            flCluster: "",
            flZone: "",
            flTransporter: "",
            devation_flag: false,
            truck_no: { "value": "Select Plant", "label": "Select Truck" },
            plant_code: { "value": "Select Plant", "label": "Select Plant" },
            grno_consignment_code: "",
            invoice_no: "",
            invoice_date: "",
            gate_out_time: "",
            consignee_code: "",
            trucklist: [],
            plantlist: [],
            dept_code: "",
            consignees: [],
            loginplant: "",
            selectedFilter: [],
            baseRouteCoords: "",
            withoutTruckGateOutTimeData: [],
            forceClosed: [],
            showdata: [],
            changeInvoiceHeader: false,
            area_names_list: [{ label: 'ALL', value: 'ALL' }],
            transporters_list: [{ label: "ALL", value: "ALL" }],
            transporter: [{ label: "ALL", value: "ALL" }],
            plant_name: [{ label: "ALL", value: "ALL" }],
            from_date: moment.parseZone().subtract(10, 'days').format('YYYY-MM-DD') + " 00:00",
            to_date: moment.parseZone().format('YYYY-MM-DD') + " 23:59",
            ao_name: [{ label: "ALL", value: "ALL" }],
            vehicle_type: [{ label: "ALL", value: "ALL" }],
            sliderForMonthSob: 'show-n',
            sliderForMonthSobCalender: 'show-n',
            sliderForAoPlan: 'show-n',
            sliderForTptAreaCode: 'show-n',
            file: '',
            cvscontentForMonthSob: '',
            cvscontentForMonthSobCalender: '',
            cvscontentForAoPlan: '',
            csvcontentForTptAreaCode: '',
            uploadFile: "",
            selectedMonth: new Date(),
            ao_summary_data: [],
            zone_summary_data: [],
            ao_summary_tab: 'show-m',
            zone_summary_tab: 'show-n',
            ao_summary_btn: 'btn-danger',
            zone_summary_btn: 'btn-default',
            tpt_area_code_data:[],
            openmodal:false,
            update_vehicle_type:{ label: 'Truck', value: 'Truck' },
            update_transporter_code:"",
            update_transporter_name:"",
            update_consignee_code:"",
            update_consignee_area:"",
            current_update_data_id:"",
            current_delete_data_id:"",
        }
        this.changeFileHandlerForMonthSob = this.changeFileHandlerForMonthSob.bind(this)
    }


    componentDidMount() {
        loadDateTimeScript();
        var current_date = moment.parseZone(new Date()).format("YYYY-MM")

        var monthStart = moment.parseZone(new Date(current_date)).startOf('month').format("YYYY-MM-DD")
        var sdate = moment.parseZone(new Date(monthStart)).format('YYYY-MM-DD') + " 00:00";

        // var monthEnd = moment.parseZone(new Date(current_date)).endOf('month').format("YYYY-MM-DD")
        var edate = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm");
        $("#from_date_cs").val(sdate);
        $("#to_date_cs").val(edate);

        redirectURL.post('/dashboard/getDistinctForCSD', {}).then(response => {
            var transporters = response.data.transporters
            var areas = response.data.areas
            var transporters_list = []
            transporters.map(e => {

                transporters_list.push({
                    label: e._id.transporter_name,
                    value: e._id.transporter_code
                })
            })
            var area_names_list = []
            areas.map(e => {

                area_names_list.push({
                    label: e._id.area_name,
                    value: e._id.area_code
                })
            })
            this.setState(prevState => ({
                area_names_list: [...prevState.area_names_list, ...area_names_list],
                transporters_list: [...prevState.transporters_list, ...transporters_list],
                tpt_area_code_data:response.data.tpt_area_code_data
            }))
        })

        var params = {
            transporter_code: JSON.stringify(['ALL']),
            plant_code: JSON.stringify(['ALL']),
            from_date: sdate + ":00",
            to_date: edate + ":59",
            area_code: JSON.stringify(['ALL']),
            vehicle_type: JSON.stringify(['ALL'])
        }
        console.log(params, "1729")

        this.onLoadCSDData(params)
    }

    onLoadCSDData = (params) => {
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
        redirectURL.post('dashboard/getCSDDashboardData', params).then(response => {
            var records = response.data
            if (records.main_df != undefined) {
                var resp1 = JSON.parse(records.main_df)
            } else {
                var resp1 = []
            }

            if (records.summary_df != undefined) {
                var resp2 = JSON.parse(records.summary_df)

            }
            else {
                var resp2 = []
            }
           
            this.setState({
                ao_summary_data: resp1,
                zone_summary_data: resp2,
                loadshow:"show-n",
                overly:"show-n"
            })
        })
    }

    onClickSubmit = () => {
        var sdate = $("#from_date_cs").val();
        var edate = $("#to_date_cs").val();
        var transporter = []
        if (this.state.transporter!=undefined&& this.state.transporter!=null){
            this.state.transporter.map(e => {
                transporter.push(e.value)
            })
        }else{
            transporter.push('ALL')
        }
        var plant_name = []
        if (this.state.plant_name!=undefined&& this.state.plant_name!=null){
            this.state.plant_name.map(e => {
                plant_name.push(e.value)
            })
        }else{
            plant_name.push('ALL')
        }
        var ao_name = []
        if (this.state.ao_name!=undefined&& this.state.ao_name!=null){
            this.state.ao_name.map(e => {
                ao_name.push(e.value)
            })
        }else{
            ao_name.push('ALL')
        }
        var vehicle_type = []
        if (this.state.vehicle_type!=undefined&& this.state.vehicle_type!=null){
            this.state.vehicle_type.map(e => {
                vehicle_type.push(e.value)
            })
        }else{
            vehicle_type.push('ALL')
        }
        var params = {
            transporter_code: JSON.stringify(transporter),
            plant_code: JSON.stringify(plant_name),
            from_date: sdate + ":00",
            to_date: edate + ":59",
            area_code: JSON.stringify(ao_name),
            vehicle_type: JSON.stringify(vehicle_type)
        }
        console.log(params, "params1729")
        this.onLoadCSDData(params)
    }

    changeHandler = (filterType, selOption) => {
        console.log(selOption)
        var options = []
        if (selOption!=null&& selOption!=undefined&& selOption.length > 1){
            var last_option = selOption[selOption.length -1]
            if (last_option.value!="ALL"){
                selOption.map(e => {
                    if (e.value!='ALL'){
                        options.push(e)
                    }
                })
            }else{
                options=[{label:"ALL" , value:"ALL"}]
            }
        }
        if (options.length > 0){
            this.setState({
                [filterType]: options
            })
        }else{
            this.setState({
                [filterType]: selOption
            })
        }
        
    }

    changeDateFilter = (e) => {
        console.log(value)
        var name = e.target.name
        var value = e.target.value
        this.setState({
            [name]: value
        })
    }

    onClickHideAll = () => {
        this.setState({
            overly: "show-n",
            loadshow: "show-n",
            show: false,
            sliderForMonthSob: '',
            sliderForAoPlan: '',
            sliderForMonthSobCalender: '',
            sliderForTptAreaCode: '',
            selectedMonth: new Date()
        })
    }

    getMonthSobSlider = () => {
        this.setState({
            sliderForMonthSob: 'slider-translate-30p',
            overly: 'show-m',

        })
    }

    getMonthsobCalenderSlider = () => {
        this.setState({
            sliderForMonthSobCalender: 'slider-translate-30p',
            overly: 'show-m',

        })
    }

    getAoPlanSlider = () => {
        this.setState({
            sliderForAoPlan: 'slider-translate-30p',
            overly: 'show-m',

        })
    }

    getTptAreaCoeSlider = () => {
        this.setState({
            sliderForTptAreaCode: 'slider-translate-60p',
            overly: 'show-m',

        })
    }

    changeFileHandlerForMonthSob = async (e) => {
        var config = {
            headers: [

                {
                    name: 'AO Name',
                    inputName: 'area_name',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'AO Code',
                    inputName: 'area_code',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'Zone',
                    inputName: 'zone',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'TPT Code',
                    inputName: 'transporter_code',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'TPT Name',
                    inputName: 'transporter_name',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'Type',
                    inputName: 'vehicle_type',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'Plant',
                    inputName: 'plant_code',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'Month Sob',
                    inputName: 'month_sob',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
            ]
        }

        var data = CSVFileValidator(e.target.files[0], config)
            .then(csvData => {
                console.log(csvData.data)
                this.setState({
                    cvscontentForMonthSob: csvData.data
                })
            })
            .catch(err => { })

        var out = new Promise(function (reject, resolve) {
            var reader = new FileReader();
            reader.onload = async function (e) {
                var contents = await e.target.result;
                resolve(contents);
            };
            var tt = reader.readAsText(e.target.files[0]);
        });

        this.setState({
            file: e.target.files[0]
        });

        if (e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
        }
        else {
            e.target.value = null;
            this.setState({
                uploadFile: '',
                show: true,
                basicType: 'danger',
                basicTitle: 'Please upload file having extensions .csv only.',
            });
        }


    }

    onSubmitBulkUploadForMonthSob = async (event) => {
        event.preventDefault()
        this.setState({
            loadshow: 'show-m',
            overly: 'show-m',
        })
        var month = this.state.selectedMonth.toLocaleString('default', { month: 'long' });
        var year = this.state.selectedMonth.getFullYear()
        var csvData = this.state.cvscontentForMonthSob
        console.log(csvData.length, "lenght1729")
        if (csvData != undefined && csvData != '') {
            if (csvData.length > 0) {
                var csvcontent = []
                csvData.map(e => {
                    if (e.plant_code != "Total"){
                        csvcontent.push(e)
                    }
                })
                console.log(csvcontent.length , "length1729")
                var params = {
                    csvcontent: csvcontent,
                    uploaded_by: localStorage.getItem('email'),
                    month: month,
                    year: year,
                }
                console.log(params)
                redirectURL.post('dashboard/bulkUploadForMonthSob', params).then(response => {
                    if (response.data.status == 'success') {
                        this.setState({
                            show: true,
                            basicTitle: 'Month SOB Data Updated Successfully',
                            basicType: "success",
                            loadshow: 'show-n',
                            overly: 'show-n',
                            sliderForMonthSob: "",
                        })
                    } else {
                        this.setState({
                            show: true,
                            basicTitle: 'Month SOB Data not Updated',
                            basicType: "danger",
                            loadshow: 'show-n',
                            overly: 'show-n',
                            sliderForMonthSob: "",
                        })
                    }
                })
            }
        }
        else {
            this.setState({
                show: true,
                basicTitle: 'Choose file to continue',
                basicType: "danger",
            });
        }

    }

    changeFileHandlerForMonthSobCalender = async (e) => {
        var config = {
            headers: [

                {
                    name: 'Plant',
                    inputName: 'consigner_code',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'Total Calender Days',
                    inputName: 'total_calender_days',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'No of Holidays',
                    inputName: 'no_of_holidays',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
            ]
        }

        var data = CSVFileValidator(e.target.files[0], config)
            .then(csvData => {
                console.log(csvData.data)
                this.setState({
                    cvscontentForMonthSobCalender: csvData.data
                })
            })
            .catch(err => { })

        var out = new Promise(function (reject, resolve) {
            var reader = new FileReader();
            reader.onload = async function (e) {
                var contents = await e.target.result;
                resolve(contents);
            };
            var tt = reader.readAsText(e.target.files[0]);
        });

        this.setState({
            file: e.target.files[0]
        });

        if (e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
        }
        else {
            e.target.value = null;
            this.setState({
                uploadFile: '',
                show: true,
                basicType: 'danger',
                basicTitle: 'Please upload file having extensions .csv only.',
            });
        }


    }

    onSubmitBulkUploadForMonthSobCalender = async (event) => {
        event.preventDefault()
        this.setState({
            loadshow: 'show-m',
            overly: 'show-m',
        })
        var month = this.state.selectedMonth.toLocaleString('default', { month: 'long' });
        var year = this.state.selectedMonth.getFullYear()
        var csvData = this.state.cvscontentForMonthSobCalender
        console.log(csvData.length, "1729")
        if (csvData != undefined && csvData != '') {
            if (csvData.length > 0) {
                var params = {
                    csvcontent: csvData,
                    uploaded_by: localStorage.getItem('email'),
                    month: month,
                    year: year,
                }
                console.log(params)
                redirectURL.post('dashboard/bulkUploadForMonthSobCalender', params).then(response => {
                    if (response.data.status == 'success') {
                        this.setState({
                            show: true,
                            basicTitle: 'Month SOB Calender Data Updated Successfully',
                            basicType: "success",
                            loadshow: 'show-n',
                            overly: 'show-n',
                            sliderForMonthSob: "",
                        })
                    } else {
                        this.setState({
                            show: true,
                            basicTitle: 'Month SOB Calender Data not Updated',
                            basicType: "danger",
                            loadshow: 'show-n',
                            overly: 'show-n',
                            sliderForMonthSob: "",
                        })
                    }
                })
            }
        }
        else {
            this.setState({
                show: true,
                basicTitle: 'Choose file to continue',
                basicType: "danger",
            });
        }

    }

    changeFileHandlerForAoPlan = async (e) => {
        var config = {
            headers: [

                {
                    name: 'AO Code',
                    inputName: 'area_code',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'AO Name',
                    inputName: 'area_name',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'HHHD',
                    inputName: 'HHHD',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'HHHG',
                    inputName: 'HHHG',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'HHHU',
                    inputName: 'HHHU',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'HM4N',
                    inputName: 'HM4N',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'HM5V',
                    inputName: 'HM5V',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'HM6C',
                    inputName: 'HM6C',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },

            ]
        }

        var data = CSVFileValidator(e.target.files[0], config)
            .then(csvData => {
                console.log(csvData.data)
                this.setState({
                    cvscontentForAoPlan: csvData.data
                })
            })
            .catch(err => { })

        var out = new Promise(function (reject, resolve) {
            var reader = new FileReader();
            reader.onload = async function (e) {
                var contents = await e.target.result;
                resolve(contents);
            };
            var tt = reader.readAsText(e.target.files[0]);
        });

        this.setState({
            file: e.target.files[0]
        });

        if (e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
        }
        else {
            e.target.value = null;
            this.setState({
                uploadFile: '',
                show: true,
                basicType: 'danger',
                basicTitle: 'Please upload file having extensions .csv only.',
            });
        }


    }

    onSubmitBulkUploadForAoPlan = async (event) => {
        event.preventDefault()
        this.setState({
            loadshow: 'show-m',
            overly: 'show-m',
        })
        var month = this.state.selectedMonth.toLocaleString('default', { month: 'long' });
        var year = this.state.selectedMonth.getFullYear()
        var csvData = this.state.cvscontentForAoPlan
        console.log(csvData.length, "lenght1729")
        if (csvData != undefined && csvData != '') {
            if (csvData.length > 0) {
                var csvcontent = []
                csvData.map(e => {
                    if (e.area_code != ""){
                        csvcontent.push(e)
                    }
                })
                console.log(csvcontent.length , "lenght_csvcontent")
                var params = {
                    csvcontent: csvcontent,
                    uploaded_by: localStorage.getItem('email'),
                    month: month,
                    year: year,
                }
                console.log(params)
                redirectURL.post('dashboard/bulkUploadForAoplan', params).then(response => {
                    if (response.data.status == 'success') {
                        this.setState({
                            show: true,
                            basicTitle: 'AO Plan Data Updated Successfully',
                            basicType: "success",
                            loadshow: 'show-n',
                            overly: 'show-n',
                            sliderForMonthSob: "",
                        })
                    } else {
                        this.setState({
                            show: true,
                            basicTitle: 'AO Plan Data not Updated',
                            basicType: "danger",
                            loadshow: 'show-n',
                            overly: 'show-n',
                            sliderForMonthSob: "",
                        })
                    }
                })
            }
        }
        else {
            this.setState({
                show: true,
                basicTitle: 'Choose file to continue',
                basicType: "danger",
            });
        }

    }


    changeFileHandlerForTptAreaCode = async (e) => {
        var config = {
            headers: [

                {
                    name: 'TPT Code',
                    inputName: 'transporter_code',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'TPT Name',
                    inputName: 'transporter_name',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'Truck Type',
                    inputName: 'vehicle_type',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'AO Code',
                    inputName: 'consignee_area',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'Area Codes',
                    inputName: 'consignee_code',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },

            ]
        }

        var data = CSVFileValidator(e.target.files[0], config)
            .then(csvData => {
                console.log(csvData.data)
                this.setState({
                    csvcontentForTptAreaCode: csvData.data
                })
            })
            .catch(err => { })

        var out = new Promise(function (reject, resolve) {
            var reader = new FileReader();
            reader.onload = async function (e) {
                var contents = await e.target.result;
                resolve(contents);
            };
            var tt = reader.readAsText(e.target.files[0]);
        });

        this.setState({
            file: e.target.files[0]
        });

        if (e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
        }
        else {
            e.target.value = null;
            this.setState({
                uploadFile: '',
                show: true,
                basicType: 'danger',
                basicTitle: 'Please upload file having extensions .csv only.',
            });
        }


    }

    onSubmitBulkUploadForTptAreaCode = async (event) => {
        event.preventDefault()
        this.setState({
            loadshow: 'show-m',
            overly: 'show-m',
        })
        var month = this.state.selectedMonth.toLocaleString('default', { month: 'long' });
        var year = this.state.selectedMonth.getFullYear()
        var csvData = this.state.csvcontentForTptAreaCode
        console.log(csvData.length, "1729")
        if (csvData != undefined && csvData != '') {
            if (csvData.length > 0) {
                var params = {
                    csvcontent: csvData,
                    uploaded_by: localStorage.getItem('email'),
                    month: month,
                    year: year,
                }
                console.log(params)
                redirectURL.post('dashboard/bulkUploadFortptAreaCode', params).then(response => {
                    if (response.data.status == 'success') {
                        this.setState({
                            show: true,
                            basicTitle: 'TPT Area Code Data Updated Successfully',
                            basicType: "success",
                            loadshow: 'show-n',
                            overly: 'show-n',
                            sliderForMonthSob: "",
                        })
                    } else {
                        this.setState({
                            show: true,
                            basicTitle: 'TPT Area Code Data not Updated',
                            basicType: "danger",
                            loadshow: 'show-n',
                            overly: 'show-n',
                            sliderForMonthSob: "",
                        })
                    }
                })
            }
        }
        else {
            this.setState({
                show: true,
                basicTitle: 'Choose file to continue',
                basicType: "danger",
            });
        }

    }



    closeAlert = () => {
        this.setState({
            show: false
        });
        if(this.state.basicType=="success" ||this.state.basicType1 =="success"){
            window.location.reload()
        }
    }
    DeleteTptAreaCodeData = () => {
        var row_id = this.state.current_delete_data_id
        redirectURL.post('dashboard/deleteTPTAreaCodeData' , {row_id}).then(response => {
            if(response.data.status == 'success'){
                this.setState({
                    show:true,
                    basicType:'success',
                    basicTitle:"Successfully Deleted the Data",
                    show1:false,
                    current_delete_data_id:"",

                })
            }
        })
        this.setState({
            show1: false
        });
        // window.location.reload()
    }

    onClickTab = (tab) => {
        if (tab == 1){
            this.setState({
                ao_summary_tab:'show-m',
                zone_summary_tab : 'show-n',
                ao_summary_btn:'btn-danger',
                zone_summary_btn:'btn-default',
            })
        }
        if (tab == 2){
            this.setState({
                ao_summary_tab:'show-n',
                zone_summary_tab : 'show-m',
                ao_summary_btn:'btn-default',
                zone_summary_btn:'btn-danger',
                
            })
        }
    }

    editTPTAreaCodeData = (params) => {
        if(params.colDef.field == '_id'){
            console.log(params.data)
            this.setState({
                openmodal:true,
                update_vehicle_type:{label : params.data.vehicle_type , value  :params.data.vehicle_type},
                update_transporter_code:params.data.transporter_code,
                update_transporter_name:params.data.transporter_name,
                update_consignee_code:params.data.consignee_code,
                update_consignee_area:params.data.consignee_area,
                current_update_data_id :params.data._id
            })
        }
        if(params.colDef.field =="remove"){
            console.log(params.data)
            this.setState({
                show1:true, 
                basicType1:"warning",
                basicTitle1:"Do want to Delete Data ?",
                current_delete_data_id : params.data._id,
            })
        }
    }

    onCloseModal = () => {
        this.setState({
            openmodal:false,
            update_consignee_area:"",
                    update_consignee_code:"",
                    update_transporter_code:"",
                    update_vehicle_type:this.state.update_vehicle_type,
                    update_transporter_name:'',
        })
    }

    onChangeTPTAreaCodeData = (e , f) => {
       if(e!='update_vehicle_type'){
        this.setState({
            [e]:f.target.value
        })
       }
       else{
        this.setState({
            [e]:f
        })
       }
    }

    formUpdateTransporterDetails = async (event) => {
        event.preventDefault()
        var data = {
            transporter_code:this.state.update_transporter_code,
            transporter_name:this.state.update_transporter_name,
            vehicle_type:this.state.update_vehicle_type.value,
            consignee_area:this.state.update_consignee_area,
            consignee_code : this.state.update_consignee_code,
        }
        var row_id = this.state.current_update_data_id
        console.log(data , row_id , "1729")
        redirectURL.post("dashboard/updateDataForTPTAreaCode" ,{data , row_id}).then(response => {
            if (response.data.status =="success"){
                this.setState({
                    show:true,
                    basicType:"success",
                    basicTitle:"Sucessfully Updated Data",
                    openmodal:false,
                    update_consignee_area:"",
                    update_consignee_code:"",
                    update_transporter_code:"",
                    update_vehicle_type:this.state.update_vehicle_type,
                    update_transporter_name:'',
                })
            }else if(response.data.status =="exists"){
                this.setState({
                    show:true,
                    basicType:"warning",
                    basicTitle:response.data.message,
                })
            }
            else{
                this.setState({
                    show:true,
                    basicType:"danger",
                    basicTitle:"Data not Updated",
                    update_consignee_area:"",
                    update_consignee_code:"",
                    update_transporter_code:"",
                    update_vehicle_type:this.state.update_vehicle_type,
                    update_transporter_name:'',
                })
            }
        })
    }

    AddTPTAreaCodeData = () => {
        this.setState({
            openmodal:true
        })
    }

    hideAlert = () => {
        this.setState({
            show1:false
        })
    }

    render() {
        var columnwithDefsForDefault = [
            {
                headerName: "AO Name",
                field: "area_name",
                width: 120
            },
            {
                headerName: "AO Code",
                field: "area_code",
                width: 120
            },
            {
                headerName: "Zone",
                field: "zone",
                width: 120
            },
            {
                headerName: "TPT Code",
                field: "transporter_code",
                width: 120
            },
            {
                headerName: "TPT Name",
                field: "transporter_name",
                width: 120
            },
            {
                headerName: "Vehicle Type",
                field: "vehicle_type",
                width: 120
            },
            {
                headerName: "Plant",
                field: "plant_code",
                width: 120
            },
            {
                headerName: "Month SOB",
                field: "month_sob",
                width: 120
            },
            {
                headerName: "MTD SOB Disp",
                field: "mtd_sob_disp",
                width: 160
            },

            {
                headerName: "MTD Actual Disp",
                field: "mtd_actual_disp",
                width: 160
            },
            {
                headerName: "AO Ach %",
                field: "ao_ach%",
                width: 120
            },
            {
                headerName: "TPT Ach %",
                field: "tpt_ach%",
                width: 120
            },
            {
                headerName: "Variance",
                field: "variance",
                width: 120
            },
            {
                headerName: "Truck Avail Yesterday",
                field: "truck_avail_yes",
                width: 120
            },
            {
                headerName: "Truck Loaded Yest",
                field: "truck_loaded_yest",
                width: 120
            },
            {
                headerName: "Truck Avail Today",
                field: "truck_avail_today",
                width: 120
            },
            {
                headerName: "Yest Dispatch With OTH Transp",
                field: "total_other_tpt_grp_qty_yesterday",
                width: 120
            },
            {
                headerName: "MTD Disp With Other TPT",
                field: "other_tpt_tot_qty",
                width: 120
            },


        ]

        var columnwithDefsForSummary = [
            {
                headerName: "AO Name",
                field: "area_name",
                width: 120
            },
            {
                headerName: "TPT Code",
                field: "transporter_code",
                width: 120
            },
            {
                headerName: "TPT Name",
                field: "transporter_name",
                width: 120
            },
            {
                headerName: "Vehicle Type",
                field: "vehicle_type",
                width: 120
            },
            {
                headerName: "Month SOB",
                field: "month_sob",
                width: 120
            },
            {
                headerName: "MTD SOB Disp",
                field: "mtd_sob_disp",
                width: 160
            },

            {
                headerName: "MTD Actual Disp",
                field: "mtd_actual_disp",
                width: 160
            },
            {
                headerName: "AO Ach %",
                field: "ao_ach%",
                width: 120
            },
            {
                headerName: "TPT Ach %",
                field: "tpt_ach%",
                width: 120
            },
            {
                headerName: "MTD Disp With Other TPT",
                field: "other_tpt_tot_qty",
                width: 120
            },
        ]

        var columnwithDefsForTPTAreaCode = [
            {
                headerName: "TPT Code",
                field: "transporter_code",
                width: 120
            },
            {
                headerName: "TPT Name",
                field: "transporter_name",
                width: 120
            },
            {
                headerName: "Vehicle Type",
                field: "vehicle_type",
                width: 120
            },
            {
                headerName: "AO Name",
                field: "consignee_area",
                width: 200
            },
            {
                headerName: "AO Code",
                field: "consignee_code",
                width: 200
            },
            {
                field:'_id',
                headerName:'EDIT',
                colId:"_id",
                pinned:"left",
                width: 120,
                cellRenderer:function(params){
                    let basestring = params.data._id;
                    let encryptedstring = window.btoa(basestring);
                    var htmloption = '<a class="custom-btn label label-success"><i class="icofont icofont-edit"></i> Edit</a>';
                    return htmloption;
                }            
            },

            {
                field:'remove',
                headerName:'REMOVE',
                colId:"remove",
                // pinned:"right",
                width: 120,
                cellRenderer:function(params){
                    let basestring = params.data._id;
                    let encryptedstring = window.btoa(basestring);
                    var htmloption = '<a class="custom-btn label label-success"><i class="icofont icofont-trash"></i>Delete</a>';
                    return htmloption;
                }            
            }
        ]
        const modalStyles = {
			width: '800px !important',
		}

        return (
            <div className='container-fluid'>
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}>
                </SweetAlert>
                <SweetAlert
                    showCancel
                    show={this.state.show1}
                    type={this.state.basicType1}
                    title={this.state.basicTitle1}
                    cancelBtnBsStyle="default"
                    onCancel={this.hideAlert}
                    onConfirm={this.DeleteTptAreaCodeData}>
                </SweetAlert>
                <div className='row'>
                    <div className='col-xl-12 col-lg-12'>
                        <div className='' style={{ padding: "10px" }}>
                            <div className="row card-body" >
                                <div className="row col-xl-12 col-lg-12">
                                    <div className="row col-xl-12 col-lg-12">
                                        <div className="form-group col-xl-3 col-lg-3">
                                            <label>Select Transporter: </label>
                                            <Select placeholder={""} value={this.state.transporter} isMulti={true} className="border-radius-0" style={{ borderRadius: "0px" }} options={this.state.transporters_list} onChange={this.changeHandler.bind(this, 'transporter')} name='' required />
                                        </div>

                                        <div className="form-group col-xl-3 col-lg-3">
                                            <label>Select Plant Name: </label>
                                            <Select placeholder={""} closeMenuOnSelect={true} value={this.state.plant_name} onChange={this.changeHandler.bind(this, 'plant_name')} isMulti={true} className="border-radius-0" options={[
                                                { label: 'ALL', value: 'ALL' },
                                                { label: 'Haridwar', value: 'HHHU' },
                                                { label: 'Gurgaon', value: 'HHHG' },
                                                { label: 'Dharuhera', value: 'HHHD' },
                                                { label: 'Neemrana', value: 'HM4N' },
                                                { label: 'Chittoor', value: 'HM6C' },
                                                { label: 'Halol', value: 'HM5V' }
                                            ]} style={{ borderRadius: "0px" }} required />

                                        </div>
                                        <div className="col-xl-3 col-lg-3 form-group">
                                            <label>From Date :</label>

                                            <input type="text" id="from_date_cs" name='date' autoComplete="off" className="os_filters_date form-control" required />


                                        </div>

                                        <div className="col-xl-3 col-lg-3 form-group">
                                            <label>To Date :</label>
                                            <input type="text" autoComplete="off" id="to_date_cs" name='date' className="os_filters_date form-control" required />
                                        </div>


                                    </div>
                                </div>

                                <div className="row col-xl-12 col-lg-12" >

                                    <div className="form-group col-xl-3 col-lg-3">
                                        <label>Select AO Name: </label>
                                        <Select placeholder={""} closeMenuOnSelect={true} value={this.state.ao_name} isMulti={true} className="border-radius-0" onChange={this.changeHandler.bind(this, 'ao_name')} style={{ borderRadius: "09px" }} options={this.state.area_names_list} required />

                                    </div>
                                    <div className="form-group col-xl-3 col-lg-3">
                                        <label>Select Vehicle Type: </label>
                                        <Select placeholder={""} closeMenuOnSelect={true} value={this.state.vehicle_type} isMulti={true} className="border-radius-0" onChange={this.changeHandler.bind(this, 'vehicle_type')} style={{ borderRadius: "09px" }} options={[
                                            { label: 'ALL', value: 'ALL' },
                                            { label: 'Trailer', value: 'Trailer' },
                                            { label: 'Truck', value: 'Truck' },
                                            { label: '3 Deck', value: '3 Deck' }
                                        ]} required />

                                    </div>

                                    <div className="col-xl-3 col-lg-3 form-group">
                                        <label>&nbsp;&nbsp;</label>
                                        <button type="button" style={{ marginTop: "30px" }} className="btn btn-success" onClick={this.onClickSubmit}  >Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'col-xl-12 col-lg-12'} >
                        <button className={'btn '+this.state.ao_summary_btn} style={{ paddingLeft: "15px" }} onClick={this.onClickTab.bind(this , 1)}>AO Summary</button>
                        <button className={'btn '+this.state.zone_summary_btn} onClick={this.onClickTab.bind(this , 2)}>Zone Summary</button>
                    </div>
                    <div className={"col-xl-12 col-lg-12 "+this.state.ao_summary_tab}>
                        <div className="card">
                            <div className="card-header">
                                <h5>
                                    Cost Saving Dashbaord - AO Summary
                                </h5>
                            </div>
                            <div className="card-body pt-10px" >
                                <div className="row">
                                    <div className="col-xl-12 mb-3 ">
                                        <button type="button" className="btn btn-warning float-right" onClick={this.getMonthSobSlider} >Bulk Uplaod MonthSOB</button>
                                        <button type="button" className="btn btn-primary float-right" onClick={this.getMonthsobCalenderSlider} style={{ marginRight: "10px" }}>Bulk Uplaod MonthSOB Calender</button>
                                        <button type="button" className="btn btn-danger float-right" onClick={this.getAoPlanSlider} style={{ marginRight: "10px" }}>Bulk Uplaod AO Plan</button>
                                        <button type="button" className="btn btn-dark float-right" onClick={this.getTptAreaCoeSlider} style={{ marginRight: "10px" }}>Bulk Uplaod TPT Area Code</button>
                                    </div>
                                    {['bhaskar.das@heromotocorp.com',"arindam.das@heromotocorp.com","shubham1.gupta@heromotocorp.com","amit.chaurasia@heromotocorp.com","ashish4.sharma@heromotocorp.com", "hero.dct@enmovil.in"  ].includes(localStorage.getItem('email'))?

                                    <div className="col-xl-12 mb-3 ">
                                        <button type="button" className="btn btn-dark float-right" onClick={this.getTptAreaCoeSlider} style={{ marginRight: "10px" }}>Update TPT Area Code</button>
                                    </div>:""}
                                </div>

                                <div id="myGrid" style={{ height: "580px", width: "100%" }} className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefsForDefault}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.ao_summary_data}
                                        groupHeaderHeight={40}
                                        headerHeight={60}
                                        floatingFiltersHeight={50}
                                        pivotGroupHeaderHeight={30}
                                        pivotHeaderHeight={50}
                                        rowHeight={40}
                                        // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        frameworkComponents={this.state.frameworkComponents}
                                        detailCellRendererParams={this.state.detailCellRendererParams}
                                        //editType={this.state.editType}
                                        stopEditingWhenGridLosesFocus={true}
                                        //floatingFilter={true}
                                        alwaysShowHorizontalScroll={true}
                                        alwaysShowVerticalScroll={true}
                                        enableCellChangeFlash={false}
                                        //suppressCellFlash={true}
                                        rowClassRules={this.state.rowClassRules}
                                        // onCellDoubleClicked={this.onRowClicked.bind(this)}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        masterDetail={true}
                                        // getRowHeight={this.state.getRowHeight}
                                        // onCellEditingStopped={this.onCellUpdateData.bind(this)}

                                        rowSelection={this.state.rowSelection}
                                        // onRowSelected={this.onRowSelection.bind(this)}
                                        suppressRowClickSelection={true}
                                        // suppressCellSelection= {true}
                                        overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                        excelStyles={this.state.excelStyles}
                                    // onCellClicked={this.onCellClicked.bind(this)}
                                    />

                                </div>

                                <div className={"slide-r " + (this.state.sliderForMonthSob)} style={{ overflow: "auto" }}>
                                    <div className="slide-r-title">
                                        <h4>
                                            Bulk Upload MonthSOB
                                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                                        </h4>
                                    </div>
                                    <div className="slide-r-body" style={{ position: "relative" }}>
                                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                                            <form method="POST" id='upform' className="theme-form col-xl-12 col-lg-12" onSubmit={this.onSubmitBulkUploadForMonthSob}>
                                                <div className="col-xl-12 col-lg-12 row form-group">
                                                    <label className="col-xl-12 col-lg-12">Upload File *</label>
                                                    <input
                                                        type="file"
                                                        name="uploadfile"
                                                        className="form-control"
                                                        onChange={this.changeFileHandlerForMonthSob} />
                                                </div>

                                                <div className="col-xl-12 col-lg-12 form-group">
                                                    <button type="submit" className="btn btn-success">Submit</button>
                                                </div>
                                                {/* <div className="col-xl-12 col-lg-12 form-group">
								Date format should be in yyyy:mm:dd hh:mm:ss
							</div> */}
                                                <div className="col-xl-12 col-lg-12 form-group">
                                                    <a href={require("../../assets/json/bulk_upload_month_sob.csv")} target="_blank" className="btn btn-warning">Download Template</a>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>

                                <div className={"slide-r " + (this.state.sliderForMonthSobCalender)} style={{ overflow: "auto" }}>
                                    <div className="slide-r-title">
                                        <h4>
                                            Bulk Upload Month SOB Calender
                                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                                        </h4>
                                    </div>
                                    <div className="slide-r-body" style={{ position: "relative" }}>
                                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                                            <form method="POST" id='upform' className="theme-form col-xl-12 col-lg-12" onSubmit={this.onSubmitBulkUploadForMonthSobCalender}>
                                                <div className="col-xl-12 col-lg-12 row form-group">
                                                    <label className="col-xl-12 col-lg-12">Upload File *</label>
                                                    <input
                                                        type="file"
                                                        name="uploadfile"
                                                        className="form-control"
                                                        onChange={this.changeFileHandlerForMonthSobCalender} />
                                                </div>

                                                <div className="col-xl-12 col-lg-12 form-group">
                                                    <button type="submit" className="btn btn-success">Submit</button>
                                                </div>
                                                {/* <div className="col-xl-12 col-lg-12 form-group">
								Date format should be in yyyy:mm:dd hh:mm:ss
							</div> */}
                                                <div className="col-xl-12 col-lg-12 form-group">
                                                    <a href={require("../../assets/json/bulk_upload_month_sob_calender.csv")} target="_blank" className="btn btn-warning">Download Template</a>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>

                                <div className={"slide-r " + (this.state.sliderForAoPlan)} style={{ overflow: "auto" }}>
                                    <div className="slide-r-title">
                                        <h4>
                                            Bulk Upload AO Plan
                                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                                        </h4>
                                    </div>
                                    <div className="slide-r-body" style={{ position: "relative" }}>
                                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                                            <form method="POST" id='upform' className="theme-form col-xl-12 col-lg-12" onSubmit={this.onSubmitBulkUploadForAoPlan} >
                                                <div className="col-xl-12 col-lg-12 row form-group">
                                                    <label className="col-xl-12 col-lg-12">Upload File *</label>
                                                    <input
                                                        type="file"
                                                        name="uploadfile"
                                                        className="form-control"
                                                        onChange={this.changeFileHandlerForAoPlan} />
                                                </div>

                                                <div className="col-xl-12 col-lg-12 form-group">
                                                    <button type="submit" className="btn btn-success">Submit</button>
                                                </div>
                                                {/* <div className="col-xl-12 col-lg-12 form-group">
								Date format should be in yyyy:mm:dd hh:mm:ss
							</div> */}
                                                <div className="col-xl-12 col-lg-12 form-group">
                                                    <a href={require("../../assets/json/bulk_upload_ao_paln.csv")} target="_blank" className="btn btn-warning">Download Template</a>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className={"slide-r " + (this.state.sliderForTptAreaCode)} style={{ overflow: "auto" }}>
                                    <div className="slide-r-title">
                                        <h4>
                                            Udpate TPT Area Code Data
                                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                                        </h4>
                                    </div>
                                    <div className="slide-r-body" style={{ position: "relative" }}>
                                        <div className="row">
                                            <div className="col-xl-12 mb-3 ">
                                                <button type="button" className="btn btn-warning float-right" onClick={this.AddTPTAreaCodeData} >ADD ITEM</button>
                                            </div>
                                        </div>
                                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >

                                            <div id="myGrid" style={{ height: "580px", width: "100%" , marginLeft : '15px' }} className="ag-theme-balham">
                                                <AgGridReact
                                                    modules={this.state.modules}
                                                    columnDefs={columnwithDefsForTPTAreaCode}
                                                    defaultColDef={this.state.defaultColDef}
                                                    rowData={this.state.tpt_area_code_data}
                                                    // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                                    enableCharts={true}
                                                    enableRangeSelection={true}
                                                    autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                    onGridReady={this.onGridReady}
                                                    onGridState={this.onGridState}
                                                    statusBar={this.state.statusBar}
                                                    sideBar={this.state.sideBar}
                                                    paginationPageSize={this.state.paginationPageSize}
                                                    pagination={true}
                                                    frameworkComponents={this.state.frameworkComponents}
                                                    detailCellRendererParams={this.state.detailCellRendererParams}
                                                    //editType={this.state.editType}
                                                    stopEditingWhenGridLosesFocus={true}
                                                    //floatingFilter={true}
                                                    alwaysShowHorizontalScroll={true}
                                                    alwaysShowVerticalScroll={true}
                                                    enableCellChangeFlash={false}
                                                    //suppressCellFlash={true}
                                                    rowClassRules={this.state.rowClassRules}
                                                    // onCellDoubleClicked={this.onRowClicked.bind(this)}
                                                    gridOptions={{
                                                        context: { componentParent: this }
                                                    }}
                                                    masterDetail={true}
                                                    // getRowHeight={this.state.getRowHeight}
                                                    // onCellEditingStopped={this.onCellUpdateData.bind(this)}

                                                    rowSelection={this.state.rowSelection}
                                                    // onRowSelected={this.onRowSelection.bind(this)}
                                                    suppressRowClickSelection={true}
                                                    // suppressCellSelection= {true}
                                                    overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                                    excelStyles={this.state.excelStyles}
                                                onCellClicked={this.editTPTAreaCodeData.bind(this)}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                    <div className={"col-xl-12 col-lg-12 "+this.state.zone_summary_tab}>
                        <div className="card">
                            <div className="card-header">
                                <h5>
                                    Cost Saving Dashbaord - Zone Summary
                                </h5>
                            </div>
                            <div className="card-body pt-10px" >
                                <div id="myGrid" style={{ height: "580px", width: "100%" }} className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefsForSummary}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.zone_summary_data}
                                        groupHeaderHeight={40}
                                        headerHeight={60}
                                        floatingFiltersHeight={50}
                                        pivotGroupHeaderHeight={30}
                                        pivotHeaderHeight={50}
                                        rowHeight={40}
                                        // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        frameworkComponents={this.state.frameworkComponents}
                                        detailCellRendererParams={this.state.detailCellRendererParams}
                                        //editType={this.state.editType}
                                        stopEditingWhenGridLosesFocus={true}
                                        //floatingFilter={true}
                                        alwaysShowHorizontalScroll={true}
                                        alwaysShowVerticalScroll={true}
                                        enableCellChangeFlash={false}
                                        //suppressCellFlash={true}
                                        rowClassRules={this.state.rowClassRules}
                                        // onCellDoubleClicked={this.onRowClicked.bind(this)}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        masterDetail={true}
                                        // getRowHeight={this.state.getRowHeight}
                                        // onCellEditingStopped={this.onCellUpdateData.bind(this)}

                                        rowSelection={this.state.rowSelection}
                                        // onRowSelected={this.onRowSelection.bind(this)}
                                        suppressRowClickSelection={true}
                                        // suppressCellSelection= {true}
                                        overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                        excelStyles={this.state.excelStyles}
                                    // onCellClicked={this.onCellClicked.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>

                    <Modal open={this.state.openmodal} onClose={this.onCloseModal} styles={modalStyles}>
                        <div className={"row"} style={{ padding: '5px 0px', width: '100%', overflow: 'hidden', zIndex: '9999', }} >
                            <form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formUpdateTransporterDetails}>
                                <div className="row col-xl-12 col-lg-12">
                                    <div className="col-xl-4 col-lg-4 form-group">
                                        <label className="col-xl-12 col-lg-12">TPT Code</label>
                                        <input type="text" 
                                            id="update_transporter_code" value={this.state.update_transporter_code} className="form-control" onChange={this.onChangeTPTAreaCodeData.bind(this , "update_transporter_code")} required />
                                    </div>
                                    <div className="col-xl-4 col-lg-4 form-group">
                                        <label className="col-xl-12 col-lg-12">TPT Name</label>
                                        <input type="text" 
                                            id="update_transporter_name" value={this.state.update_transporter_name} className="form-control" onChange={this.onChangeTPTAreaCodeData.bind(this , "update_transporter_name")} required />
                                    </div>
                                    <div className="col-xl-4 col-lg-4 form-group">
                                        <label className="col-xl-12 col-lg-12"> Select Vehicle Type</label>
                                        <Select
                                            className="border-radius-0"
                                            isMulti={false}
                                            id="update_vehicle_type"
                                            style={{ borderRadius: "0px" }}
                                            value={this.state.update_vehicle_type}
                                            onChange={this.onChangeTPTAreaCodeData.bind(this , "update_vehicle_type")}
                                            options={[
                                                { label: 'Trailer', value: 'Trailer' },
                                                { label: 'Truck', value: 'Truck' },
                                                { label: '3 Deck', value: '3 Deck' }]}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="row col-xl-12 col-lg-12">
                                    <div className="col-xl-6 col-lg-6 form-group">
                                        <label className="col-xl-12 col-lg-12">Area Name</label>
                                        <input type="text" 
                                            id="update_consignee_area"
                                            className="form-control"
                                            value={this.state.update_consignee_area}
                                            onChange={this.onChangeTPTAreaCodeData.bind(this , "update_consignee_area")} required />

                                    </div>
                                    <div className="col-xl-6 col-lg-6 form-group">
                                        <label className="col-xl-12 col-lg-12">Area Code</label>
                                        <input type="text" 
                                            id="update_consignee_code" className="form-control"
                                            value={this.state.update_consignee_code}
                                            onChange={this.onChangeTPTAreaCodeData.bind(this , "update_consignee_code")} required />
                                    </div>
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group text-center">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </div>
                            </form>
                        </div>
                    </Modal>

                    <div className={"dataLoadpage " + (this.state.loadshow)}>
                    </div>
                    <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                        <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                    </div>
                    <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll}></div>

                </div>
            </div>
        )
    }
}
function loadDateTimeScript() {
    // alert("timeDate");
    $('.datetimepicker_mask').datetimepicker({
        format: 'd-m-Y H:i'
    });
    $('.os_filters_date').datetimepicker({
        format: 'Y-m-d H:i',
        timepicker: true
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}

$(document).on("change", "#to_date_cs", function (e) {
    e.preventDefault();
    var bookingDate = $("#from_date_cs").val();
    var cargoDate = $("#to_date_cs").val();
    // var bookingDate = bookingDate.split("-")[2]+"-"+bookingDate.split("-")[1]+"-"+bookingDate.split("-")[0]
    // var cargoDate = cargoDate.split("-")[2]+"-"+cargoDate.split("-")[1]+"-"+cargoDate.split("-")[0]
    var startDate = new Date(bookingDate);
    var endDate = new Date(cargoDate);
    // var startTime = startDate.getTime();
    // var endTime = endDate.getTime();
    var current_date = moment.parseZone(new Date())
    console.log(startDate, endDate);
    if(endDate  > current_date){
        $("#to_date_cs").val("");
        alert("Can't Select To Date greater than Today");
    }
    if (startDate > endDate) {
        $("#to_date_cs").val("");
        alert("To Date should be greater than or equal to From Date");
    }
})

$(document).on("change", "#from_date_cs", function (e) {
    e.preventDefault();
    var bookingDate = $("#from_date_cs").val();
    var cargoDate = $("#to_date_cs").val();
    var startDate = new Date(bookingDate);
    var endDate = new Date(cargoDate);
    // var startTime = startDate.getTime();
    // var endTime = endDate.getTime();
    console.log(startDate, endDate);
    if (startDate > endDate) {
        $("#from_date_cs").val("");
        alert("From Date should be less than equal to To Date");
    }
})

function checkIfArrayIsUnique(myArray) {
    return myArray.length === new Set(myArray).size;
}