import React, { Component } from 'react';
import CountUp from 'react-countup';
import axios from 'axios';
var redirectURL = require('../redirectURL');

export default class ConsignmentCountersComponent extends Component {
    constructor(props){
    	super(props);
    	
    	this.state={
    		records:[],
            plantcounters : {},
            TVP_Bangalore_records :0,
            Gurgaon_records : 0,
            Gujarat_records : 0,
            Siliguri_records : 0,
            Manesar_records : 0,
            TVP_Nagpur_records : 0,
            rowData : []
    	}
    }
    componentDidMount(){
        this.setState({
            TVP_Bangalore_records : this.props.TVP_Bangalore_records,
            Gurgaon_records : this.props.Gurgaon_records,
            Gujarat_records : this.props.Gujarat_records,
            Siliguri_records : this.props.Siliguri_records,
            Manesar_records : this.props.Manesar_records,
            TVP_Nagpur_records : this.props.TVP_Nagpur_records
        })
    }
    componentWillReceiveProps = async (newprops) =>{
        if(newprops.rowData != this.state.rowData)
        {
            console.log(newprops);
            this.setState({
                TVP_Bangalore_records : newprops.TVP_Bangalore_records,
                Gurgaon_records : newprops.Gurgaon_records,
                Gujarat_records : newprops.Gujarat_records,
                Siliguri_records : newprops.Siliguri_records,
                Manesar_records : newprops.Manesar_records,
                TVP_Nagpur_records : newprops.TVP_Nagpur_records
            })
        }
        

    }

    onClickCounter(data){
        console.log("click")
        this.props.context.onClickCounterShowData(data)
    }
    render(){
        return (
            <div className="row">

            <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                <div className="card">
                    <div className="card-body" id="counterCardBody">
                        <div className="crm-numbers pb-0">
                            
                            <div className="row">
                                <div className="col cirlce-d cursorPointer"  onClick={this.onClickCounter.bind(this,"F")}>
                                    <span className="f13"><i className="icofont icofont-ui-browser f24 greenfont"></i><br /> Gurgaon</span>
                                    <h4 className="txt-info f40"><span className="counter"><CountUp end={this.state.Gurgaon_records}/></span></h4>
                                </div>
                                <div className="col cirlce-d cursorPointer"  onClick={this.onClickCounter.bind(this,"M")}>
                                    <span className="f13"><i className="icofont icofont-ui-browser f24 greenfont"></i><br /> Manesar</span>
                                    <h4 className="txt-info f40"><span className="counter"><CountUp end={this.state.Manesar_records}/></span></h4>
                                </div>
                                <div className="col cirlce-d cursorPointer"  onClick={this.onClickCounter.bind(this,"G")}>
                                    <span className="f13"><i className="icofont icofont-ui-browser f24 greenfont"></i><br /> Gujarat</span>
                                    <h4 className="txt-info f40"><span className="counter"><CountUp end={this.state.Gujarat_records}/></span></h4>
                                </div>
                                <div className="col cirlce-d cursorPointer"  onClick={this.onClickCounter.bind(this,"I")}>
                                    <span className="f13"><i className="icofont icofont-ui-browser f24 greenfont"></i><br /> Siliguri</span>
                                    <h4 className="txt-info f40"><span className="counter"><CountUp end={this.state.Siliguri_records}/></span></h4>
                                </div>
                                <div className="col cirlce-d cursorPointer"  onClick={this.onClickCounter.bind(this,"B")}>
                                    <span className="f13"><i className="icofont icofont-ui-browser f24 greenfont"></i><br /> TVP Bangalore</span>
                                    <h4 className="txt-info f40"><span className="counter"><CountUp end={this.state.TVP_Bangalore_records}/></span></h4>
                                </div>
                                <div className="col cirlce-d cursorPointer"  onClick={this.onClickCounter.bind(this,"N")}>
                                    <span className="f13"><i className="icofont icofont-ui-browser f24 greenfont"></i><br /> TVP Nagpur</span>
                                    <h4 className="txt-info f40"><span className="counter"><CountUp end={this.state.TVP_Nagpur_records}/></span></h4>
                                </div>
                            
                                    
                                
                                
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
            
            </div>
        );
    }
}






