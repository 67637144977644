/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import _, { join } from "lodash";
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';
import DateFormater from '../layouts/dateComponent';
import {
	getDDMMYYYY,
	getDDMMYYYYHHMMSS,
	getYYYYMMDD,
	getYYYYMMDDHHMMSS,
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD,
	getHyphenDDMMYYYYHHMMSS,
	getHyphenDDMMYYYY,
	getDDMMMYYYYHHMMDefault
} from '../common/utils';
import 'react-vertical-timeline-component/style.min.css';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
// import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
$.datetimepicker.setLocale('en');
// var infoBox = require('../common/google-infowindow');

var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

export default class TruckMonthlyBillingInfo extends Component{

    state ={
        startDate : '',
			modules: AllModules,
			eventGridAction: "gridAction",
			eventFormAction: "formAction",
			pageTitle: "Transporter Performance Dashboard",
			loadshow:'show-n',
			overly : "show-n",
            alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			dept_code:'',
            defaultColDef: {
				sortable: true,
				filter: true,
				// editable: true,
				resizable: true
			},
			animateRows: true,
            showToolPanel: false,
			rowSelection: "single" ,
			context: { componentParent: this },
			frameworkComponents : {
            },
			loadingCellRenderer: "customLoadingCellRenderer",
			paginationPageSize:50,
            rowClassRules : {
                "colorForDangerLight" : function(params){
                    if(params.data.parameters == "growth")
                    {
                        return true;
                    }
                    else{
                        return false;
                    }
                } 
            },
            billableTrucks : 1000,
            basePrice : 44,
            totalPrice : ''
    }

    componentDidMount(){
        var sdate = moment.parseZone().format('YYYY-MM-DD');
        let dpt = JSON.parse(localStorage.getItem("dept_code"))[0]
        this.setState({
            dept_code: dpt,
            startDate : sdate,
            loadshow:'show-m',
			overly : "show-m",
        })
        this.loadPageData(sdate)
    }

    handlerStartDateTime = e =>{
        var d = new Date(e._d);
		var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());
		this.setState({
			startDate: startdate
		});
    }

    selectMonthDate = e =>{
        // console.log("e",e.target.value)
        this.setState({
			startDate: `${e.target.value}-01`
		});
    }

    loadPageData = (args) =>{
        let data = args.split("-")
        let year = data[0]
        let month = data[1]
        let params = {
            // month : 8,
            month : month,
            year : year
        }
        redirectURL.post("/consignments/getMonthlyTrucksInfo", params).then( response =>{
            let recievedData = response.data
            // console.log("resposne", recievedData)
            let totalTrucks = recievedData.truck_count
            let rowData = []            
            // console.log("trucksResponse", rowData)
            if(totalTrucks < 1000){
                var totalPrice = 1000 * 44
            }else{
                totalPrice = totalTrucks * 44
            }
            try{
                rowData = JSON.parse(recievedData.trucks_info)
                this.setState({
                    totalTrucks,
                    rowData,
                    loadshow:'show-n',
                    overly : "show-n",
                    'totalPrice' :totalPrice
                })
            }
            catch(e){
                this.setState({
                    totalTrucks,
                    rowData : [],
                    loadshow:'show-n',
                    overly : "show-n",
                    'totalPrice' :''
                })
            }           
        })
    }

    onGetTruckDetails = () =>{
        this.setState({
            loadshow:'show-m',
            overly : "show-m",
        })
        const {startDate} = this.state       
        this.loadPageData(startDate)

    }

    onGridReady = params => {
		this.gridApi = params.api;		
		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;
    };

    onGridState = () => {
        //console.log(this.gridApi);
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();
        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }

    onClickHideAll = () =>{
        this.setState({
			overly : "show-n",
            loadshow : "show-n",
            show: false
		})
    }
    
    closeAlert = () => {
		this.setState({
			show: false
		});
	}

    render(){
        const columnWithDefs = [
            {
				headerName: "Truck No",
				field: "truck_no",
				resizable: true,
				width: 100,
				filter: true
			},
            {
				headerName: "Transporter Name",
				field: "transporter_name",
				resizable: true,
				width: 100,
				filter: true
			},
            {
				headerName: "Invoice No",
				field: "invoice_no",
				resizable: true,
				width: 120,
				filter: true
			},
            {
                headerName: "Dealer Code",
                field: "consignee_code",
                width: 150,
                filter: "agSetColumnFilter",
                cellRenderer: '', resizable: true
            },
            {
                headerName: "Dealer Name",
                field: "consignee_name",
                width: 150,
                hide: false,
                filter: "agSetColumnFilter",
                cellRenderer: '', resizable: true
            },
            {
                headerName: "Dealer City",
                field: "consignee_city",
                width: 150,
                hide: false,
                filter: "agSetColumnFilter",
                cellRenderer: '', resizable: true
            },
            {
                headerName: "Dealer State",
                field: "consignee_state",
                width: 150,
                hide: false,
                filter: "agSetColumnFilter",
                cellRenderer: '', resizable: true
            },

        ]

        const {show,basicType,basicTitle,dept_code,startDate,modules,rowData,defaultColDefNew,statusBar,sideBar,paginationPageSize,totalTrucks, basePrice, totalPrice,billableTrucks} = this.state
        return(
            <div className="container-fluid">
                <SweetAlert
					show={show}
					type={basicType}
					title={basicTitle}
					onConfirm={this.closeAlert}>
				</SweetAlert>
                <div className="card col-xl-12">
                    <div className="card-header">
                        <h5 className="float-left row">
                            <i className="icon-map cus-i"></i> <span>Trucks Billing Info</span>
                        </h5>
                    </div>

                    <div className="card-body col-xl-12 col-lg-12">
                        <div className='row col-xl-12 col-lg-12'>
                            {/* <div className="col-xl-3 col-lg-3 form-group">
                                <label>Select Month</label>
                                <Datetime value={startDate}
                                    disableCloseOnClickOutside={false}
                                    closeOnSelect={true}
                                    inputProps={{ placeholder: 'From', name: 'startDate', autoComplete: 'off' }}
                                    dateFormat="YYYY-MM-DD"
                                    name="startDate"
                                    onChange={this.handlerStartDateTime} />
                            </div> */}
                            <div className="col-xl-3 col-lg-3 form-group">
                                <label>Select Month</label>
                                <input type="month" id="start" name="start" style={{ padding: "5px", marginLeft: '10px' }}
                                    min="2022-01" onChange={this.selectMonthDate} onKeyDown={(e) => e.preventDefault()} />
                            </div>
                            <div >
                                <button type="button" className="btn btn-success" onClick={this.onGetTruckDetails}>Submit</button>
                            </div>    
                        </div>
                    </div>

                    <div className="row">
                        <div  className="col-xl-12 col-lg-12 col-sm-12 col-md-12">
                            <table className="table table-bordered tptinfo">
                                <tr>
                                    <th style={{width: "20%"}}>No Of Trucks</th>
                                    <td style={{fontWeight:'bold'}} >{totalTrucks}</td>
                                    <th style={{width: "20%"}}>Min Billable Trucks</th>
                                    <td style={{fontWeight:'bold'}}>{billableTrucks}</td>
                                    
                                </tr>
                                <tr>
                                    <th>Base Price</th>                 
                                    <td style={{fontWeight:'bold'}}>{basePrice}</td>
                                    <th>Total Price</th>
                                    <td style={{fontWeight:'bold'}} >{totalPrice}</td>
                                </tr>                             
                            </table>
                        </div>

                        <div className='col-xl-12 col-lg-12 col-sm-12 col-md-12 mt-20p'>
                            <div id="fleetDeployedGrid" style={{ width: "100%", height: "800px"}} className={"ag-theme-balham "}>
                                <AgGridReact
                                    modules={modules}
                                    columnDefs={columnWithDefs}
                                    defaultColDef={defaultColDefNew}
                                    rowData={rowData}
                                    enableCharts={false}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    statusBar={statusBar}
                                    sideBar={sideBar}
                                    stopEditingWhenGridLosesFocus={true}
                                    paginationPageSize={paginationPageSize}
                                    enableRangeSelection={true}
                                    pagination={true}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                />
                            </div>
                        </div>

                    </div>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)} style={{position:"fixed", top: "43%"}}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll}></div>

            </div>
        )
    }

}