import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import redirectURL from '../redirectURL';
import $ from 'jquery';
// import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';

var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

var childJson = []

export default class AddContract extends Component{
        constructor(props){
        super(props);
        this.state = {
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            csvcontent:"",
            file : "",
            loadshow:'show-n',
            overly:'show-n',
            uploadYard : "",
            uploadDispatchDate : "",
            rowData:"",
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:50,
            geofencelist:[],
            geoFenceData:"",
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            dept_code:"",
            pagetitle:"",
            bulkslide:"",
            certOfOrigin:"",
            dg:"",
            date:"",
            deptName:"",
            indenterName:"",
            mobile:"",
            indenterEmail:"",
            ringiNo:"",
            party_name:"",
            cargoDeliveryPlant:"",
            itemDesc:"",
            grossWt:"",
            dimensions:"",
            cargo_chargeable_weight:"",
            supplierName:"",
            supplierAddr:"",
            supplierCnctPerson:"",
            country:"",
            destinationAirport:"",
            emailID:"",
            phone:"",
            deliveryTerms:"Ex Works",
            pickUpAddr:"",
            cargoDate:"",
            commercialInvoiceDate:"",
            commercialInvoice:"",
            nature : "",
            payment_terms : "",
            export_incentive : "",
            shipmentReturn : "",
            deliveryLabel : "Pick Up Address",
            airportName:"",
            port : "",
            cargo_date:"",
            rows: [{}],
            ringirows: [{}],
            packetrows: [{}],
            brequest:"activet",
            prepaid:"",
            track:"",
            requestData:"show-m",
            trackData:"show-n",
            trackResData : 'show-n',
            ffsuppliers: [],
            requestFields:"show-m",
            prepaidFields:"show-n",
            addresstxt:"",
            request_type:1,
            pagetitle:"Booking Request (Air Exports)",
            invoice_file : "",
            packing_list_file : "",
            sqc_list_file : "",
            annex_file : "",
            gr_wavier_file : "",
            msds_file : "",
            iip_file : "",
            //components : {datepicker:getDatePicker()}
        }
        this.changeHandler = this.changeHandler.bind(this);
        this.packetsHandler = this.packetsHandler.bind(this);
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount(){
        loadDateTimeScript();
    }

    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
    }
    
onClickHideAll(){
    this.setState({
        loadshow:'show-n',
        overly:'show-n',
        bulkslide:''
    });
    
}
closeAlert = () => {
    this.setState({
        show: false
    });
}

changeHandler = (event) => {
    console.log("Select", event.target.value);
    let name = event.target.name;
    console.log(name);
    let value = event.target.value;
    this.setState({[name]:value});
}
dateValidation = (currentDate) => {
    return currentDate.isBefore(moment(this.state.deliverymaxDate)) && currentDate.isAfter(moment(this.state.deliveryminDate));
};
changeFileHandler = async (e) => {
    console.log(e.target.name)
    this.setState({ [e.target.name]: e.target.files[0] });
}
formHandler(event){
    event.preventDefault();
    var dept_code=$("#dept_code").val();
    //var sub_department=$("#sub_department").val();
    var divison=$("#divison").val();
    var contract_name=$("#contract_name").val();
    var rngLength=$("#rngLength").val();
    var partyData=[];
    for(var r=0;r<rngLength;r++)
    {
        var party_name=$("#party_name_"+r).val();
        if(party_name !='')
        {
            partyData.push(party_name)
        }
    }
    var contract_start_date = $("#contract_start_date").val();
    var contract_end_date = $("#contract_end_date").val();
    //var contract_validity = $("#contract_validity").val();
    var rate_validity = $("#rate_validity").val();
    var current_rate_valid_till = $("#current_rate_valid_till").val();
    var ringi_no = $("#ringi_no").val();
    var ringi_date = $("#ringi_date").val();
    //var process_renewal_contract = $("#process_renewal_contract").val();
    var actual_renewal_process_start = $("#actual_renewal_process_start").val();
    var reasons_for_delay = $("#reasons_for_delay").val();
    var loc = $("#loc").val();
    var asc_ecm = $("#asc_ecm").val();
    var pic = $("#pic").val();
    var dpm = $("#dpm").val();
    // var personData = [];
    // if(pic !=undefined && pic !='')
    // {
    //     if(pic.includes(",") == true)
    //     {
    //         var personData = pic.split(",");
    //     }
    //     else
    //     {
    //         personData.push(pic);
    //     }
    // }
    var formfields = {
        dept_code : dept_code,
        //sub_department : sub_department,
        divison : divison,
        contract_name : contract_name,
        //partyData : JSON.stringify(partyData),
        partyData : partyData,
        contract_start_date : contract_start_date,
        contract_end_date : contract_end_date,
        //contract_validity : contract_validity,
        rate_validity : rate_validity,
        current_rate_valid_till : current_rate_valid_till,
        ringi_no : ringi_no,
        ringi_date : ringi_date,
        //process_renewal_contract : process_renewal_contract,
        actual_renewal_process_start : actual_renewal_process_start,
        reasons_for_delay : reasons_for_delay,
        loc : loc,
        asc_ecm : asc_ecm,
        pic : pic,
        dpm : dpm,
        partyLength : rngLength
    }
    
    var flag= 1;
    for(let key in formfields)
    {
        if(key !="reasons_for_delay")
        { 
            if(formfields[key] != undefined && formfields[key] !="") 
            {
                flag = 0;
            }
            else
            {
                flag = 1;
                console.log(formfields[key],key,"formfields[key]")
                break;
            }
        }
    }
    if(flag == 0)
    {
        this.setState({
            loadshow:'show-m',
            overly:'show-m',
        })
        // console.log(ringiNo)
        let saveData = new FormData();
        for(let key in formfields)
        {
            saveData.append(key,formfields[key]);
        }
        saveData.append("created_by",localStorage.getItem("email"));
        saveData.append("created_by_user",localStorage.getItem("username"));
        
        redirectURL.post("/consignments/savecontratdetails",saveData,{
            headers:{
                'content-type': 'multipart/form-data',
                'Access-Control-Allow-Origin':"*",
                'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, DELETE',
                'Access-Control-Allow-Headers': 'Origin, Content-Type'
            }
        }).then((response)=>{   
            this.setState({
                basicTitle:"Success",
                basicType : "success",
                show : true,
                loadshow:'show-n',
                overly:'show-n',
            });
			setTimeout(async () => {
                window.location.href="/contractmonitoring";
			},2000)
        }).catch((e)=>{
            console.log(e);
        })
              
    }
    else
    {
        this.setState({
            basicTitle:"Fields Should not be Empty",
            basicType : "warning",
            show : true
        })
    }
}

handleRemoveRow = (idx) => {
    this.state.rows.splice(idx, 1);
    this.setState({ rows: this.state.rows });
};

handleAddRingiRow = () => {
    const item = {
        party_name: "",
    };
    this.setState({
        ringirows: [...this.state.ringirows, item]
    });
};
handleRemoveRingiRow = (idx) => {
    this.state.ringirows.splice(idx, 1);
    this.setState({ ringirows: this.state.ringirows });
};

packetsHandler = (e)=> {
    var noOfPackets = e.target.value;
    if(parseInt(noOfPackets) >= 1)
    {
        var lespcks = parseInt(noOfPackets)-1;
        var bhanu=[{}];
        for(var n=0;n<lespcks;n++)
        {
            const item = {
                itemDesc: "",
                grossWt: "",
                dimensions: "",
                cargo_chargeable_weight:"",
            };
            this.setState({
                packetrows: [...bhanu, item]
            });
            var bhanu=[...bhanu, item];
        }
    }
    else
    {
        alert("No of packets should be greater than 0");
        $("#no_of_packets").val(1);
        const item = {
            itemDesc: "",
            grossWt: "",
            dimensions: "",
            cargo_chargeable_weight:"",
        };
        this.setState({
            packetrows: [item]
        });
    }
}

onClickTab(pageType){
    if(pageType == "brequest")
    {
        var brequest = "activet";
        var ffupdates = "";
        var track = "";
        var requestData = "show-m";
        var trackData = "show-n";
        var requestFields = "show-m";
        var prepaidFields = "show-n";
        var addresstxt = "";
        var request_type = 1;
        var pagetitle = "Booking Request (Air Exports)";
        $(".requestFields").attr("required",true);
        $(".prepaidFields").removeAttr("required",true);
    }
    else if(pageType == "prepaid")
    {
        var brequest = "";
        var prepaid = "activet";
        var track = "";
        var requestData = "show-m";
        var trackData = "show-n";
        var requestFields = "show-n";
        var prepaidFields = "show-m";
        var addresstxt = "(To be mentioned on BOE)";
        var request_type = 2;
        var pagetitle = "Booking Request For Prepaid Shipments (Air Exports)";
        $(".requestFields").removeAttr("required",true);
        $(".prepaidFields").attr("required",true);
    }
    else if(pageType == "track")
    {
        var brequest = "";
        var prepaid = "";
        var track = "activet";
        var requestData = "show-n";
        var trackData = "show-m";
        var requestFields = "show-n";
        var prepaidFields = "show-n";
        var addresstxt = "";
        var request_type = "";
        var pagetitle ="";
    }
    this.setState({
        brequest:brequest,
        prepaid:prepaid,
        track:track,
        requestData:requestData,
        trackData:trackData,
        requestFields:requestFields,
        prepaidFields:prepaidFields,
        addresstxt:addresstxt,
        request_type:request_type,
        pagetitle:pagetitle
    })
}

trackHandler(event){
    event.preventDefault();
    var invoice_no = $("#invoice_no").val();
    if(invoice_no !=undefined && invoice_no !='')
    {
        this.setState({
            loadshow:'show-m',
            overly:'show-m',
        })
        var params = {
            invoice_no : invoice_no
        }
        redirectURL.post("/consignments/getAirCargoExportsStatus",params)    
        .then((response) => {
            var records = response.data;
            this.setState({
                rowData : records,
                trackResData : 'show-m',
                loadshow:'show-n',
                overly:'show-n',
            })
        })
        .catch(function(e){
            console.log("Error ",e)
        })
    }
    else
    {
        this.setState({
            basicTitle:"Please enter Indenter Email",
            basicType : "warning",
            show : true
        })
    }
}

render(){
        return(
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
            		<div className={"col-xl-12 col-lg-12"}>
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
                                    <span>Add Contract</span>     
                                    <a href="/contractmonitoring" className="btn btn-danger" style={{float:"right"}}>Back</a>
								</h5>
				   			</div>
                            <div className="card-body pt-15px">
                                <form class="aircargo-form" onSubmit={this.formHandler.bind(this)} encType="multipart/form-data">
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Department* : </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="dept_code" id="dept_code" required onChange={this.changeHandler}>
                                                <option value="">-- Select Department --</option>
                                                <option value="LOG-BDI">LOG-BDI</option>
                                                <option value="LOG-EXP">LOG-EXP</option>
                                                <option value="LOG-IMP">LOG-IMP</option>
                                                <option value="LOG-RLY">LOG-RLY</option>
                                                <option value="LOG-SPL">LOG-SPL</option>
                                                <option value="SND-FNC">SND-FNC</option>
                                                <option value="SND-G">SND-G</option>
                                                <option value="SND-GJ">SND-GJ</option>
                                                <option value="SND-PZ">SND-PZ</option>
                                                <option value="SND-B">SND-B</option>
                                            </select>
                                        </div>
                                        {/* <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Sub Department* : </label>
                                            <input type="text" name="sub_department" id="sub_department" className="form-control"  onChange={this.changeHandler} required/>
                                        </div> */}
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Divison* : </label>
                                            <input type="text" name="divison" id="divison" className="form-control"  onChange={this.changeHandler} required/>
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Contract Name* : </label>
                                            <input type="text" name="contract_name" id="contract_name" className="form-control"  onChange={this.changeHandler} required/>
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    {this.state.ringirows.map((item, idx) => (
                                    <div className={"row col-xl-12 "} key={idx}>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Party* : </label>
                                            <input type="text" name={"party_name_"+idx} id={"party_name_"+idx} className="form-control" onChange={this.changeHandler} required/>
                                        </div>
                                        <div className="col-xl-4" style={{display: (idx != 0) ? 'block' : 'none'}}>
                                            <h6 style={{marginTop:"2.5em"}} onClick={() => this.handleRemoveRingiRow(idx)}>Clear</h6>
                                        </div>
                                    </div>
                                    ))}
                                    <input type="hidden" id="rngLength" value={this.state.ringirows.length}/>
                                    <div class={"row col-sm-12 "}>
                                        <div className="row col-xl-12 m-top-1">
                                            <div class="col-xl-12">
                                                <button class="btn btn-primary" type="button" onClick={this.handleAddRingiRow}>Add More Fields +</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class={"hr-dashed "}></div>
                                    <div className="row col-xl-12">
                                        <div className={"col-xl-3 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">Contract Start Date* : </label>
                                            <input type="text" name="contract_start_date" id="contract_start_date" className="datetimepicker_date form-control requestFields"  onChange={this.changeHandler} required/>
                                        </div>
                                        <div className={"col-xl-3 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">Contract End Date* : </label>
                                            <input type="text" name="contract_end_date" id="contract_end_date" className="datetimepicker_date form-control requestFields"  onChange={this.changeHandler} required/>
                                        </div>
                                        {/* <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Contract Validity* : </label>
                                            <input type="text" name="contract_validity" id="contract_validity" className="form-control" onChange={this.changeHandler} required/>
                                        </div> */}
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Rate Validity* : </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="rate_validity" id="rate_validity" required onChange={this.changeHandler}>
                                                <option value="">-- Select Option --</option>
                                                <option value="N/A">N/A</option>
                                                <option value="Monthly">Monthly</option>
                                                <option value="Quarterly">Quarterly</option>
                                                <option value="Half yearly">Half yearly</option>
                                                <option value="Half yearly except 1st month">Half yearly except 1st month</option>
                                                <option value="6 months">6 months</option>
                                                <option value="8 months">8 months</option>
                                                <option value="9 months">9 months</option>
                                                <option value="Annually">Annually</option>
                                                <option value="Bi-Annual">Bi-Annual</option>
                                                <option value="2 years">2 years</option>
                                                <option value="After 2 years">After 2 years</option>
                                                <option value="After 3 years">After 3 years</option>
                                                <option value="After 5 years">After 5 years</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class={"hr-dashed "}></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Current Rate Validity Till* : </label>
                                            <input type="text" name="current_rate_valid_till" id="current_rate_valid_till" className="datetimepicker_date form-control" onChange={this.changeHandler} required/>
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Ringi No* : </label>
                                            <input type="text" name="ringi_no" id="ringi_no" className="form-control"  onChange={this.changeHandler} />
                                        </div>
                                        <div className={"col-xl-4 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">Ringi Date* : </label>
                                            <input type="text" name="ringi_date" id="ringi_date" className="datetimepicker_date form-control requestFields"  onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        {/* <div className={"col-xl-4 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">Process for renewal of Contract / Rate to be initiated (Target)* : </label>
                                            <input type="text" name="process_renewal_contract" placeholder="" id="process_renewal_contract" className="datetimepicker_date form-control requestFields"  onChange={this.changeHandler} required/>
                                        </div> */}
                                        <div className={"col-xl-4 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">Actual Renewal process start (Actual)* : </label>
                                            <input type="text" name="actual_renewal_process_start" id="actual_renewal_process_start" className="datetimepicker_date form-control requestFields"  onChange={this.changeHandler} />
                                        </div>
                                        <div className={"col-xl-4 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">Reasons for delay : </label>
                                            <input type="text" name="reasons_for_delay" id="reasons_for_delay" className="form-control requestFields"  onChange={this.changeHandler}/>
                                        </div>                                 
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Location of Original Contract* : </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="loc" id="loc"  onChange={this.changeHandler}  required>changeHandler
                                                <option value="">-- Select Option --</option>
                                                <option value="Legal">Legal</option>
                                                <option value="User">User</option>
                                                <option value="Both">Both</option>
                                            </select>
                                        </div>
                                        
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Availability of Scanned Contract in ECM* : </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="asc_ecm" id="asc_ecm"  onChange={this.changeHandler}  required>changeHandler
                                                <option value="">-- Select Option --</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </div>
                                        <div className={"col-xl-4 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">PIC (Email ID)* : </label>
                                            <input type="text" name="pic" id="pic" className="form-control requestFields"  onChange={this.changeHandler} required/>
                                            <h5 style={{fontWeight:"bold",color:"red",fontSize:"14px",marginTop:"2%"}}>Note: MSIL Notification Emails should be provided in comma ' , ' delimited only</h5>
                                        </div>
                                        <div className={"col-xl-4 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">DPM (Email ID)* : </label>
                                            <input type="text" name="dpm" id="dpm" className="form-control requestFields"  onChange={this.changeHandler} required/>
                                            <h5 style={{fontWeight:"bold",color:"red",fontSize:"14px",marginTop:"2%"}}>Note: MSIL Notification Emails should be provided in comma ' , ' delimited only</h5>
                                        </div>
                                    </div>
                                    <div className="row col-xl-12 col-lg-12 form-group">
                                        <button type="submit" className="btn btn-success" style={{marginLeft:"45%",width:"192px",marginTop:"20px"}}>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>           
            </div>
        )
    }
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_date').datetimepicker({
		//mask:'39-19-9999',
        format:'d-m-Y',
        minDate:false,
		timepicker:false,
    });
    
    $('.cargo_date').datetimepicker({
		//mask:'39-19-9999',
        format:'d-m-Y',
        minDate:false,
		timepicker:false,
	});

    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    script.crossOrigin = "anonymous"
	index.parentNode.insertBefore(script,index)
}

// $('body').on('focus',".datetimepicker_date", function(){
//     $(this).datetimepicker({
// 		//mask:'39-19-9999',
//         format:'Y-m-d',
//         minDate:true,
//         timepicker:false
// 	});
// });

// $('body').on('focus',".cargo_date", function(){
//     $(this).datetimepicker({
// 		//mask:'39-19-9999',
//         format:'Y-m-d',
//         minDate:true,
//         timepicker:false,

// 	});
// });

$(document).on("input", ".num-cls", function() {
    this.value = this.value.replace(/\D/g,'');
});

$(document).on("change", "#cargoDate", function(e) {
    e.preventDefault();
    var bookingDate=$("#dateField").val();
    var cargoDate=$("#cargoDate").val();
    var bookingDate = bookingDate.split("-")[2]+"-"+bookingDate.split("-")[1]+"-"+bookingDate.split("-")[0]
    var cargoDate = cargoDate.split("-")[2]+"-"+cargoDate.split("-")[1]+"-"+cargoDate.split("-")[0]

    var startDate = new Date(bookingDate);
    var endDate = new Date(cargoDate);
    var startTime = startDate.getTime();
    var endTime = endDate.getTime();
    console.log(startTime,endTime);
    if(startTime >= endTime)
    {
        $("#cargoDate").val("");
        alert("Date should be greater than Booking Date");
    }
})

$(document).on("keypress", "#commercialInvoice", function(e) {
    var regex = new RegExp("^[0-9-!@#$%*?]");
    var key = String.fromCharCode(e.charCode ? e.which : e.charCode);
    if (!regex.test(key)) {
        e.preventDefault();
        return false;
    }
});