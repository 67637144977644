/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import $ from "jquery";
import _ from "lodash";
import SweetAlert from 'react-bootstrap-sweetalert';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Trucklocaction from '../layouts/trucklocaction';
import MarkerClusterer from "@google/markerclusterer";
import {
    getDDMMYYYY,
    getDDMMYYYYHHMMSS,
    getYYYYMMDD,
    getYYYYMMDDHHMMSS,
    getHyphenYYYYMMDDHHMMSS,
    getHyphenDDMMMYYYYHHMM,
    getHyphenYYYYMMDD,
    getHyphenDDMMYYYYHHMMSS,
    getHyphenDDMMYYYY,
    getDDMMMYYYYHHMMDefault
} from '../common/utils';
var redirectURL = require('../redirectURL');

var infoBox = require('../common/google-infowindow');
var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);

var moment = require('moment');
var marker;
var circle;
var map;
var rad = function (x) {
    return x * Math.PI / 180;
};
var markersArray;
var viewMarkersArr = [];
var doOnce = true;
export default class MobileTruckWithinGeoFence extends Component {
    state = {
        pageTitle: "Mobile Truck Within GeoFence",
        eventGridAction: "gridAction",
        eventCounterAction: "counterAction",
        eventFormAction: "formAction",
        routemap: '',
        allmarkers: '',
        trucks: [],
        truck_no: '',
        selected_truck_no: "",
        startDate: '',
        endDate: '',
        mapinfo: '',
        mapinfoclick: '',
        modules: AllModules,
        defTransitCoords: '',
        activeMarker: {},
        selectedPlace: {},
        dealerCode: '',
        timelinesmarkers: [],
        viewtimelinemarkers: true,
        viewgoogleroutes: true,
        googleroutepath: [],
        loadshow: 'show-n',
        open: false,
        alert: null,
        show: false,
        basicTitle: '',
        defaultColDef: {
            sortable: true,
            filter: true,
            resizable: true

        },
        gridshow: 'show-m',
        filteredData: [],
        mapshow: 'show-m',
        actgridbtn: "btn-success",
        actmapbtn: "btn-default",
        rowData: [],
        showTrucksList: "show-n",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
        overlayNoRowsTemplate: "",
        frameworkComponents: {
            trucklocaction: Trucklocaction
        },
        animateRows: true,
        debug: true,
        showToolPanel: false,
        uppressAggFuncInHeader: true,

        childconfs: [],
        childrow: [],
        rowModelType: "serverSide",
        paginationPageSize: 50,
        cacheBlockSize: 10,
        maxBlocksInCache: 1,
        truckNoList: [],
        overly: "show-n",
        statusBar: {
            statusPanels: [
                {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                },
                {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center"
                },
                { statusPanel: "agFilteredRowCountComponent" },
                { statusPanel: "agSelectedRowCountComponent" },
                { statusPanel: "agAggregationComponent" }
            ]
        },
        sideBar: {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel"
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel"
                }
            ]
        },
        detailCellRendererParams: {},
        rowClassRules: {
            "highlitRow": "data.transit_delay == 1",
            "green": "data.status >= 3"

        },
        tolls: [],
        plant_code: [],
        frequency: "",
        showmarkers: 0,
        tottalDistance: [],
        coordinates: [],
        transporterTrucks: [],
        plantLocations: [],
        datashow: "show-n",
        dataht: "40px",
        icofnt: "icofont-rounded-up",
        icshow: "show-m",
    }

    componentDidMount = () =>{
        // console.log("this.props", this.props)
        // console.log("this.params", this.props.match.params)
        const {searchType,selectType,selectOption,truckWithGps,radius,dept_code,predefgeo } = this.props.match.params
        let params = {}
        try{
            switch(searchType){
                case 'plant':
                    // console.log("check1")
                    var selectedValue = []
                    if(selectOption.includes('ALL')){
                     selectedValue = (['ALL',"HHHU","HHHD","HHHG","HM4N","HM5V","HM6C"])
                    }else{
                     selectedValue = ([selectOption])
                    }
                    params.request_type = selectType
                    params.radius = radius
                    params.gps_records_flag = truckWithGps
                    params.plant_codes = (selectedValue)
                    params.dept_code = dept_code
                    this.setState({
                     plant_code: selectedValue
                    })
                    break;
                 case 'state':
                     
                     break;
                 case 'within_states':
                     params.request_type = selectType
                     params.states_list = JSON.stringify([selectOption])
                     params.radius = radius
                     params.gps_records_flag = truckWithGps
                     params.dept_code = dept_code
                     break;
                 case 'pre_defined_geofence':
                     var selectedValue =[]
                     if(selectOption.includes('ALL')){
                         selectedValue = (["ALL","Ampere Vehicles Coimbatore","BHAGWANPUR-MAKHANPUR","Bajaj PUNE","Bajaj PantNagar",
                                             "CHIRIYAPUR-BORDER","HONDA GUJARAT","HONDA KOLAR","HONDA TAPUKHERA","Hero Electric Ludhiana",
                                             "Honda Manesar","Kanpur","Kosi Kalan","Mahindra-Haridwar","Mahindra-Pithampur",
                                             "Okinawa Kushkhera","Ola Electric Krishnagiri","PALWAL","Royal Enfield Chennai",
                                             "Suzuki Gurgaon","TVS Hosur","TVS Madurai","TVS Mysore","YAMAHA Chennai","YAMAHA Noida"])
                     }else{
                         selectedValue = ([selectOption])
                     }
                     params.plant_codes = selectedValue
                     params.request_type = selectType
                     params.gps_records_flag = truckWithGps
                     params.dept_code = dept_code
                     params.pre_defined_geofence = predefgeo
                     params.radius = radius     
                     break;
                 case 'address':
                     params.request_type = selectType
                     params.gps_records_flag = truckWithGps
                     params.radius = radius
                     params.dept_code = dept_code
                     params.latitude = parseFloat(selectOption)
                     params.longitude = parseFloat(predefgeo)     
                     break
                 default:
                     break;
            }
        }catch(e){

            this.setState({
                show: true,
                basicType : 'warning',
                basicTitle : "Enter Proper Values"
            })
        }
    //    console.log("params209", params)
       this.submitFormHandler(params)
    }

    submitFormHandler = (formdata) => {
		// rlatitude = document.getElementById("latitude").value;
		// rlongitude = document.getElementById("longitude").value;
		var urlpath = '/vicinity/plantRadiusCoordinates';
		redirectURL.post(urlpath, formdata).then(async (response) => {
			// console.log("response", response);
			if (response.data == "success") {
				// $("#email_ids").val("");
				this.setState({
					sliderStateEmailsTranslate: "",
					show: true,
					basicTitle: "Reports will be sent to provided emails",
					basicType: "success",
					overly: "show-n"
				})
			}
			else {
				var tabplants = [];
				if (response.data.cluster_centriods.length > 0) {
					console.log("consigners ", this.state.plant_code)
					response.data.cluster_centriods.forEach(item => {
						tabplants.push(item);
					})
				}
				var trucks_data = JSON.parse(response.data.trucks_data);
				var allmarkers = response.data.trucks_data;
				// console.log("allmarkers ", trucks_data);
				var dCode = this.state.dept_code;
				if (trucks_data.length > 0) {
					var markers = [];
					var mainlat = '';
					var mainlng = '';
					var maincoords = [];
					var mlat = '';
					var mlng = '';
					
					this.setState({
						defaultradius: formdata.radius,
						coordinates: trucks_data,
						mwidth: "col-xl-7 col-lg-7",
						rowData: trucks_data,
						originalrowData: trucks_data,
						radius: formdata.radius,
						loadshow: 'show-n',
						showform: 'show-n',
						showrefresh: 'show-m',
                        rlatitude: "21.144644112601775",
                        rlongitude: "79.08860126768066",
						tabplants: tabplants,
					});
                    var coords = markers;
					var p1 = { lat: parseFloat(formdata.latitude), lng: parseFloat(formdata.longitude) };
				

					// window.initMap = await this.initMap
					await this.renderMap();
				}
				else {
					this.setState({
						show: true, basicType: 'warning', basicTitle: "No Data found.",
						loadshow: 'show-n',
						showform: 'show-m',
					});

				}
			}

		})
			.catch(function (error) {
				console.log(error);
			});
	}

    renderMap = () => {
		loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyBQUmk7hdajZEAYllxKhVPnUx5pXuK-j_Y&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
 	}

    initMap = () => {
		try {
			var currentwindow;
			if (this.state.selectedTabLat != '' && this.state.selectedTabLng != '') {
				var lt = this.state.selectedTabLat;
				var ln = this.state.selectedTabLng;
			}
			else {
				var lt = this.state.rlatitude;
				var ln = this.state.rlongitude;
			}
			map = new window.google.maps.Map(document.getElementById('map_canvas'), {
				zoom: 6,
				center: new window.google.maps.LatLng(lt, ln),
				mapTypeId: window.google.maps.MapTypeId.ROADMAP,
				/*mapTypeControl: true,
				gestureHandling: 'greedy',
				mapTypeControlOptions: {
					style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
					position: window.google.maps.ControlPosition.TOP_RIGHT
				},
				disableDefaultUI: true,
				zoomControl: true*/
			});

			var input = document.getElementById('search');
			var searchBox = new window.google.maps.places.SearchBox(input);
			map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

			// Bias the SearchBox results towards current map's viewport.
			map.addListener('bounds_changed', function () {

				searchBox.setBounds(map.getBounds());
			});

			var markers = this.state.rowData;
			/*SEARCH START*/
			//console.log("searchBox",searchBox);
			searchBox.addListener('places_changed', function () {
				var places = searchBox.getPlaces();
				if (places.length == 0) {
					return;
				}
				// Clear out the old markers.
				markers.forEach(function (marker) {
					console.log("Search Marker ", marker)
					marker.setMap(null);
				});
				markers = [];
				// For each place, get the icon, name and location.
				var bounds = new window.google.maps.LatLngBounds();
				places.forEach(function (place) {
					if (!place.geometry) {
						console.log("Returned place contains no geometry");
						return;
					}
					var icon = {
						url: place.icon,
						size: new window.google.maps.Size(71, 71),
						origin: new window.google.maps.Point(0, 0),
						anchor: new window.google.maps.Point(17, 34),
						scaledSize: new window.google.maps.Size(25, 25)
					};

					// Create a marker for each place.
					//console.log("Plae Lat", place.geometry.location.lat());
					// document.getElementById("latitude").value = place.geometry.location.lat();
					// document.getElementById("longitude").value = place.geometry.location.lng();

					markers.push(new window.google.maps.Marker({
						map: map,
						icon: icon,
						title: place.name,
						position: place.geometry.location
					}));

					if (place.geometry.viewport) {
						// Only geocodes have viewport.
						bounds.union(place.geometry.viewport);
					} else {
						bounds.extend(place.geometry.location);
					}
				});
				map.fitBounds(bounds);
			});

			/*SEARCH END*/

			circle = new window.google.maps.Circle({
				map: map
			});
			marker = new window.google.maps.Marker({
				map: map,
				draggable: true,
				animation: window.google.maps.Animation.DROP,
				//position: {lat: 28.48, lng: 77.06}
			});

			marker.addListener('click', function () {
				marker.setAnimation(window.google.maps.Animation.BOUNCE)
			});

			window.google.maps.event.addListener(map, 'click', function (event) {
				// document.getElementById("latitude").value = event.latLng.lat();
				// document.getElementById("longitude").value = event.latLng.lng();
				marker.setPosition(event.latLng);

			});
			circle.bindTo('center', marker, 'position');
			var markerLatlng1;
			//console.log("Radius ", this.state.radius)
			//console.log("state Lat ",this.state.rlatitude)
			var bounds = new window.google.maps.LatLngBounds();
			var infowindow = new window.google.maps.InfoWindow();
			if (this.state.rlatitude != '' && this.state.rlongitude != '') {
				//console.log(parseFloat(this.state.rlatitude),parseFloat(this.state.rlongitude));
				markerLatlng1 = new window.google.maps.LatLng(this.state.rlatitude, this.state.rlongitude);
				//console.log("markerLatlng", markerLatlng1)
				circle = new window.google.maps.Circle({
					strokeColor: '#71caab',
					strokeOpacity: 0.7,
					strokeWeight: 1,
					fillColor: '#71caab',
					fillOpacity: 0.25,
					map: map,
					center: markerLatlng1,
					radius: Math.sqrt(this.state.radius) * 10000
				});
			}
			if (doOnce) {
				initOMS();
				doOnce = false;
			}

			if (this.state.rowData.length > 0) {
				var markers = this.state.rowData.map((marker) => {
					//console.log("marker ", marker)
					if (marker.status == 1) {
						var truckText = "Inside Plant";
						var color = "#333333";
						var image = require('../../assets/icons/truck_inside_24.png');
					}
					else if (marker.status == 2) {
						var truckText = "Going to Destination";
						var color = "#333333";
						var image = require('../../assets/icons/truck_going_24.png');
					}
					else if (marker.status == 3 || marker.status == 4) {
						var truckText = "At Destination Location";
						var color = "#333333";
						var image = require('../../assets/icons/truck_atdealer_24.png');
					}
					else if (marker.status == 5) {
						var truckText = "Left Destination Location";
						var color = "#333333";
						var image = require('../../assets/icons/truck_leftdealer_24.png');
					}
					else if (marker.status == 6) {
						var truckText = "Return to Plant";
						var color = "#333333";
						var image = require('../../assets/icons/truck_return_24.png');
					}
					else if (marker.status == 7) {
						var truckText = "Empty Truck Outside Plant";
						var color = "#333333";
						var image = require('../../assets/icons/truck_empty_24.png');
					}
					else {
						var truckText = marker.truck_no;
						var color = "#333333";
						var image = require('../../assets/icons/truck_na_24.png');
					}
					var image = require('../../assets/icons/truck_na_24.png');
					var contentarr = []
					var headerTitle = truckText
					contentarr.push({ "key": "Truck No", "value": marker.truck_no });
					contentarr.push({ "key": "Transporter Name", "value": marker.transporter_name });
					contentarr.push({ "key": "Distance from geofence", "value": marker.distance_from_coordinates });
					//contentarr.push({"key":"LSP Name", "value":marker.lsp_name});
					contentarr.push({ "key": "Address", "value": marker.area + ", " + marker.state });
					var contentString = infoBox(image, headerTitle, contentarr, '')

					var infowindow = new window.google.maps.InfoWindow({
						content: contentString
					});

					var markerLatlng = new window.google.maps.LatLng(parseFloat(marker.latitude), parseFloat(marker.longitude));
					bounds.extend(new window.google.maps.LatLng(parseFloat(marker.latitude), parseFloat(marker.longitude)));

					var image = require('../../assets/icons/truck_na_24.png');				
					if (marker.status == 1) {
						var image = require('../../assets/icons/truck_inside_24.png');
					}
					else if (marker.status == 2) {
						var image = require('../../assets/icons/truck_going_24.png');
					}
					else if (marker.status == 3 || marker.status == 4) {
						var image = require('../../assets/icons/truck_atdealer_24.png');
					}
					else if (marker.status == 5) {
						var image = require('../../assets/icons/truck_leftdealer_24.png');
					}
					else if (marker.status == 6) {
						var image = require('../../assets/icons/truck_return_24.png');
					}
					else if (marker.status == 7) {
						var image = require('../../assets/icons/truck_empty_24.png');
					}
					else {
						var image = require('../../assets/icons/truck_na_24.png');
					}

					var image = require('../../assets/icons/truck_na_24.png');
					var mark = new window.google.maps.Marker({
						position: markerLatlng,
						map: map,
						title: marker.truck_no,
						icon: image
					});
					mark.addListener('click', function () {
						infowindow.open(map, mark);
					});
					mark.setMap(map);
					return mark
				});
				var markerCluster = new MarkerClusterer(map, markers,
					{ imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m' });
			}
			var eventtrigger = document.getElementsByClassName('truck');
				if (this.state.tabplants.length > 0) {
					this.state.tabplants.forEach(item => {
						//console.log("Item Marker ", item)
						markerLatlng1 = new window.google.maps.LatLng(item.lat, item.lng);
						//console.log("markerLatlng", markerLatlng1)
						circle = new window.google.maps.Circle({
							strokeColor: '#71caab',
							strokeOpacity: 0.7,
							strokeWeight: 1,
							fillColor: '#71caab',
							fillOpacity: 0.25,
							map: map,
							center: markerLatlng1,
							radius: Math.sqrt(this.state.radius) * 8000
						});
						bounds.extend(new window.google.maps.LatLng(parseFloat(item.lat), parseFloat(item.lng)));
						var mainmark = new window.google.maps.Marker({
							position: new window.google.maps.LatLng(parseFloat(item.lat), parseFloat(item.lng)),
							map: map,
							title: item.plant_code
						});
						mainmark.addListener('click', (function () {
							var contentarr = []
							//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
							var header = "Plant"
							contentarr.push({ "key": "Code", "value": item.plant_code })
							var contentString = infoBox("", header, contentarr, '')
							infowindow.setContent(contentString);
							currentwindow = infowindow;
							infowindow.open(map, marker);

						})(marker));
						mainmark.setMap(map);
					})
				}
			// var searchBox = new window.google.maps.places.SearchBox(eventtrigger);
			//map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(eventtrigger);
			map.fitBounds(bounds)
		}
		catch (e) {
			console.log(e);
		}
	}

    closeAlert = () => {	
        this.setState({
            show: false
        });
    }
 

     render(){
         return(
             <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                > 
                </SweetAlert>
                <div id="map_canvas"  style={{ width: '100%', height: "100vh" }}></div>
            </div>
         )
     }
}


function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function timeConvert(n){
    var num = n;
    var hours = (num / (3600));
    var rhours = parseInt(hours);
    var minutes = (num-(rhours*3600)) / (60);
    var rminutes = Math.round(minutes);
    return rhours + " hour(s) and " + rminutes + " minute(s).";
}   

function ConvertMinutes(num){
  var d = Math.floor(num/1440); // 60*24
  var h = Math.floor((num-(d*1440))/60);
  var m = Math.round(num%60);
  return d+" Day(s) "+h+" Hrs "+m+" mins"
}

function ConvertSeconds(n){
  var days = Math.round(parseInt(n / (24 * 3600)));
  n = n % (24 * 3600);
  var hours = Math.round(parseInt(n / 3600));
  n %= 3600;
  var minutes = Math.round(n / 60);
  n %= 60;
  var seconds = Math.round(n);
  return days + " Day(s) " + hours + " Hrs " + minutes + " mins " + seconds + " secs"
  // var days = Math.floor(num/86400);
  // var hours = Math.floor(num/3600)
  // var minutes = Math.floor(num/60);
  // var minutes = Math.round((totalSeconds % 3600) / 60);
  // var hours = Math.round((totalSeconds % 86400) / 3600);
  // var days = Math.round((totalSeconds % (86400 * 30)) / 86400);
  // let d = (new Date(t0)) - (new Date(t1));
  // let weekdays     = Math.floor(d/1000/60/60/24/7);
  // let days         = Math.floor(d/1000/60/60/24 - weekdays*7);
  // let hours        = Math.floor(d/1000/60/60    - weekdays*7*24            - days*24);
  // let minutes      = Math.ceil(d/1000/60       - weekdays*7*24*60         - days*24*60         - hours*60);
  // return days+" Day(s) "+hours+" Hrs "+minutes+" mins"
}

function ConvertHHMMSeconds(n){
  var days =Math.round(parseInt( n / (24 * 3600)));
  n = n % (24 * 3600);
  var hours = Math.round(parseInt(n / 3600));
  n %= 3600;
  var minutes = Math.round(n / 60);
  n %= 60;
  var seconds = Math.round(n);
  return days+" Day(s) "+hours+" Hrs "+minutes+" mins "+ seconds+" secs"
}

function convertMeters(metersValue){
  return (metersValue/1000).toFixed(2);
}

$(document).ready(function(){
  $(document).on('keyup',".validateNumber",function(){
      // alert("click");
      if(isNaN($(this).val()) == true){
          $(this).val($(this).val().slice(0,-1));
      }
  });
});

function arrayMax(arr) {
  return arr.reduce(function (p, v) {
    return ( p > v ? p : v );
  });
}

function initOMS() {
	// Initializing OMS
	oms = new oms.OverlappingMarkerSpiderfier(map, {
		//markersWontMove: true,
		//keepSpiderfied: true,
		//nearbyDistance: 10,
		//circleFootSeparation: 60,
		//legWeight: 1.5
		markersWontMove: true,
		markersWontHide: true,
		keepSpiderfied: true,
		basicFormatEvents: true
	});
}