import React, { Component } from 'react';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import { TabContent, TabPane, Nav, NavItem, NavLink,Row, Col } from 'reactstrap';
import classnames from 'classnames';

import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
// import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import TnpUserItems from "./tnpuseritems";
import TnpOperationAssignment from "./tnpoperationassignment";
$.datetimepicker.setLocale('en');    
var redirectURL = require('../redirectURL');
var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");

export default class TnpUserItemsTabs extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			active_tab: '1',
            active_tab_bottom_left: '1',
            active_tab_icon: '1',
            active_tab_right_align: '1',
            active_tab_vertical: '1',
            active_tab_pill: '1',
            active_tab_pill_icon: '1',
            active_tab_dark_color: '1',
            active_tab_primary: '1',
            active_tab_secondary: '1',
			isRoleExists:0,
			adaniCheck:0,
            
        };
        
		this.toggle = this.toggle.bind(this);
	}
    
    componentDidMount(){      

    }

	toggle(tab) {
        if (this.state.active_tab !== tab) {
            this.setState({
                active_tab: tab
            });
        }

    }

    render(){
		
        return (
            
    		<div className="container-fluid">
				
            	<div className="row">
                    <div className="col-xl-12 col-lg-12">
							
                        <Nav tabs className={'tabs-color'}>
                            <NavItem >
                                <NavLink
                                    className={classnames({ active: this.state.active_tab === '1' })}
                                    onClick={() => { this.toggle('1'); }} >
                                    Item Mapping
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={classnames({ active: this.state.active_tab === '2' })}
                                            onClick={() => { this.toggle('2'); }} >
                                    Operations Assignment
                                </NavLink>
                            </NavItem>
                        </Nav>

                        <TabContent activeTab={this.state.active_tab}>
                            <TabPane tabId="1">
                                <Row>
                                    <Col sm="12">
                                        <TnpUserItems />
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="2">
                                <Row>
                                    <Col sm="12">
                                        <TnpOperationAssignment operations={1}/>
                                    </Col>
                                </Row>
                            </TabPane>                           
                        </TabContent>
                    </div>	
				 </div>

            </div>
              
        );
    }
}
