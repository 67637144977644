import React, {Component} from 'react';

import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';

import SweetAlert from 'react-bootstrap-sweetalert';
var images = require.context('../../assets/images/', true);
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

class ResetPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pageTitle: "Reset Your Password",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
            style: {},
            email: "",
            alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
        };
		this.cipher = this.cipher.bind(this);
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    
    componentDidMount() {
        this.logPageView(); // Log page view to GA
        setTimeout(function () {
            this.setState({style: {display:'none'}});
        }.bind(this),1000)
    }
    cipher = salt => {
		const textToChars = text => text.split('').map(c => c.charCodeAt(0));
		const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
		const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
	
		return text => text.split('')
			.map(textToChars)
			.map(applySaltToChar)
			.map(byteHex)
			.join('');
    }
    closeAlert = async () => {
        await this.setState({
            show: false
        });
         window.location.href="/"
    }
	FormHandler(e){
		console.log("In ResetPassword, formhandler, e = ", e);
        e.preventDefault();
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pageTitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventFormAction,
                "label": googleAnalytics.page.action.formSubmittedResetPassword,
            }
            googleAnalytics.logEvent(eventOptions);
        }
        //return false;
		this.setState({errorEmptyValue: false});
		this.setState({messageResetPasswordSuccessful: false});
		this.setState({errorResetPasswordFailed: false, messageFromServer: ""})
		let email = this.state.email;
        let username=this.state.username;
        //console.log("In ResetPassword, formhandler, email = ", email);
		if(!email) {
			this.setState({errorEmptyValue: true});
			return false;
        }
        if(username !='Air Imports' && username !='Sea Imports')
        {
            var em = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
			email = em(this.state.email)
            const layoutdata={
                //token:window.atob(localStorage.getItem('_t')),
                email:email
            }
            //console.log("In ResetPassword, formhandler, layoutdata = ", layoutdata);
            redirectURL.post('/masters/resetpassword', layoutdata,{
                headers:{
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
                }
            })
            .then((response) => {
                // console.log(response);
                //var records = JSON.parse(JSON.stringify(response.body)).data;
                var records = response.data;
               // console.log("Records = ", records);
                if (records.status == "Success") {
                    ////////////////////EMAIL START///////////////
                    // let layoutdataEmail = {
                    //     password: records.data,
                    //     email: email,
                    // }
                    // redirectURL.post('/masters/sendpasswordresetremail', layoutdataEmail,{
                    //     headers:{
                    //         "Access-Control-Allow-Origin": "*",
                    //         "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
                    //     }
                    // })
                    // .then((response) => {
                    //     var response = JSON.parse(JSON.stringify(response));
                    //     var responseData = response.data;

                    //     //console.log("IN sendpasswordresetremail, statusCode = ", responseData.statusCode, response);
                    //     if (responseData.statusCode == 200) {
                            let message = "An email with new password, sent successfully.";
                            this.setState({
                                messageResetPasswordSuccessful: true, 
                                messageFromServer: message,
                                email:"",
                                show:true,
                                basicTitle:message,
                                basicType:"success"
                            })
                    //     }
                    //     if (responseData.statusCode != 200) {
                    //         let message = "An email with new password, could not be sent. Please try again";
                    //         this.setState({errorResetPasswordFailed: true, messageFromServer: message})
                    //     }
                    //     e.preventDefault();
                    // })
                    // .catch(function (error) {
                    //     console.log(error);
                    // });
                    ////////////////////EMAIL END///////////////
                }
                if (records.status == "error") {
                    var message = (records.message) ? records.message : "An email with new password, could not be sent. Please try again";
                    this.setState({
                        errorResetPasswordFailed: true, 
                        messageFromServer: message,
                        email:"",
                        show:true,
                        basicTitle:records.message,
                        basicType:"danger"
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        }
        else
        {
            this.setState({errorEmptyValue: true});
			return false;
        }
	}

	changeHandler(event){
		let name = event.target.name;
		let value = event.target.value;
		//console.log("name and value = ", name, value);
		this.setState({[name]:value});
		// Reset messages
		this.setState({errorEmptyValue: false});
		this.setState({messageResetPasswordSuccessful: false});
		this.setState({errorResetPasswordFailed: false, messageFromServer: ""})
	}
    render() {
        let style = this.state.style;
        const background = require('../../assets/images/auth-layer.png')

        return (
            <div>
                <SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
                {/* Loader starts */}
                <div className="loader-wrapper" style={style}>
                    <div className="loader bg-white">
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <h4>Loading The Reset Password Page <span>&#x263A;</span></h4>
                    </div>
                </div>
                {/* Loader ends */}

                {/*page-wrapper Start*/}
                <div className="page-wrapper">

                    <div className="container-fluid">

                        {/*Reset Password page start*/}

                        <div className="authentication-main">
                            <div className="row">
                                <div className="col-md-4 p-0">
                                    <div className="auth-innerleft" style ={{ backgroundImage: "url("+background+")" }}>
                                        <div className="text-center">
                                            <img src={require('../../assets/images/key.png')} className="img-fluid security-icon" alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8 p-0">
                                    <div className="auth-innerright">
                                        <div className="authentication-box">
                                            <h3>RESET YOUR PASSWORD</h3>
                                            <div className="card mt-4 p-4">
                                                <Form  className="theme-form"  onSubmit={this.FormHandler.bind(this)}>
                                                    <h5 className="f-16 mb-3">Please provide your email id to receive an email with a new password.</h5>
                                                    
                                                    <div className="col-xl-12 col-lg-12 col-sm-12">
                                                        {(this.state.messageResetPasswordSuccessful) ? 
                                                            <div className="txt-success f15" style={{padding:'0px 0px 20px 0px'}}>{this.state.messageFromServer}</div>
                                                            : ""
                                                        }
                                                        {(this.state.errorResetPasswordFailed) ? 
                                                            <div className="txt-danger f15" style={{padding:'0px 0px 20px 0px'}}> {this.state.messageFromServer}</div>
                                                            : ""
                                                        }
                                                        
                                                        {(this.state.errorEmptyValue) ? 
                                                            <div className="txt-danger f15" style={{padding:'0px 0px 20px 0px'}}>Please provide a valid email id.</div>
                                                            : ""
                                                        }
                                                    </div>

                                                    <div className="form-group">
                                                        <label className="col-form-label">Email Id *</label>
                                                        <input type="email" className="form-control" name="email" id="email" autoComplete='off'
                                                          placeholder="Email Id" value={this.state.email} onChange={this.changeHandler.bind(this)} required />
                                                    </div>
                                                    <div className="form-group form-row mb-0">
                                                        <div className="col-md-2">
                                                            <button type="submit" className="btn btn-info">Reset Password
                                                            </button>
                                                        </div>
                                                        <div className="col-md-10">
                                                        <a href={"/"} className="btn btn-info float-right">Login</a>
                                                        </div>
                                                    </div>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/*Reset Password page end*/}
                    </div>
                </div>
            </div>
        )
    }
}


export default ResetPassword;
