/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import _ from "lodash";
import Modal from 'react-responsive-modal';
import SweetAlert from 'react-bootstrap-sweetalert';
import CountUp from 'react-countup';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getHyphenDDMMMYYYYHHMM } from '../common/utils';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import more from 'material-ui/svg-icons/notification/more';
var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");


const decipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
    return encoded => encoded.match(/.{1,2}/g)
        .map(hex => parseInt(hex, 16))
        .map(applySaltToChar)
        .map(charCode => String.fromCharCode(charCode))
        .join('');
}
var decode = decipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

var sliderColumnsList = [
    {
        headerName: "Transporter Code",
        // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
        field: "transporter_code",
        width: 110,
        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
        chartDataType: "series"
    },
    {
        headerName: "Transporter Name",
        // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
        field: "transporter_name",
        width: 160,
        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
        chartDataType: "series"
    },
    {
        headerName: "Truck No",
        // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
        field: "truck_no",
        width: 110,
        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
        chartDataType: "series"
    },

    {
        headerName: "Invoice No",
        // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
        field: "invoice_no",
        width: 110,
        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
        chartDataType: "series"
    },
    {
        headerName: "Consignment Code",
        // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
        field: "consignment_code",
        width: 130,
        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
        chartDataType: "series"
    },
    {
        headerName: "Consignee Name",
        // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
        field: "consignee_name",
        width: 150,
        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
        chartDataType: "series"
    },
    {
        headerName: "Consignee Code",
        // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
        field: "consignee_code",
        width: 120,
        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
        chartDataType: "series"
    },
    {
        headerName: "Consignee Area",
        // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
        field: "consignee_area",
        width: 120,
        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
        chartDataType: "series"
    },
    {
        headerName: "Consignee City",
        // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
        field: "consignee_city",
        width: 130,
        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
        chartDataType: "series"
    },
    {
        headerName: "Zone",
        // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
        field: "zone",
        width: 100,
        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
        chartDataType: "series"
    },
    {
        headerName: "Consigner Code",
        // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
        field: "consigner_code",
        width: 100,
        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
        chartDataType: "series"
    },
    {
        headerName: "Current Location",
        // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
        field: "current_location",
        width: 150,
        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
        chartDataType: "series"
    },
    {
        headerName: "Invoice Time",
        // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
        field: "invoice_time",
        width: 150,
        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
        chartDataType: "series",
        valueGetter: params => {
            if (params.data.invoice_time != '' && params.data.invoice_time != undefined && params.data.invoice_time != "NA") {
                return getHyphenDDMMMYYYYHHMM(params.data.invoice_time);
            }
            else {
                return "NA";
            }
        }
    },
    {
        headerName: "Original ETA",
        field: "expected_trip_end",
        id: "expected_trip_end",
        width: 120,
        //pinned: 'left',
        resizable: true,
        valueGetter: function (params) {
            if (params.data.expected_trip_end != "" && params.data.expected_trip_end != undefined && params.data.expected_trip_end != null) {
                return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end);
            }
            else {
                return "";
            }
        },
        //filter: "agDateColumnFilter",
        // comparator: dateComparator,
    },
    {
        headerName: 'Remarks',
        field: 'remarks_added',
        width: 100,
        editable: param => {
            if (!['NO ISSUE', 'HMCL ISSUE', 'TCI ISSUE'].includes(param.data.remarks_added)) return true
            return false
        },
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: () => {
            return {
                values: ['HMCL ISSUE', 'TCI ISSUE']
            }
        },
        valueGetter: param => {
            return param.data.remarks_added
        }
    },

]
var truckTripTatColumnList = [
    {
        headerName: "Parking In-Time",
        // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
        field: "parking_tat_start_time",
        width: 150,
        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
        chartDataType: "series",
        valueGetter: params => {
            if (params.data.parking_tat_start_time != '' && params.data.parking_tat_start_time != undefined && params.data.parking_tat_start_time != "NA") {
                return getHyphenDDMMMYYYYHHMM(params.data.parking_tat_start_time);
            }
            else {
                return "NA";
            }
        }
    },
    {
        headerName: "Parking Out-Time",
        // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
        field: "parking_tat_end_time",
        width: 150,
        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
        chartDataType: "series",
        valueGetter: params => {
            if (params.data.parking_tat_end_time != '' && params.data.parking_tat_end_time != undefined && params.data.parking_tat_end_time != "NA") {
                return getHyphenDDMMMYYYYHHMM(params.data.parking_tat_end_time);
            }
            else {
                return "NA";
            }
        }
    },
    {
        headerName: "Left Dealer Time",
        // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
        field: "left_dealer_marked",
        width: 150,
        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
        chartDataType: "series",
        valueGetter: params => {
            if (params.data.left_dealer_marked != '' && params.data.left_dealer_marked != undefined && params.data.left_dealer_marked != "NA") {
                return getHyphenDDMMMYYYYHHMM(params.data.left_dealer_marked);
            }
            else {
                return "NA";
            }
        }
    },
    {
        headerName: "Parking TAT (Hrs)",
        // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
        field: "parking_tat_in_hrs",
        width: 150,
        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
        chartDataType: "series"
    },
    {
        headerName: "Loading TAT (Hrs)",
        // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
        field: "loading_tat_in_hrs",
        width: 150,
        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
        chartDataType: "series"
    },
    {
        headerName: "Transit TAT (Hrs)",
        // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
        field: "transit_tat_in_hrs",
        width: 150,
        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
        chartDataType: "series"
    },
    {
        headerName: "Unloading TAT (Hrs)",
        // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
        field: "unloading_tat_in_hrs",
        width: 150,
        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
        chartDataType: "series"
    },
    {
        headerName: "Return TAT (Hrs)",
        // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
        field: "return_tat_in_hrs",
        width: 150,
        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
        chartDataType: "series"
    },
]
// var left_dealer_time = {
//     headerName: "Left Dealer Time",
//     // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
//     field: "left_dealer_marked",
//     width: 150,
//     // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
//     chartDataType: "series",
//     valueGetter: params => {
//         if (params.data.left_dealer_marked != '' && params.data.left_dealer_marked != undefined && params.data.left_dealer_marked != "NA") {
//             return getHyphenDDMMMYYYYHHMM(params.data.left_dealer_marked);
//         }
//         else {
//             return "NA";
//         }
//     }
// }

var sliderColumns2 = [...sliderColumnsList]
export default class DedicatedTrucksDashBoard extends Component {
    state = {
        show: false,
        show1: false,
        basicTitle: '',
        basicType: "default",
        basicTitle1: '',
        basicType1: "default",
        loadshow: "show-n",
        overly: "show-n",
        dept_code: "",
        totalTrucks: [],
        truckscnt: 0,
        rowData: [],
        rowDataForFilter:[],
        pivotmodeis: false,
        pivotcols: [],
        defaultColDef: {
            sortable: true,
            filter: true,
            resizable: true

        },
        frameworkComponents: {
        },
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
        animateRows: true,
        debug: true,
        showToolPanel: true,
        suppressAggFuncInHeader: true,
        statusBar: {
            statusPanels: [
                {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                },
                {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center"
                },
                { statusPanel: "agFilteredRowCountComponent" },
                { statusPanel: "agSelectedRowCountComponent" },
                { statusPanel: "agAggregationComponent" }
            ]
        },
        sideBar: {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel"
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel"
                }
            ]
        },
        filterTransporters: [],
        filterTrucks: [],
        usergridstate: [],
        sliderCriticalTranslate: '',
        sliderTitle: '',
        trips_df: [],
        tripWiseRowData: [],
        truckWiseRowData: [],
        zero_trips: [],
        one_trip: [],
        two_trips: [],
        three_trips: [],
        four_trips: [],
        five_trips: [],
        six_trips: [],
        seven_trips: [],
        eight_trips: [],
        more_than_eight_trips: [],
        flTruck: [{ value: 'ALL', label: 'All' }],
        flTransporter: [{ value: 'ALL', label: 'All' }],
        userTransporter: [],
        systemTransporter: [],
        tab1: 'show-m',
        tab2: 'show-n',
        from_date: moment.parseZone().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        to_date: moment.parseZone().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
        parking_0_1_5: [],
        parking_1_5_3: [],
        parking_3: [],
        loading_0_1_5: [],
        loading_1_5_3: [],
        loading_3: [],
        inTransit_r_20: [],
        inTransit_g_20: [],
        // inTransit_0_1_5: 0,
        // inTransit_1_5_3: 0,
        // inTransit_3: 0,
        unLoading_g_20: [],
        unLoading_r_20: [],
        // unLoading_0_1_5: 0,
        // unLoading_1_5_3: 0,
        // unLoading_3: 0,
        return_0_1_5: 0,
        return_1_5_3: 0,
        return_3: 0,
        return_g_20: [],
        return_r_20: [],
        dedicatedFleetRowData: [],
        selectedMonthUser: new Date(),
        selectedMonthSystem: new Date(),
        dates: [],
        columns: [],
        showUserData: "show-n",
        showSystemData: "show-n",
        updatedData: [],
        userData: [],
        systemData: [],
    }

    cipher = (salt) => {
        const textToChars = text => text.split('').map(c => c.charCodeAt(0));
        const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
        const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
        try {
            return text => text.split('')
                .map(textToChars)
                .map(applySaltToChar)
                .map(byteHex)
                .join('');
        }
        // eslint-disable-next-line no-unreachable
        catch (e) { }
    }

    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }

    componentDidMount() {

        this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});


		redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname})
		.then( async (resp) => {
			await this.setState({
				usergridstate:resp.data
			});
			await this.restoreGridStates();
		})

        let from_date = moment.parseZone().subtract(1, "month").startOf("day").format("YYYY-MM-DD HH:mm:ss");
        let to_date = moment.parseZone().endOf("day").format("YYYY-MM-DD HH:mm:ss");
        this.setState({
            startDate: from_date,
            endDate: to_date,
            loadshow: "show-m",
            overlay: "show_m"
        })

        this.getDedicatedFleetStatus()

        const { flTruck, flTransporter, startDate, endDate } = this.state
        let params = { from_date, to_date, 'truck_no': flTruck, 'transporters': flTransporter }
        // console.log('flTruck', params)

        this.getDashboardRowData(params)

    }

    restoreGridStates()
	{
		try{


			if(this.state.usergridstate.length > 0)
			{
				var windowstates = this.state.usergridstate;
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
			
			}
		}
		catch(e){}
	}

    onClickSaveGridState(){
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Save Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		
		let screenpage = 'Alloy bars dispatch';
		

		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

    resetState = () => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Reset Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

	};

    onGridReadyDFD = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	

	onGridStateDFD = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
		   
	}

    LoadCNGData = (data_type , selectedMonth) => {
        var month = selectedMonth.getMonth() + 1
        var year = selectedMonth.getFullYear()
        var params = {
            month, year,
            datatype: data_type
         }
        redirectURL.post('dashboard/getDataForCNG', params).then(response => {
            console.log(response.data.records, "1729")
            var records = response.data.records
            this.setState({
                systemData: records,
                rowDataForFilter:records,
                userTransporter:[],
                systemTransporter:[],
            })

        })
    }

    generateColumns = (selectedMonth) => {
        var columns = [
            {
                headerName: 'Transporter Code',
                field: 'transporter_code',
                width: 120,
                pinned: 'left',

            },
            {
                headerName: 'Transporter Name',
                field: 'transporter_name',
                width: 200,
                pinned: 'left',

            },
            {
                headerName: 'Truck No',
                field: 'truck_no',
                width: 120,
                pinned: 'left',

            }
            
        ];

        if (selectedMonth) {
            var month = selectedMonth.getMonth();
            var year = selectedMonth.getFullYear();
            console.log(month , "month")
            var daysInMonth = new Date(year, month + 1, 0).getDate();
            for (let i = 1; i <= daysInMonth; i++) {
                if (i < 10) {
                    i = `0${i}`
                }
                if (month+1 < 10) {
                    var headerName = `${year}-0${month + 1}-${i}`
                } else {
                    var headerName = `${year}-${month + 1}-${i}`
                }
                var current_date = moment.parseZone(new Date()).format("YYYY-MM-DD")
                var yesterday = moment.parseZone(new Date()).subtract(1, 'days').format("YYYY-MM-DD")
                var isEditable = this.state.showUserData == 'show-m' ? (headerName == current_date || headerName == yesterday) ? true : false : false
                var column = {
                    headerName: headerName,
                    field: headerName,
                    width: '150',
                    editable: isEditable,

                };
                columns.push(column);
            }
        }
        this.setState({
            columns,
        })
        // return columns;
    }

    updateGridDataOnClick = () => {
        if (this.state.updatedData.length > 0) {
            console.log(this.state.updatedData)
            var updatedData = this.state.updatedData
            var uploaded_by = localStorage.getItem('email')
            redirectURL.post('dashboard/updateCNGData', { updatedData, uploaded_by }).then(response => {
                if (response.data.status == 'success') {
                    this.setState({
                        show1: true,
                        basicTitle1: 'Data Updated Successfully',
                        basicType1: 'success',
                        updatedData: []
                    })
                }
                else if (response.data.status == 'fail') {
                    this.setState({
                        show: true,
                        basicTitle: "Can't Update Empty Status",
                        basicType: 'warning',
                        updatedData: []
                    })
                }
                else {
                    this.setState({
                        show: true,
                        basicTitle: 'Something went Wrong',
                        basicType: 'danger',
                        updatedData: []
                    })
                }
            })
        }
        else {
            this.setState({
                show: true,
                basicType: 'info',
                basicTitle: 'No Changes Made'

            })
        }



    }

    onCellValueChanged = (params) => {
        if (params.oldValue != params.newValue) {
            console.log(params.data, "data changed")
            var changedFiled = params.colDef.field
            var changedData = {
                truck_no: params.data.truck_no
            }
            changedData[changedFiled] = params.newValue
            this.setState(prevState => ({
                updatedData: [...prevState.updatedData, changedData]
            }))
        }

    }
    onChangeHandler1 = (e) => {
        var selectedMonth = new Date(e.target.value);
            var user_data_type = 'user_data'
            this.setState({ selectedMonthUser:selectedMonth }, () => {
                this.generateColumns(selectedMonth)
                this.LoadCNGData(user_data_type , selectedMonth)
            });
        
        

    }
    onChangeHandler2 = (e) => {
        var selectedMonth = new Date(e.target.value);
        var user_type = 'system_data'
        this.setState({ selectedMonthSystem:selectedMonth }, () => {
            this.generateColumns(selectedMonth)
            this.LoadCNGData(user_type , selectedMonth)
        });

    }

    getDashboardRowData = ({ from_date, to_date, truck_no, transporters }) => {
        let params = {
            from_date, to_date,
        }
        let truck_p = []
        let trans_p = []
        let truck_value = truck_no ? truck_no[0].value : '';
        let transporter_value = transporters ? transporters[0].value : ''

        if (truck_value != '' && truck_value != undefined) {
            if (truck_no.length > 0) {
                // console.log(truck_no[0].value)
                truck_no.map(e => {
                    truck_p.push(e.value)
                })
            }
            params.truck_no = JSON.stringify(truck_p)
        }

        if (transporter_value != '' && transporter_value != undefined) {
            if (transporters.length > 0) {
                transporters.map(e => {
                    trans_p.push(e.value)
                })
            }

            params.transporters = JSON.stringify(trans_p)
        }
        console.log(params , "params1729")
        redirectURL.post("/dashboard/getDataForCngDashboard", params).then(resp => {
            const truck_list = resp.data.dedicated_trucks
            const dedicated_transporters = resp.data.dedicated_transporters
            // console.log("dedicated transporters", dedicated_transporters)
            // console.log("dedicated trucks", truck_list)
            const rowData = JSON.parse(resp.data.apiData)
            // console.log('rowData', rowData, typeof [rowData])
            const rowGridData = []
            const tripGridData = []
            const tripData = {}
            const truckNo = []

            for (let i = 0; i < rowData.length; i++) {
                rowGridData.push(rowData[i])
            }
            // for(let i=0; i<rowGridData.length;i++){
            //     var trips = rowGridData[i];
            //     if(!tripData[trips.key]){
            //         tripData["1"] = []
            //     }
            //     if(trips.no_of_trips == 1){
            //         tripData["1"].push(trips.truck_no)
            //         console.log(tripData)
            //     }

            // }
            const zero_trips = []
            const one_trip = []
            const two_trips = []
            const three_trips = []
            const four_trips = []
            const five_trips = []
            const six_trips = []
            const seven_trips = []
            const eight_trips = []
            const more_than_eight_trips = []
            console.log("rowGridData", rowGridData)
            try {
                rowGridData.map((item, idx) => {
                    // console.log("rowGridData",item)
                    tripData["total_trucks"] = idx + 1
                    // console.log('item', item)
                    if (item.no_of_trips == 0) {
                        var temp = JSON.parse(item.trip_leg_detail)
                        for (let i = 0; i < temp.length; i++) {
                            zero_trips.push(temp[i])
                        }
                        const zero_trips_length = zero_trips.reduce((resultSet, item) => resultSet.add(item["truck_no"]), new Set()).size
                        tripData["0"] = zero_trips_length
                    }
                    if (item.no_of_trips == 1) {
                        temp = JSON.parse(item.trip_leg_detail)
                        // console.log('temp1', temp)
                        for (let i = 0; i < temp.length; i++) {
                            one_trip.push(temp[i])
                        }
                        const one_trip_length = one_trip.reduce((resultSet, item) => resultSet.add(item["truck_no"]), new Set()).size
                        tripData["1"] = one_trip_length
                        // console.log("truck_no", item.truck_no)
                    }
                    if (item.no_of_trips == 2) {
                        temp = JSON.parse(item.trip_leg_detail)
                        // console.log('temp2', temp)
                        for (let i = 0; i < temp.length; i++) {
                            two_trips.push(temp[i])
                        }
                        const two_trips_length = two_trips.reduce((resultSet, item) => resultSet.add(item["truck_no"]), new Set()).size
                        tripData["2"] = two_trips_length
                    }
                    if (item.no_of_trips == 3) {
                        temp = JSON.parse(item.trip_leg_detail)
                        for (let i = 0; i < temp.length; i++) {
                            three_trips.push(temp[i])
                        }
                        const three_trips_length = three_trips.reduce((resultSet, item) => resultSet.add(item["truck_no"]), new Set()).size
                        tripData["3"] = three_trips_length
                    }
                    if (item.no_of_trips == 4) {
                        temp = JSON.parse(item.trip_leg_detail)
                        for (let i = 0; i < temp.length; i++) {
                            four_trips.push(temp[i])
                        }
                        const four_trips_length = four_trips.reduce((resultSet, item) => resultSet.add(item["truck_no"]), new Set()).size
                        tripData["4"] = four_trips_length
                    }
                    if (item.no_of_trips == 5) {
                        temp = JSON.parse(item.trip_leg_detail)
                        for (let i = 0; i < temp.length; i++) {
                            five_trips.push(temp[i])
                        }
                        const five_trips_length = five_trips.reduce((resultSet, item) => resultSet.add(item["truck_no"]), new Set()).size
                        tripData["5"] = five_trips_length
                    }
                    if (item.no_of_trips == 6) {
                        temp = JSON.parse(item.trip_leg_detail)
                        for (let i = 0; i < temp.length; i++) {
                            six_trips.push(temp[i])
                        }
                        const six_trips_length = six_trips.reduce((resultSet, item) => resultSet.add(item["truck_no"]), new Set()).size
                        tripData["6"] = six_trips_length
                    }
                    if (item.no_of_trips == 7) {
                        temp = JSON.parse(item.trip_leg_detail)
                        for (let i = 0; i < temp.length; i++) {
                            seven_trips.push(temp[i])
                        }
                        const seven_trips_length = seven_trips.reduce((resultSet, item) => resultSet.add(item["truck_no"]), new Set()).size
                        tripData["7"] = seven_trips_length
                    }
                    if (item.no_of_trips == 8) {
                        temp = JSON.parse(item.trip_leg_detail)
                        for (let i = 0; i < temp.length; i++) {
                            eight_trips.push(temp[i])
                        }
                        const eight_trips_length = eight_trips.reduce((resultSet, item) => resultSet.add(item["truck_no"]), new Set()).size
                        tripData["8"] = eight_trips_length
                    }
                    if (item.no_of_trips > 8) {
                        temp = JSON.parse(item.trip_leg_detail)
                        for (let i = 0; i < temp.length; i++) {
                            more_than_eight_trips.push(temp[i])
                        }
                        const more_than_eight_trips_length = more_than_eight_trips.reduce((resultSet, item) => resultSet.add(item["truck_no"]), new Set()).size
                        tripData[">8"] = more_than_eight_trips_length
                    }
                    // console.log('tripdata',tripData)
                })
            } catch (e) {
                console.log("error", e)
            }

            // one_trip.map(trip => trip)

            tripGridData.push(tripData)
            // console.log('two_trips', two_trips)
            // console.log('rowGridData', rowGridData)
            // console.log('tripGridData', tripGridData)
            this.setState({
                'filterTrucks': truck_list,
                'truckWiseRowData': rowGridData,
                'tripWiseRowData': tripGridData,
                'zero_trips': zero_trips,
                'one_trip': one_trip,
                'two_trips': two_trips,
                'three_trips': three_trips,
                'four_trips': four_trips,
                'five_trips': five_trips,
                'six_trips': six_trips,
                'seven_trips': seven_trips,
                'eight_trips': eight_trips,
                'more_than_eight_trips': more_than_eight_trips,
                filterTransporters: dedicated_transporters,
                loadshow: 'show-n',
                overly: 'show-n'
            })
        })
    }

    setStateForSlider = (rowData, sliderTitle, sliderColumns1) => {
        this.setState({
            sliderZoneTranslate: "slider-translate-60p",
            loadshow: "show-n",
            overly: "show-m",
            rowCriticalData: rowData,
            sliderTitle: sliderTitle,
            'sliderColumns1': sliderColumns1
        })
    }

    showSliderTripRowData = (item) => {
        const col_id = item.column.colId
        const rowData = this.state.tripWiseRowData
        const truckData = this.state.truckWiseRowData
        const allTrucksData = []
        // console.log(item, "tripWiseRowData", rowData)
        if (col_id == "total_trucks") {
            truckData.map(truck => {
                var temp = JSON.parse(truck.trip_leg_detail)
                // console.log("temp", temp)
                for (let i = 0; i < temp.length; i++) {
                    allTrucksData.push(temp[i])
                }
            })
            // this.setState({
            //     allTrucksData: allTrucksData
            // })
            var sliderColumns1 = [...sliderColumnsList, ...truckTripTatColumnList]
            this.setStateForSlider(allTrucksData, 'All Trip Details', sliderColumns1)
        }
        if (col_id == "0_1") {
            sliderColumns1 = [...sliderColumnsList, ...truckTripTatColumnList]
            this.setStateForSlider(this.state.zero_trips, 'Trip Details', sliderColumns1)
        }
        if (col_id == "1") {
            // console.log('one_trip', this.state.one_trip)
            sliderColumns1 = [...sliderColumnsList, ...truckTripTatColumnList]
            this.setStateForSlider(this.state.one_trip, 'Trip Details', sliderColumns1)
        }
        if (col_id == "2") {
            sliderColumns1 = [...sliderColumnsList, ...truckTripTatColumnList]
            this.setStateForSlider(this.state.two_trips, 'Trip Details', sliderColumns1)
        }
        if (col_id == "3") {
            sliderColumns1 = [...sliderColumnsList, ...truckTripTatColumnList]
            this.setStateForSlider(this.state.three_trips, 'Trip Details', sliderColumns1)
        }
        if (col_id == "4") {
            sliderColumns1 = [...sliderColumnsList, ...truckTripTatColumnList]
            this.setStateForSlider(this.state.four_trips, 'Trip Details', sliderColumns1)
        }
        if (col_id == "5") {
            sliderColumns1 = [...sliderColumnsList, ...truckTripTatColumnList]
            this.setStateForSlider(this.state.five_trips, 'Trip Details', sliderColumns1)
        }
        if (col_id == "6") {
            sliderColumns1 = [...sliderColumnsList, ...truckTripTatColumnList]
            this.setStateForSlider(this.state.six_trips, 'Trip Details', sliderColumns1)
        }
        if (col_id == "7") {
            sliderColumns1 = [...sliderColumnsList, ...truckTripTatColumnList]
            this.setStateForSlider(this.state.seven_trips, 'Trip Details', sliderColumns1)
        }
        if (col_id == "8") {
            sliderColumns1 = [...sliderColumnsList, ...truckTripTatColumnList]
            this.setStateForSlider(this.state.eight_trips, 'Trip Details', sliderColumns1)
        }
        if (col_id == ">8") {
            sliderColumns1 = [...sliderColumnsList, ...truckTripTatColumnList]
            this.setStateForSlider(this.state.more_than_eight_trips, 'Trip Details', sliderColumns1)
        }

    }

    showSliderRowData = (item) => {
        console.log("item", item)
        const truck_no = item.data.truck_no
        const trip_leg_detail = JSON.parse(item.data.trip_leg_detail)
        // console.log(trip_leg_detail)
        var sliderColumns1 = [...sliderColumnsList, ...truckTripTatColumnList]
        this.setStateForSlider(trip_leg_detail, 'Trip Details', sliderColumns1)
    }

    onClickTab = tab => {
        if (tab === 1) {
            // this.getDedicatedFleetStatus()
            this.setState({
                tab1: 'show-m',
                tab2: 'show-n',
                showUserData: "show-n",
                showSystemData: "show-n",
            })

        }
        if (tab == 2) {
            this.setState({
                tab1: 'show-n',
                tab2: 'show-m',
                showUserData: "show-n",
                showSystemData: "show-n",
            })
        }
        if (tab == 3) {
            var user_data_type = 'user_data'
            var selectedMonth = this.state.selectedMonthUser
            this.setState({
                showUserData: "show-m",
                showSystemData: "show-n",
                tab1: 'show-n',
                tab2: 'show-n',
            }, () => {
                this.generateColumns(selectedMonth)
                this.LoadCNGData(user_data_type,selectedMonth)
            })
        }
        if (tab == 4) {
            var user_type = 'system_data'
            var selectedMonth = this.state.selectedMonthSystem
            this.setState({
                showUserData: "show-n",
                showSystemData: "show-m",
                tab1: 'show-n',
                tab2: 'show-n',
            }, () => {
                this.generateColumns(selectedMonth)
                this.LoadCNGData(user_type,selectedMonth)
            })
        }

    }

    getDedicatedFleetStatus = () => {
        let params = {}
        redirectURL.post("/dashboard/getDataForDedicatedFleetDashboard", params).then(response => {
            const respData = response.data
            const rowData = JSON.parse(respData.final_df)
            // console.log('getDedicatedFleetStatus', rowData)

            const in_transit_df = JSON.parse(respData.in_transit_df)
            // console.log('in_transit_df', in_transit_df)
            const loading_df = JSON.parse(respData.loading_df)
            // console.log('loading_df', loading_df)

            const parking_df = JSON.parse(respData.parking_df)
            // console.log('parking_df', parking_df)

            const return_to_plant_df = JSON.parse(respData.return_to_plant_df)
            // console.log('return_to_plant_df', return_to_plant_df)

            const unloading_df = JSON.parse(respData.unloading_df)
            // console.log('unloading_df', unloading_df)

            // console.log("response" , respData)
            this.setState({
                dedicatedFleetRowData: rowData
            })
            console.log('dedicatedFleetRowData', rowData)
            let parking_0_1_5 = []
            let parking_1_5_3 = []
            let parking_3 = []
            if (parking_df.length > 0) {
                parking_df.map(e => {
                    if (e.loading_tat === '0 to 1.5') {
                        parking_0_1_5.push(e)
                    }
                    else if (e.loading_tat === "1.5 to 3") {
                        parking_1_5_3.push(e)
                    }
                    else {
                        parking_3.push(e)
                    }
                })
            }

            let loading_0_1_5 = []
            let loading_1_5_3 = []
            let loading_3 = []
            if (loading_df.length > 0) {
                loading_df.map(e => {
                    if (e.loading_tat === '0 to 1.5') {
                        loading_0_1_5.push(e)
                    }
                    else if (e.loading_tat === "1.5 to 3") {
                        loading_1_5_3.push(e)
                    }
                    else {
                        loading_3.push(e)
                    }
                })
            }

            let inTransit_g_20 = []
            let inTransit_r_20 = []
            if (in_transit_df.length > 0) {
                in_transit_df.map(e => {
                    if (e.in_transit_tat == ">20") {
                        inTransit_r_20.push(e)
                    }
                    else {
                        inTransit_g_20.push(e)
                    }
                })
            }
            let unLoading_g_20 = []
            let unLoading_r_20 = []
            if (unloading_df.length > 0) {
                unloading_df.map(e => {
                    if (e.in_transit_tat == ">3") {
                        unLoading_r_20.push(e)
                    }
                    else {
                        unLoading_g_20.push(e)
                    }
                })
            }
            let return_g_20 = []
            let return_r_20 = []
            if (return_to_plant_df.length > 0) {
                return_to_plant_df.map(e => {
                    if (e.in_transit_tat == "<12") {
                        return_g_20.push(e)
                    }
                    else {
                        return_r_20.push(e)
                    }
                })
            }

            this.setState({
                inTransit_g_20,
                inTransit_r_20,
                unLoading_g_20,
                unLoading_r_20,
                return_g_20,
                return_r_20,
                parking_0_1_5,
                parking_1_5_3,
                parking_3,
                loading_0_1_5,
                loading_1_5_3,
                loading_3,
                loadshow: 'show-n',
                overly: 'show-n'
            })
        })
    }

    getTruckConsignments = event => {
        event.preventDefault();
        this.setState({
            loadshow: "show-m",
            overly: "show-m"
        })
        const { startDate, endDate, flTruck, flTransporter } = this.state


        // console.log("from_date", startDate, " \n to_date", endDate, "\n truck_no", flTruck, "\n transporter", flTransporter)
        const params = { from_date: startDate, to_date: endDate, truck_no: flTruck, transporters: (flTransporter) }
        //[{val, lab}, {val , lab}]
        // JSON.stringify(['33255'])
        this.getDashboardRowData(params)

    }

    handlerStartDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);

        var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());
        if (this.state.endDate != undefined && this.state.endDate != "") {
            // console.log(startdate,"startdate")
            // console.log(this.state.endDate,"endDate")
            var startDate = new Date(startdate);
            var endDate = new Date(this.state.endDate);
            var startTime = startDate.getTime();
            var endTime = endDate.getTime();
            // console.log(startTime,endTime);
            if (startTime > endTime) {
                this.setState({
                    startDate: ""
                });
                alert("To Date should be greater than From Date");
            }
            else {
                this.setState({
                    startDate: `${startdate} 00:00:00`
                });
            }
        }
        else {
            this.setState({
                startDate: `${startdate} 00:00:00`
            });
        }
    }

    handlerEndDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        var edate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
        if (this.state.endDate != undefined && this.state.endDate != "") {
            // console.log(this.state.startDate,"startdate")
            // console.log(edate,"endDate")
            var startDate = new Date(this.state.startDate);
            var endDate = new Date(edate);
            var startTime = startDate.getTime();
            var endTime = endDate.getTime();
            // console.log(startTime,endTime);
            if (startTime > endTime) {
                this.setState({
                    endDate: ""
                });
                alert("To Date should be greater than From Date");
            }
            else {
                this.setState({
                    endDate: `${edate} 23:59:59`
                });
            }
        }
        else {
            this.setState({
                endDate: `${edate} 23:59:59`
            });
        }
    }

    newFilter = (filterType, selOption) => {
        console.log(filterType, selOption)
        this.setState({ [filterType]: selOption });
        var rowData = this.state.rowDataForFilter
        if (selOption!= null){
            if (selOption.length > 0){
                var values = []
                selOption.map(e => {
                    values.push(e.value)
                })
            
                if (filterType == 'userTransporter'){
                    if (!values.includes('ALL')){
                        var filterData = []
                        rowData.map(e => {
                            selOption.map(f => {
                                if (e.transporter_code == f.value){
                                    filterData.push(e)
                                }
                            })
                        })
                        this.setState({
                            systemData:filterData
                        })
                    }else{
                        this.setState({
                            systemData:rowData
                        })
                    }
                    
                   
                }
                if (filterType == 'systemTransporter'){
                    var rowData = this.state.rowDataForFilter
                    if (!values.includes('ALL')){
                        var rowData = this.state.rowDataForFilter
                        var filterData = []
                        rowData.map(e => {
                            selOption.map(f => {
                                if (e.transporter_code == f.value){
                                    filterData.push(e)
                                }
                            })
                        })
                        this.setState({
                            systemData:filterData
                        })
                    }else{
                        this.setState({
                            systemData:rowData
                        })
                    }
                }
            }
        }
        else{
            this.setState({
                systemData:rowData
            })
        }
        
        
        
    }
    onCloseSliderDiv = () => {
        this.setState({
            sliderZoneTranslate: "",
            sliderCriticalTranslate: "",
            showDiv: "show-n",
            overly: "show-n",
            loadshow: "show-n",
        });
    };

    onClickShowClickableData = tat => {
        // console.log("tat", tat)
        let parkingColumns = [...sliderColumns2,
        {
            headerName: "Parking In-Time",
            // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
            field: "first_inside_fence",
            width: 150,
            // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
            chartDataType: "series",
            valueGetter: params => {
                if (params.data.first_inside_fence != '' && params.data.first_inside_fence != undefined && params.data.first_inside_fence != "NA") {
                    return getHyphenDDMMMYYYYHHMM(params.data.first_inside_fence);
                }
                else {
                    return "NA";
                }
            }
        }
        ]
        let loadingColumns = [...sliderColumns2,
        {
            headerName: "Parking In-Time",
            // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
            field: "parking_in_time",
            width: 150,
            // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
            chartDataType: "series",
            valueGetter: params => {
                if (params.data.parking_in_time != '' && params.data.parking_in_time != undefined && params.data.parking_in_time != "NA") {
                    return getHyphenDDMMMYYYYHHMM(params.data.parking_in_time);
                }
                else {
                    return "NA";
                }
            }
        },
        {
            headerName: "Parking Out-Time",
            // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
            field: "parking_out_time",
            width: 150,
            // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
            chartDataType: "series",
            valueGetter: params => {
                if (params.data.parking_out_time != '' && params.data.parking_out_time != undefined && params.data.parking_out_time != "NA") {
                    return getHyphenDDMMMYYYYHHMM(params.data.parking_out_time);
                }
                else {
                    return "NA";
                }
            }
        },
        {
            headerName: "Parking TAT (Hrs)",
            // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
            field: "parking_duration",
            width: 150,
            // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
            chartDataType: "series"
        },
        {
            headerName: "Loading TAT (Hrs)",
            // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
            field: "plant_duration",
            width: 150,
            // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
            chartDataType: "series"
        },
        ]
        let inTransitColumns = [...sliderColumns2,
        {
            headerName: "Parking In-Time",
            // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
            field: "parking_in_time",
            width: 150,
            // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
            chartDataType: "series",
            valueGetter: params => {
                if (params.data.parking_in_time != '' && params.data.parking_in_time != undefined && params.data.parking_in_time != "NA") {
                    return getHyphenDDMMMYYYYHHMM(params.data.parking_in_time);
                }
                else {
                    return "NA";
                }
            }
        },
        {
            headerName: "Parking Out-Time",
            // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
            field: "parking_out_time",
            width: 150,
            // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
            chartDataType: "series",
            valueGetter: params => {
                if (params.data.parking_out_time != '' && params.data.parking_out_time != undefined && params.data.parking_out_time != "NA") {
                    return getHyphenDDMMMYYYYHHMM(params.data.parking_out_time);
                }
                else {
                    return "NA";
                }
            }
        },
        {
            headerName: "Parking TAT (Hrs)",
            // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
            field: "parking_duration",
            width: 150,
            // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
            chartDataType: "series"
        },
        {
            headerName: "Loading TAT (Hrs)",
            // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
            field: "plant_duration",
            width: 150,
            // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
            chartDataType: "series"
        },
        {
            headerName: "In Transit TAT (Hrs)",
            // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
            field: "transit_hours",
            width: 150,
            // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
            chartDataType: "series"
        },
        ]

        let unloadingColumns = [...sliderColumns2,
        {
            headerName: "Parking In-Time",
            // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
            field: "parking_in_time",
            width: 150,
            // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
            chartDataType: "series",
            valueGetter: params => {
                if (params.data.parking_in_time != '' && params.data.parking_in_time != undefined && params.data.parking_in_time != "NA") {
                    return getHyphenDDMMMYYYYHHMM(params.data.parking_in_time);
                }
                else {
                    return "NA";
                }
            }
        },
        {
            headerName: "Parking Out-Time",
            // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
            field: "parking_out_time",
            width: 150,
            // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
            chartDataType: "series",
            valueGetter: params => {
                if (params.data.parking_out_time != '' && params.data.parking_out_time != undefined && params.data.parking_out_time != "NA") {
                    return getHyphenDDMMMYYYYHHMM(params.data.parking_out_time);
                }
                else {
                    return "NA";
                }
            }
        },
        {
            headerName: "Near Dealership At",
            field: 'first_25_km_entry',
            width: 180,
            //filter: "agDateColumnFilter",
            // comparator: dateComparator,
            valueGetter: function (params) {
                return getHyphenDDMMMYYYYHHMM(params.data.first_25_km_entry);
            },
        },
        {
            headerName: "Parking TAT (Hrs)",
            // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
            field: "parking_duration",
            width: 150,
            // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
            chartDataType: "series"
        },
        {
            headerName: "Loading TAT (Hrs)",
            // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
            field: "plant_duration",
            width: 150,
            // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
            chartDataType: "series"
        },
        {
            headerName: "In Transit TAT (Hrs)",
            // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
            field: "transit_hours",
            width: 150,
            // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
            chartDataType: "series"
        },
        {
            headerName: "Unloading TAT (Hrs)",
            // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
            field: "unloading_hours",
            width: 150,
            // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
            chartDataType: "series"
        },
        ]

        let returnColumns = [...sliderColumns2,
        {
            headerName: "Parking In-Time",
            // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
            field: "parking_in_time",
            width: 150,
            // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
            chartDataType: "series",
            valueGetter: params => {
                if (params.data.parking_in_time != '' && params.data.parking_in_time != undefined && params.data.parking_in_time != "NA") {
                    return getHyphenDDMMMYYYYHHMM(params.data.parking_in_time);
                }
                else {
                    return "NA";
                }
            }
        },
        {
            headerName: "Parking Out-Time",
            // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
            field: "parking_out_time",
            width: 150,
            // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
            chartDataType: "series",
            valueGetter: params => {
                if (params.data.parking_out_time != '' && params.data.parking_out_time != undefined && params.data.parking_out_time != "NA") {
                    return getHyphenDDMMMYYYYHHMM(params.data.parking_out_time);
                }
                else {
                    return "NA";
                }
            }
        },
        {
            headerName: "Near Dealership At",
            field: 'first_25_km_entry',
            width: 180,
            //filter: "agDateColumnFilter",
            // comparator: dateComparator,
            valueGetter: function (params) {
                return getHyphenDDMMMYYYYHHMM(params.data.first_25_km_entry);
            },

        },
        {
            headerName: "Left Destination",
            field: 'left_dealer_marked',
            width: 180,
            //filter: "agDateColumnFilter",
            // comparator: dateComparator,
            valueGetter: function (params) {
                if (params.data.left_dealer_marked !== '' && params.data.left_dealer_marked !== null) {
                    return getHyphenDDMMMYYYYHHMM(params.data.left_dealer_marked);
                }
                return ''
            },
        },
        {
            headerName: "Parking TAT (Hrs)",
            // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
            field: "parking_duration",
            width: 150,
            // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
            chartDataType: "series"
        },
        {
            headerName: "Loading TAT (Hrs)",
            // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
            field: "plant_duration",
            width: 150,
            // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
            chartDataType: "series"
        },
        {
            headerName: "In Transit TAT (Hrs)",
            // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
            field: "transit_hours",
            width: 150,
            // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
            chartDataType: "series"
        },
        {
            headerName: "Unloading TAT (Hrs)",
            // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
            field: "unloading_hours",
            width: 150,
            // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
            chartDataType: "series"
        },
        {
            headerName: "Return TAT (Hrs)",
            // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
            field: "return_hours",
            width: 150,
            // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
            chartDataType: "series"
        },

        ]

        if (tat == 'parking_0_1_5') {
            const rowData = this.state.parking_0_1_5;
            // let newRData = rowData.filter(item => item.parking_tat_grp == "0 to 1.5");
            this.setStateForSlider(rowData, "Parking(0 to 1.5 hrs)", parkingColumns)
        }
        else if (tat == 'parking_1_5_3') {
            const rowData = this.state.parking_1_5_3;
            // let newRData = rowData.filter(item => item.parking_tat_grp == "1.5 to 3");
            this.setStateForSlider(rowData, "Parking(1.5 to 3 hrs)", parkingColumns)
        }
        else if (tat == 'parking_3') {
            const rowData = this.state.parking_3;
            // let newRData = rowData.filter(item => item.parking_tat_grp == ">3");
            // console.log('parking_3', rowData)
            this.setStateForSlider(rowData, "Parking(>3 hrs)", parkingColumns)
        }
        else if (tat == 'loading_0_1_5') {
            const rowData = this.state.loading_0_1_5;
            // let newRData = rowData.filter(item => item.loading_tat_grp == "0 to 1.5");            
            this.setStateForSlider(rowData, "Loading(0 to 1.5 hrs)", loadingColumns)
        }
        else if (tat == 'loading_1_5_3') {
            const rowData = this.state.loading_1_5_3;
            // let newRData = rowData.filter(item => item.loading_tat_grp == "1.5 to 3");
            this.setStateForSlider(rowData, "Loading(1.5 to 3 hrs)", loadingColumns)
        }
        else if (tat == 'loading_3') {
            const rowData = this.state.loading_3;
            // let newRData = rowData.filter(item => item.loading_tat_grp == ">3");
            this.setStateForSlider(rowData, "Loading(>3 hrs)", loadingColumns)
        }
        // else if(tat == 'inTransit_0_1_5'){
        //     const rowData = this.state.dedicatedFleetRowData;
        //     let newRData = rowData.filter(item => item.transit_tat_grp == "0 to 1.5");
        //     this.setStateForSlider(newRData, "In Transit(0 to 1.5 hrs)", sliderColumns2)
        // }
        else if (tat == 'inTransit_g_20') {
            const rowData = this.state.inTransit_g_20;
            // let newRData = rowData.filter(item => item.transit_tat_grp == "1.5 to 3");
            this.setStateForSlider(rowData, "In Transit(1.5 to 3 hrs)", inTransitColumns)
        }
        else if (tat == 'inTransit_r_20') {
            const rowData = this.state.inTransit_r_20;
            // let newRData = rowData.filter(item => item.transit_tat_grp == ">3");
            this.setStateForSlider(rowData, "In Transit(>3 hrs)", inTransitColumns)
        }
        // else if(tat == 'unLoading_0_1_5'){
        //     const rowData = this.state.dedicatedFleetRowData;
        //     let newRData = rowData.filter(item => item.unloading_tat_grp == "0 to 1.5");
        //     this.setStateForSlider(newRData, "Unloading(0 to 1.5 hrs)", sliderColumns2)
        // }
        else if (tat == 'unLoading_g_20') {
            const rowData = this.state.unLoading_g_20;

            // let newRData = rowData.filter(item => item.unloading_tat_grp == "1.5 to 3");
            this.setStateForSlider(rowData, "Unloading(>3 hrs)", unloadingColumns)
        }
        else if (tat == 'unLoading_r_20') {
            const rowData = this.state.unLoading_r_20;

            // let newRData = rowData.filter(item => item.unloading_tat_grp == ">3");
            this.setStateForSlider(rowData, "Unloading(<=3 hrs)", unloadingColumns)
        }
        // else if(tat == 'return_0_1_5'){
        //     const rowData = this.state.dedicatedFleetRowData;
        //     let newRData = rowData.filter(item => item.return_tat_grp == "0 to 1.5");
        //     this.setStateForSlider(newRData, "Return(0 to 1.5 hrs)", sliderColumns2)
        // }
        else if (tat == 'return_g_20') {
            const rowData = this.state.return_g_20;
            // let newRData = rowData.filter(item => item.return_tat_grp == "1.5 to 3");            
            this.setStateForSlider(rowData, "Return(<12 hrs)", returnColumns)
        }
        else if (tat == 'return_r_20') {
            const rowData = this.state.return_r_20;
            // let newRData = rowData.filter(item => item.return_tat_grp == ">3");
            this.setStateForSlider(rowData, "Return(>12 hrs)", returnColumns)
        }
        this.setState({
            loadshow: "show-n",
            overly: "show-m",
        })

    }

    onClickHideAll = () => {
        this.setState({
            overly: "show-n",
            loadshow: "show-n",
            show: false
        })
    }

    showSliderFleetRowData = (item) => {
        // console.log("item", item.data)
        // const rowData = item.data;
        const rowGridData = []
        rowGridData.push(item.data)
        // console.log('FleetData', rowGridData)
        // const rowData = this.state.dedicatedFleetRowData
        // console.log(rowData)
        // rowData.filter(x => x.plant_code == item.plant_code)
        var sliderColumns1 = sliderColumnsList
        this.setStateForSlider(rowGridData, 'Trip Details', sliderColumns1)
    }

    updateCellData = val => {
        // console.log("val", val)
        if (val.colDef.field === 'remark') {
            // console.log("updateBtn",val.data)
            const cCode = {
                invoice_no: val.data.invoice_no
            }

            const query = {
                remarks_added: val.data.remarks_added,
                remarks_marked_by: localStorage.getItem('email'),
                dedicated_remark_update: 1
            }
            const left_dealer_marked = val.data.left_dealer_marked
            if (![null, undefined, '', ' ', 'null', 'undefined'].includes(left_dealer_marked) && val.data.dedicated_remark_update !== 1) {
                console.log("enter_c")
                if (!['NO ISSUE', "", " ", null].includes(val.data.remarks_added) && !["", " ", null].includes(val.data.invoice_no)) {
                    redirectURL.post('/trucks/updateDedicatedFleetRemarks', { cCode, query }).then(res => {
                        // console.log("response", res.data)
                        this.setState({
                            show: true,
                            basicTitle: 'Updated Remarks',
                            basicType: 'success'
                        })
                        // window.location.reload();
                        this.getDedicatedFleetStatus()
                    })
                } else {
                    this.setState({
                        show: true,
                        basicTitle: "Can't add Remark without having Remarks and Invoice ",
                        basicType: 'danger'
                    })
                }
            }
        }
    }

    closeAlert = () => {
        this.setState({
            show: false,
            basicType: "default",
            basicTitle: ""
        })
    }

    closeAlert1 = async () => {
        await this.setState({
            show: false,
            basicType: "default",
            basicTitle: ""
        })
        await window.location.reload()
    }

    render() {
        var { userData, systemData, show, basicType, basicTitle, rowData, modules, defaultColDef, statusBar, columns, slider, loadshow, overly } = this.state
        const tripWiseSummaryColDef = [
            {
                headerName: "Trip Wise Dashboard",
                headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                field: "",
                cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                // chartDataType: 'series',
                width: 300,
                chartDataType: "excluded",
                children: [
                    {
                        headerName: "Total Trucks",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                        field: "total_trucks",
                        width: 120,
                        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                        chartDataType: "series"
                    },
                    {
                        headerName: "0",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                        field: "0",
                        width: 80,
                        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                        chartDataType: "series"
                    },
                    {
                        headerName: "1",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                        field: "1",
                        width: 80,
                        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                        chartDataType: "series"
                    },
                    {
                        headerName: "2",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                        field: "2",
                        width: 80,
                        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                        chartDataType: "series"
                    },
                    {
                        headerName: "3",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                        field: "3",
                        width: 80,
                        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                        chartDataType: "series"
                    },
                    {
                        headerName: "4",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                        field: "4",
                        width: 80,
                        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                        chartDataType: "series"
                    },
                    {
                        headerName: "5",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                        field: "5",
                        width: 80,
                        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                        chartDataType: "series"
                    },
                    {
                        headerName: "6",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                        field: "6",
                        width: 80,
                        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                        chartDataType: "series"
                    },
                    {
                        headerName: "7",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                        field: "7",
                        width: 80,
                        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                        chartDataType: "series"
                    },
                    {
                        headerName: "8",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                        field: "8",
                        width: 80,
                        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                        chartDataType: "series"
                    },
                    {
                        headerName: ">8",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                        field: ">8",
                        width: 80,
                        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                        chartDataType: "series"
                    },
                ]
            },
        ]

        const truckWiseSummaryColDef = [
            {
                headerName: "Truck Wise Dashboard",
                headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                field: "",
                cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                // chartDataType: 'series',
                width: 300,
                chartDataType: "excluded",
                children: [
                    {
                        headerName: "Truck Number",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                        field: "truck_no",
                        width: 150,
                        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                        chartDataType: "series"
                    },
                    {
                        headerName: "Total Trips",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                        field: "no_of_trips",
                        width: 150,
                        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                        chartDataType: "series"
                    },

                ]
            },
        ]

        const dedicatedFleetDashboardColDef = [...sliderColumns2,
        {
            headerName: "Device Status",
            field: 'timestamp',
            width: 140,
            //filter: "agDateColumnFilter",
            // comparator: dateComparator,
            valueGetter: function (params) {
                if (params.data.timestamp !== '' && params.data.timestamp !== null) {
                    return getHyphenDDMMMYYYYHHMM(params.data.timestamp);
                }
                return ''
            },
        },
        {
            headerName: "Left Destination",
            field: 'left_dealer_marked',
            width: 140,
            //filter: "agDateColumnFilter",
            // comparator: dateComparator,
            valueGetter: function (params) {
                if (params.data.left_dealer_marked !== '' && params.data.left_dealer_marked !== null) {
                    return getHyphenDDMMMYYYYHHMM(params.data.left_dealer_marked);
                }
                return ''
            },
        },
        {
            headerName: "Near Dealership At",
            field: 'first_25_km_entry',
            width: 140,
            //filter: "agDateColumnFilter",
            // comparator: dateComparator,
            valueGetter: function (params) {
                return getHyphenDDMMMYYYYHHMM(params.data.first_25_km_entry);
            },

        },
        {
            headerName: "Parking TAT (Hrs)",
            // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
            field: "parking_duration",
            width: 140,
            // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
            chartDataType: "series"
        },
        {
            headerName: "Loading TAT (Hrs)",
            // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
            field: "plant_duration",
            width: 140,
            // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
            chartDataType: "series"
        },
        {
            headerName: "In Transit TAT (Hrs)",
            // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
            field: "transit_hours",
            width: 140,
            // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
            chartDataType: "series"
        },
        {
            headerName: "Unloading TAT (Hrs)",
            // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
            field: "unloading_hours",
            width: 140,
            // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
            chartDataType: "series"
        },
        {
            headerName: "Return TAT (Hrs)",
            // headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
            field: "return_hours",
            width: 140,
            // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
            chartDataType: "series"
        },

        {
            headerName: "Parking In",
            field: 'parking_in_time',
            width: 180,
            //filter: "agDateColumnFilter",
            // comparator: dateComparator,
            valueGetter: function (params) {
                try {
                    return getHyphenDDMMMYYYYHHMM(params.data.parking_in_time);
                } catch (e) {
                    return ''
                }
            },
        },
        {
            headerName: "Parking Out",
            field: 'parking_out_time',
            width: 180,
            //filter: "agDateColumnFilter",
            // comparator: dateComparator,
            valueGetter: function (params) {
                try {
                    return getHyphenDDMMMYYYYHHMM(params.data.parking_out_time);
                } catch (e) {
                    return ''
                }

            },
        },
        {
            headerName: "Plant In",
            field: 'plant_in_time',
            width: 180,
            //filter: "agDateColumnFilter",
            // comparator: dateComparator,
            valueGetter: function (params) {
                try {
                    return getHyphenDDMMMYYYYHHMM(params.data.plant_in_time);
                } catch (e) {
                    return ''
                }
            },
        },
        {
            headerName: "Plant Out",
            field: 'plant_out_time',
            width: 180,
            //filter: "agDateColumnFilter",
            // comparator: dateComparator,
            valueGetter: function (params) {
                try {
                    return getHyphenDDMMMYYYYHHMM(params.data.plant_out_time);
                } catch (e) {
                    return ''
                }

            },
        },
        {
            headerName: "Recent Stoppage Start",
            field: 'latest_break_start',
            width: 140,
            //filter: "agDateColumnFilter",
            // comparator: dateComparator,
            valueGetter: function (params) {
                try {
                    return getHyphenDDMMMYYYYHHMM(params.data.latest_break_start);
                } catch (e) {
                    return ''
                }

            },
        },
        {
            headerName: "Recent Stoppage End",
            field: 'latest_break_end',
            width: 140,
            //filter: "agDateColumnFilter",
            // comparator: dateComparator,
            valueGetter: function (params) {
                try {
                    return getHyphenDDMMMYYYYHHMM(params.data.latest_break_end);
                } catch (e) {
                    return ''
                }

            },
        },
        {
            headerName: "Total Stoppage Duration",
            field: 'total_break_duration',
            width: 180,
        },
        // {
        //     headerName : 'Remarks',
        //     field : 'remarks_added',
        //     width : 100,
        //     editable : param =>{
        //         if (!['NO ISSUE','HMCL ISSUE', 'TCI ISSUE'].includes(param.data.remarks_added)) return true
        //             return false 
        //     },
        //     cellEditor: 'agRichSelectCellEditor',
        //     cellEditorParams: () =>{                   				
        //         return {
        //             values: ['HMCL ISSUE', 'TCI ISSUE']
        //             }							
        //     },
        //     // valueSetter : param =>{
        //     //     const oldValue = param.oldValue
        //     //     const newValue = param.newValue
        //     //     if(oldValue !== newValue){
        //     //         param.data.remarks_added = newValue
        //     //     }
        //     // },
        //     valueGetter : param => {
        //         // console.log("remarks_added",param.data.remarks_added)
        //         return param.data.remarks_added
        //     }
        // },
        {
            headerName: 'update',
            field: 'remark',
            width: 100,
            cellRenderer: (params) => {
                // console.log("p.data.remarks_added",params.data.remarks_added)
                const eta_date = params.data.expected_trip_end
                const left_dealer_marked = params.data.left_dealer_marked
                if ([null, undefined, '', ' ', 'null', 'undefined'].includes(left_dealer_marked)) {
                    if (moment() > moment(eta_date).add(24, 'hours')) {
                        return ""
                    }
                    if (moment() > moment(eta_date)) {
                        console.log("check",)
                        if (!["", " ", "NO ISSUE"].includes(params.data.remarks_added) && params.data.dedicated_remark_update !== 1) return '<button class="btn btn-danger" style="padding:0 9px">Update</button>'
                    }
                    return ""
                }
                return ""
            },
            // valueGetter : params =>{
            //     const eta_date = params.data.expected_trip_end
            //     const left_dealer_marked = params.data.left_dealer_marked                  
            //     if(moment()> moment.utc(eta_date) && ![null,undefined, '', ' ','null', 'undefined'].includes(left_dealer_marked)){
            //         console.log('moment(new Date)',moment())
            //         console.log('etaDate',moment.utc(eta_date))
            //         console.log('moment',moment() > moment(eta_date))
            //         console.log(left_dealer_marked,'is_left',!left_dealer_marked)
            //     }
            // }           
        }
        ]
        return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <SweetAlert
                    show={this.state.show1}
                    type={this.state.basicType1}
                    title={this.state.basicTitle1}
                    onConfirm={this.closeAlert1}
                >
                </SweetAlert>
                <div className={'col-xl-12 col-lg-12'} >
                    <button className={"btn btn-danger"} style={{ marginLeft: '-30px' }} onClick={() => this.onClickTab(1)}>Dedicated Fleet Dashboard</button>
                    <button className={"btn btn-warning"} onClick={() => this.onClickTab(2)}>Truck Wise Trip Dashboard</button>
                    <button className={"btn btn-info"} onClick={() => this.onClickTab(3)}>User Data</button>
                    <button className={"btn btn-dark"} onClick={() => this.onClickTab(4)}>System Data</button>
                </div>
                <div className='row'>
                    <div className="card col-xl-12 col-lg-12">
                        {/* Truck Wise tab button data  */}
                        <div className={"card-body " + this.state.tab2} style={{ padding: '10px' }}>
                            <form className="row" onSubmit={this.getTruckConsignments} style={{ margin: '10px' }}>
                                <div className="form-group col-xl-3 col-lg-3">
                                    <label>Transporter :  </label>
                                    <Select
                                        className="border-radius-0"
                                        isMulti={true}
                                        id="selectedTransporter"
                                        style={{ borderRadius: "0px" }}
                                        value={this.state.flTransporter}
                                        onChange={this.newFilter.bind(this, "flTransporter")}
                                        options={this.state.filterTransporters}
                                    />
                                </div>
                                <div className="form-group col-xl-3 col-lg-3">
                                    <label>Trucks :  </label>
                                    <Select
                                        className="border-radius-0"
                                        isMulti={true}
                                        id="selectedTruck"
                                        style={{ borderRadius: "0px" }}
                                        value={this.state.flTruck}
                                        onChange={this.newFilter.bind(this, "flTruck")}
                                        options={this.state.filterTrucks}
                                    />
                                </div>
                                <div className="col-xl-2 col-lg-2 form-group">
                                    <label>From Date</label>
                                    <Datetime
                                        value={this.state.startDate}
                                        disableCloseOnClickOutside={false}
                                        closeOnSelect={true}
                                        inputProps={{ placeholder: 'From', name: 'startDate', autoComplete: 'off' }}
                                        dateFormat="YYYY-MM-DD"
                                        name="startDate"
                                        onChange={this.handlerStartDateTime} />
                                </div>

                                <div className="col-xl-2 col-lg-2 form-group">
                                    <label>To Date</label>
                                    <Datetime
                                        value={this.state.endDate}
                                        disableCloseOnClickOutside={false}
                                        closeOnSelect={true}
                                        inputProps={{ placeholder: 'To', name: 'endDate', autoComplete: 'off' }}
                                        dateFormat="YYYY-MM-DD"
                                        onChange={this.handlerEndDateTime} />
                                </div>
                                <div className="form-group col-xl-2 col-lg-2" style={{ textAlign: "left", marginTop: "30px" }}>
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </div>
                            </form>
                            <hr style={{ border: '1px solid #c5bcbc' }} />
                            <div className="col-xl-12 col-lg-12">
                                <div className="height-equal equal-height-lg" style={{ padding: '10px' }}>
                                    <div className="card-body" style={{ padding: '10px' }}>
                                        <div className='row'>
                                            {/* Truck Wise this is for 2nd tab */}
                                            <div className="col-xl-3 col-lg-3">
                                                <div className="card-header" style={{ padding: '10px' }}>
                                                    <h5>Truck Trip Wise Dashboard</h5>
                                                </div>
                                                <div id="myGrid" style={{ height: "400px" }} className="ag-theme-balham">
                                                    <AgGridReact
                                                        modules={this.state.modules}
                                                        columnDefs={truckWiseSummaryColDef}
                                                        defaultColDef={this.state.defaultColDef}
                                                        rowData={this.state.truckWiseRowData}
                                                        enableCharts={false}
                                                        enableRangeSelection={true}
                                                        statusBar={this.state.statusBar}
                                                        pagination={true}
                                                        groupHeaderHeight={40}
                                                        headerHeight={40}
                                                        floatingFiltersHeight={50}
                                                        pivotGroupHeaderHeight={30}
                                                        pivotHeaderHeight={50}
                                                        rowHeight={40}
                                                        onGridReady={this.onGridReady1}
                                                        onGridState={this.onGridState1}
                                                        onCellClicked={this.showSliderRowData}
                                                    //sideBar={this.state.sideBar}
                                                    // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-9 col-lg-9">
                                                <div className="card-header">
                                                    <h5> <br /> </h5>
                                                </div>
                                                {/* Trip Wise */}
                                                <div id="myGrid" style={{ height: "400px" }} className="ag-theme-balham">
                                                    <AgGridReact
                                                        modules={this.state.modules}
                                                        columnDefs={tripWiseSummaryColDef}
                                                        defaultColDef={this.state.defaultColDef}
                                                        rowData={this.state.tripWiseRowData}
                                                        enableCharts={false}
                                                        enableRangeSelection={true}
                                                        statusBar={this.state.statusBar}
                                                        pagination={true}
                                                        groupHeaderHeight={40}
                                                        headerHeight={40}
                                                        floatingFiltersHeight={50}
                                                        pivotGroupHeaderHeight={30}
                                                        pivotHeaderHeight={50}
                                                        rowHeight={40}
                                                        onGridReady={this.onGridReady1}
                                                        onGridState={this.onGridState1}
                                                        onCellClicked={this.showSliderTripRowData}

                                                    //sideBar={this.state.sideBar}
                                                    // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* slider div */}
                        <div
                            className={"slide-r " + this.state.sliderZoneTranslate}
                            style={{ overflow: "auto" }}
                        >
                            <div className="slide-r-title">
                                <h4>
                                    {this.state.sliderTitle}
                                    <span
                                        className="float-right closebtn"
                                        style={{ marginRight: "25px" }}
                                        onClick={this.onCloseSliderDiv}
                                    >
                                        X
                                    </span>
                                </h4>
                            </div>
                            <div className="slide-r-body" style={{ position: "relative" }}>
                                <div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
                                    <div
                                        id="myGrid"
                                        style={{
                                            height: "600px",
                                            width: "100%"
                                        }}
                                        className="ag-theme-balham"
                                    >
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={this.state.sliderColumns1}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowCriticalData}
                                            paginationPageSize={this.state.paginationPageSize}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            // overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                            // pivotHeaderHeight={this.state.pivotHeaderHeight}
                                            // headerHeight={this.state.headerHeight}
                                            // floatingFiltersHeight={this.state.floatingFiltersHeight}
                                            // pivotGroupHeaderHeight={this.state.pivotGroupHeaderHeight}
                                            onCellClicked={this.getZoneLegData}
                                            rowHeight={this.state.rowHeight}
                                            // onGridReady={this.onGridReady}
                                            // onGridState={this.onGridState}
                                            pagination={true}
                                            masterDetail={true}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            suppressRowClickSelection={true}
                                        // onCellEditingStopped={this.onCellUpdateData}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* slider div end */}

                        {/* Dedicated Fleet tab button data  */}
                        <div className={"col-xl-12 col-lg-12 " + this.state.tab1} style={{ padding: '10px' }}>
                            <div className="crm-numbers pb-0">
                                <div className="row">
                                    <div className="col cirlce-d">
                                        <span className="f16"><i className="icofont icofont-ui-calculator txt-warning"></i><br /> Parking Tat</span>
                                        <br />
                                        <div className="row f16">
                                            <div className="col-xl-4  font-weight-bold mt-3 counter txt-success-medium cursorPointer" onClick={() => this.onClickShowClickableData("parking_0_1_5")}>
                                                <span>
                                                0-1.5 Hrs: &nbsp;
                                                <CountUp end={(this.state.parking_0_1_5.length != 0) ? this.state.parking_0_1_5.length : 0} />
                                                </span>
                                            </div>
                                            <div className="col-xl-4  font-weight-bold mt-3 counter txt-warning cursorPointer" onClick={() => this.onClickShowClickableData("parking_1_5_3")}>
                                                1.5-3 Hrs : &nbsp;
                                                <CountUp end={(this.state.parking_1_5_3.length != 0) ? this.state.parking_1_5_3.length : 0} />
                                            </div>
                                            <div className="col-xl-4 mt-3  font-weight-bold counter txt-danger cursorPointer" onClick={() => this.onClickShowClickableData("parking_3")}>
                                                &gt; 3 Hrs : &nbsp;
                                                <CountUp end={(this.state.parking_3.length != 0) ? this.state.parking_3.length : 0} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col cirlce-d">
                                        <span className="f16"><i className="icofont icofont-ui-calculator txt-primary"></i><br /> Loading Tat</span>
                                        {/* <h4 className="txt-dark f20 cursorPointer" onClick={() => this.onClickShowClickableData("totalWaitingAtDealer")}><span className="counter"><CountUp end={((waitingAtDealer.length) != '') ? (waitingAtDealer.length) : 0} /></span></h4> */}
                                        <br />
                                        <div className="row f16">
                                            <div className="col-xl-4 mt-3  font-weight-bold counter txt-success-medium cursorPointer" onClick={() => this.onClickShowClickableData("loading_0_1_5")}>
                                                0-1.5 Hrs : &nbsp;
                                                <CountUp end={(this.state.loading_0_1_5.length != 0) ? this.state.loading_0_1_5.length : 0} />
                                            </div>
                                            <div className="col-xl-4 mt-3  font-weight-bold counter txt-warning cursorPointer" onClick={() => this.onClickShowClickableData("loading_1_5_3")}>
                                                1.5-3 Hrs : &nbsp;
                                                <CountUp end={(this.state.loading_1_5_3.length != 0) ? this.state.loading_1_5_3.length : 0} />
                                            </div>
                                            <div className="col-xl-4 mt-3  font-weight-bold counter txt-danger cursorPointer" onClick={() => this.onClickShowClickableData("loading_3")}>
                                                &gt; 3 Hrs : &nbsp;
                                                <CountUp end={(this.state.loading_3.length != 0) ? this.state.loading_3.length : 0} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col cirlce-d">
                                        <span className="f16"><i className="icofont icofont-ui-calculator txt-info"></i><br /> In Transit Tat</span>
                                        {/* <h4 className="txt-dark f20 cursorPointer" onClick={() => this.onClickShowClickableData("totalWaitingAtDealer")}><span className="counter"><CountUp end={((waitingAtDealer.length) != '') ? (waitingAtDealer.length) : 0} /></span></h4> */}
                                        <br />
                                        <div className="row f16">
                                            <div className="col-xl-6 mt-3  font-weight-bold counter txt-success-medium cursorPointer" onClick={() => this.onClickShowClickableData("inTransit_g_20")}>
                                                &#60; 20 Hrs : &nbsp;
                                                <CountUp end={(this.state.inTransit_g_20.length != 0) ? this.state.inTransit_g_20.length : 0} />
                                            </div>
                                            {/* 1.5-3 Hrs : &nbsp; */}
                                            <div className="col-xl-6 mt-3  font-weight-bold counter txt-danger cursorPointer" onClick={() => this.onClickShowClickableData("inTransit_r_20")}>
                                                &gt; 20 Hrs: &nbsp;
                                                <CountUp end={(this.state.inTransit_r_20.length != 0) ? this.state.inTransit_r_20.length : 0} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col cirlce-d">
                                        <span className="f16"><i className="icofont icofont-ui-calculator txt-secondary"></i><br /> Unloading Tat</span>
                                        {/* <h4 className="txt-dark f20 cursorPointer" onClick={() => this.onClickShowClickableData("totalWaitingAtDealer")}><span className="counter"><CountUp end={((waitingAtDealer.length) != '') ? (waitingAtDealer.length) : 0} /></span></h4> */}
                                        <br />
                                        <div className="row f16">
                                            <div className="col-xl-6 mt-3  font-weight-bold counter txt-success-medium cursorPointer" onClick={() => this.onClickShowClickableData("unLoading_r_20")}>
                                                &#60; 3 Hrs : &nbsp;
                                                <CountUp end={(this.state.unLoading_r_20.length != 0) ? this.state.unLoading_r_20.length : 0} />
                                            </div>
                                            <div className="col-xl-6 mt-3  font-weight-bold counter txt-danger cursorPointer" onClick={() => this.onClickShowClickableData("unLoading_g_20")}>
                                                &gt; 3 Hrs: &nbsp;
                                                <CountUp end={(this.state.unLoading_g_20.length != 0) ? this.state.unLoading_g_20.length : 0} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col cirlce-d">
                                        <span className="f16"><i className="icofont icofont-ui-calculator txt-success"></i><br /> Return Tat</span>
                                        {/* <h4 className="txt-dark f20 cursorPointer" onClick={() => this.onClickShowClickableData("totalWaitingAtDealer")}><span className="counter"><CountUp end={((waitingAtDealer.length) != '') ? (waitingAtDealer.length) : 0} /></span></h4> */}
                                        <br />
                                        <div className="row f16">
                                            <div className="col-xl-6 mt-3  font-weight-bold counter txt-success-medium cursorPointer" onClick={() => this.onClickShowClickableData("return_g_20")}>
                                                &#60; 12 Hrs : &nbsp;
                                                <CountUp end={(this.state.return_g_20.length != 0) ? this.state.return_g_20.length : 0} />
                                            </div>
                                            <div className="col-xl-6 mt-3  font-weight-bold counter txt-danger cursorPointer" onClick={() => this.onClickShowClickableData("return_r_20")}>
                                                &gt; 12 Hrs : &nbsp;
                                                <CountUp end={(this.state.return_r_20.length != 0) ? this.state.return_r_20.length : 0} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr style={{ border: '1px solid #c5bcbc' }} />
                            <div className='row'>
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card-header" style={{ padding: '10px' }}>
                                    <h5 >
                                    Truck Trip TAT Dashboard
                                    
                                    <span className="layoutbtns float-right">
                                        <button className="float-right custom-btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
                                            <i className="icofont icofont-save"></i> Save Grid Layout
                                        </button>
                                        <button className="float-right custom-btn white btn-info" style={{marginRight:"10px"}} onClick={this.resetState.bind(this)}>
                                            <i className="icofont icofont-refresh"></i> Reset Default Layout
                                        </button>
                                        {/* <button className="float-right custom-btn white btn-info" style={{marginRight:"10px"}} onClick={this.showBulkUpload.bind(this)}>
                                            Bulk Force Close
                                        </button> */}
                                    </span>
                                </h5>
                                    </div>
                                    <div id="myGrid" style={{ height: "600px", width: "100%" }} className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={dedicatedFleetDashboardColDef}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.dedicatedFleetRowData}
                                            enableCharts={false}
                                            enableRangeSelection={true}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            pagination={true}
                                            groupHeaderHeight={40}
                                            headerHeight={40}
                                            floatingFiltersHeight={50}
                                            pivotGroupHeaderHeight={30}
                                            pivotHeaderHeight={50}
                                            rowHeight={40}
                                            onGridReady={this.onGridReadyDFD}
                                            onGridState={this.onGridStateDFD}
                                            onCellClicked={this.updateCellData}
                                        //sideBar={this.state.sideBar}
                                        // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"col-xl-12 col-lg-12 " + this.state.showUserData} style={{ padding: '10px' }}>

                            <div className="card-header" style={{ padding: '10px' }}>
                                <h4 className='mb-4'>Dedicated Truck Monthly Dashbaord - User</h4>
                                <div className="row">
                                    <div className='col-xl-3 col-lg-3'>
                                        <label>Select Month :  </label> <br/>
                                        <input type="month" value={this.state.selectedMonthUser ? this.state.selectedMonthUser.toISOString().substr(0, 7) : ''} onChange={this.onChangeHandler1}                                             style={{ borderRadius: "0px" , height:"38px" }}  />
                                    </div>
                                    <div className="col-xl-3 col-lg-3">
                                        <label>Transporter :  </label>
                                        <Select
                                            className="border-radius-0"
                                            isMulti={true}
                                            id="selectedTransporter"
                                            style={{ borderRadius: "0px" }}
                                            value={this.state.userTransporter}
                                            onChange={this.newFilter.bind(this, "userTransporter")}
                                            options={this.state.filterTransporters}
                                        />
                                    </div>

                                    <div className="col-sm-12">
                                        <button className={"float-right btn btn-warning"} onClick={this.updateGridDataOnClick}>
                                            Update
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="height-equal equal-height-lg" style={{ padding: '10px' }}>
                                <div className="card-body" style={{ padding: '10px' }}>
                                    <div id="myGrid" style={{ height: "400px" }} className="ag-theme-balham">
                                        <AgGridReact
                                            modules={modules}
                                            columnDefs={columns}
                                            defaultColDef={defaultColDef}
                                            rowData={systemData}
                                            enableCharts={false}
                                            enableRangeSelection={true}
                                            statusBar={statusBar}
                                            pagination={true}
                                            groupHeaderHeight={40}
                                            headerHeight={40}
                                            floatingFiltersHeight={50}
                                            pivotGroupHeaderHeight={30}
                                            pivotHeaderHeight={50}
                                            rowHeight={40}
                                            onGridReady={this.onGridReady1}
                                            onGridState={this.onGridState1}
                                            // onCellClicked={this.showSliderRowData}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            onCellValueChanged={this.onCellValueChanged}
                                        //sideBar={sideBar}
                                        // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                        />
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className={"col-xl-12 col-lg-12 " + this.state.showSystemData} style={{ padding: '10px' }}>

                            <div className="card-header" style={{ padding: '10px' }}>
                                <h4 className='mb-4'>Dedicated Truck Monthly Dashbaord - System</h4>
                                <div className="row">
                                <div className='col-xl-3 col-lg-3'>
                                        <label>Select Month :  </label> <br/>
                                        <input type="month" value={this.state.selectedMonthSystem ? this.state.selectedMonthSystem.toISOString().substr(0, 7) : ''} onChange={this.onChangeHandler2}                                             style={{ borderRadius: "0px" , height:"38px" }}  />
                                    </div>
                                    <div className="col-xl-3 col-lg-3">
                                        <label>Transporter :  </label>
                                        <Select
                                            className="border-radius-0"
                                            isMulti={true}
                                            id="selectedTransporter"
                                            style={{ borderRadius: "0px" }}
                                            value={this.state.systemTransporter}
                                            onChange={this.newFilter.bind(this, "systemTransporter")}
                                            options={this.state.filterTransporters}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="height-equal equal-height-lg" style={{ padding: '10px' }}>
                                <div className="card-body" style={{ padding: '10px' }}>
                                    <div id="myGrid" style={{ height: "400px" }} className="ag-theme-balham">
                                        <AgGridReact
                                            modules={modules}
                                            columnDefs={columns}
                                            defaultColDef={defaultColDef}
                                            rowData={systemData}
                                            enableCharts={false}
                                            enableRangeSelection={true}
                                            statusBar={statusBar}
                                            pagination={true}
                                            groupHeaderHeight={40}
                                            headerHeight={40}
                                            floatingFiltersHeight={50}
                                            pivotGroupHeaderHeight={30}
                                            pivotHeaderHeight={50}
                                            rowHeight={40}
                                            onGridReady={this.onGridReady1}
                                            onGridState={this.onGridState1}
                                            // onCellClicked={this.showSliderRowData}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            onCellValueChanged={this.onCellValueChanged}
                                        //sideBar={sideBar}
                                        // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                        />
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " + (loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (loadshow)} style={{ position: "fixed", top: "43%" }}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (overly)} onClick={this.onClickHideAll}></div>
            </div>
        )
    }
}