import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";
import Select from 'react-select';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import CustomLoadingCellRenderer from "./customLoadingCellRenderer";
import RemovePickDate from "./removePickDate";
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD,getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY } from '../common/utils'; 
import 'react-vertical-timeline-component/style.min.css';
import DrawMap from '../common/drawmap';
import $ from 'jquery';
// import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
var redirectURL = require('../redirectURL');
var moment = require('moment');
var infoBox = require('../common/google-infowindow');
var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);
var googleAnalytics = require("../common/googleanalytics");

var containerslist = [];
var uncheckcontainerslist = [];

export default class IcdGarhiContainerPlan extends Component {

	constructor(props){
		super(props);
		this.state={
			pageTitle: "Container Planning - ICD Garhi",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			uploadDivWidth:'0%',
			sliderTranslate:'',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			overly:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
            basicTitle:'',
			basicType:"default",
			modules: AllModules,
			sidebarSubHeader:'',
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true

      	    },
      	    rowData: [],
      	    rowPlannedData:[],
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
			  overlayNoRowsTemplate: "",
			  frameworkComponents: {
				
				customLoadingCellRenderer: CustomLoadingCellRenderer,
				RemovePickDate : RemovePickDate
			},

      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,
				
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
			rowClassRules: {
				"highlitRow": "data.transit_delay == 1",
				"green":"data.status >= 3"

            },
            rowSelection: "multiple",
            autoGroupColumnDef: {
                headerName: "Container No",
                field: "container_no",
                width: 200,
                cellRenderer: "agGroupCellRenderer",
                cellRendererParams: { checkbox: true }
              },
            containerslist:[],
            errorMessage:'',
            successMessage:'',
			pickup_plan_date:'',
			showAppBtn:"show-n",
			components: { datePicker: getDatePicker() },			  
			usergridstate:[],
			screenurl:"",
			screentitle:"",
			
		}
		this.onClickRemovePickupdate = this.onClickRemovePickupdate.bind(this)
	}
	componentDidMount = async() => {
		//console.log("Props ", this.props.match)
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}

		redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname})
		.then( async (resp) => {
			await this.setState({
				usergridstate:resp.data
			});
			await this.restoreGridStates();
		})
		var urlpath;
        var dpt='';
	   
		//var d = new Date(moment(event).format("YYYY-MM-DD"));
		var c = new Date();
		var e = new Date(moment(c).format("YYYY-MM-DD"));
		var tomorrow = moment.parseZone(c).add(1,"days").format('YYYY-MM-DD');
        //console.log("d ", d.getTime())
		//console.log("tomorrow ", tomorrow)
		//var ddt = moment.parseZone(d).format("x");
		var edt = moment.parseZone(e).format("x");
		//console.log("ddt ", ddt)
		this.setState({
			loadshow:'show-m',
			rowData:''
		});
		
            var startdate = e.getFullYear()+"-"+(e.getMonth() +1)+"-"+(e.getDate()<10?("0"+e.getDate()):e.getDate());
            //console.log("d ", getDDMMYYYY(startdate))
            this.setState({
                pickup_plan_date:getYYYYMMDD(startdate)
			});
			
			
			//console.log(reqparams)

			
			var alloycontainers = []
			await redirectURL.post("/importscoils/getAlloybars")
			.then((res) => {
				if(res.data.length > 0)
				{
					res.data.map((item) => {
						var pickdate = item.dispatch_date;
						//console.log(item.container_no,item.dispatch_date);
						if(item.dispatch_date == "" || item.dispatch_date == undefined || item.dispatch_date == null)
						{
							//console.log("pickdate ", item.container_no+" === "+pickdate)
							alloycontainers.push(item.container_no)
						}		
					})
				}
			})
			.catch(function(e){
				alloycontainers = []
			})

			//console.log("alloycontainers ", alloycontainers.length)
			//icdgarhialloyplandatabydate
			var reqparams={
				pickupdate:getYYYYMMDD(startdate),
				alloycontainers:alloycontainers
			}
			var urlpath = '/consignments/icdgarhialloyplandatabydate';
			await redirectURL.post(urlpath,reqparams)
			.then(async (response) => {
				//console.log("response ",response.data)
				var planarr=[];
				var plannedarr = [];
				if(response.data.length > 0)
				{
					response.data.map((item) => {
						var pickdate = getYYYYMMDD(item.pickup_plan_date);
						//console.log("once pickdate ", pickdate)
						/*New Code by Venkatesh*/
						
						if( pickdate != '' && pickdate != '0000-00-00' && 
							pickdate != undefined  && pickdate != 'undefined')
						{
							//console.log("pickdate ", item.container_no+" === "+pickdate)
							plannedarr.push(item)
						}
						else{
							//console.log("Step2 ",item)
							planarr.push(item);
						}
					})
				}

				//console.log("plannedarr init ", plannedarr)
				var reqparams={
					pickupdate:getYYYYMMDD(startdate)
				}
				var urlpath = '/consignments/icdgarhiplandatabydate';
				
				await redirectURL.post(urlpath,reqparams)
				.then((response) => {
					var records = response.data;
					//console.log("records ", records)
					let newArray = []; 
					
					// Declare an empty object 
					let uniqueObject = {}; 
					var objTitle;
					// Loop for the array elements 
					for (let i in records) { 
			
						// Extract the title 
						objTitle = records[i]['container_no']; 
			
						// Use the title as the index 
						uniqueObject[objTitle] = records[i]; 
					} 
					
					// Loop to push unique object into array 
					for (var a in uniqueObject) { 
						newArray.push(uniqueObject[a]); 
					} 
						
					//console.log("newArray ", newArray)
						
					//console.log("records", records);
					
					newArray.map((item) => {
						
						var pickdate = item.pickup_plan_date;
						//console.log("pickdate ", pickdate)
						/*New Code by Venkatesh*/
						
						if( pickdate != '' && pickdate != '0000-00-00' && pickdate != undefined  && pickdate != 'undefined' && typeof item.dispatch_date == 'undefined')
						{
							//console.log("pickdate ", item.container_no+" === "+pickdate)
							plannedarr.push(item)
						}
						else{
							//console.log("Step2 ",item)
							planarr.push(item);
						}
						
					});
					let uniqueObject1 = {}; 
					var objTitle1;
					var newArray1 = []
					// Loop for the array elements 
					for (let i1 in planarr) { 
			
						// Extract the title 
						objTitle1 = planarr[i1]['container_no']; 
			
						// Use the title as the index 
						uniqueObject1[objTitle1] = planarr[i1]; 
					} 
					
					// Loop to push unique object into array 
					for (var a1 in uniqueObject1) { 
						newArray1.push(uniqueObject1[a1]); 
					} 
					//console.log("plannedarr ", plannedarr)
						
					this.setState({
						rowData:newArray1,
						rowPlannedData:plannedarr,
						loadshow:'show-n'
					});
					
					})
				.catch(function (error) {
					console.log(error);
				});
			
				
			})
			.catch(function(e){

			})
			
	};
	
	
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	
	   //    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	   //console.log("Deptcode " ,this.state.deptcode)
	//    this.gridApi.forEachLeafNode( (node) => {
	// 		if (node.data.pickup_plan_date != '' && node.data.pickup_plan_date != '0000-00-00' && node.data.pickup_plan_date != undefined) {
	// 			node.setSelected(true);
	// 		}
	// 	});
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);

		   this.gridApi.forEachLeafNode( (node) => {
			   //console.log(node)
				if (node.data.pickup_plan_date != '' && node.data.pickup_plan_date != undefined) {
					node.setSelected(true);
				}
			});
		   
	}

	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:''
		});
		
	}
	closeAlert = async () => {
        await this.setState({
            show: false,
            pickup_plan_date:''
		});
		await window.location.reload();
    }
   
    handlerStartDateTime = (event, currentDate, selectedDate) => {
		//console.log("Select Date", event)
    	var d = new Date(moment(event).format("YYYY-MM-DD"));
		var c = new Date();
		var e = new Date(moment(c).format("YYYY-MM-DD"));
		var tomorrow = moment.parseZone(c).add(1,"days").format('YYYY-MM-DD');
        //console.log("d ", d.getTime())
		//console.log("tomorrow ", tomorrow)
		var ddt = moment.parseZone(d).format("x");
		var edt = moment.parseZone(e).format("x");
		//console.log("ddt ", ddt)
		this.setState({
			loadshow:'show-m',
			rowData:''
		});
		//console.log("ddt >= edt ", ddt +" >= "+ edt)
        //if(d.getTime() >= e.getTime())
		if(ddt >= edt)
		{
            var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?("0"+d.getDate()):d.getDate());
            //console.log("d ", getDDMMYYYY(startdate))
            this.setState({
                pickup_plan_date:getYYYYMMDD(startdate)
			});
			
			var urlpath = '/consignments/icdgarhiplandatabydate';
			var reqparams={
				pickupdate:getYYYYMMDD(startdate)
			}
			//console.log(reqparams)
			redirectURL.post(urlpath,reqparams)
			  .then((response) => {
				var records = response.data;
				let newArray = []; 
              
				// Declare an empty object 
				let uniqueObject = {}; 
				var objTitle;
				// Loop for the array elements 
				for (let i in records) { 
		
					// Extract the title 
					objTitle = records[i]['bill_of_lading']; 
		
					// Use the title as the index 
					uniqueObject[objTitle] = records[i]; 
				} 
				
				// Loop to push unique object into array 
				for (var a in uniqueObject) { 
					newArray.push(uniqueObject[a]); 
				} 
					
				//console.log("newArray ", newArray)
					
				//console.log("records", records);
				var planarr=[];
				var plannedarr = [];
				newArray.map((item) => {
					
					var pickdate = item.pickup_plan_date;
					// if(d.getTime() > e.getTime())
					// {
					// 	x											
					// 	if( pickdate != '' && pickdate != '0000-00-00' && pickdate != undefined && item.dispatch_date == undefined)
					// 	{
					// 		plannedarr.push(item)
					// 	}
					// 	else{
					// 		//console.log("Step2 ",item)
					// 		planarr.push(item);
					// 	}
					// }
					// else{
					// 	console.log("Enter ELse ",item)
					// 	if(item.dispatch_date == undefined)
					// 	{
					// 		//console.log("Step3 ",item)
					// 		plannedarr.push(item)
					// 	}
					// 	else{
					// 		//console.log("Enter Else Else ",item)											
					// 		if( pickdate != '' && pickdate != '0000-00-00' && pickdate != undefined && item.dispatch_date == '')
					// 		{
					// 			//console.log("Step4 ",item)
					// 			var p = new Date(moment(pickdate).format("YYYY-MM-DD"));	
					// 			if(d.getTime() >= p.getTime())
					// 			{
					// 				//console.log("Enter Else Else IF ",item)
					// 				//console.log("Step5 ",item)
					// 				planarr.push(item);
					// 			}
					// 		}
					// 	}
					// }


					/*New Code by Venkatesh*/
					
					if( pickdate != '' && pickdate != '0000-00-00' && pickdate != undefined && typeof item.dispatch_date == 'undefined')
					{
						plannedarr.push(item)
					}
					else{
						//console.log("Step2 ",item)
						planarr.push(item);
					}
					
				});
				
				this.setState({
					rowData:planarr,
					rowPlannedData:plannedarr,
					loadshow:'show-n'
				});
				
			  })
			.catch(function (error) {
				console.log(error);
			});

        }
        else
        {
            this.setState({
                show:true,
                basicTitle:"Previous date is not allowed",
                basicType:"danger",
				pickup_plan_date:'',
				loadshow:'show-n',
                rowData:'',
				showAppBtn:'show-n'
            });
		}
		
    }
    onRowSelected(event) {
        //console.log("row " + event.node.data.container_no+ " selected = " + event.node.selected);
       // var items=[];
	   var rowCount = event.api.getSelectedNodes();
	  
	   containerslist=[];
	   if(event.node.selected == false)
	   {
		uncheckcontainerslist.push(event.node.data.container_no)
	   }
       //console.log("rowCount ", rowCount)
       
	   
        rowCount.map((item) => {
            containerslist.push(item.data.container_no)
        })
			
		//console.log("checkcontainerslist ", this.state.containerslist)
		//console.log("uncheckcontainerslist ", uncheckcontainerslist)
		if(containerslist.length > 0)
		{
			this.setState({
				showAppBtn:'show-m'
			});
		}
        else{
			if(uncheckcontainerslist.length > 0)
			{
				this.setState({
					showAppBtn:'show-m'
				});
			}
			else
			{
				this.setState({
					showAppBtn:'show-n'
				});
			}
		}
      }
   
    formHandler = (event) => {
		event.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedApplyChanges,
			}
			googleAnalytics.logEvent(eventOptions);
		}
        this.setState({
            loadshow:'show-m'
        });
        //var formdata = new FormData(event.target);
        if(this.state.pickup_plan_date != '' && this.state.pickup_plan_date != undefined)
        {
			var pickdate=this.state.pickup_plan_date+" 23:59:00";
			var d = new Date(moment(pickdate).format("YYYY-MM-DD"));
			var ed = new Date();
			var c = new Date(moment(ed).format("YYYY-MM-DD"));
			//console.log("pickdate D", d.getTime())
			//console.log("pickdate C", c.getTime())
            if(d.getTime() >= c.getTime())
            {
                if(containerslist.length > 0 || uncheckcontainerslist.length > 0)
                {
					var pplant = this.state.pickup_plan_date;
					var pickplandate = pplant.split("/");
					var pdate =  pickplandate[0]+"-"+pickplandate[1]+"-"+pickplandate[2]
                    var reqparams= {
						containerslist:containerslist,
						unchecklist:uncheckcontainerslist,
                        pickup_plan_date:pdate
                    }
                    //console.log("formdata ", formdata)
                    var urlpath = '/container/updateICDDispacth';
                
                    redirectURL.post(urlpath,reqparams)
                    .then((response) => {
                        var records = JSON.parse(JSON.stringify(response.data));
                        console.log("records", records);
                       
                        this.setState({
							loadshow:'show-n',
							show:true,
							basicTitle:"Successfull apply changes",
							basicType:"success",
										
                        })
                        
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                }
                else
                {
                    this.setState({
                        show:true,
                        basicTitle:"Please select atleast one container from grid",
                        basicType:"danger",
						showAppBtn:'show-n'
                        
                    });
                }
            }
            else
            {
                this.setState({
                    show:true,
                    basicTitle:"Previous date is not allowed",
                    basicType:"danger",
                    pickup_plan_date:'',
					showAppBtn:'show-n'
                    
                });
            }
            /*
            
            */
        }
        else
        {
            this.setState({
                show:true,
                basicTitle:"Previous date is not allowed",
                basicType:"danger",
                pickup_plan_date:'',
				showAppBtn:'show-n'
                
            });
        }
    }
	onCellUpdateData = (params) => {
		console.log("Params ", params)
		if(params.colDef.field == "pickup_plan_date")
		{
			//console.log("params ", params)
			//console.log("params field", params.colDef.field);
			var pickup_plan_date = '';
			if(params.value != '')
			{
				pickup_plan_date = params.value;
			}
			var pdate = moment.parseZone(new Date(pickup_plan_date)).add(2, 'days').utcOffset("+05:30")._d;
			var upddata = {
				updateData:params.data,
				pickeupdate:pdate
			}
			redirectURL.post("/imports/updateicdcontainerdate", upddata)
			.then((response) => {
				console.log("Resp ",response.data)
			});
		}
		else{
			
		}
		
	}

	onClickRemovePickupdate(params){
		console.log("Prams ", params)
		var rowPlannedData = this.state.rowPlannedData;
		var upddata = {
			container_no:params.value
		}
		redirectURL.post("/imports/removeicdcontainerdate", upddata)
		.then((response) => {
			var ar= []
			rowPlannedData.map((item) => {
				if(item.container_no == params.value)
				{

				}
				else{
					ar.push(item)
				}
			})
			this.setState({
				rowPlannedData:ar
			})
		});
		
	}

	sendContainerEMails(){
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Send EMails",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		var c = new Date();
		var e = new Date(moment(c).format("YYYY-MM-DD"));
		var trrow = moment.parseZone(c).add(1,"days").format('YYYY-MM-DD');
		var today = moment.parseZone(e).format('x');
        //console.log("d ", d.getTime())
		var tomorrow = moment.parseZone(trrow).format("x");
		var edt = moment.parseZone(e).format("x");
		var records = this.state.rowPlannedData;
		//console.log("records ", records)
		var contaners = []
		if(records.length > 0)
		{
			records.map((item) => {
				var pdate = item.pickup_plan_date;
				var pickdate = pdate.replace(/\//g,"-")
				var pickupdate = moment.parseZone(pickdate).format("x");
				
				// if(pickupdate == today || pickupdate == tomorrow){
					contaners.push(item.container_no)
				// }
				
			})
			//console.log("contaners ",contaners)
			redirectURL.post("/imports/sendContainerEmails", {
				containers:contaners
			})
			.then((response) => {
				//console.log("containers ", JSON.parse(response.data.status))
				var res = JSON.parse(response.data.status)
				if(res.status == 'Success')
				{

					this.setState({
						show:true,
						basicTitle:res.message,
						basicType:"success"
					})
				}
			})
			.catch(function(e){
				console.log("Error ",e)
			})
		}
		//console.log("tomorrow ", trrow)
		
	}



	onClickSaveGridState(){
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Save Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		
		let screenpage = 'icd garhi plan';


		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates()
	{
		///console.log("this.state.usergridstate ", this.state.usergridstate)
		try{
			if(this.state.usergridstate.length > 0)
			{
				var windowstates = this.state.usergridstate;
				console.log("windowstates[0].gridcolumns ",windowstates[0].gridcolumns)
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
			
			}
		}
		catch(e){}
	}

	resetState = () => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Reset Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

	};



	render(){
		//console.log("Rendere ",this.state.deptcode)
		const columnwithDefs = [
            	
				{
					headerName: "Date of Arrival",
					field: "grfl_arrival_date",
					width: 130,
					filter: true,
					resizable: true,
					valueGetter:function(params){                     
						return getHyphenDDMMMYYYY(params.data.grfl_arrival_date)
					},
					//filter: "agDateColumnFilter",
					comparator: dateComparator1,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			else{
					// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			// console.log(cellValue,filterValue);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },
                    headerCheckboxSelection: true,
                    headerCheckboxSelectionFilteredOnly: true,
                    checkboxSelection: true

				},
				{
					headerName: "Container no",
					field: "container_no",
					width: 140,
					filter: true,
                    resizable: true
				},
				{
					headerName: "Container Size",
					field: "container_size",
					width: 100,
					filter: true,
                    resizable: true,                   

				},
				{
					headerName: "BOE No",
					field: "boe_no",
					width: 100,
					filter: true,
                    resizable: true,
                    

				},
				{
					headerName: "BOE Date",
					field: "boe_date",
					width: 140,
					filter: true,
                    resizable: true,
                    valueGetter:function(params){                      
                        return getHyphenDDMMMYYYY(params.data.boe_date)
					},
					//filter: "agDateColumnFilter",
					comparator: dateComparator1,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			else{
					// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			// console.log(cellValue,filterValue);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },

				},
				{
					headerName: "Cargo Description",
					field: "cargo_description",
					width: 200,
					filter: true,
                    resizable: true,
                    

				},
				{
					headerName: "S/Line",
					field: "shipping_line",
					width: 200,
					filter: true,
                    resizable: true,
                    

				},
				{
					headerName: "BL No",
					field: "bill_of_lading",
					width: 150,
					filter: true,
                    resizable: true,
                    

				},
				{
					headerName: "Plant Name",
					field: "plant_name",
					width: 150,
					filter: true,
                    resizable: true,
                    

				},
				{
					headerName: "DO Validty",
					field: "do_validity",
					width: 140,
					filter: true,
                    resizable: true,
                    valueGetter:function(params){                     
                        return getHyphenDDMMMYYYY(params.data.do_validity)
					},
					//filter: "agDateColumnFilter",
					comparator: dateComparator1,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			else{
					// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			// console.log(cellValue,filterValue);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// }

				},
				{
					headerName: "Customs Clearing Date",
					field: "coc_date",
					width: 140,
					filter: true,
                    resizable: true,
                    valueGetter:function(params){                      
                        return getHyphenDDMMMYYYY(params.data.coc_date)
					},
					//filter: "agDateColumnFilter",
					comparator: dateComparator1,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			else{
					// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			// console.log(cellValue,filterValue);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },

				},
				{
					headerName: "Handover Date",
					field: "handover_date",
					width: 140,
					filter: true,
                    resizable: true,
                    valueGetter:function(params){                      
                        return getHyphenDDMMMYYYY(params.data.handover_date)
					},
					//filter: "agDateColumnFilter",
						comparator: dateComparator1,
						// filterParams: {
						// 	browserDatePicker: true,
						// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
						// 	// provide comparator function
						// 	comparator: function(filteredDate,cellValue,secondDate) {
						// 		cellValue = cellValue.replace(/\//g,"-")
						// 		if(cellValue != "" && cellValue != " "){
						// 			cellValue = cellValue.split(" ")[0].split("-");
						// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
						// 			var cellDate = new Date(cellValue);
						// 			if(filteredDate.getMonth() < 10){
						// 				var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			else{
						// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			var filteredDate = new Date(filterValue);
						// 			cellDate = cellDate.getTime();
						// 			filteredDate = filteredDate.getTime();
						// 			// console.log(cellDate,filteredDate);
						// 			// console.log(cellValue,filterValue);
						// 			if(cellDate === filteredDate)
						// 			{
						// 				return 0;
						// 			}
						// 			if(cellDate < filteredDate){
						// 				return -1;
						// 			}
						// 			if(cellDate > filteredDate)
						// 			{
						// 				return 1;
						// 			}
						// 		}
						// 	}
						// },

				}
				
				
			
			];


		
			const columnwithPlanDefs = [
                {
					headerName: "Container no",
					field: "container_no",
					width: 110,
					filter: true,
                    resizable: true,
                    // headerCheckboxSelection: true,
                    // headerCheckboxSelectionFilteredOnly: true,
                    // checkboxSelection: true
				},
				{
					headerName: "Planned Dispatch Date",
					field: "pickup_plan_date",
					width: 150,
					filter: true,
					resizable: true,
					editable:false,
					valueGetter:function(params){                     
                        return getHyphenDDMMMYYYY(params.data.pickup_plan_date)
					},
					//filter: "agDateColumnFilter",
					cellEditor: DateEditor
					
				},
				{
					headerName: "",
					field: "container_no",
					width: 80,
					cellRendererSelector:function(params){
						var rendComponent = {
							component: 'RemovePickDate'
						};
						return rendComponent;
					}
					
				}
			];	

		const dStyles={
			width:this.props.width,
            height:'500px',
            marginLeft:'0px !important'

	    }
        
        const searatesList=[
            {value:"Pipavav", label:"Pipava"},
            {value:"Mundra", label:"Mundra"}
        ]
		return(
			<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
				<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span> {this.state.pageTitle} </span>
										
									   <span className="layoutbtns float-right">
										<button className="float-right btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
											<i className="icofont icofont-save"></i> Save Grid Layout
										</button>
										<button className="float-right btn white btn-info" style={{marginRight:"10px"}} onClick={this.resetState.bind(this)}>
											<i className="icofont icofont-refresh"></i> Reset Default Layout
										</button>
									</span>
									<button type="button" onClick={this.sendContainerEMails.bind(this)} style={{marginRight:"10px"}} className={"btn btn-warning float-right "}>Send Email</button>
									
								</h5>
				   			</div>				   			
		            		<div className="card-body">
                                <div className="row col-xl-12 col-lg-12">
                                    {(this.state.errorMessage != '')?
                                    <div className="row col-xl-12 xol-lg-12 alert alert-danger">
                                        {this.state.errorMessage}
                                    </div>
                                    
                                    :""}
									{(this.state.successMessage != '')?
                                    <div className="row col-xl-12 xol-lg-12 alert alert-success">
                                        {this.state.successMessage}
                                    </div>
                                    
                                    :""}
                                    <form className="row theme-form col-xl-12 col-lg-12" onSubmit={this.formHandler}>
                                        <div className="col-xl-3 col-lg-3 form-group">
											<label>Select Date</label>
                                        <Datetime 
                                        inputProps={{ placeholder: 'Pickup Plan Date', name: 'pickup_plan_date', autoComplete:'off', required:true }} 
                                        dateFormat="DD-MM-YYYY" 
                                        timeFormat={false} 
                                        closeOnSelect={true}
                                        onChange={this.handlerStartDateTime.bind(this)}
                                        value={this.state.pickup_plan_date} 
                                        />
                                       
                                        </div>

                                        <div className={"col-xl-3 col-lg-3 form-group "}>
                                            <lable>&nbsp;</lable><button type="submit" className="btn btn-success mt-28p">Apply Changes</button>
                                        </div>
										
                                    </form>
                                </div>
                                <div className="row col-xl-12 col-lg-12">
								    
									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>
                                   {
									(this.state.rowData.length > 0)?
										<div className="col-xl-8 col-lg-8">										
											<h5>Due for Planning</h5>
											<div id="myGrid" style={{ height: "500px",width: "100%"}}  className="ag-theme-balham">
												<AgGridReact
													modules={this.state.modules}
													columnDefs={columnwithDefs}
													defaultColDef={this.state.defaultColDef}
													rowData={this.state.rowData}
													enableCharts={false}
													enableRangeSelection={true}
													autoGroupColumnDef={this.state.autoGroupColumnDef}
													onGridReady={this.onGridReady}
													onGridState={this.onGridState}
													statusBar={this.state.statusBar}
													//sideBar={this.state.sideBar}
													paginationPageSize={this.state.paginationPageSize}
													pagination={true}
                                                    rowSelection={this.state.rowSelection}
                                                    onRowSelected={this.onRowSelected.bind(this)}
                                                    suppressRowClickSelection={true}
													//floatingFilter={true}
													//onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
												    //onCellClicked={this.popmarker}
												/>
											</div>
                                           
										</div>
										: ""
									}

								{
									(this.state.rowPlannedData.length > 0)?
										<div className="col-xl-4 col-lg-4">										
											<h5>Planned Containers</h5>
											<div id="myGrid" style={{ height: "500px",width: "100%"}}  className="ag-theme-balham">
												<AgGridReact
													modules={this.state.modules}
													columnDefs={columnwithPlanDefs}
													defaultColDef={this.state.defaultColDef}
													rowData={this.state.rowPlannedData}
													enableCharts={false}
													enableRangeSelection={true}
													autoGroupColumnDef={this.state.autoGroupColumnDef}
													onGridReady={this.onGridReady}
													onGridState={this.onGridState}
													statusBar={this.state.statusBar}
													paginationPageSize={this.state.paginationPageSize}
													pagination={true}
                                                    rowSelection={this.state.rowSelection}
                                                    onRowSelected={this.onRowSelected.bind(this)}
                                                    suppressRowClickSelection={true}
													components={this.state.components}
													frameworkComponents={this.state.frameworkComponents}
													gridOptions={{
														context: { componentParent: this }
													}}
													onCellEditingStopped={this.onCellUpdateData.bind(this)}
												/>
											</div>
                                           
										</div>
										: ""
									}
                                </div>
				   			</div>
				   		</div>	
				   	</div>
					   
				 </div>
				 
				
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
              	
		);
	}
}

function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}                 
function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = parseInt(hours);
	var minutes = (num-(rhours*3600)) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}                              

function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function distance(lat1, lon1, lat2, lon2, unit) {
	if ((lat1 == lat2) && (lon1 == lon2)) {
		return 0;
	}
	else {
		var radlat1 = Math.PI * lat1/180;
		var radlat2 = Math.PI * lat2/180;
		var theta = lon1-lon2;
		var radtheta = Math.PI * theta/180;
		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) {
			dist = 1;
		}
		dist = Math.acos(dist);
		dist = dist * 180/Math.PI;
		dist = dist * 60 * 1.1515;
		if (unit=="K") { dist = dist * 1.609344 }
		if (unit=="N") { dist = dist * 0.8684 }
		return dist;
	}
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }

function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}
function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getDatePicker() {
	function Datepicker() {}
	Datepicker.prototype.init = function(params) {
	  this.eInput = document.createElement("input");
	  //this.eInput = this.eInput.setAttribute("class","datepicker");
	  this.eInput.value = params.value;
	  console.log(this.eInput)
	  window.$(".datepicker").datepicker({ dateFormat: "dd/mm/yy" });
	};
	Datepicker.prototype.getGui = function() {
	  return this.eInput;
	};
	Datepicker.prototype.afterGuiAttached = function() {
	  this.eInput.focus();
	  this.eInput.select();
	};
	Datepicker.prototype.getValue = function() {
	  return this.eInput.value;
	};
	Datepicker.prototype.destroy = function() {};
	Datepicker.prototype.isPopup = function() {
	  return false;
	};
	return Datepicker;
  }


function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datepicker({
        dateFormat: "dd-MM-yy",
        changeMonth: true,
		changeYear: true,
		minDate:1
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};