import React, { Component } from 'react';
import CountUp from 'react-countup';
import axios from 'axios';

class ConsignmentsTodayTopCounter extends Component {
    constructor(props){
    	super(props);
    	console.log("Props = ", props);
    	this.state={
    		records:[],
    		activeConsignmentsCount: 0,
			gateInConsignmentsCount: 0,
			inTransitConsignmentsCount: 0,
			atDealerConsignmentsCount: 0,
    	}
    
    }
    componentDidMount(){
    	//console.log("Box ",localStorage.getItem('token'));
    	//console.log(this.props);
		const layoutdata={
			token:localStorage.getItem('token'),
		}
    		//console.log("Trucks", this.props.tottrucks);
    	//	//console.log("trucklocations", this.props.truckgps);
			//console.log("notrans", this.props.notruckgps);
			
		 this.setState({
			activeConsignmentsCount:this.props.activeConsignmentsCount,
			gateInConsignmentsCount:this.props.gateInConsignmentsCount,
			inTransitConsignmentsCount:this.props.inTransitConsignmentsCount,
			atDealerConsignmentsCount:this.props.atDealerConsignmentsCount,
  			   
  		   });
    }
    renderOLD(){
        return (
            <div className="row">
                <div className="col-xl-4 col-lg-4 col-sm-4">
                    <div className="card">
                        <div className="card-body boxbgblue">
                			<div className="brd brd-txt1"></div>
                            <div className="stat-widget-dashboard">
                                <div className="media">
                                    <div className="media-body text-left">
                                        <h4 className="mt-0 fnt2"><CountUp className="font-primary" end={this.props.activeConsignmentsCount} /></h4>
                                    </div>
                                </div>
                                    <div className="col-lg-12 col-sm-12 n-p-0 f15">Total Active Consignments</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-2 col-lg-4 col-sm-4">
                    <div className="card">
                        <div className="card-body boxbgblue2">
	                        <div className="brd brd-txt2"></div>
	                        <div className="stat-widget-dashboard">
                                <div className="media">
                                	<div className="media-body text-left">
                                        <h4 className="mt-0 fnt2"><CountUp className="font-secondary" end={this.props.gateInConsignmentsCount} /></h4>
                                    </div>
                                </div>
                                    <div className="col-lg-12 col-sm-12 n-p-0 f15">Gate in</div>
                            </div>
                        </div>
                    </div>
                </div>
            
                {/* <div className="col-xl-2 col-lg-4 col-sm-4">
                    <div className="card">
                        <div className="card-body boxbgblue3">
                        	<div className="brd brd-txt4"></div>
                            <div className="stat-widget-dashboard">
                                <div className="media">
                                    <div className="media-body text-left">
                                        <h4 className="mt-0 fnt2">
											<CountUp className="font-info" end={this.props.tottrucks-this.props.truckgps} />
										</h4>
                                    </div>
                                </div>
                                    <div className="col-lg-12 col-sm-12 n-p-0 f15">Gate out</div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="col-xl-2 col-lg-4 col-sm-4">
	                <div className="card">
	                    <div className="card-body boxbgblue4">
	                    	<div className="brd brd-txt3"></div>
	                        <div className="stat-widget-dashboard">
	                            <div className="media">
	                                <div className="media-body text-left">
	                                    <h4 className="mt-0 fnt2 counter font-success">
											<CountUp className="font-success" end={this.props.inTransitConsignmentsCount} />
										</h4>
	                                </div>
	                            </div>
		                               <div className="col-lg-12 col-sm-12 n-p-0 f15">In Transit</div>
	                        </div>
	                    </div>
	                </div>
	            </div>
                <div className="col-xl-4 col-lg-4 col-sm-4">
	                <div className="card">
	                    <div className="card-body boxbgblue5">
	                    	<div className="brd brd-txt5"></div>
	                        <div className="stat-widget-dashboard">
	                            <div className="media">
	                                <div className="media-body text-left">
	                                    <h4 className="mt-0 fnt2 counter font-color-yellow">
											<CountUp className="font-color-yellow" end={this.props.atDealerConsignmentsCount} />
										</h4>
	                                </div>
	                            </div>
	                               <div className="col-lg-12 col-sm-12 n-p-0 f15">At Dealer</div>
	                        </div>
	                    </div>
	                </div>
	            </div>
                
            </div>
        );
	}
	render(){
        return (
            <div className="row">

            <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                <div className="card">
                    <div className="card-body">
                        <div className="crm-numbers pb-0">
                            <div className="row">
                                <div className="col cirlce-d">
                                    <span className="f13"><i className="icofont icofont-truck-loaded f24 txt-primary-dark"></i><br /> Active Consignments</span>
                                    <h4 className="txt-primary f50"><span className="counter"><CountUp end={this.props.activeConsignmentsCount}/></span></h4>
                                </div>
                                <div className="col">
                                    <span className="f13"><i className="icofont icofont-truck f24 txt-secondary-dark"></i><br /> Gate in</span>
                                    <h4 className="txt-secondary f50"><span className="counter"><CountUp end={this.props.gateInConsignmentsCount}/></span></h4>
                                </div>
                                <div className="col">
                                    <span className="f13"><i className="icofont icofont-clock-time f22 txt-info-dark"></i><br /> In Transit</span>
                                    <h4 className="txt-info f50"><span className="counter"><CountUp end={this.props.inTransitConsignmentsCount}/></span></h4>
                                </div>
                                <div className="col">
                                    <span className="f13"><i className="icofont icofont-social-google-map f24 txt-warning-dark"></i><br /> At Dealer</span>
                                    <h4 className="txt-warning f50"><span className="counter"><CountUp end={this.props.atDealerConsignmentsCount}/></span></h4>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
           
            </div>
        );
    }
}

export default ConsignmentsTodayTopCounter