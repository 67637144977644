import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import $ from "jquery";
import _ from "lodash";
import MarkerClusterer from "@google/markerclusterer";
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import CountUp from 'react-countup';
import Modal from 'react-responsive-modal';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";


import { getDDMMYYYY, 
	getDDMMYYYYHHMMSS, 
	getYYYYMMDD, 
	getYYYYMMDDHHMMSS, 
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD, 
	getHyphenDDMMYYYYHHMMSS, 
	getHyphenDDMMYYYY,
	getDDMMMYYYYHHMMDefault } from '../common/utils'; 
var redirectURL = require('../redirectURL');

var infoBox = require('../common/google-infowindow');
var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);
var googleAnalytics = require("../common/googleanalytics");

var marker;
var markers = [];
var circle;
var selectlat;
var selectlng
var allcords=[];
var map;
var rad = function(x) {
	return x * Math.PI / 180;
  };
var rlatitude;
var rlongitude;

var fencingLength=0;
var geoFencingArea;
export default class MllConsignmentNearByTrucks extends Component {
 
    constructor(props) {
		super(props);
		console.log(this.props.match.url)
		this.displayData = [];
        this.state = {
			pageTitle: "",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			showform:'show-m',
			loadshow:'show-n',
			showrefresh:'show-n',
			sliderTranslate:'',
			showdata:this.displayData,
			radius:'5000',
			rlatitude:'',
			rlongitude:'',
			latitude:'',
			longitude:'',
			defaultradius:0,
			coordinates:[],
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			contentString:"testing",
			mwidth:"col-xl-12 col-lg-12",
			alldepts:[],
			transportervalue:'ALL',
			alltransporters:[],
			truckstatus:'0',
			kpicode:'0',
			deptcode:'',
			truckNoList:[],
			alltrucks:[],
			truckno:'',
			showTrucksList : "show-n",
			filteredData : [],
			triptype :{"value":1,"label":"ALL"},
			plantLocations : [],
			totalTrucksCount : 0,
			onTripCounter : 0,
            usermanualmodal:false,
            defaultColDef: {
				sortable: true,
				filter: true,
				//editable: true,
				resizable: true
			},
			
		};
		this.popmarker=this.popmarker.bind(this);
		this.goBack = this.goBack.bind(this);
		
	}
	/*Alert Popups*/
	closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onRecieveInput = (value) => {
        this.setState({
            alert: (
                <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
                    You wrote: {value}
                </SweetAlert>
            )
        });
    }
	hideAlert = () => {
        this.setState({
            alert: null
        });
	}
	
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
		
    componentDidMount(){
		//console.log(this.props.match.)
		this.logPageView(); // Log page view to GA
	
		this.setState({
			loadshow:'show-m',
			coordinates:[],
			rowData:[]
		});
		var dept;
		var hText = 'All';
		if(this.props.match.url == '/nearbyprttrucks')
		{
			dept='LOG-PRT';
			hText = 'Spare Parts';
		}
		if(this.props.match.url == "/mllnearbysndtrucks")
		{
			dept='SNDG';
			hText = '';
		}
		if(this.props.match.url == "/nearbysndtrucks")
		{
			dept='SNDG';
			hText = '( Sales and Dispatch )';
		}
		if(this.props.match.url == "/nearbysctrucks")
		{
			dept='LOG-SC';
			hText = '( Supply Chain )';
		}
		if(this.props.match.url == "/nearbytnptrucks")
		{
			dept='LOG-TNP';
			hText = '( Train and Production )';
		}

		let pageTitle = "Trucks Locations  " + hText;
		this.setState({
			deptcode:dept,
			pageTitle: pageTitle
		});

		const layoutdata={
			token:localStorage.getItem('token'),
			deptcode:dept
		}
		redirectURL.post('/masters/transportersbydept', layoutdata,{
			headers:{
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
			}
		})
  		  .then((response) => {
  			//console.log(response);
  			 var records = JSON.parse(JSON.stringify(response)).data;
  			 var optss={};
  			records.map((item, key) => {
  			   var optValue = item.dept_code;
  			 optss[optValue] = item.dept_name
  			});
  			this.setState({
				alltransporters:records
  			});
  		  })
  		  .catch(function (error) {
  		    console.log(error);
  		  });


		var formdata = {
			radius:3000,
			latitude:"20.73568784876339",
			longitude:"79.38999444931353",
			dept_code:dept,
			transporter_code:this.state.transportervalue,
			status:this.state.truckstatus,
			trip_type : 1
		}
		rlatitude = "20.73568784876339";
		rlongitude = "79.38999444931353";
		//http://autometrics.in:5000/getNearByTrucks
		redirectURL.post('/vicinity/getRadiusCoordinates',formdata,{
			headers:{
				'Content-Type':'application/json',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'PUT, GET, POST, DELETE, OPTIONS',
				'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept, Authorization"

			} 
		})
		.then(
			(response) =>{
			console.log(response)
			console.log("response", JSON.parse(response.data.body));
			// console.log(response.data.body)
			var markers=JSON.parse(JSON.parse(response.data.body).trucks_data);
			console.log(markers);
			console.log(JSON.parse(response.data.body).gps_trucks);
			if(markers.length > 0)
			{
				this.setState({
					defaultradius:formdata.radius,
					coordinates:markers,
					totalTrucksCount : markers.length,
					mwidth:"col-xl-12 col-lg-12",
					rowData:markers,
					radius:5000,
					loadshow:'show-n',
					showform:'show-n',
					showrefresh:'show-m',
					rlatitude:"21.144644112601775",
					rlongitude:"79.08860126768066",
					alltrucks:markers,
					onTripCounter : JSON.parse(response.data.body).gps_trucks
				});
				redirectURL.post("/weather/getPlants").then((rsp)=>{
					this.setState({
					  plantLocations : rsp.data,
					});
					console.log(rsp.data);
					this.renderMap();
		
				})								
				
			}
			else{
				this.setState({
					show: true, basicType:'warning', basicTitle:"No Data found.",
					loadshow:'show-n',
					showform:'show-m',
				});
				
			}
		})
		.catch(function(error){
			console.log(error);
		});

		redirectURL.post("consignments/getAllTrucksData").then((response)=>{
            // var trucks = response.data.map(function(e)
            // {
            //     if(e.truck_no != "" && e.dept_code == dept && e.truck_no != undefined)
            //     {
            //         return e.truck_no;
            //     }
            // })
            // this.setState({
            //     truckNoList :trucks
			// });
			try{
				var trucks = response.data.filter(function(e){return e.dept_code == dept;})
				//console.log("trucks ", trucks)
				trucks = trucks.map(function(e){
					if(e.truck_no != "")
					{
						return e.truck_no;
					}
				})
				this.setState({
						truckNoList :trucks
				});
			}catch(e){}
			
			//console.log(trucks.length);
        });
		

		this.renderMap();
		
	}
	createSelectTransportersItems() {
		 let items = []; 
		 items.push({ value: 'ALL', label: "All Transporters" });
    	this.state.alltransporters.map(item=>{
    		items.push({ value: item.transporter_code, label: item.transporter_name });
    		//items.push(<option key={item.dept_code} value={item.dept_code}>{item.dept_name}</option>); 
    	});
    	return items;
	} 
	/*
	createSelectItems() {
        let items = [];         
        //console.log(this.state.alldepts);
        items.push(<option key='all' value='all'>All Departments </option>);  
        this.state.alldepts.map((item, key) => {
        	//console.log("Here "+item,key);
        	items.push(<option key={item.dept_code} value={item.dept_code}>{item.dept_name}</option>);  
		});
        return items;
	} 
	
	*/


    changeHandler = truckstatus => {

    	this.setState(
	      { truckstatus },
	      () => console.log(`Option selected:`, this.state.truckstatus)
	    );
	}	

    changeKpiHandler = kpicode => {

    	this.setState(
	      { kpicode },
	      () => console.log(`Option selected:`, this.state.kpicode)
	    );
	}	

	setTripType = trip =>{
		this.setState({
			triptype : trip
		})
	}
	handleChange = transportervalue => {
    	this.setState(
			{ transportervalue },
			() => console.log(`Option selected:`, this.state.transportervalue)
		  );
	}
	/*handleTruckStatusChange(e){
		this.setState({ truckstatus: e.target.value });
	}*/
	initalList =(event)=>{
        $(".trucks-dropdown").removeClass("show-n");
        var dataset = this.state.truckNoList;
        dataset = dataset.slice(0,100);
        this.setState({
            filteredData : dataset,
            showTrucksList : "show-m",
        })
    }
    handlerForm = (event) => {
		try
		{
			$(".trucks-dropdown").removeClass("show-n");
			var dataset = this.state.truckNoList;
			var filteredResult = dataset.filter(function(e){
				if(e != "" && e != null)
				{
					return e.toString().toLowerCase().includes($("#inputTruck").val().toLocaleLowerCase());
				}
				
			});
			filteredResult = filteredResult.slice(0,100);
			this.setState({
				filteredData : filteredResult,
				showTrucksList : "show-m",
			});
		}catch(e){}
    }
    onClickTruck(event)
    {
         $("#inputTruck").val($(this).text());
    }
	handleFilter(e){
    	console.log("filter clicked");
		e.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedSearchByTransporter,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.setState({
			loadshow:'show-m',
		});
		//console.log("Here ",this.props.match.url);
		var dept;
		if(this.props.match.url == '/nearbyprttrucks')
		{
			dept='LOG-PRT'
		}

		if(this.props.match.url == "/nearbysndtrucks")
		{
			dept='SNDG'
		}
		if(this.props.match.url == "/nearbysctrucks")
		{
			dept='LOG-SC'
		}
		console.log("dept ",dept);
		//console.log("Checking ",this.state.transportervalue);
		var transcode=[];
		var tstatus;
		console.log(this.state.transportervalue);
		var selectedTransporters = this.state.transportervalue;
		try{
			if(selectedTransporters.length == 0)
			{
				selectedTransporters = "ALL";
			}
			if(selectedTransporters != "ALL")
			{
				selectedTransporters.map(function(e){
					transcode.push(e.value);
				})
				// if(this.state.transportervalue == 'ALL')
				// {
				// 	transcode=this.state.transportervalue;
				// }
				// else{
				// 	transcode=this.state.transportervalue.value;
				// }
				if(transcode.indexOf("ALL") != -1)
				{
					transcode = "ALL";
				}
				else
				{
					transcode = JSON.stringify(transcode);
				}
			}
			else
			{
				transcode = selectedTransporters;
			}
		}catch(e){
			transcode = "ALL";
		}
		
		
		if(this.state.truckstatus == '0')
		{
			tstatus=this.state.truckstatus;
		}
		else{
			tstatus=this.state.truckstatus.value;
		}
		var trip_type = this.state.triptype.value;
		
		var formdata = {
			radius:3000,
			latitude:"20.73568784876339",
			longitude:"79.38999444931353",
			dept_code:dept,
			transporter_code:transcode,
			status:tstatus,
			trip_type : trip_type
		}
		console.log(formdata);
		rlatitude="20.73568784876339";
		rlongitude="79.38999444931353";
		redirectURL.post('/vicinity/getRadiusCoordinates',formdata,{
			headers:{
				'Content-Type':'application/json',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'PUT, GET, POST, DELETE, OPTIONS',
				'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept, Authorization"

			}
		})
		.then(
			(response) =>{
			//console.log("response", JSON.parse(response.data.body));
		
			var markers = JSON.parse(JSON.parse(response.data.body).trucks_data);
			console.log(JSON.parse(JSON.parse(response.data.body).trucks_data))

			if(markers.length > 0)
			{
				this.setState({
					defaultradius:formdata.radius,
					coordinates:markers,
					onTripCounter : JSON.parse(response.data.body).gps_trucks,
					mwidth:"col-xl-12 col-lg-12",
					rowData:markers,
					radius:formdata.radius,
					loadshow:'show-n',
					showform:'show-n',
					showrefresh:'show-m',
					rlatitude:formdata.latitude,
					rlongitude:formdata.longitude,
				});
				redirectURL.post("/weather/getPlants").then((rsp)=>{
					this.setState({
						plantLocations : rsp.data,
					});
					console.log(rsp.data);
					this.renderMap();
					})
				
				
			}
			else{
				this.setState({
					show: true, basicType:'warning', basicTitle:"No Data found.",
					loadshow:'show-n',
					showform:'show-m',
				});
				
			}
		})
		.catch(function(error){
			console.log(error);
		});
		
		this.renderMap();
		
		
		
	}
	renderMap = () => {
    	
		loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyBQUmk7hdajZEAYllxKhVPnUx5pXuK-j_Y&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
 	}
	
	 initMap = () => {
		allcords=[];
		this.state.coordinates.map((marker) => {
			allcords.push(marker);
		});
		
		var contentdata = this.state.contentString;
		if(this.state.rlatitude == '' && this.state.rlongitude == '')
		{
			var lt=21.144644112601775;
			var ln=79.08860126768066;
		}
		else{
			var lt=this.state.rlatitude;
			var ln=this.state.rlongitude;
		}
		try{
			map = new window.google.maps.Map(document.getElementById('map_canvas'), {
				zoom: 5,
				center: new window.google.maps.LatLng(lt,ln),
				mapTypeId: window.google.maps.MapTypeId.ROADMAP,
				/*mapTypeControl: true,
				gestureHandling: 'greedy',
				mapTypeControlOptions: {
					style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
					position: window.google.maps.ControlPosition.TOP_RIGHT
				  },            
				disableDefaultUI: true,
				zoomControl: true*/
			});
		}
		catch(e){
			map = new window.google.maps.Map(document.getElementById('map_canvas'), {
				zoom: 5,
				center: new window.google.maps.LatLng(parseFloat(21.144644112601775),parseFloat(79.08860126768066)),
				mapTypeId: window.google.maps.MapTypeId.ROADMAP,
				/*mapTypeControl: true,
				gestureHandling: 'greedy',
				mapTypeControlOptions: {
					style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
					position: window.google.maps.ControlPosition.TOP_RIGHT
				  },            
				disableDefaultUI: true,
				zoomControl: true*/
			});
		}
    	
		circle = new window.google.maps.Circle({
            map: map
		});
		
		marker = new window.google.maps.Marker({
			map: map,
			draggable: true,
			animation: window.google.maps.Animation.DROP,
			//position: {lat: 28.48, lng: 77.06}
		});
		
		marker.addListener('click', function(){
			marker.setAnimation(window.google.maps.Animation.BOUNCE)			
		});
		
  		window.google.maps.event.addListener(map, 'click', function(event) {
			
			//document.getElementById("latitude").value = event.latLng.lat();
			//document.getElementById("longitude").value = event.latLng.lng();
			marker.setPosition(event.latLng);
			
		});
		circle.bindTo('center', marker, 'position');
		var markerLatlng1;
		//console.log("Radius ", this.state.radius)
		console.log(allcords);
		var markers = allcords.map((marker) => {
			// console.log("marker ",marker);
			if(marker.status == 1)
			{
				var truckText =  "Inside Plant";
				var color =  "#333333";
				var image =  require('../../assets/icons/truckblue.png');
			}
			else if(marker.status == 2)
			{
				var truckText =  "Going to Dealer";
				var color =  "#333333";
				var image =  require('../../assets/icons/truckblue.png');
			}
			else if(marker.status == 3 || marker.status == 4) 
			{
				var truckText =  "At Dealer Location";
				var color =  "#333333";
				var image =  require('../../assets/icons/truckblue.png');
			}
			else if(marker.status == 5)
			{
				var truckText =  "Left Destination Location";
				var color =  "#333333";
				var image =  require('../../assets/icons/truckblue.png');
			}
			else if(marker.status == 6)
			{
				var truckText =  "Return to Plant";
				var color =  "#333333";
				var image =  require('../../assets/icons/truckblue.png');
			}
			else if(marker.status == 7)
			{
				var truckText =  "Empty Truck Outside Plant";
				var color =  "#333333";
				var image =  require('../../assets/icons/truckblue.png');
			}
			else{
				var truckText =  marker.truck_no;
				var color =  "#333333";
				var image =  require('../../assets/icons/truckblue.png');
			}
			var image =  require('../../assets/icons/truckblue.png');
			var contentarr = []
			var headerTitle = truckText
			//contentarr.push({"key":"Truck No", "value":marker.truck_no});
			contentarr.push({"key":"Transporter Name", "value":marker.transporter_name});
			//contentarr.push({"key":"Distance from geofence", "value":marker.distance});
			contentarr.push({"key":"GPS Provider", "value":marker.actual_lspuser});
			contentarr.push({"key":"Last Packet Time", "value":getHyphenDDMMMYYYYHHMM(marker.timestamp)});
			contentarr.push({"key":"Speed (km/h)", "value":Math.ceil(marker.speed)});
			contentarr.push({"key":"Address", "value":marker.area+", "+marker.state});
			
			var contentString = infoBox(image, headerTitle, contentarr, '')

			
			// var contentString = "<table><tr><th>Status: </th><td style='color:"+color+";font-weight:600;text-align: right'>"+truckText+"</td></tr><tr><th>Distance from geofence: </th><td style='text-align: right'>";
			// contentString = contentString + marker.distance+"</td></tr><th>Truck No: </th><td style='text-align: right'>"+marker.truck_no+"</td></tr><tr><th>Name: </th><td style='text-align: right'>";
			// contentString = contentString + marker.transporter_name+"</td></tr><tr><th> LSP Name:</th><td style='text-align: right'>"+marker.lsp_name+"</td></tr><tr><th>Address:</th><td style='text-align: right'>";
			// contentString = contentString  +marker.address+"</td></tr></table>";
			
			//var contentString = "Here "+marker.truck_no;

			//var contentString = infoBox(require('../../assets/icons/truck_inside_24.png'), header, contentarr)

			var infowindow = new window.google.maps.InfoWindow({
				content: contentString
			});
			
			var markerLatlng = new window.google.maps.LatLng(parseFloat(marker.latitude), parseFloat(marker.longitude));
			
			if(marker.status == 1)
			{
				var image =  require('../../assets/icons/truckblue.png');
			}
			else if(marker.status == 2)
			{
				var image =  require('../../assets/icons/truckblue.png');
			}
			else if(marker.status == 3 || marker.status == 4) 
			{
				var image =  require('../../assets/icons/truckblue.png');
			}
			else if(marker.status == 5)
			{
				var image =  require('../../assets/icons/truckblue.png');
			}
			else if(marker.status == 6)
			{
				var image =  require('../../assets/icons/truckblue.png');
			}
			else if(marker.status == 7)
			{
				var image =  require('../../assets/icons/truckblue.png');
			}
			else{
				var image =  require('../../assets/icons/truckblue.png');
			}
			var image =  require('../../assets/icons/truckblue.png');
			/*
			if(marker.overspeeding_exception == 1)
			{
				var image =  require('../../assets/icons/truck_overspeed.png');
			}
			if(marker.nightdriving_exception == 1)
			{
				var image =  require('../../assets/icons/truck-end.png');
			}
			*/
			
			
			var mark = new window.google.maps.Marker({
                position: markerLatlng,
				map: map,
				title:"Status: "+truckText+" #"+marker.truck_no,
				icon: image
            });
			mark.addListener('click', function() {
				infowindow.open(map, mark);
			  });
			return mark
            mark.setMap(map);
		});
		var eventtrigger = document.getElementsByClassName('truck');
		var markerCluster = new MarkerClusterer(map, markers,
            {imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'});
		  // var searchBox = new window.google.maps.places.SearchBox(eventtrigger);
		  //map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(eventtrigger);
		if(this.state.plantLocations.length > 0){
			// this.state.plantLocations.map(function(params){
			// 	var plant = {
			// 	url: require("../../assets/icons/"+params.icon), // url
			// 	scaledSize: new window.google.maps.Size(32,32), // scaled size
			// 	origin: new window.google.maps.Point(0,0), // origin
			// 	anchor: new window.google.maps.Point(0, 0) // anchor
			// 	};
			// 	var coords = JSON.parse(params.coordinates);
			// 	var plantmarker = new window.google.maps.Marker({
			// 		position: new window.google.maps.LatLng(coords.lat,coords.lng),
			// 		map: map,
			// 		icon: plant,
			// 		title : params.plant_name
			// 	});
			// 	// var cityCircle = new window.google.maps.Circle({
			// 	// strokeColor: '#9fc9b9',
			// 	// strokeOpacity: 0.3,
			// 	// strokeWeight: 2,
			// 	// fillColor: '#9fc9b9',
			// 	// fillOpacity: 0.6,
			// 	// map: map,
			// 	// center:coords,
			// 	// radius: 50 * 1000
			// 	// });
			// })
		}
		 
	}
	toggleBounce() {
		if (marker.getAnimation() !== null) {
		  marker.setAnimation(null);
		} else {
		  marker.setAnimation(window.google.maps.Animation.BOUNCE);
		}
		
	  }
	
	changeInputHandler = (event) => {
    	let name = event.target.name;
		let value = event.target.value;
    	this.setState({[name]:value});
    }
    
	changeRadiusHandler = (event) => {
    	let name = event.target.name;
		let value = event.target.value;
		this.setState({[name]:value});
		circle.setRadius(value);
    }
    
	
	goBack(){
		window.location.reload();
	}
	getDistance = (p1, p2) => {
		//console.log("p1",p1);
		//console.log("p2",p2);
		var R = 6378137; // Earth’s mean radius in meter
		var dLat = rad(p2.lat - p1.lat);
		var dLong = rad(p2.lng - p1.lng);
		var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
		var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		var d = R * c;
		return d/1000; // returns the distance in meter
	  };
	onShowInfo = (e) =>{
		console.log(JSON.parse(e.target.id));
		this.setState({
			contentString:"Here Success"
		});
		var ourMarker = e.target.id;
 			window.google.maps.event.trigger(ourMarker, 'click');
		var infowindow = new window.google.maps.InfoWindow({
			content: "Here Success"
		  });
		infowindow.open(map, marker);

	}
	popmarker(e)
	{
		//console.log("OnClick Table ",e.data)
		var lat = parseFloat(e.data.latitude);
		var lng =parseFloat(e.data.longitude);
		var data =e.data;
		map.setCenter(new window.google.maps.LatLng(lat,lng));
		map.setZoom(22);
		var markerLatlng = new window.google.maps.LatLng(lat, lng);
		var image =  require('../../assets/icons/truckblue.png');
		var marker = new window.google.maps.Marker({
			position: markerLatlng,
			map: map,
			title:data.truck_no,
			icon:image
		});
		// var contentString = "Truck No: "+data.truck_no+"<br />Name: "+data.transporter_name+" LSP Name:"+data.lsp_name+"<br />Address:"+data.address;
		// var contentString = "<table><tr><th>Status: </th><td style='text-align: right'>"+data.status;
		// contentString = contentString +"</td></tr><th>Truck No: </th><td style='text-align: right'>"+data.truck_no;
		// contentString = contentString + "</td></tr><tr><th>Name: </th><td style='text-align: right'>"+data.transporter_name+"</td></tr><tr><th> LSP Name:</th><td style='text-align: right'>"+data.lsp_name;
		// contentString = contentString + "</td></tr><tr><th>Address:</th><td style='text-align: right'>"+data.address+"</td></tr></table>";
		
		var contentarr = []
		var headerTitle = data.truck_no
		//contentarr.push({"key":"Truck No", "value":marker.truck_no});
		contentarr.push({"key":"Transporter Name", "value":data.transporter_name});
		//contentarr.push({"key":"Distance from geofence", "value":marker.distance});
		contentarr.push({"key":"GPS Provider", "value":data.actual_lspuser});
		contentarr.push({"key":"Last Packet Time", "value":getHyphenDDMMMYYYYHHMM(data.timestamp)});
		contentarr.push({"key":"Speed (km/h)", "value":Math.ceil(data.speed)});
		contentarr.push({"key":"Address", "value":data.area+", "+data.state});
		
		var contentString = infoBox(image, headerTitle, contentarr, '')
		var infowindow = new window.google.maps.InfoWindow({
			content: contentString
		});
		
		marker.setAnimation(window.google.maps.Animation.DROP)
		marker.addListener('click', function() {
			infowindow.open(map, marker);
		});
		
		marker.setMap(map);
		
		
	}
	onShowUploadDiv = () => {
		//console.log("Click Upload Button")
		this.setState({
			uploadDivWidth:'30%',
			sliderTranslate:"slider-translate",
			showDiv:'show-m',
			csvmessage:'',
			csverrmessage:'',
		});
	}
	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
		});
	}

	onSelectTrucks(){
		var items = []
		this.state.alltrucks.map((item) => {
			items.push({ value: item, label: item.truck_no });
		})
		return items;
	}
	changeTruckHandler(event){
		let name = event.target.name;
		let value = event.target.value;
    	this.setState({[name]:value});
	}
	truckFormHandler(e){
		e.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedSearchByTruckNo
			}
			googleAnalytics.logEvent(eventOptions);
		}
		var truck = []
		var trucks = this.state.alltrucks;
		console.log($("#inputTruck").val(),"input truck");
		var filteredValue = trucks.filter(function(e){
			return e.truck_no == $("#inputTruck").val()
		});
		if(filteredValue.length > 0)
		{
			console.log("Matched")
			  var lat = parseFloat(filteredValue[0].latitude);
				var lng =parseFloat(filteredValue[0].longitude);
				var data = filteredValue[0];
				map.setCenter(new window.google.maps.LatLng(lat,lng));
				map.setZoom(22);
				var markerLatlng = new window.google.maps.LatLng(lat, lng);
				
				if(filteredValue[0].status == 1)
				{
					var truckText =  "Inside Plant";
					var color =  "#333333";
				}
				else if(filteredValue[0].status == 2)
				{
					var truckText =  "Going to Dealer";
					var color =  "#333333";
				}
				else if(filteredValue[0].status == 3 || filteredValue[0].status == 4) 
				{
					var truckText =  "At Dealer Location";
					var color =  "#333333";
				}
				else if(filteredValue[0].status == 5)
				{
					var truckText =  "Left Destination Location";
					var color =  "#333333";
				}
				else if(filteredValue[0].status == 6)
				{
					var truckText =  "Return to Plant";
					var color =  "#333333";
				}
				else if(filteredValue[0].status == 7)
				{
					var truckText =  "Empty Truck Outside Plant";
					var color =  "#333333";
				}
				else{
					var truckText =  "N/A";
					var color =  "#333333";
				}
				
				if(filteredValue[0].status == 1)
				{
					var image =  require('../../assets/icons/truckblue.png');
				}
				else if(filteredValue[0].status == 2)
				{
					var image =  require('../../assets/icons/truckblue.png');
				}
				else if(filteredValue[0].status == 3 || filteredValue[0].status == 4) 
				{
					var image =  require('../../assets/icons/truckblue.png');
				}
				else if(filteredValue[0].status == 5)
				{
					var image =  require('../../assets/icons/truckblue.png');
				}
				else if(filteredValue[0].status == 6)
				{
					var image =  require('../../assets/icons/truckblue.png');
				}
				else if(filteredValue[0].status == 7)
				{
					var image =  require('../../assets/icons/truckblue.png');
				}
				else{
					var image =  require('../../assets/icons/truckblue.png');
				}
				var image =  require('../../assets/icons/truckblue.png');
				var marker = new window.google.maps.Marker({
					position: markerLatlng,
					map: map,
					title:data.truck_no,
					icon:image
				});



				var contentarr = []
				var headerTitle = truckText
				contentarr.push({"key":"Truck No", "value":filteredValue[0].truck_no});
				contentarr.push({"key":"Transporter Name", "value":filteredValue[0].transporter_name});
				//contentarr.push({"key":"Distance from geofence", "value":trucks[i].distance});
				contentarr.push({"key":"LSP Name", "value":filteredValue[0].lsp_name});
				contentarr.push({"key":"Address", "value":filteredValue[0].address});
				
				var contentString = infoBox(image, headerTitle, contentarr, '')

			



				// var contentString = "<table><tr><th>Status: </th><td style='color:"+color+";font-weight:600;text-align: right'>"+truckText+"</td></tr><tr><th>Distance from geofence: </th><td style='text-align: right'>";
				// contentString = contentString + trucks[i].distance+"</td></tr><th>Truck No: </th><td style='text-align: right'>"+trucks[i].truck_no+"</td></tr><tr><th>Name: </th><td style='text-align: right'>";
				// contentString = contentString + trucks[i].transporter_name+"</td></tr><tr><th> LSP Name:</th><td style='text-align: right'>"+trucks[i].lsp_name+"</td></tr><tr><th>Address:</th><td style='text-align: right'>";
				// contentString = contentString  +trucks[i].address+"</td></tr></table>";
			
				
				var infowindow = new window.google.maps.InfoWindow({
					content: contentString
				});
				
				marker.setAnimation(window.google.maps.Animation.DROP)
				marker.addListener('click', function() {
					infowindow.open(map, marker);
				});
				
				marker.setMap(map);
		}
		else{
			this.setState({
				show: true, basicType:'warning',
				basicTitle:"No Data found.",
				loadshow:'show-n',
				showform:'show-m',
			})
		}
		console.log(filteredValue);
		// for (var i = 0; i < trucks.length; i++){
		// 	// look for the entry with a matching `code` value
		// 	if (trucks[i].truck_no == $("#inputTruck").val()){
		// 	   // we found it
		// 	  // trucks[i].name is the matched result
			  
		// 	}
		//   }
		/*
				var lat = parseFloat(e.value.latitude);
				var lng =parseFloat(e.value.longitude);
				var data =e.value;
				map.setCenter(new window.google.maps.LatLng(lat,lng));
				map.setZoom(22);
				var markerLatlng = new window.google.maps.LatLng(lat, lng);
				
				if(e.value.status == 1)
				{
					var image =  require('../../assets/icons/truck_inside_24.png');
				}
				else if(e.value.status == 2)
				{
					var image =  require('../../assets/icons/truck_going_24.png');
				}
				else if(e.value.status == 3 || e.value.status == 4) 
				{
					var image =  require('../../assets/icons/truck_atdealer_24.png');
				}
				else if(e.value.status == 5)
				{
					var image =  require('../../assets/icons/truck_leftdealer_24.png');
				}
				else if(e.value.status == 6)
				{
					var image =  require('../../assets/icons/truck_return_24.png');
				}
				else if(e.value.status == 7)
				{
					var image =  require('../../assets/icons/truck_empty_24.png');
				}
				else{
					var image =  require('../../assets/icons/truck_na_24.png');
				}
				
				var marker = new window.google.maps.Marker({
					position: markerLatlng,
					map: map,
					title:data.truck_no,
					icon:image
				});
				var contentString = "Truck No: "+data.truck_no+"<br />Name: "+data.transporter_name+" LSP Name:"+data.lsp_name+"<br />Address:"+data.address;
				var contentString = "<table><tr><th>Status: </th><td style='text-align: right'>"+data.status;
				contentString = contentString +"</td></tr><tr><th>Distance from geofence: </th><td style='text-align: right'>"+data.distance+" (KM)</td></tr><th>Truck No: </th><td style='text-align: right'>"+data.truck_no;
				contentString = contentString + "</td></tr><tr><th>Name: </th><td style='text-align: right'>"+data.transporter_name+"</td></tr><tr><th> LSP Name:</th><td style='text-align: right'>"+data.lsp_name;
				contentString = contentString + "</td></tr><tr><th>Address:</th><td style='text-align: right'>"+data.address+"</td></tr></table>";
				
				
				var infowindow = new window.google.maps.InfoWindow({
					content: contentString
				});
				
				marker.setAnimation(window.google.maps.Animation.DROP)
				marker.addListener('click', function() {
					infowindow.open(map, marker);
				});
				
				marker.setMap(map);
		*/
	}
	onCloseUserManualModal = () => {
		this.setState({ usermanualmodal: false });
	};

	onClickShowUserManaul = () => {
		this.setState({ usermanualmodal: true });
	};
    render(){
		const modalStyles  = {
			width:'1300px !important',
		}
		
		const {usermanualmodal} = this.state;			
		const { truckstatus } =this.state;
		const truckstatusoptions = [
			{ value: '0', label: 'All Status' },
			{ value: '1', label: 'Inside Plant' },
			{ value: '2', label: 'Going to Destination' },
			{ value: '3', label: 'At Destination Location' },
			{ value: '5', label: 'Left Destination Location' },
			/*{ value: '6', label: 'Return to Plant' },*/
			{ value: '7', label: 'Empty Truck Outside Plant' },
		  ];
		  const kpioptions = [
			  { value: '0', label: 'All Status' },
			  { value: '1', label: 'Overspeed' },
			  { value: '2', label: 'Transit Time Delay' },
			  { value: '3', label: 'Night Driving' },
			  { value: '4', label: 'Enroute Stoppages' }
			];
    	const dStyles={
			width:'90%',
			marginLeft:'0px',
			paddingLeft:'0px',
			height:'500px'
		}
		const btstye={
			marginBottom:"10px"
		}
		var listData = []
        try{
            this.state.filteredData.map(function(e){
                listData.push(<li className="dropdown-truck-no">{e}</li>)
                })
		}catch(e){console.log(e)}
        
        var showonlysnd = true;
		var showonlyprt = true;
		var hidecol = true;
		var hideDistance = true;
        var columnDefs= [
			{
				headerName: "",
				field: "map",
				width: 60,
				cellRenderer: function(params){
					return "<i class='icofont icofont-map-pins f25'></i>"
				},
				editable: false
			},
			{
				headerName: "Truck No",
				field: "truck_no",
				width: 150,
				filter: true,
			},
			// {
			// 	headerName: "Transporter Name",
			// 	field: "transporter_name",
			// 	width: 130,
			// 	filter: true
			// },
			// {
			// 	headerName: "Plant Code",
			// 	field: "plant_code",
			// 	width: 100,
			// 	filter: true
			// },
			{
				headerName: "Segment",
				field: "segment",
				width: 110,
				filter: true,
				hide: showonlysnd,
				valueGetter: function(params){
					if(params.data.segment == "P")
					{
						return "Primary"
					}
					else{
						return "Secondary"
					}
				}
			},
			// {
			// 	headerName: "Distance From Co-ordinates (KMS)",
			// 	field: "distance_from_coordinates",
			// 	width: 160,
			// 	filter: true,
			// 	valueGetter: function(params){
			// 		return Math.round(params.data.distance_from_coordinates)
			// 	}
			// },
			// {
			// 	headerName: "Actual LSP User",
			// 	field: "actual_lspuser",
			// 	width: 130,
			// 	filter: true
			// },
			
			// {
			// 	headerName: "Area",
			// 	field: "area",
			// 	width: 130,
			// 	filter: true
			// },
			// {
			// 	headerName: "State",
			// 	field: "state",
			// 	width: 130,
			// 	filter: true
			// },
			{
				headerName: "Speed",
				field: "speed",
				width: 130,
				filter: true,
				valueGetter : function(params){
					if(params.data.speed !=undefined && params.data.speed !="")
					{
						return Math.round(params.data.speed)
					}
				}
			},
			{
				headerName: "Last Packet Time",
				field: "timestamp",
				width: 160,
				filter: true,
				valueGetter : function(params){
                    try{
                        if(params.data.timestamp != "" && params.data.timestamp != undefined)
                        {
                            return getDDMMMYYYYHHMMDefault(params.data.timestamp);
                        }
                        else
                        {
                            return "";
                        }
                        
                    }
                    catch(e){
                        return "";
                    }
					
				}
			}
			 
		  ];


        return (
            
    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        > 
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
		            	
			                <div className="card-header">
				       			<h5>
				       				{/* <i className="icofont icofont-truck cus-i"></i> <span> Trucks Locations ( {hText} ) </span> */}
				       				<i className="icofont icofont-truck cus-i"></i> <span> {this.state.pageTitle} </span>
				       				
								</h5>
				   			</div>
				   			<div className="card-body row">
							 {/*  <span className="col-xl-12 col-lg-12 float-right" style={{fontSize:"12px",marginBottom:"20px"}}>
									<img className="mapico1" src={require('../../assets/icons/truck_na_32.png')} /> - N/A
									&nbsp;&nbsp;<img className="mapico1" src={require('../../assets/icons/truck_inside_32.png')} /> - Inside Plant
									&nbsp;&nbsp;<img className="mapico1" src={require('../../assets/icons/truck_going_32.png')} /> - Going to Dealer
									&nbsp;&nbsp;<img className="mapico1" src={require('../../assets/icons/truck_atdealer_32.png')} /> - At Dealer Location
									&nbsp;&nbsp;<img className="mapico1" src={require('../../assets/icons/truck_leftdealer_32.png')} /> - Left Dealer Location
									&nbsp;&nbsp;<img className="mapico" src={require('../../assets/icons/truck_empty_32.png')} /> - Empty Truck Outside Plant
									
								</span>
								*/}

                                <div className="col-xl-12 col-lg-12 col-sm-12 row ">
							   		<form className="row theme-form col-xl-4 col-lg-4" onSubmit={this.handleFilter.bind(this)}>
										<div className="col-xl-6 col-lg-6 col-sm-6" style={{paddingRight:'0px',marginBottom:"0.2em"}}>
											<label>Search by Trip Type:</label>
											<Select placeholder="Select Trip Type"
											isMulti={false}
											value={this.state.triptype}
											onChange={this.setTripType.bind(this)} 
											options={(this.state.deptcode == "LOG-SC")?[{"value":1,"label":"ALL"}]:
                                            [{"value":1,"label":"ALL"},
                                            // {"value":2,"label":"On Trip"},{"value":5,"label":"Waiting at Dealer"},{"value":3,"label":"Empty / Non-MSIL trip"}
                                            ]
											} 
											required/>
										</div>
                                        <div className="col-xl-6 col-lg-6 col-sm-6" style={{paddingRight:'0px'}}>
                                        <label>Select Transporter:</label>
											<Select placeholder="Select Transporters"
											className="rselect" 
											isMulti={true}
											value={this.state.transportervalue}
											onChange={this.handleChange.bind(this)} 
											options={this.createSelectTransportersItems()} required/>

										</div>
										<div className="col-xl-2 col-lg-2 col-sm-2" style={{padding:"0"}}>
											<button name="go" id="go"style={{height:"38px",margin:"10px 15px"}} className="btn btn-primary" type="submit">Go</button>
										</div>
									</form>
									<div className="col-xl-2 col-lg-2" style={{textAlign:"right",marginRight:"20px"}}>
										<label className=""><i className="icofont icofont-vehicle-delivery-van cus-i"></i> Total Carriers : </label>
									</div>
									<div className="col-xl-2 col-lg-2">
										<h6 className="txt-info f20" style={{paddingTop:"0.2em",marginLeft:"-2em"}}>
											<span className="counter">
												<CountUp end={(this.state.onTripCounter != '')?this.state.onTripCounter:0}/>
											</span>
										</h6>
									</div>
									<div className={"trucks-dropdown "+this.state.showTrucksList} style={{top: "72px",left: "60em", width: "20.3em"}}>
										<ul>
											{listData}
										</ul>
									</div>
									<form className=" theme-form col-xl-4 col-lg-4" onSubmit={this.truckFormHandler.bind(this)}>
										<div className="col-xl-12 col-lg-12 col-sm-12" style={{paddingRight:'0px'}}>
											<label>Search by Truck No:</label>
											<input type="text" name="truck_no" id="inputTruck"  placeholder="Truck No" autoComplete="off" className="form-control" onFocus={this.initalList.bind(this)} onChange={this.handlerForm.bind(this)} />
										</div>
										<div className="col-xl-12 col-lg-12 col-sm-12" style={{padding:"0"}}>
											<button name="go" id="go"style={{height:"38px",margin:"10px 15px"}} className="btn btn-primary" type="submit">Go</button>
										</div>
									</form>
								</div>
								 
								<div className="row col-xl-12 col-lg-12">
									
									
									{/* <div id="map_canvas" className={this.state.mwidth} style={dStyles}></div> */}
									
                                    <div className="col-xl-6 col-lg-6">
										<div id="myGrid" style={{ height: "540px",width: "100%"}}  className="ag-theme-balham">
											<AgGridReact
												modules={this.state.modules}
												columnDefs={columnDefs}
												defaultColDef={this.state.defaultColDef}
												rowData={this.state.rowData}
												enableCharts={false}
												enableRangeSelection={false}
												autoGroupColumnDef={this.state.autoGroupColumnDef}
												onGridReady={this.onGridReady}
												onGridState={this.onGridState}
												statusBar={this.state.statusBar}
												sideBar={this.state.sideBar}
												paginationPageSize={this.state.paginationPageSize}
												pagination={true}
												enableRangeSelection={false}
												floatingFilter={false}
												onCellClicked={this.popmarker}
											/>
										</div>
									</div>
									<div className="col-xl-6 col-lg-6">
										
										
										<div id="map_canvas" className={this.state.mwidth} style={dStyles}></div>
										
									</div>

								</div>
							   		
                            </div>
							<div className="card-header">
				       			<h6>
				       				<i className="fa fa-info-circle" style={{color:"cornflowerblue"}}></i> <span> Note : Only trucks with GPS data in the last 24 hours are considered </span>
				       				
								</h6>
				   			</div>
							
				   		</div>	
				   	</div>
					   
				 </div>
				
				 
				 <div className={"slide-r "+(this.state.sliderTranslate)} >
				 	
					
				 	<div className="slide-r-body" style={{position:"relative"}}>
						<div className={"dataLoad " +(this.state.loadshow)}>
							<img src={require("../../assets/icons/loader.gif")} />
						</div>
				 		<div className="col-xl-12 col-lg-12">
				 			 {this.displayData}
						</div>
					</div>
				 </div>
				
				<span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span>
          		<Modal open={usermanualmodal} onClose={this.onCloseUserManualModal.bind(this)} styles={modalStyles}>
					<div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<h5 className="model-title">Truck Location (Map)</h5>
						<div className="col-xl-12 col-lg-12 mt-20p">
							<div className="col-xl-12 col-lg-12">
								This utility provides pictorial representation of entire fleet (departmental) present at various locations throughout India. 
							</div>
							<div className="col-xl-12 col-lg-12">
								Through this utility option user can find vehicle details either for individual truck or for selected transporter fleet for in transit and Empty/non MSIL trips
							</div>
							<div className="col-xl-12 col-lg-12">
								Image here
							</div>
						</div>
					</div>
				</Modal>

                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                </div>
            </div>
              
        );
    }
}


function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function getPolygonCoords()
{
	
	 var len = geoFencingArea.getPath().getLength();
	 console.log(len);
	 var htmlStr = "";
	 var coordinates=[];
	 for (var i = 0; i < len; i++) {
		// var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
		// coordinates.push()
		 var strlatlng = geoFencingArea.getPath().getAt(i).toUrlValue(5);
		 var spliting = strlatlng.split(",");
		 var latlngd = {"lat":parseFloat(spliting[0]),"lng":parseFloat(spliting[1])};
		 coordinates.push(latlngd);
	  // htmlStr += "new google.maps.LatLng(" + geoFencingArea.getPath().getAt(i).toUrlValue(5) + "), ";
	   //Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
	   //htmlStr += "" + geoFencingArea.getPath().getAt(i).toUrlValue(5);
	 }
	// console.log("htmlStr", coordinates);
	 
}


$(document).ready(function(){
    $(document).on("click",".dropdown-truck-no",function(){
        var vals = $(this).text();
         $("#inputTruck").val(vals);
         $(".trucks-dropdown").removeClass("show-m");
         $(".trucks-dropdown").addClass("show-n");
    });

    $("body").on("click",function(e){
        var container = $("#inputTruck");
        if(!container.is(e.target))
        {
            try{
                if($(".trucks-dropdown").attr("class").includes("show-m") || $(".trucks-dropdown").attr("class") == "trucks-dropdown")
                {
                    $(".trucks-dropdown").removeClass("show-m");
                    $(".trucks-dropdown").addClass("show-n");
                }
            }catch(e){}
            
        }
        
        
    })
})