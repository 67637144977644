/* eslint-disable no-unused-vars */
var axios = require('axios');
var login_type = localStorage.getItem("login_type")
console.log(login_type,"login_type-redirectURL")
// var baseURL = 'http://34.131.205.216:5000/disco'
var baseURL = 'https://hero-autometrics.in/disco'
// var baseURL= 'http://localhost:5024/disco/'
if(login_type == 2 || login_type == "2"){
  baseURL = 'https://hero-autometrics.in/ibl'
  // baseURL= 'http://34.131.205.216:5001/ibl/'
}
var axiosInstance = axios.create({
  baseURL: baseURL
  /* other custom settings */
});

const cipher = salt => {
  try {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);

    return text => text.split('')
      .map(textToChars)
      .map(applySaltToChar)
      .map(byteHex)
      .join('');
  }
  // eslint-disable-next-line no-unreachable
  catch (e) { }

}
const encode = cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

var invalidreqs = ["drop", "select", "truncate", "insert", "update", "delete", "DROP", "SELECT", "TRUNCATE", "INSERT", "UPDATE", "DELETE",
  "Drop", "Select", "Truncate", "Insert", "Update", "Delete","HTML","html","Html","Script","SCRIPT","script","IFRAME","iframe","Iframe","&lt;","&gt;",
  ".aspx",".html",".js",".ASPX",".java",".JAVA",".C",".sh",".c",".SH",".JS",".HTML",".py",".PY",".exe",".EXE"];

axiosInstance.interceptors.request.use((param) =>{
  const url = param.url
  const parameters = param.data
  console.log("urls_ins", url)
  const nonParam_list = ['/consignments/preDefinedGeoFencePlants','/masters/trucks','/masters/getAllTransporters','/masters/departments','/transporters/list','/geofence/lists','/dashboard/getInitialDataForDeviationAnalysis','/dashboard/getallsavedbaseroutesforprt','/vicinity/getDistinctStates',]
  const params_urlsList = ['/dashboard/getDropDownValuesForGlobalSearch','/consignments/prtconsignmentsNoGateOut',
          '/consignments/getConsignmentsNewFilterKeys','/consignments/plants','/consignments/prtconsignments',
          '/dashboard/prtdepttrucks','/dashboard/prtdeptplants','/consignments/filterByConsignment',
          '/consignments/filterPRTActiveConsignments','/consignments/maproutes','/consignments/nogpsdata','/consignments/getDayWiseDistanceTravelled','/consignments/enroutes',"/consignments/updateConsignmentForceClose","/dashboard/saveConsignmentData",'/consignments/deliveredprtconsignments','/consignments/filterDeliveredByConsignment','/consignments/maproutes','/consignments/allprtconsignments','/consignments/filterAllByConsignment','/consignments/allsndconsignments','/consignments/forceclosureconsignments','/trucks/prttrucks','/consignments/getConsignmentsNewFilterKeysForSndTrucks','/dashboard/gettrucks','/dashboard/gettruckswithgpsdata','/overview/trucksWithNoTransportersDataCount','/overview/truckWithGPSData','/vicinity/plantRadiusCoordinates','/gmap','/consignments/getMonthlyTrucksInfo','/manage/getNoGpsPrtTrucks','/trucks/getUsersAndDealers','/trucks/addDealersListToUsers',"/transporters/insertNewTransporter","/geofence/addGeo","/geofence/updateGeo",'/dashboard/saveuser','/dashboard/userslist',"/dashboard/activateuser",'dashboard/getConsignerCoordinates',"/dashboard/saveheroprtbaseroute",'/dashboard/getOverspeedTriggers','/dashboard/getNightDrivingReport','/dashboard/getEnrouteStoppageReport','/consignments/mlltrucks','/consignments/monthlyDistanceTravelled','/consignments/deliveredsndconsignments','/consignments/filterSNDActiveConsignments','/trucks/triptrucks', '/consignments/getPrtTrucksTransDealers', '/consignments/getPrtDetentionReport']

  const newparameters = {}
  if(params_urlsList.includes(url)){
    // console.log("if_enteredUrl", url)
    for (let key in parameters){
      if(parameters[key] !== "" && parameters[key] !== undefined && typeof parameters[key] != "number" &&parameters[key] !== null){
        try{
            newparameters[key] = encode(parameters[key]);
        }catch(e){
          // console.log("Before_if_enteredUrl",parameters)
          // console.log("if_enteredUrl", url)
          // console.log("After_if_enteredUrl",e)
        }
        
      }else{
        newparameters[key] = (parameters[key]);
      }
    }
    param.data = newparameters
  }
  
  return param
  
})

module.exports = axiosInstance;
