import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';

import SweetAlert from 'react-bootstrap-sweetalert';
import DrawMap from '../common/drawmap';
import $ from 'jquery';
import { getDDMMMYYYYHHMMDefault } from '../common/utils';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import CountUp from 'react-countup';
var moment = require('moment');
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);
var infoBox = require('../common/google-infowindow');
var aggriddata = [];
var originaldata;

$.datetimepicker.setLocale('en');
var marker;
var allcords=[];
var map;
var doOnce = true;

var rad = function(x) {
	return x * Math.PI / 180;
  };
export default class RailPlanning extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
            loadshow:'show-n',
            overly:'show-n',
			modules: AllModules,
        	alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
                //editable: true,
                autoHeight:true,
				resizable: true
			},
			rowData: [],
            headerHeight: 60,
            rowHeight:50,
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
            showToolPanel: false,
            griddata:[],
			rowSelection: "single" ,
			context: { componentParent: this },
            frameworkComponents: {
               
            },
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,
            pagetitle:"Route Planning",
            originaldata:"",
            orignalresponse:"",
            mapinfo:[],
            originoption:{"value":"","label":"Select Origin"},
            destinationoption:{"value":"","label":"Select Destination"},
            originarr:[],
            destinationarr:[],
            selectedorigin:'',
            selecteddestination:'',
            checkedinputs:[],
            uncheckedinputs:[],
            noofroutes:3,
            tolls:[],
            tollshow:false,
            plantcoords:"",
            plantname:"",
            sliderTranslate:"",
            viewtolls:[],
            tollrowid:"",
            routeid:"",
            routeapprovestatus:"",
            open:false,
            route_reason:"",
            gridapi: [],
            viewroutedata:[],
            sliderRouteTranslate:"",
            sliderChokeTranslate:"",
            chokepoints:[],
            chokepointdata:[],
            startlat:"",
            endlng:"",
            mapcolors:[],
            trip_start:"",
            trip_end:"",
            total_trip_distance:"",
            total_trip_duration:"",
            baserouteid:'',
            baseroutecheck:false,
            decktype:"",
            transporter:{"value":"", "label":"Select Transporter"},
            transporterarr:[],
            loadingpoints:"",
            destinationpoints:"",
            loadingarr:[],
            destinationarr:[],
            rowraildata:[],
            columnDefs:[]
        };
        this.origins = this.origins.bind(this);
        this.destinations = this.destinations.bind(this);
        this.onLoadFormRequest = this.onLoadFormRequest.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.deckadjustments = this.deckadjustments.bind(this);
        this.createColumns = this.createColumns.bind(this);
        this.createRowData = this.createRowData.bind(this);
        
	}
	/*Alert Popups*/
	closeAlert = () => {
        this.setState({
            show: false
        });
    }

	hideAlert = () => {
        this.setState({
            alert: null
        });
	}
	
    componentDidMount(){
        loadDateTimeScript()
       // console.log("this.macth ", this.props.location.search)
        var s = this.props.location.search;
        var dept_code=''
        if(this.props.match.path == "/sndrouteplanning")
        {
            dept_code = "SNDG"
        }
        if(this.props.match.path == "/prtrouteplanning")
        {
            dept_code = "LOG-PRT"
        }
        if(this.props.match.path == "/tnprouteplanning")
        {
            dept_code = "LOG-TNP"
        }
        this.setState({
            dept_code:dept_code
        })
        
        //this.onClickShowRoute()
		// console.log("path ", this.props.match.path)
		this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        this.setState({eventAction: eventAction});
        redirectURL.post("/consignments/alltransporters")
        .then((response) => {
            this.setState({
                transporterarr:response.data
            })
        })
        redirectURL.post("/consignments/rakeloaddestpoints")
        .then((response) => {
           var records = response.data;
           //console.log("records ", records);
           var loadingpoints = records.loadingpoints;
           var destinationpoints = records.destinationpoints;
           this.setState({
                loadingarr:loadingpoints,
                destinationarr:destinationpoints
           })
           
        })
    }
    
	renderMap = () => {   
		
		loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyBQUmk7hdajZEAYllxKhVPnUx5pXuK-j_Y&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
    }
    

	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
	}
	onGridReady = params => {
		this.gridApi = params.api;
        this.setState({
            gridapi : params.api
        })
        this.gridColumnApi = params.columnApi;
        
        this.gridApi.addEventListener('paginationChanged', (e) =>
        {
            //Reset rows selection based on current page
           // this.resetPaginationSelection(this);
        });

        this.gridApi.forEachLeafNode( (node) => {
            // console.log("node here ",node)
            // node.setSelected(true);
         
        });
        
	};
	

	onGridState = () =>{
		 console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
           this.gridApi.setFilterModel(window.filterState);
    }

	changeOriginHandler = originoption => {
        this.setState(
            { originoption }
        );
    }
    changeTransporterHandler = transporter => {
        this.setState({
            transporter
        })
    }
    changeDestinationHandler = destinationoption => {
        this.setState(
            { destinationoption }
        );
    }
   
    changeTruckTypeHandler = decktype => {
        this.setState(
            { decktype }
        );
    }
   
   
    changeDispatchHandler = dispatchtime => {
        this.setState(
            { dispatchtime }
        );
    }
   
	formHandler = (event) =>{
        event.preventDefault();
       // loadchecked()
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.formAction,
			}
			googleAnalytics.logEvent(eventOptions);
		}
        try{
            var tcheck = document.getElementById("tollcheck")
            if(tcheck.checked == true)
            {
                tcheck.checked = false;
            }
        }
        catch(e){

        }
		this.setState({
            loadshow:'show-m'
        });
        if(this.state.transporter.value != "" && this.state.originoption.value != "" && this.state.destinationoption.value != "")
        {  
            var transporter=this.state.transporter.value;
            var formdata = {
                origin:this.state.originoption.value,
                destination:this.state.destinationoption.value,
                transporter:transporter[0],
                // decktype:this.state.decktype.value
            }
            
            this.onLoadFormRequest(formdata)
        }
        else
        {
            this.setState({
                loadshow:'show-n'
            });
        }
    }
    
    onLoadFormRequest = async(formdata) => { 
        //console.log("formdata ", formdata)
        await redirectURL.post("/consignments/railplanningdata", formdata)
        .then(async (response) => {
            console.log("Response ",response.data)
            this.setState({
                rowraildata:response.data,
                loadshow:'show-n',
                overly:'show-n'
            })
            await this.createColumns();
        })
    }
	origins(){
        var items=[]
        if(this.state.loadingarr.length > 0)
        {
            var loadingarr = this.state.loadingarr;
            loadingarr.map((item) =>{
                items.push({
                    value:item.geofence_name,label:item.geofence_name 
                })
            })
        }
        return items;
    }

    destinations(){
        var items=[]
        if(this.state.destinationarr.length > 0)
        {
            var destinationarr = this.state.destinationarr;
            destinationarr.map((item) =>{
                items.push({
                    value:item.geofence_name,label:item.geofence_name 
                })
            })
        }
        return items;
    }

    deckadjustments(){
        var items=[]
        items.push(
            {value:"25mm", label:"25mm"},
            {value:"50mm", label:"50mm"}
            
        );
        
        return items;
    }

    transporters(){
        var items=[]
        if(this.state.transporterarr.length > 0)
        {
            var transporterarr = this.state.transporterarr;
            transporterarr.map((item) =>{
                items.push({"value":item.transporter_code,"label":item.firstname})
            })
        }
        return items;
    }

    onCloseUploadDiv = () => {
		this.setState({
			sliderTranslate:"",
            sliderRouteTranslate:'',
            sliderChokeTranslate:"",
			showDiv:'show-n',
			overly:'show-n'
		});
    }
    

	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
            sliderTranslate:'',
            sliderRouteTranslate:'',
            sliderChokeTranslate:""
		});
		
    }
    
    changeHandler(event){
        let name = event.target.name;
        let value = event.target.value;
        this.setState({
            [name]:value
        });
    }
    
    onOpenModal = () => {
		this.setState({ open: true });
	 };
	
	onCloseModal = () => {
	   this.setState({ open: false });
    };
    
    createColumns()
    {
        var columns=[]
        var rowdata = this.state.rowraildata;
        columns.push({
            headerName:"Loading Point Left On",
            field:"loading_point_left_on",
            width:180,
            valueGetter:function(params){
                return getDDMMMYYYYHHMMDefault(params.data.loading_point_left_on)
            }
        },
        {
            headerName:"No Of Cars Planned",
            field:"no_of_vins_planned",
            width:100
        },
        {
            headerName:"Trip Load Factor",
            field:"trip_load_factor",
            width:100
        })
        var colss = []
        rowdata.forEach((wares, wkeys) => {
            // console.log("wares ", wares)
            // console.log("wkeys ", wkeys)
            
            for(var x in wares)
            {
                if(x != "loading_point_left_on" && x != "no_of_vins_planned" && x != "total"
                && x != "trip_load_factor")
                {
                    colss.push(x)
                }
            }
            
        })

        var unique = colss.filter(function (value, index, self) {
            return self.indexOf(value) === index;
        });
        // console.log("unique ", unique)
        unique.forEach((w, k) => {
            var defs = {
                headerName:w,
                field:w,
                headerClass:["textAlignCenter"],	
                cellClass:["textAlignCenter"],
                width:80
            }
            columns.push(defs);
        })   
        columns.push({
            headerName:"Total",
            headerClass:["textAlignCenter"],
            field:"total",
            width:100,
            //pinned:"right",
            cellClass:["textAlignCenter"]
        })
       console.log("columns ", columns)
        this.setState({
            columnDefs:columns,
            rowData:this.state.rowraildata
        })
    }
    createRowData(){
        // var rowdefs = []
        // var transporters = this.state.transporters;
        // var transportername = groupBy(transporters, rdata => rdata.transporter_name);
        // transportername.forEach((trans, transkey) =>{
        //     var rows = {}
        //     rows.transporter_name=transkey
        //     var rowcnt = 0
        //     if(trans.length > 0)
        //     {
        //         //console.log("trans ", trans)
        //         var warehousewise = groupBy(trans, rdata => rdata.warehouse);
        //         //console.log("warehousewise ", warehousewise)
        //         warehousewise.forEach((wares,wkey) => {
        //             var warehouses = groupBy(wares, rdata=>rdata.truck_type)
        //            // console.log("warehouses ", warehouses)
        //             warehouses.forEach((wh,ky) => {
        //                 rows[wh[0].warehouse+""+ky] = wh.length
        //                 rowcnt = rowcnt+parseInt(wh.length)
        //             })
        //         })
        //     }
        //     rows["total"] = rowcnt
        //     rowdefs.push(rows)
        // })
        // this.setState({
        //     rowdefs:rowdefs
        // })
        //console.log("rowdefs ", rowdefs)
    }


    render(){
        const modalStyles  = {
			width:'500px !important',
            }
        const { open } = this.state;
        var gridcolors = this.state.mapcolors
		const dStyles={
			width:'100%',
			marginLeft:'0px',
			paddingLeft:'0px',
			height:'500px'
        }
       
        return (
            
    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
		            	
			                <div className="card-header">
				       			<h5>
				       				<i className="icon-map cus-i"></i> <span>Rail Load Plan  </span>
				       			</h5>
				   			</div>
				   			<div className="card-body row">
							  
								<div className="row col-xl-12 col-lg-12">
                                    <div className="form-group col-xl-3 col-lg-3">
                                        <label>Select Transporter: </label>
                                        <Select 
                                            name="transporter"
                                            closeMenuOnSelect={true} 
                                            value={this.state.transporter} 
                                            className="border-radius-0" 
                                            onChange={this.changeTransporterHandler.bind(this)} 
                                            style={{borderRadius:"0px"}} 
                                            options={this.transporters()} required
                                        />
                                    </div>
                                    <div className="form-group col-xl-2 col-lg-2">
                                        <label>Select Origin: </label>
                                        <Select 
                                            name="originoption"
                                            closeMenuOnSelect={true} 
                                            value={this.state.originoption} 
                                            className="border-radius-0" 
                                            onChange={this.changeOriginHandler.bind(this)} 
                                            style={{borderRadius:"0px"}} 
                                            options={this.origins()} required
                                        />
                                    </div>
                                    <div className="form-group col-xl-2 col-lg-2">
                                        <label>Select Destination: </label>
                                        <Select 
                                            name="destinationoption"
                                            closeMenuOnSelect={true} 
                                            value={this.state.destinationoption} 
                                            className="border-radius-0" 
                                            onChange={this.changeDestinationHandler.bind(this)} 
                                            style={{borderRadius:"0px"}} 
                                            options={this.destinations()} required
                                        />
                                    </div>
                                   
                                    <div className="form-group col-xl-2 col-lg-2">
                                        <label>Select Deck: </label>
                                        <Select 
                                            name="decktype"
                                            closeMenuOnSelect={true} 
                                            value={this.state.decktype} 
                                            className="border-radius-0" 
                                            onChange={this.changeTruckTypeHandler.bind(this)} 
                                            style={{borderRadius:"0px"}} 
                                            options={this.deckadjustments()} required
                                        />
                                    </div>
                             
                                    
                                    <div className="form-group col-xl-2 col-lg-2">
                                        <button style={{marginTop:"30px"}} onClick={this.formHandler.bind(this)} type="button" className="btn btn-success">Submit</button>
                                    </div>
                                   
                                </div>

                                <div className="row col-xl-12 col-lg-12">                              
                                
                                    <div className="col-xl-12 col-lg-12">
                                      
                                        {/* <div className="col-xl-12 col-lg-12">
                                            <input type="checkbox" className="tollcheck" id="tollcheck" onClick={this.onClickShowTolls.bind(this)} /> Show Tolls
                                        </div> */}
                                        <div id="myGrid" style={{ height: "560px",width: "100%"}}  className="ag-theme-balham">
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={this.state.columnDefs}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.rowData}
                                                enableCharts={false}
                                                enableRangeSelection={false}
                                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                onGridReady={this.onGridReady}
                                                onGridState={this.onGridState}
                                                statusBar={this.state.statusBar}
                                                sideBar={this.state.sideBar}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                                enableRangeSelection={true}
                                                gridOptions={{
                                                    context: { componentParent: this }
                                                }}
                                                frameworkComponents={this.state.frameworkComponents}
                                                //onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                                onCellClicked={this.popmarker}
                                                headerHeight={this.state.headerHeight}
                                                rowHeight={this.state.rowHeight}
                                                onCellClicked={this.popmarker}
                                            />
                                        </div>
                                    </div>
                                   
								</div>
							   		
                            </div>
				   		</div>	
				   	</div>
				 </div>

				

                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
			
            </div>
              
        );
    }
}


window.onload = function (){
	// $(".datepicker").datepicker({
    //     dateFormat: "dd-mm-yy",
    //     changeMonth: true,
    //     changeYear: true
	// });
	$('#dispatchtime').datetimepicker({
		mask:'29:59',
        format:'H:i',
        datepicker:false,
    });
    
    
	$(".styles_modal__gNwvD").css({width:"450px"})
};

document.addEventListener('turbolinks:before-render', () => {
	loadDateTimeScript() 
  });

function loadDateTimeScript(){
   
	$('#dispatchtime').datetimepicker({
		mask:'29:59',
        format:'H:i',
        datepicker:false,
	});
	
}


function groupBy(list, keyGetter) {
    const map11 = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map11.get(key);
         if (!collection) {
             map11.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map11;
}

function GetSortOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
function setBg(){
	const randomColor = Math.floor(Math.random()*16777215).toString(16);
	return "#"+randomColor
  }
  function initOMS(){
    // Initializing OMS
    oms = new oms.OverlappingMarkerSpiderfier(map, {
      //markersWontMove: true,
      //keepSpiderfied: true,
      //nearbyDistance: 10,
      //circleFootSeparation: 60,
	  //legWeight: 1.5
	  markersWontMove: true,
	markersWontHide: true,
	keepSpiderfied: true,
	basicFormatEvents: true
    });
  }