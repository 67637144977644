/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import $ from "jquery";
import _ from "lodash";
import MarkerClusterer from "@google/markerclusterer";
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import CountUp from 'react-countup';
import Modal from 'react-responsive-modal';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import {
	getDDMMYYYY,
	getDDMMYYYYHHMMSS,
	getYYYYMMDD,
	getYYYYMMDDHHMMSS,
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD,
	getHyphenDDMMYYYYHHMMSS,
	getHyphenDDMMYYYY,
	getDDMMMYYYYHHMMDefault
} from '../common/utils';
var redirectURL = require('../redirectURL');

var infoBox = require('../common/google-infowindow');
var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);
var googleAnalytics = require("../common/googleanalytics");

var marker;
var markers = [];
var circle;
var selectlat;
var selectlng
var allcords = [];
var map;
var rad = function (x) {
	return x * Math.PI / 180;
};
var rlatitude;
var rlongitude;

var fencingLength = 0;
var geoFencingArea;
export default class ModelTruckData extends Component {

	constructor(props) {
		super(props);

		this.displayData = [];
		this.state = {
			pageTitle: "",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			showform: 'show-m',
			loadshow: 'show-n',
			showrefresh: 'show-n',
			sliderTranslate: '',
			showdata: this.displayData,
			radius: '5000',
			rlatitude: '',
			rlongitude: '',
			latitude: '',
			longitude: '',
			defaultradius: 0,
			coordinates: [],
			alert: null,
			show: false,
			basicTitle: '',
			basicType: "default",
			contentString: "testing",
			mwidth: "col-xl-12 col-lg-12",
			alldepts: [],
			transportervalue: '',
			alltransporters: [],
			truckstatus: '0',
			kpicode: '0',
			deptcode: '',
			truckNoList: [],
			alltrucks: [],
			truckno: '',
			showTrucksList: "show-n",
			filteredData: [],
			triptype: { "value": 1, "label": "ALL" },
			plantLocations: [],
			totalTrucksCount: 0,
			onTripCounter: 0,
			usermanualmodal: false,
			transporterTrucks: []

		};
		this.popmarker = this.popmarker.bind(this);
		this.truckFormHandler = this.truckFormHandler.bind(this);
	}


	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
			} else {
				console.log("GA disabled...");
			}
		} catch (error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}

	componentWillReceiveProps = async (newprops) => {
		// console.log("truckNo", newprops.truck_no)
		// console.log( "oldtruck", this.state.truckno)
		if (newprops.truck_no != this.state.truckno) {
			var truckno = newprops.truck_no
			//console.log("trcukno ", truckno)
			this.setState({
				truckno: truckno
			})
			this.setState({
				loadshow: 'show-m',
				coordinates: [],
				rowData: [],
				truckno: truckno
			});

			var dept = "SNDG";
			// console.log("dept_code is ",JSON.parse(localStorage.getItem("dept_code")))
			if (localStorage.getItem("dept_code") != undefined) {
				var depmntCodeJns = JSON.parse(localStorage.getItem("dept_code"));
				if (depmntCodeJns != "") {
					dept = depmntCodeJns[0];
				}
				else {
					dept = "SNDG";
				}

			}
			var transporter_code = "";
			// if(localStorage.getItem('transportercode') !=undefined)
			// {
			// 	var tptCode = JSON.parse(localStorage.getItem("transportercode"));
			// 	//var transporter_code = tptCode[0];
			// }
			this.setState({
				deptcode: dept,
				// transportervalue:tptCode
			});
			var formdata = {
				radius: 3000,
				latitude: "20.73568784876339",
				longitude: "79.38999444931353",
				dept_code: dept,
				// transporter_code:JSON.stringify(tptCode),
				status: this.state.truckstatus,
				trip_type: 1,
				source: "tptlogin",
				truck_no : truckno
			}
			rlatitude = "20.73568784876339";
			rlongitude = "79.38999444931353";
			//http://autometrics.in:5000/getNearByTrucks
			await redirectURL.post('/vicinity/getRadiusCoordinates', formdata, {
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Methods': 'PUT, GET, POST, DELETE, OPTIONS',
					'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept, Authorization"

				}
			})
				.then(
					async (response) => {
						// console.log(JSON.parse(response.data.apiData.body))
						var mrkers = JSON.parse(JSON.parse(response.data.apiData.body).trucks_data);
						// console.log(mrkers, "markers");
						//console.log(response.data.body,"resppp");
						// console.log("props.truck_no ", truckno)
						// await redirectURL.post("/dashboard/devices").then(async (response1)=>{
						// 	console.log("response1 ", response1.data)
						// 	var transporterTrucks = [];
						// 	var transptrucklist = [];
						// 	await response1.data.devices.map(function(e){
						// 		if(tptCode.indexOf(e.transporter_code) > -1 )
						// 		{
						// 			console.log("s.props.truck_no ", truckno)
						// 			if(truckno == e.truck_no)
						// 			{
						// 				transporterTrucks.push(e)
						// 				transptrucklist.push(e.truck_no);
						// 			}

						// 		}
						// 	})

						if (mrkers.length > 0) {
							// console.log(transporterTrucks,"transporterTrucks")
							// var markers = [];
							// if(transporterTrucks.length > 0)
							// {
							// 	// mrkers.map(function(t){
							// 	// 	if(transporterTrucks.indexOf(t.truck_no) > -1 )
							// 	// 	{
							// 	// 		markers.push(t)
							// 	// 	}
							// 	// })

							// 	markers =await mrkers.filter(f =>
							// 		transporterTrucks.filter((d )=> {
							// 			if(d.truck_no == f.truck_no){
							// 				// console.log("API ", d)
							// 				if(d.registereddevices != undefined)
							// 				{
							// 					if(d.registereddevices.length > 0)
							// 					{
							// 						f.device_address = d.registereddevices[0].address
							// 						f.device_city = d.registereddevices[0].city
							// 						f.device_state = d.registereddevices[0].state
							// 					}
							// 				}
							// 				return f;
							// 			}
							// 		})
							// 	);
							// }
							var markersarr = []
							if (mrkers.length > 0) {
								mrkers.map((item) => {
									if (item.truck_no == truckno) {
										markersarr.push(item)
									}
								})
							}
							// console.log(markersarr, "markersfilter")
							await this.setState({
								defaultradius: formdata.radius,
								coordinates: markersarr,
								totalTrucksCount: markersarr.length,
								mwidth: "col-xl-12 col-lg-12",
								rowData: markersarr,
								radius: 5000,
								loadshow: 'show-n',
								showform: 'show-n',
								showrefresh: 'show-m',
								rlatitude: "21.144644112601775",
								rlongitude: "79.08860126768066",
								alltrucks: markersarr,
								//onTripCounter : JSON.parse(response.data.body).gps_trucks,
								onTripCounter: markersarr.length,
								// transporterTrucks : transporterTrucks,
								// truckNoList :transptrucklist
							});
							redirectURL.post("/weather/getPlants").then((rsp) => {
								this.setState({
									plantLocations: rsp.data,
								});
								//console.log(rsp.data);


							})
							this.renderMap();

						}
						else {
							this.setState({
								show: true, basicType: 'warning', basicTitle: "No Data found.",
								loadshow: 'show-n',
								showform: 'show-m',
							});

						}
						// })

					})
				.catch(function (error) {
					console.log(error);
				});
			var tptParams = {
				transporter_code: localStorage.getItem("transportercode")
			}
			//await this.renderMap();
			// await this.truckFormHandler()
		}
	}
	// componentWillReceiveProps = async(newprops) => {
	// 	var truckno = newprops.truck_no
	// 	this.setState({
	// 		loadshow:'show-m',
	// 		coordinates:[],
	// 		rowData:[],
	// 		truckno:truckno
	// 	});
	// 	var dept = "";
	// 	if(localStorage.getItem("dept_code") !=undefined)
	// 	{            
	// 		var depmntCodeJns = JSON.parse(localStorage.getItem("dept_code"));
	// 		var dept = depmntCodeJns[0];
	// 	}
	// 	var transporter_code = "";
	// 	if(localStorage.getItem('transportercode') !=undefined)
	// 	{
	// 		var tptCode = JSON.parse(localStorage.getItem("transportercode"));
	// 		//var transporter_code = tptCode[0];
	// 	}
	// 	this.setState({
	// 		deptcode:dept,
	// 		transportervalue:tptCode
	// 	});
	// 	var formdata = {
	// 		radius:3000,
	// 		latitude:"20.73568784876339",
	// 		longitude:"79.38999444931353",
	// 		dept_code:dept,
	// 		transporter_code:JSON.stringify(tptCode),
	// 		status:this.state.truckstatus,
	// 		trip_type : 1
	// 	}
	// 	rlatitude = "20.73568784876339";
	// 	rlongitude = "79.38999444931353";
	// 	//http://autometrics.in:5000/getNearByTrucks
	// 	await redirectURL.post('/vicinity/getRadiusCoordinates',formdata,{
	// 		headers:{
	// 			'Content-Type':'application/json',
	// 			'Access-Control-Allow-Origin': '*',
	// 			'Access-Control-Allow-Methods': 'PUT, GET, POST, DELETE, OPTIONS',
	// 			'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept, Authorization"

	// 		} 
	// 	})
	// 	.then(
	// 		async (response) =>{
	// 		//console.log(response)
	// 		//console.log("response", JSON.parse(response.data.body));
	// 		// console.log(response.data.body)
	// 		var mrkers=JSON.parse(JSON.parse(response.data.body).trucks_data);
	// 		//console.log(mrkers,"markers");
	// 		//console.log(response.data.body,"resppp");
	// 					console.log("props.truck_no ", truckno)
	// 		await redirectURL.post("/dashboard/devices").then(async (response1)=>{
	// 			console.log("response1 ", response1.data)
	// 			var transporterTrucks = [];
	// 			var transptrucklist = [];
	// 			await response1.data.devices.map(function(e){
	// 				if(tptCode.indexOf(e.transporter_code) > -1 )
	// 				{
	// 					console.log("s.props.truck_no ", truckno)
	// 					if(truckno == e.truck_no)
	// 					{
	// 						transporterTrucks.push(e)
	// 						transptrucklist.push(e.truck_no);
	// 					}

	// 				}
	// 			})

	// 			if(mrkers.length > 0)
	// 			{
	// 				console.log(transporterTrucks,"transporterTrucks")
	// 				var markers = [];
	// 				if(transporterTrucks.length > 0)
	// 				{
	// 					// mrkers.map(function(t){
	// 					// 	if(transporterTrucks.indexOf(t.truck_no) > -1 )
	// 					// 	{
	// 					// 		markers.push(t)
	// 					// 	}
	// 					// })

	// 					markers =await mrkers.filter(f =>
	// 						transporterTrucks.filter((d )=> {
	// 							if(d.truck_no == f.truck_no){
	// 								// console.log("API ", d)
	// 								if(d.registereddevices != undefined)
	// 								{
	// 									if(d.registereddevices.length > 0)
	// 									{
	// 										f.device_address = d.registereddevices[0].address
	// 										f.device_city = d.registereddevices[0].city
	// 										f.device_state = d.registereddevices[0].state
	// 									}
	// 								}
	// 								return f;
	// 							}
	// 						})
	// 					);
	// 				}
	// 				var markersarr = []
	// 				if(markers.length > 0)
	// 				{
	// 					markers.map((item) => {
	// 						if(item.truck_no == truckno)
	// 						{
	// 							markersarr.push(item)
	// 						}
	// 					})
	// 				}
	// 				console.log(markersarr,"markersfilter")
	// 				await this.setState({
	// 					defaultradius:formdata.radius,
	// 					coordinates:markersarr,
	// 					totalTrucksCount : markersarr.length,
	// 					mwidth:"col-xl-12 col-lg-12",
	// 					rowData:markersarr,
	// 					radius:5000,
	// 					loadshow:'show-n',
	// 					showform:'show-n',
	// 					showrefresh:'show-m',
	// 					rlatitude:"21.144644112601775",
	// 					rlongitude:"79.08860126768066",
	// 					alltrucks:markersarr,
	// 					//onTripCounter : JSON.parse(response.data.body).gps_trucks,
	// 					onTripCounter : markersarr.length,
	// 					transporterTrucks : transporterTrucks,
	// 					truckNoList :transptrucklist
	// 				});
	// 				redirectURL.post("/weather/getPlants").then((rsp)=>{
	// 					this.setState({
	// 					plantLocations : rsp.data,
	// 					});
	// 					//console.log(rsp.data);
	// 					this.renderMap();

	// 				})								

	// 			}
	// 			else{
	// 				this.setState({
	// 					show: true, basicType:'warning', basicTitle:"No Data found.",
	// 					loadshow:'show-n',
	// 					showform:'show-m',
	// 				});

	// 			}
	// 		})

	// 	})
	// 	.catch(function(error){
	// 		console.log(error);
	// 	});
	// 	var tptParams = {
	// 		transporter_code : localStorage.getItem("transportercode")
	// 	}
	// 	await this.renderMap();
	// 	// await this.truckFormHandler()
	// }

	renderMap = () => {

		loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyBQUmk7hdajZEAYllxKhVPnUx5pXuK-j_Y&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
	}

	initMap = () => {
		allcords = [];
		if (this.state.coordinates.length > 0) {
			this.state.coordinates.map((marker) => {
				allcords.push(marker);
			});
			//console.log("allcords ", this.state.coordinates)
			var contentdata = this.state.contentString;

			var lt = 21.144644112601775;
			var ln = 79.08860126768066;

			try {
				map = new window.google.maps.Map(document.getElementById('map_modal'), {
					zoom: 11,
					center: new window.google.maps.LatLng(lt, ln),
					mapTypeId: window.google.maps.MapTypeId.ROADMAP,
					/*mapTypeControl: true,
					gestureHandling: 'greedy',
					mapTypeControlOptions: {
						style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
						position: window.google.maps.ControlPosition.TOP_RIGHT
					},            
					disableDefaultUI: true,
					zoomControl: true*/
				});
			}
			catch (e) {
				// map = new window.google.maps.Map(document.getElementById('map_modal'), {
				// 	zoom: 11,
				// 	center: new window.google.maps.LatLng(parseFloat(21.144644112601775),parseFloat(79.08860126768066)),
				// 	mapTypeId: window.google.maps.MapTypeId.ROADMAP,
				// 	/*mapTypeControl: true,
				// 	gestureHandling: 'greedy',
				// 	mapTypeControlOptions: {
				// 		style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
				// 		position: window.google.maps.ControlPosition.TOP_RIGHT
				// 	  },            
				// 	disableDefaultUI: true,
				// 	zoomControl: true*/
				// });
			}

			circle = new window.google.maps.Circle({
				map: map
			});

			marker = new window.google.maps.Marker({
				map: map,
				draggable: true,
				animation: window.google.maps.Animation.DROP,
				//position: {lat: 28.48, lng: 77.06}
			});

			marker.addListener('click', function () {
				marker.setAnimation(window.google.maps.Animation.BOUNCE)
			});

			window.google.maps.event.addListener(map, 'click', function (event) {

				//document.getElementById("latitude").value = event.latLng.lat();
				//document.getElementById("longitude").value = event.latLng.lng();
				marker.setPosition(event.latLng);

			});
			circle.bindTo('center', marker, 'position');
			var markerLatlng1;
			//console.log("Radius ", this.state.radius)
			//console.log(allcords);
			var bounds = new window.google.maps.LatLngBounds();
			var markers = allcords.map((marker) => {
				let gpsValue = marker.actual_lspuser
				if(gpsValue === 'AXESTRACK_SND'){
					gpsValue = 'AXESTRACK'
				}
				var image = require('../../assets/icons/truck_na_24.png');
				var contentarr = []
				var headerTitle = marker.truck_no;
				contentarr.push({ "key": "Truck No", "value": marker.truck_no });
				contentarr.push({ "key": "Transporter Name", "value": marker.transporter_name });
				//contentarr.push({"key":"Distance from geofence", "value":marker.distance});
				contentarr.push({"key":"GPS Provider", "value":gpsValue});
				// contentarr.push({ "key": "GPS Provider", "value": "Enmovil" }); //commented default gps provided to dynamic one
				contentarr.push({ "key": "Speed (km/h)", "value": Math.ceil(marker.speed) });
				contentarr.push({ "key": "Last Packet Time", "value": getHyphenDDMMMYYYYHHMM(marker.timestamp) });
				// truck_travelling_status
				if (marker.truck_travelling_status == 0) {
					var travelstat = "Travelling";
				}
				else {
					var travelstat = "Idle " + ConvertSeconds(marker.elaped_time_from_last_moved_seconds);
				}
				contentarr.push({ "key": "Travelling Status", "value": travelstat });
				contentarr.push({ "key": "Address", "value": marker.area });
				// contentarr.push({"key":"City", "value":marker.device_city});
				contentarr.push({ "key": "State", "value": marker.state });
				var contentString = infoBox(image, headerTitle, contentarr, '')

				//console.log(contentString,"contentString");
				// var contentString = "<table><tr><th>Status: </th><td style='color:"+color+";font-weight:600;text-align: right'>"+truckText+"</td></tr><tr><th>Distance from geofence: </th><td style='text-align: right'>";
				// contentString = contentString + marker.distance+"</td></tr><th>Truck No: </th><td style='text-align: right'>"+marker.truck_no+"</td></tr><tr><th>Name: </th><td style='text-align: right'>";
				// contentString = contentString + marker.transporter_name+"</td></tr><tr><th> LSP Name:</th><td style='text-align: right'>"+marker.lsp_name+"</td></tr><tr><th>Address:</th><td style='text-align: right'>";
				// contentString = contentString  +marker.address+"</td></tr></table>";

				//var contentString = "Here "+marker.truck_no;

				//var contentString = infoBox(require('../../assets/icons/truck_inside_24.png'), header, contentarr)

				var infowindow = new window.google.maps.InfoWindow({
					content: contentString
				});
				// console.log("parseFloat(marker.latitude) ", parseFloat(marker.latitude))
				var markerLatlng = new window.google.maps.LatLng(parseFloat(marker.latitude), parseFloat(marker.longitude));

				var image = require('../../assets/icons/truck_na_24.png');
				/*
				if(marker.overspeeding_exception == 1)
				{
					var image =  require('../../assets/icons/truck_overspeed.png');
				}
				if(marker.nightdriving_exception == 1)
				{
					var image =  require('../../assets/icons/truck-end.png');
				}
				*/


				var mark = new window.google.maps.Marker({
					position: markerLatlng,
					map: map,
					title: "Truck No: " + marker.truck_no,
					icon: image
				});
				mark.addListener('click', function () {
					infowindow.open(map, mark);
				});
				infowindow.open(map, mark);
				//return mark
				// mark.setMap(map);

				bounds.extend(new window.google.maps.LatLng(parseFloat(marker.latitude), parseFloat(marker.longitude)));
			});
			map.fitBounds(bounds)
			var eventtrigger = document.getElementsByClassName('truck');
			var markerCluster = new MarkerClusterer(map, markers,
				{ imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m' });
			// console.log("markers here ", markers)
			// var plantmarker = new window.google.maps.Marker({
			// 	position: new window.google.maps.LatLng(coords.lat,coords.lng),
			// 	map: map,
			// 	// icon: plant,
			// 	// title : params.plant_name
			// });
			// var searchBox = new window.google.maps.places.SearchBox(eventtrigger);
			//map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(eventtrigger);
			// if(this.state.plantLocations.length > 0){
			// 	this.state.plantLocations.map(function(params){
			// 		var plant = {
			// 		// url: require("../../assets/icons/"+params.icon), // url
			// 		scaledSize: new window.google.maps.Size(32,32), // scaled size
			// 		origin: new window.google.maps.Point(0,0), // origin
			// 		anchor: new window.google.maps.Point(0, 0) // anchor
			// 		};
			// 		var coords = JSON.parse(params.coordinates);
			// 		var plantmarker = new window.google.maps.Marker({
			// 			position: new window.google.maps.LatLng(coords.lat,coords.lng),
			// 			map: map,
			// 			// icon: plant,
			// 			// title : params.plant_name
			// 		});
			// 		// var cityCircle = new window.google.maps.Circle({
			// 		// strokeColor: '#9fc9b9',
			// 		// strokeOpacity: 0.3,
			// 		// strokeWeight: 2,
			// 		// fillColor: '#9fc9b9',
			// 		// fillOpacity: 0.6,
			// 		// map: map,
			// 		// center:coords,
			// 		// radius: 50 * 1000
			// 		// });
			// 	})
			// }
		}
		else {
			// console.log("elseCondition")
			$("#map_modal").html("No data found.")
		}

	}
	toggleBounce() {
		if (marker.getAnimation() !== null) {
			marker.setAnimation(null);
		} else {
			marker.setAnimation(window.google.maps.Animation.BOUNCE);
		}

	}

	getDistance = (p1, p2) => {
		//console.log("p1",p1);
		//console.log("p2",p2);
		var R = 6378137; // Earth’s mean radius in meter
		var dLat = rad(p2.lat - p1.lat);
		var dLong = rad(p2.lng - p1.lng);
		var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
		var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		var d = R * c;
		return d / 1000; // returns the distance in meter
	};
	onShowInfo = (e) => {
		// console.log(JSON.parse(e.target.id));
		this.setState({
			contentString: "Here Success"
		});
		var ourMarker = e.target.id;
		window.google.maps.event.trigger(ourMarker, 'click');
		var infowindow = new window.google.maps.InfoWindow({
			content: "Here Success"
		});
		infowindow.open(map, marker);

	}
	popmarker(e) {
		var truckNo = e.data.truck_no;
		var truck = [];
		var trucks = this.state.alltrucks;
		// console.log(truckNo, "input truck");
		var filteredValue = trucks.filter(function (e) {
			return e.truck_no == truckNo
		});
		if (filteredValue.length > 0) {
			// console.log("Matched")
			var lat = parseFloat(filteredValue[0].latitude);
			var lng = parseFloat(filteredValue[0].longitude);
			var data = filteredValue[0];
			map.setCenter(new window.google.maps.LatLng(lat, lng));
			map.setZoom(22);
			var markerLatlng = new window.google.maps.LatLng(lat, lng);

			if (filteredValue[0].status == 1) {
				var truckText = "Inside Plant";
				var color = "#333333";
			}
			else if (filteredValue[0].status == 2) {
				var truckText = "Going to Dealer";
				var color = "#333333";
			}
			else if (filteredValue[0].status == 3 || filteredValue[0].status == 4) {
				var truckText = "At Dealer Location";
				var color = "#333333";
			}
			else if (filteredValue[0].status == 5) {
				var truckText = "Left Destination Location";
				var color = "#333333";
			}
			else if (filteredValue[0].status == 6) {
				var truckText = "Return to Plant";
				var color = "#333333";
			}
			else if (filteredValue[0].status == 7) {
				var truckText = "Empty Truck Outside Plant";
				var color = "#333333";
			}
			else {
				var truckText = "N/A";
				var color = "#333333";
			}

			if (filteredValue[0].status == 1) {
				var image = require('../../assets/icons/truck_inside_24.png');
			}
			else if (filteredValue[0].status == 2) {
				var image = require('../../assets/icons/truck_going_24.png');
			}
			else if (filteredValue[0].status == 3 || filteredValue[0].status == 4) {
				var image = require('../../assets/icons/truck_atdealer_24.png');
			}
			else if (filteredValue[0].status == 5) {
				var image = require('../../assets/icons/truck_leftdealer_24.png');
			}
			else if (filteredValue[0].status == 6) {
				var image = require('../../assets/icons/truck_return_24.png');
			}
			else if (filteredValue[0].status == 7) {
				var image = require('../../assets/icons/truck_empty_24.png');
			}
			else {
				var image = require('../../assets/icons/truck_na_24.png');
			}
			var image = require('../../assets/icons/truck_na_24.png');
			var marker = new window.google.maps.Marker({
				position: markerLatlng,
				map: map,
				title: data.truck_no,
				icon: image
			});

			let gpsValue = filteredValue[0].actual_lspuser
				if(gpsValue === 'AXESTRACK_SND'){
					gpsValue = 'AXESTRACK'
				}
			var contentarr = []
			var headerTitle = filteredValue[0].truck_no;
			contentarr.push({ "key": "Truck No", "value": filteredValue[0].truck_no });
			contentarr.push({ "key": "Transporter Name", "value": filteredValue[0].transporter_name });
			//contentarr.push({"key":"Distance from geofence", "value":trucks[i].distance});
			contentarr.push({"key":"GPS Provider", "value":gpsValue});
			// contentarr.push({ "key": "GPS Provider", "value": "Enmovil" }); //commented default gps provided to dynamic one
			contentarr.push({ "key": "Speed (KMPH)", "value": filteredValue[0].speed });
			contentarr.push({ "key": "Last Packet Time", "value": getHyphenDDMMMYYYYHHMM(filteredValue[0].timestamp) });
			// contentarr.push({"key":"Address", "value":filteredValue[0].area+", "+filteredValue[0].state});
			if (filteredValue[0].truck_travelling_status == 1) {
				var travelstat = "Travelling";
			}
			else {
				var travelstat = "Idle " + ConvertSeconds(filteredValue[0].elaped_time_from_last_moved_seconds);
			}
			contentarr.push({ "key": "Travelling Status", "value": travelstat });
			contentarr.push({ "key": "Address", "value": filteredValue[0].device_address });
			contentarr.push({ "key": "City", "value": filteredValue[0].device_city });
			contentarr.push({ "key": "State", "value": filteredValue[0].device_state });

			var contentString = infoBox(image, headerTitle, contentarr, '')
			//console.log(contentarr,"contentarr")
			var infowindow = new window.google.maps.InfoWindow({
				content: contentString
			});

			marker.setAnimation(window.google.maps.Animation.DROP)
			marker.addListener('click', function () {
				infowindow.open(map, marker);
			});

			marker.setMap(map);
		}
		else {
			this.setState({
				show: true, basicType: 'warning',
				basicTitle: "No Data found.",
				loadshow: 'show-n',
				showform: 'show-m',
			})
		}
		console.log(filteredValue);

	}

	truckFormHandler() {
		//e.preventDefault();

		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedSearchByTruckNo
			}
			googleAnalytics.logEvent(eventOptions);
		}
		var truck = []
		var trucks = this.state.alltrucks;
		var struck = this.state.truckno
		var filteredValue = trucks.filter(function (e) {
			return e.truck_no == struck
		});
		// console.log(filteredValue, "filteredValue")
		if (filteredValue.length > 0) {
			// console.log("Matched")
			var lat = parseFloat(filteredValue[0].latitude);
			var lng = parseFloat(filteredValue[0].longitude);
			var data = filteredValue[0];
			// map.setCenter(new window.google.maps.LatLng(lat,lng));
			// map.setZoom(22);
			var markerLatlng = new window.google.maps.LatLng(lat, lng);

			var image = require('../../assets/icons/truck_na_24.png');
			var marker = new window.google.maps.Marker({
				position: markerLatlng,
				map: map,
				title: data.truck_no,
				icon: image
			});

			let gpsValue = filteredValue[0].actual_lspuser
			if(gpsValue === 'AXESTRACK_SND'){
				gpsValue = 'AXESTRACK'
			}

			var contentarr = []
			var headerTitle = filteredValue[0].truck_no;
			// contentarr.push({"key":"Truck No", "value":filteredValue[0].truck_no});
			contentarr.push({ "key": "Transporter Name", "value": filteredValue[0].transporter_name });
			//contentarr.push({"key":"Distance from geofence", "value":trucks[i].distance});
			contentarr.push({"key":"GPS Provider", "value":gpsValue});
			// contentarr.push({ "key": "GPS Provider", "value": "Enmovil" }); //commented default gps provided to dynamic one
			contentarr.push({ "key": "Speed (KMPH)", "value": filteredValue[0].speed });
			contentarr.push({ "key": "Last Packet Time", "value": getHyphenDDMMMYYYYHHMM(filteredValue[0].timestamp) });
			// contentarr.push({"key":"Address", "value":filteredValue[0].area+", "+filteredValue[0].state});
			contentarr.push({ "key": "Address", "value": filteredValue[0].device_address });
			contentarr.push({ "key": "City", "value": filteredValue[0].device_city });
			contentarr.push({ "key": "State", "value": filteredValue[0].device_state });

			var contentString = infoBox(image, headerTitle, contentarr, '')

			var infowindow = new window.google.maps.InfoWindow({
				content: contentString
			});

			marker.setAnimation(window.google.maps.Animation.DROP)
			marker.addListener('click', function () {
				infowindow.open(map, marker);
			});

			marker.setMap(map);
		}
		else {
			this.setState({
				show: true, basicType: 'warning',
				basicTitle: "No Data found.",
				loadshow: 'show-n',
				showform: 'show-m',
			})
		}



	}
	closeAlert = () => {
		this.setState({
			show: false
		});
	}

	render() {

		const dStyles = {
			width: '100%',
			marginLeft: '0px',
			paddingLeft: '0px',
			height: '70vh'
		}


		return (

			<div className="container-fluid">
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}
				>
				</SweetAlert>
				{this.state.alert}
				<div className="row">
					<div id="map_modal" className={this.state.mwidth} style={dStyles}>Loading...</div>
				</div>
			</div>

		);
	}
}


function loadScript(url) {
	var index = window.document.getElementsByTagName("script")[0]
	var script = window.document.createElement("script")
	script.src = url
	script.async = true
	script.defer = true
	index.parentNode.insertBefore(script, index)
}

function getPolygonCoords() {

	var len = geoFencingArea.getPath().getLength();
	// console.log(len);
	var htmlStr = "";
	var coordinates = [];
	for (var i = 0; i < len; i++) {
		// var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
		// coordinates.push()
		var strlatlng = geoFencingArea.getPath().getAt(i).toUrlValue(5);
		var spliting = strlatlng.split(",");
		var latlngd = { "lat": parseFloat(spliting[0]), "lng": parseFloat(spliting[1]) };
		coordinates.push(latlngd);
		// htmlStr += "new google.maps.LatLng(" + geoFencingArea.getPath().getAt(i).toUrlValue(5) + "), ";
		//Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
		//htmlStr += "" + geoFencingArea.getPath().getAt(i).toUrlValue(5);
	}
	// console.log("htmlStr", coordinates);

}
function ConvertSeconds(n) {
	var days = Math.round(parseInt(n / (24 * 3600)));

	n = n % (24 * 3600);
	var hours = Math.round(parseInt(n / 3600));

	n %= 3600;
	var minutes = Math.round(n / 60);

	n %= 60;
	var seconds = Math.round(n);

	return days + " Day(s) " + hours + " Hrs " + minutes + " mins " + seconds + " secs"
}

$(document).ready(function () {
	$(document).on("click", ".dropdown-truck-no", function () {
		var vals = $(this).text();
		$("#inputTruck").val(vals);
		$(".trucks-dropdown").removeClass("show-m");
		$(".trucks-dropdown").addClass("show-n");
	});

	$("body").on("click", function (e) {
		var container = $("#inputTruck");
		if (!container.is(e.target)) {
			try {
				if ($(".trucks-dropdown").attr("class").includes("show-m") || $(".trucks-dropdown").attr("class") == "trucks-dropdown") {
					$(".trucks-dropdown").removeClass("show-m");
					$(".trucks-dropdown").addClass("show-n");
				}
			} catch (e) { }

		}


	})
})