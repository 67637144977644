/* eslint-disable no-dupe-keys */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import _ from "lodash";
import Modal from 'react-responsive-modal';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import 'react-vertical-timeline-component/style.min.css';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getHyphenDDMMMYYYYHHMM, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import $ from "jquery";
var moment = require('moment');
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

const decipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
    return encoded => encoded.match(/.{1,2}/g)
        .map(hex => parseInt(hex, 16))
        .map(applySaltToChar)
        .map(charCode => String.fromCharCode(charCode))
        .join('');
}
var decode = decipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

export default class DetentionReportPrt extends Component {
    state = {
        startDate: moment.parseZone().subtract(10, 'days').format('YYYY-MM-DD'),
        endDate: moment.parseZone().format('YYYY-MM-DD'),
        modules: AllModules,
        eventGridAction: "gridAction",
        eventFormAction: "formAction",
        pageTitle: "Detention Report",
        loadshow: 'show-n',
        overly: "show-n",
        statusBar: {
            statusPanels: [
                {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                },
                {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center"
                },
                { statusPanel: "agFilteredRowCountComponent" },
                { statusPanel: "agSelectedRowCountComponent" },
                { statusPanel: "agAggregationComponent" }
            ]
        },
        sideBar: {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel"
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel"
                }
            ]
        },
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
        animateRows: true,
        debug: true,
        showToolPanel: true,
        alert: null,
        show: false,
        basicTitle: '',
        basicType: "default",
        dept_code: '',
        defaultColDef: {
            sortable: true,
            filter: true,
            // editable: true,
            resizable: true
        },
        animateRows: true,
        showToolPanel: false,
        rowSelection: "single",
        context: { componentParent: this },
        frameworkComponents: {
        },
        loadingCellRenderer: "customLoadingCellRenderer",
        paginationPageSize: 50,
        rowClassRules: {
            "colorForDangerLight": function (params) {
                if (params.data.parameters == "growth") {
                    return true;
                }
                else {
                    return false;
                }
            }
        },
        consigner: [{ value: 'All', label: 'All' }],
        rowData: [],
        filterTransporters: [], flTransporter: [{ value: 'All', label: 'All' }],
        truckVal: [{ value: 'All', label: 'All' }],
        filterTrucks: [], dealerList: [], btn1: 'btn-danger', btn2: 'btn-default', tab1: 'show-m', tab2: 'show-n',
    }

    componentDidMount() {

        this.onLoadGetFiltersList()
        this.onGetDetentionReport()
    }

    onLoadGetFiltersList = () => {
        const dpt = 'LOG-PRT';
        const query = {
            dept_code: dpt,
        }
        const dealer = localStorage.getItem('consignee_code')
        let dealerArr = []
        if (dealer !== "" && dealer !== undefined && dealer !== "undefined" && dealer !== null) {
            let dealerList = JSON.stringify(dealer.split(","))
            dealerArr = dealer.split(",")
            //  console.log("dealerList",dealerList)
            query.dealerList = dealerList
        }
        redirectURL.post('/consignments/getPrtTrucksTransDealers', query).then(res => {
            // console.log("res", res.data)
            let dealerList = res.data.dealerList
            const transList = res.data.transList
            const truckList = res.data.truckList
            // if(dealerArr.length > 0){
            //     dealerList = dealerList.filter(e =>dealerArr.includes(e.value) )
            // }
            this.setState({
                filterTransporters: transList,
                dealerList,
                filterTrucks: truckList
            })

        })
    }

    handlerStartDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);

        var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());
        if (this.state.endDate != undefined && this.state.endDate != "") {
            // console.log(startdate,"startdate")
            // console.log(this.state.endDate,"endDate")
            var startDate = new Date(startdate);
            var endDate = new Date(this.state.endDate);
            var startTime = startDate.getTime();
            var endTime = endDate.getTime();
            // console.log(startTime,endTime);
            if (startTime > endTime) {
                this.setState({
                    startDate: ""
                });
                alert("To Date should be greater than From Date");
            }
            else {
                this.setState({
                    startDate: startdate
                });
            }
        }
        else {
            this.setState({
                startDate: startdate
            });
        }
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }

    handlerEndDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        var edate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
        if (this.state.endDate != undefined && this.state.endDate != "") {
            // console.log(this.state.startDate,"startdate")
            // console.log(edate,"endDate")
            var startDate = new Date(this.state.startDate);
            var endDate = new Date(edate);
            var startTime = startDate.getTime();
            var endTime = endDate.getTime();
            // console.log(startTime,endTime);
            if (startTime > endTime) {
                this.setState({
                    endDate: ""
                });
                alert("To Date should be greater than From Date");
            }
            else {
                this.setState({
                    endDate: edate
                });
            }
        }
        else {
            this.setState({
                endDate: edate
            });
        }
    }

    newFilter = (filterType, selOption) => {
        console.log(filterType, selOption)
        if (selOption !== null && selOption !== "null") {
            this.setState({ [filterType]: selOption });
        } else {
            alert("select atleast one value")
        }
    }

    onGetDetentionReport = () => {
        const { startDate, endDate, flTransporter, truckVal, consigner, tab2 } = this.state
        const transList = []
        const truckList = []
        const dealerList = []
        if (flTransporter.length > 0) {
            flTransporter.map(e => {
                if (e.value !== "All") transList.push(e.value)
            })
        }

        truckVal.map(e => {
            if (e.value !== "All") truckList.push(e.value)
        })
        consigner.map(e => {
            if (e.value !== "All") dealerList.push(e.value)
        })
        const query = {
            startDate,
            endDate,
            trans_list: JSON.stringify(transList),
            truckList: JSON.stringify(truckList),
            dealerList: JSON.stringify(dealerList)
        }

        const query1 = {
            'from_date': startDate + ' 00:00:00',
            'to_date': endDate + ' 23:59:59'
        }
        if (truckList.length > 0) query1.truck_no = (truckList)
        if (transList.length > 0) query1.transporter_code = (transList)


        const dealer = localStorage.getItem('consignee_code')
        console.log("dealer", dealer)
        if (dealer !== "" && dealer !== undefined && dealer !== "undefined" && dealer !== null) {
            let dealerList = JSON.stringify(dealer.split(","))
            console.log("dealerList", dealerList)
            query.dealerList = dealerList
            query1.consignee_code = dealerList
            // console.log("check")
        }
        if (dealerList.length > 0) {
            // console.log("check1")
            query1.consignee_code = JSON.stringify(dealerList)
        }

        this.setState({
            loadshow: 'show-m',
            overly: 'show-m'
        })
        console.log("tab2", tab2)
        if (tab2 == 'show-m') {
            // console.log("trucksAtDealerPRT",query1)
            redirectURL.post('/trucks/trucksAtDealerPRT', query1).then(res => {
                // console.log("trucksAtDealerPRT", res.data)
                if (res.data.status == 'Failure' || res.data.message == "No Data") {
                    this.setState({
                        rowData : [],
                        show: true,
                        basicType: 'danger',
                        basicTitle: res.data.message,
                        loadshow: 'show-n',
                        overly: 'show-n'
                    })
                } else {
                    this.setState({
                        rowData: res.data,
                        overly: 'show-n',
                        loadshow: 'show-n',
                    })
                }
            })
        } else {
            // console.log("params",query)
            redirectURL.post('/consignments/getPrtDetentionReport', query).then(res => {
                console.log("responseData", res.data)
                const resData = res.data
                const rowData = []
                resData.forEach(e => {
                    e.diff_in_hrs = ""
                    if (e.recent_dealer_reported !== "" && e.recent_dealer_reported !== undefined && e.left_dealer_marked !== "" && e.left_dealer_marked !== undefined) {
                        var d1 = moment.parseZone(e.recent_dealer_reported).format("YYYY-MM-DD HH:mm:ss")
                        var d2 = moment.parseZone(e.left_dealer_marked).format("YYYY-MM-DD HH:mm:ss")
                        var ms = moment(d2, "YYYY-MM-DD HH:mm:ss").diff(moment(d1, "YYYY-MM-DD HH:mm:ss"));
                        var d = moment.duration(ms);
                        var s = Math.floor(d.asHours());
                        e.diff_in_hrs = s
                    }
                    e.transit_time_taken_hrs = 0
                    if (e.gate_out_time !== "" && e.gate_out_time !== undefined) {
                        var d1 = moment.parseZone(e.recent_dealer_reported).format("YYYY-MM-DD HH:mm:ss")
                        var d2 = moment.parseZone(e.gate_out_time).format("YYYY-MM-DD HH:mm:ss")
                        var ms = moment(d1, "YYYY-MM-DD HH:mm:ss").diff(moment(d2, "YYYY-MM-DD HH:mm:ss"));
                        var d = moment.duration(ms);
                        var s = Math.floor(d.asHours());
                        e.transit_time_taken_hrs = s
                    }
                    rowData.push(e)
                })

                this.setState({
                    rowData: rowData,
                    detentionData: rowData,
                    loadshow: 'show-n',
                    overly: 'show-n'
                })
            })
        }


    }

    onClickHideAll = () => {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
        });
    }

    onClickTab = button => {
        const { detentionData, startDate, endDate } = this.state
        // console.log('onClickTab',button)
        if (button == 'btn1') {
            this.setState({
                btn1: 'btn-danger',
                btn2: 'btn-default',
                tab1: 'show-m',
                tab2: 'show-n',
                rowData: detentionData,
                pageTitle: 'Detention Report'
            })
        } else {
            this.setState({
                btn1: 'btn-default',
                btn2: 'btn-danger',
                tab1: 'show-n',
                tab2: 'show-m',               
                pageTitle: 'Trucks At Dealer'
            })
            const query = {
                'from_date': startDate + ' 00:00:00',
                'to_date': endDate + ' 00:00:00'
            }
            const dealer = localStorage.getItem('consignee_code')
            // console.log("dealer",dealer)
            if (dealer !== "" && dealer !== undefined && dealer !== "undefined" && dealer !== null) {
                let dealerList = JSON.stringify(dealer.split(","))
                // console.log("dealerList",dealerList)
                query.consignee_code = dealerList
                // console.log("check")
            }
            // console.log("trucksAtDealerPRTquery",query)
            redirectURL.post('/trucks/trucksAtDealerPRT', query).then(res => {
                // console.log("res", res.data)
                if(res.data.message === "No Data"){
                    // console.log("trucksAtDealerPRT")
                    this.setState({
                        rowData: [],
                        overly: 'show-n',
                        loadshow: 'show-n'
                    })
                }
                if (!["", " ", undefined].includes(res.data)) {
                    if (res.data.length > 0) {
                        this.setState({
                            rowData: res.data,
                            overly: 'show-n',
                            loadshow: 'show-n'
                        })
                    }
                }                
                else{
                    
                    this.setState({
                        rowData: [],
                        overly: 'show-n',
                        loadshow: 'show-n'
                    })
                }

            })

        }
    }

    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
        // this.gridApi.sizeColumnsToFit();
    };

    onGridState = () => {
        //console.log(this.gridApi);
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();
        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }

    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    render() {
        const { show, basicType, basicTitle, loadshow, overly, defaultColDef, modules, rowData,
            autoGroupColumnDef, statusBar, sideBar, paginationPageSize, frameworkComponents, detailCellRendererParams,
            rowClassRules, rowSelection, overlayNoRowsTemplate, excelStyles, filterTransporters, flTransporter, truckVal,
            filterTrucks, dealerList, consigner, btn1, btn2, startDate, tab1, tab2, pageTitle
        } = this.state

        let columnwithDefs = [
            {
                headerName: "Truck no",
                field: "truck_no",
                width: 110,
                filter: true,
                resizable: true
            },
            {
                headerName: "Invoice no",
                field: "invoice_no",
                width: 110,
                filter: true,
                resizable: true
            },
            {
                headerName: "Consignment No",
                field: "consignment_code",
                //colId : "consignment_code",
                width: 120,
                editable: false,
                filter: "agSetColumnFilter", resizable: true,
            },
            {
                headerName: "Transporter name",
                field: "transporter_name",
                width: 190,
                filter: true,
                resizable: true
            },
            {
                headerName: "PD Code",
                field: "consignee_code",
                width: 90,
                filter: true,
                resizable: true
            },
            {
                headerName: "PD City",
                field: "consignee_city",
                width: 110,
                filter: true,
                resizable: true
            },
            {
                headerName: "PD Name",
                field: "consignee_name",
                width: 150,
                filter: true,
                resizable: true
            },
            {
                headerName: 'GPC gate out Date & Time (GPC)',
                field: "gate_out_time",
                width: 150,
                filter: true,
                resizable: true,
                valueGetter: val => {
                    if (val.data.gate_out_time !== "" && val.data.gate_out_time !== undefined) {
                        return getHyphenDDMMMYYYYHHMM(val.data.gate_out_time)
                    }
                    return "NA"
                }
            },
            {
                headerName: "Reached Destination At",
                field: "recent_dealer_reported",
                width: 150,
                filter: true,
                resizable: true,
                valueGetter: val => {
                    return getHyphenDDMMMYYYYHHMM(val.data.recent_dealer_reported)
                }
            },
            {
                headerName: "Transit Time taken(in Hrs)",
                field: "transit_time_taken_hrs",
                width: 130,
                filter: true,
                resizable: true
            },
            {
                headerName: "PD Geofence Entry_5km",
                field: "first_5_km_entry",
                width: 150,
                filter: true,
                resizable: true,
                valueGetter: val => {
                    return getHyphenDDMMMYYYYHHMM(val.data.first_5_km_entry)
                }
            },
            {
                headerName: "PD Geofence Exit_5Km",
                field: "left_dealer_marked",
                width: 150,
                filter: true,
                resizable: true,
                valueGetter: val => {
                    return getHyphenDDMMMYYYYHHMM(val.data.left_dealer_marked)
                }
            },
            {
                headerName: "At Dealer Since(In hrs)",
                field: "diff_in_hrs",
                width: 100,
                filter: true,
                resizable: true
            },
        ]
        if (btn2 == 'btn-danger') {
            columnwithDefs = [
                {
                    headerName: "Truck no",
                    field: "truck_no",
                    width: 110,
                    filter: true,
                    resizable: true
                },
                {
                    headerName: "Invoice no",
                    field: "invoice_no",
                    width: 110,
                    filter: true,
                    resizable: true
                },
                {
                    headerName: "Consignment No",
                    field: "consignment_code",
                    //colId : "consignment_code",
                    width: 120,
                    editable: false,
                    filter: "agSetColumnFilter", resizable: true,
                },
                {
                    headerName: "Transporter Name",
                    field: "transporter_name",
                    width: 160,
                    filter: true,
                    resizable: true
                },
                {
                    headerName: "PD Code",
                    field: "consignee_code",
                    width: 100,
                    filter: true,
                    resizable: true
                },
                {
                    headerName: "PD Name",
                    field: "consignee_name",
                    width: 140,
                    filter: true,
                    resizable: true
                },
                {
                    headerName: "PD location (Dealer City)",
                    field: "consignee_city",
                    width: 140,
                    filter: true,
                    resizable: true
                },
                {
                    headerName: 'GPC gate out Date & Time(GPC)',
                    field: "gate_out_time",
                    width: 140,
                    filter: true,
                    resizable: true,
                    valueGetter: val => {
                        if (val.data.gate_out_time !== "" && val.data.gate_out_time !== undefined) {
                            return getHyphenDDMMMYYYYHHMM(val.data.gate_out_time)
                        }
                        return "NA"
                    }
                },
                {
                    headerName: 'Entry Date & Time(Dealer Geofence<=5km)',
                    field: "recent_dealer_reported",
                    width: 140,
                    filter: true,
                    resizable: true,
                    valueGetter: val => {
                        if (val.data.recent_dealer_reported !== "" && val.data.recent_dealer_reported !== undefined) {
                            return getHyphenDDMMMYYYYHHMM(val.data.recent_dealer_reported)
                        }
                        return "NA"
                    }
                },
                {
                    headerName: "Transit Time Taken (in Hrs)",
                    field: "transit_time_in_hours",
                    width: 140,
                    filter: true,
                    resizable: true
                },
                {
                    headerName: "At Dealer location Since (in Hrs)",
                    field: "at_dealer_since",
                    width: 140,
                    filter: true,
                    resizable: true
                }
            ]
        }

        return (
            <div className="container-fluid">
                <SweetAlert
                    show={show}
                    type={basicType}
                    title={basicTitle}
                    onConfirm={this.closeAlert}>
                </SweetAlert>
                <div className="card col-xl-12 col-lg-12">
                    <div className="button-group">
                        <button className={"btn " + btn1} onClick={() => this.onClickTab("btn1")}>Detention Report</button>
                        <button className={"btn " + btn2} onClick={() => this.onClickTab("btn2")}>Trucks At Dealer</button>
                    </div>
                    <div className={"row card-body "} style={{ padding: '10px' }}>
                        <div className="row col-xl-12 col-lg-12">
                            <div className="col-xl-3 col-lg-3 form-group">
                                <label>From Date</label>
                                <Datetime value={startDate}
                                    disableCloseOnClickOutside={false}
                                    closeOnSelect={true}
                                    inputProps={{ placeholder: 'From', name: 'startDate', autoComplete: 'off', readOnly: true }}
                                    dateFormat="YYYY-MM-DD"
                                    name="startDate"
                                    onChange={this.handlerStartDateTime.bind(this)} />
                            </div>
                            <div className="col-xl-3 col-lg-3 form-group">
                                <label>To Date</label>
                                <Datetime value={this.state.endDate}
                                    disableCloseOnClickOutside={false}
                                    closeOnSelect={true}
                                    inputProps={{ placeholder: 'To', name: 'endDate', autoComplete: 'off', readOnly: true }}
                                    dateFormat="YYYY-MM-DD"
                                    onChange={this.handlerEndDateTime.bind(this)} />
                            </div>
                            <div className="form-group col-xl-3 col-lg-3">
                                <label>Transporter Name :  </label>
                                <Select
                                    className="border-radius-0"
                                    isMulti={true}
                                    id="selectedTransporter"
                                    style={{ borderRadius: "0px" }}
                                    value={flTransporter}
                                    onChange={this.newFilter.bind(this, "flTransporter")}
                                    options={filterTransporters}
                                />
                            </div>
                            <div className="form-group col-xl-3 col-lg-3">
                                <label>Truck No:  </label>
                                <Select
                                    className="border-radius-0"
                                    isMulti={true}
                                    id="selectedTruck"
                                    style={{ borderRadius: "0px" }}
                                    value={truckVal}
                                    onChange={this.newFilter.bind(this, "truckVal")}
                                    options={filterTrucks}
                                />
                            </div>
                        </div>
                        <div className="row col-xl-12 col-lg-12">
                            <div className="form-group col-xl-3 col-lg-3">
                                <label>Select Dealer: </label>
                                <Select placeholder={"All"} closeMenuOnSelect={false} value={consigner} isMulti="true" className="border-radius-0"
                                    onChange={this.newFilter.bind(this, "consigner")} style={{ borderRadius: "09px" }} options={dealerList} required />
                            </div>
                            <div className="form-group col-xl-3 col-lg-3 mt-4" >
                                <button type="button" className="btn btn-success" onClick={this.onGetDetentionReport}>Submit</button>
                            </div>
                        </div>

                    </div>

                    <div className="row" >
                        <div className="col-xl-12 col-lg-12">

                            <div className="card-header">
                                <h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{pageTitle} </span>
                                </h5>
                            </div>
                            <div id="myGrid" style={{ height: "580px", width: "100%" }} className="ag-theme-balham">
                                <AgGridReact
                                    modules={modules}
                                    columnDefs={columnwithDefs}
                                    defaultColDef={defaultColDef}
                                    rowData={rowData}
                                    enableCharts={true}
                                    enableRangeSelection={true}
                                    autoGroupColumnDef={autoGroupColumnDef}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    statusBar={statusBar}
                                    sideBar={sideBar}
                                    paginationPageSize={paginationPageSize}
                                    pagination={true}
                                    frameworkComponents={frameworkComponents}
                                    detailCellRendererParams={detailCellRendererParams}
                                    stopEditingWhenGridLosesFocus={true}
                                    rowClassRules={rowClassRules}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                    masterDetail={true}
                                    enableCellChangeFlash={false}
                                //floatingFilter={true}
                                //editType={editType}
                                //suppressCellFlash={true}
                                // getRowHeight={getRowHeight}
                                // onCellDoubleClicked={this.onRowClicked}
                                // onCellEditingStopped={this.onCellUpdateData}
                                // rowSelection={rowSelection}
                                // onRowSelected={this.onRowSelection}
                                // suppressRowClickSelection={true}
                                // suppressCellSelection= {true}
                                // onCellClicked={this.onCellClicked}
                                />
                            </div>
                        </div>

                    </div>
                </div>
                <div className={"dataLoadpage " + (loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (overly)} onClick={this.onClickHideAll}></div>
            </div>
        )
    }
}