import React, { Component } from 'react';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import {getDDMMMYYYYHHMMDefault,getHyphenDDMMYYYYHHMMSS  } from '../common/utils'; 
import $ from 'jquery';
// import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import ExportsTrackingCountersComponent from "./exportstrackingcounterComponent";
import SweetAlert from 'react-bootstrap-sweetalert';
import UpdateButton from "./updatelmstates";
 import ForceCloseButton from "./exportsforcecloseaction";
var redirectURL = require('../redirectURL');
var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");

export default class ExportsContainerTracking extends Component {
    constructor(props){
    	super(props);
    	
    	this.state={
    		pageTitle: "Exports Container Tracking",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
            eventFormAction: "formAction",
            loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			overly:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
            basicTitle:'',
			basicType:"default",
            modules: AllModules,
            sidebarSubHeader:'',
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true

      	    },
      	    rowData: [],
      	      
			rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            overlayNoRowsTemplate: "",
            frameworkComponents: {
                UpdateButton:UpdateButton,
                ForceCloseButton:ForceCloseButton
            },

      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,				
			groupDefaultExpanded: -1,
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:1000,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
            highseescnt:0,
            highseesarr:[],
            icdtoport:0,
            icdtoportarr:[],
            insideicdcnt:0,
            insideicdarr:[],
            insideportcnt:0,
            insideportarr:[],
            msilicdcnt:0,
            msilicdarr:[],
            originalData:[],
            polList:[],
            mgorocnt:0,
            mgoroarr:[],
            ropocnt:0,
            ropoarr:[],
            podicnt:0,
            podiarr:[],
            rowid:"",
            selectcontainerno:""
    	}
        this.onLoadDashboardData = this.onLoadDashboardData.bind(this);
		this.resetState = this.resetState.bind(this);
        this.onClickCounterShowData = this.onClickCounterShowData.bind(this);
        this.onClickForceClose = this.onClickForceClose.bind(this);
    }
   
    componentDidMount(){
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
        }
        
        redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname})
		.then( async (resp) => {
			//console.log("resp ", resp)
			await this.setState({
				usergridstate:resp.data
			});
			await this.restoreGridStates();
        })
        
        redirectURL.post("/consignments/getPolNames").then((response)=>{
            var pols = response.data;
            var polList = [];
            if(pols.length > 0)
            {
                pols.map((item) =>{
                    polList.push(item)
                })
            }
            this.setState({
                polList : polList,
            })
        }).catch((e)=>{
            console.log(e);
        })

        this.onLoadDashboardData()
	};
    
    onLoadDashboardData()
    {
        var params = {
            dept_code : "LOG-SC",
        }
		redirectURL.post("/consignments/exportstracking",params)
		.then((response) => {
            console.log("Respons ", response.data)
            var records = response.data;
            var highseesarr=[];
            var icdtoportarr=[];
            var insideicdarr=[];
            var insideportarr=[];
            var msilicdarr=[];
            var mgoroarr=[];
            var ropoarr=[];
            var podiarr=[];
            if(records.length > 0)
            {
                records.map((item) => {
                    if(item.status == "High Seas")
                    {
                        highseesarr.push(item)
                    }
                    if(item.status == "Inside Port")
                    {
                        insideportarr.push(item)
                    }
                    if(item.status == "ICD to Port")
                    {
                        icdtoportarr.push(item)
                    }
                    if(item.status == "Inside ICD")
                    {
                        insideicdarr.push(item)
                    }
                    if(item.status == "MSIL to ICD")
                    {
                        msilicdarr.push(item)
                    }
                    if(item.exports_to == undefined || item.exports_to == "")
                    {
                        if(item.manual_searates_pod != undefined && item.manual_searates_pod != "")
                        {
                            item.export_to = item.manual_searates_pod
                        }
                    }
                    if(item.dest_in_transit_delay == 1)
                    {
                        podiarr.push(item)
                    }
                    else if(item.port_out_transit_delay == 1)
                    {
                        ropoarr.push(item)
                    }
                    else if(item.rail_out_transit_delay == 1)
                    {
                        mgoroarr.push(item)
                    }
                })
            }
            this.setState({
                rowData:records,
                originalData:records,
                highseesarr:highseesarr,
                insideportarr:insideportarr,
                icdtoportarr:icdtoportarr,
                insideicdarr:insideicdarr,
                msilicdarr:msilicdarr,
                highseescnt:highseesarr.length,
                insideportcnt:insideportarr.length,
                icdtoportcnt:icdtoportarr.length,
                insideicdcnt:insideicdarr.length,
                msilicdcnt:msilicdarr.length,
                mgorocnt:mgoroarr.length,
                mgoroarr:mgoroarr,
                ropocnt:ropoarr.length,
                ropoarr:ropoarr,
                podicnt:podiarr.length,
                podiarr:podiarr,
            })
        })
        
    }
	
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
	}

	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			openUpdatedModal : false
		});
		
	}
	closeAlert = () => {
        this.setState({
            show: false
        });
    }
   

	onClickSaveGridState(){
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
        let screenpage = 'Exports Container Tracking';

		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates()
	{
        try{
            if(this.state.usergridstate.length > 0)
            {
                var windowstates = this.state.usergridstate;
                this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
                this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
                this.gridApi.setSortModel(windowstates[0].gridcolsort);
                this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
                
            }
        }
        catch(e){

        }
		
	}

	resetState = () => {
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

    };
    
	logCounterClickGA(params) {
		if (googleAnalytics.page.enableGA) {
            if(params == 1)
            {
                var statusText = "MSIL to ICD";
            }
            if(params == 2)
            {
                var statusText = "Inside ICD";
            }
            if(params == 3)
            {
                var statusText = "ICD to Port";
            }
            if(params == 4)
            {
                var statusText = "Inside Port";
            }
            if(params == 5)
            {
                var statusText = "Highseess";
			}
			let eventOptions = {
				"category": this.state.pageTitle,
				"action": this.state.eventCounterAction,
				"label": statusText,
			}
			googleAnalytics.logEvent(eventOptions);
		}
	}

    onClickCounterShowData(params){
        this.logCounterClickGA(params);
       // console.log("params ", params)
        // Log Counter Actions for Google Analytics
        if(params == 0)
        {
            this.setState({
                rowData:this.state.originalData
            })
        }
        if(params == 1)
        {
            this.setState({
                rowData:this.state.msilicdarr
            })
        }
        if(params == 2)
        {
            this.setState({
                rowData:this.state.insideicdarr
            })
        }
        if(params == 3)
        {
            this.setState({
                rowData:this.state.icdtoportarr
            })
        }
        if(params == 4)
        {
            this.setState({
                rowData:this.state.insideportarr
            })
        }
        if(params == 5)
        {
            this.setState({
                rowData:this.state.highseesarr
            })
        }
        if(params == 6)
        {
            this.setState({
                rowData:this.state.mgoroarr
            })
        }
        if(params == 7)
        {
            this.setState({
                rowData:this.state.ropoarr
            })
        }
        if(params == 8)
        {
            this.setState({
                rowData:this.state.podiarr
            })
        }
    }
    
    onCellRowClicked(e){
        // if(e.colDef.field == "not_log_sc")
        // {
        //     var container_number = e.data.container_number;
        //     if(container_number !=undefined && container_number !='')
        //     {
        //         var reqparams = {
        //             container_number : container_number,
        //             department_changed_by : localStorage.getItem("username"),
        //             department_changed_user_email : localStorage.getItem("email"),
        //         }
        //         console.log(reqparams);
        //         if (window.confirm("Are You Sure! Do you want really proceed?"))
        //         {
        //             redirectURL.post("/consignments/changeLogScExportsDepartment", reqparams)
        //             .then((response) => {
        //                 if(response.data.message == "Success")
        //                 {
        //                     this.setState({
        //                         basicTitle:"Successfully marked as Not LOG-SC",
        //                         basicType : "success",
        //                         show : true,
        //                         overly:"show-n",
        //                         loadshow:"show-n",
        //                     })
        //                     //window.location.reload();
        //                     this.onLoadDashboardData();
        //                 }
        //                 else
        //                 {
        //                     this.setState({
        //                         basicTitle:"Failed to mark as Not LOG-SC.",
        //                         basicType : "warning",
        //                         show : true
        //                     })
        //                 }
        //             })
        //         }
        //     }
        //     else
        //     {
        //         this.setState({
        //             basicTitle:"Something went wrong.",
        //             basicType : "warning",
        //             show : true
        //         })
        //     }
        // }
        // if(e.colDef.field == "update")
        // {
        //     if(e.data.export_to == '' || e.data.export_to == undefined)
        //     {
                
        //     }
        // }
    }
    updateLmStates(e)
    {
        console.log(e,"update")
        var container_number = e.container_number;
        if(container_number !=undefined && container_number !='')
        {
            var export_to = e.export_to;
            if(export_to !=undefined && export_to !='')
            {
                var reqparams = {
                    container_number : container_number,
                    export_to : export_to,
                }
                console.log(reqparams);
                redirectURL.post("/consignments/updateLogScExports", reqparams)
                .then((response) => {
                    if(response.data.message == "Success")
                    {
                        this.setState({
                            basicTitle:"Successfully Updated",
                            basicType : "success",
                            show : true,
                            overly:"show-n",
                            loadshow:"show-n",
                        })
                        //window.location.reload();
                        this.onLoadDashboardData();
                    }
                    else
                    {
                        this.setState({
                            basicTitle:"Failed to update.",
                            basicType : "warning",
                            show : true
                        })
                    }
                })
            }
            else
            {
                this.setState({
                    basicTitle:"Exports To field can not br empty.",
                    basicType : "warning",
                    show : true
                })
            }
        }
        else
        {
            this.setState({
                basicTitle:"Something went wrong.",
                basicType : "warning",
                show : true
            })
        }
    }
    onClickForceClose(params)
    {
        this.setState({
            loadshow:"show-m"
        })
        var cnf = window.confirm("Are you sure you want force close this item?")
        if(cnf == true)
        {
            // console.log("Params ", params)
            var parameters = {
                rowid:params._id,
                container_no:params.container_no
            }
            redirectURL.post("/consignments/exportsforceclose",parameters)
            .then((response) => {
                // console.log("response ", response.data)
                if(response.data.status == "success")
                {
                    this.setState({
                        show:true,
                        basicTitle:"Successfully force closed item",
                        basicType:"success",
                        loadshow:"show-n"
                    })
                }
                else
                {
                    this.setState({
                        show:true,
                        basicTitle:"Failed to force closed item",
                        basicType:"danger",
                        loadshow:"show-n"
                    })
                }
            })
        }
        else
        {

        }
        
    }
    render(){
        var columnwithDefs = [
            // {
            //     headerName : "",
            //     field : "not_log_sc",
            //     width:170,
            //     editable : false,
            //     pinned:"left",
            //     cellRenderer: function(params){
            //         return "<button class='btn btn-danger' style='padding:0px;width:146px'>Not LOG-SC Container</button>"
            //     }
            // },
            // {
            //     headerName : "",
            //     field : "update",
            //     width:100,
            //     editable : false,
            //     pinned:"left",
            //     cellRendererSelector: function(params){
            //         var rendComponent = {
            //             component: 'UpdateButton'
            //         };
            //         if(params.data.export_to == '' || params.data.export_to == undefined)
            //         {
            //             return rendComponent;
            //         }
            //     }
            // },
            {
                headerName : "Container No",
                field : "container_number",
                width:150,
                editable : false,
                pinned:"left",
                resizable:true
            },
            {
                headerName:"Export To",
                field:"export_to",
                width:150,
                editable:true,
                pinned:"left",
                resizable:true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: this.state.polList,
                },
            },
            // {
            //     headerName:"ICD Gate Out",
            //     field:"icd_gate_out",
            //     width:180,
            //     editable:false,
            //     resizable:true,
            //     pinned:"left",
            //     valueGetter:function(params){
            //         try{
            //             if(params.data.icd_gate_out != "" && params.data.icd_gate_out != undefined)
            //             {
            //                 return moment.parseZone(params.data.icd_gate_out).format('DD-MMM-YYYY');
            //             }
            //             else
            //             {
            //                 return "";
            //             }
            //         }
            //         catch(e)
            //         {
            //             return "";
            //         }
            //     }
            // },
            {
                headerName:"Dispatch Date From MSIL",
                field:"dispatch_date_from_msil",
                width:180,
                editable:false,
                pinned:"left",
                resizable:true,
                valueGetter:function(params){
                    try{
                        if(params.data.dispatch_date_from_msil != "" && params.data.dispatch_date_from_msil != undefined)
                        {
                            return getDDMMMYYYYHHMMDefault(params.data.dispatch_date_from_msil);
                        }
                        else
                        {
                            return "";
                        }
                    }
                    catch(e)
                    {
                        return "";
                    }
                }
            },
            {
                headerName:"Rail Out",
                field:"railout_date",
                width:180,
                editable:false,
                resizable:true,
                cellStyle: function(params){
                    if(params.data.rail_out_transit_delay == 1)
                    {
                        return {background:"#EA4C46"}
                    }
                },
                valueGetter:function(params){
                    try{
                        if(params.data.railout_date != undefined && params.data.railout_date != "")
                        {
                            if(params.data.railout_date.includes("T") == true)
                            {
                                return getHyphenDDMMYYYYHHMMSS(params.data.railout_date);
                            }
                            else
                            {
                                return (params.data.railout_date);
                            }
                        }
                        else
                        {
                            return "";
                        }
                    }
                    catch(e)
                    {
                        return "";
                    }
                }
            },
            {
                headerName:"Expected Rail Out(MSIL TT)",
                field:"expected_eta_railout",
                width:200,
                editable:false,
                resizable:true,
                valueGetter:function(params){
                    try{
                        if(params.data.expected_eta_railout != undefined && params.data.expected_eta_railout != "")
                        {
                            if(params.data.expected_eta_railout.includes("T") == true)
                            {
                                return getHyphenDDMMYYYYHHMMSS(params.data.expected_eta_railout);
                            }
                            else
                            {
                                return (params.data.expected_eta_railout);
                            }
                        }
                        else
                        {
                            return "";
                        }
                    }
                    catch(e)
                    {
                        return "";
                    }
                }
            },
            {
                headerName:"Port Entry",
                field:"port_entry_date",
                width:180,
                editable:false,
                resizable:true,
                valueGetter:function(params){
                    try{
                        if(params.data.port_entry_date != "" && params.data.port_entry_date != undefined)
                        {
                            return getDDMMMYYYYHHMMDefault(params.data.port_entry_date);
                        }
                        else
                        {
                            return "";
                        }
                    }
                    catch(e)
                    {
                        return "";
                    }
                }
            },
            {
                headerName:"Status",
                field:"status",
                width:120,
                editable:false,
                resizable:true
            },
            {
                headerName:"Transhipment",
                field:"is_transshipment",
                width:120,
                editable:false,
                resizable:true,
                hide:true,
                valueGetter:function(params){
                    if(params.data.is_transshipment != undefined && params.data.is_transshipment !='')
                    {
                        if(params.data.is_transshipment == 1)
                        {
                            return "Yes";
                        }
                        else
                        {
                            return "No"
                        }
                    }
                    else
                    {
                        return "No"
                    }
                }
            },
            {
                headerName:"Old Container No",
                field:"old_container_no",
                hide:true,
                width:120,
                editable:false,
                resizable:true,
                valueGetter:function(params){
                    if(params.data.is_transshipment != undefined && params.data.is_transshipment !='')
                    {
                        if(params.data.is_transshipment == 1)
                        {
                            return params.data.old_container_no;
                        }
                    }
                }
            },
            {
                headerName:"Shipping Line Booking Id",
                field:"shipping_line_booking_id",
                width:150,
                editable:false,
                resizable:true
            },
            {
                headerName:"Container Type",
                field:"container_type",
                width:150,
                editable:false,
                resizable:true
            },
            {
                headerName:"Nomination Type",
                field:"nomination_type",
                width:150,
                editable:false,
                resizable:true
            },
            {
                headerName:"CHA NAME",
                field:"cha_name",
                width:150,
                editable:false,
                resizable:true
            },
            {
				headerName: "Loading",
				headerClass:["cellstylegridY","cellTopHeader"],			
				children: [
                    {
                        headerName:"Loading Port",
                        headerClass:"cellstylegridY",
                        field:"loading_port",
                        width:180,
                        editable:false,
                        resizable:true,
						cellClass:'cellstylegridY'
                    },
                    {
                        headerName:"Loading Vessel",
                        headerClass:"cellstylegridY",
                        field:"loading_vessel",
                        width:180,
                        editable:false,
                        resizable:true,
						cellClass:'cellstylegridY'
                    },
                    {
                        headerName:"Booking Id",
                        headerClass:"cellstylegridY",
                        field:"booking_id",
                        width:140,
                        editable:false,
                        resizable:true,
						cellClass:'cellstylegridY'
                    },
                    {
                        headerName:"Planned Dispatch From Indian Port",
                        headerClass:"cellstylegridY",
                        field:"planned_dispatch_from_indian_port",
                        width:180,
                        editable:false,
                        resizable:true,
						cellClass:'cellstylegridY',
                        valueGetter:function(params){
                            try{
                                if(params.data.planned_dispatch_from_indian_port != undefined && params.data.planned_dispatch_from_indian_port != "")
                                {
                                    if(params.data.planned_dispatch_from_indian_port.includes("T") == true)
                                    {
                                        return getHyphenDDMMYYYYHHMMSS(params.data.planned_dispatch_from_indian_port);
                                    }
                                    else
                                    {
                                        return (params.data.planned_dispatch_from_indian_port);
                                    }
                                }
                                else
                                {
                                    return "";
                                }
                            }
                            catch(e)
                            {
                                return "";
                            }
                        }
                    },
                    {
                        headerName:"Revised/Actual Dispatch from Indian Port",
                        headerClass:"cellstylegridY",
                        field:"revised_actual_dispatch_from_indian_port",
                        width:180,
                        editable:false,
                        resizable:true,
						cellClass:'cellstylegridY',
                        cellStyle: function(params){
                            if(params.data.port_out_transit_delay == 1)
                            {
                                return {background:"#EA4C46"}
                            }
                        },
                        valueGetter:function(params){
                            try{
                                if(params.data.revised_actual_dispatch_from_indian_port != undefined && params.data.revised_actual_dispatch_from_indian_port != "")
                                {
                                    if(params.data.revised_actual_dispatch_from_indian_port.includes("T") == true)
                                    {
                                        return getHyphenDDMMYYYYHHMMSS(params.data.revised_actual_dispatch_from_indian_port);
                                    }
                                    else
                                    {
                                        return (params.data.revised_actual_dispatch_from_indian_port);
                                    }
                                }
                                else
                                {
                                    return "";
                                }
                            }
                            catch(e)
                            {
                                return "";
                            }
                        }
                    },
                    {
                        headerName:"Expected ETA Port Out(MSIL TT)",
                        headerClass:"cellstylegridY",
                        field:"expected_eta_port_out",
                        width:200,
                        editable:false,
                        resizable:true,
                        cellClass:'cellstylegridY',
                        valueGetter:function(params){
                            try{
                                if(params.data.expected_eta_port_out != undefined && params.data.expected_eta_port_out != "")
                                {
                                    if(params.data.expected_eta_port_out.includes("T") == true)
                                    {
                                        return getHyphenDDMMYYYYHHMMSS(params.data.expected_eta_port_out);
                                    }
                                    else
                                    {
                                        return (params.data.expected_eta_port_out);
                                    }
                                }
                                else
                                {
                                    return "";
                                }
                            }
                            catch(e)
                            {
                                return "";
                            }
                        }
                    },
                ]
            },
            {
				headerName: "Transshipment",
				headerClass:["cellstylegridB","cellTopHeader"],			
				children: [
                    {
                        headerName:"Transshipment Port",
                        headerClass:"cellstylegridB",
                        field:"transshipment_port",
                        width:180,
                        editable:false,
                        resizable:true,
						cellClass:'cellstylegridB'
                    },
                    {
                        headerName:"Planned Arrival at Singapore",
                        headerClass:"cellstylegridB",
                        field:"planned_dispatch_from_transshipment_port",
                        width:180,
                        editable:false,
                        resizable:true,
						cellClass:'cellstylegridB',
                        valueGetter:function(params){
                            try{
                                if(params.data.planned_dispatch_from_transshipment_port != undefined && params.data.planned_dispatch_from_transshipment_port != "")
                                {
                                    if(params.data.planned_dispatch_from_transshipment_port.includes("T") == true)
                                    {
                                        return getHyphenDDMMYYYYHHMMSS(params.data.planned_dispatch_from_transshipment_port);
                                    }
                                    else
                                    {
                                        return (params.data.planned_dispatch_from_transshipment_port);
                                    }
                                }
                                else
                                {
                                    return "";
                                }
                            }
                            catch(e)
                            {
                                return "";
                            }
                        }
                    },
                    {
                        headerName:"Revised /Actual  arrival at Singapore",
                        headerClass:"cellstylegridB",
                        field:"revised_actual_dispatch_from_transshipment_port",
                        width:180,
                        editable:false,
                        resizable:true,
						cellClass:'cellstylegridB',
                        valueGetter:function(params){
                            try{
                                if(params.data.revised_actual_dispatch_from_transshipment_port != undefined && params.data.revised_actual_dispatch_from_transshipment_port != "")
                                {
                                    if(params.data.revised_actual_dispatch_from_transshipment_port.includes("T") == true)
                                    {
                                        return getHyphenDDMMYYYYHHMMSS(params.data.revised_actual_dispatch_from_transshipment_port);
                                    }
                                    else
                                    {
                                        return (params.data.revised_actual_dispatch_from_transshipment_port);
                                    }
                                }
                                else
                                {
                                    return "";
                                }
                            }
                            catch(e)
                            {
                                return "";
                            }
                        }
                    },
                    {
                        headerName:"Transshipment Vessel",
                        headerClass:"cellstylegridB",
                        field:"transshipment_vessel",
                        width:180,
                        editable:false,
                        resizable:true,
						cellClass:'cellstylegridB'
                    },
                    {
                        headerName:"Planned ETD Singapore",
                        headerClass:"cellstylegridB",
                        field:"planned_etd_from_transshipment_port",
                        width:180,
                        editable:false,
                        resizable:true,
						cellClass:'cellstylegridB',
                        valueGetter:function(params){
                            try{
                                if(params.data.planned_etd_from_transshipment_port != undefined && params.data.planned_etd_from_transshipment_port != "")
                                {
                                    if(params.data.planned_etd_from_transshipment_port.includes("T") == true)
                                    {
                                        return getHyphenDDMMYYYYHHMMSS(params.data.planned_etd_from_transshipment_port);
                                    }
                                    else
                                    {
                                        return (params.data.planned_etd_from_transshipment_port);
                                    }
                                }
                                else
                                {
                                    return "";
                                }
                            }
                            catch(e)
                            {
                                return "";
                            }
                        }
                    },
                    {
                        headerName:"Revised/Actual ETD Singapore",
                        headerClass:"cellstylegridB",
                        field:"revised_actual_etd_from_transshipment_port",
                        width:180,
                        editable:false,
                        resizable:true,
						cellClass:'cellstylegridB',
                        valueGetter:function(params){
                            try{
                                if(params.data.revised_actual_etd_from_transshipment_port != undefined && params.data.revised_actual_etd_from_transshipment_port != "")
                                {
                                    if(params.data.revised_actual_etd_from_transshipment_port.includes("T") == true)
                                    {
                                        return getHyphenDDMMYYYYHHMMSS(params.data.revised_actual_etd_from_transshipment_port);
                                    }
                                    else
                                    {
                                        return (params.data.revised_actual_etd_from_transshipment_port);
                                    }
                                }
                                else
                                {
                                    return "";
                                }
                            }
                            catch(e)
                            {
                                return "";
                            }
                        }
                    }
                ]
            },
            {
				headerName: "Destination",
				headerClass:["cellstylegridG","cellTopHeader"],			
				children: [
                    {
                        headerName:"Planned arrival at Destination Port",
                        headerClass:"cellstylegridG",
                        field:"planned_dispatch_from_destination_port",
                        width:180,
                        editable:false,
                        resizable:true,
                        cellClass:'cellstylegridG',
                        valueGetter:function(params){
                            try{
                                if(params.data.planned_dispatch_from_destination_port != undefined && params.data.planned_dispatch_from_destination_port != "")
                                {
                                    if(params.data.planned_dispatch_from_destination_port.includes("T") == true)
                                    {
                                        return getHyphenDDMMYYYYHHMMSS(params.data.planned_dispatch_from_destination_port);
                                    }
                                    else
                                    {
                                        return (params.data.planned_dispatch_from_destination_port);
                                    }
                                }
                                else
                                {
                                    return "";
                                }
                            }
                            catch(e)
                            {
                                return "";
                            }
                        }
                    },
                    {
                        headerName:"Revised/Actual  arrival at Destination Port",
                        headerClass:"cellstylegridG",
                        field:"revised_actual_dispatch_from_destination_port",
                        width:180,
                        editable:false,
                        resizable:true,
                        cellClass:'cellstylegridG',
                        cellStyle: function(params){
                            if(params.data.dest_in_transit_delay == 1)
                            {
                                return {background:"#EA4C46"}
                            }
                        },
                        valueGetter:function(params){
                            try{
                                if(params.data.revised_actual_dispatch_from_destination_port != undefined && params.data.revised_actual_dispatch_from_destination_port != "")
                                {
                                    if(params.data.revised_actual_dispatch_from_destination_port.includes("T") == true)
                                    {
                                        return getHyphenDDMMYYYYHHMMSS(params.data.revised_actual_dispatch_from_destination_port);
                                    }
                                    else
                                    {
                                        return (params.data.revised_actual_dispatch_from_destination_port);
                                    }
                                }
                                else
                                {
                                    return "";
                                }
                            }
                            catch(e)
                            {
                                return "";
                            }
                        }
                    },
                    {
                        headerName:"Expected ETA Destination In(MSIL TT)",
                        headerClass:"cellstylegridG",
                        field:"expected_eta_dest_in",
                        width:200,
                        editable:false,
                        resizable:true,
                        cellClass:'cellstylegridG',
                        valueGetter:function(params){
                            try{
                                if(params.data.expected_eta_dest_in != undefined && params.data.expected_eta_dest_in != "")
                                {
                                    if(params.data.expected_eta_dest_in.includes("T") == true)
                                    {
                                        return getHyphenDDMMYYYYHHMMSS(params.data.expected_eta_dest_in);
                                    }
                                    else
                                    {
                                        return (params.data.expected_eta_dest_in);
                                    }
                                }
                                else
                                {
                                    return "";
                                }
                            }
                            catch(e)
                            {
                                return "";
                            }
                        }
                    },
                    {
                        headerName:"Destination Arrival Status",
                        headerClass:"cellstylegridG",
                        field:"destination_arrival_status",
                        width:180,
                        editable:false,
                        resizable:true,
						cellClass:'cellstylegridG'
                    },
                    {
                        headerName : "Force Close",
                        field : "",
                        width:100,
                        editable : false,
                        cellRendererSelector: function(params){
                            var rendComponent = {
                                component: 'ForceCloseButton'
                            };
                            return rendComponent;
                            
                        }
                    }

                ]
            }
        ]
        return (
            <div className="row">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="col-xl-12 col-lg-12">
                    <ExportsTrackingCountersComponent
                        context={this}
                        highseescnt={this.state.highseescnt}
                        insideportcnt={this.state.insideportcnt}
                        icdtoportcnt={this.state.icdtoportcnt}
                        insideicdcnt={this.state.insideicdcnt}
                        msilicdcnt={this.state.msilicdcnt}
                        allRowDatacount={this.state.originalData.length}
                        dealyCounters={1}
                        mgorocnt={this.state.mgorocnt}
                        ropocnt={this.state.ropocnt}
                        podicnt={this.state.podicnt}
                        avgCounters={0}
                    />
                    <div className="card">
                        <div className="card-header">
                            <h5>
                                <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span> Export Summary </span>                                    
                            </h5>
                        </div>				   			
                        <div className="card-body">
                            <div className="col-xl-12 col-lg-12">
                                <div id="myGrid" style={{ height: "500px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={false}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        frameworkComponents={this.state.frameworkComponents}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        enableRangeSelection={true}
                                        components={this.state.components}
                                        rowSelection={this.state.rowSelection}
                                        suppressRowClickSelection={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        onCellClicked={this.onCellRowClicked.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}






