import React,  { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import { TabContent, TabPane } from 'reactstrap';
import CKEditor from "react-ckeditor-component";

import _ from "lodash";

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import Modal from 'react-responsive-modal';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
//import Grid from '../layouts/gridnosidebarComponent';
import CountUp from 'react-countup';
import CustomLoadingCellRenderer from "./customLoadingCellRenderer";
import KpiActions from '../manage/kpiActionsComponent';
import TicketsMap from "./ticketsMapcomponent";
import Select from 'react-select';
import KpiTransitDelayActions from '../manage/kpitickettransitdelay';
import KpiNightDriveActions from '../manage/kpiNightDriveActionsComponent';
import Ticketkpienroute from './ticketkpienroute';
import Ticketloadingaction from './ticketloadingaction';
import Ticketunloadingaction from './ticketunloadingaction';
import NoGps from '../manage/nogpsActionComponent.js';
import RoutemapAction from '../manage/routemapActionComponent';
import RoutemapTransitAction from '../manage/routemaptransit';
import RouteNightDrivemap from '../manage/routenightdrivemap';
import RoutemapNoGPSAction from '../manage/routemapnogps';
import RoutemapenrouteAction from '../manage/routemapenroute';
import Slowtrucks from "./slowtrucksaction";
import Tickethistory from './tickethistory';
import InvalidTicket from './invalidTicket';
import Ticketmailview from './ticketmailview';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
//import CSVFileValidator from 'csv-file-validator'
import Sendemailticket from './sendEmailTicket';
import { getDDMMYYYY, 
	getDDMMYYYYHHMMSS, 
	getYYYYMMDD, 
	getYYYYMMDDHHMMSS, 
	getHyphenYYYYMMDDHHMMSS, 
	getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,
	getHyphenYYYYMMDD, 
	getHyphenDDMMYYYYHHMMSS, 
	getHyphenDDMMYYYY } from '../common/utils';
import 'react-vertical-timeline-component/style.min.css';
import DrawMap from '../common/drawmap';
var infoBox = require('../common/google-infowindow');
 
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');
 
var containerslist = [];

export default class Tms extends Component {

	constructor(props){
		super(props);
		this.state={
			allRowData: [],
			activeFilter: null,
			uploadDivWidth:'0%',
			sliderTranslate:'',
            sliderRouteTranslate:'',
			sliderTicketTranslate:'',
			inboxSlider:'',
			viewmailSlider:'',
			loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			overly:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
			basicTitle:'',
			showdiscard:'show-n',
			rownode:'',
			totaltypes:[],
			basicType:"default",
			modules: AllModules,
			defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true

      	    },
      	    rowData: null,
			contactrowData:null,
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
			  overlayNoRowsTemplate: "",
			  frameworkComponents: {
                customLoadingCellRenderer: CustomLoadingCellRenderer,
                sendemailticket:Sendemailticket,
                kpiActions:KpiActions,
				kpiTransitDelayActions:KpiTransitDelayActions,
				kpiNightDriveActions:KpiNightDriveActions,
				ticketkpienroute:Ticketkpienroute,
				ticketloadingaction:Ticketloadingaction,
				ticketunloadingaction:Ticketunloadingaction,
                nogps:NoGps,
                RoutemapAction:RoutemapAction,
                RoutemapTransitAction:RoutemapTransitAction,
				RoutemapenrouteAction:RoutemapenrouteAction,
				RouteNightDrivemap:RouteNightDrivemap,
				tickethistory:Tickethistory,
				invalidTicket:InvalidTicket,
				ticketmailview:Ticketmailview,
				TicketsMap:TicketsMap,
				Slowtrucks:Slowtrucks
			},

      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,
				
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			rowSelection:'multiple',
			detailCellRendererParams:{},
            opens:0,
			workinprogresses:0,
			transporter:0,
            closeds:0,
            onholds:0,
			autocloseds:0,
			closenoresponse:0,
			forceclosed:0,
            invalids:0,
            sidebarSubHeader:'',
            allCommunicationTypes: [],
			allTicketStatusTypes: [],
			com_code: null,
			ticket_status_code: null,
            ticket_comment: null,	
            routeTruck:'',
            googelRoutes:'',
            defTransitCoords:'',
            mapinfo:'',
			leg_no:0,
			transit:0,
			gps:0,
			overspeed:0,
			enroute:0,
			nightdrive:0,
			loading:0,
			unloading:0,
			showMailBtn:'show-n',
			showCommentBtn:'show-n',
			showCommentBtnb:'show-m',
			showMailBtnb:'show-m',
			alldepartments:[],
			dept_code:'all',
			originalData:[],
			ticketEmailContent: '',
			ticketSubject:'',
			toEmail:'',
			ccEMail:'',
			mail_escalation:'',
            SelectedTab: '1',
			toggleMore: false,
			emailformat:[],
			showleftmailside:'show-n',
			ticketMailID:'',
			ticketslist:[],
			commentTickets:[],
			reponsecomment:"",
			satisfactory:'',
			draftlist:[],
			emaillist:[],
			sendmaildiv:"col-xl-9",
			ticketHistoryLog:'',
			totalDrafts:'',
			title:'',
			isclosetickts:'',
			startDate:'',
			endDate:'',
			invalid_reason:'',
			invalidticketinfo:[],
			file:'',
			bulkslide:'',
			csvcontent:'',
			isinvalidtickets:'',
			consignercords:[],
			consigneecords:[],
			criticalTickets:"",
			checked:false,
			rowClassRules: {
				"boxbgblue5" : "data.ready_to_close == 1",
				"boxbgblue": "data.addressed_by_dct == 1",
				
			},
			transporterresponse:'',
			viewcols:"col-xl-12 col-md-12",
			viewshow:"show-n",
			mailresponse:[],
			transportercontact:'',
			transportcont:'',
			defaultsdate:'',
			defaultedate:'',
			usergridstate:[],
			screenurl:"",
			screentitle:"",
			pagetitle:"",
            eventGridAction: "gridAction",
			sliderTicketMapRoute : "",
			slow_trucks_data : [],
			ready_to_close_data : [],
        }
        this.communicationTypesDropdownOptions = this.communicationTypesDropdownOptions.bind(this);
		this.ticketStatusTypesDropdownOptions = this.ticketStatusTypesDropdownOptions.bind(this);
		this.onShowTicketDetails = this.onShowTicketDetails.bind(this);
        this.changeHandler=this.changeHandler.bind(this);
        this.onShowRouteDiv = this.onShowRouteDiv.bind(this);
		this.onShowRouteMap = this.onShowRouteMap.bind(this);
		this.onShowTransitRouteMap = this.onShowTransitRouteMap.bind(this);
		this.onShowGoogleRoute = this.onShowGoogleRoute.bind(this)
		this.mailToggle = this.mailToggle.bind(this);
		this.updateContent = this.updateContent.bind(this);
		this.onChangeHandler = this.onChangeHandler.bind(this);
		this.onShowTicketHistory = this.onShowTicketHistory.bind(this);
		this.onShowNoGPSRouteMap = this.onShowNoGPSRouteMap.bind(this);
		this.onShowEnrouteRouteMap = this.onShowEnrouteRouteMap.bind(this);
		this.uploadFormHandler = this.uploadFormHandler.bind(this);
		this.changeFileHandler = this.changeFileHandler.bind(this);
		this.onShowTicketSentEmail = this.onShowTicketSentEmail.bind(this);
		this.onLoadShowTMSData = this.onLoadShowTMSData.bind(this);
		this.onShowTicketsMap = this.onShowTicketsMap.bind(this);
	}

	componentDidMount(){
		var edate = moment.parseZone().format('YYYY-MM-DD');
		var sdate = moment.parseZone().subtract(2, 'days').format('YYYY-MM-DD');
        let communicationTypes = [
			{"com_code":"email", "com_name":"Email" }, 
		    {"com_code":"phone", "com_name":"Phone"}, 
			{"com_code":"other", "com_name":"Other"} 
		];
		let ticketStatusTypes = [
			{"ticket_status_code":"open", "ticket_status_name":"Open" }, 
		    {"ticket_status_code":"closed", "ticket_status_name":"Closed"}, 
		    {"ticket_status_code":"invalid", "ticket_status_name":"Invalid"}, 
		    {"ticket_status_code":"onhold", "ticket_status_name":"On Hold"}, 
			{"ticket_status_code":"closednoresponse", "ticket_status_name":"Closed - No response"} , 
			{"ticket_status_code":"other", "ticket_status_name":"Other"} 
		];
		this.setState({
			allCommunicationTypes:communicationTypes,
			allTicketStatusTypes:ticketStatusTypes,
			startDate:sdate,
			endDate:edate,
			defaultsdate:sdate,
			defaultedate:edate,
			loadshow:'show-m'
        });
		
		redirectURL.post("/tms/departments")
        .then((response) => {
			//console.log("response.data ", response.data)
			if(response.data)
			{
				this.setState({
					alldepartments:response.data
				});
			}
		});

		redirectURL.post("/tms/drafttickets")
        .then((response) => {
			//console.log("response.data ", response.data)
			if(response.data)
			{
				this.setState({
					totalDrafts:response.data.length
				});
			}
		});

		var urlpath = '';
        var dpt = '';
		var title = '';
		var isclosetickts='';
		var isinvalidtickets='';
		//console.log("tms ",this.props.match.path)
        if(this.props.match.path == "/ticketmanagementsystem")
		{
			urlpath = '/tms';
			title = 'Ticket Management System';
			isclosetickts='';
			isinvalidtickets='';
		}
		
        if(this.props.match.path == "/closetickets")
		{
			urlpath = '/tms/closetickets';
			
			title = 'Closed Tickets';
			isclosetickts=1;
			isinvalidtickets='';
		}
        if(this.props.match.path == "/invalidtickets")
		{
			urlpath = '/tms/invalidtickets';
			
			title = 'Invalid Tickets';
			isclosetickts='';
			isinvalidtickets=1;
		}
		//console.log(title);
		this.setState({
			title:title,
			isclosetickts:isclosetickts,
			isinvalidtickets:isinvalidtickets,
			pagetitle:title
		});
		var reprms = {
			startDate:sdate,
			endDate:edate,
			dept_code:'all'
		}
		
		redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname})
		.then( async (resp) => {
		//	console.log("resp ", resp)
			await this.setState({
				usergridstate:resp.data
			});
			await this.restoreGridStates();
		})


		this.onLoadShowTMSData(urlpath,reprms);
		
		this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});

	};

	logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
	onLoadShowTMSData(urlpath, parameters)
	{
		
		redirectURL.post(urlpath, parameters)
        .then((response) => {
            console.log("REsponse ", response.data)
            var records = response.data.records;
		   //console.log(records);
			var criticalTickets = response.data.criticalRecords;
			this.setState({
				criticalTickets : criticalTickets
			})
            var openarr=[];
            var workinprogressarr=[];
            var closedarr=[];
            var onholdarr=[];
            var autoclosedarr=[];
			var invalidarr=[];
			var closenoresponsearr=[];
			var forceclosearr=[];
			

            var transitarr=[];
            var gpsarr=[];
            var overspeedarr=[];
            var nightdrivearr=[];
            var enroutearr=[];
            var loadingarr=[];
			var unloadingarr=[];
			var transporterarr = [];
			var slow_trucks_data = [];
			var ready_to_close_data = [];

            if(records.length > 0)
            {
				var filtered = records.filter(function(e){
					return e.ready_to_close == 1;
				})
				console.log(filtered,"filtered")
                records.map((item) => {
					if(item.ready_to_close == 1)
					{
						ready_to_close_data.push(item)
					}
					if(item.ticket_type == "slow_trucks")
					{
						slow_trucks_data.push(item)
					}
                    if(item.ticket_status == 1)
                    {
                        openarr.push(item)
                    }
                    if(item.ticket_status == 2)
                    {
                        workinprogressarr.push(item)
                    }
                    if(item.ticket_status == 2 && item.transporter_commented == 1)
                    {
                        transporterarr.push(item)
                    }
                    if(item.ticket_status == 3)
                    {
                        closedarr.push(item)
                    }
                    if(item.ticket_status == 4)
                    {
                        onholdarr.push(item)
                    }
                    if(item.ticket_status == 5)
                    {
                        autoclosedarr.push(item)
                    }
                    if(item.ticket_status == 6)
                    {
                        invalidarr.push(item)
					}
					if(item.ticket_status == 7)
					{
						closenoresponsearr.push(item)
					}

					if(item.ticket_status == 8)
					{
						forceclosearr.push(item)
					}
					
                    if(item.ticket_type == 'transit_delay')
                    {
                        transitarr.push(item)
					}
					
                    if(item.ticket_type == 'no_gps')
                    {
                        gpsarr.push(item)
					}
					
                    if(item.ticket_type == 'overspeeding')
                    {
                        overspeedarr.push(item)
                    }
                    if(item.ticket_type == 'enroute_stoppage')
                    {
                        enroutearr.push(item)
                    }
                    if(item.ticket_type == 'night_driving')
                    {
                        nightdrivearr.push(item)
                    }
                    if(item.ticket_type == 'loading_delay')
                    {
                        loadingarr.push(item)
                    }
                    if(item.ticket_type == 'unloading_delay')
                    {
                        unloadingarr.push(item)
                    }
                });
            }

            this.setState({
				originalData:records,
				rowData:records,
				totaltypes:records.length,
                opens:openarr.length,
				workinprogresses:workinprogressarr.length,
				transporter:transporterarr.length,
                closeds:closedarr.length,
                onholds:onholdarr.length,
                autocloseds:autoclosedarr.length,
				invalids:invalidarr.length,
				closenoresponse:closenoresponsearr.length,
				forceclosed:forceclosearr.length,
                transit:transitarr.length,
                gps:gpsarr.length,
                overspeed:overspeedarr.length,
                enroute:enroutearr.length,
                nightdrive:nightdrivearr.length,
                loading:loadingarr.length,
				unloading:unloadingarr.length,
				slow_trucks_data : slow_trucks_data,
				ready_to_close_data : ready_to_close_data,
				loadshow:'show-n'
            });


        })
        .catch(function(e){
            console.log(e)
        })
	}

	// Display Ticket Details
	onShowTicketDetails = (e) =>{
		
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		console.log("IN  onShowTicketDetails, e = ", e);
		if ((e.transit_time) > 0)
		{
			var seconddate = new Date(new Date(e.gate_out_time).getTime() + ((e.transit_time+1) *24*60*60*1000));
		}
		else{
			var seconddate = new Date(new Date(e.gate_out_time).getTime() + 2*24*60*60*1000);
		}	
		var sdate = seconddate.getFullYear()+"-"+(seconddate.getMonth()+1)+"-"+seconddate.getDate()+" "+seconddate.getHours()+":"+seconddate.getMinutes()+":"+seconddate.getSeconds();
		var edate = seconddate.getFullYear()+"-"+(seconddate.getMonth()+1)+"-"+seconddate.getDate()+" "+seconddate.getHours()+":"+seconddate.getMinutes()+":"+seconddate.getSeconds();
		
		//sliderTicketDetails
		this.setState({
			sliderTicketDetails:"slider-translate-60p",
			showDiv:'show-m',
			//mapinfo:records,
			mapinfo:[],
			dealer:e.consignee_code,
			consignment_code:"Consignment : "+e.consignment_code,
			maptruckno:e.truck_no,
			routeTruck:	{"truck_no":e.truck_no,"startTime":sdate,"endTime": edate},
			loadshow:'show-n',
			sidebarSubHeader:"Consignment Ticket Details",
			overly:'show-m',
			rownode:e,
			leg_no:0
		});
	}
	
	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderTranslate:"",
			tabsliderTranslate:'',
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n'
		});
	}
	
	
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	
	//    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
    //    };
       
	    
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
	}
	

	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			sliderTicketMapRoute : "",
            sliderTicketTranslate:'',
			tabsliderTranslate:'',
			inboxSlider:'',
			bulkslide:'',
			viewmailSlider:"",
			viewcols:"col-xl-12 col-lg-12",
			viewshow:'show-n',
			transportcont:''
		});
		
	}

	closeAlert = () => {
        this.setState({
            show: false
        });
    }
    onClickSendEmail(data){
		console.log("Ticket Data ", data)
        this.setState({
			sliderTicketTranslate:"slider-translate-40p",
			showDiv:'show-m',
			loadshow:'show-n',
			sidebarSubHeader:"Consignment Ticket Details",
			overly:'show-m',
			rownode:data,
			commentTickets:[data.data.ticket_id]
		});
        //console.log("EMail ",data)
    }
    onClickCallNow(data){
		console.log("Telephone Call ",data)
		var ticketparams = {
			dept_code:data.data.dept_code,
			transporter_name:data.data.transporter_name
		}
		
		redirectURL.post("tms/transporterContact",ticketparams)
		.then((resp) => {
			console.log("Ticket info ", JSON.parse(resp.data.message));
			if(JSON.parse(resp.data.message).length > 0)
			{
				try{
					var output=JSON.parse(resp.data.message);
					this.setState({
						transportercontact:output,
						transportcont:"slider-translate-40p",
						overly:'show-m',
						contactrowData:output
					});		
				}
				catch(e){
					console.log("Error ",e);
					this.setState({
						transportercontact:[],
						transportcont:"slider-translate-40p",
						overly:'show-m',
						contactrowData:[]
					});	
				}
			}
			else
			{
				this.setState({
					transportercontact:[],
					transportcont:"slider-translate-40p",
					overly:'show-m',
					contactrowData:[]
				});	
			}
			
		})
		.catch(function(e){
			console.log("Error ",e);
		})
	}
	onClickCounterShowCriticalData(params){
		console.log(this.state.criticalTickets);
		this.gridApi.setRowData(this.state.criticalTickets);
		this.gridApi.onFilterChanged();
	}
	onClickCounterShowData(params){
		console.log("Counter Params: ", params);
		let filterInstance = null;
		let filterInstance2 = null;
		let filterActivated = false;
		this.gridApi.setFilterModel(null);
		this.gridApi.onFilterChanged();
		
        filterInstance = this.gridApi.getFilterInstance("ticket_status");
        filterActivated = true;
    
		
		//console.log("filterActivated ",filterActivated)
		if (filterActivated) {
			
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["ticket_status"]) {
                    if(params == 'open')
                    {
                        return rec["ticket_status"] == 1;
                    }
                    if(params == 'workinprogress')
                    {
                        return rec["ticket_status"] == 2;
					}
					

                    if(params == 'transporterreponse')
                    {
                        return (rec["ticket_status"] == 2 && rec["transporter_commented"] == 1 );
					}
					if(params == 'closed')
                    {
                        return rec["ticket_status"] == 3;
                    }
                    if(params == 'onhold')
                    {
                        return rec["ticket_status"] == 4;
                    }
                    if(params == 'autoclosed')
                    {
                        return rec["ticket_status"] == 5;
                    }
                    if(params == 'invalid')
                    {
                        return rec["ticket_status"] == 6;
                    }
                    if(params == 'closenoresponse')
                    {
                        return rec["ticket_status"] == 7;
                    }
                    if(params == 'forceclosed')
                    {
                        return rec["ticket_status"] == 8;
                    }
				}
			});
			if (isFilterDataAvailable.length) {
                //filterInstance.selectNothing();
                // if(params == 'open')
                // {
                //     filterInstance.selectValue(1);
				// }
                // if(params == 'workinprogress')
                // {
                //     filterInstance.selectValue(2);
				// }
                // if(params == 'closed')
                // {
                //     filterInstance.selectValue(3);
				// }
                // if(params == 'onhold')
                // {
                //     filterInstance.selectValue(4);
				// }
                // if(params == 'autoclosed')
                // {
                //     filterInstance.selectValue(5);
				// }
                // if(params == 'invalid')
                // {
                //     filterInstance.selectValue(6);
				// }
                // if(params == 'closenoresponse')
                // {
                //     filterInstance.selectValue(7);
				// }
                // if(params == 'forceclosed')
                // {
                //     filterInstance.selectValue(8);
				// }
				//filterInstance.applyModel();
				this.gridApi.setRowData(isFilterDataAvailable);
				this.gridApi.onFilterChanged();
			}
			else{
				// filterInstance.selectNothing();
				 //filterInstance.selectValue("");
				// filterInstance.applyModel();
				 this.gridApi.setRowData([]);
				this.gridApi.onFilterChanged();
			}
		 }
		 else{
			this.gridApi.setRowData(this.state.rowData);
			this.gridApi.onFilterChanged();
		 }
	}
	
	onClickTicketTypeCounterShowData(params){
		//console.log("Counter Params: ", params);
		let filterInstance = null;
		let filterInstance2 = null;
		let filterActivated = false;
		try{
			
			this.gridApi.setFilterModel(null);
			this.gridApi.onFilterChanged();
			this.setState({
				showMailBtn:'show-n',
				showCommentBtn:'show-n'
			});
			filterInstance = this.gridApi.getFilterInstance("ticket_type");
			filterActivated = true;
			if(params == 'all')
			{
				filterActivated = false;
			}
			try{
			//console.log("filterActivated ",filterActivated)
				var isFilterDataAvailable = [];
				if (filterActivated) {
					if(params != "slow_trucks_data" && params != "ready_to_close_data")
					{
						console.log(params, "counter type");
						isFilterDataAvailable = this.state.rowData.filter(rec => {
							// if (rec["ticket_type"]) {
							if(params == 'transitdelay')
							{
								return rec["ticket_type"] == "transit_delay";
							}
							if(params == 'nogps')
							{
								return rec["ticket_type"] == "no_gps";
							}
							if(params == 'overspeeding')
							{
								return rec["ticket_type"] == "overspeeding";
							}
							if(params == 'enroute')
							{
								return rec["ticket_type"] == "enroute_stoppage";
							}
							if(params == 'nightdrive')
							{
								return rec["ticket_type"] == "night_driving";
							}
							if(params == 'loadingdelay')
							{
								return rec["ticket_type"] == "loading_delay";
							}
							if(params == 'unloadingdelay')
							{
								return rec["ticket_type"] == "unloading_delay";
							}
							// }
						});
						console.log(isFilterDataAvailable, "isFilterDataAvailable")
					}
					else
					{
						
						isFilterDataAvailable = this.state[params]
					}
					console.log(isFilterDataAvailable, "isFilterDataAvailable")
					if (isFilterDataAvailable.length) {
						// filterInstance.selectNothing();
						
						// if(params == 'transitdelay')
						// {
						// 	filterInstance.selectValue("transit_delay");
						// }
						// if(params == 'nogps')
						// {
						// 	filterInstance.selectValue("no_gps");
						// }
						// if(params == 'overspeeding')
						// {
						// 	filterInstance.selectValue("overspeeding");
						// }
						// if(params == 'enroute')
						// {
						// 	filterInstance.selectValue("enroute_stoppage");
						// }
						// if(params == 'nightdrive')
						// {
						// 	filterInstance.selectValue("night_driving");
							
						// }
						// if(params == 'loadingdelay')
						// {
						// 	filterInstance.selectValue("loading_delay");
						// }
						// if(params == 'unloadingdelay')
						// {
						// 	filterInstance.selectValue("unloading_delay");
						// }
						
						// filterInstance.applyModel();
						this.gridApi.setRowData(isFilterDataAvailable);
						this.gridApi.onFilterChanged();
					}
					else{
						// filterInstance.selectNothing();
						//filterInstance.selectValue("");
						// filterInstance.applyModel();
						this.gridApi.setRowData([]);
						this.gridApi.onFilterChanged();
					}
				}
				else{
					console.log("Here")
					this.gridApi.setRowData(this.state.rowData);
					this.gridApi.onFilterChanged();
				}
			}
			catch(e){
				console.log("Counter Filter Error ", e)
			}
		}
		catch(e){
			console.log("Counter Filter Error ", e)
		}
    }

    communicationTypesDropdownOptions(){
		var options=[];
	
        this.state.allCommunicationTypes.map((item) =>{
            options.push(<option key={item.com_code} value={item.com_code}>{item.com_name}</option>)
        });
        return options;
    }
    ticketStatusTypesDropdownOptions(){
		var options=[];
	
        this.state.allTicketStatusTypes.map((item) =>{
            options.push(<option key={item.ticket_status_code} value={item.ticket_status_code}>{item.ticket_status_name}</option>)
        });
        return options;
    }
    changeHandler(event){
        let name = event.target.name;
        let value = event.target.value;
        this.setState({
            [name]:value
        });
    }
    
	selectOptionsItems() {
        let items = [];     
		//console.log("transporterslist ",this.state.alldepartments);
		items.push({value:'all',label:"All"}); 
        this.state.alldepartments.forEach(item => {
			items.push({value:item.dept_code,label:item.dept_name}); 
        });
        return items;
	}
	changeArrayHandler = async dept_code => {
    	
    	this.setState(
	      { dept_code },
	      () => console.log(`Option selected:`, this.state.dept_code)
		);
	}
    onRowSelected(event) {
        //console.log("row " + event.node.data.container_no+ " selected = " + event.node.selected);
       // var items=[];
	    var rowCount = event.api.getSelectedNodes();
        // console.log("rowCount ", rowCount)
	    // containerslist=[];
        // rowCount.map((item) => {
        //     containerslist.push(item.data.container_no)
		// })
		if(rowCount.length > 0)
		{
			this.setState({
				showMailBtn:'show-m',
				showCommentBtn:'show-m'
			});
		}
		else{
			this.setState({
				showMailBtn:'show-n',
				showCommentBtn:'show-n'
			});
		}
	}
	
	onShowDeptData = async () => {
		var records=[];
		this.setState({
			loadshow:'show-m'
		});
		//console.log("this.state.dept_code.value ", this.state.dept_code.value);
		// if(this.state.dept_code.value == 'all')
		// {
		// 	this.gridApi.setRowData(this.state.originalData);
		// 	this.gridApi.onFilterChanged();

		// 	records=this.state.originalData;
		// 	console.log("records ", records)
		// 	var openarr=[];
		// 		var workinprogressarr=[];
		// 		var closedarr=[];
		// 		var onholdarr=[];
		// 		var autoclosedarr=[];
		// 		var invalidarr=[];
				

		// 		var transitarr=[];
		// 		var gpsarr=[];
		// 		var overspeedarr=[];
		// 		var nightdrivearr=[];
		// 		var enroutearr=[];
		// 		var loadingarr=[];
		// 		var unloadingarr=[];
		// 		if(records.length > 0)
		// 		{
		// 			records.map((item) => {
		// 				if(item.ticket_status == 1)
		// 				{
		// 					openarr.push(item)
		// 				}
		// 				if(item.ticket_status == 2)
		// 				{
		// 					workinprogressarr.push(item)
		// 				}
		// 				if(item.ticket_status == 3)
		// 				{
		// 					closedarr.push(item)
		// 				}
		// 				if(item.ticket_status == 4)
		// 				{
		// 					onholdarr.push(item)
		// 				}
		// 				if(item.ticket_status == 5)
		// 				{
		// 					autoclosedarr.push(item)
		// 				}
		// 				if(item.ticket_status == 6)
		// 				{
		// 					invalidarr.push(item)
		// 				}
						
		// 				if(item.ticket_type == 'transit_delay')
		// 				{
		// 					transitarr.push(item)
		// 				}
						
		// 				if(item.ticket_type == 'no_gps')
		// 				{
		// 					gpsarr.push(item)
		// 				}
						
		// 				if(item.ticket_type == 'overspeeding')
		// 				{
		// 					overspeedarr.push(item)
		// 				}
		// 				if(item.ticket_type == 'enroute_stoppage')
		// 				{
		// 					enroutearr.push(item)
		// 				}
		// 				if(item.ticket_type == 'night_driving')
		// 				{
		// 					nightdrivearr.push(item)
		// 				}
		// 				if(item.ticket_type == 'loading_delay')
		// 				{
		// 					loadingarr.push(item)
		// 				}
		// 				if(item.ticket_type == 'unloading_delay')
		// 				{
		// 					unloadingarr.push(item)
		// 				}
		// 			});
		// 		}

		// 		this.setState({
		// 			rowData:records,
		// 			totaltypes:records.length,
		// 			opens:openarr.length,
		// 			workinprogresses:workinprogressarr.length,
		// 			closeds:closedarr.length,
		// 			onholds:onholdarr.length,
		// 			autocloseds:autoclosedarr.length,
		// 			invalids:invalidarr.length,
		// 			transit:transitarr.length,
		// 			gps:gpsarr.length,
		// 			overspeed:overspeedarr.length,
		// 			enroute:enroutearr.length,
		// 			nightdrive:nightdrivearr.length,
		// 			loading:loadingarr.length,
		// 			unloading:unloadingarr.length
		// 		});

		// }
		// else{
			
			if(this.state.startDate == '' || this.state.endDate == '')
			{
				var actiontype = 0;
			}
			else
			{
				actiontype=1;
			}
			if(this.state.dept_code == 'all')
			{
				var selectdept = 'all';
			}
			else
			{
				var selectdept = this.state.dept_code.value;
			}
			if(this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN")
			{
				var sdate = this.state.defaultsdate;
			}
			else{
				var sdate = this.state.startDate;
			}

			if(this.state.endDate == '' || this.state.endDate == "NaN-NaN-NaN")
			{
				var edate = this.state.defaultedate;
			}
			else{
				var edate = this.state.endDate;
			}

			var reqparms = {
				startDate:sdate,
				endDate:edate,
				dept_code:selectdept,
				//actiontype:actiontype
			}
			var urlpath='';
			// if(this.props.match.path == "/ticketmanagementsystem")
			// {
			// 	urlpath = '/tms/departementtickets';
				
			// }
			
			// if(this.props.match.path == "/closetickets")
			// {
			// 	urlpath = '/tms/departmentclosetickets';
				
			// }
			// if(this.props.match.path == "/invalidtickets")
			// {
			// 	urlpath = '/tms/departmentinvalidtickets';
				
			// }

			if(this.props.match.path == "/ticketmanagementsystem")
			{
				urlpath = '/tms';
			}
			
			if(this.props.match.path == "/closetickets")
			{
				urlpath = '/tms/closetickets';
				
			}
			if(this.props.match.path == "/invalidtickets")
			{
				urlpath = '/tms/invalidtickets';
				
			}
			
			this.onLoadShowTMSData(urlpath, reqparms)



			
			// redirectURL.post(urlpath, reqparms)
			// .then((response) => {
			// 	console.log("departementtickets ", response.data)
			// 	var records = response.data.records;

			// 	console.log("records dept ", records)

			// 	var openarr=[];
			// 	var workinprogressarr=[];
			// 	var closedarr=[];
			// 	var onholdarr=[];
			// 	var autoclosedarr=[];
			// 	var invalidarr=[];
			// 	var closenoresponsearr=[];
			// 	var forceclosearr=[];
				

			// 	var transitarr=[];
			// 	var gpsarr=[];
			// 	var overspeedarr=[];
			// 	var nightdrivearr=[];
			// 	var enroutearr=[];
			// 	var loadingarr=[];
			// 	var unloadingarr=[];
			// 	var transporterarr = [];
			// 	if(records.length > 0)
			// 	{
			// 		records.map((item) => {
			// 			if(item.ticket_status == 1)
			// 			{
			// 				openarr.push(item)
			// 			}
			// 			if(item.ticket_status == 2)
			// 			{
			// 				workinprogressarr.push(item)
			// 			}

			// 			if(item.ticket_status == 2 && item.transporter_commented == 1)
			// 			{
			// 				transporterarr.push(item)
			// 			}
			// 			if(item.ticket_status == 3)
			// 			{
			// 				closedarr.push(item)
			// 			}
			// 			if(item.ticket_status == 4)
			// 			{
			// 				onholdarr.push(item)
			// 			}
			// 			if(item.ticket_status == 5)
			// 			{
			// 				autoclosedarr.push(item)
			// 			}
			// 			if(item.ticket_status == 6)
			// 			{
			// 				invalidarr.push(item)
			// 			}
			// 			if(item.ticket_status == 7)
			// 			{
			// 				closenoresponsearr.push(item)
			// 			}

			// 			if(item.ticket_status == 8)
			// 			{
			// 				forceclosearr.push(item)
			// 			}
			// 			if(item.ticket_type == 'transit_delay')
			// 			{
			// 				transitarr.push(item)
			// 			}
						
			// 			if(item.ticket_type == 'no_gps')
			// 			{
			// 				gpsarr.push(item)
			// 			}
						
			// 			if(item.ticket_type == 'overspeeding')
			// 			{
			// 				overspeedarr.push(item)
			// 			}
			// 			if(item.ticket_type == 'enroute_stoppage')
			// 			{
			// 				enroutearr.push(item)
			// 			}
			// 			if(item.ticket_type == 'night_driving')
			// 			{
			// 				nightdrivearr.push(item)
			// 			}
			// 			if(item.ticket_type == 'loading_delay')
			// 			{
			// 				loadingarr.push(item)
			// 			}
			// 			if(item.ticket_type == 'unloading_delay')
			// 			{
			// 				unloadingarr.push(item)
			// 			}
			// 		});
			// 	}

			// 	this.setState({
			// 		rowData:records,
			// 		totaltypes:records.length,
			// 		opens:openarr.length,
			// 		workinprogresses:workinprogressarr.length,
			// 		transporter:transporterarr.length,
			// 		closeds:closedarr.length,
			// 		onholds:onholdarr.length,
			// 		autocloseds:autoclosedarr.length,
			// 		invalids:invalidarr.length,
			// 		closenoresponse:closenoresponsearr.length,
			// 		forceclosed:forceclosearr.length,
			// 		transit:transitarr.length,
			// 		gps:gpsarr.length,
			// 		overspeed:overspeedarr.length,
			// 		enroute:enroutearr.length,
			// 		nightdrive:nightdrivearr.length,
			// 		loading:loadingarr.length,
			// 		unloading:unloadingarr.length,
			// 		loadshow:'show-n'
			// 	});

			// })
			// .catch(function(e){
			// 	console.log("Error ",e)
			// });
	
			// console.log("this.state.dept_code.value ", this.state.dept_code.value);
			// var filterComponent = this.gridApi.getFilterInstance("dept_code");
			// filterComponent.selectNothing();
			// filterComponent.selectValue(this.state.dept_code.value);
			// filterComponent.applyModel();
			// let isFilterDataAvailable = this.state.originalData.filter(rec => {
			// 	if (rec["dept_code"]) {
			// 		return rec["dept_code"] == this.state.dept_code.value;
			// 	}
			// });
			// console.log("isFilterDataAvailable ", isFilterDataAvailable)
			// this.gridApi.setRowData(isFilterDataAvailable);
			// this.gridApi.onFilterChanged();
			
			// records=isFilterDataAvailable;
		//}
	
	}
	
    formHandler(event){
        event.preventDefault();
        //console.log("event.target = ", event.target);
        //console.log("event = ", event);
       
        var data = new FormData();
        data.append('com_code',this.state.com_code)
        data.append('ticket_status_code',this.state.ticket_status_code)
		data.append('ticket_comment',this.state.ticket_comment)
		if(this.state.ticket_status_code == 'closed' && this.state.satisfactory == '')
		{
			this.setState({
				show: true,
				basicTitle:"Satisfactory field should not be empty when status is close or closed no response",
				basicType:"danger"
			});
		}
		else{
			var transporterresponse="No";
			if(this.state.transporterresponse == '')
			{
				transporterresponse="No";
			}
			else
			{
				transporterresponse="Yes";
			}
			var fomrdata = {
				ticket_id:this.state.commentTickets,
				userId : localStorage.getItem('userid'),
				communication_type:this.state.com_code,
				ticket_status:this.state.ticket_status_code,
				satisfactory:this.state.satisfactory,
				reponsecomment:this.state.reponsecomment,
				ticket_comment:this.state.ticket_comment,
				transporterresponse:transporterresponse
			}
			//console.log("data = ", data);
		// console.log("Form data ",data);
			redirectURL.post('/tms/saveticketcomment',fomrdata)
			.then((response) => {
				this.setState({
					formMessage:response.data.message,
					formStatus:response.data.status,
					alertFade:false,
					com_code:'',
					ticket_status_code:'',
					ticket_comment:'',
					commentTickets:'',
					reponsecomment:"",
					satisfactory:'',
					transporterresponse:'',
					sliderTicketTranslate:'',
					overly:'show-n',
					loadshow:'show-n'
				});      
				this.gridApi.deselectAll();  
			})
			.catch((error) => {
				console.log(error)
			})
		}
    }
	//No GPS
	onShowNoGPSKpi = async (params) => {
		//console.log(params);
		
		//console.log(params.column.colId);
		if(params != 0)
		{
			console.log("Consign onShowNoGPSKpi params = ",params);
			var ticketparams = {
				ticket_id:params.data.ticket_number
			}
			
			redirectURL.post("tms/ticket",ticketparams)
			.then((resp) => {
				//console.log("Ticket info ", resp.data);
				var consignercords = JSON.parse(resp.data[0].consigner_locations[0]);
				//console.log("consignercords " , consignercords);

				var consigneecords = resp.data[0].consignee_locations;
				//console.log("consignee_locations " , consigneecords)
				this.setState({
					consignercords:consignercords,
					consigneecords:consigneecords
				});
			})
			.catch(function(e){
				console.log("Error ",e);
			})
			var transitedelaydata=[];
			
			await this.setState({
					
				detailCellRendererParams:{
					suppressRefresh: true,
					detailGridOptions: {
						columnDefs: [
							{ headerName: "",field: "_id", 
							
								cellRendererFramework:RoutemapNoGPSAction,
								width:50,
								suppressSizeToFit: true,
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}
									
								}
							},
							
							{ headerName: "Truck No",field: "truck_no", width:100,resizable: true,
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}
									
								}
							},
							{ 
								headerName: "Last Data Receieved",
								field: "last_packet_datetime", 
								width:180,
								resizable: true,
								valueGetter:function(params){
									return getDDMMYYYYHHMMSS(params.data.last_packet_datetime);
								},
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}
									
								}
							},
							{ 
								headerName: "Data Received on",
								field: "packet_received_on", 
								width:180,
								resizable: true,
								valueGetter:function(params){
									return getDDMMYYYYHHMMSS(params.data.packet_received_on);
								},
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}
									
								}
						 	},
							 { 
								 headerName: "Last Packet Latitude",
								 field: "last_packed_latitude", 
								 width:180,
								 resizable: true,
								 
							  },
							  { 
								  headerName: "Last Packet Longitude",
								  field: "last_packed_longitude", 
								  width:180,
								  resizable: true,
								 
							   }						
						],
						overlayNoRowsTemplate: 'No data available',
												
					},
					getDetailRowData: function(param) {
						//console.log("Step 4 ",transitedelaydata)
						//console.log("Step 4 ",param)
						redirectURL.post('/tms/nogpsdata', {
							consignment_code:param.data.consignment_codes, 
							truck_no:param.data.truck_no,
							last_packet_datetime:param.data.last_packet_datetime,
							ticket_id:param.data.ticket_id
						})
						.then(async (response) =>{
							//console.log("Step 1 ",response.data)
							transitedelaydata=response.data;
							var legsarr=[]
							transitedelaydata.map((item) => {
								//if(item.trip_completed < 2)
								//{
									if(item.packet_received_on != '' && item.packet_received_on != undefined)
									{
										var packetreceived=item.packet_received_on;
									}
									else{
										var packetreceived=moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
									}
									var legdata={
										_id:item._id,
										consignment_codes:item.consignment_codes,
										exception_closed:item.exception_closed,
										truck_no:item.truck_no,
										last_packed_latitude:item.last_packed_latitude,
										last_packed_longitude:item.last_packed_longitude,
										last_packet_datetime:item.last_packet_datetime,
										ready_to_notify:item.ready_to_notify,
										packet_received_on:packetreceived,
										reset_packet_latitude:item.reset_packet_latitude,
										reset_packet_longitude:item.reset_packet_longitude,
										no_gps_ticket:item.no_gps_ticket,
									}
								legsarr.push(legdata)
								//}
							})
							console.log('transitedelaydata ', transitedelaydata);

							param.successCallback(legsarr);
							//console.log("Step 2 ",response.data)
							
						});
						
					}
				}
			});
			
			//if( params.column.colId == 'transit_delay' || params.column.colId == 'transit_delay_1' ||   params.column.colId == 'no_gps_data' || params.column.colId == 'no_gps_data_1')
			if(params.column.colDef.field == 'transit_delay' || params.column.colDef.field == 'ticket_type' || params.column.colDef.field == 'no_gps')
			{
				params.node.setExpanded(!params.node.expanded);
				//console.log("expanded ",params.node.expanded)
				if(params.node.expanded == false)
				{
					transitedelaydata=[]
				}
			}
			else{

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
			
		}
		else{
			this.setState({
			
				detailCellRendererParams:{
					detailGridOptions: {
					  columnDefs: [
						{ headerName: "Truck No",field: "truck_no" },
						{ headerName: "Speed (km/h)",field: "speed" },
						{ headerName: "Reported At",field: "first_instance" }
					]
					  
					},
					getDetailRowData: function(params) {
						//console.log("Child ", params);
					  params.successCallback([]);
					}
				  }
			});
		}
		
	}

	//NO GPS Map
	onShowNoGPSRouteMap(rownode){
		console.log("rownode", rownode);
		this.setState({
			loadshow:'show-m'
		})
		// var fintance = rownode.last_packet_datetime.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		// var eintance = rownode.packet_received_on.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		
		var fintance = getHyphenYYYYMMDDHHMMSS(rownode.last_packet_datetime);
		var eintance = getHyphenYYYYMMDDHHMMSS(rownode.packet_received_on);

		var params = { 
			truck_no:rownode.truck_no,
			consignment_code:rownode.consignment_code,
			//consignee_code:rownode.consignee_code,
            first_instance:fintance,
			recent_instance:eintance,
			screen:"consignment"
		}
		 redirectURL.post('/tms/nightdriveroutes',params)
		  .then((response) => {	
		  
		   //var records = JSON.parse(JSON.stringify(response)).data;
			//console.log("Inner Grid ",response.data);
			if(response.data.coords.length == 0)
			{
				this.setState({
					show: true,
					basicTitle:'No Route data available',
					basicType:"danger",
					loadshow:'show-n'
				});
			}
			else{
				var sdate='';
				var edate='';

				if(rownode.last_packet_datetime != '' && rownode.last_packet_datetime != undefined)
				{
					sdate=getHyphenYYYYMMDDHHMMSS(rownode.last_packet_datetime);
				}
				if(rownode.packet_received_on != '' && rownode.packet_received_on != undefined)
				{
					edate=getHyphenYYYYMMDDHHMMSS(rownode.packet_received_on)
				}
			this.setState({
					mapinfo:response.data,
					dealer:rownode.consignee_code,
					sliderRouteTranslate:"slider-translate-50p",
					defTransitCoords: rownode.consignee_coordinates,
					consignment_code:"Consignment : "+rownode.truck_no,
					maptruckno:rownode.truck_no,
					routeTruck:	{"truck_no":rownode.truck_no,"startTime":sdate,"endTime": edate},
					loadshow:'show-n',
					sidebarSubHeader:"Consignment Leg Information",
					overly:'show-m',
					rownode:rownode,
					leg_no:rownode.leg_no

			});

			this.onShowGoogleRoute(rownode.consignment_code, rownode.consignee_code, rownode.leg_no, rownode.truck_no)
				//this.renderMap();
			
			} 
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
		  
	}

	//Transit Delay
    onShowTransitKpi = async (paramsdata) => {
		//console.log(e);
		console.log("Consign onShowTransitKpi params = ",paramsdata);
		// var tdata = {
		// 	ticket_id:params.data.ticket_id
		// } 
		// console.log("tdata ", tdata)
		// var consignmentinfo = await redirectURL.post("/consignments/consignmentinfo", tdata)
		// .then((resp) => {
		// 	console.log("Resp ",resp)
		// });
		// console.log(consignmentinfo);
		if(paramsdata != 0)
		{
			var ticketparams = {
				ticket_id:paramsdata.data.ticket_number
			}
			
			redirectURL.post("tms/ticket",ticketparams)
			.then((resp) => {
				//console.log("Ticket info ", resp.data);
				var consignercords = JSON.parse(resp.data[0].consigner_locations[0]);
				//console.log("consignercords " , consignercords);

				var consigneecords = resp.data[0].consignee_locations;
				//console.log("consignee_locations " , consigneecords)
				this.setState({
					consignercords:consignercords,
					consigneecords:consigneecords
				});
			})
			.catch(function(e){
				console.log("Error ",e);
			})
			var transitedelaydata=[];
			
			await this.setState({
					
				detailCellRendererParams:{
					suppressRefresh: true,
					detailGridOptions: {
						headerHeight: 70,
						columnDefs: [
							{ 
								headerName: "",field: "_id", 
								headerClass:["agrowheight"],
								cellRendererFramework:RoutemapTransitAction,
								width:50,
								suppressSizeToFit: true,
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}
									
								}
							},
							{ 
								headerName: "Truck No",
								headerClass:["agrowheight"],
								field: "truck_no", 
								width:100,
								resizable: true,
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}
									
								}
							},
							{ 
								headerName: "Leg Start Time",
								headerClass:["agrowheight"],
								field: "leg_start",
								width:180,
								resizable: true,
								valueGetter:function(params){
									return getDDMMYYYYHHMMSS(params.data.leg_start);
								},
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}
									
								}
							},
							{ 
								headerName: "Leg End Time",
								headerClass:["agrowheight"],
								field: "leg_end_eta", 
								width:180,
								resizable: true,
								valueGetter:function(params){
									return getDDMMYYYYHHMMSS(params.data.leg_end_eta);
								},
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}
									
								}
						 	},
							{ 
								headerName: "Exp. Leg Distance (KM)",
								headerClass:["agrowheight"],
								field: "leg_expected_distance", 
								width:140,
								resizable: true,
								valueGetter:function(params){
									return Math.round(params.data.leg_expected_distance);
								},
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}
									
								}
							},
							{ 
								headerName: "Actual Leg Distance Covered (KM)",
								headerClass:["agrowheight"],
								field: "actual_leg_distance",
								width:140,
								resizable: true,
								valueGetter:function(params){
									//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
									if(params.data.actual_leg_distance != '' && params.data.actual_leg_distance != undefined)
									{
										return Math.round(params.data.actual_leg_distance);
									}
									else{
										return 0;
									}
									
								},
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}
									
								}
							},							
							{ 
								headerName: "Exp. Distance from Trip Start(KM)",
								headerClass:["agrowheight"],
								field: "expected_distance_start_to_leg",
								width:140,
								resizable: true,
								valueGetter:function(params){
									//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
									if(params.data.expected_distance_start_to_leg != '' && params.data.expected_distance_start_to_leg != undefined)
									{
										return Math.round(params.data.expected_distance_start_to_leg);
									}
									else{
										return 0;
									}
									
								},
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}
									
								}
							
							},
							{ 
								headerName: "Actual Distance from Trip Start (KM)",
								headerClass:["agrowheight"],
								field: "actual_distance_traveled_start_to_leg",
								width:140,
								resizable: true,
								valueGetter:function(params){
									//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
									if(params.data.actual_distance_traveled_start_to_leg != '' && params.data.actual_distance_traveled_start_to_leg != undefined)
									{
										return Math.round(params.data.actual_distance_traveled_start_to_leg);
									}
									else{
										return 0;
									}
									
								},
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}
									
								}
							
						
							},
							
							{ 
								headerName: "Google Distance from Start (KM)",
								headerClass:["agrowheight"],
								field: "actual_start_to_leg_google_distance", 
								width:140,
								resizable: true,
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}
									
								}
							
						
							},
							{ 
								headerName: "GPS Data Available",
								headerClass:["agrowheight"],
								field: "no_gps_data", 
								width:140,
								resizable: true,
								valueGetter:function(params){
									//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
									if(params.data.no_gps_data == 1)
									{
										return "Not Available";
									}
									if(params.data.no_gps_data == 0)
									{
										return "Available";
									}
									
								},
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}
									
								}
							
						
							},
							
							
							{ 
								headerName: "Trip Completed",
								headerClass:["agrowheight"],
								field: "trip_completed", 
								width:140,
								resizable: true,
								valueGetter:function(params){
									//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
									if(params.data.trip_completed == 1)
									{
										return "Yes";
									}
									if(params.data.trip_completed == 2)
									{
										return "";
									}
									if(params.data.trip_completed == 0)
									{
										return "";
									}
									
								},
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}
									
								}
							
						
							}							
						],
						overlayNoRowsTemplate: 'No data available',
												
					},
					getDetailRowData: function(params) {
						//console.log("Step 4 ",transitedelaydata)
						//console.log("Step 4 ",params)
						redirectURL.post('/tms/tickettransitdelay', {
							consignment_code:params.data.consignment_codes,
							consignee_code:params.data.consignee_codes,
							truck_no:params.data.truck_no,
							leg_no:params.data.leg_no
						})
						.then(async (response) =>{
							//console.log("Step 1 ",response.data)
							transitedelaydata=response.data;
							var legsarr=[]
							transitedelaydata.map((item) => {
								//if(item.trip_completed < 2)
								//{
								legsarr.push(item)
								//}
							})
							//console.log('transitedelaydata ', transitedelaydata);

							params.successCallback(legsarr);
							//console.log("Step 2 ",response.data)
							
						});
						
					}
				}
			});
			
			//if( params.column.colId == 'transit_delay' || params.column.colId == 'transit_delay_1' ||   params.column.colId == 'no_gps_data' || params.column.colId == 'no_gps_data_1')
			if(paramsdata.column.colDef.field == 'ticket_type')
			{
				paramsdata.node.setExpanded(!paramsdata.node.expanded);
				//console.log("expanded ",params.node.expanded)
				if(paramsdata.node.expanded == false)
				{
					transitedelaydata=[]
				}
			}
			else{

				paramsdata.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
			
		}
		else{
			this.setState({
			
				detailCellRendererParams:{
					detailGridOptions: {
					  columnDefs: [
						{ headerName: "Truck No",field: "truck_no" },
						{ headerName: "Speed (km/h)",field: "speed" },
						{ headerName: "Reported At",field: "first_instance" }
					]
					  
					},
					getDetailRowData: function(params) {
						//console.log("Child ", params);
					  params.successCallback([]);
					}
				  }
			});
		}
		
    }
	
	//Overspeed
    onShowOverspeedKpi = async (params) => {
		//console.log(e);
		console.log("Consign Overspeed ",params);
		//console.log(params.column.colId);
		if(params != 0)
		{
			var ticketparams = {
				ticket_id:params.data.ticket_number
			}
			
			redirectURL.post("tms/ticket",ticketparams)
			.then((resp) => {
				//console.log("Ticket info ", resp.data);
				var consignercords = JSON.parse(resp.data[0].consigner_locations[0]);
				console.log("consignercords " , consignercords);

				var consigneecords = resp.data[0].consignee_locations;
				console.log("consignee_locations " , consigneecords)
				this.setState({
					consignercords:consignercords,
					consigneecords:consigneecords
				});
			})
			.catch(function(e){
				console.log("Error ",e);
			})
			var overspeedData=[];
			
			//console.log("Step 3 ",overspeedData)
			var propsdata = params;
			await this.setState({
					
				detailCellRendererParams:{
					detailGridOptions: {
						columnDefs: [
							{ headerName: "",field: "_id", 
								width:50,
								cellRendererFramework:RoutemapAction,
								resizable: true
							},
							{ headerName: "Truck No",field: "truck_no", filter:true,resizable: true },
							{ headerName: "Speed (km/h)",field: "speed", filter:true,resizable: true },
							{ headerName: "Reported At",field: "first_instance", filter:true,resizable: true,
								valueGetter:function(params){
									return getDDMMYYYYHHMMSS(params.data.first_instance);
								}
							},
						],
													
					},
					getDetailRowData: function(param) {
						console.log("Step 4 ",param)
						redirectURL.post('/tms/ticketoverspeed', {
							consignment_code:param.data.consignment_codes,
							truck_no:param.data.truck_no,
							first_instance:param.data.first_instance
						})
						.then(async (response) =>{
							//console.log("Step 1 ",response.data)
							overspeedData=response.data;
							param.successCallback(overspeedData);
							//console.log("Step 2 ",response.data)
							
						});
						 
					},
					masterDetail: true
				}
			});
			
			if(propsdata.column.colDef.field == 'ticket_type')
			{

				propsdata.node.setExpanded(!propsdata.node.expanded);
			}
			else{

				propsdata.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
			
		}
		else{
			this.setState({
			
				detailCellRendererParams:{
					detailGridOptions: {
					  columnDefs: [
						{ headerName: "Truck No",field: "truck_no" },
						{ headerName: "Speed",field: "speed" },
						{ headerName: "Reported At",field: "first_instance" }
					]
					  
					},
					getDetailRowData: function(params) {
						//console.log("Child ", params);
					  params.successCallback([]);
					}
				  }
			});
		}
		
	}

	//Night Drvie
	onShowNightDriveKpi = async (params) => {
		//console.log(e);
		console.log("Night Drive ",params);
	
		if(params != 0)
		{
			var ticketparams = {
				ticket_id:params.data.ticket_number
			}
			redirectURL.post("tms/ticket",ticketparams)
			.then((resp) => {
				//console.log("Ticket info ", resp.data);
				var consignercords = JSON.parse(resp.data[0].consigner_locations[0]);
				//console.log("consignercords " , consignercords);

				var consigneecords = resp.data[0].consignee_locations;
				//console.log("consignee_locations " , consigneecords)
				this.setState({
					consignercords:consignercords,
					consigneecords:consigneecords
				});
			})
			.catch(function(e){
				console.log("Error ",e);
			})

			var overspeedData=[];
			

			//console.log("Step 3 ",overspeedData)
			
			await this.setState({
					
				detailCellRendererParams:{
					detailGridOptions: {
						columnDefs: [
							{ headerName: "",field: "_id", 
							
								cellRendererFramework:RouteNightDrivemap,
								width:50,
								suppressSizeToFit: true
							},
							{ headerName: "Truck No",field: "truck_no", width:150,resizable: true },
							{ headerName: "Traveled From",field: "first_instance", width:180,resizable: true,
								valueGetter:function(params){
									return getDDMMYYYYHHMMSS(params.data.first_instance);
								}
							},
							{ headerName: "Traveled Until",field: "recent_instance",resizable: true ,
								valueGetter:function(params){
									return getDDMMYYYYHHMMSS(params.data.recent_instance);
								}
							},
							{ headerName: "Distance Traveled (km)",field: "distance_from_first_instance", width:180,
								resizable: true,
								valueGetter:function(params){
									return Math.round(params.data.distance_from_first_instance)
								}
							}							
						],
									
					},
					getDetailRowData: function(params) {
						//console.log("Step 4 ",overspeedData)
						redirectURL.post('/tms/ticketnightdriving', {
							consignment_code:params.data.consignment_codes,
							//gate_out_time:params.data.gate_out_time, 
							truck_no:params.data.truck_no,
							first_instance:params.data.first_instance
						})
						.then(async (response) =>{
							//console.log("Step 1 ",response.data)
							overspeedData=response.data;
							params.successCallback(overspeedData);
							//console.log("Step 2 ",response.data)
							
						});
						
					}
					
				}
			});
			
			//if(params.column.colId == 'nightdriving_exception')
			if(params.column.colDef.field == 'ticket_type')
			{

				params.node.setExpanded(!params.node.expanded);
			}
			else{

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
			
		}
		else{
			this.setState({
			
				detailCellRendererParams:{
					detailGridOptions: {
					  columnDefs: [
						{ headerName: "Truck No",field: "truck_no" },
						{ headerName: "Speed (km/h)",field: "speed" },
						{ headerName: "Reported At",field: "first_instance" }
					]
					  
					},
					getDetailRowData: function(params) {
						//console.log("Child ", params);
					  params.successCallback([]);
					}
				  }
			});
		}
		
	}

	//Enroute Stoppage
	onShowEnrouteStoppagesKpi = async (params) => {
		//console.log(e);
		console.log("Enroute  ",params);
	
		if(params != 0)
		{
			var ticketparams = {
				ticket_id:params.data.ticket_number
			}
			redirectURL.post("tms/ticket",ticketparams)
			.then((resp) => {
				//console.log("Ticket info ", resp.data);
				var consignercords = JSON.parse(resp.data[0].consigner_locations[0]);
				//console.log("consignercords " , consignercords);

				var consigneecords = resp.data[0].consignee_locations;
				//console.log("consignee_locations " , consigneecords)
				this.setState({
					consignercords:consignercords,
					consigneecords:consigneecords
				});
			})
			.catch(function(e){
				console.log("Error ",e);
			})
			var overspeedData=[];
			

			//console.log("Step 3 ",overspeedData)
			
			await this.setState({
					
				detailCellRendererParams:{
					detailGridOptions: {
						columnDefs: [
							{ headerName: "",field: "_id", 
							
								cellRendererFramework:RoutemapenrouteAction,
								width:50,
								suppressSizeToFit: true,
								
							},
							{ 
								headerName: "Break Start",
								field: "stoppage_start_time", 
								width:150,
								resizable: true,
								valueGetter:function(params){
									if(params.data.stoppage_start_time != undefined)
									{
										return getDDMMYYYYHHMMSS(params.data.stoppage_start_time)
									}
									else{
										return '';
									}
									
								}
						    },
							
							{ 
								headerName: "Break Time",
								field: "break_time",
								width:180,
								resizable: true
							},
							{ 
								headerName: "Last Update on",
								field: "break_end", 
								width:220,
								resizable: true,
								valueGetter:function(params){
									if(params.data.break_end != undefined)
									{
										return params.data.break_end
									}
									else{
										return '';
									}
									
								}
							},
							{ 
								headerName: "Last Packet Latitude",
								field: "last_packet_latitude", 
								width:180,
								resizable: true,
								
							 },
							 { 
								 headerName: "Last Packet Longitude",
								 field: "last_packet_longitude", 
								 width:180,
								 resizable: true,
								
							  }
						]							
					},
					getDetailRowData: async function(params) {
						//console.log("Step 4 ",overspeedData)
						if(params.data.stoppage_start_time)
						{
							var sstartime = params.data.stoppage_start_time
						}
						else
						{
							var sstartime = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
						}
						redirectURL.post('/tms/ticketenroutes', {
							//consignment_code:params.data.consignment_codes,
							//consignee_code:params.data.consignee_codes[0],
							truck_no:params.data.truck_no,
							stoppage_start_time:sstartime
						})
						.then(async (response) =>{
							//console.log("Step 1 ",response.data)
							overspeedData=response.data;
							var endata = []
							if(overspeedData.length > 0)
							{
								overspeedData.map((item) => {
									var cdatetime = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
									console.log("Reset ",item.reset_datetime)
									console.log("Stoppage ",item.stoppage_start_time)
									//console.log(item.hasOwnProperty('reset_datetime'));
									if(item.hasOwnProperty('reset_datetime') === false)
									{
										var cdatetme = moment.parseZone(cdatetime).format("X");
										var stoptime = moment.parseZone(item.stoppage_start_time).format('YYYY-MM-DD HH:mm:ss');
										
										var etne = moment.parseZone(stoptime).format("X");
										if(cdatetme < etne)
										{
											var brtime = etne-cdatetme;
										
										}
										else
										{
											var brtime = cdatetme-etne;
										
										}
										var breaktime = secondsToString(brtime); 
										var break_end = moment.parseZone(cdatetime).format('DD-MM-YYYY HH:mm:ss');
										// console.log("cdatetime ", cdatetime);
										 console.log("cdatetme ", cdatetme);
										// console.log("stoppage_start_time ", stoptime);
										// console.log("etne ",etne);
									}
									else
									{
										if(item.reset_datetime < item.stoppage_start_time)
										{
											var cdatetme = moment.parseZone(cdatetime).format("X");
											var etne = moment.parseZone(item.stoppage_start_time).format("X");
											var brtime = cdatetme-etne;
											var breaktime = secondsToString(brtime); 
											var break_end = moment.parseZone(cdatetime).format('DD-MM-YYYY HH:mm:ss');
											console.log("cdatetme 1", cdatetme);
											console.log("etne 1", etne);
										}
										else{
											
											var cdatetme = moment.parseZone(item.reset_datetime).format("X");
											var etne = moment.parseZone(item.stoppage_start_time).format("X");
											var brtime = cdatetme-etne;
											var breaktime = secondsToString(brtime); 
											var break_end = moment.parseZone(item.reset_datetime).format('DD-MM-YYYY HH:mm:ss');
											console.log("cdatetme here", cdatetme);
											console.log("etne here", etne);
										}
									}
								
									 console.log("breaktime ", brtime)
									 console.log("break_start ", item.stoppage_start_time)
									 console.log("break_end ", break_end)
									endata.push({
										last_packet_latitude : item.last_packet_latitude,
										last_packet_longitude : item.last_packet_longitude,
										stoppage_start_time:item.stoppage_start_time,
										reset_datetime:item.reset_datetime,
										break_time:breaktime,
										break_end:break_end,
										truck_no:item.truck_no,
										truck_no:item.truck_no,
										consignment_codes:item.consignment_codes,
										ticket_id:item.ticket_id,
										ticket_type:item.ticket_type,
										ticket_status:item.ticket_status,
										consignment_object_ids:item.consignment_object_ids,
										dept_code:item.dept_code,
										transporter_name:item.transporter_name
									})
								})
							}
							params.successCallback(endata);
							//console.log("Step 2 ",response.data)
							
						});
						
					}
				}
			});
			
			if(params.column.colDef.field == 'ticket_type')
			{

				params.node.setExpanded(!params.node.expanded);
			}
			else{

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
			
		}
		else{
			this.setState({
			
				detailCellRendererParams:{
					detailGridOptions: {
					  columnDefs: [
						{ headerName: "Truck No",field: "truck_no" },
						{ headerName: "Speed (km/h)",field: "speed" },
						{ headerName: "Reported At",field: "first_instance" }
					]
					  
					},
					getDetailRowData: function(params) {
						//console.log("Child ", params);
					  params.successCallback([]);
					}
				  }
			});
		}
		
	}

	//Enroute Map
	onShowEnrouteRouteMap(rownode){
		console.log("Enroute Routemap rownode", rownode);
		console.log("UI Step1", moment.parseZone().format('HH:mm:ss'))
		this.setState({
			loadshow:'show-m'
		})
		var fintance =moment.parseZone(rownode.stoppage_start_time).format('YYYY-MM-DD HH:mm:ss');
		// rownode.stoppage_start_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var stime = moment.parseZone(fintance).format("X")
		if(rownode.reset_datetime && rownode.reset_datetime != '')
		{
			console.log("1")
			var etime = moment.parseZone(rownode.reset_datetime).format("X");
			console.log(stime+" > "+etime)
			if(stime > etime)
			{
				var eintance = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
			
			}
			else
			{
				console.log("2")
				//var eintance = rownode.reset_datetime.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
				var eintance = moment.parseZone(rownode.reset_datetime).format('YYYY-MM-DD HH:mm:ss');
			}
		}
		else{
			console.log("3")
			var eintance = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
		
		}
		//var eintance = rownode.packet_received_on.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var params = { 
			truck_no:rownode.truck_no,
			//consignment_code:rownode.consignment_code,
			//consignee_code:rownode.consignee_code,
            first_instance:fintance,
			recent_instance:eintance,
			screen:"consignment"
		}
		 redirectURL.post('/tms/nightdriveroutes',params)
		  .then((response) => {	
			
		console.log("UI Step2", moment.parseZone().format('HH:mm:ss'))
		   //var records = JSON.parse(JSON.stringify(response)).data;
			//console.log("Inner Grid ",response.data);
			if(response.data.coords.length == 0)
			{
				this.setState({
					show: true,
					basicTitle:'No Route data available',
					basicType:"danger",
					loadshow:'show-n'
				});
			}
			else{
				var sdate='';
				var edate='';

				if(rownode.leg_start != '' && rownode.leg_start != undefined)
				{
					sdate=rownode.stoppage_start_time;
				}
				if(rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined)
				{
					edate=getHyphenYYYYMMDDHHMMSS(rownode.leg_end_eta)
				}
			this.setState({
					mapinfo:response.data,
					dealer:rownode.consignee_code,
					sliderRouteTranslate:"slider-translate-50p",
					defTransitCoords: rownode.consignee_coordinates,
					consignment_code:"Consignment : "+rownode.truck_no,
					maptruckno:rownode.truck_no,
					routeTruck:	{"truck_no":rownode.truck_no,"startTime":getDDMMYYYYHHMMSS(fintance),"endTime": getDDMMYYYYHHMMSS(eintance)},
					loadshow:'show-n',
					sidebarSubHeader:"Consignment Leg Information",
					overly:'show-m',
					rownode:rownode,
					leg_no:rownode.leg_no

			});

			this.onShowGoogleRoute(rownode.consignment_code, rownode.consignee_code, rownode.leg_no, rownode.truck_no)
			//this.renderMap();
			
			} 
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
		  
	}

	//Loading Delay
	onShowLoadingDelayKpi = async (params) => {
		//console.log(e);
		console.log("Consign onShowLoadingDelayKpi ",params);
		
		var consignment = '';
		var reqdata = {
			consignment_code:params.data.consignment_codes
		}
		await redirectURL.post("/consignments/item", reqdata)
		.then((response) => {
			//console.log("consignment ",response.data)

			consignment=response.data;
		})
		//console.log(params.column.colId);
		if(params != 0)
		{
			var overspeedData=[];
			

			//console.log("Step 3 ",overspeedData)
			
			await this.setState({
					
				detailCellRendererParams:{
					detailGridOptions: {
						columnDefs: [
							
							{ 
								headerName: "Gate In Time",
								field: "gate_in_time", 
								width:180,
								resizable: true,
								valueGetter:function(params){
									if(params.data.gate_in_time)
									{
										//return getDDMMYYYYHHMMSS(params.data.gate_in_time);
										return params.data.gate_in_time;
									}
									else
									{
										return "NA"
									}
									
								}
							},
							{ 
								headerName: "Gate Out Time",
								field: "gate_out_time", 
								width:180,
								resizable: true,
								valueGetter:function(params){
									//console.log("IN gate_out_time valueGetter, params = ", params);
										
									if(params.data.gate_out_time)
									{
										//console.log("IN gate_out_time valueGetter, IF params.data.gate_out_time = ", params.data.gate_out_time);
										//return getDDMMYYYYHHMMSS(params.data.gate_out_time);
										return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
									}
									else
									{
										//console.log("IN gate_out_time valueGetter, ELSE params.data.gate_out_time = ", params.data.gate_out_time);
										return "NA"
									}
								},
								//filter: "agDateColumnFilter",
								comparator: dateComparator,
								// filterParams: {
								// 	browserDatePicker: true,
								// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
								// 	// provide comparator function
								// 	comparator: function(filteredDate,cellValue,secondDate) {
								// 		cellValue = cellValue.replace(/\//g,"-")
								// 		if(cellValue != "" && cellValue != " "){
								// 			cellValue = cellValue.split(" ")[0].split("-");
								// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
								// 			var cellDate = new Date(cellValue);
								// 			if(filteredDate.getMonth() < 10){
								// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
								// 			}
								// 			else{
								// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
								// 			}
								// 			if(filterValue.split("-")[2].length < 2)
								// 			{
								// 				var temp = filterValue
								// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
								// 			}
								// 			var filteredDate = new Date(filterValue);
								// 			cellDate = cellDate.getTime();
								// 			filteredDate = filteredDate.getTime();
								// 			// console.log(cellDate,filteredDate);
								// 			if(cellDate === filteredDate)
								// 			{
								// 				return 0;
								// 			}
								// 			if(cellDate < filteredDate){
								// 				return -1;
								// 			}
								// 			if(cellDate > filteredDate)
								// 			{
								// 				return 1;
								// 			}
								// 		}
								// 	}
								// },
							},
							{
								headerName:"Loading Time",
								field:"loading_time",
								width:180,
								
							}
													
						],
									
					},
					getDetailRowData: function(params) {
						//console.log("Step 4 ",params)
						if(consignment[0].gate_in_time != undefined)
						{
							var g1 = getDDMMYYYYHHMMSS(consignment[0].gate_in_time);
							//var g1 = (params.data.gate_in_time) ? moment.parseZone(params.data.gate_in_time).format('DD-MM-YYYY') : "";
							var gdate1 = new Date(consignment[0].gate_in_time);
							var fromDate = g1;
						}
						else{
							
							var g1 = getDDMMYYYYHHMMSS(consignment[0].inside_fence);
							var gdate1 = new Date(consignment[0].inside_fence);
							var fromDate = g1;
						}
						//console.log("g1 ", g1)//console.log("Step 4 ",params)
						if(consignment[0].gate_out_time != undefined)
						{
							var g2 = getDDMMYYYYHHMMSS(consignment[0].gate_out_time);
							var gdate2 = new Date(consignment[0].gate_out_time);
							var toField = g2;
							//console.log("gatein ", gatein)
							//console.log("gateout ", gateout)
							//var total =0 ;
						}
						else{
							var c = new Date();
							var todaydate = new Date(moment(c).format("YYYY-MM-DD HH:mm:ss"));
							var g2 = getDDMMYYYYHHMMSS(todaydate);
							var gdate2 = new Date(todaydate);
							var toField = g2;
						}
						//var g2 = getDDMMYYYY(params.data.gate_out_time);

						//console.log("g1 ", g2)
						var s1 = g1.split("-");
						var s2 = g2.split("-");
						
						
						var gatein = gdate1.getTime();
						var gateout = gdate2.getTime();
						var total = gateout-gatein;
						//console.log("total ", total)
						var diffMs = total;
						var diffDays = Math.ceil(diffMs / 86400000); // days
						var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
						var diffMins = parseInt(((diffMs % 86400000) % 3600000) / 60000); 
						
						let loadingTimeDifference = null;

						var dataarr = []
							
							dataarr.push({
								"gate_in_time": fromDate,
								"gate_out_time": toField,
								"loading_time": diffHrs+" Hr(s) "+" "+diffMins+" Min(s)"
							})
							params.successCallback(dataarr);
					}
					
				}
			});
			
			//if(params.column.colId == 'loading_delay_exception')
			
			if(params.column.colDef.field == 'ticket_type')
			{

				params.node.setExpanded(!params.node.expanded);
			}
			else{

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
			
		}
		else{
			
		}
		
	}



	onShowUnLoadingDelayKpi = async (params) => {
		//console.log(e);
		console.log("Unloading ",params);
		var consignment = '';
		var reqdata = {
			consignment_code:params.data.consignment_codes
		}
		await redirectURL.post("/tms/item", reqdata)
		.then((response) => {
			console.log("consignment ",response.data)

			consignment = response.data;
		})
		//console.log("Here consignment",consignment[0].first_dealer_reported);
		if(params != 0)
		{
			var overspeedData=[];
			

			//console.log("Step 3 ",overspeedData)
			
			await this.setState({
					
				detailCellRendererParams:{
					detailGridOptions: {
						columnDefs: [
							
							{ 
								headerName: "From Date",
								field: "from_date", 
								width:180,
								resizable: true,
								valueGetter:function(params){
									console.log("child params ", params)
									if(params.data.from_date != undefined)
									{
										return getDDMMYYYYHHMMSS(params.data.from_date);
									}
									else
									{
										return "NA"
									}
									
								}
							},
							{ 
								headerName: "To Date",
								field: "to_date", 
								width:180,
								resizable: true,
								valueGetter:function(params){
									if(params.data.to_date != undefined)
									{
										return getDDMMYYYYHHMMSS(params.data.to_date);
									}
									else
									{
										return "NA"
									}
								}
							},
							{
								headerName:"Unloading Time",
								field:"unloading_time",
								width:180,
								
							}
													
						],
									
					},
					getDetailRowData: function(param) {
						console.log("Step 4 ",param)
						console.log("Step 5 ",consignment)
						if(consignment[0].locked_dealer_reported == undefined)
						{
							var g1 = getYYYYMMDDHHMMSS(consignment[0].first_dealer_reported);
							var fromField = g1;
						}
						else
						{
							
							var g1 = getYYYYMMDDHHMMSS(consignment[0].locked_dealer_reported);
							var fromField = g1;
						}
						//console.log("param.data.left_dealer_marked ", param.data.left_dealer_marked)
						if(consignment[0].left_dealer_marked == undefined)
						{
							var c = new Date();
							var todaydate = c;
							var g2 = getYYYYMMDDHHMMSS(todaydate);
							var toField = g2;
						}
						else
						{
							var g2 = getYYYYMMDDHHMMSS(consignment[0].left_dealer_marked);
							var toField = g2;
							//console.log("toField stee " ,toField);
						}
							var fstr = fromField.split("-");
							console.log(fstr);
							var gdate1 = new Date(fromField);
							var gdate2 = new Date(toField);
							var frmd = gdate1.getTime();
							var tod = gdate2.getTime();
							var total = tod-frmd;
							console.log("From Date ", fromField)
							console.log("To Date ", toField)
							//var total =0 ;
							console.log("gdate1 ", frmd)
							console.log("gdate2 ", tod)
							console.log("Total ", total)
							var diffMs = total;
							var diffDays = Math.floor(diffMs / 86400000); // days
							var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
							var diffMins = parseInt(((diffMs % 86400000) % 3600000) / 60000); 
							
							// var sr = param.data.unloading_time_in_hrs;
							// var hrs=sr.toString().split(".");
							// var hours = parseInt(hrs[0]);
							// var mins = Math.round((hrs[1]/100)*60)
							var dataarr = []
							// dataarr.push({
							// 	"from_date":fromField,
							// 	"to_date": toField,
							// 	"unloading_time":diffHrs+" Hr(s) "+" "+diffMins+" Min(s)"
							// 	//"unloading_time":hours+"Hr(s) "+mins+" min(s)"
							// })
							let loadingTimeDifference = null;
							if (diffDays) {
								loadingTimeDifference = diffDays+" Day(s) " + diffHrs + " Hr(s) "+" "+diffMins+" Min(s)";
							} else {
								loadingTimeDifference = diffHrs+" Hr(s) "+" "+diffMins+" Min(s)";
							}
							dataarr.push({
									"from_date":fromField,
									"to_date": toField,
									"unloading_time":loadingTimeDifference
									//"unloading_time":hours+"Hr(s) "+mins+" min(s)"
							})
							param.successCallback(dataarr);
						
					}
					
				}
			});
			
			//if(params.column.colId == 'unloading_delay_exception')
			if(params.column.colDef.field == 'ticket_type')
			{

				params.node.setExpanded(!params.node.expanded);
			}
			else{

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
			
		}
		else{
			
		}
		
	}

	onShowTicketsMap(rowdata){
		this.setState({
			loadshow : "show-m",
			overly : "show-m"
		})
		var truck_no = rowdata.truck_no;
		if(rowdata.ticket_type == "slow_trucks")
		{
			var gate_out_time = getHyphenYYYYMMDDHHMMSS(rowdata.exception_start_time)
		}
		else
		{
			var gate_out_time = getHyphenYYYYMMDDHHMMSS(rowdata.gate_out_invoice_time)
		}
		var current_time = moment.parseZone().format("YYYY-MM-DD")
		current_time = current_time.toString()+" 23:59:59";
		var formdata = {
			truck_no : truck_no,
			gate_out_time : gate_out_time,
			seconddate : current_time
		}
		this.onShowGoogleRoute(rowdata.consignment_codes[0],rowdata.consignee_codes[0],0,rowdata.truck_no)
		redirectURL.post("/consignments/maproutes",formdata).then((response)=>{
			try{
				if(response.data.coords.length)
				{
					this.setState({
						sliderTicketMapRoute : "slider-translate-60p",
						mapinfo : response.data,
						rownode : rowdata,
						loadshow : "show-n",
						overly : "show-n"
					})
				}
				else
				{
					this.setState({
						basicTitle : "No Route Data Found",
						basicType : "warning",
						loadshow : "show-n",
						overly : "show-n",
						show : true
					})
				}
			}
			catch(e){
				this.setState({
					basicTitle : "No Route Data Found",
					basicType : "warning",
					loadshow : "show-n",
					overly : "show-n",
					show : true
				})
			}
			
		})
	}
    /*Google Route Maps Start*/

	onShowRouteMap = async (rownode) => {
		console.log("rownode", rownode);
		console.log("rownode.consignment_code ", rownode.consignment_code)
		var reqs={
			ticket_id:rownode.ticket_id
		}
		console.log("reqs ", reqs)
		
		this.setState({
			loadshow:'show-m'
		})
		var d = new Date(rownode.first_instance);
    	var r = new Date(rownode.recent_instance);
		//var finstance = rownode.first_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		//var rinstance = rownode.recent_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		
		//var finstance = moment.parseZone(new Date(rownode.first_instance)).format('DD-MM-YYYY HH:mm:ss');
		//var rinstance = moment.parseZone(new Date(rownode.recent_instance)).format('DD-MM-YYYY HH:mm:ss');
		var finstance =getHyphenYYYYMMDDHHMMSS(rownode.first_instance);
		var rinstance = getHyphenYYYYMMDDHHMMSS(rownode.recent_instance);
	   
		var params = { 
			truck_no:rownode.truck_no,
			consignment_code:rownode.consignment_codes,
			consignee_code:rownode.consignee_codes,
            first_instance:finstance,
			recent_instance:rinstance,
			screen:"consignment"
		}
		
		 await redirectURL.post('/tms/overspeedgpsroutes',params)
		  .then((response) => {
		  
		   //var records = JSON.parse(JSON.stringify(response)).data;
		    //console.log("Overspeed  ",rownode);
		   this.setState({
				mapinfo:response.data,
				//dealer:rownode.consignee_code,
				sliderRouteTranslate:"slider-translate-50p",
				consignment_code:rownode.truck_no,
				maptruckno:rownode.truck_no,
				routeTruck:	{"truck_no":rownode.truck_no,"startTime":getDDMMYYYYHHMMSS(response.data['route_details']['start_time']),
					"endTime": getDDMMYYYYHHMMSS(response.data['route_details']['end_time'])},
				overly:'show-m',
				loadshow:'show-n',
				rownode:rownode
		   });
		   //this.onShowGoogleRoute(rownode.consignment_code, rownode.consignee_code, 0, rownode.truck_no)
					
		   	//this.renderMap();
		
			   
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
		  
	}
	
	onShowTransitRouteMap(rownode){
		console.log("rownode", rownode);
		this.setState({
			loadshow:'show-m'
		})
		var d = new Date(rownode.leg_start);
    	var r = new Date(rownode.leg_end_eta);
    	//var fintance = rownode.leg_start.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        //var eintance = rownode.leg_end_eta.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		
		var fintance = getHyphenYYYYMMDDHHMMSS(rownode.gate_out_time);
        var eintance = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
        
		var params = { 
			truck_no:rownode.truck_no,
			consignment_code:rownode.consignment_code,
			consignee_code:rownode.consignee_code,
            first_instance:fintance,
			recent_instance:eintance,
			screen:"consignment"
		}
		 redirectURL.post('/tms/nightdriveroutes',params)
		  .then((response) => {	
		  
		   //var records = JSON.parse(JSON.stringify(response)).data;
			//console.log("Inner Grid ",response.data);
			if(response.data.coords.length == 0)
			{
				this.setState({
					show: true,
					basicTitle:'No Route data available',
					basicType:"danger",
					loadshow:'show-n'
				});
			}
			else{
				var sdate='';
				var edate='';

				if(rownode.leg_start != '' && rownode.leg_start != undefined)
				{
					sdate=getDDMMYYYYHHMMSS(rownode.leg_start);
				}
				if(rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined)
				{
					edate=getDDMMYYYYHHMMSS(rownode.leg_end_eta)
				}
			this.setState({
					mapinfo:response.data,
					dealer:rownode.consignee_code,
					sliderRouteTranslate:"slider-translate-50p",
					defTransitCoords: rownode.consignee_coordinates,
					consignment_code:"Ticket : "+rownode.truck_no,
					maptruckno:rownode.truck_no,
					routeTruck:	{"truck_no":rownode.truck_no,"startTime":fintance,"endTime": eintance},
					loadshow:'show-n',
					sidebarSubHeader:"Consignment Leg Information",
					overly:'show-m',
					rownode:rownode,
					leg_no:rownode.leg_no

			});

			this.onShowGoogleRoute(rownode.consignment_code, rownode.consignee_code, rownode.leg_no, rownode.truck_no)
				//this.renderMap();
			
			} 
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
		  
	}

	onShowNightDriveRouteMap(rownode){
		console.log("nightdrivenode", rownode);
		this.setState({
			loadshow:'show-m'
		});
		var d = new Date(rownode.first_instance);
    	var r = new Date(rownode.recent_instance);
    	//var fintance = rownode.first_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        //var eintance = rownode.recent_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var fintance = getHyphenYYYYMMDDHHMMSS(rownode.first_instance);
		var eintance = getHyphenYYYYMMDDHHMMSS(rownode.recent_instance);
		
		var params = { 
			truck_no:rownode.truck_no,
            first_instance:fintance,
            recent_instance:eintance,
		}
		
		 redirectURL.post('/tms/nightdriveroutes',params)
		  .then((response) => {
		  
		   //var records = JSON.parse(JSON.stringify(response)).data;
			console.log("nightroutedata",response.data);
			
		   this.setState({
				mapinfo:response.data,
				sliderRouteTranslate:"slider-translate-50p",
				consignment_code:rownode.truck_no,
				defTransitCoords: {"lat":rownode.first_lat,"lng":rownode.first_lng},
				maptruckno:rownode.truck_no,
				routeTruck:	{"truck_no":rownode.truck_no,"startTime":getDDMMYYYYHHMMSS(response.data['route_details']['start_time']),
					"endTime": getDDMMYYYYHHMMSS(response.data['route_details']['end_time'])},
				loadshow:'show-n',
				overly:'show-m'
		   });
		   	this.renderMap();
		
			   
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
		  
		  
    }
    
    onShowGoogleRoute(consignmentcode, consigneecode, legno, truck_no){
		//console.log("GOogle params ", consignmentcode+", "+ consigneecode+", "+legno)
		var reqData = {
			consignment_code:consignmentcode,
			consignee_code:consigneecode,
			leg_no:legno,
			truck_no:truck_no
		}
		redirectURL.post('/consignments/googlemaproutes',reqData, {
            headers:{
                'content-type':'application/json'
            }
        })
		.then((response) => {
			//console.log("Google ", response)
			//var gdt = '[{	"lat": 28.367420000000003,	"lng": 76.90442},{	"lat": 28.379795,	"lng": 76.903625},{	"lat": 28.380361999999998,	"lng": 76.899445},{	"lat": 28.383162,	"lng": 76.90275},{	"lat": 28.384619,	"lng": 76.900024},{	"lat": 28.385069,	"lng": 76.900024},{	"lat": 28.384990000000002,	"lng": 76.8998}]' 
			this.setState({
				googelRoutes:response.data.googleroute
			})
		})
		.catch(function(e){
			console.log(e)
		})
	}
	onShowRouteDiv = (e) => {
		console.log("Rute params ", e)
		this.setState({
			loadshow:'show-m',
			maptruckno:e.truck_no
		});

		// If gateouttime is current time - It shows "No Route data available"
		var gateouttime = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
		if(e.ticket_type == "slow_trucks")
		{
			gateouttime = e.exception_start_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		}
		else
		{
			if (e.gate_out_time) {
				gateouttime = e.gate_out_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
			}
		}
		
		
		var edate ='';
		var end_date;
		if(e.status == 2)
		{
			//var ms = new Date(e.gate_out_time).getTime() + ((e.transit_time+1) * 86400000);
			//var edatesecond = new Date(ms);
			end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
			
			edate = getDDMMYYYYHHMMSS(end_date);
		}

		else if(e.status == 3 || e.status == 4)
		{
			if (e.recent_dealer_reported) {
				end_date = e.recent_dealer_reported.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
				edate = getDDMMYYYYHHMMSS(e.recent_dealer_reported);
			}
		}

		else if(e.status == 5)
		{
			if (e.left_dealer_marked) {
				end_date = e.left_dealer_marked.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
				edate = getDDMMYYYYHHMMSS(e.left_dealer_marked);
			}
			
		}
		else{
			if (e.created_date) {
				end_date = e.created_date.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
				edate = getDDMMYYYYHHMMSS(e.created_date);
			}
			
		}
		
		//console.log("end date",end_date);
		//console.log("checking e",e)

		if ((e.transit_time) > 0)
		{
			var seconddate = new Date(new Date(e.gate_out_time).getTime() + ((e.transit_time+1) *24*60*60*1000));
		}
		else{
			var seconddate = new Date(new Date(e.gate_out_time).getTime() + 2*24*60*60*1000);
		}	
		
		var sdate = seconddate.getFullYear()+"-"+(seconddate.getMonth()+1)+"-"+seconddate.getDate()+" "+seconddate.getHours()+":"+seconddate.getMinutes()+":"+seconddate.getSeconds();
		
		if (!end_date) {
			// When end date is unavailable then current date is considered as end date.
			end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
			//console.log("Defining end_date ", end_date);
		} 
		var reqData = {
            truck_no:e.truck_no,
			consignment_code:e.consignment_code,
			consignee_code:e.consignee_code,
			gate_out_time:gateouttime,
			seconddate:end_date,
			screen:"consignment"
        }
        console.log("reqData: ", reqData);
		redirectURL.post('/consignments/maproutes',reqData, {
            headers:{
                'content-type':'application/json'
            }
        })
		.then((response) => {
			var records = response.data;
			//console.log("Props data ", e)

			this.setState({
				loadshow:'show-m'
			});
			if(records.coords.length == 0)
			{
				//console.log("records", records.coords);
				this.setState({
					show: true,
					basicTitle:'No Route data available',
					basicType:"danger",
					loadshow:'show-n'
				});
			}
			else{
				if(records != '')
				{
					var sdate = e.gate_out_time;
					var edate ='';
					if(e.status == 2)
					{
						//var ms = new Date(e.gate_out_time).getTime() + ((e.transit_time+1)*86400000);
						//var edatesecond = new Date(ms);
						end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
						edate = getDDMMYYYYHHMMSS(end_date);
					}

					else if(e.status == 3 || e.status == 4)
					{
						edate = getDDMMYYYYHHMMSS(e.recent_dealer_reported);
					}

					else if(e.status == 5)
					{
						edate = getDDMMYYYYHHMMSS(e.left_dealer_marked);
					}
					else{
						edate = getDDMMYYYYHHMMSS(e.created_date);
					}
					//console.log("End Date ", edate)
					this.setState({
						sliderRouteTranslate:"slider-translate-50p",
						showDiv:'show-m',
						mapinfo:records,
						dealer:e.consignee_code,
						consignment_code:"Consignment : "+e.consignment_code,
						maptruckno:e.truck_no,
						routeTruck:	{"truck_no":e.truck_no,"startTime":sdate,"endTime": edate},
						loadshow:'show-n',
						sidebarSubHeader:"Consignment Information",
						overly:'show-m',
						rownode:e,
						leg_no:0
					});
					this.onShowGoogleRoute(e.consignment_code, e.consignee_code, 0, e.truck_no)
					//this.renderMap();

				}
			}
			
			
		})
		.catch(function (error) {
			console.log(error);
		});
	}
	onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderRouteTranslate:"",
			sliderTicketMapRoute : "",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n'
		});
    }
    renderMap = () => {    	
		//loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyBQUmk7hdajZEAYllxKhVPnUx5pXuK-j_Y&libraries=places,drawing&callback=initMap");
		//window.initMap = this.initMap
	}
	/*End Maps*/
	
	onClickSendEMails = async (etype) =>{
		var rowCount = this.gridApi.getSelectedNodes();
		// var status_array=[]
		// var status_flag=0;
		// console.log("etype", etype)
		//console.log("rowCount ", rowCount)
		if(rowCount.length > 0)
		{
			// for(var i=0;i<rowCount.length;i++)
			// {
			// 	status_array.push(rowCount[i].data.ticket_status);
			// }
			// console.log(status_array);
			// const ticket_status = status_array.map((status)=>{
			// 	if(status != 1 && status != 2 && status != 4)
			// 	{
			// 		status_flag = 1;
			// 	}
			// });
			// console.log(status_array);
			// if(status_flag != 1)
			// {
				containerslist=[];
				var existdept=[];
				var existtransport=[];
				var transporter=[];
				var transportercode=[];
				var dept=[];
				var nogpsarr = [];
				rowCount.map((item) => {
					// console.log("item.ticket_type ", item)
					containerslist.push({
						"truck_no":item.data.truck_no,
						"ticket_id":item.data.ticket_id,
						"ticket_number":item.data.ticket_number,
						"ticket_type": item.data.ticket_type,
						"actual_lspuser": item.data.actual_lspuser,
						"transporter_name":item.data.transporter_name,
						"transporter_code":item.data.transporter_code,
						"consignment_codes":item.data.consignment_codes,
						"consignee_codes" : item.data.consignee_codes,
						"dept_code": item.data.dept_code,
						"consigner_codes" : item.data.consigner_codes,
						"ticket_text" : item.data.ticket_text,
						"trip_type" : item.data.trip_type,
						
					})
					
					existdept.push(item.data.dept_code)
					existtransport.push(item.data.transporter_name)
					transporter.push(item.data.transporter_name);
					transportercode.push(item.data.transporter_code);
					dept.push(item.data.dept_code);
					nogpsarr.push(item.data.ticket_type)
					
				})
				//console.log("containerslist ", containerslist)
				const uniqueDepts = existdept.filter((value, index, self) => {
					return self.indexOf(value) === index
				})
				const uniqueTransporters = existtransport.filter((value, index, self) => {
					return self.indexOf(value) === index
				})
				
				const uniqueTicketTypes = nogpsarr.filter((value, index, self) => {
					return self.indexOf(value) === index
				})
				//console.log("uniqueTicketTypes ", uniqueTicketTypes)
				
				if(uniqueDepts.length == 1 && uniqueTransporters.length == 1)
				{
						var issues = {
							tickets:containerslist,
							escalation:etype,
							transporter_name:transporter[0],
							transporter_code:transportercode[0],
							dept_code:dept[0],
							userid:localStorage.getItem("userid")

						}
					//console.log("issues ", issues)
					await redirectURL.post("/tms/submitIssuesMails", issues)
					.then((response) => {
						var records = response.data;
						try{
						//console.log("records ",records)
							if(records[0].body != "")
							{
								//console.log("Issues Tickets ", records)
								try
								{
									this.setState({					
										toggleMore:!this.state.toggleMore,
										inboxSlider:'slider-translate-75p',
										showleftmailside:'show-n',
										sendmaildiv:"col-xl-12",
										overly:'show-m',
										emailformat:records,
										toEmail:records[0].toEmail,
										ccEmail:records[0].ccEMail,
										mail_escalation:records[0].escalation,
										ticketEmailContent:records[0].body,
										ticketSubject:records[0].subject,
										ticketMailID:records[0]._id,
										ticketslist:records[0].ticket_numbers
									});
								}
								catch(e){
									this.setState({					
										toggleMore:!this.state.toggleMore,
										inboxSlider:'',
										overly:'show-n',
										emailformat:[]
									});
								}
							}
							else{
								this.setState({
									show: true,
									basicTitle:"Ticket Might have been auto closed. Please the Refresh the page.",
									basicType:"info",
									toggleMore:!this.state.toggleMore
								});
							}
						}
						catch(e){
							this.setState({
								show: true,
								basicTitle:records.message,
								basicType:"info",
								toggleMore:!this.state.toggleMore
							});
						}
					})
					//console.log("Ticketlist ",containerslist)
				}
				else{
					if(uniqueTicketTypes.length > 1)
					{
						this.setState({
							show: true,
							basicTitle:"Accepts only GPS NA Ticket Type",
							basicType:"danger",
							toggleMore:!this.state.toggleMore
						});
					}
					else{
						if(uniqueDepts.length > 0)
						{
							this.setState({
								show: true,
								basicTitle:"Multiple Departments or Transporters are not allowed",
								basicType:"danger",
								toggleMore:!this.state.toggleMore
							});
						}
						else{
							this.setState({
								show: true,
								basicTitle:"No Tickets selected",
								basicType:"danger",
								toggleMore:!this.state.toggleMore
							});
						}
					}
					this.gridApi.deselectAll();
				}
			// }
			// else
			// {
			// 	this.setState({
			// 		show: true,
			// 		basicTitle:"Tickets Status should be Open, Working Progress, Invalid",
			// 		basicType:"danger",
			// 		toggleMore:!this.state.toggleMore
			// 	});
			// 	this.gridApi.deselectAll();
			// }
		
		}
		else{
			alert("Please select atleast one item")
		}
		
	}
	onClickSaveMailNow = async (e) =>{
		e.preventDefault();
		var mailData = await {
			ticketId: this.state.ticketMailID,
			tickets:this.state.ticketslist,
			toEmail:this.state.toEmail,
			ccEmail:this.state.ccEmail,
			ticketEmailContent:this.state.ticketEmailContent,
			ticketSubject:this.state.ticketSubject,
			userId : localStorage.getItem('userid')
		}
		//console.log("mailData ", this.state.ticketEmailContent)
		//console.log("mailData Userid ", mailData)
		setTimeout(
			await redirectURL.post("/tms/saveTicketMail",mailData)
		.then((response) => {
			this.setState({					
				show: true,
				basicTitle:"Mail saved successfully",
				basicType:"success"
			});
			//window.location.reload();
		}), 1000);
		
	}
	onClickSaveAndSendMailNow(){
		console.log("this.state.toEmail ", this.state.toEmail)
		if(this.state.toEmail != "")
		{
			if(Array.isArray(this.state.toEmail))
			{
				var tickmail = this.state.toEmail;
			}
			else
			{
				var tmas = this.state.toEmail.split(",")
				var tomalarr = [];
				if(tmas.length > 0)
				{
					tmas.forEach((item) => {
						tomalarr.push(item)
					})
				}
				var tickmail = tomalarr;
			}

			if(Array.isArray(this.state.ccEmail))
			{
				var cctickmail = this.state.ccEmail;
			}
			else
			{
				var cctickmail = [this.state.ccEmail];
			}
			var mailData = {
				ticketId: this.state.ticketMailID,
				tickets:this.state.ticketslist,
				toEmail:tickmail,
				ccEmail:cctickmail,
				ticketEmailContent:this.state.ticketEmailContent,
				ticketSubject:this.state.ticketSubject,
				mail_escalation:this.state.mail_escalation,
				userId : localStorage.getItem('userid')
			}
			console.log("mailData ", mailData)
			//console.log("mailData Userid ", localStorage.getItem('userid'))
			redirectURL.post("/tms/saveandsendmail",mailData)
			.then((response) => {
				//console.log(" response ", response)
				this.setState({					
					inboxSlider:'',
					showleftmailside:'show-n',
					sendmaildiv:"col-xl-12",
					overly:'show-n',
					emailformat:[],
					toEmail:'',
					ccEmail:'',
					ticketEmailContent:'',
					ticketSubject:'',
					ticketMailID:'',
					show: true,
					basicTitle:response.data.message,
					basicType:"success"
				});


				redirectURL.post("/tms/drafttickets")
				.then((response) => {
					//console.log("response.data ", response.data)
					if(response.data)
					{
						this.setState({
							totalDrafts:response.data.length
						});
					}
				});

				
				
			})
		}
		else{
			this.setState({					
				show: true,
				basicTitle:"To Mail Should not be empty",
				basicType:"danger"
			});
		}
	}
	onClickInbox(){
		redirectURL.post("/tms/drafttickets")
		.then((response) => {
			//console.log("Draft ", response.data)
			var drafts = response.data
			if(drafts.length > 0)
			{
				this.setState({
					inboxSlider:'slider-translate-75p',
					overly:'show-m',
					showleftmailside:'show-m',
					sendmaildiv:"col-xl-9",
					draftlist:drafts,
					toEmail:'',
					ccEmail:'',
					ticketEmailContent:'',
					ticketSubject:'',
					ticketMailID:''
				});
			}
			else{
				this.setState({
					inboxSlider:'',
					overly:'show-n',
					showleftmailside:'show-n',
					sendmaildiv:"col-xl-12",
					toEmail:'',
					ccEmail:'',
					ticketEmailContent:'',
					ticketSubject:'',
					ticketMailID:'',
					show: true,
					basicTitle:"No drafts found",
					basicType:"warning"
				});
			}
			
		})
	}
	
	onClickDraftItem = (params) =>{
		//console.log("params ", params)
		var reqparams={
			ticketemailid:params._id
		}
		redirectURL.post("/tms/ticketemailinfo", reqparams)
		.then((response) => {
			console.log("Draft ", response.data)
			var records = response.data
			this.setState({
				emailformat:records,
				toEmail:records[0].toEmail,
				ccEmail:records[0].ccEMail,
				ticketEmailContent:records[0].body,
				ticketSubject:records[0].subject,
				ticketMailID:records[0]._id,
				ticketslist:records[0].ticket_numbers,
				showdiscard:'show-m'
			});
		})
	}


	
	onClickViewMailItem = (params) =>{
		console.log("params ", params)
		var reqparams={
			ticketemailid:params._id,
			ticketnumbers:params.ticket_numbers,
		}
		redirectURL.post("/tms/viewticketemaildata", reqparams)
		.then((response) => {
			console.log("Draft ", response.data)
			var records = response.data.mails
			this.setState({
				emailformat:records,
				toEmail:records[0].toEmail,
				ccEmail:records[0].ccEMail,
				ticketEmailContent:records[0].body,
				ticketSubject:records[0].subject,
				ticketMailID:records[0]._id,
				ticketslist:records[0].ticket_numbers,
				showdiscard:'show-m',
				viewcols:"col-xl-3 col-md-6",
				viewshow:'show-m',
				mailresponse:response.data.mailresponse
			});
		})
	}

	onClickDiscardAllMail(){
		var reqparams = {
			userid:localStorage.getItem("userid")
		};
		redirectURL.post("/tms/discardallmail",reqparams)
		.then((response) => {
			//console.log(" response ", response)
			redirectURL.post("/tms/discardmail")
			.then((response) => {
				
				this.setState({					
					inboxSlider:'',
					showleftmailside:'show-n',
					sendmaildiv:"col-xl-12",
					overly:'show-n',
					emailformat:[],
					toEmail:'',
					ccEmail:'',
					ticketEmailContent:'',
					ticketSubject:'',
					ticketMailID:'',
					show: true,
					basicTitle:"Successfully discarded mails",
					basicType:"success",
					totalDrafts:0
				});
				
			})
		})
		.catch(function(e){
			console.log("Error ",e)
		})
	}
	onClickDiscardMail(){
		if(Array.isArray(this.state.toEmail))
		{
			var tickmail = this.state.toEmail;
		}
		else
		{
			var tmas = this.state.toEmail.split(",")
			var tomalarr = [];
			if(tmas.length > 0)
			{
				tmas.forEach((item) => {
					tomalarr.push(item)
				})
			}
			var tickmail = tomalarr;
		}

		if(Array.isArray(this.state.ccEmail))
		{
			var cctickmail = this.state.ccEmail;
		}
		else
		{
			var cctickmail = [this.state.ccEmail];
		}
		var mailData = {
			ticketId: this.state.ticketMailID,
			tickets:this.state.ticketslist,
			toEmail:tickmail,
			ccEmail:cctickmail,
			ticketEmailContent:this.state.ticketEmailContent,
			ticketSubject:this.state.ticketSubject,
			userId : localStorage.getItem('userid')
		}
		//console.log("mailData ", mailData)
		//console.log("mailData Userid ", localStorage.getItem('userid'))
		redirectURL.post("/tms/discardmail",mailData)
		.then((response) => {
			//console.log(" response ", response)
			var old = this.state.totalDrafts
			if(response.data.ok == 1)
			{
				this.setState({					
					inboxSlider:'',
					showleftmailside:'show-n',
					sendmaildiv:"col-xl-12",
					overly:'show-n',
					emailformat:[],
					toEmail:'',
					ccEmail:'',
					ticketEmailContent:'',
					ticketSubject:'',
					ticketMailID:'',
					show: true,
					basicTitle:response.data.message,
					basicType:"success",
					totalDrafts:(old-1)
				});
			}
			else
			{
				this.setState({					
					inboxSlider:'',
					showleftmailside:'show-n',
					sendmaildiv:"col-xl-12",
					overly:'show-n',
					emailformat:[],
					toEmail:'',
					ccEmail:'',
					ticketEmailContent:'',
					ticketSubject:'',
					ticketMailID:'',
					show: true,
					basicTitle:response.data.message,
					basicType:"danger"
				});
			}
			
			
			//window.location.reload();
		})
	}
	/*Inbox actions Start*/
	mailToggle(tab) {
        if (this.state.SelectedTab !== tab) {
            this.setState({
                SelectedTab: tab
            });
        }
    }

	changeFileHandler (e){
		console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		const config = {
			headers: [
				{ 
					name: 'ticketNo',
					inputName: 'ticketNo',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},{ 
					name: 'communicationType',
					inputName: 'communicationType',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},{ 
					name: 'status',
					inputName: 'status',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
			]
		}
		// var data = CSVFileValidator(e.target.files[0], config)
		// 	console.log("dt here", data)
			
		
		var out = new Promise(function(reject, resolve){
			var reader =  new FileReader();
			reader.onload = async function(e) {
				var contents = await e.target.result;
				console.log("contents ", contents )
				resolve(contents);
			};
			 reader.readAsText(e.target.files[0]);
		});
		console.log("Out ", out);
		// this.setState({
		// 	csvcontent:contents
		// });
		this.setState({
			file:e.target.files[0]
		});
		console.log("e.target.files[0].type ", e.target.files[0].type);

		if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
		{
		}
		else{
			e.target.value = null;
			this.setState({
				uploadFile:'',
				show: true, 
				basicType:'danger', 
				basicTitle:'Please upload file having extensions .csv only.',
			});
		}
		
	}

	// uploadFormHandler(event){
	// 	event.preventDefault();
	// 	this.setState({
	// 		loadshow:'show-m'
	// 	}); 
	// 	console.log("event.target ", event.target.file)
	// 	var formData = new FormData(event.target);
	// 	//formData.set('file', formData.get('file'))
	// 	formData.append('file',this.state.file);
	// 	var reqparms = {
	// 		file:this.state.file
	// 	}
		
	// 	console.log('file',formData);
	// 	console.log('file reqparms',reqparms);
		
	// 	redirectURL.post("/tms/uploadBulkTicketComment", reqparms,{
	// 		headers:{
	// 			'Content-Disposition': 'multipart/form-data',
	// 			'Content-Type': 'text/csv'
	// 		}
	// 	})
	// 	.then(
	// 		(response)=>{
	// 			console.log(response);
	// 			//console.log(response.data.status);
	// 			document.getElementById("upform").reset();
	// 			if(response.data.status == 'failure')
	// 			{
	// 				this.setState({
	// 					//csverrmessage:response.data.status,
	// 					show: true, basicType:'danger', basicTitle:response.data.message,
	// 					bulkslide:'0%',
	// 					sliderTranslate:'',
	// 					showDiv:'show-n',
	// 					loadshow:'show-n',
	// 					uploadFile:'',
	// 					file:''
	// 				});
					
	// 			}
	// 			else
	// 			{
	// 				this.setState({
	// 					show: true, basicType:'success', basicTitle:response.data.message,
	// 					bulkslide:'0%',
	// 					sliderTranslate:'',
	// 					showDiv:'show-n',
	// 					uploadFile:'',
	// 					loadshow:'show-n',
	// 					file:''
	// 				});
					
	// 			}
				
				 
	// 		}
	// 	)
	// 	.catch(function(error){
	// 		console.log(error);
	// 	});
	// }

	uploadFormHandler(event){
		event.preventDefault();
		
		if(this.state.file != '')
		{
			var formData = new FormData(event.target);
			formData.append('uploadFile',this.state.file);
			formData.append("userId", localStorage.getItem('userid'));
			formData.append("csvcontent",this.state.csvcontent);
	
			//console.log('file',formData);
			this.setState({
				loadshow:'show-m'
			}); 
			redirectURL.post("/tms/uploadBulkTicketComment", formData,{
				headers:{
					'content-type': 'multipart/form-data'
				}
			}).
			then(
				(response)=>{
					console.log(response);
					console.log(response.data);
					document.getElementById("upform").reset();
					if(response.data.status == 'failure')
					{
						if(response.data.message.empty!="")
						{
							var emtymesg = response.data.message.empty
						}
						else{
							var emtymesg = "NA";
						}
						if(response.data.message.wrong!="")
						{
							var wrongymesg = response.data.message.wrong
						}
						else{
							var wrongymesg = "NA";
						}
						if(response.data.message.scriptings!="")
						{
							var scriptingsmesg = response.data.message.scriptings
						}
						else{
							var scriptingsmesg = "NA";
						}
						var resmessage = "";
						resmessage=resmessage+emtymesg+", ";
						resmessage=resmessage+wrongymesg+", ";
						resmessage=resmessage+scriptingsmesg
						
						this.setState({
							//csverrmessage:response.data.status,
							show: true, basicType:'danger', basicTitle:resmessage,
							uploadDivWidth:'0%',
							sliderTranslate:'',
							showDiv:'show-n',
							loadshow:'show-n',
							uploadFile:'',
							file:''
						});
						
					}
					else
					{
						this.setState({
							show: true, basicType:'success', basicTitle:response.data.message,
							uploadDivWidth:'0%',
							sliderTranslate:'',
							showDiv:'show-n',
							uploadFile:'',
							loadshow:'show-n',
							file:''
						});
						
					}
					
					 
				}
			)
			.catch(function(error){
				console.log(error);
			});
		}
		
	}

    updateContent(newContent) {
		console.log("newContent ",newContent)
        this.setState({
            ticketEmailContent:  newContent.editor.getData()
        })
    }

	resetUpload = () => {
		this.setState({
			uploadDivWidth:'0%',
			bulkslide:'',
			showDiv:'show-n',
			overly:'show-n',
			file:''
		});
		document.getElementById("upform").reset();
	}
    onBlur = async (evt) =>{
		console.log("onBlur event called with event info: ", evt.editor.getData());
		await this.setState({
            ticketEmailContent:  evt.editor.getData()
        })
    }

    afterPaste(evt){
		console.log("afterPaste event called with event info: ", evt);
		this.setState({
            ticketEmailContent:  evt.editor.getData()
        })
    }

    HandleToggleMore() {
        this.setState({
            toggleMore:!this.state.toggleMore
        })
	}
	
	onChangeHandler = (event) => {
		let name = event.target.name;
		let value = event.target.value;
    	this.setState({[name]:value});
	}
	
	onShowTicketHistory = async (rowdata) =>{
		console.log("rowdata ", rowdata)
	
		var transitedelaydata=[];
		
		await this.setState({
				
			detailCellRendererParams:{
				suppressRefresh: true,
				detailGridOptions: {
					columnDefs: [
						{ 
							headerName: "Communication Type",
							field: "communication_type", 
							width:150,
							resizable: true,
						},
						{ 
							headerName: "Comment",
							field: "ticket_comment", 
							width:400,
							resizable: false,
							cellClass: ['wraptext'],
							autoHeight: true,
						},
						{ 
							headerName: "Satisfactory Status",
							field: "satisfactory", 
							width:120,
							resizable: false,
						},
						{ 
							headerName: "Comment By",
							field: "username", 
							width:150,
							cellClass: ['wraptext'],
							resizable: true,
						},
						{ 
							headerName: "Transporter Response",
							field: "reponsecomment", 
							//field: "transporter_response",
							width:400,
							cellClass: ['wraptext'],
							resizable: true,
						},
						// { 
						// 	headerName: "Transporter Response",
						// 	field: "pasteResponseEmail", 
						// 	width:150,
						// 	resizable: true,
						// },
						{ 
							headerName: "Created Date",
							field: "created_date", 
							width:150,
							resizable: true,
							valueGetter:function(params){
								return getHyphenDDMMMYYYYHHMM(params.data.created_date)
							}
						},
						{ 
							headerName: "Status",
							field: "ticket_status", 
							width:150,
							resizable: true,
							valueGetter:function(params){
								if(params.data.ticket_status == 1)
								{
									return "Open";
								}
								if(params.data.ticket_status == 2)
								{
									return "Work in progress";
								}
								if(params.data.ticket_status == 3)
								{
									return "Closed";
								}
								if(params.data.ticket_status == 4)
								{
									return "On Hold";
								}
								if(params.data.ticket_status == 5)
								{
									return "Auto Closed";
								}
								if(params.data.ticket_status == 6)
								{
									return "Invalid";
								}
								if(params.data.ticket_status == 7)
								{
									return "Closed - No response";
								}
							}
						},
											
					],
					overlayNoRowsTemplate: 'No ticket history available',
											
				},
				getDetailRowData: function(params) {
					//console.log("Step 4 ",transitedelaydata)
					console.log("Step 4 ",params)
					var qu = {
						ticket_id:params.data.ticket_id,
						ticket_no:params.data.ticket_number
					}
					redirectURL.post('/tms/tickethistorybyid', qu)
					.then(async (response) =>{
						console.log("Step 1 ",response.data)


						params.successCallback(response.data);
						//console.log("Step 2 ",response.data)
						
					});
					
				}
			}
		});
		
		//if( params.column.colId == 'transit_delay' || params.column.colId == 'transit_delay_1' ||   params.column.colId == 'no_gps_data' || params.column.colId == 'no_gps_data_1')
		if(rowdata.column.colDef.field == '_id')
		{
			rowdata.node.setExpanded(!rowdata.node.expanded);
			//console.log("expanded ",params.node.expanded)
			if(rowdata.node.expanded == false)
			{
				transitedelaydata=[]
			}
		}
		else{

			rowdata.node.setExpanded(false);
		}
		//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
	}

	onShowTicketSentEmail = async(ticketdata) =>{
		console.log("onShowTicketSentEmail ", ticketdata)

		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Show Ticket Sent E-Mails",
			}
			googleAnalytics.logEvent(eventOptions);
		}

		var rqparams = {
			ticket_number:ticketdata.data.ticket_number
		}
		redirectURL.post("/tms/viewticketmails", rqparams)
		.then((response) => {
			//console.log("Draft ", response.data)
			var drafts = response.data
			if(drafts.length > 0)
			{
				this.setState({
					viewmailSlider:'slider-translate-75p',
					overly:'show-m',
					showleftmailside:'show-m',
					sendmaildiv:"col-xl-9",
					emaillist:drafts,
					toEmail:'',
					ccEmail:'',
					ticketEmailContent:'',
					ticketSubject:'',
					ticketMailID:''
				});
			}
		})
		.catch(function(e){
			console.log("Error ",e)
		})
	}

	onClickCommentBtn(){
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Comment Button",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		var rowCount = this.gridApi.getSelectedNodes();
		//console.log("etype", etype)
		//console.log("rowCount ", rowCount)
		if(rowCount.length > 0)
		{
			containerslist=[];
			var existdept=[];
			rowCount.map((item) => {
				containerslist.push(item.data.ticket_number)
			})
			console.log("containerslist ", containerslist.length)
			if(containerslist.length == 1)
			{
				var fdata ={
					tickets:containerslist
				}
				redirectURL.post("/tms/getTicketsHistory", fdata)
				.then((response) => {
					var datahistory = response.data;
					//console.log("response ", datahistory)
					this.setState({
						ticketHistoryLog:datahistory	
					});
					//window.location.reload();
					
				})
				.catch(function(e){
					console.log("Error ", e)
				})
				this.setState({
					sliderTicketTranslate:"slider-translate-40p",
					showDiv:'show-m',
					loadshow:'show-n',
					sidebarSubHeader:"Ticket Details",
					overly:'show-m',
					commentTickets:containerslist	
				});
			
			}
			else
			{
				this.setState({
					show: true, basicType:'danger', basicTitle:'Please select one item only',
						
				});
			}
			
		}
		else{
			this.setState({
				show: true, basicType:'danger', basicTitle:'Please select item',
					
			});
		}
	}
	
	onClickInvalidTicket = async(params) =>{
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Invalid Ticket",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		console.log("Props ", params)
		// var reqparm = {
		// 	ticket_id : params.value
		// }
		// redirectURL.post("/tms/makeTicketInvalid", reqparm)
		// .then((response) => {
		// 	console.log(response.data)
		// })
		// .catch(function(e) {
		// 	console.log("Error ",e)
		// })
		
		var selectedData = [params.data];
		this.setState({
			invalidticketinfo:selectedData
		});
		this.onOpenModal();
		//var res = this.gridApi.updateRowData({ remove: selectedData });
		//printResult(res);
	}

	onClickSetTicketAsInvalid = async() =>{
		//console.log("invalidticketinfo ", this.state.invalidticketinfo)
		//console.log("Reason ", this.state.invalid_reason);
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Set Ticket As Invalid",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		var check = CheckForHtmlTag(this.state.invalid_reason)
		if(check == 1)
		{
			
			this.setState({
				show: true, basicType:'danger', basicTitle:'Html Tags or Script tags are not acceptable',
					
			});
		}
		
		else{
			var reason = this.state.invalid_reason;
			reason = reason.trim();
			if(reason != '')
			{
				var reqparm = {
					ticket_id : this.state.invalidticketinfo[0].ticket_number,
					reason:this.state.invalid_reason,
					userId : localStorage.getItem('userid'),
				}
				redirectURL.post("/tms/makeTicketInvalid", reqparm)
				.then((response) => {
					//console.log(response.data)
					this.onCloseModal();
				})
				.catch(function(e) {
					console.log("Error ",e)
				})
				//var selectedData = this.gridApi.getSelectedRows();
				//console.log("selectedData ", selectedData);
				var selectedData = this.state.invalidticketinfo;
				//console.log("params selectedData ", selectedData);
				var res = this.gridApi.updateRowData({ remove: selectedData });
				//var res = this.gridApi.updateRowData({ remove: params });
				printResult(res);
			}
			else
			{
				this.setState({
					show: true, basicType:'danger', basicTitle:"Field should not be empty",
						
				});
			}
		}
		
	}
	onOpenModal = () => {
		this.setState({ open: true });
	 };
	
	 onCloseModal = () => {
	   this.setState({ open: false });
	 };

	handlerStartDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
    	this.setState({
    		startDate:startdate
    	});
    	//let value = event.target.value;
        //this.setState({'startDate':value});
    }

    handlerEndDateTime  = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
        var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate())
        this.setState({
    		endDate:edate
    	});
	}
	onShowBulkUpload(){
		
		this.setState({
			bulkslide:"slider-translate",
			overly:'show-m'
		});
	}
	
	selectAll(event) {
		// Set all checked states to true
		  this.setState({
			checked: this.state.checked.map(function() { 
				return event.target.checked 
			})
		});
	  }

	
	  onClickSaveGridState(){
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}

		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		
		let screenpage = '';

		if(this.props.match.path == "/ticketmanagementsystem")
		{
			screenpage = 'ticket management system';
		}
		
        if(this.props.match.path == "/closetickets")
		{
			screenpage = 'closed tickets';
		}
        if(this.props.match.path == "/invalidtickets")
		{
			screenpage = 'invalid tickets';
		}

		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates()
	{

		if(this.state.usergridstate.length > 0)
		{
			try{
				var windowstates = this.state.usergridstate;
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
			}
			catch(e){

			}
			
		}
	}

	resetState = () => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Reset Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

	};

	
	/*End*/
	render(){
		const modalStyles  = {
			width:'500px !important',
			}
	const { open } = this.state;
		//console.log("ticketHistoryLog ", this.state.ticketHistoryLog)
		var tktHistory=[];
		if(this.state.ticketHistoryLog.length > 0)
		{
			this.state.ticketHistoryLog.forEach(item => {
				tktHistory.push({
					username:item.username,
					ticket_comment:item.ticket_comment,
					communication_type:item.communication_type,
					ticket_status:item.ticket_status,
					created_date:item.created_date
				})
			});
		}
		let hideonlyInvlid = (this.props.match.path == "/invalidtickets") ? true : false;
		let showonlytms = (this.state.isinvalidtickets == '')?false:true;
        const columnwithDefs=[
            {
                headerName:"",
                field:"ticket_type",
                width:80,
                cellRendererSelector:function(params){
                    if(params.data.ticket_type == 'transit_delay')
                    {
                        var rendComponent = {
                            component: 'kpiTransitDelayActions'
                        };
                        return rendComponent
                    }

                    if(params.data.ticket_type == 'no_gps')
                    {
                        var rendComponent = {
                            component: 'nogps'
                        };
                        return rendComponent
                    }

                    if(params.data.ticket_type == 'overspeeding')
                    {
                        var rendComponent = {
                            component: 'kpiActions'
                        };
                        return rendComponent
                    }

                    if(params.data.ticket_type == 'night_driving')
                    {
                        var rendComponent = {
                            component: 'kpiNightDriveActions'
                        };
                        return rendComponent
                    }
                    if(params.data.ticket_type == 'enroute_stoppage')
                    {
                        var rendComponent = {
                            component: 'ticketkpienroute'
                        };
                        return rendComponent
					}
					
                    if(params.data.ticket_type == 'slow_trucks')
                    {
                        var rendComponent = {
                            component: 'Slowtrucks'
                        };
                        return rendComponent
					}
					
                },

                headerCheckboxSelection: true,
                headerCheckboxSelectionFilteredOnly: true,
				checkboxSelection: true
				
			},
			{
                headerName:"",
				field:"",
				width:50,
				filter:false,
                cellRendererSelector:function(params){
                    
					var rendComponent = {
						component: 'TicketsMap'
					};
					return rendComponent
					
				}
               
            },
			{
                headerName:"",
				field:"ticket_id",
				width:50,
				filter:false,
				hide:hideonlyInvlid,
                cellRendererSelector:function(params){
                    
					var rendComponent = {
						component: 'invalidTicket'
					};
					return rendComponent
					
				}
               
            },
			{
                headerName:"",
				field:"_id",
				width:50,
				filter:false,
                cellRendererSelector:function(params){
                    
					var rendComponent = {
						component: 'tickethistory'
					};
					return rendComponent
					
				}
               
			},
			{
                headerName:"",
                field:"truck_no",         
                width:50,
                cellRendererSelector:function(params){
                    var rendComponent = {
                        component: 'sendemailticket'
                    };
                    return rendComponent
                }
            },
			{
                headerName:"",
				field:"_id",
				width:50,
				filter:false,
				hide:showonlytms,
                cellRendererSelector:function(params){
                    
					var rendComponent = {
						component: 'ticketmailview'
					};
					return rendComponent
					
				}
               
            },
            {
                headerName:"Ticket",
				field:"ticket_type",
				width:120,
                valueGetter:function(params){
                    var tickettype = params.data.ticket_type;
                    var str = tickettype.replace(/_/g, " ")
                    return str;
                },
                cellClass:"capitlize",
               
            },
            // {
            //     headerName:"Ticket ID",
            //     field:"ticket_id",                
            //     width:180,
			// },
			
            {
                headerName:"Ticket No",
                field:"ticket_number",                
                width:120,
			},
			// {
			// 	headerName:"Ready To Close",
            //     field:"ready_to_close",                
			// 	width:120,
			// 	valueGetter : function(params)
			// 	{
			// 		if(params.data.ready_to_close == 1)
			// 		{
			// 			return "Yes";
			// 		}
			// 		else
			// 		{
			// 			return "";
			// 		}
			// 	}
			// },
            {
                headerName:"Truck No",
                field:"truck_no",                
                width:120,
            },
            {
                headerName:"Consignment Code",
                field:"consignment_codes",
                width:120,
            },
            {
                headerName:"Consignee Code",
                field:"consignee_codes",                
                width:120,
            },
            {
                headerName:"Transporter",
                field:"transporter_name",                
                width:120,
            },
            {
                headerName:"Department",
                field:"dept_code",                
                width:150,
            },
            {
                headerName:"Actual LSP User",
                field:"actual_lspuser",                
                width:150,
            },
            {
                headerName:"Status",
                field:"ticket_status",
                width:130,
                valueGetter:function(params){
                    if(params.data.ticket_status == 1)
                    {
                        return "Open";
                    }
                    if(params.data.ticket_status == 2)
                    {
                        return "Work in progress";
                    }
                    if(params.data.ticket_status == 3)
                    {
                        return "Closed";
                    }
                    if(params.data.ticket_status == 4)
                    {
                        return "On hold";
                    }
                    if(params.data.ticket_status == 5)
                    {
                        return "Auto closed";
                    }
                    if(params.data.ticket_status == 6)
                    {
                        return "Invalid";
                    }
                    if(params.data.ticket_status == 7)
                    {
                        return "Closed - No response";
                    }
                    if(params.data.ticket_status == 8)
                    {
                        return "Force Closed";
                    }
                }
			},
            {
                headerName:"Consignee City",
                field:"consignee_city",                
				width:180,
				hide:showonlytms,
				valueGetter:function(params){
					if(typeof params.data.consignee_city == undefined){
						return '';
					}
					else{
						return params.data.consignee_city;
					}
				}
            },
            {
                headerName:"Transit Delay Hours",
                field:"transit_delay_hours",                
				width:180,
				hide:showonlytms,
				valueGetter:function(params){
					if(typeof params.data.transit_delay_hours == undefined){
						return '';
					}
					else{
						return params.data.transit_delay_hours;
					}
				}
			},
			{
				headerName : "Exception Location",
				field : "exception_location",
				width : 170,
				resizable : true
			},
			// {
			// 	headerName : "Exception Longitude",
			// 	field : "exception_lng",
			// 	width : 140,
			// 	resizable : true
			// },
			{
				headerName:"Last Known City/Area",
                field:"area",                
				width:180,
				valueGetter : function(params)
				{
					if(params.data.truck.length){
						return params.data.truck[0].area
					}
					else
					{
						return "";
					}
					
				}
			},
			{
				headerName:"Last Known State",
                field:"area",                
				width:180,
				valueGetter : function(params)
				{
					return params.data.truck[0].state
				}
			},
			{
				headerName:"Last Packet Time",
                field:"timestamp",                
				width:180,
				valueGetter:function(params){
					return getHyphenDDMMMYYYYHHMM(params.data.truck[0].timestamp)
				},
				comparator: dateComparator,
			},

            {
                headerName:"Gate Out / Invoice Time",
                field:"gate_out_invoice_time",                
				width:180,
				valueGetter:function(params){
					return getHyphenDDMMMYYYYHHMM(params.data.gate_out_invoice_time)
				},
				//filter: "agDateColumnFilter",
				comparator: dateComparator,
				// filterParams: {
				// 	browserDatePicker: true,
				// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
				// 	// provide comparator function
				// 	comparator: function(filteredDate,cellValue,secondDate) {
				// 		cellValue = cellValue.replace(/\//g,"-")
				// 		if(cellValue != "" && cellValue != " "){
				// 			cellValue = cellValue.split(" ")[0].split("-");
				// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
				// 			var cellDate = new Date(cellValue);
				// 			if(filteredDate.getMonth() < 10){
				// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
				// 			}
				// 			else{
				// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
				// 			}
				// 			if(filterValue.split("-")[2].length < 2)
				// 			{
				// 				var temp = filterValue
				// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
				// 			}
				// 			var filteredDate = new Date(filterValue);
				// 			cellDate = cellDate.getTime();
				// 			filteredDate = filteredDate.getTime();
				// 			// console.log(cellDate,filteredDate);
				// 			if(cellDate === filteredDate)
				// 			{
				// 				return 0;
				// 			}
				// 			if(cellDate < filteredDate){
				// 				return -1;
				// 			}
				// 			if(cellDate > filteredDate)
				// 			{
				// 				return 1;
				// 			}
				// 		}
				// 	}
				// },
            },
            {
                headerName:"Created Date",
                field:"ticket_creation_date",                
				width:180,
				valueGetter:function(params){
					return getHyphenDDMMMYYYYHHMM(params.data.ticket_creation_date)
				},
				//filter: "agDateColumnFilter",
				comparator: dateComparator,
				// filterParams: {
				// 	browserDatePicker: true,
				// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
				// 	// provide comparator function
				// 	comparator: function(filteredDate,cellValue,secondDate) {
				// 		cellValue = cellValue.replace(/\//g,"-")
				// 		if(cellValue != "" && cellValue != " "){
				// 			cellValue = cellValue.split(" ")[0].split("-");
				// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
				// 			var cellDate = new Date(cellValue);
				// 			if(filteredDate.getMonth() < 10){
				// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
				// 			}
				// 			else{
				// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
				// 			}
				// 			if(filterValue.split("-")[2].length < 2)
				// 			{
				// 				var temp = filterValue
				// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
				// 			}
				// 			var filteredDate = new Date(filterValue);
				// 			cellDate = cellDate.getTime();
				// 			filteredDate = filteredDate.getTime();
				// 			// console.log(cellDate,filteredDate);
				// 			if(cellDate === filteredDate)
				// 			{
				// 				return 0;
				// 			}
				// 			if(cellDate < filteredDate){
				// 				return -1;
				// 			}
				// 			if(cellDate > filteredDate)
				// 			{
				// 				return 1;
				// 			}
				// 		}
				// 	}
				// },
            },
            {
                headerName:"Modified Date",
                field:"ticket_modification_date",                
				width:180,
				valueGetter:function(params){
					return getHyphenDDMMMYYYYHHMM(params.data.ticket_modification_date)
				},
				//filter: "agDateColumnFilter",
				comparator: dateComparator,
				// filterParams: {
				// 	browserDatePicker: true,
				// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
				// 	// provide comparator function
				// 	comparator: function(filteredDate,cellValue,secondDate) {
				// 		cellValue = cellValue.replace(/\//g,"-")
				// 		if(cellValue != "" && cellValue != " "){
				// 			cellValue = cellValue.split(" ")[0].split("-");
				// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
				// 			var cellDate = new Date(cellValue);
				// 			if(filteredDate.getMonth() < 10){
				// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
				// 			}
				// 			else{
				// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
				// 			}
				// 			if(filterValue.split("-")[2].length < 2)
				// 			{
				// 				var temp = filterValue
				// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
				// 			}
				// 			var filteredDate = new Date(filterValue);
				// 			cellDate = cellDate.getTime();
				// 			filteredDate = filteredDate.getTime();
				// 			// console.log(cellDate,filteredDate);
				// 			if(cellDate === filteredDate)
				// 			{
				// 				return 0;
				// 			}
				// 			if(cellDate < filteredDate){
				// 				return -1;
				// 			}
				// 			if(cellDate > filteredDate)
				// 			{
				// 				return 1;
				// 			}
				// 		}
				// 	}
				// },
            },
            
		]
		
		var draftcount = this.state.totalDrafts;

		var contactcolumnwithDefs=[
            
            {
                headerName:"Contact Name",
                field:"contact_name",                
				width:180
			},
			
            {
                headerName:"Contact Number",
                field:"phone_no",                
				width:180,
				cellClass: ['wraptext'],
				valueGetter:function(params){
					var nos = '';
					try{
						if(params.data.phone_no != '' && params.data.phone_no.length > 0)
						{
							console.log("params.data.phone_no ", params.data.phone_no.toString().replace("[","").replace("]","").replace(/"/g,"").replace(/'/g,""))
							// params.data.phone_no.map((item) => {
							// 	nos=nos+params.data.phone_no+",";
							// });
							// return nos;
							return  params.data.phone_no.toString().replace("[","").replace("]","").replace(/"/g,"").replace(/'/g,"");
						}
						else
						{
							return '';
						}
					}
					catch(e){
						return "";
					}	
				}

			},
			
            {
                headerName:"Escalation Level",
                field:"escalation_level",                
				width:180,
				sort:'asc'
            }
		]
		//console.log("this.state. ", this.state.isclosetickts)
		
		// var isAllChecked = this.state.checked.filter(function(c) {
		// 	return c;
		// }).length === this.state.checked.length;
		return(
			<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
				<div className="row">
                    <div className="col-xl-12 col-lg-12">		            	
                        <h5>
		<i className="icofont icofont-ticket cus-i"></i> <span>{this.state.title}</span>
		{(this.state.isclosetickts == '' && this.state.isinvalidtickets == '')?
                            <button type="button" onClick={this.onClickInbox.bind(this)} className="btn btn-warning float-right">
								Draft {(draftcount != '')?("("+draftcount+")"):""}
							</button>
							:""}
                        </h5>
                    
                    </div>
					{/*Ticket Types*/}
					<div className="row col-xl-12 col-lg-12">
						<div className="col-xl-3 col-lg-3 form-group">
							<label>Select Department</label>
							<Select 
							value={this.state.dept_code} 
							className="border-radius-0" 
							onChange={this.changeArrayHandler.bind(this)} 
							style={{borderRadius:"0px"}} 
							options={this.selectOptionsItems()} 
							placeholder={"All"}
							required 
							/>
							
						</div>

						<div className="col-xl-3 col-lg-3 form-group">
							<label>From Date</label>
							<Datetime timeFormat={false} value={this.state.startDate} disableCloseOnClickOutside={false} closeOnSelect={true} inputProps={{ placeholder: 'From', name: 'startDate', autoComplete:'off' }} dateFormat="YYYY-MM-DD"  name="startDate" onChange={this.handlerStartDateTime.bind(this)} />
						</div>

						<div className="col-xl-3 col-lg-3 form-group">
							<label>To Date</label>
							<Datetime timeFormat={false} value={this.state.endDate} disableCloseOnClickOutside={false} closeOnSelect={true} inputProps={{ placeholder: 'To', name: 'endDate', autoComplete:'off'}} dateFormat="YYYY-MM-DD" onChange={this.handlerEndDateTime.bind(this)} />
						</div>
						<div className={"col-xl-3 col-lg-3 form-group float-left"}>
							<label></label><br />
							<button type="button" onClick={this.onShowDeptData.bind(this)} className="btn btn-info mt-9p">Get Data</button>
						</div>
					</div>
                    <div className="col-xl-6 col-lg-6 col-sm-6 beffect">
                        <div className="card">
                            <div className="card-body p-10px">
                                <div className="crm-numbers pb-0">
                                    <div className="row">                                    

										<div className="col cpointer" onClick={this.onClickTicketTypeCounterShowData.bind(this,"all")}>
                                            <span className="f13">
                                               Total 
                                            </span>
                                            <h4 className="txt-pink-medium f30">
                                                <span className="counter">
                                                    <CountUp end={this.state.totaltypes}/>
                                                </span>
                                            </h4>
                                        </div>
                                        <div className="col cpointer" onClick={this.onClickTicketTypeCounterShowData.bind(this,"transitdelay")}>
                                            <span className="f13">
                                               Tranist Delay 
                                            </span>
                                            <h4 className="txt-pink-medium f30">
                                                <span className="counter">
                                                    <CountUp end={this.state.transit}/>
                                                </span>
                                            </h4>
                                        </div>

                                        <div className="col cpointer" onClick={this.onClickTicketTypeCounterShowData.bind(this,"nogps")}>
                                            <span className="f13">
                                                GPS Data NA</span>
                                            <h4 className="txt-secondary f30">
                                                <span className="counter">
                                                    <CountUp end= {this.state.gps}/>
                                                </span>
                                            </h4>
                                        </div>

                                        {/* <div className="col cpointer" onClick={this.onClickTicketTypeCounterShowData.bind(this,"overspeeding")}>
                                            <span className="f13">
												Overspeeding
											</span>
                                            <h4 className="txt-success f30">
                                                <span className="counter">
                                                    <CountUp end={this.state.overspeed}/>
                                                </span>
                                            </h4>
                                        </div> */}
										<div className="col cpointer" onClick={this.onClickTicketTypeCounterShowData.bind(this,"enroute")}>
                                            <span className="f13">
												Stoppages
											</span>
                                            <h4 className="maroon f30">
                                                <span className="counter">
                                                    <CountUp end={this.state.enroute}/>
                                                </span>
                                            </h4>
                                        </div>
										
                                    </div>
									<div className="row">  
                                    	<div className="col cpointer" onClick={this.onClickTicketTypeCounterShowData.bind(this,"slow_trucks_data")}>
                                            <span className="f13">
												Slow Trucks
											</span>
                                            <h4 className="maroon f30">
                                                <span className="counter">
                                                    <CountUp end={this.state.slow_trucks_data.length}/>
                                                </span>
                                            </h4>
                                        </div>
										<div className="col cpointer" onClick={this.onClickTicketTypeCounterShowData.bind(this,"ready_to_close_data")}>
                                            <span className="f13">
												Ready to Close
											</span>
                                            <h4 className="maroon f30">
                                                <span className="counter">
                                                    <CountUp end={this.state.ready_to_close_data.length}/>
                                                </span>
                                            </h4>
                                        </div>
										<div className="col cpointer">
                                            {/* <span className="f13">
												Ready to Close
											</span>
                                            <h4 className="maroon f30">
                                                <span className="counter">
                                                    <CountUp end={this.state.enroute}/>
                                                </span>
                                            </h4> */}
                                        </div>
										<div className="col cpointer" >
                                            {/* <span className="f13">
												Ready to Close
											</span>
                                            <h4 className="maroon f30">
                                                <span className="counter">
                                                    <CountUp end={this.state.enroute}/>
                                                </span>
                                            </h4> */}
                                        </div>
                                        {/* <div className="col cirlce-d cpointer" onClick={this.onClickTicketTypeCounterShowData.bind(this,"nightdrive")}>
                                            <span className="f13">
												Night Driving
											</span>
                                            <h4 className="txt-primary f30">
                                                <span className="counter">
                                                    <CountUp end={this.state.nightdrive}/>
                                                </span>
                                            </h4>
                                        </div>  */}

                                        
                                        {/* <div className="col cpointer" onClick={this.onClickTicketTypeCounterShowData.bind(this,"loadingdelay")}>
                                            <span className="f13">
												Loading Delay
											</span>
                                            <h4 className="darkorange f30">
                                                <span className="counter">
                                                    <CountUp end={this.state.loading}/>
                                                </span>
                                            </h4>
                                        </div> */}
                                        {/* <div className="col cpointer" onClick={this.onClickTicketTypeCounterShowData.bind(this,"unloadingdelay")}>
                                            <span className="f13">
												Unloading Delay
											</span>
                                            <h4 className="mildgreen f30">
                                                <span className="counter">
                                                    <CountUp end={this.state.unloading}/>
                                                </span>
                                            </h4>
                                        </div> */}
                                    </div>
                                </div>
                            
                            </div>
                        </div>
                    </div>
					{/*Ticket Status*/}
                    <div className="col-xl-6 col-lg-6 col-sm-6 beffect">
                        <div className="card" >
                            <div className={"card-body "+(this.state.isclosetickts == 1)?"p-14px":""} style={{margiTop:"35px"}}>
                                <div className="crm-numbers" style={{padding:"0px"}}>
									{
										
										(this.state.isclosetickts == '' && this.state.isinvalidtickets=='')?
									
                                    <div className="row">                                    

                                        <div className="col cpointer pt-26px" onClick={this.onClickCounterShowData.bind(this,"open")} style={{marginTop: "-15px"}}>
                                            <span className="f13">
                                                Open 
                                            </span>
                                            <h4 className="txt-pink-medium f30">
                                                <span className="counter">
                                                    <CountUp end={this.state.opens}/>
                                                </span>
                                            </h4>
                                        </div>

                                        <div className="col cpointer pt-26px" onClick={this.onClickCounterShowData.bind(this,"workinprogress")} style={{marginTop: "-15px"}}>
                                            <span className="f13">
                                                Work in progress</span>
                                            <h4 className="txt-secondary f30">
                                                <span className="counter">
                                                    <CountUp end= {this.state.workinprogresses}/>
                                                </span>
                                            </h4>
                                        </div>
										<div className="col cpointer pt-26px" onClick={this.onClickCounterShowData.bind(this,"transporterreponse")} style={{marginTop: "-15px"}}>
                                            <span className="f13">
                                                Transporter Responded Online</span>
                                            <h4 className="txt-secondary f30">
                                                <span className="counter">
                                                    <CountUp end= {this.state.transporter}/>
                                                </span>
                                            </h4>
                                        </div>

                                    
                                        <div className="col cirlce-d cpointer pt-26px" onClick={this.onClickCounterShowData.bind(this,"onhold")} style={{marginTop: "-15px"}}>
                                            <span className="f13">
												 On Hold</span>
                                            <h4 className="txt-info f30">
                                                <span className="counter">
                                                    <CountUp end={this.state.onholds}/>
                                                </span>
                                            </h4>
                                        </div> 

										<div className="col cirlce-d cpointer pt-26px" onClick={this.onClickCounterShowCriticalData.bind(this,"onhold")} style={{marginTop: "-15px"}}>
                                            <span className="f13">
												 Critical</span>
                                            <h4 className="txt-info f30">
                                                <span className="counter">
                                                    <CountUp end={this.state.criticalTickets.length}/>
                                                </span>
                                            </h4>
                                        </div> 

                                    </div>
									:""}
									{(this.state.isclosetickts == 1)?
									<div className="row pt-15px pl-10px">                                    

                                        <div className="col cpointer" onClick={this.onClickCounterShowData.bind(this,"closed")}>
                                            <span className="f13">
												Closed<br/><br/> </span>
                                            <h4 className="txt-pink-medium f30">
                                                <span className="counter">
                                                    <CountUp end={this.state.closeds}/>
                                                </span>
                                            </h4>
                                        </div> 
                                    
                                        <div className="col cpointer" onClick={this.onClickCounterShowData.bind(this,"autoclosed")}>
                                            <span className="f13">
												
												Auto Closed<br/><br/></span>
                                            <h4 className="txt-primary f30">
                                                <span className="counter">
                                                    <CountUp end={this.state.autocloseds}/>
                                                </span>
                                            </h4>
                                        </div>

                                        <div className="col cpointer" onClick={this.onClickCounterShowData.bind(this,"closenoresponse")}>
                                            <span className="f12">
												Closed No Response</span>
                                            <h4 className="txt-primary f30">
                                                <span className="counter">
                                                    <CountUp end={this.state.closenoresponse}/>
                                                </span>
                                            </h4>
                                        </div>
                                        
                                        <div className="col cpointer" onClick={this.onClickCounterShowData.bind(this,"forceclosed")}>
                                            <span className="f13">
												
												Force Closed<br/><br/></span>
                                            <h4 className="txt-primary f30">
                                                <span className="counter">
                                                    <CountUp end={this.state.forceclosed}/>
                                                </span>
                                            </h4>
                                        </div>
                                    </div>
									:""}
									{(this.state.isinvalidtickets == 1)?
									<div className="row pt-15px">                                    

										<div className="col cpointer" onClick={this.onClickCounterShowData.bind(this,"invalid")}>
                                            <span className="f13">
												 Invalid</span>
                                            <h4 className="txt-secondary f30">
                                                <span className="counter">
                                                    <CountUp end={this.state.invalids}/>
                                                </span>
                                            </h4>
                                        </div>
									
									</div>
									:""}


                                </div>
                            
                            </div>
                        </div>
                    </div>
            		
                    

                    <div className="col-xl-12 col-lg-12">
		            	<div className="card">
                           			   			
		            		<div className="row card-body pt-15px" >
								<div className="float-left pr-10px">
									<i class="fa fa-circle" style={{color:"#d5e9f0",border:"1px solid #000",background:'#000',borderRadius:"100%"}}></i> Addressed By DCT</div>
								<div className="float-left">
									<i class="fa fa-circle" style={{"color":"#ebcaab","border":"1px solid #000",background:"#000",borderRadius:"100%"}}></i> Ready to Close
								</div>
								{(this.state.isclosetickts == 1)?"":
								<div className="col-xl-12 col-lg-12">
									<div className="float-right">
										<button
											type="button" 
											title="Bulk Upload"
											onClick={this.onShowBulkUpload.bind(this)}
											className={"btn se-ico btn-success ml-5px "}
											type="button">
											Bulk Update
										</button>
									</div>
									<div className="float-right">
										<button
											type="button" 
											title="Write Comment"
											onClick={this.onClickCommentBtn.bind(this)}
											className={"btn se-ico btn-info ml-5px "+(this.state.showCommentBtnb)}
											type="button">
											Comment
										</button>
									</div>
									<div className="float-right">
										<div className="dropdown">
											<button
												type="button" 
												title="Send EMail"
												onClick={() => this.HandleToggleMore()}
												className={"btn se-ico btn-warning dropdown-toggle text-muted "+(this.state.showMailBtnb)}
												type="button" id="dropdownMenuButton"
												data-toggle="dropdown" aria-haspopup="true"
												aria-expanded="false">
												Send Mail
											</button>
											<div className={`dropdown-menu ${this.state.toggleMore?'show':''}`}
												aria-labelledby="dropdownMenuButton">
												<a className="dropdown-item" href="#" onClick={this.onClickSendEMails.bind(this,1)}>Level 1</a>
												<a className="dropdown-item" href="#" onClick={this.onClickSendEMails.bind(this,2)}>Level 2</a>
												<a className="dropdown-item" href="#" onClick={this.onClickSendEMails.bind(this,3)}>Level 3</a>
											</div>
										</div>
									</div>
									<span className="layoutbtns float-right">
										<button className="float-right btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
											<i className="icofont icofont-save"></i> Save Grid Layout
										</button>
										<button className="float-right btn white btn-info" onClick={this.resetState.bind(this)}>
											<i className="icofont icofont-refresh"></i> Reset Default Layout
										</button>
									</span>
								</div>
								}
								{(this.state.isclosetickts == 1)?
								<div className="col-xl-12">
									<span className="layoutbtns float-right">
										<button className="float-right btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
											<i className="icofont icofont-save"></i> Save Grid Layout
										</button>
										<button className="float-right btn white btn-info" onClick={this.resetState.bind(this)}>
											<i className="icofont icofont-refresh"></i> Reset Default Layout
										</button>
									</span>
								</div>
								:""}
								<div id="myGrid" style={{ height: "550px",width: "100%"}}  className="col-xl-12 col-lg-12 ag-theme-balham">
		          		            <AgGridReact
		          		            modules={this.state.modules}
		          		            columnDefs={columnwithDefs}
		          		            defaultColDef={this.state.defaultColDef}
		          		            rowData={this.state.rowData}
		          		            enableCharts={true}
		          		            enableRangeSelection={true}
		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
		          		            onGridReady={this.onGridReady}
		          		            onGridState={this.onGridState}
		          		            statusBar={this.state.statusBar}
									sideBar={this.state.sideBar}
									paginationPageSize={this.state.paginationPageSize}
									pagination={true}
									frameworkComponents={this.state.frameworkComponents}
									detailCellRendererParams={this.state.detailCellRendererParams}
									//editType={this.state.editType}
									stopEditingWhenGridLosesFocus={true}
									//floatingFilter={true}
									enableCellChangeFlash={true}
									suppressCellFlash={true}
									rowClassRules={this.state.rowClassRules}
									//onCellClicked={this.onRowClicked.bind(this)}
									gridOptions={{
										context: { componentParent: this }
									}}
                                    masterDetail={true}
                                    rowSelection={this.state.rowSelection}
                                    //onRowSelected={this.onRowSelected.bind(this)}
                                    suppressRowClickSelection={true}
		          		          />


		          		        </div>
				   			</div>
				   		</div>	
				   	</div>

				 </div>
					 <div className={"slide-r "+(this.state.sliderTicketTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								{(this.state.sidebarSubHeader)}
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
                       
							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
								
                                {/*Ticket form start*/}

                                
                                <div className="crm-numbers pb-0">
                                    
                                    <div className="col-xl-12 col-lg-12 row">
                                        {/* "FORM START" */}
                                        <form className="row theme-form" onSubmit={this.formHandler.bind(this)}>
                                                
                                            <div className="col-md-12 col-sm-6 col-xl-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="col-form-label">Communication Type</label>
                                                    <select required className="form-control mb-1" name="com_code" value={this.state.com_code} onChange={this.changeHandler}>
                                                        <option value=''>Select</option>
                                                        {this.communicationTypesDropdownOptions()}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-6 col-xl-6 col-lg-6 ">
                                                <div className="form-group">
                                                    <label className="col-form-label">Status</label>
                                                    <select required className="form-control mb-1" name="ticket_status_code" value={this.state.ticket_status_code} onChange={this.changeHandler}>
                                                        <option value=''>Select</option>
                                                        {this.ticketStatusTypesDropdownOptions()}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-sm-12 col-xl-12 col-lg-12 ">
                                                <div className="form-group">
                                                    <label className="col-form-label">Quality of Response</label>
													<div>
                                                    	<input type="radio" name="satisfactory" onChange={this.changeHandler} value="Yes" /> Satisfactory
                                                    	&nbsp;&nbsp;<input type="radio" name="satisfactory" onChange={this.changeHandler} value="NO" /> Not Satisfactory
													</div>
												</div>
                                            </div>

                                            <div className="col-md-12 col-sm-12 col-xl-12 col-lg-12 ">
                                                <div className="form-group">
                                                    <label className="col-form-label">Transporter response</label>
													<div>
                                                    	<input type="radio" name="transporterresponse" onChange={this.changeHandler} value="Yes" /> Yes
                                                    	&nbsp;&nbsp;<input type="radio" name="transporterresponse" onChange={this.changeHandler} value="NO" /> No
													</div>
												</div>
                                            </div>
											{(tktHistory.length)?
											<div className="col-md-12 col-sm-12 col-xl-12 col-lg-12 ">
												<div className="form-group">
													<label className="col-form-label">History:</label>
													<ul class="crm-activity ch300" style={{overflowY:"auto"}}>
															{tktHistory.map((item) => 
															<li class="media">
																<span class="mr-3 font-primary">
																	{item.username[0]}
																</span>
																<div class="align-self-center media-body">
																	<h6 class="mt-0">
																		{item.ticket_comment}
																	</h6>
																	<ul class="dates">
																		<li class="digits">
																			Communication: {item.communication_type}
																		</li>
																		<li class="digits">
																			Created at: {getDDMMYYYY(item.created_date)}
																		</li>
																		<li class="digits">
																			By: {item.username}
																		</li>
																	</ul>
																</div>
															</li>
														)}	
														
													</ul>
												</div>
											</div> 
											
											:""}

											<div className="col-md-12 col-sm-12 col-xl-12 col-lg-12 ">
                                                <div className="form-group">
                                                    <label className="col-form-label">Comments</label>
                                                    <textarea required className="form-control" rows="6" column="10" name="ticket_comment" value={this.state.ticket_comment} onChange={this.changeHandler} placeholder="Please provide details"/>
                                                </div>
                                            </div>
										    <div className="col-md-12 col-sm-12 col-xl-12 col-lg-12 ">
                                                <div className="form-group">
                                                    <label className="col-form-label">Paste Response Email</label>
                                                    <textarea required className="form-control" rows="6" column="10" name="reponsecomment" value={this.state.reponsecomment} onChange={this.changeHandler} placeholder="Please provide details"/>
                                                </div>
                                            </div>
                                            <div className="form-row col-xl-12 col-sm-12 col-lg-12">
                                                <div className="col-sm-6">
                                                    <button type="submit" className="btn btn-secondary">Save</button>
                                                </div>
                                                
                                            </div>
                                                
                                        </form>

                                        {/* "FORM END" */}
                                    </div>
                                </div>

                                {/*End*/}


							</div>
						</div>
						
					</div>

                    {/*Google Route Maps Starts*/}
					
                    {
                    (this.state.routeTruck != '')?
                    <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								{(this.state.consignment_code)}
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
							
							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
								<DrawMap context={this} rownode={this.state.rownode} consignercords={this.state.consignercords} consigneecords={this.state.consigneecords} mapFor={"ticket"} googleroutes={this.state.googelRoutes} truckno={this.state.maptruckno} dealer={this.state.dealer} mapinfo={this.state.mapinfo} defTransitCoords={this.state.defTransitCoords} />
								
								<div className="col-xl-12 col-lg-12 n-p-0">
									<div className="crm-numbers pb-0">
										<h3 className="subH">{this.state.sidebarSubHeader}</h3>
										<div className="col-xl-12 col-lg-12 row">
											<div className="col route-block">
												<label className="sidebar-label">Truck No</label>
												<div>{this.state.routeTruck.truck_no}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(this.state.routeTruck.startTime != '')?this.state.routeTruck.startTime:"NA"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(this.state.routeTruck.endTime != '')?this.state.routeTruck.endTime:"NA"}</div>
											</div>
											
											<div className="col route-block">
												<label className="sidebar-label">GPS Distance</label>
												<div>{(this.state.mapinfo.route_details != '')?Math.round(this.state.mapinfo.route_details.distance/1000):"0"} Kms</div>
											</div>
											{(this.state.leg_no == 0 || this.state.leg_no == null)?	""
											:
											<div className="col route-block">
												<label className="sidebar-label">Google Distance</label>
												<div>{(this.state.rownode.actual_google_leg_distance)?parseInt(this.state.rownode.actual_google_leg_distance):"0"} Kms</div>
											</div>
											}
											
										
										</div>
									</div>
								</div>
                                
							</div>
						</div>
						
					</div>
				:""}
				{(this.state.sliderTicketMapRoute != "")?
					<div className={"slide-r "+(this.state.sliderTicketMapRoute)} style={{overflow:"auto"}}>
						<div className="slide-r-title">
							<h4>
								Map Route
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
							
							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
								<DrawMap 
									context={this}
									rownode={this.state.rownode}
									mapFor={"ticket"}
									googleroutes={this.state.googelRoutes}
									truckno={this.state.maptruckno}
									mapinfo={this.state.mapinfo}
								/>
								
								<div className="col-xl-12 col-lg-12 n-p-0">
									<div className="crm-numbers pb-0">
										<h3 className="subH">{this.state.sidebarSubHeader}</h3>
										<div className="col-xl-12 col-lg-12 row">
											<div className="col route-block">
												<label className="sidebar-label">Truck No</label>
												<div>{this.state.rownode.truck_no}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(this.state.rownode.gate_out_invoice_time != '')?getHyphenDDMMMYYYYHHMM(this.state.rownode.gate_out_invoice_time):"NA"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{moment.parseZone().format("YYYY-MMM-DD HH:MM")}</div>
											</div>
											
											{/* <div className="col route-block">
												<label className="sidebar-label">GPS Distance</label>
												<div>{(this.state.mapinfo.route_details != '')?Math.round(this.state.mapinfo.route_details.distance/1000):"0"} Kms</div>
											</div>
											{(this.state.leg_no == 0 || this.state.leg_no == null)?	""
											:
											<div className="col route-block">
												<label className="sidebar-label">Google Distance</label>
												<div>{(this.state.rownode.actual_google_leg_distance)?parseInt(this.state.rownode.actual_google_leg_distance):"0"} Kms</div>
											</div>
											} */}
											
										
										</div>
									</div>
								</div>
								
							</div>
						</div>
						
					</div>
				:""}

					<div className={"slide-r "+(this.state.inboxSlider)} style={{overflow:"hidden"}}>
					 	
						<div className="slide-r-body" style={{position:"relative"}}>
							
							<div className="container-fluid">
								{/* Inbox Start  */}
								<div className="email-wrap">
                        			<div className="row">
										<div className={"col-xl-3 col-md-6 "+(this.state.showleftmailside)}>
											<div className="email-right-aside">
												<div className="card email-body">
													<div className="pr-0 b-r-light">
														<div className="email-top n-p-0">
															<div className="row">
																<div className="col">
																	<h5>
																		Draft
																		<button type="button"
																			onClick={this.onClickDiscardAllMail.bind(this)}
																				className={"btn custom-btn text-center mb-0 mt-0 mr-5p fright "}>
																			<i className="fa fa-trash mr-2"></i> DISCARD ALL
																		</button>
																	</h5>
																</div>
																
															</div>
														</div>

														<div className="inbox">
															{this.state.draftlist.map((item, index) => (
															
																<div className="media cpointer brd-top" onClick={this.onClickDraftItem.bind(this,item)}>
																	<div className="media-body">
																		<h6 className="mt-0" style={{textAlign:"left"}}>
																			{item.subject}
																		</h6>
																	</div>
																</div>
															))}
															
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className={" col-md-12 "+(this.state.sendmaildiv)}>
											<div className="email-right-aside">
												<div className="card email-body radius-left">
													<div className="pl-0">
														<TabContent className="tab-content" activeTab={this.state.SelectedTab}>
															<TabPane tabId="1">
																	<div className="email-compose">
																		<div className="email-top compose-border n-p-0">
																			<div className="row">
																				<div className="col-sm-5 xl-30">
																					<h4 className="mb-0">Send Mail</h4>
																				</div>
																				<div className="col-sm-7 btn-middle xl-70">
																					<button type="button"
																						onClick={this.onClickSaveMailNow.bind(this)}
																							className="btn btn-secondary btn-mail text-center mb-0 mt-0 fright">
																						<i className="fa fa-save mr-2"></i> SAVE
																					</button>
																					<button type="button"
																						onClick={this.onClickSaveAndSendMailNow.bind(this)}
																							className="btn btn-primary btn-mail text-center mb-0 mt-0 mr-5p fright">
																						<i className="fa fa-paper-plane mr-2"></i> SEND
																					</button>
																					<button type="button"
																						onClick={this.onClickDiscardMail.bind(this)}
																							className={"btn btn-square btn-outline-danger-2x btn-mail red text-center mb-0 mt-0 mr-5p fright "+(this.state.showdiscard)}>
																						<i className="fa fa-trash mr-2"></i> DISCARD
																					</button>
																					
																				</div>
																			</div>

																		</div>
																		<div className="email-wrapper">
																			<form className="theme-form">
																				<div className=" row form-group">
																					<label htmlFor="exampleInputEmail1"
																						className="col-form-label pt-0 col-xl-2 col-lg-2">To</label>
																					<input type="email" className="form-control col-xl-10 col-lg-10"
																						id="exampleInputEmail1" name="toEmail" value={this.state.toEmail}
																						onChange={this.onChangeHandler} aria-describedby="emailHelp" />
																				</div>
																				<div className="form-group row">
																					<label htmlFor="exampleInputEmail1"
																						className="col-form-label pt-0 col-xl-2 col-lg-2">Cc</label>
																					<input type="email" className="form-control col-xl-10 col-lg-10"
																						id="exampleInputEmail1" name="ccEmail" value={this.state.ccEmail}
																						 onChange={this.onChangeHandler} aria-describedby="emailHelp" />
																				</div>
																				<div className="form-group row">
																					<label
																						htmlFor="exampleInputPassword1" className=" col-xl-2 col-lg-2">Subject</label>
																					<input type="text" className="form-control col-xl-10 col-lg-10" value={this.state.ticketSubject}
																						onChange={this.onChangeHandler} name="ticketSubject" id="exampleInputPassword1" />
																				</div>
																				<div className="form-group mb-0">
																					<label className="text-muted">Message</label>
																						<CKEditor
																						min-height="500px"
																							config={ {height : "400px"}}
																							activeClass="p10"
																							content={this.state.ticketEmailContent}
																							events={{
																								"blur": this.onBlur.bind(this),
																								"afterPaste": this.afterPaste.bind(this),
																								"updateContent": this.updateContent.bind(this)
																							}}
																						/>
																				</div>
																				
																			</form>
																		</div>
																	</div>
															</TabPane>
															
														</TabContent>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								{/* Inbox End  */}


							</div>
						</div>
					</div>

					{/*Sent Ticket Mail Data*/}

					<div className={"slide-r "+(this.state.viewmailSlider)} style={{overflow:"hidden"}}>
					 	
						<div className="slide-r-body" style={{position:"relative"}}>
							
							<div className="container-fluid">
								{/* Inbox Start  */}
								<div className="email-wrap">
                        			<div className="row">
										<div className={(this.state.viewcols)+" "+(this.state.showleftmailside)}>
											<div className="email-right-aside">
												<div className="card email-body">
													<div className="pr-0 b-r-light">
														<div className="email-top n-p-0">
															<div className="row">
																<div className="col">
																	<h5>
																		View Mails
																	</h5>
																</div>
																
															</div>
														</div>

														<div className="inbox">
															{this.state.emaillist.map((item, index) => (
																
																<div className={"media cpointer brd-top "+((index%2 == 0)?"":"maileven")} onClick={this.onClickViewMailItem.bind(this,item)}>
																	<div className="media-body">
																		<h6 className="mt-0" style={{textAlign:"left"}}>
																			{item.subject}
																		</h6>
																	</div>
																</div>
															))}
															
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className={" col-md-12 "+(this.state.sendmaildiv)+" "+(this.state.viewshow)}>
											<div className="email-right-aside">
												<div className="card email-body radius-left">
													<div className="pl-0">
														<TabContent className="tab-content" activeTab={this.state.SelectedTab}>
															<TabPane tabId="1">
																	<div className="email-top compose-border n-p-0">
																		<div className="row">
																			<div className="col-sm-12 xl-100">
																				<h4 className="mb-0">
																					{(this.state.ticketSubject != '')?this.state.ticketSubject:"NA"}
																				</h4>
																			</div>
																		</div>

																	</div>
																	<div className="email-compose mb-70p">
																		
																		<div className="email-wrapper mb-60p">
																			<form className="theme-form">
																				<div className=" row form-group">
																					<label htmlFor="exampleInputEmail1"
																						className="col-form-label pt-0 col-xl-2 col-lg-2">To</label>
																					<div className="col-xl-10 col-lg-10">
																						{(this.state.toEmail != '')?this.state.toEmail:"NA"}
																					</div>
																				</div>
																				<div className="form-group row">
																					<label htmlFor="exampleInputEmail1"
																						className="col-form-label pt-0 col-xl-2 col-lg-2">Cc</label>
																					<div className="col-xl-10 col-lg-10">
																						{(this.state.ccEmail != '')?this.state.ccEmail:"NA"}
																					</div>
																				</div>
																				
																				<div className="form-group mb-0">
																					<label className="text-muted">Message</label>
																					<div  className="col-xl-12 col-lg-12">
																						<CKEditor
																							min-height="500px"
																							config={ {height : "500px",width:"100%"}}
																							activeClass="p10"
																							content={this.state.ticketEmailContent}
																							events={{
																								"blur": this.onBlur.bind(this),
																								"afterPaste": this.afterPaste.bind(this),
																								"updateContent": this.updateContent.bind(this)
																							}}
																						/>
																						
																					</div>
																				</div>
																				
																				<div className="form-group mb-0 mt-20p mb-60p">
																					<label className="text-muted">Transporter Response:</label>
																					{(this.state.mailresponse.length > 0)?
																					this.state.mailresponse.map((item, index) => (
																					<div  className="col-xl-12 col-lg-12">
																						{item.reponsecomment}
																					</div>
																					))
																					:"NA"}
																		
																				</div>
																			</form>
																		</div>
																	</div>
															</TabPane>
															
														</TabContent>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								{/* Inbox End  */}


							</div>
						</div>
					</div>

					<div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
			
				<Modal open={open} onClose={this.onCloseModal} styles={modalStyles} >
	                <div className={"row"} style={{padding:'5px 0px', width: '400px', overflow: 'hidden',zIndex:'9999',}} >
						<form method="post">
						<div className="col-md-12 col-sm-12 col-xl-12 col-lg-12 ">
							<div className="form-group">
								<label className="col-form-label">Reason:</label>
								<textarea required onBlur="javascript:return CheckForHtmlTag()" id="invalid_reason" className="form-control" rows="6" name="invalid_reason" value={this.state.invalid_reason} onChange={this.changeHandler} placeholder="Enter Reason"/>
							</div>
						</div>
						<div className="col-md-12 col-sm-12 col-xl-12 col-lg-12 ">
							<div className="form-group">
								<button type="button" className="btn btn-success" name="submit" onClick={this.onClickSetTicketAsInvalid.bind(this)}>Submit</button>						
							</div>
							
						</div>
						</form>
					</div>
				</Modal>
			    <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
					<h3 className="subH">Bulk Update Comments Upload</h3>
						 	
					<div className="slide-r-body" style={{position:"relative"}}>
						
						<div className="container-fluid">
							<form id="upform" className="theme-form" onSubmit={this.uploadFormHandler}>

							<div className="form-group mt-20p">
								<label className="">Upload File</label> 
								<input type="file" name="uploadFile" onChange={this.changeFileHandler}  className="form-control" required  />
							</div>
							<div className="form-group">
								<button type="submit" className="btn btn-success">Submit</button>
								<button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
							</div>
							</form>
							<div className="form-group">
								<p style={{color:"#ff0000"}}>*Note: All fields related to ticket are mandatory</p>
								<p><b>Ticketing bulk update rules:</b></p>

							
								<p><b>Communication Type:</b> Email/Phone/Other</p>

								<p><b>Status: </b>Open/Closed/Invalid/On Hold/Closed - No response/Other</p>

								<p><b>Satisfactory Response:</b> Yes/No</p>
								<p><b>Transporter Response:</b> Yes/No</p>

							</div>
							<div className="form-group">
								<a className="btn btn-primary" href={require('../../assets/json/Bulk_tickets_update_file.csv')} target="_blank">Sample Template</a>
							
							</div>		
							{/* <form id="upform" method="POST" className="theme-form" enctype="multipart/form-data" onSubmit={this.uploadFormHandler}>

								<div className="form-group mt-20p">
									<label className="">Upload File</label> 
									<input type="file" name="file" id="file" onChange={this.changeFileHandler}  className="form-control" required  />
								</div>
								<div className="form-group">
									<button type="submit" className="btn btn-success">Upload</button>
									<button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
								</div>
							</form> */}
						</div>
					</div>
				</div>
				{(this.state.transportercontact)?
				<div className={"slide-r "+(this.state.transportcont)} style={{overflow:"hidden"}}>
					<h3 className="subH">Transpoter Contact Details</h3>
						 	
					<div className="slide-r-body" style={{position:"relative",overflowY:"auto", height:"600px"}}>
					{/* <table className="table table-bordered">
						{this.state.transportercontact.map((item, index) => (
						<tbody>
							<tr>
								<td>
									Contact Name
								</td>
								<td>
									{item.contact_name} 
								</td>
							</tr>
							<tr>
								<td>
									Contact Number
								</td>
								<td>
									{item.phone_no.toString().replace("[","").replace("]","").replace(/"/g,"").replace(/'/g,"")} 
								</td>
							</tr>
							<tr>
								<td>
									Escalation Level
								</td>
								<td>
									{item.escalation_level} 
								</td>
							</tr>
						</tbody>
						))
						}
						</table> */}

						<div id="myGrid" style={{ height: "550px",width: "100%"}}  className="col-xl-12 col-lg-12 ag-theme-balham">
								<AgGridReact
								modules={this.state.modules}
								columnDefs={contactcolumnwithDefs}
								defaultColDef={this.state.defaultColDef}
								rowData={this.state.contactrowData}
								enableCharts={true}
								enableRangeSelection={true}
								autoGroupColumnDef={this.state.autoGroupColumnDef}
								onGridReady={this.onGridReady}
								onGridState={this.onGridState}
								statusBar={this.state.statusBar}
								paginationPageSize={this.state.paginationPageSize}
								pagination={true}
								
								gridOptions={{
									context: { componentParent: this }
								}}
								
								/>


						</div>
					</div>
				</div>
				:""}

			</div>
              	
		);
	}
}
function secondsToString(seconds)
{
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}

function printResult(res) {
	console.log(res)
	if (res.remove) {
	  res.remove.forEach(function(rowNode) {
		console.log('Removed Row Node', rowNode);
	  });
	}
  }
// funciton checkforhtmltag
  function CheckForHtmlTag(){
	var rs = document.getElementById("invalid_reason");
	var reg =/<(.|\n)*?>/g; 
	if (reg.test(rs.value)) {
		var ErrorText = "Oops! HTML or Script is not allowed.";		
		rs.value=''
		//alert();
		return 1;
	}
}

function fileValidation(){
    var fileInput = document.getElementById('file');
    var filePath = fileInput.value;
    var allowedExtensions = /(\.csv)$/i;
    if(!allowedExtensions.exec(filePath)){
       
        fileInput.value = '';
        return 0;
    }
}

function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
        // console.log(date1,date2);
    var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
    date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
    var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
    date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
    // console.log(date1,date2);
    var date1Number = monthToComparableNumber(date1);
    var date2Number = monthToComparableNumber(date2);
    // console.log(date1Number,date2Number);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
    }
  }
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName){
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
        ];
    var monthNum = months.indexOf(monthName)+1;
    if(monthNum < 10){
        monthNum = "0"+monthNum.toString();
    }
    return monthNum;
}