import React, { Component } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
// import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
$.datetimepicker.setLocale('en');
var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");
const UpdateManualTransit = (props) => {
    const handleRouteClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.UpdateManualTransitBtn(props);
    };

    return (
        <div>
            <button
                style={{ color: "#fff" }}
                onClick={handleRouteClick}
                className="btn btn-danger custom-btn f12 label label-success"
                title=""
            >
                Update
            </button>
        </div>
    );
};


export default class PRTUpdateTrasitTimes extends Component {

    constructor(props) {
        super(props);
        this.displayData = [];
        this.state = {
            loadshow: 'show-n',
            overly: "show-n",
            modules: AllModules,
            alert: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            dept_code: '',
            defaultColDef: {
                sortable: true,
                filter: true,
                // editable: true,
                resizable: true
            },
            rowData: [],
            frameworkComponents: {
                UpdateManualTransit: UpdateManualTransit,
            },
            context: { componentParent: this },
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            detailCellRendererParams: {},
            paginationPageSize: 200,
            pageTitle: "Dealer Transit",
            dataToUpdate: [],
            updateDistanceInKm: "",
            updateTransitTime: "",
        };
        this.onClickHideAll = this.onClickHideAll.bind(this);
    }
    componentDidMount() {
        this.onLoadData();
    }
    onLoadData = () => {
        this.setState({
            loadshow: "show-m",
            overly: "show-m",
        })
        redirectURL.post("/consignments/getTransitTimesData")
            .then((response) => {
                var recs = response.data;
                recs.forEach(record => {
                    Object.keys(record).forEach(key => {
                        if (isNaN(record[key]) && typeof record[key] !== 'string') {
                            record[key] = "";  // Replace NaN with an empty string
                        }
                    });
                });
                // console.log("recs", recs)
                if (recs.status == "Failure") {
                    this.setState({
                        loadshow: "show-n",
                        overly: "show-n",
                        show: true,
                        basicType: 'danger',
                        basicTitle: 'No Data'
                    })
                } else {
                    this.setState({
                        rowData: recs,
                        loadshow: "show-n",
                        overly: "show-n",
                    });
                }
            })
    }

    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    /*Alert Popups*/
    closeAlert = async () => {
        await this.setState({
            show: false
        });
    }

    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            tabsliderTranslate: '',
            sliderTranslatesidebar: '',
            bulkslide: '',
            sliderForceDestination: '',
            sliderBulkForceClose: "",
            sliderUpdateTransitDetails: "",
            updateDistanceInKm: "",
            dataToUpdate: [],
            updateTransitTime: "",
        });
    }

    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", this.gridOptionsWrapper);
        this.gridColumnApi = params.columnApi;
    };

    onGridState = () => {
        //console.log(this.gridApi);
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();
        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }

    UpdateManualTransitBtn = (rowdata) => {
        const { _id: rowId, distance_in_km: distanceInKm, transit_time: transitTime } = rowdata.data;
        // Set state with the required data
        this.setState({
            loadshow: 'show-n',
            overly: 'show-m',
            sliderUpdateTransitDetails: 'slider-translate-30p',
            dataToUpdate: rowdata.data,
            updateDistanceInKm: distanceInKm,
            updateTransitTime: transitTime,
        });
    }

    handleInputChange = (event) => {
        const { name, value } = event.target;
        if (value === '' || /^\d+$/.test(value)) {
            this.setState({
                [name]: value
            });
        }
    }
    updateTransitData = async (event) => {
        // Prevent default form submission
        event.preventDefault();
        this.setState({ loadshow: 'show-m' });
        const { dataToUpdate, updateDistanceInKm, updateTransitTime } = this.state;
        const params = { row_id: dataToUpdate._id };
        // Add only updated fields to params
        if (dataToUpdate.distance_in_km != updateDistanceInKm) {
            params.distance_in_km = updateDistanceInKm;
        }
        if (dataToUpdate.transit_time != updateTransitTime) {
            params.transit_time = updateTransitTime;
        }
        if (!params.distance_in_km && !params.transit_time) {
            this.setState({
                show: true,
                basicTitle: "Successful",
                basicType: "success",
                loadshow: 'show-n',
                overly: "show-n",
            });
            this.onClickHideAll();
        } else {
            try {
                // Post the data to the backend
                console.log(params)
                const response = await redirectURL.post("/consignments/updateTransitTimeData", params);
                // Handle success and failure cases
                if (response.data.status === "success") {
                    this.setState({
                        show: true,
                        basicTitle: "Successful",
                        basicType: "success",
                        loadshow: 'show-n',
                        overly: "show-n",
                    });
                    this.onClickHideAll();
                    this.onLoadData()
                } else {
                    this.setState({
                        show: true,
                        basicTitle: "Failed",
                        basicType: "danger",
                        loadshow: 'show-n',
                        // overly: "show-n",
                    });
                }

            } catch (error) {
                console.error("Error:", error);
            }
        }
    };

    render() {

        var columnwithDefs = [
            {
                headerName: "Update",
                field: "",
                width: 100,
                // pinned: 'right',
                filter: true, resizable: true,
                cellRendererSelector: function (params) {
                    var rendComponent = {
                        component: 'UpdateManualTransit'
                    };
                    return rendComponent;
                },

            },
            {
                headerName: "Plant Name",
                field: "plant_name",
                width: 120,
            },
            {
                headerName: "Plant Code",
                field: "plant_code",
                width: 100,
            },
            {
                headerName: "Dealer Name",
                field: "consignee_name",
                width: 140,
            },
            {
                headerName: "Dealer Code",
                field: "consignee_code",
                width: 120
            },
            {
                headerName: "Distance Travelled (KM)",
                field: "distance_in_km",
                width: 160,
                editable: true,
                valueGetter: function (params) {
                    try {
                        return params.data.distance_in_km;
                    }
                    catch (e) {
                        return "";
                    }
                }
            },
            {
                headerName: "Transit Time",
                field: "transit_time",
                editable: true,
                width: 120,

            },
            {
                headerName: "Zone",
                field: "zone",
                width: 100,

            },
            {
                headerName: "Dealer City",
                field: "consignee_city",
                width: 120,
            },
            {
                headerName: "Dealer State",
                field: "consignee_state",
                width: 120,
            },



        ]

        return (

            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>
                                    <i className="icofont icofont-truck-alt cus-i"></i> <span>{this.state.pageTitle}  </span>

                                </h5>
                            </div>
                            <div className="card-body row">
                                <div className={"row col-xl-12 col-lg-12 "}>
                                    <div id="myGrid" style={{ height: "480px", width: "100%" }} className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnwithDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            frameworkComponents={this.state.frameworkComponents}
                                            overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            masterDetail={true}
                                            gridOptions={{
                                                context: { componentParent: this },
                                                getRowStyle: function (params) {
                                                    if (params.node.rowPinned) {
                                                        return { 'font-weight': 'bold', 'font-size': '16px' };
                                                    }
                                                },
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"slide-r " + (this.state.sliderUpdateTransitDetails)} style={{ overflow: "auto", width: "25%", }}>
                    <div className="slide-r-title">
                        <h4>
                            Update Transit Data
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative", paddingLeft: "20px" }}>
                        <div className={"row"} style={{ padding: '5px 0px', width: '100%', overflow: 'hidden', zIndex: '9999', height: "100em" }} >
                            <form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.updateTransitData} >
                                <div className="col-xl-12 col-lg-12 row form-group">
                                    <label htmlFor='distance' className="col-xl-12 col-lg-12 ml-0 pl-0" style={{ fontSize: '18px !important', fontWeight: '700' }}>Distance In Km</label>
                                    <input
                                        type="text"
                                        placeholder="Distance In KM"
                                        value={this.state.updateDistanceInKm}
                                        id="distance"
                                        name="updateDistanceInKm"
                                        className="form-control mb-2"
                                        autoComplete="off"
                                        onChange={this.handleInputChange}
                                        required
                                    />
                                </div>
                                <div className="col-xl-12 col-lg-12 row form-group">
                                    <label htmlFor='transitTime' className="col-xl-12 col-lg-12 ml-0 pl-0" style={{ fontSize: '18px !important', fontWeight: '700' }}>Transit Time In Days</label>
                                    <input
                                        type="text"
                                        placeholder="Enter Transit Time"
                                        value={this.state.updateTransitTime}
                                        id="transitTime"
                                        name="updateTransitTime"  // Must match the state key
                                        className="form-control mb-2"
                                        autoComplete="off"
                                        onChange={this.handleInputChange}
                                        required
                                    />
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group ml-0 pl-0 ">
                                    <button type="submit" className="btn btn-success pl-2 pr-2 pt-1 pb-1 " style={{ fontSize: '16px', borderRadius: '4px' }}>Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll}></div>
            </div>
        );
    }
}
