'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import CSVFileValidator from 'csv-file-validator';
import Select from 'react-select';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import ColumnGroup from './columngroupComponent';
import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import BatchDownload from "./batchdownload";
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent="";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;
var validatedBy="";



export default class BatchWiseClusters extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable : false,
            width : 160,
            resizable : true
        },
        eventAction: null,
        show: false,
        basicTitle:'',
        basicType:"default",
        loadshow:'show-n',
        overly:'',
        rowdata:[],
        sliderbulkupload : "",
        rowModelhighchartoptionsType: 'enterprise',
        frameworkComponents : {
            BatchDownload : BatchDownload
        },
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:50,
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        csvData:0,
        overly:"show-n",
        loadshow:"show-n",
        pagetitle:"",
        eventGridAction: "gridAction",
        csvcontent : [],
        file : "",
        batch_name : "",
        cluster_breaks : "2",
        cluster_radius : "0.5",
        notification_emails : ""
    }
    this.downloadbatchfile = this.downloadbatchfile.bind(this);
  }



componentDidMount(){
    this.setState({
        loadshow : "show-m",
        overly : "show-m"
    })
    loadDateTimeScript();
    
    this.logPageView(); // Log page view to GA
    let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
    this.setState({eventAction: eventAction});

    redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname})
    .then( async (resp) => {
        //console.log("resp ", resp)
        await this.setState({
            usergridstate:resp.data
        });
        await this.restoreGridStates();
    });
    redirectURL.post('/vicinity/getbatchwiseclusters').then((response)=>{
        console.log(response.data)
        this.setState({
            rowdata : response.data,
            loadshow : "show-n",
            overly : "show-n"
        })
        $("input[name=cluster_radius]").val("0.5");
        $("input[name=cluster_breaks]").val("2");
    }).catch((e)=>{
        console.log(e)
    })

}
   
logPageView = () => {
    try {
        if (googleAnalytics.page.enableGA) {
            googleAnalytics.logPageView();
            let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
            let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
            let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
            this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
        } else {
            console.log("GA disabled...");
        }
    } catch(error) {
        console.error("Error occured while logging to GA, error = ", error);
    }
}
 
onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
};
onGridState = () =>{
        //console.log(this.gridApi);
    
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
                
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
}



closeAlert = () => {
    this.setState({
        show: false
    });
}




onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    sliderbulkupload:"",
    
    });
    
}



 
onClickSaveGridState(){
    if (googleAnalytics.page.enableGA) {
        let pageTitle = this.state.pagetitle;
        let eventOptions = {
            "category": pageTitle,
            "action": this.state.eventGridAction,
            "label": "Save Grid Layout",
        }
        googleAnalytics.logEvent(eventOptions);
    }
    window.colState = this.gridColumnApi.getColumnState();
    window.groupState = this.gridColumnApi.getColumnGroupState();
    window.sortState = this.gridApi.getSortModel();
    window.filterState = this.gridApi.getFilterModel();
    
    let screenpage = '';

    if(this.props.match.path == "/sndPossibletransshipments")
    {
        screenpage='snd possible transhipment';
    }
    else if(this.props.match.path == "/prtPossibletransshipments")
    {
        screenpage='prt possible transhipment';
    }
    else if(this.props.match.path == "/tnpPossibletransshipments")
    {
        screenpage='tnp possible transhipment';
    }

    let reqparams = {
        gridcolumns:window.colState,
        gridgroup:window.groupState,
        gridcolsort:window.sortState,
        gridcolfilter:window.filterState,
        userId:localStorage.getItem("userid"),
        screenurl:window.location.pathname,
        screentitle:screenpage
    }
    //console.log("reqparams ", reqparams)
    redirectURL.post("/consignments/saveGridStates", reqparams)
    .then((response) => {
        //console.log("State response ",response.data)
        this.setState({
            show:true,
            basicTitle:"Successfully saved grid layout",
            basicType:"success",
            screenurl:window.location.pathname,
            screentitle:screenpage
        })
    })
    .catch(function(e){
        console.log("Error ", e)
    })
}

restoreGridStates()
{

    if(this.state.usergridstate.length > 0)
    {
        var windowstates = this.state.usergridstate;
        this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
        this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
        this.gridApi.setSortModel(windowstates[0].gridcolsort);
        this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
        
    }
}

resetState = () => {
    if (googleAnalytics.page.enableGA) {
        let pageTitle = this.state.pagetitle;
        let eventOptions = {
            "category": pageTitle,
            "action": this.state.eventGridAction,
            "label": "Reset Grid Layout",
        }
        googleAnalytics.logEvent(eventOptions);
    }
    this.gridColumnApi.resetColumnState();
    this.gridColumnApi.resetColumnGroupState();
    this.gridApi.setSortModel(null);
    this.gridApi.setFilterModel(null);
    //console.log('column state reset');
    var reqparams = {
        userId:localStorage.getItem("userid"),
        screenurl:window.location.pathname,
        screentitle:this.state.screenpage
    }

    redirectURL.post("/consignments/removeUserGridState", reqparams)
    .then((response) => {
        this.setState({
            show:true,
            basicTitle:"Successfully reset default grid layout",
            basicType:"success"
        })
    })

};

changeFileHandler = async (e) => {
    const config = {
        headers: [
            { 
                name: 'consignment_code',
                inputName: 'consignment_code',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
        ]
    }
    var data = CSVFileValidator(e.target.files[0], config)
    .then((csvData) => {
        this.setState({
            csvcontent: csvData.data
        });
    })
    .catch(err => {})

    this.setState({
        file: e.target.files[0]
    });

    if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
    {
      
    }
    else{
        e.target.value = null;
        this.setState({
            uploadFile:'',
            file:"",
            show: true, 
            basicType:'danger', 
            basicTitle:'Please upload file having extensions .csv only.',
        });
    }
    
}

uploadBulkForceFormHandler(event)
{
    event.preventDefault();

    if (googleAnalytics.page.enableGA) {
        let pageTitle = this.state.pageTitle;
        let eventOptions = {
            "category": pageTitle,
            "action": this.state.eventGridAction,
            "label": googleAnalytics.page.action.formAction,
        }
        googleAnalytics.logEvent(eventOptions);
    }
    var bulkData = this.state.csvcontent;
    var consignment_codes_list=[];
    bulkData.map(function(e,index){
        if(index !=0 && index != (bulkData.length-1))
        {
            consignment_codes_list.push(e.consignment_code)
        }
        
    });
    console.log(bulkData);
    console.log(consignment_codes_list);
    var formdata = new FormData();
    var emails = $("input[name=notification_emails]").val();
    emails = emails.replace(/\s/g,"");
    if(emails.indexOf(",") != -1 )
    {
        emails = emails.split(",");
    }
    else
    {
        emails = [emails]
    }
    formdata.append("batch_file",this.state.file)
    formdata.append("batch_name",$("input[name=batch_name]").val())
    formdata.append("cluster_radius",$("input[name=cluster_radius]").val())
    formdata.append("cluster_breaks",$("input[name=cluster_breaks]").val())
    formdata.append("emails",JSON.stringify(emails))
    formdata.append("consignment_codes",JSON.stringify(consignment_codes_list))
    formdata.append("user",localStorage.getItem("username"))
    redirectURL.post("/consignments/uploadClusterBatches",formdata,{
        headers:{
            'content-type': 'multipart/form-data',
            //'Access-Control-Allow-Origin':"*",
            'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, DELETE',
            'Access-Control-Allow-Headers': 'Origin, Content-Type'
        }
    }).then((response)=>{
        this.setState({
            basicTitle : "Success",
            basicType : "success",
            show : true,
            file : "",
            bulkForceCloseSlide:"",
            overly:"show-n",
            loadshow : "show-n"
        })
        window.location.reload();
    }).catch((e)=>{
        console.log(e);
    })
}

onClickShowBulkUpload()
{
    this.setState({
        sliderbulkupload : "slider-translate-40p",
        overly : "show-m"
    })
}
checkbatchname(e)
{
   var batch_names_list = [];
   console.log(this.state.rowdata);
   this.state.rowdata.map(function(e){
       console.log(e)
       var names = e.batch_name.toLowerCase()
       names = names.replace(/\s/g,"");
       batch_names_list.push(names);
    });
    var inputval = e.target.value.toLowerCase();
    inputval = inputval.replace(/\s/g,"");
    if(batch_names_list.includes(inputval))
    {
        this.setState({
            basicTitle : "Batch Name is Already Present",
            basicType : "warning",
            show : true
        });
        $("input[name=batch_name]").val("");
    }
}

downloadbatchfile(params)
{
    var file = params.file_upload_path;
    if(file.includes("\\"))
    {
        var file_splitted = file.split("\\");
    }
    else
    {
        var file_splitted = file.split("/");
    }
    console.log(file_splitted[file_splitted.length-1])
    redirectURL.post("/consignments/downloadRingiFile",{"ringiFile":file_splitted[file_splitted.length-1]}).then((response)=>{

    }).catch((e)=>{

    })
}

render() {
    const batchwisecolumns = [
        {
            field:'_id',
            headerName:'',
            colId:"_id",
            width: 120,
            cellRenderer:function(params){
                if(params.data.batch_status == "Completed")
                {
                    let basestring = params.data.batch_name;
                    let encryptedstring = window.btoa(basestring);
                    var htmloption = '<button class="btn btn-success" style="padding-top:0px"><a href="/batchwisecatalysis/'+encryptedstring+'" class="custom-btn label label-success"> View output</a></button>';
                    return htmloption;
                }
                
            }  				
        },
        {
            headerName : "Batch Name",
            field : "batch_name",
            cellRenderer : function(e){
                try{
                    return "BatchDownload"
                }catch(e){}
               
            }

        },
        {
            headerName : "Cluster Radius",
            field : "cluster_radius_kms",
        },
        {
            headerName : "Minimum Trucks in Cluster",
            field : "cluster_min_breaks",
        },
        {
            headerName : "Batch Status",
            field : "batch_status",
        },
        {
            headerName : "Remarks",
            field : "remarks",
        },
        {
            headerName : "Created By",
            field : "created_by",
        },
        {
            headerName : "Created On",
            field : "created_on",
            valueGetter : function(params)
            {
                return getHyphenDDMMMYYYYHHMM(params.data.created_on)
            }
        },
        {
            headerName : "Notification Emails",
            field : "notification_mails"
        }
    ]
    return (
    <div class="container-fluid">
            
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>
        {/* <div className="row col-xl-12 col-lg-12">
            <div className="row col-xl-12 col-lg-12">
                <div className="col-xl-2 col-lg-2 form-group">
                    <label>Select Month</label>
                   
                        <input type="text" className="selected_date form-control" id="dispatch_dashboard_date"  />

                </div>
                <div className="form-group col-xl-2 col-lg-2">
                    <label>&nbsp;</label><br />
                    <button type="button" className="btn btn-success" autoComplete="off" onClick={this.onClickSubmit.bind(this)}>Submit</button>
                </div>
                
            </div>
        </div> */}
        <div className="row">
            <div className="col-xl-12 col-lg-12" style={{paddingRight:"3em"}}>
                <div className="">
                    <h5>
                        <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Clusters </span>
                        <span className="layoutbtns float-right">
                            <button className="float-right btn white btn-info" onClick={this.onClickShowBulkUpload.bind(this)}>
                                <i className="icofont icofont-save"></i> Create New Batch
                            </button>
                            {/* <button className="float-right btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
                                <i className="icofont icofont-save"></i> Save Grid Layout
                            </button>
                            <button className="float-right btn white btn-info" style={{marginRight:"10px"}} onClick={this.resetState.bind(this)}>
                                <i className="icofont icofont-refresh"></i> Reset Default Layout
                            </button> */}
                        </span>
                    </h5>
                </div>				   			
                <div className="pt-15px" >
                    <div className="row">
                        <div id="myGrid" style={{width:"100%",height:"478px"}} className="ag-theme-balham">    
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={batchwisecolumns}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.rowdata}
                                enableCharts={false}
                                // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                rowClassRules={this.state.rowClassRules}
                                // statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus= {true}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                                // components={this.state.components}
                                enableRangeSelection= {true}
                                // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                />
                        </div>
                        
                        
                    </div>
                </div>
            </div>
        </div>
        
        <div className={"slide-r "+(this.state.sliderbulkupload)} style={{overflow:"hidden"}}>
            <h3 className="subH">Upload Batch File</h3>
                    
            <div className="slide-r-body" style={{position:"relative"}}>
                
                <div className="container-fluid">
                    <form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkForceFormHandler.bind(this)}>
                    <div className="form-group mt-20p">
                        <label className="">Batch Name *</label> 
                        <input type="text" name="batch_name" id="" onBlur={this.checkbatchname.bind(this)} className="form-control" required  />
                    </div>
                    <div className="form-group mt-20p">
                        <label className="">Cluster Radius *</label> 
                        <input type="number" name="cluster_radius"   className="form-control" step="0.1" min="0.2" required/>
                    </div>
                    <div className="form-group mt-20p">
                        <label className="">Minimum trucks in a cluster *</label> 
                        <input type="number" name="cluster_breaks"   className="form-control" min="2" required/>
                    </div>
                    <div className="form-group mt-20p">
                        <label className="">Notification Emails *</label> 
                        <input type="text" name="notification_emails"  placeholder="Please Provide Emails (Comma Separated)"  id="" className="form-control" required  />
                    </div>
                    <p style={{color:"#ff0000"}}>The cluster analysis will take some time. We will notify you when the process is completed.</p>
                    <div className="form-group mt-20p">
                        <label className="">Upload File *</label> 
                        <input type="file" name="uploadFile" id="bulkUploadID" onChange={this.changeFileHandler}  className="form-control" required  />
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-success">Submit</button>
                    </div>
                    </form>
                    <div className="form-group">
                        <p style={{color:"#ff0000"}}>* Note : All fields related to the form are mandatory and cannot be empty</p>
                        
                        {/* <p style={{color:"green"}}>* Please use the below fields as headers while uploading</p>
                        <p style={{color:"cornflowerblue"}}> -> consignment_code</p>
                        <p style={{color:"cornflowerblue"}}> -> reason</p> */}
                
                    </div>
                    <div className="form-group">
                        <a className="btn btn-primary" href={require('../../assets/json/bulk_batch_wise_sample.csv')} target="_blank">Sample Template</a>
                    </div>
                    <div className="form-group">
                        {/* <a className="btn btn-primary" href={require('../../assets/json/Bulk_tickets_update_file.csv')} target="_blank">Sample Template</a> */}
                    
                    </div>		
                </div>
            </div>
        </div>

        <div className={"dataLoadpage " +(this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
    </div>
    );
  }
}


function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript(){
	// $('.datetimepicker_mask').datetimepicker({
	// 	mask:'39-19-9999 29:59',
	// 	format:'m-Y'
	// });
	// $('.datetimepicker_date').datetimepicker({
	// 	mask:'39-19-9999',
    //     format:'m-Y',
    //     validateOnBlur: false,
    //     timepicker:false,
    //     monthpicker:true,
	// });
    // var index  =window.document.getElementsByTagName("script")[1]
    // var script = window.document.createElement("script")
    // script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	// //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	// script.async=true
    // script.defer = true
    // index.parentNode.insertBefore(script,index)

    $("#dispatch_dashboard_date").datepicker( {
        changeMonth: true,
        changeYear: true,
        showButtonPanel: false,
        dateFormat: "mm-yy",
        onClose: function (dateText, inst) {

            //Get the selected month value
            var month = $("#ui-datepicker-div .ui-datepicker-month :selected").val();

            //Get the selected year value
            var year = $("#ui-datepicker-div .ui-datepicker-year :selected").val();

            //set month value to the textbox
            $(this).datepicker('setDate', new Date(year, month, 1));
        },
        beforeShow: function(input, inst) {
            $('#ui-datepicker-div').addClass("dispatch_dashboard_date");
        }
    });
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

