import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";
import Select from 'react-select';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import CustomLoadingCellRenderer from "./customLoadingCellRenderer";
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD,getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY } from '../common/utils'; 
import 'react-vertical-timeline-component/style.min.css';
import $ from 'jquery';
// import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

var containerslist = [];
var uncheckcontainerslist = [];

export default class AssignACTLContainers extends Component {

	constructor(props){
		super(props);
		this.state={
			pageTitle: "Assign ACTL Containers",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			overly:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
            basicTitle:'',
			basicType:"default",
			modules: AllModules,
			sidebarSubHeader:'',
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true

      	    },
      	    rowData: [],
      	    rowPlannedData:[],
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
			  overlayNoRowsTemplate: "",
			  frameworkComponents: {
				
				customLoadingCellRenderer: CustomLoadingCellRenderer
			},

      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,
				
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:100,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
			rowClassRules: {
				"highlitRow": "data.transit_delay == 1",
				"green":"data.status >= 3"

            },
            rowSelection: "multiple",
            autoGroupColumnDef: {
                headerName: "Container No",
                field: "container_no",
                width: 200,
                cellRenderer: "agGroupCellRenderer",
                cellRendererParams: { checkbox: true }
              },
            containerslist:[],
            errorMessage:'',
            successMessage:'',
			pickup_plan_date:'',
			showAppBtn:"show-n",
			components: { datePicker: getDatePicker() },			  
			usergridstate:[],
			screenurl:"",
            screentitle:"",
            preselecteditems : []
			
		}
	}
	componentDidMount = async() => {
		//console.log("Props ", this.props.match)
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}

		redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname})
		.then( async (resp) => {
			await this.setState({
				usergridstate:resp.data
			});
			await this.restoreGridStates();
		})
		var urlpath;
        var dpt='';
	   
		//var d = new Date(moment(event).format("YYYY-MM-DD"));
		var c = new Date();
		var e = new Date(moment(c).format("YYYY-MM-DD"));
		var tomorrow = moment.parseZone(c).add(1,"days").format('YYYY-MM-DD');
        //console.log("d ", d.getTime())
		//console.log("tomorrow ", tomorrow)
		//var ddt = moment.parseZone(d).format("x");
		var edt = moment.parseZone(e).format("x");
		//console.log("ddt ", ddt)
		this.setState({
			loadshow:'show-m',
			rowData:''
		});
		
        var startdate = e.getFullYear()+"-"+(e.getMonth() +1)+"-"+(e.getDate()<10?("0"+e.getDate()):e.getDate());
        //console.log("d ", getDDMMYYYY(startdate))
        this.setState({
            pickup_plan_date:getYYYYMMDD(startdate)
        });
        
        

        //console.log("alloycontainers ", alloycontainers.length)
        //icdgarhialloyplandatabydate
        var reqparams={
        }
        var urlpath = '/consignments/actlimportscontainers';
        redirectURL.post(urlpath,reqparams)
        .then((response) => {
            console.log("response ",response.data)
            var recordsarr = []
            recordsarr = response.data.records
            var existsactls = []
            if(recordsarr.length > 0)
            {
                recordsarr.map((item) => {
                    if(item.target_icd == 'actl')
                    {
                        existsactls.push(item)
                    }
                })
            }
            console.log("existsactls ", existsactls)
            recordsarr.sort(GetSortAscOrder("container_no"));
            this.setState({
                loadshow:"show-n",
                overly:'show-n',
                rowData:recordsarr,
                preselecteditems:existsactls
            });
        })
        .catch(function(e){

        })
        
	};
	
	
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	
	//    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	   //console.log("Deptcode " ,this.state.deptcode)
	   this.gridApi.forEachLeafNode( (node) => {
			if (node.data.target_icd == 'actl' || node.data.target_icd == undefined)  {
				node.setSelected(true);
			}
		});
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);

		   this.gridApi.forEachLeafNode( (node) => {
			   //console.log(node)
				if (node.data.pickup_plan_date != '' && node.data.pickup_plan_date != undefined) {
					node.setSelected(true);
				}
			});
		   
	}

	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:''
		});
		
	}
	closeAlert = () => {
        this.setState({
            show: false,
            pickup_plan_date:''
        });
    }
   
    onRowSelected(event) {
        //console.log("row " + event.node.data.container_no+ " selected = " + event.node.selected);
       // var items=[];
	   var rowCount = event.api.getSelectedNodes();
	  
	   containerslist=[];
	   if(event.node.selected == false)
	   {
		uncheckcontainerslist.push(event.node.data.container_no)
	   }
       //console.log("rowCount ", rowCount)
       
	   
        rowCount.map((item) => {
            containerslist.push(item.data.container_no)
        })
			
		//console.log("checkcontainerslist ", this.state.containerslist)
		//console.log("uncheckcontainerslist ", uncheckcontainerslist)
		if(containerslist.length > 0)
		{
			this.setState({
				showAppBtn:'show-m'
			});
		}
        else{
			if(uncheckcontainerslist.length > 0)
			{
				this.setState({
					showAppBtn:'show-m'
				});
			}
			else
			{
				this.setState({
					showAppBtn:'show-n'
				});
			}
		}
      }

	onClickSaveGridState(){
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Save Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		
		let screenpage = 'icd garhi plan';


		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates()
	{
		///console.log("this.state.usergridstate ", this.state.usergridstate)
		try{
			if(this.state.usergridstate.length > 0)
			{
				var windowstates = this.state.usergridstate;
				console.log("windowstates[0].gridcolumns ",windowstates[0].gridcolumns)
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
			
			}
		}
		catch(e){}
	}

	resetState = () => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Reset Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

	};

    formAssignHandler = (event) => {
		event.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedUpdateNow,
			}
			googleAnalytics.logEvent(eventOptions);
		}
        this.setState({
            loadshow:'show-m'
        });
        //var formdata = new FormData(event.target);
		var itemsToUpdate = [];
		this.gridApi.forEachNodeAfterFilterAndSort(function(rowNode, index) {
			var data = rowNode.data;
			itemsToUpdate.push(data);
		});

		var rowCount = this.gridApi.getSelectedNodes();
		//console.log("rowCount ", rowCount)
        var assignarr=[];
        var selectedrows = []
		rowCount.map((dt) => {
			var item = dt.data;
			//console.log("item ", item)
			var cdate =new Date();
			var currentDate = getDDMMYYYYHHMMSS(cdate);
            selectedrows.push(item);
            // assignarr.push({
            //     container_no:item.container_no,
            //     hbl_no:item.hblnos
            // })
        
			
        })
        var preselecteditems = this.state.preselecteditems;
        //console.log("selectedrows ", selectedrows)
        //console.log("preselecteditems ", preselecteditems)
        var nmatchedrecords = []
        if(preselecteditems.length > 0)
        {
            nmatchedrecords = preselecteditems.filter(f =>
                !selectedrows.some(d => d.container_no == f.container_no)
            );

        }
        var paramarray  = []
        if(nmatchedrecords.length > 0)
        {
            paramarray = nmatchedrecords;
        }
        
        if(selectedrows.length > 0 || preselecteditems.length > 0)
        {
            var urlpath = '/consignments/updateassignactlcontainers';
            var reqparams = {
                updateData:selectedrows,
                uncheckitems:paramarray
            }
           // console.log("reqparams ", reqparams)
            redirectURL.post(urlpath,reqparams)
            .then((response) => {
                //console.log("Response ", response)
                var response = response.data;
                if(response.status == 'success')
                {
                    this.setState({
                        loadshow:'show-n',
                        show:true,
                        basicTitle:"Successfully updated",
                        basicType : "success"
                    })
                }
                else
                {
                    this.setState({
                        loadshow:'show-n',
                        show:true,
                        basicTitle:"Failed",
                        basicType : "danger"
                    })
                }
                
            })
            .catch(function (error) {
                console.log(error);
            });
        }
        else
        {
            this.setState({
                loadshow:'show-n',
                show:true,
                basicTitle:"Please select checkbox",
                basicType : "warning"
            })
        }
		
		
	}
	
	render(){
		//console.log("Rendere ",this.state.deptcode)
		const columnwithDefs = [            	
            {
                headerName: "Assign",
                field: "_id",
                width: 80,
				filter: true,
				pinned:'left',
                resizable: true,
				editable:false,
				//headerCheckboxSelection: true,
                //headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                cellRenderer:function(params){
                    console.log("target_icd ", params.data.target_icd)
                    if(params.data.target_icd == "actl")
                    {
                        params.node.setSelected(true);
                    }
                    
                }
            },
            {
                headerName: "Container No",
                field: "container_no",
                width: 140,
				filter: true,
				pinned:'left',
                resizable: true,
				editable:false
            },
            // {
            //     headerName: "MBL NO",
            //     field: "mbl_no",
            //     width: 140,
            //     filter: true,
			// 	pinned:'left',
            //     resizable: true,
            //     editable:false
            // },
            {
                headerName: "BL NO",
                field: "hblnos",
                width: 200,
                filter: true,
				pinned:'left',
                resizable: true,
                editable:false
            },
            {
                headerName: "Searates POD",
                field: "searates_pod",
                width: 140,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Status",
                field: "last_status",
                width: 140,
                filter: true,
                resizable: true,
				editable:false,
				valueGetter:function(params){
					if(params.data.last_status == "Port Out")
					{
						return "In Transit to ICD";
					}
					else
					{
						return params.data.last_status;
					}
				}
            },
            {
                headerName: "ETD POL",
                field: "searates_etd_pol",
                width: 140,
                filter: true,
                resizable: true,
                editable:false,
                valueGetter:function(params){
                    if(params.data.searates_etd_pol != "")
                    {
                        return getHyphenDDMMMYYYY(params.data.searates_etd_pol);
                    }
                    else
                    {
                        return "";
                    }
                }
            },
            {
                headerName: "ATD POL",
                field: "searates_atd_pol",
                width: 140,
                filter: true,
                resizable: true,
                editable:false,
                valueGetter:function(params){
                    if(params.data.searates_atd_pol != "")
                    {
                        return getHyphenDDMMMYYYY(params.data.searates_atd_pol);
                    }
                    else
                    {
                        return "";
                    }
                }
            },
            {
                headerName: "ETA POD",
                field: "searates_eta_pod",
                width: 140,
                filter: true,
                resizable: true,
                editable:false,
                valueGetter:function(params){
                    if(params.data.searates_eta_pod != "")
                    {
                        return getHyphenDDMMMYYYY(params.data.searates_eta_pod);
                    }
                    else
                    {
                        return "";
                    }
                }
            },
            {
                headerName: "ATA POD",
                field: "searates_ata_pod",
                width: 140,
                filter: true,
                resizable: true,
                editable:false,
                valueGetter:function(params){
                    if(params.data.searates_ata_pod != "")
                    {
                        return getHyphenDDMMMYYYY(params.data.searates_ata_pod);
                    }
                    else
                    {
                        return "";
                    }
                }
            },
            {
                headerName: "Country",
                field: "country_code",
                width: 140,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Supplier",
                field: "supplier_code",
                width: 140,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Booking NO",
                field: "booking_no",
                width: 140,
                filter: true,
                resizable: true,
                editable:false
            }
        ];

		return(
			<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
				<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span> {this.state.pageTitle} </span>
										
									   <span className="layoutbtns float-right">
										{/* <button className="float-right btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
											<i className="icofont icofont-save"></i> Save Grid Layout
										</button>
										<button className="float-right btn white btn-info" style={{marginRight:"10px"}} onClick={this.resetState.bind(this)}>
											<i className="icofont icofont-refresh"></i> Reset Default Layout
										</button> */}
									</span>
									
								</h5>
				   			</div>				   			
		            		<div className="card-body">
                                <div className="row col-xl-12 col-lg-12">
								    
									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>
                                   
                                    <div className="col-xl-12 col-lg-12">										
                                        <div id="myGrid" style={{ height: "500px",width: "100%"}}  className="ag-theme-balham">
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={columnwithDefs}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.rowData}
                                                enableCharts={false}
                                                enableRangeSelection={true}
                                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                onGridReady={this.onGridReady}
                                                onGridState={this.onGridState}
                                                statusBar={this.state.statusBar}
                                                //sideBar={this.state.sideBar}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                                rowSelection={this.state.rowSelection}
                                                onRowSelected={this.onRowSelected.bind(this)}
                                                suppressRowClickSelection={true}
                                                //floatingFilter={true}
                                                //onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                                //onCellClicked={this.popmarker}
                                            />
                                        </div>
                                        <button type="button" onClick={this.formAssignHandler.bind(this)} className="btn btn-primary float-right">Assign Now</button>
										
                                    </div>
                            
                                </div>
				   			</div>
				   		</div>	
				   	</div>
					   
				 </div>
				 
				
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
              	
		);
	}
}

function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}                 
function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = parseInt(hours);
	var minutes = (num-(rhours*3600)) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}                              

function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function distance(lat1, lon1, lat2, lon2, unit) {
	if ((lat1 == lat2) && (lon1 == lon2)) {
		return 0;
	}
	else {
		var radlat1 = Math.PI * lat1/180;
		var radlat2 = Math.PI * lat2/180;
		var theta = lon1-lon2;
		var radtheta = Math.PI * theta/180;
		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) {
			dist = 1;
		}
		dist = Math.acos(dist);
		dist = dist * 180/Math.PI;
		dist = dist * 60 * 1.1515;
		if (unit=="K") { dist = dist * 1.609344 }
		if (unit=="N") { dist = dist * 0.8684 }
		return dist;
	}
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }

function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}
function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getDatePicker() {
	function Datepicker() {}
	Datepicker.prototype.init = function(params) {
	  this.eInput = document.createElement("input");
	  //this.eInput = this.eInput.setAttribute("class","datepicker");
	  this.eInput.value = params.value;
	  console.log(this.eInput)
	  window.$(".datepicker").datepicker({ dateFormat: "dd/mm/yy" });
	};
	Datepicker.prototype.getGui = function() {
	  return this.eInput;
	};
	Datepicker.prototype.afterGuiAttached = function() {
	  this.eInput.focus();
	  this.eInput.select();
	};
	Datepicker.prototype.getValue = function() {
	  return this.eInput.value;
	};
	Datepicker.prototype.destroy = function() {};
	Datepicker.prototype.isPopup = function() {
	  return false;
	};
	return Datepicker;
  }


function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};
function GetSortOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
  } 
  function GetSortAscOrder(prop) {    
      return function(a, b) {    
          if (a[prop] > b[prop]) {    
              return 1;    
          } else if (a[prop] < b[prop]) {    
              return -1;    
          }    
          return 0;    
      }    
    } 