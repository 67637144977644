import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import Modal from 'react-responsive-modal';
import _ from "lodash";
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import CSVFileValidator from 'csv-file-validator';
import Deliverconsignment from './deliverconsignment';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Consignmentforceclose from '../manage/consignmentforceclose.js';
import MarkDeliver from "../layouts/markDeliveredButton.js";
import CustomLoadingCellRenderer from "../layouts/customLoadingCellRenderer";
import CourierActions from '../layouts/courierActions';
import 'react-vertical-timeline-component/style.min.css';
import TruckSidebarComponent from './truckSidebarComponent';
import * as Datetime from 'react-datetime';
import CourierCounter from './couriercounter';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDDHHMMSS,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS } from '../common/utils'; 
import $ from 'jquery';
// import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
//import datetimepicker from 'jquery-datetimepicker';
import Select from 'react-select';
var googleAnalytics = require("../common/googleanalytics");
var redirectURL = require('../redirectURL');
 
var moment = require('moment');
//$.datetimepicker.setLocale('en');
var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749','#F73E3E', '#B7B3B3' ];
var selectedColor;
var colorButtons = {};
var fencingLength=0;
var mapZoom=12;
var coordinates=[];
var geoFencingArea;

export default class ManageCourierConsignments extends Component {

	constructor(props){
		super(props);
		this.state={
			pageTitle: "",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			uploadDivWidth:'0%',
			sliderTranslate:'',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			timelineinfo:'',
			consignment_code:'',
			mapinfo:'',
			loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			alerterrshow:'fade',
			alert:null,
			show: false,
			markDeliverDefault:"",
			deliveredOtherReason:"",
            basicTitle:'',
			basicType:"default",
			showPriority: false,
            basicPriorityTitle:'',
			basicPriorityType:"default",
			selectedRow:"",
			modules: AllModules,
			filterstatess:{},
      	    columnsdatas:{},
      	    rowgroupdcols:[],
      	    valuecolumns:[],
      	    pivotmodeis:false,
			pivotcols:[],
			defTransitCoords:'',
      	      
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true
      	    },
      	    rowData: null,
      	      
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
			frameworkComponents: {
				courierActions:CourierActions,
				consignmentforceclose : Consignmentforceclose,
				MarkDeliver:MarkDeliver,
				customLoadingCellRenderer: CustomLoadingCellRenderer,
				deliverconsignment:Deliverconsignment
			},

      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,
			
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
			rowClassRules: {
				"highlitRow": "data.transit_delay == 1"
			},
			intransit : [],
			outdeliver : [],
			deliver : [],
			datana : [],
			intransitcount : [],
			outdelivercount : [],
			delivercount : [],
			datanacount : [],
			prioritydata : [],
			prioritydatacount : 0,
			totalcount:0,
			from_date:'',
			to_date:"",
			consigner:[{"value":"all", "label":"All"}],
			consigners:[],
			originaldata:[],
			counttype:'all',
			forceCloseRowNode:"",	
			forceclosedata:"",
			sliderForceCloseTranslate:"",
			overly : "show-n",
			markDeliverRowNode : "",
			markDeliverData:"",
			sliderMarkDeliverTranslate:"",
			bulkPrioritySlide : "",
			bulkForceCloseSlide : "",
			bulkMarkDeliverSlide : "",
			file:"",
			inputMarkDeliverFile : "",
			uploadDivWidth:"",
			csvcontent:"",
			mdCsvContent : "",
			deliveryStatus : "",
			deliverStatusValue : "",
			distance_from_dealer_location : "",
			deliveredDeptCode : "",
			deliveredTruckNo : "",
			delivermodal: false,
			hideTransporterBtns : "show-m",
			confirmDelivery:"",
			displayOtherField : "show-n",
			selectedDeliveryReason : "",
			usergridstate:[],
			screenurl:"",
			screentitle:"",
			critical_data : [],
			critical_counter_data : [],
			cancelled_data: [],
			pod_received_data: [],
			trip_closed_by_pod: [], 
			delDate:"none"
			
		}
		this.onClickForceClose = this.onClickForceClose.bind(this);
		this.onClickMarkDelivered = this.onClickMarkDelivered.bind(this);
		this.onLoadShowCouriers = this.onLoadShowCouriers.bind(this);
		this.submitForm = this.submitForm.bind(this);
		this.cipher = this.cipher.bind(this);
	}
	cipher = (salt) => {
		const textToChars = text => text.split('').map(c => c.charCodeAt(0));
		const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
		const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
		try{
			return text => text.split('')
				.map(textToChars)
				.map(applySaltToChar)
				.map(byteHex)
				.join('');
		}
		catch(e){
	
		}
		
	}

	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
	componentDidMount(){
		loadDateTimeScript();
		var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		// $("#from_date,#to_date").on("click",function(){
		// 	$(".xdsoft_timepicker").css("display","none");
		// });
		this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});


		redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname})
		.then( async (resp) => {
			await this.setState({
				usergridstate:resp.data
			});
			await this.restoreGridStates();
		})
		//console.log("Props ", this.props.match)
		if(this.props.match.path == "/activecourierconsignments")
		{
			var fdate = moment.parseZone().subtract(3, 'days').format('DD-MM-YYYY');
		}
		else
		{
			var fdate = moment.parseZone().subtract(10, 'days').format('DD-MM-YYYY');

		}
		var tdate = moment.parseZone().format('DD-MM-YYYY');
		var fdt = fdate.split("-");
		var fromdate = fdt[2]+"-"+fdt[1]+"-"+fdt[0];
		var tdt = tdate.split("-");
		var todate = tdt[2]+"-"+tdt[1]+"-"+tdt[0];
		document.getElementById("from_date").value=fdate;
		document.getElementById("to_date").value=tdate;
			
		var urlpath;
		var dpt;
		var counttype;
		var serviceProviders = [];
		if(localStorage.getItem("user_type") == "TRANSPORTER")
		{
			this.setState({
				hideTransporterBtns :"show-n"
			});
			serviceProviders = JSON.parse(localStorage.getItem('transportercode'))
		}
		

		if(this.props.match.path == "/courierconsignments")
		{
			urlpath = '/consignments/couriers';
			counttype='all';
			
			var preqparams = {
				from_date:fromdate,
				to_date:todate,
				consigner_code:[{"value":'all',"label":"All"}],
				serviceProviders : serviceProviders,
				is_filter_call : 0
			}
			this.setState({
				counttype:counttype,
				loadshow:'show-m',
				from_date:fdate,
				to_date:tdate,

			});
			redirectURL.post("/consignments/summarysecondleveldata",{dept_code:encode("LOG-PRT (Courier)")})
			.then(async (res) =>{
				console.log("res.data.message[0] ",res.data)
				var criticaldata=res.data.message[0].critical_data;
				this.setState({
					pageTitle : "Courier Consignments (All)",
					critical_data : criticaldata
				});
				this.onLoadShowCouriers(preqparams);
			}).catch((e)=>{
				console.log(e);
			});
			
			
		}
		else if(this.props.match.path == "/activecourierconsignments")
		{
			urlpath = '/consignments/activecouriers';
			counttype='active';
			
			var preqparams = {
				from_date: fromdate,
				to_date: todate,
				consigner_code:[{"value":'all',"label":"All"}],
				serviceProviders : serviceProviders,
				is_filter_call : 0
			}
			redirectURL.post("/consignments/summarysecondleveldata",{dept_code:encode("LOG-PRT (Courier)")})
			.then(async (res) =>{
				console.log("res.data.message[0] ",res.data)
				var criticaldata=res.data.message[0].critical_data;
				this.setState({
					counttype:counttype,
					loadshow:'show-m',
					from_date:fdate,
					to_date:tdate,
					pageTitle : "Courier Consignments (Active)",
					critical_data : criticaldata
				});
				this.onLoadShowCouriers(preqparams);
			}).catch((e)=>{
				console.log(e);
			});
			
		}
		else if(this.props.match.path == "/deliveredcourierconsignments")
		{
			urlpath = '/consignments/deliveredcouriers';
			counttype='deliver';
			this.setState({
				pageTitle : "Courier Consignments (Delivered)"
			})
			var preqparams = {
				from_date:fromdate,
				to_date:todate,
				consigner_code:[{"value":'all',"label":"All"}],
				serviceProviders : serviceProviders,
				is_filter_call : 0
			}
			this.setState({
				counttype:counttype,
				loadshow:'show-m',
				from_date:fdate,
				to_date:tdate,

			});
			this.onLoadShowCouriers(preqparams);
		}
		else if(this.props.match.path == "/invalidcourierconsignments")
		{
			urlpath = '/consignments/getInvalidCourierDataByParams';
			counttype='invalid';
			this.setState({
				pageTitle : "Courier Consignments (Invalid)"
			})
			var preqparams = {
				from_date:fromdate,
				to_date:todate,
				consigner_code:[{"value":'all',"label":"All"}],
				serviceProviders : serviceProviders,
				is_filter_call : 0
			}
			this.setState({
				counttype:counttype,
				loadshow:'show-m',
				from_date:fdate,
				to_date:tdate,

			});
			this.onLoadShowCouriers(preqparams);
		}

		// preqparams['is_filter_call'] = 0;
		
	};

	onLoadShowCouriers(parameters)
	{
		var urlpath;
		

		if(this.props.match.path == "/courierconsignments")
		{
			urlpath = '/consignments/couriers';
		}
		else if(this.props.match.path == "/activecourierconsignments")
		{
			urlpath = '/consignments/activecouriers';
		}
		else if(this.props.match.path == "/deliveredcourierconsignments")
		{
			urlpath = '/consignments/deliveredcouriers';
		}
		else if(this.props.match.path == "/invalidcourierconsignments")
		{
			urlpath = '/consignments/getInvalidCourierDataByParams';
		}
		
		
	    redirectURL.post(urlpath,parameters)    
		  .then((response) => {
			var records = response.data;
			//console.log("records", records);
			var intransitarr=[];
			var priorityarr=[];
			var outdeliverarr = [];
			var deliverarr = [];
			var forcearr = [];
			var datanaarr = [];
			var confirmDelivery=[]
			records.map((item) => {
				
				//In Transit
				if(item.consignment_status == 'In Transit')
				{
					intransitarr.push(item);
				}

				//Out of Delivery
				if(item.consignment_status == 'Out For Delivery')
				{
					outdeliverarr.push(item);
				}

				//Delivered
				if(item.consignment_status == 'Delivered' || item.consignment_status == 'Force Closed')
				{
					deliverarr.push(item);
				}

				if(item.consignment_status == 'Force Closed')
				{
					forcearr.push(item);
				}
				//Data Not Available
				if(item.consignment_status == '' || item.consignment_status == null || item.consignment_status == "Invalid docket")
				{
					datanaarr.push(item);
				}
				if(item.tpt_confirm_delivery == 1)
				{
					confirmDelivery.push(item);
				}
				//Priority
				if(item.is_priority == 1)
				{
					priorityarr.push(item)
				}
				
			});
			let newArray = []; 
            // console.log("item.consignment_status",outdeliverarr)
			// Declare an empty object 
			let uniqueObject = {}; 
			var objTitle;
			// Loop for the array elements 
			for (let i in records) { 
	
				// Extract the title 
				objTitle = records[i]['consigner_code']; 
	
				// Use the title as the index 
				uniqueObject[objTitle] = records[i]; 
			} 
			
			// Loop to push unique object into array 
			for (var a in uniqueObject) { 
				newArray.push(uniqueObject[a]); 
			} 
			
			//console.log("newArray ", newArray)
			if(parameters.is_filter_call == 0)
			{
				var consigners=[];
				newArray.map((ccode) => {
					consigners.push(ccode.consigner_code)
				})
			}
			else{
				consigners = this.state.consigners
			}
			
			//console.log("records ", records)
			if(this.props.match.path == "/invalidcourierconsignments")
			{
				var allrecords = datanaarr;
			}
			else
			{
				var allrecords = records;
			}
			//console.log(allrecords);
			var critical_counter_data = []
			if(urlpath == "/consignments/couriers" || urlpath == '/consignments/activecouriers')
			{
				//console.log(this.state.critical_data,"critical data length")
				this.state.critical_data.map(function(e,index){
					
					allrecords.map(function(k){
						if(e.consignment_code == k.consignment_code && getHyphenYYYYMMDDHHMMSS(k.mgpa_date_time) == e.mgpa_date_time)
						{
							k['critical_record'] = 1;
							critical_counter_data.push(k);
						}
					})
				})
			}

			//console.log(consigners,"consigners");
			var cancelled_data = "Not Applicable"
			if(this.props.match.path == "/courierconsignments" || this.props.match.path == "/deliveredcourierconsignments")
			{
				cancelled_data = allrecords.filter(function(e){
					return e.cancelled == 1
				})
			}
			var pod_received_data = []
			var trip_closed_by_pod = []
			if(this.props.match.path == "/courierconsignments" || this.props.match.path == "/activecourierconsignments" 
			|| this.props.match.path == "/deliveredcourierconsignments")
			{
				pod_received_data = allrecords.filter(function(e){
					return e.pod_received == 1
				})
				trip_closed_by_pod = allrecords.filter(function(e){
					return e.trip_closed_by_pod == 1
				})
			}
            this.setState({
				consigners:consigners,
				rowData:allrecords,
				originaldata:allrecords,
				intransit:intransitarr,
				outdeliver:outdeliverarr,
				deliver:deliverarr,
				datana:datanaarr,
				prioritydata:priorityarr,
				intransitcount:intransitarr.length,
				outdelivercount:outdeliverarr.length,
				delivercount:deliverarr.length,
				datanacount:datanaarr.length,
				totalcount:allrecords.length,
				prioritydatacount:priorityarr.length,
				confirmDelivery:confirmDelivery,
				critical_counter_data : critical_counter_data,
				overly : "show-n",
				loadshow:'show-n',
				cancelled_data: cancelled_data,
				pod_received_data: pod_received_data,
				trip_closed_by_pod: trip_closed_by_pod
            });
		  })
		  .catch(function (error) {
		    console.log(error);
			});
	}
	
	onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	
	//    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };

	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);

		   
		   
	}
	onRowSelection(event){
		console.log("row selected")
		var containerslist=[];
		//console.log("rowselect", event)
		var rwCount = event.api.getSelectedNodes();
		console.log("rwCount ", rwCount)
		//if(event.node.selected == true)
		//{
			//var containerlist=[];
			console.log("rowselect", event.data)
			var rowCount =  event.data
			//console.log("rowCount ", rowCount)
			
			rwCount.map((item) => {
				containerslist.push(item.data.truck_no)
			});
			var deliverStatusValue;
			if(event.data.status == 1)
			{
				deliverStatusValue = "Inside Plant"
			}
			else if(event.data.status == 2)
			{
				deliverStatusValue =  "In Transit"
			}
			else if(event.data.status == 3)
			{
				deliverStatusValue =  "Near Destination"
			}
			else if(event.data.status == 4)
			{
				deliverStatusValue = "Reached Destination"
			}	
			
			else if(event.data.status == 5)
			{
				deliverStatusValue =  "Left Destination"
			}					
			else if(event.data.status == 6)
			{
				deliverStatusValue =  "Force Closed"
			}
			else{
				deliverStatusValue =  ""
			}
			this.setState({
				containerslist:containerslist,
				deliveryStatus : event.data.status,
				deliverStatusValue : deliverStatusValue,
				distance_from_dealer_location : event.data.distance_from_dealer_location,
				deliveredDeptCode : event.data.dept_code,
				deliveredTruckNo : event.data.truck_no,
			})
			 console.log("checkedlist ", containerslist)
		// }
		// else{
		// 	if(containerslist.length > 0)
		// 	{
		// 		containerslist=[]
		// 		this.setState({
		// 			containerslist:containerslist
		// 		})
		// 	}
		// }
		
	}
	renderMap = () => {    	
		loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyBQUmk7hdajZEAYllxKhVPnUx5pXuK-j_Y&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
	}
	
	initMap = () => {
    	console.log("mapinfo ",this.state.mapinfo);
    	var currentwindow;
    	
		var jsondata = this.state.mapinfo;
		var locations = jsondata.breaks;
		var arr = jsondata.coords;
		

		console.log("Here",arr);
		if(arr.length > 0)
		{
			var lt=arr[0].lat;
			var ln=arr[0].lng;
		}
		else{
			if(this.state.defTransitCoords == '')
			{
				var lt=28.4519751;
				var ln=77.0310713;
			}
			else{
				var lt=JSON.parse(this.state.defTransitCoords).lat;
				var ln=JSON.parse(this.state.defTransitCoords).lng;
			}
			
		}
        var routeinfo = jsondata.route_details;
    	 var mapOptions = {
    			zoom: 7,
				zoomControl: true,
				mapTypeControl: true,
				scaleControl: true,
				streetViewControl: true,
				rotateControl: true,
				fullscreenControl: true,
    	          labels:true,
    	          mapTypeControlOptions: {
    	            mapTypeIds: ['hybrid', 'roadmap'],
    	          },
    	          center: new window.google.maps.LatLng(lt,ln),
    	          mapTypeId: window.google.maps.MapTypeId.ROADMAP
    	        };
    	        
    	        var map = new window.google.maps.Map(document.getElementById('map'),
    	            mapOptions);

				var bounds = new window.google.maps.LatLngBounds();
				bounds.extend(new window.google.maps.LatLng(lt,ln));
    			 // Create our info window content
    			var currentinfowindow = null;
    	       var line=new window.google.maps.Polyline(
    	              {
    	                map:map,
    									strokeColor: '#157254',
    									strokeOpacity: 1.0,
    									strokeWeight: 2.5,
    	                		icons: [{
    	                          icon: {
    	                                  path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    	                                  strokeColor:'#ff8c52',
    	                                  fillColor:'#ff8c52',
    	                                  fillOpacity:1,
    									  strokeWeight: 2
    	                                },
    	                          repeat:'100px',
    	                          path:[]
    	                       }]
    	                 });

			if(arr.length > 0)
			{
    		  for (let i = 0; i < arr.length; i++) {
    				var path=line.getPath().getArray();
    					
    						let latLng=new window.google.maps.LatLng(arr[i].lat,arr[i].lng);
    						path.push(latLng);
    	          			line.setPath(path);
    					

    				  
    		  
    	          //Change line color based on map type
    	        window.google.maps.event.addListener( map, 'maptypeid_changed', function() {
    	            if (map.getMapTypeId() =='hybrid' || map.getMapTypeId() =='satellite')
    	            {
    	                var line=new window.google.maps.Polyline(
    	                  {
    	                    map:map,
    	                    strokeColor: '#FFFFFF',
    	                    strokeOpacity: 1.0,
    	                    strokeWeight: 2.5,
    	                    icons: [{
    	                              icon: {
    	                                      path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    	                                      strokeColor:'#ff8c52',
    	                                      fillColor:'#ff8c52',
    	                                      fillOpacity:1,
    	                                      strokeWeight: 2
    	                                    },
    	                              repeat:'100px',
    	                              path:[]
    	                           }]
    	                     });                
    	            }
    	            else
    	            {
    	                var line=new window.google.maps.Polyline(
    	                  {
    	                    map:map,
    	                    strokeColor: '#157254',
    	                    strokeOpacity: 1.0,
    	                    strokeWeight: 2.5,
    	                    icons: [{
    	                              icon: {
    	                                      path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    	                                      strokeColor:'#ff8c52',
    	                                      fillColor:'#ff8c52',
    	                                      fillOpacity:1,
    	                                      strokeWeight: 2
    	                                    },
    	                              repeat:'100px',
    	                              path:[]
    	                           }]
    	                     });

    	            }

    	              for (i = 0; i < arr.length; i++) {
    	                    var path=line.getPath().getArray();
    	                    let latLng=new window.google.maps.LatLng(arr[i].lat,arr[i].lng);
    						path.push(latLng);
    	          			line.setPath(path);
    	                            //map.setCenter(latLng);
    	                  }
    	                            
    	             var x = map.getZoom();
    	             var c = map.getCenter();
    	             window.google.maps.event.trigger(map, 'resize');
    	             map.setZoom(x);
    	             map.setCenter(c);
				} );
				
				bounds.extend(new window.google.maps.LatLng(arr[i].lat,arr[i].lng));
    		  }

		  var infowindow = new window.google.maps.InfoWindow();
			var marker, l;

			for (l = 0; l < locations.length; l++) {
		      marker = new window.google.maps.Marker({
	    	  position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
		  	  icon:require('../../assets/icons/cf.png'),
		      map: map,
			});

		      window.google.maps.event.addListener(marker, 'click', (function(marker, l) {
		        return function() {
		        	 var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+secondsToString(locations[l].break_time)+")</p>";
	    	        		
				  infowindow.setContent(contentString);
				  currentwindow = infowindow;
				  infowindow.open(map, marker);
				  //console.log(marker.position.toJSON());
				  //console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
				}
			})(marker, l));
			}
			marker = new window.google.maps.Marker({
		        position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
		  		icon:require('../../assets/icons/track_start.png'),
		        map: map,	
		      });
	   	   window.google.maps.event.addListener(marker, 'click', (function(marker) {
	   	        return function() {
	   	        	//	console.log(clusters[0])
	   	         	
	       		    var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
	   	        	
	   			  infowindow.setContent(contentString);
	   			  currentwindow = infowindow;
	   			  infowindow.open(map, marker);
	   			  //console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
	   			}
	   		})(marker));
	   	  marker = new window.google.maps.Marker({
	        position: new window.google.maps.LatLng(arr[(arr.length-1)].lat, arr[(arr.length-1)].lng),
	  		icon:require('../../assets/icons/truck-end.png'),
	        map: map,	
	      });
		   window.google.maps.event.addListener(marker, 'click', (function(marker) {
		        return function() {
		        	//	console.log(clusters[0])
		         	
	   		    var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
		        	
				  infowindow.setContent(contentString);
				  currentwindow = infowindow;
				  infowindow.open(map, marker);
				 // console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
				}
			})(marker));
		}
		map.fitBounds(bounds)
	}
	onClickForceClose = (params) =>{
		//console.log("Pareas ", params)
		this.setState({
		   forceCloseRowNode : params,
			forceclosedata:params.data,
			sliderForceCloseTranslate:"slider-translate",
			overly:'show-m'
		})
	}
	onClickMarkDelivered = (params) =>{
		console.log(params.data);
		if(params.data.tpt_delivery_date != undefined && params.data.tpt_delivery_date != null && params.data.tpt_delivery_date != "")
		{
			var markdate = params.data.tpt_delivery_date.split("T")[0];
			this.setState({
				markDeliverDefault : markdate
			});
		}
		else{
			console.log("else")
			this.setState({
				markDeliverDefault : ""
			});
		}
		this.setState({
			 markDeliverRowNode : params,
			 markDeliverData:params.data,
			 sliderMarkDeliverTranslate:"slider-translate",
			 overly:'show-m',
		 })
	}
	closeAlert = () => {
        this.setState({
            show: false
        });
	}
	closePriorityAlert = () => {
        this.setState({
            showPriority: false
		});
		window.location.reload();
	}
	
	uploadBulkPriorityFormHandler(event)
	{
		event.preventDefault();

		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.formAction,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		var bulkMdData = this.state.pCsvContent;
		//console.log(bulkMdData);
		var flag = 0;
		bulkMdData.map(function(e,index){
			var keys = Object.keys(e);
			var consignmentKey=""
			keys.map(function(key){
				if(key == "Consignment_Code" ||
					key == "Consignment Code" ||
					key == "consignment_code" ||
					key == "consignment code" ||
					key == "ConsignmentCode" ||
					key == "consignmentcode" 
				)
				{
					consignmentKey = key;
				}
			})
			if(index != (bulkMdData.length-1))
			{
				if(e[consignmentKey] == "")
				{
					flag = 1
				}
			}
			
		})
		if(flag == 0)
		{
			var reqParams = {
				email : localStorage.getItem("email"),
				csvData : bulkMdData
			}
			redirectURL.post("/consignments/bulkUploadPriority",reqParams).then((response)=>{
				this.setState({
					basicTitle : "Success",
					basicType : "success",
					show : true,
					file : "",
					bulkPrioritySlide:"",
					overly:"show-n",
					loadshow : "show-n"
				})
				window.location.reload();
			}).catch((e)=>{
				console.log(e);
			})
			
		}
		else{
			$("#bulkUploadPID").val();
			this.setState({
				basicTitle : "Consignment_code fields must not be empty",
				basicType : "danger",
				show : true,
				file : "",
				pCsvContent : "",
			})
		}
	}
	uploadBulkForceFormHandler(event)
	{
		event.preventDefault();
	
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.formAction,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		var bulkData = this.state.csvcontent;
		console.log(bulkData);
		var flag = 0;
		bulkData.map(function(e){
			var keys = Object.keys(e);
			console.log("keys",keys)
			var consignmentKey=""
			keys.map(function(key){
				if(key == "Consignment_Code" ||
					key == "Consignment Code" ||
					key == "consignment_code" ||
					key == "consignment code" ||
					key == "ConsignmentCode" ||
					key == "consignmentcode" 
				)
				{
					consignmentKey = key;
				}
			})
			if(e[consignmentKey] == "" || e.reason == "" || e.mgpa_date == "")
			{
				flag = 1
			}
		})
		if(flag == 0)
		{
			if(bulkData.length > 0)
			{
				var reqParams = {
					email : localStorage.getItem("email"),
					csvData : bulkData
				}
				redirectURL.post("/consignments/bulkUploadForceClose",reqParams).then((response)=>{
					this.setState({
						basicTitle : "Success",
						basicType : "success",
						show : true,
						file : "",
						bulkForceCloseSlide:"",
						overly:"show-n",
						loadshow : "show-n"
					})
					// window.location.reload();
				}).catch((e)=>{
					console.log(e);
				})
			}
			else
			{
				this.setState({
					basicTitle : "Invalid File",
					basicType : "warning",
					show : true
				})
			}
			
		}
		else{
			$("#bulkUploadID").val();
			this.setState({
				basicTitle : "Consignment_code, reason, mgpa_date fields must not be empty",
				basicType : "danger",
				show : true,
				file:"",
				csvData : "",
			})
		}
	}
	
	uploadBulkMarkDeliverHandler(event)
	{
		event.preventDefault();

		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.formAction,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		var bulkMdData = this.state.mdCsvContent;
		var delivery_date_time = $("#bulk_delivery_date_time").val();
		//console.log(delivery_date_time)
		//console.log(bulkMdData);
		if(delivery_date_time != "__-__-____")
		{
			var flag = 0;
			bulkMdData.map(function(e,index){
				var keys = Object.keys(e);
				var consignmentKey=""
				keys.map(function(key){
					if(key == "Consignment_Code" ||
						key == "Consignment Code" ||
						key == "consignment_code" ||
						key == "consignment code" ||
						key == "ConsignmentCode" ||
						key == "consignmentcode" 
					)
					{
						consignmentKey = key;
					}
				})
				if(index != (bulkMdData.length-1))
				{
					if(e[consignmentKey] == "")
					{
						flag = 1
					}
				}
				
			})
			if(flag == 0)
			{
				var reqParams = {
					email : localStorage.getItem("email"),
					deliveryDate : delivery_date_time,
					csvData : bulkMdData
				}
				redirectURL.post("/consignments/bulkUploadMarkDeliver",reqParams).then((response)=>{
					this.setState({
						basicTitle : "Success",
						basicType : "success",
						show : true,
						file : "",
						bulkMarkDeliverSlide:"",
						overly:"show-n",
						loadshow : "show-n"
					})
					window.location.reload();
				}).catch((e)=>{
					console.log(e);
				})
				
			}
			else{
				$("#bulkUploadMDID").val();
				this.setState({
					basicTitle : "Consignment_code fields must not be empty",
					basicType : "danger",
					show : true,
					file : "",
					mdCsvContent : "",
				})
			}
		}
		else{
			// $("#bulkUploadMDID").val();
			this.setState({
				basicTitle : "Delivery Date is Not Selected",
				basicType : "danger",
				show : true,
				file : "",
				mdCsvContent : "",
			})
		}
	}
	
    onShowCourierTrack = async (params) => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.trackCourier,
			}
			googleAnalytics.logEvent(eventOptions);
		}
        if(params != 0)
		{
			
			await this.setState({
					
				detailCellRendererParams:{
					detailGridOptions: {
						columnDefs: [
							
							{ 
                                headerName: "Transit Date",
                                field: "intransit_date", 
                                valueGetter:function(params){
									console.log("IN onShowCourierTrack, params.data = ", params.data);
                                  // var intransitdate=getDDMMYYYY(params.data.intransit_date)+" "+params.data.intransit_time
                                   var intransitdate= params.data.intransit_date +" "+ params.data.intransit_time
								   return intransitdate
								},
								filter:true,
								resizable:true
                            },
							{ 
                                headerName: "Location",
                                field: "intransit_location", 
                                filter:true,
								resizable:true 
                            },
							{ 
                                headerName: "Status",
                                field: "intransit_status", 
                                filter:true ,
								resizable:true
                            }
						]							
					},
					getDetailRowData: function(params) {
						//console.log("Step 4 ",overspeedData)
						redirectURL.post('/consignments/trackcourier', {
							consignment_code:params.data.consignment_code
						})
						.then(async (response) =>{
                            //console.log("Step 1 ",response.data)
                            //console.log("test ", response);
                            var trackData=response.data;
                            //console.log("trackData ", trackData)
                            if(trackData.status == 'failure')
                            {
                                params.successCallback([]);
                            }
                            else{
                                params.successCallback(trackData);
                            }
							
							//console.log("Step 2 ",response.data)
							
						});
						 
					},
					masterDetail: true
				}
			});
			
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
			
		}
		//console.log("params.column.colId ", params.column)
		if(params.column.colDef.field == '_id')
		{
			params.node.setExpanded(!params.node.expanded);
		}
		else{

			params.node.setExpanded(false);
		}
    }
    hideAlert = () => {
        this.setState({
            alert: null
        });
    }
	onClickCounterShowData(ctype){
		console.log("CType ", ctype);
	}
	resetUpload = () => {
		this.setState({
			uploadDivWidth:'0%',
			bulkPrioritySlide:'',
			bulkForceCloseSlide:'',
			showDiv:'show-n',
			overly:'show-n',
			file:''
		});
		document.getElementById("upform").reset();
	}
	onCloseDeliverModal = () => {
		this.gridApi.deselectAll();
		this.setState({ delivermodal: false, delDate:"none" });
	};
	// handlerDeliverDateTime = (event, currentDate, selectedDate) => {
    // 	var d = new Date(event._d);
    	
    // 	var deliverdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
    // 	this.setState({
    // 		deliverdate:deliverdate
    // 	});
	// }
	handlerDeliverDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
		var deliverdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
		var temp = deliverdate
		if(temp.split("-")[1]<10)
		{
			temp = temp.split("-")[0]+"-0"+temp.split("-")[1]+"-"+temp.split("-")[2]
		}

		var checkDeliveryDate = new Date(temp);
		var mgpa_date = this.state.selectedRow.mgpa_date_time;
		mgpa_date =new Date(mgpa_date.split("T")[0]);
		var currentdate = moment.parseZone(new Date()).format("YYYY-MM-DD");
		// console.log(checkDeliveryDate,mgpa_date,currentdate);
		var flag = 0;
		if(checkDeliveryDate < mgpa_date || checkDeliveryDate > new Date(currentdate))
		{
			flag = 1;
		}
		if(flag == 0)
		{
			console.log(deliverdate)
			this.setState({
				deliverdate:deliverdate
			});
		}
		else
		{
			this.setState({
				deliverdate:"",
				basicTitle : "Selected Date Must be Between MGPA Date and Current Date",
				basicType : "warning",
				show : true
			});
		}
    	
	}
	selectReason(){
		var rowdata = this.state.selectedRow;
		var arr = []
		arr.push({"label":"Delay in Pick up","value":"Delay in Pick up"});
		arr.push({"label":"Delay in transit/Network Delay","value":"Delay in transit/Network Delay"});
		arr.push({"label":"Delay due to Strike, Natural Calamity, Govt. Restrictions","value":"Delay due to Strike, Natural Calamity, Govt. Restrictions"});
		arr.push({"label":"Consignee Door Closed/Consignee Shifted","value":"Consignee Door Closed/Consignee Shifted"});
		arr.push({"label":"Consignee Refused to Accept","value":"Consignee Refused to Accept"});
		arr.push({"label":"Consignee asked to reschedule delivery","value":"Consignee asked to reschedule delivery"});
		arr.push({"label":"Incorrect Address/Pin Code","value":"Incorrect Address/Pin Code"});
		if(rowdata.vehicle_mode == "AIR CARGO")
		{
			arr.push({"label":"Consignment Offloaded due to DG goods/Oversize","value":"Consignment Offloaded due to DG goods/Oversize"});
			arr.push({"label":"Consignment offloaded by airlines","value":"Consignment offloaded by airlines"});
		}
		arr.push({"label":"Cartons short from MSIL","value":"Cartons short from MSIL"});
		arr.push({"label":"Cartons short received at Delivery Hub","value":"Cartons short received at Delivery Hub"});
		arr.push({"label":"Local Festival","value":"Local Festival"});
		arr.push({"label":"Manual Delivery/Dispatched via other Transporter","value":"Manual Delivery/Dispatched via other Transporter"});
		arr.push({"label":"Consignment delivered, awaiting confirmation from local branch","value":"Consignment delivered, awaiting confirmation from local branch"});
		arr.push({"label":"Invalid Docket (Correct GR no. to be specified)","value":"Invalid Docket (Correct GR no. to be specified)"});
		arr.push({"label":"Others (Please Specify)","value":"Others (Please Specify)"});
		return arr;
	}
	setSelectedReason = (event)=>{
		var selectedReason = event.value;
		if(selectedReason == "Invalid Docket (Correct GR no. to be specified)" || selectedReason == "Others (Please Specify)")
		{
			this.setState({
				displayOtherField : "show-m"	
			})
		}
		else
		{
			this.setState({
				displayOtherField : "show-n"
				
			})
		}
		if(selectedReason == "Manual Delivery/Dispatched via other Transporter" || selectedReason == "Consignment delivered, awaiting confirmation from local branch")
		{
			var delDate = "flex";
		}
		else
		{
			var delDate = "none";
		}
		this.setState({
			selectedDeliveryReason : event.value,
			delDate : delDate
		})
	}
	changeReasonHandler(e){
		this.setState({
			deliveredOtherReason : e.target.value
		})
	}
	formSetDeliveredDate(event){
        event.preventDefault();
        var deliverrow=this.state.deliverrow;
		var deliverdate = this.state.deliverdate;
		var tpt_delivery_reason = this.state.selectedDeliveryReason;
		var tpt_delivery_other_reason = this.state.deliveredOtherReason;
		var selectedDeliveryReason = this.state.selectedDeliveryReason;
		//console.log("deliverrow ", deliverrow);
		console.log(deliverdate);
		var dateFlag = 0;
		if(tpt_delivery_reason == "Manual Delivery/Dispatched via other Transporter" || tpt_delivery_reason == "Consignment delivered, awaiting confirmation from local branch")
		{			
			if(moment.parseZone(deliverdate).format("YYYY-MM-DD") == "Invalid date" ||moment.parseZone(deliverdate).format("YYYY-MM-DD") == "" || moment.parseZone(deliverdate).format("YYYY-MM-DD") == undefined)
			{
				var dateFlag = 1;
			}
		}
		
		var flag = 0;
		if(tpt_delivery_reason == "Others (Please Specify)")
		{
			if(tpt_delivery_other_reason.replace(/\s/g,"").length == 0)
			{
				flag = 1;
			}
		}
		
		if(flag == 1)
		{
			this.setState({
				basicTitle : "Other Reason Should not be Empty",
				basicType : "warning",
				show : true
			});
		}
		else
		{
			if(dateFlag == 1)
			{
				this.setState({
					basicTitle : "Invalid Delivery Date",
					basicType : "warning",
					show : true
				});
			}
			else
			{
				if(tpt_delivery_reason !=undefined && tpt_delivery_reason !='')
				{
					var delReason = tpt_delivery_reason;
				}
				else
				{
					var delReason = "Imvalid Docket (Correct GR no. to be specified)";
				}
				var reqparams = {
					tpt_confirm_delivery:1,
					dept_code : this.state.deliveredDeptCode,
					truck_no : this.state.deliveredTruckNo,
					email : localStorage.getItem("email"),
					tpt_delivery_date:moment.parseZone(deliverdate).format("YYYY-MM-DD"),
					consigment_code:deliverrow.data.consignment_code,
					tpt_delivery_reason : delReason,
					tpt_delivery_other_reason : tpt_delivery_other_reason
				}
				redirectURL.post("/consignments/setCouriersDeliverDate", reqparams)
				.then((response) => {
					console.log("resss ", response.data)
					if(response.data.ok == 1){
						this.setState({
							delivermodal:false,
							delDate:"none",
							deliverdate:'',
							deliveryStatus:"",
							deliveredDeptCode:"",
							deliveredTruckNo:"",
							overly : "show-m",
							loadshow :"show-m"
						});
						this.submitForm();
					}
				})
			}
		}
    }
	onClickCounterShowData(params){
		console.log("IN onClickCounterShowData, params = ",params);
		if (googleAnalytics.page.enableGA) {
			let eventLabel = null;
			if (params) {
				eventLabel = googleAnalytics.page.kpi[params];
				//console.log("eventLabel = ", eventLabel);
			}
			
			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventCounterAction,
				"label": eventLabel,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		if(params == "critical")
		{
			this.gridApi.setRowData(this.state.critical_counter_data);
			// this.gridApi.onFilterChanged();
		}
		if(params == "intransit")
		{
			var filterComponent = this.gridApi.getFilterInstance("consignment_status");
			// filterComponent.selectNothing();
			// filterComponent.selectValue("1");
			
			// filterComponent.applyModel();
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["consignment_status"]) {
					return rec["consignment_status"] == "In Transit";
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		if(params == "confirmDelivery")
		{
			
			this.gridApi.setRowData(this.state.confirmDelivery);
			this.gridApi.onFilterChanged();
		}
		
		if(params == "outfordeliver")
		{
			var filterComponent = this.gridApi.getFilterInstance("consignment_status");
			console.log(filterComponent);
			// filterComponent.selectNothing();
			// filterComponent.selectValue("1");
			
			// filterComponent.applyModel();
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["consignment_status"]) {
					return rec["consignment_status"] == "Out for Delivery";
				}
			});
			console.log(isFilterDataAvailable);
			this.gridApi.setRowData(this.state.outdeliver);
			this.gridApi.onFilterChanged();
		}
		if(params == "deliver")
		{
			var filterComponent = this.gridApi.getFilterInstance("consignment_status");
			// filterComponent.selectNothing();
			// filterComponent.selectValue("1");
			
			// filterComponent.applyModel();
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["consignment_status"]) {
					return rec["consignment_status"] == "Delivered";
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}

		if(params == "datana")
		{
			var filterComponent = this.gridApi.getFilterInstance("consignment_status");
			// filterComponent.selectNothing();
			// filterComponent.selectValue("1");
			
			// filterComponent.applyModel();
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["consignment_status"]) {
					return (rec["consignment_status"] == "" || rec["consignment_status"] == null || rec["consignment_status"] == "Invalid docket");
				}
			});
			this.gridApi.setRowData(this.state.datana);
			this.gridApi.onFilterChanged();
		}

		if(params == "all")
		{
			var filterComponent = this.gridApi.getFilterInstance("consignment_status");
			// filterComponent.selectNothing();
			// filterComponent.selectValue("1");
			
			// filterComponent.applyModel();
			
			this.gridApi.setRowData(this.state.originaldata);
			this.gridApi.onFilterChanged();
		}
		if(params == "cancelled")
		{
			this.gridApi.setRowData(this.state.cancelled_data);
			this.gridApi.onFilterChanged();
		}
		if(params == "trip_closed")
		{
			this.gridApi.setRowData(this.state.trip_closed_by_pod);
			this.gridApi.onFilterChanged();
		}
		if(params == "pod")
		{
			this.gridApi.setRowData(this.state.pod_received_data);
			this.gridApi.onFilterChanged();
		}
		if(params == "priority")
		{
			this.gridApi.setRowData(this.state.prioritydata);
			this.gridApi.onFilterChanged();
		}
		
	}

	handlerStartDateTime = (selectDate) => {
		console.log("selectDate ", selectDate)
    	// var d = new Date(event._d);
    	
    	// var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
    	// this.setState({
    	// 	from_date:startdate
    	// });
	}
	handleChange = date => {
		this.setState({
		  to_date: date
		});
	  };
	  handleChange1 = date => {
		this.setState({
		  from_date: date
		});
	  };
	handlerEndDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
    	this.setState({
    		to_date:startdate
    	});
    	//let value = event.target.value;
        //this.setState({'startDate':value});
	}
	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderForceCloseTranslate:'',
			sliderMarkDeliverTranslate : "",
			bulkPrioritySlide:"",
			bulkForceCloseSlide:"",
			bulkMarkDeliverSlide:""
		});
		
	}
	
	onBulkPriorityBtn(){
		this.setState({
			overly : "show-m",
			bulkPrioritySlide : "slider-translate"
		})
	}
	onBulkForceCloseBtn(){
		this.setState({
			overly : "show-m",
			bulkForceCloseSlide : "slider-translate"
		})
	}
	onBulkMarkDeliverBtn(){
		this.setState({
			overly : "show-m",
			bulkMarkDeliverSlide : "slider-translate"
		})
	}
	
	formForceCloseHandler(event){
		event.preventDefault()
		var consignmentId = this.state.forceclosedata.consignment_code;
		var forceCloseReason = $("#forceClosureResonID").val();
		var email = localStorage.getItem("email");
		// console.log(forceCloseReason,email);
		var reqParams = {
			consignmentId : consignmentId,
			forceCloseReason : forceCloseReason,
			email : email
		}
		if(forceCloseReason.replace(/\s/g,"").length)
		{
			redirectURL.post("/consignments/updateCourierForceClosure",reqParams).then((response)=>{
				this.setState({
					sliderForceCloseTranslate : "",
					loadshow : "show-n",
					overly:"show-n",
					basicTitle : "Force Closed Successfully",
					basicType : "success",
					show : true,
					forceCloseRowNode : "",
					forceclosedata:"",
				});
				window.location.reload();
			}).catch((e)=>{
				console.log(e)
			})
		}
		else
		{
			this.setState({
				basicTitle : "Reason Should not be Empty.",
				basicType : "danger",
				show : true,
			});
		}
		

	}
	formMarkDeliverHandler(event)
	{
		event.preventDefault()
		var consignmentId = this.state.markDeliverData.consignment_code;
		var delivery_date_time = $("#delivery_date_time").val();
		console.log(delivery_date_time)
		if(delivery_date_time != "__-__-____")
		{
			var rowdata = this.state.markDeliverData;

			var temp = delivery_date_time
			if(temp.split("-")[1].length < 2)
			{
				temp = temp.split("-")[2]+"-0"+temp.split("-")[1]+"-"+temp.split("-")[0]
			}
			else
			{
				temp = temp.split("-")[2]+"-"+temp.split("-")[1]+"-"+temp.split("-")[0]
			}

			var flag=0;
			var checkDeliveryDate = new Date(temp);
			var mgpa_date = this.state.markDeliverData.mgpa_date_time;
			mgpa_date =new Date(mgpa_date.split("T")[0]);
			var currentdate = moment.parseZone(new Date())
			console.log(new Date(currentdate),mgpa_date,checkDeliveryDate);
			if(checkDeliveryDate < mgpa_date)
			{
				flag = 1;
			}
			if(flag == 0)
			{
				var email = localStorage.getItem("email");
				var reqParams = {
					consignmentId : consignmentId,
					delivery_datetime  : delivery_date_time,
					email : email
				}
				console.log(reqParams);
				redirectURL.post("/consignments/courierMarkDelivered",reqParams).then((response)=>{
					this.setState({
						sliderMarkDeliverTranslate : "",
						loadshow : "show-n",
						overly:"show-n",
						basicTitle : "Marked as Delivered",
						basicType : "success",
						show : true,
						markDeliverRowNode : "",
						markDeliverRowNode:"",
					})
					window.location.reload();
				}).catch((e)=>{
					console.log(e)
				})
			}
			else
			{
				this.setState({
					deliverdate:"",
					basicTitle : "Selected Date Must be Between greater than MGPA Date",
					basicType : "warning",
					show : true
				});
			}
		}
		else
		{
			this.setState({
				basicTitle : "Delivery Date is Not Selected.",
				basicType : "danger",
				show : true
			})
		}
	}

	changeConsignerArrayHandler = consigner =>{
        this.setState(
	      { consigner },
	      () => console.log(`Consigner Option selected:`, this.state.consigner)
        );
        
        this.setState({
			errorMessage:'',
			successMessage:''
        })
	}
	selectConsignerOptionsItems(){
        let items = [];     
		items.push({value:'all',label:'All'}); 
		this.state.consigners.forEach(item =>{
			items.push({value:item,label:item}); 
		});
        
        return items;
	}
	changeFileHandler = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		const config = {
			headers: [
				{ 
					name: 'consignment_code',
					inputName: 'consignment_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'reason',
					inputName: 'reason',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'mgpa_date',
					inputName: 'mgpa_date',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				

			
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			console.log(csvData)
			this.setState({
				csvcontent:csvData.data
			});
		})
		.catch(err => {})
		// console.log("e.target.files[0]",e.target.result);
		var contentList = []
		
		var out = new Promise(function(reject, resolve){
			var reader =  new FileReader();
			reader.onload = async function(e) {
				var contents = await e.target.result;
				resolve(contents);
			};
			var tt =  reader.readAsText(e.target.files[0]);
		});
		console.log(contentList);
		this.setState({
			file:e.target.files[0]
		});
		// console.log("e.target.files[0].type ", e.target.files[0]);

		if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
		{
			// console.log(fileData);
			// console.log(typeof(fileData))
			
		}
		else{
			e.target.value = null;
			this.setState({
				uploadFile:'',
				file:"",
				show: true, 
				basicType:'danger', 
				basicTitle:'Please upload file having extensions .csv only.',
			});
		}
		
	}


	changeMDHandler = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		const config = {
			headers: [
				{ 
					name: 'consignment_code',
					inputName: 'consignment_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			this.setState({
				mdCsvContent:csvData.data
			});
		})
		.catch(err => {})
		// console.log("e.target.files[0]",e.target.result);
		var contentList = []
		
		var out = new Promise(function(reject, resolve){
			var reader =  new FileReader();
			reader.onload = async function(e) {
				var contents = await e.target.result;
				resolve(contents);
			};
			var tt =  reader.readAsText(e.target.files[0]);
		});
		this.setState({
			file:e.target.files[0]
		});
		// console.log("e.target.files[0].type ", e.target.files[0]);

		if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
		{
			// console.log(fileData);
			// console.log(typeof(fileData))
			
		}
		else{
			e.target.value = null;
			this.setState({
				uploadFile:'',
				file:"",
				show: true, 
				basicType:'danger', 
				basicTitle:'Please upload file having extensions .csv only.',
			});
		}
		
	}
	changePriorityFileHandler = async (e) => {
		var dt = '';

		const config = {
			headers: [
				{ 
					name: 'consignment_code',
					inputName: 'consignment_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			this.setState({
				pCsvContent:csvData.data
			});
		})
		.catch(err => {})
		// console.log("e.target.files[0]",e.target.result);
		var contentList = []
		
		var out = new Promise(function(reject, resolve){
			var reader =  new FileReader();
			reader.onload = async function(e) {
				var contents = await e.target.result;
				resolve(contents);
			};
			var tt =  reader.readAsText(e.target.files[0]);
		});
		this.setState({
			file:e.target.files[0]
		});
		// console.log("e.target.files[0].type ", e.target.files[0]);

		if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
		{
		}
		else{
			e.target.value = null;
			this.setState({
				uploadFile:'',
				file:"",
				show: true, 
				basicType:'danger', 
				basicTitle:'Please upload file having extensions .csv only.',
			});
		}
		
	}
	onClickShowDeliverPopup = (params) =>{
		var containerslist=[]
			var event = params
			var deliverStatusValue;
			if(event.data.status == 1)
			{
				deliverStatusValue = "Inside Plant"
			}
			else if(event.data.status == 2)
			{
				deliverStatusValue =  "In Transit"
			}
			else if(event.data.status == 3)
			{
				deliverStatusValue =  "Near Destination"
			}
			else if(event.data.status == 4)
			{
				deliverStatusValue = "Reached Destination"
			}	
			
			else if(event.data.status == 5)
			{
				deliverStatusValue =  "Left Destination"
			}					
			else if(event.data.status == 6)
			{
				deliverStatusValue =  "Force Closed"
			}
			else{
				deliverStatusValue =  ""
			}
			console.log(event.data);
			this.setState({
				containerslist:containerslist,
				deliveryStatus : event.data.status,
				deliverStatusValue : deliverStatusValue,
				distance_from_dealer_location : event.data.distance_from_dealer_location,
				deliveredDeptCode : event.data.dept_code,
				deliveredTruckNo : event.data.truck_no,
				selectedRow:event.data
			})
        this.setState({ 
            delivermodal: true, 
            deliverrow:params,
			deliverdate:''
		});
    }
	submitForm()
	{
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.formAction,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		var fromdate = document.getElementById("from_date").value;
		var todate = document.getElementById("to_date").value;
		this.setState({
			from_date:fromdate,
			to_date:todate,
			loadshow:'show-m'
		});
		console.log("formdate",fromdate)
		console.log("todate",todate)
		if(fromdate != '')
		{
			// var fdt = fromdate.split(" ");
			var sfdate = fromdate.split("-");
			var fdate = sfdate[2]+"-"+sfdate[1]+"-"+sfdate[0];
		}
		else
		{
			fdate=""
		}
		if(todate != '')
		{

			var tdt = todate.split(" ");
			var stdate = todate.split("-");
			var tdate = stdate[2]+"-"+stdate[1]+"-"+stdate[0];
		}
		else
		{
			tdate=""
		}
		console.log("firstdate",fdate);
		console.log("TODate",tdate);
		// console.log("this.state.consigner ",this.state.consigner)
		if(this.state.consigner == null)
		{
			var consigners = [{"value":"all"}]
		}
		else{
			var consigners = this.state.consigner; 
		}
		// if(fromdate == '' && todate == '')
		// {
		// 	var reqparasm = {
		// 		from_date:fdate,
		// 		to_date:tdate,
		// 		consigner_code:[{"value":"all"}],
		// 	}
		// }
		// else
		// {
			// console.log("tdate ",tdate)
			// var consignsarr = [];
			// if(this.state.consigner.length > 0)
			// {
			// 	this.state.consigner.map((item) => {
			// 		consignsarr.push(item.value)
			// 	})
			// }
			// else{
			// 	consignsarr.push({"value":'all'})
			// }
			var serviceProviders = [];
			if(localStorage.getItem("user_type") == "TRANSPORTER")
			{
				serviceProviders = JSON.parse(localStorage.getItem('transportercode'))
			}
			var reqparasm = {
				from_date:fdate,
				to_date:tdate,
				consigner_code:consigners,
				serviceProviders : serviceProviders,
				is_filter_call : 1
			}
			
		//}
		console.log("reqparasm ", reqparasm)
		var urlpath;
		if(this.props.match.path == "/courierconsignments")
		{
			urlpath = '/consignments/filtercouriers';
		}
		else if(this.props.match.path == "/activecourierconsignments")
		{
			urlpath = '/consignments/getFilteredActiveCourierDataByParams';
		}
		else if(this.props.match.path == "/deliveredcourierconsignments")
		{
			urlpath = '/consignments/filterdelivercouriers';
		}

		this.onLoadShowCouriers(reqparasm)

		// redirectURL.post(urlpath,reqparasm)
		// .then((response)=>{
		// 	console.log("Resposne ", response.data)
		// 	var records = response.data;
		// 	//console.log("records", records);
		// 	var intransitarr=[];
		// 	var outdeliverarr = [];
		// 	var deliverarr = [];
		// 	var datanaarr = [];
		// 	records.map((item) => {

		// 		//In Transit
		// 		if(item.consignment_status == 'In Transit')
		// 		{
		// 			intransitarr.push(item);
		// 		}

		// 		//Out of Delivery
		// 		if(item.consignment_status == 'Out for delivery')
		// 		{
		// 			outdeliverarr.push(item);
		// 		}

		// 		//Delivered
		// 		if(item.consignment_status == 'Delivered')
		// 		{
		// 			deliverarr.push(item);
		// 		}

		// 		//Data Not Available
		// 		if(item.consignment_status == '' || item.consignment_status == null)
		// 		{
		// 			datanaarr.push(item);
		// 		}
				
				
		// 	});
		// 	let newArray = []; 
              
		// 	// Declare an empty object 
		// 	let uniqueObject = {}; 
		// 	var objTitle;
		// 	// Loop for the array elements 
		// 	for (let i in records) { 
	
		// 		// Extract the title 
		// 		objTitle = records[i]['consigner_code']; 
	
		// 		// Use the title as the index 
		// 		uniqueObject[objTitle] = records[i]; 
		// 	} 
			
		// 	// Loop to push unique object into array 
		// 	for (var a in uniqueObject) { 
		// 		newArray.push(uniqueObject[a]); 
		// 	} 
			
		// 	//console.log("newArray ", newArray)
		// 	var consigners=[];
		// 	newArray.map((ccode) => {
		// 		consigners.push(ccode.consigner_code)
		// 	})
        //     this.setState({
		// 		consigners:consigners,
		// 		rowData:records,
		// 		intransit:intransitarr,
		// 		outdeliver:outdeliverarr,
		// 		deliver:deliverarr,
		// 		datana:datanaarr,
		// 		intransitcount:intransitarr.length,
		// 		outdelivercount:outdeliverarr.length,
		// 		delivercount:deliverarr.length,
		// 		datanacount:datanaarr.length,
		// 		totalcount:records.length,
		// 		loadshow:'show-n'
        //     });
		// })
		// .catch(function(e){
		// 	console.log("Error ", e)
		// })
	}
	getTruckConsignments=(event)=>{
		event.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Search for truck/consignment",
			}
			googleAnalytics.logEvent(eventOptions);
		}

		var selectedFilter = $("#selectedFilter").text();
		var inputTruckConsignment = $("#inputTruckConsignment").val();
		if(selectedFilter == "Select..." || inputTruckConsignment == "")
		{
			this.setState({
				basicTitle : "Fields Should not be Empty",
				basicType : "danger",
				show : true
			})
		}
		else
		{
			console.log(selectedFilter,inputTruckConsignment);
			var urlpath=""
			if(this.props.match.path == "/activecourierconsignments")
			{
				urlpath = "/consignments/filterActiveCourierByConsignment";
				var pageurl = "/activecourierconsignments";
			}
			if(this.props.match.path == "/deliveredcourierconsignments")
			{
				urlpath = "/consignments/filterActiveCourierByConsignment";
				var pageurl = "/deliveredcourierconsignments";
			}
			if(this.props.match.path == "/courierconsignments")
			{
				urlpath = "/consignments/filterActiveCourierByConsignment";
				var pageurl = "/courierconsignments";
			}
			if(this.props.match.path == "/invalidcourierconsignments")
			{
				urlpath = "/consignments/filterActiveCourierByConsignment";
				var pageurl = "/invalidcourierconsignments";
			}
			redirectURL.post(urlpath,
			{"selectedFilter":selectedFilter,
			"inputTruckConsignment":inputTruckConsignment,
			"urlpath" : pageurl})
			.then((response)=>{
				var records = response.data;
				var intransitarr=[];
				var priorityarr=[];
				var outdeliverarr = [];
				var deliverarr = [];
				var forcearr = [];
				var datanaarr = [];
				var confirmDelivery=[]
				records.map((item) => {
					
					//In Transit
					if(item.consignment_status == 'In Transit')
					{
						intransitarr.push(item);
					}

					//Out of Delivery
					if(item.consignment_status == 'Out For Delivery')
					{
						outdeliverarr.push(item);
					}

					//Delivered
					if(item.consignment_status == 'Delivered' || item.consignment_status == 'Force Closed')
					{
						deliverarr.push(item);
					}

					if(item.consignment_status == 'Force Closed')
					{
						forcearr.push(item);
					}
					//Data Not Available
					if(item.consignment_status == '' || item.consignment_status == null || item.consignment_status == "Invalid docket")
					{
						datanaarr.push(item);
					}
					if(item.tpt_confirm_delivery == 1)
					{
						confirmDelivery.push(item);
					}
					//Priority
					if(item.is_priority == 1)
					{
						priorityarr.push(item)
					}
					
				});
				let newArray = []; 
				// console.log("item.consignment_status",outdeliverarr)
				// Declare an empty object 
				let uniqueObject = {}; 
				var objTitle;
				// Loop for the array elements 
				for (let i in records) { 
		
					// Extract the title 
					objTitle = records[i]['consigner_code']; 
		
					// Use the title as the index 
					uniqueObject[objTitle] = records[i]; 
				} 
				
				// Loop to push unique object into array 
				for (var a in uniqueObject) { 
					newArray.push(uniqueObject[a]); 
				} 
				
				//console.log("newArray ", newArray)
				var consigners=[];
				newArray.map((ccode) => {
					consigners.push(ccode.consigner_code)
				})
				//console.log("records ", records)
				if(this.props.match.path == "/invalidcourierconsignments")
				{
					var allrecords = datanaarr;
				}
				else
				{
					var allrecords = records;
				}
				
				this.setState({
					rowData:allrecords,
					originaldata:allrecords,
					intransit:intransitarr,
					outdeliver:outdeliverarr,
					deliver:deliverarr,
					datana:datanaarr,
					prioritydata:priorityarr,
					intransitcount:intransitarr.length,
					outdelivercount:outdeliverarr.length,
					delivercount:deliverarr.length,
					datanacount:datanaarr.length,
					totalcount:allrecords.length,
					prioritydatacount:priorityarr.length,
					confirmDelivery:confirmDelivery,
					loadshow:'show-n'
				});
				// this.setState({
				// 	rowData : response.data,
				// })
			}).catch=((e)=>
			{
				console.log(e);
			})
		}
	}

	onClickSaveGridState(){
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Save Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		
		let screenpage = '';

		if(this.props.match.path == "/courierconsignments")
		{
			screenpage='transporter courier consignments (all)';
		}
		else if(this.props.match.path == "/activecourierconsignments")
		{
			screenpage='transporter courier consignments (active)';
		}
		else if(this.props.match.path == "/deliveredcourierconsignments")
		{
			screenpage='transporter courier consignments (delivered)';
		}
		else if(this.props.match.path == "/invalidcourierconsignments")
		{
			screenpage='transporter courier consignments (invalid)';
		}

		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates()
	{
		try{


			if(this.state.usergridstate.length > 0)
			{
				var windowstates = this.state.usergridstate;
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
			
			}
		}
		catch(e){}
	}

	resetState = () => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Reset Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

	};
	onCellClicked(e){
		if(e.colDef.field == "priority")
		{
			if(e.data.is_priority !=1)
			{
				this.setState({
					loadshow : "show-m",
					overly:"show-m",
				});
				var consignmentId = e.data.consignment_code;
				var email = localStorage.getItem("email");
				var reqParams = {
					consignmentId : consignmentId,
					email : email
				}
				redirectURL.post("/consignments/updateCourierPriority",reqParams).then((response)=>{
					this.setState({
						loadshow : "show-n",
						overly:"show-n",
						basicPriorityTitle : "Consignment Marked As Priority",
						basicPriorityType : "success",
						showPriority : true,
					});
				}).catch((e)=>{
					console.log(e)
				})
			}
		}
	}
	render(){
		var hideonlyacive = (this.props.match.path == "/activecourierconsignments")?true:false;
		let hideCouriersCheckBox = ((this.props.match.path == "/activecourierconsignments") 
		&& (localStorage.getItem("user_type") == "TRANSPORTER"))?false:true;
		//console.log("Rendere ",this.state.deptcode)
		if(this.state.counttype == "active")
		{
			var hidden = false
		}
		else{
			var hidden = true
		}
		if(this.state.counttype != "active" && this.state.counttype != "invalid")
		{
			var reasonHide = false; 
		}
		else{
			var reasonHide = true;
		}
		if(localStorage.getItem("user_type") == "TRANSPORTER")
		{
			hidden = true;
			reasonHide = true;
		}
		const hideCritical = ((this.state.counttype == "active") || (this.state.counttype == "all"))?false:true;
		const columnwithDefs = [
                {
                    headerName: "",
                    field: "_id",
                    width: 70,
					pinned:'left',
                    cellRenderer:'courierActions',
                    filter: false,
                    
                },
				{
					headerName: "Consignment Code",
					field: "consignment_code",
					width: 115,
					editable:false,
					pinned:'left',
					filter: "agSetColumnFilter",
					//cellRenderer: "agGroupCellRenderer"
				},
				{
					headerName: "MGPA No",
					field: "mgpa_no",
					width: 115,
					editable:false,
					pinned:'left',
					filter: "agSetColumnFilter",
					//cellRenderer: "agGroupCellRenderer"
				},
				{
                    headerName: "MGPA date",
                    field: "mgpa_date_time",
                    width: 120,                 
                    // filter: "agSetColumnFilter",
					cellRenderer:'',
					pinned:'left',
					sort:"desc",
					valueGetter:function(params){
						return getHyphenDDMMMYYYYHHMM(params.data.mgpa_date_time)
					},
					//filter: "agDateColumnFilter",
						comparator: dateComparator1,
					// 	filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			else{
					// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
								
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			// console.log(cellValue,filterValue);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// }, 	          
				},
                {
                    headerName: "Status",
                    field: "consignment_status",                    
                    width: 120,
					pinned:'left',
                    filter: "agSetColumnFilter",
                    cellStyle: function(params) {
                        if (params.value=='Delivered') {
                            //mark police cells as red
                            return {color: 'green'};
                        } else {
                            return null;
                        }
					},
					valueGetter: function(params)
					{
						if(params.data.consignment_status == "Cancelled")
						{
							return "Cancelled As Per MSIL"
						}
						else
						{
							return params.data.consignment_status
						}
					}
				  },
				   
				  {
					  headerName: "Original ETA",
					  field: "expected_eta",
					  width: 120,   
					  pinned: 'left',              
					  resizable: true,
					  valueGetter:function(params){
						  return getHyphenDDMMMYYYYHHMM(params.data.expected_eta);	 
					  },
					  //filter: "agDateColumnFilter",
					  comparator: dateComparator,
					//   filterParams: {
					// 	  browserDatePicker: true,
					// 	  filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	  // provide comparator function
					// 	  comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		  if(cellValue != "" && cellValue != " "){
					// 			  cellValue = cellValue.split(" ")[0].split("-");
					// 			  cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
					// 			  var cellDate = new Date(cellValue);
					// 			  if(filteredDate.getMonth() < 10){
					// 			  var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			  }
					// 			  else{
					// 				  var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			  }
					// 			  if(filterValue.split("-")[2].length < 2)
					// 			  {
					// 				  var temp = filterValue
					// 				  filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			  }
					// 			  var filteredDate = new Date(filterValue);
					// 			  cellDate = cellDate.getTime();
					// 			  filteredDate = filteredDate.getTime();
					// 			  // console.log(cellDate,filteredDate);
					// 			  if(cellDate === filteredDate)
					// 			  {
					// 				  return 0;
					// 			  }
					// 			  if(cellDate < filteredDate){
					// 				  return -1;
					// 			  }
					// 			  if(cellDate > filteredDate)
					// 			  {
					// 				  return 1;
					// 			  }
					// 		  }
					// 	  }
					//   }, 	          
				  },          
				  {
					  headerName: "Revised ETA",
					  field: "revised_eta",
					  width: 120,   
					  pinned: 'left',              
					  resizable: true,
					  valueGetter:function(params){
						  //console.log(" ETA ", params.data)
							if(params.data.revised_eta == '' || params.data.revised_eta == undefined)
							{
								return getHyphenDDMMMYYYYHHMM(params.data.revised_eta);
							}
							else{
								return getHyphenDDMMMYYYYHHMM(params.data.revised_eta);
							}
					  
					  },
					  //filter: "agDateColumnFilter",
					  comparator: dateComparator,
					//   filterParams: {
					// 	  browserDatePicker: true,
					// 	  filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	  // provide comparator function
					// 	  comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		  if(cellValue != "" && cellValue != " "){
					// 			  cellValue = cellValue.split(" ")[0].split("-");
					// 			  cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
					// 			  var cellDate = new Date(cellValue);
					// 			  if(filteredDate.getMonth() < 10){
					// 			  var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			  }
					// 			  else{
					// 				  var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			  }
					// 			  if(filterValue.split("-")[2].length < 2)
					// 			  {
					// 				  var temp = filterValue
					// 				  filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			  }
					// 			  var filteredDate = new Date(filterValue);
					// 			  cellDate = cellDate.getTime();
					// 			  filteredDate = filteredDate.getTime();
					// 			  // console.log(cellDate,filteredDate);
					// 			  if(cellDate === filteredDate)
					// 			  {
					// 				  return 0;
					// 			  }
					// 			  if(cellDate < filteredDate){
					// 				  return -1;
					// 			  }
					// 			  if(cellDate > filteredDate)
					// 			  {
					// 				  return 1;
					// 			  }
					// 		  }
					// 	  }
					//   },
					  cellClass:function(params){
						  let hourDifference = 0;
						  if (params.data.revised_eta && params.data.expected_eta) {
							  let revisedDateOnlyMoment  = moment(params.data.revised_eta);
							  let expectedDateOnlyMoment  = moment(params.data.expected_eta);
							  hourDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'hours'); 
							  //console.log("diff= ", params.data.consignment_code, hourDifference);
						  } else {
							  //console.log("ELSE = ", params.data.revised_trip, params.data.expected_trip_end);
						  }
  
						  if(hourDifference >= 1)
						  {
							  return 'bgColorDangerMedium'
						  }
  
						  if(hourDifference <= -1)
						  {
							  return 'bgColorSuccessMedium'
						  }
						  else{
							  return ''
						  }
					  }
				  },
				  {
					headerName: "Delivered?",
					field: "",
					width: 120,
					pinned : "left",
					//checkboxSelection: true,
					hide:hideCouriersCheckBox,
                    cellRendererSelector:function(params){
                        
                            var rendComponent = {
                                component: 'deliverconsignment'
                            };
                            return rendComponent
						
					},
                    // valueGetter:function(params){
                    //     if(params.data.tpt_confirm_delivery == 1)
                    //     {
                    //         //console.log("tpt_delivery_date ",params.data.tpt_delivery_date);
                    //         return getHyphenDDMMMYYYYHHMM(params.data.tpt_delivery_date)
                    //     }
                    //     else{
                    //         return '';
                    //     }
                    // },
                    filter: false,
                    resizable: true
					
				},
				  {
                    headerName: "Transit Delays (Days)",
                    field: "",                    
                    width: 130,
                    valueGetter : function(params){
						var revisedeta = params.data.revised_eta;
						var originaleta = params.data.expected_eta;
						if(originaleta != "" && originaleta != null && originaleta != NaN && revisedeta != "" && revisedeta != null && revisedeta != NaN)
						{
							var diff = differenceInDays(revisedeta,originaleta);
							return diff;
						}
						else{
							return "";
						}
					}
				  },
				  
				  {
					headerName: "Transit Time/MSIL (Days)",
					field: "expected_transit_days",
					width:150,
					filter: true,
					resizable: true,
					editable:false
				},
				  {
					headerName: "Delivered On",
					field: "delivery_datetime",
					hide:hideonlyacive,
					width: 150,
					filter: "agSetColumnFilter",
					cellRenderer:'',
					valueGetter:function(params){
						return getHyphenDDMMMYYYYHHMM(params.data.delivery_datetime)
					},
					//filter: "agDateColumnFilter",
					  comparator: dateComparator,
					//   filterParams: {
					// 	  browserDatePicker: true,
					// 	  filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	  // provide comparator function
					// 	  comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		  if(cellValue != "" && cellValue != " "){
					// 			  cellValue = cellValue.split(" ")[0].split("-");
					// 			  cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
					// 			  var cellDate = new Date(cellValue);
					// 			  if(filteredDate.getMonth() < 10){
					// 			  var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			  }
					// 			  else{
					// 				  var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			  }
					// 			  if(filterValue.split("-")[2].length < 2)
					// 			  {
					// 				  var temp = filterValue;
					// 				  filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			  }
					// 			  var filteredDate = new Date(filterValue);
					// 			  cellDate = cellDate.getTime();
					// 			  filteredDate = filteredDate.getTime();
					// 			  // console.log(cellDate,filteredDate);
					// 			  if(cellDate === filteredDate)
					// 			  {
					// 				  return 0;
					// 			  }
					// 			  if(cellDate < filteredDate){
					// 				  return -1;
					// 			  }
					// 			  if(cellDate > filteredDate)
					// 			  {
					// 				  return 1;
					// 			  }
					// 		  }
					// 	  }
					//   },
				
				  },
				  {
                    headerName: "Delivery in Transit (Days)",
                    field: "",                    
                    width: 150,
                    valueGetter : function(params){
						var delivery_datetime = params.data.delivery_datetime;
						var mgpa_date_time = params.data.mgpa_date_time;
						if(delivery_datetime == "" || delivery_datetime == undefined || delivery_datetime == " " || delivery_datetime == NaN || delivery_datetime == "Invalid date"){
							delivery_datetime = new Date();
						}
						if(mgpa_date_time != "" && mgpa_date_time != null && mgpa_date_time != NaN)
						{
							var diff = deliveryInTransit(delivery_datetime,mgpa_date_time);
							if (diff > 0){
							return diff - 1;}else{
								return 0;
							}
						}
						else{
							return "";
						}
					}
				  },
				//   {
                //     headerName: "In Transit Delay (Days)",
                //     field: "",                    
                //     width: 150,
                //     valueGetter : function(params){
				// 		var currentDate = new Date();
				// 		var mgpa_date_time = params.data.mgpa_date_time;
				// 		if(mgpa_date_time != "" && mgpa_date_time != null && mgpa_date_time != NaN)
				// 		{
				// 			var diff = InTransitDelay(currentDate,mgpa_date_time);
				// 			return diff;
				// 		}
				// 		else{
				// 			return "";
				// 		}
				// 	}
				//   }, 
				  {
                    headerName: "Courier Status",
                    field: "last_status",                    
                    width: 150,
					filter: "agSetColumnFilter",
					// valueGetter:function(params){
					// 	if(typeof params.data.track_last_status != 'undefined')
					// 	{
					// 		if(params.data.track_last_status.length > 0)
					// 		{
					// 			var last_track_status = params.data.track_last_status[0].transit_details.sort(GetSortDescOrder("intransit_date"))
					// 			last_track_status = last_track_status.sort(GetSortDescOrder("intransit_time"))
					// 			console.log("Last ",last_track_status)
					// 		}
					// 	}
					// }
				  },  
				  {
                    headerName: "Courier Location",
                    field: "last_location",                    
                    width: 150,
                    filter: "agSetColumnFilter"
				  },  

				  {
                    headerName: "Updated On",
                    field: "last_date",                    
                    width: 150,
					// filter: "agSetColumnFilter",
					valueGetter : function(params){
						if(params.data.last_date != "" && params.data.last_date){
							return getHyphenDDMMMYYYYHHMM(params.data.last_date);
						}
					},
					//filter: "agDateColumnFilter",
					  comparator: dateComparator,
					//   filterParams: {
					// 	  browserDatePicker: true,
					// 	  filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	  // provide comparator function
					// 	  comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		  if(cellValue != "" && cellValue != " "){
					// 			  cellValue = cellValue.split(" ")[0].split("-");
					// 			  cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
					// 			  var cellDate = new Date(cellValue);
					// 			  if(filteredDate.getMonth() < 10){
					// 			  var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			  }
					// 			  else{
					// 				  var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			  }
					// 			  if(filterValue.split("-")[2].length < 2)
					// 			  {
					// 				  var temp = filterValue;
					// 				  filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			  }
					// 			  var filteredDate = new Date(filterValue);
					// 			  cellDate = cellDate.getTime();
					// 			  filteredDate = filteredDate.getTime();
					// 			  // console.log(cellDate,filteredDate);
					// 			  if(cellDate === filteredDate)
					// 			  {
					// 				  return 0;
					// 			  }
					// 			  if(cellDate < filteredDate){
					// 				  return -1;
					// 			  }
					// 			  if(cellDate > filteredDate)
					// 			  {
					// 				  return 1;
					// 			  }
					// 		  }
					// 	  }
					//   },
				  }, 
				  {
                    headerName: "Last Processed On",
                    field: "last_process_on",                    
                    width: 150,
					// filter: "agSetColumnFilter",
					valueGetter : function(params){
						if(params.data.last_process_on != "" && params.data.last_date){
							return getHyphenDDMMMYYYYHHMM(params.data.last_process_on);
						}
					},
					//filter: "agDateColumnFilter",
					  comparator: dateComparator,
					//   filterParams: {
					// 	  browserDatePicker: true,
					// 	  filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	  // provide comparator function
					// 	  comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		  if(cellValue != "" && cellValue != " "){
					// 			  cellValue = cellValue.split(" ")[0].split("-");
					// 			  cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
					// 			  var cellDate = new Date(cellValue);
					// 			  if(filteredDate.getMonth() < 10){
					// 			  var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			  }
					// 			  else{
					// 				  var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			  }
					// 			  if(filterValue.split("-")[2].length < 2)
					// 			  {
					// 				  var temp = filterValue;
					// 				  filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			  }
					// 			  var filteredDate = new Date(filterValue);
					// 			  cellDate = cellDate.getTime();
					// 			  filteredDate = filteredDate.getTime();
					// 			  // console.log(cellDate,filteredDate);
					// 			  if(cellDate === filteredDate)
					// 			  {
					// 				  return 0;
					// 			  }
					// 			  if(cellDate < filteredDate){
					// 				  return -1;
					// 			  }
					// 			  if(cellDate > filteredDate)
					// 			  {
					// 				  return 1;
					// 			  }
					// 		  }
					// 	  }
					//   },
				  },  
				  {
                    headerName: "Consigner Code",
                    field: "consigner_code",
                    width: 130,
                    filter: "agSetColumnFilter",
					cellRenderer:''	    	          
                },               
                {
                  headerName: "Consignee Code",
                  field: "consignee_code",
                  width: 130,                 
                  filter: "agSetColumnFilter",
				  cellRenderer:''	    	          
				}, 
				{
					headerName: "Consignee Name",
					field: "consignee_name",
					width: 130,                 
					filter: "agSetColumnFilter",
					resizable:true	    	          
				}, 
				{
                    headerName: "Consignee City",
                    field: "consignee_city",
                    width: 130,
                    filter: "agSetColumnFilter",
					cellRenderer:''	    	          
                },    
	  	          
				{
                    headerName: "Consignee State",
                    field: "consignee_state",
                    width: 130,
                    filter: "agSetColumnFilter",
					cellRenderer:''	    	          
				}, 
				{
                    headerName: "Vehicle Mode",
                    field: "vehicle_mode",
                    width: 130,
                    filter: "agSetColumnFilter",
					resizable:true,    	          
                },  
                {
                headerName: "Delivered At",
                field: "delivery_station",
                hide:hideonlyacive,
                width: 150,
                filter: "agSetColumnFilter",
                cellRenderer:''	          
              },
	  	        {
		          headerName: "Service Provider Code",
                  field: "transporter_code",
                  width: 130,
                  filter: "agSetColumnFilter",
				  cellRenderer:''	          
                }, 
				{
				headerName: "Service Provider Name",
				field: "service_provider",				
				width: 135,
				filter: "agSetColumnFilter",
				valueGetter:function(params){
					return params.data.service_provider;
					},         
				},
				{
                    headerName: "MSIL POD Date",
                    field:"pod_date",
                    width:110,
					valueGetter:(params)=>{
						if(params.data.pod_date != undefined && params.data.pod_date != null)
						{
							return getHyphenDDMMMYYYYHHMM(params.data.pod_date)
						}
						else{
							return ""
						}
					},
					resizable: true,

				},
				{
					headerName: "TPT Delivery Date",
					field: "tpt_delivery_date",				
					width: 135,
					filter: "agSetColumnFilter",
					valueGetter:function(params){
						if(params.data.tpt_delivery_date != "")
						{
							return getHyphenDDMMMYYYYHHMM(params.data.tpt_delivery_date);
						}
						else
						{
							return ""
						}
						
					}, 
					//filter: "agDateColumnFilter",
					  comparator: dateComparator,
					//   filterParams: {
					// 	  browserDatePicker: true,
					// 	  filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	  // provide comparator function
					// 	  comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		  if(cellValue != "" && cellValue != " "){
					// 			  cellValue = cellValue.split(" ")[0].split("-");
					// 			  cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
					// 			  var cellDate = new Date(cellValue);
					// 			  if(filteredDate.getMonth() < 10){
					// 			  var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			  }
					// 			  else{
					// 				  var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			  }
					// 			  if(filterValue.split("-")[2].length < 2)
					// 			  {
					// 				  var temp = filterValue;
					// 				  filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			  }
					// 			  var filteredDate = new Date(filterValue);
					// 			  cellDate = cellDate.getTime();
					// 			  filteredDate = filteredDate.getTime();
					// 			  // console.log(cellDate,filteredDate);
					// 			  if(cellDate === filteredDate)
					// 			  {
					// 				  return 0;
					// 			  }
					// 			  if(cellDate < filteredDate){
					// 				  return -1;
					// 			  }
					// 			  if(cellDate > filteredDate)
					// 			  {
					// 				  return 1;
					// 			  }
					// 		  }
					// 	  }
					//   },        
				},
				{
					headerName: "TPT Delivery Reason",
					field: "tpt_delivery_reason",				
					width: 200,
					filter: "agSetColumnFilter",   
				},
				{
					headerName : "Critical",
					field : "critical_record",
					width: 120,
					hide : hideCritical,
					valueGetter : function(params)
					{
						if(params.data.critical_record == 1)
						{
							return "Yes";
						}
						else
						{
							return "No";
						}
					}
				}, 
				{
					headerName : "Priority",
					field : "is_priority",
					width: 120,
					//hide : hidden,
					valueGetter : function(params)
					{
						if(params.data.is_priority == 1)
						{
							return "Yes";
						}
						else
						{
							return "No";
						}
					}
				},
				{
					headerName: "Mark Priority",
					field: "priority",
					width: 130,
					cellRenderer:function(params){
						if(params.data.is_priority == 1)
						{
							return "";
						}
						else
						{
							var htmloption = '<button class="btn btn-info" style="padding-top:0px">Priority</button>';
                			return htmloption;
						}
					},
					//hide : hidden,
					filter: false,
					resizable: true,	
				},
				{
					headerName: "Force Close",
					field: "consignment_code",
					width: 80,
					cellRendererSelector:function(params){					
						var rendComponent = {
							component: 'consignmentforceclose'
						};
						return rendComponent;
					},
					hide : hidden,
					filter: false,resizable: true,	
				}, 
				{
					headerName: "Mark Delivered",
					field: "consignment_code",
					width: 100,
					cellRendererSelector:function(params){					
						var rendComponent = {
							component: 'MarkDeliver'
						};
						return rendComponent;
					},
					hide : hidden,
					filter: false,resizable: true,	
				},
				{
					headerName: "Force Close Reason",
					field: "force_close_reason",
					width: 150,
					hide : reasonHide,
					filter: false,resizable: true,	
				},  
				             
	  	        
                // {
                //     headerName: "Vehicle Mode",
                //     field: "vehicle_mode",
                //     width: 180,                 
                //     filter: "agSetColumnFilter",
				// 	cellRenderer:''	    	          
                // },
                // {
                //     headerName:"Route ID",
                //     field:"route_id",
                //     width:200,
                //     filter:"agSetColumnFilter",
				// 	cellRenderer:''
                // },
                
                
                // {
                //     headerName:"Items",
                //     field:"items",
                //     width:200,
                //     filter:"agSetColumnFilter",
				// 	cellRenderer:''
                // },            
                // {
                //   headerName: "Driver1 Code",
                //   field: "driver1_code",
                //   width: 120,                 
                //   filter: "agSetColumnFilter",
				//   cellRenderer:''    	          
                // },
                // {
                //     headerName: "Driver2 Code",
                //     field: "driver2_code",
                //     width: 180,                 
                //     filter: "agSetColumnFilter",
				// 	cellRenderer:''	    	          
				// },
				// {
                //     headerName: "Cluster",
                //     field: "cluster",
                //     width: 180,                 
                //     filter: "agSetColumnFilter",
				// 	cellRenderer:''	    	          
				// },
								
            			
                
		];
		const dStyles={
			width:this.props.width,
			height:'500px',
			marginLeft:this.props.marginLeft,

	}
	const {delivermodal} = this.state;
	const modalStyles  = {
		width:'1300px !important',
	}
		return(
			<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
				>
				</SweetAlert>
				<SweetAlert
	            show={this.state.showPriority}
	            type={this.state.basicPriorityType}
	            title={this.state.basicPriorityTitle}
	            onConfirm={this.closePriorityAlert}
				>
				</SweetAlert>
	            {this.state.alert}
				<div className="row">
					<div className="col-xl-5 col-lg-6">
						<div className=" card" >
							<div className="row card-body"style={{padding:"10px 30px"}}>
							<form className="row" onSubmit={this.getTruckConsignments.bind(this)}>
								<div className="form-group col-xl-6 col-lg-6">
									<label>Select Type</label>
									<Select 
									className="border-radius-0"
									isMulti={false}
									id="selectedFilter"
									style={{borderRadius:"0px"}} 
									options={[{label:"Consingments",value:"Consingments"}]} required />
								</div>
								<div className={"col-xl-6 col-lg-6 form-group"}>
									<label className="reason">Consignment No</label>
									<input type="text" name="reason" autoComplete="off" class="form-control" id="inputTruckConsignment" />
								</div>
								<div className="form-group col-xl-12 col-lg-12" style={{}}>
									<button type="submit" style={{}} className="btn btn-success">Submit</button>
								</div>
							</form>
							</div>
						</div>
					</div>
					<div className="col-xl-7 col-lg-6">
						<div className=" card" >
							<div className="row card-body"style={{padding:"10px 30px"}}>
							<form method="POST" className="row theme-form col-xl-12 col-lg-12">
								<div className="form-group col-xl-4 col-lg-4">
									<label>Select Consigner: </label>
									<Select placeholder={"All"} closeMenuOnSelect={false} value={this.state.consigner} isMulti="true" className="border-radius-0" onChange={this.changeConsignerArrayHandler.bind(this)} style={{borderRadius:"0px"}} options={this.selectConsignerOptionsItems()} required />
								</div>
								<div className="form-group col-xl-4 col-lg-4">
									<label>From Date</label>
									{/* <input type="text" placeholder="From Date" id="from_date" onChange={this.handlerStartDateTime.bind(this)} value={this.state.from_date} className="datepicker form-control" /> */}
									<input type="text" placeholder="From Date" autoComplete="off" id="from_date" value={this.state.from_date}  className="datetimepicker_date form-control" required />
									
								</div>
								<div className="form-group col-xl-4 col-lg-4">
									<label>To Date</label>
									<input type="text" autoComplete="Off" autoComplete="off" placeholder="To Date" id="to_date" value={this.state.to_date}  className="datetimepicker_date form-control" required />
									{/* <input type="text" placeholder="To Date" id="to_date" onChange={this.handlerEndDateTime.bind(this)} value={this.state.to_date} className="datepicker form-control" /> */}
								</div>

								<div className="form-group col-xl-12 col-lg-12">
									<button className="btn btn-success" type="button" onClick={this.submitForm.bind(this)}>Submit</button>
								</div>
							</form>
							</div>
						</div>
					</div>
				</div>

				<div className="row">
            		<div className="col-xl-12 col-lg-12">

						<CourierCounter context={this} 
							records={this.state.rowData} 
							intransitcount={this.state.intransitcount}
							outdelivercount={this.state.outdelivercount}
							delivercount={this.state.delivercount}
							datanacount={this.state.datanacount}
							prioritydatacount={this.state.prioritydatacount}
							total={this.state.totalcount}
							counttype={this.state.counttype}
							critical_counter_data = {this.state.critical_counter_data.length}
							confirmDelivery = {this.state.confirmDelivery.length}
							cancelled_data = {this.state.cancelled_data}
							trip_closed_by_pod = {this.state.trip_closed_by_pod.length}
							pod_received_data = {this.state.pod_received_data.length}
							path={this.props.match.path}
						/> 
					</div>
				</div>
				<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5 style={{float:"left"}}>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span> {this.state.pageTitle}</span>
									 

								</h5>
								{(this.state.counttype == "active")?
								<div>
									<div className="float-right" style={{marginLeft:"7px"}}>
										<button
											type="button" 
											title="Write Comment"
											onClick={this.onBulkMarkDeliverBtn.bind(this)}
											className={"btn se-ico btn-info ml-5px "+(this.state.hideTransporterBtns)}
											type="button">
											Bulk Mark Delivered
										</button>
									</div>
									<div className="float-right" style={{marginLeft:"7px"}}>
										<button
											type="button" 
											title="Bulk Upload"
											onClick={this.onBulkForceCloseBtn.bind(this)}
											className={"btn se-ico btn-success ml-5px "+(this.state.hideTransporterBtns)}
											type="button">
											Bulk Force Close
										</button>
									</div>
								</div>
								:""}
								
									<div className="float-right">
										<button
											type="button" 
											title="Bulk Upload"
											onClick={this.onBulkPriorityBtn.bind(this)}
											className={"btn se-ico btn-danger ml-5px "+(this.state.hideTransporterBtns)}
											type="button">
											Bulk Mark Priority
										</button>
									</div>
							   </div>
							   
		            		<div className="row  card-body">
								<div className="col-xl-12">
									<span className="layoutbtns float-right">
										<button className="float-right custom-btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
											<i className="icofont icofont-save"></i> Save Grid Layout
										</button>
										<button className="float-right custom-btn white btn-info" style={{marginRight:"10px"}} onClick={this.resetState.bind(this)}>
											<i className="icofont icofont-refresh"></i> Reset Default Layout
										</button>
									</span>
								</div>
								 <div id="myGrid" style={{ height: "580px",width: "100%"}}  className="ag-theme-balham">
		          		          <AgGridReact
		          		            modules={this.state.modules}
		          		            columnDefs={columnwithDefs}
		          		            defaultColDef={this.state.defaultColDef}
		          		            rowData={this.state.rowData}
		          		            enableCharts={true}
		          		            enableRangeSelection={true}
		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
		          		            onGridReady={this.onGridReady}
		          		            onGridState={this.onGridState}
		          		            statusBar={this.state.statusBar}
									sideBar={this.state.sideBar}
									paginationPageSize={this.state.paginationPageSize}
									pagination={true}
									frameworkComponents={this.state.frameworkComponents}
									detailCellRendererParams={this.state.detailCellRendererParams}
									//editType={this.state.editType}
									stopEditingWhenGridLosesFocus={true}
									enableCellChangeFlash={true}
									suppressCellFlash={true}
									rowSelection={true}
									rowClassRules={this.state.rowClassRules}
									//onCellClicked={this.onRowClicked.bind(this)}
									onRowSelected={this.onRowSelection.bind(this)}
									gridOptions={{
										context: { componentParent: this }
									}}
									masterDetail={true}
									onCellClicked={this.onCellClicked.bind(this)}
		          		          />

									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>
									<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
		          		        </div>
				   			</div>
				   		</div>	
				   	</div>
				 </div>
				 <div className={"slide-r "+(this.state.sliderForceCloseTranslate)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Force Closure
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                                    
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        
					<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', overflow: 'hidden',zIndex:'9999',height:"302px"}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formForceCloseHandler.bind(this)}>
							{/* <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Reached Dealer Time</label>
								
                                <input type="text" placeholder="Reached Dealer " id="reached_dealer_time" className="datetimepicker_mask form-control"  />
							
							</div>

							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Left Dealer Time</label>
								
                                <input type="text" placeholder="Left Dealer " id="left_dealer_time" className="datetimepicker_mask form-control" />
							
							</div> */}
							{/* <ForceCloseSideBar context={this} handleInput = {this.handleInput}/> */}
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Reason for closure</label>
								<textarea name="reasonforceclose" id="forceClosureResonID" rows="5" className="form-control col-xl-12 col-lg-12" required></textarea>
							</div>
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>
                        </div>
					</div>
				</div>
				
					<div className={"slide-r "+(this.state.sliderMarkDeliverTranslate)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Mark Delivered
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                                    
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        
					<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', overflow: 'hidden',zIndex:'9999',height:"302px"}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formMarkDeliverHandler.bind(this)}>
							
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Delivery Time</label>
                                <input type="text" autoComplete="off" class="form-control datetimepicker_date" value={this.state.markDeliverDefault} id="delivery_date_time" required/>
							</div>
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>
                        </div>
					</div>
					</div>
				
				<div className={"slide-r "+(this.state.bulkPrioritySlide)} style={{overflow:"hidden"}}>
					<h3 className="subH">Bulk Priority</h3>
						 	
					<div className="slide-r-body" style={{position:"relative"}}>
						
						<div className="container-fluid">
							<form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkPriorityFormHandler.bind(this)}>

							<div className="form-group mt-20p">
								<label className="">Upload File</label> 
								<input type="file" name="uploadFile" id="bulkUploadPID" onChange={this.changePriorityFileHandler}  className="form-control" required  />
							</div>
							<div className="form-group">
								<button type="submit" className="btn btn-success">Submit</button>
								<button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
							</div>
							</form>
							<div className="form-group">
								<a className="btn btn-primary" href={require('../../assets/json/bulk_couriers_mark_deliver_sample.csv')} target="_blank">Sample Template</a>
							</div>
							<div className="form-group">
								{/* <a className="btn btn-primary" href={require('../../assets/json/Bulk_tickets_update_file.csv')} target="_blank">Sample Template</a> */}
							
							</div>		
						</div>
					</div>
				</div>

				<div className={"slide-r "+(this.state.bulkForceCloseSlide)} style={{overflow:"hidden"}}>
					<h3 className="subH">Bulk Force Close</h3>
						 	
					<div className="slide-r-body" style={{position:"relative"}}>
						
						<div className="container-fluid">
							<form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkForceFormHandler.bind(this)}>

							<div className="form-group mt-20p">
								<label className="">Upload File</label> 
								<input type="file" name="uploadFile" id="bulkUploadID" onChange={this.changeFileHandler}  className="form-control" required  />
							</div>
							<div className="form-group">
								<button type="submit" className="btn btn-success">Submit</button>
								<button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
							</div>
							</form>
							<div className="form-group">
								<p style={{color:"#ff0000"}}>* Note : All fields related to Force close are mandatory and cannot be empty</p>
								{/* <p style={{color:"green"}}>* Please use the below fields as headers while uploading</p>
								<p style={{color:"cornflowerblue"}}> -> consignment_code</p>
								<p style={{color:"cornflowerblue"}}> -> reason</p> */}
						
							</div>
							<div className="form-group">
								<a className="btn btn-primary" href={require('../../assets/json/bulk_couriers_force_close_sample.csv')} target="_blank">Sample Template</a>
							</div>
							<div className="form-group">
								{/* <a className="btn btn-primary" href={require('../../assets/json/Bulk_tickets_update_file.csv')} target="_blank">Sample Template</a> */}
							
							</div>		
						</div>
					</div>
				</div>

				<div className={"slide-r "+(this.state.bulkMarkDeliverSlide)} style={{overflow:"hidden"}}>
					<h3 className="subH">Bulk Mark Delivered</h3>
						 	
					<div className="slide-r-body" style={{position:"relative"}}>
						
						<div className="container-fluid">
							<form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkMarkDeliverHandler.bind(this)}>

							<div className="form-group mt-20p">
								<label className="">Select Delivery Time</label> 
								<input type="text" autoComplete="off" class="form-control datetimepicker_date" id="bulk_delivery_date_time" required/>
							</div>
							<div className="form-group mt-20p">
								<label className="">Upload File</label> 
								<input type="file" name="uploadFile" id="bulkUploadMDID" onChange={this.changeMDHandler}  className="form-control" required  />
							</div>
							<div className="form-group">
								<button type="submit" className="btn btn-success">Submit</button>
								<button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
							</div>
							</form>
							<div className="form-group">
								<p style={{color:"#ff0000"}}>*Note: </p>
								<p><b style={{color:"#ff0000"}}> * </b>All fields related to Mark Delivered are mandatory and cannot be empty</p>
								<p><b style={{color:"#ff0000"}}> * </b>Delivery Date Must be Greater than MGPA Datetime.</p>
							</div>
							<div className="form-group">
								<a className="btn btn-primary" href={require('../../assets/json/bulk_couriers_mark_deliver_sample.csv')} target="_blank">Sample Template</a>
							</div>
							<div className="form-group">
								{/* <a className="btn btn-primary" href={require('../../assets/json/Bulk_tickets_update_file.csv')} target="_blank">Sample Template</a> */}
							
							</div>		
						</div>
					</div>
				</div>
				<Modal open={delivermodal} onClose={this.onCloseDeliverModal} styles={modalStyles}>
	                <div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
					<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formSetDeliveredDate.bind(this)}>
							
							<div className="row form-group col-xl-12 col-lg-12">
								<label className="col-xl-4 col-lg-4">Reason For Delay/Current Status</label>
								<Select 
								className="border-radius-0 col-xl-6 col-lg-6"
								isMulti={false}
								id="selectedFilter"
								onChange={this.setSelectedReason.bind(this)}
								style={{borderRadius:"0px"}} 
								options={this.selectReason()} required />
							</div>

							<div className={"col-xl-12 col-lg-12 row form-group"} style={{display:this.state.delDate}}>
								<label className="col-xl-4 col-lg-4">Delivery Date</label>
								<Datetime 
                                    value={this.state.deliverdate} 
                                    disableCloseOnClickOutside={false} 
                                    closeOnSelect={true} 
                                    inputProps={{ placeholder: 'Delivery Date', name: 'deliverdate', autoComplete:'off' }} 
                                    dateFormat="YYYY-MM-DD"  
                                    name="deliverdate"
                                    className="col-xl-6 col-lg-6" 
                                    onChange={this.handlerDeliverDateTime.bind(this)} 
                                />
							</div>
							<div className={"form-group col-xl-12 col-lg-12 row "+(this.state.displayOtherField)}>
								<label className="col-xl-4 col-lg-4" style={{float: "left"}}>Other Reason</label>
								<textarea name="reason" onChange={this.changeReasonHandler.bind(this)} rows="5" className="form-control col-xl-6 col-lg-6" ></textarea>
							</div>	
							<div className="col-xl-12 col-lg-12 form-group" style={{textAlign:"center"}}>
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>
					</div>
				</Modal>
            </div>
              	
		);
	}
}
function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
                   
function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = Math.floor(hours);
	var minutes = (n) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}                              
var currentinfowindow = null;
function getTimeInfo(marker,timedet,coords)
{
	var timeinfo = new window.google.maps.InfoWindow({
          content: "Time at this location: " + timedet
        });

	marker.addListener('click', function() {
		
				if (currentinfowindow != null) {
					currentinfowindow.close();
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				else
				{
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				
        });
}


function secondsToString(seconds)
{
var numdays = Math.floor(seconds / 86400);
var numhours = Math.floor((seconds % 86400) / 3600);
var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
var numseconds = ((seconds % 86400) % 3600) % 60;
return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}


function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}
function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	// console.log(date.length);
	// console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}


window.onload = function (){
	// $(".datepicker").datepicker({
    //     dateFormat: "dd-mm-yy",
    //     changeMonth: true,
    //     changeYear: true
	// });
	$('.datetimepicker_mask1').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
};

function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function differenceInDays(startDate,endDate){
	// console.log(startDate,endDate);
	startDate = moment.parseZone(startDate);
	endDate = moment.parseZone(endDate);
	// console.log(startDate);
	// console.log(endDate);
	var res = Math.abs((startDate - endDate)/1000);
	return Math.round((res)/86400);
}

function deliveryInTransit(startDate,endDate){
	// console.log(startDate,endDate);
	endDate = endDate.split("T")[0];
	if(typeof(startDate) == "string")
	{
		if(startDate.indexOf(":") >= 0)
		{
			startDate = startDate.split(" ")[0];
		}
		if(startDate.indexOf(":")>=0 && startDate.indexOf("T") >= 0)
		{
			startDate = startDate.split("T")[0];
		}

		// console.log(startDate);
		// console.log(startDate.split("-")[1]+"/"+startDate.split("-")[2]+"/"+startDate.split("-")[0]);
		// console.log(endDate.split("-")[1]+"/"+endDate.split("-")[2]+"/"+endDate.split("-")[0]);
		if(startDate.indexOf("/") < 0){
			startDate = new Date(startDate.split("-")[1]+"/"+startDate.split("-")[2]+"/"+startDate.split("-")[0]);
		}
		else{
			startDate = new Date(startDate.split("/")[1]+"/"+startDate.split("/")[2]+"/"+startDate.split("/")[0]);
		}
	}
	else{
		var dd = String(startDate.getDate()).padStart(2, '0');
		var mm = String(startDate.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = startDate.getFullYear();
		startDate = mm + '/' + dd + '/' + yyyy;
		startDate = new Date(startDate);
	}
	
	endDate = new Date(endDate.split("-")[1]+"/"+endDate.split("-")[2]+"/"+endDate.split("-")[0]);
	// console.log(startDate,endDate);
	var difference = startDate.getTime() - endDate.getTime();
	// console.log(difference);
	// var res = Math.abs((startDate - endDate)/1000);
	return Math.abs(Math.round((difference)/(1000*3600*24)));

}

function InTransitDelay(startDate,endDate){
	endDate = endDate.split("T")[0];
	var dd = String(startDate.getDate()).padStart(2, '0');
	var mm = String(startDate.getMonth() + 1).padStart(2, '0'); //January is 0!
	var yyyy = startDate.getFullYear();
	startDate = mm + '/' + dd + '/' + yyyy;
	startDate = new Date(startDate);
	endDate = new Date(endDate.split("-")[1]+"/"+endDate.split("-")[2]+"/"+endDate.split("-")[0]);
	console.log(startDate,endDate);
	var difference = startDate.getTime() - endDate.getTime();
	console.log(difference);
	// var res = Math.abs((startDate - endDate)/1000);
	return Math.abs(Math.round((difference)/(1000*3600*24)));
}

function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 
