/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import $ from "jquery";
import _ from "lodash";
import MarkerClusterer from "@google/markerclusterer";
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import CountUp from 'react-countup';
import Modal from 'react-responsive-modal';

import {
    getDDMMYYYY,
    getDDMMYYYYHHMMSS,
    getYYYYMMDD,
    getYYYYMMDDHHMMSS,
    getHyphenYYYYMMDDHHMMSS,
    getHyphenDDMMMYYYYHHMM,
    getHyphenYYYYMMDD,
    getHyphenDDMMYYYYHHMMSS,
    getHyphenDDMMYYYY,
    getDDMMMYYYYHHMMDefault
} from '../common/utils';
var redirectURL = require('../redirectURL');

var infoBox = require('../common/google-infowindow');
var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');

var marker;
var markers = [];
var circle;
var selectlat;
var selectlng
var allcords = [];
var map;
var rad = function (x) {
    return x * Math.PI / 180;
};
var rlatitude;
var rlongitude;

var fencingLength = 0;
var geoFencingArea;

export default class TruckLocationForMobile extends Component {

    state = {
        pageTitle: "",
        eventGridAction: "gridAction",
        eventCounterAction: "counterAction",
        eventFormAction: "formAction",
        showform: 'show-m',
        loadshow: 'show-n',
        showrefresh: 'show-n',
        sliderTranslate: '',
        showdata: this.displayData,
        radius: '5000',
        rlatitude: '',
        rlongitude: '',
        latitude: '',
        longitude: '',
        defaultradius: 0,
        coordinates: [],
        alert: null,
        show: false,
        basicTitle: '',
        basicType: "default",
        contentString: "testing",
        mwidth: "col-xl-12 col-lg-12",
        alldepts: [],
        transportervalue: 'ALL',
        alltransporters: [],
        truckstatus: '0',
        kpicode: '0',
        deptcode: '',
        truckNoList: [],
        alltrucks: [],
        truckno: '',
        showTrucksList: "show-n",
        filteredData: [],
        triptype: { "value": 1, "label": "ALL" },
        plantLocations: [],
        totalTrucksCount: 0,
        onTripCounter: 0,
        usermanualmodal: false,
        weatherData: [],
        displayWeatherForecast: false,
        weatherMarkers: [],
        loginplant: '',
        truck_no: this.props.match.params.param1
    }

    componentDidMount = () => {
        const { searchType, param1, trip_type, dept } = this.props.match.params
        console.log(searchType, '\n', param1, '\n', trip_type, '\n', dept)
        var transporter_code = []
        var tripType = 1
        try{
            if (searchType == 'transporter') {
                transporter_code = JSON.stringify([param1])
                tripType = trip_type
                var selectedTransporters = transporter_code;
                console.log('selectedTransporters',selectedTransporters)
                this.onLoadPageFunc(selectedTransporters, dept, tripType)
            }
            
            if (searchType == 'truck') {
                // console.log("check2")
                var selectedTransporters = transporter_code;
                let mapLoad = this.onLoadPageFunc(selectedTransporters, dept, tripType)
                this.setState({
                    truck_no: param1
                })
                // Promise.all([mapLoad]).then(() =>{
                //     this.truckFormHandler()
                // })
                setTimeout(() => { this.truckFormHandler() }, 5000);
            }
        }catch(e){

        }
       
    }

    onLoadPageFunc = (selectedTransporters, dept, tripType) => {
        var transcode = [];
        try {
            if (selectedTransporters.length == 0) {
                selectedTransporters = "ALL";
            }
            if (selectedTransporters != "ALL") {
                transcode =  selectedTransporters
                if (transcode.indexOf("ALL") != -1) {
                    transcode = "ALL";
                }
            }
            else {
                transcode = selectedTransporters;
            }
        } catch (e) {
            console.log("error",e)
            transcode = "ALL";
        }

        var formdata = {
            radius: 3000,
            latitude: "20.73568784876339",
            longitude: "79.38999444931353",
            dept_code: dept,
            transporter_code: transcode,
            status: '0',
            trip_type: tripType
        }
        rlatitude = "20.73568784876339";
        rlongitude = "79.38999444931353";
        //http://autometrics.in:5000/getNearByTrucks
        redirectURL.post('/vicinity/getRadiusCoordinates', formdata, {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'PUT, GET, POST, DELETE, OPTIONS',
                'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept, Authorization"

            }
        })
            .then(
                (response) => {
                    // console.log(response)
                    // console.log("response", JSON.parse(response.data.body));
                    // console.log(response.data.body)
                    var markers = JSON.parse(JSON.parse(response.data.apiData.body).trucks_data);
                    	console.log('markers',markers);
                    if (markers.length > 0) {
                        markers.map((item) => {
                            // console.log("Plant ", item);
                        })
                    }
                    // console.log(JSON.parse(response.data.apiData.body).gps_trucks);
                    if (markers.length > 0) {
                        this.setState({
                            weatherData: response.data.weatherData,
                            defaultradius: formdata.radius,
                            coordinates: markers,
                            totalTrucksCount: markers.length,
                            mwidth: "col-xl-12 col-lg-12",
                            rowData: markers,
                            radius: 5000,
                            loadshow: 'show-n',
                            showform: 'show-n',
                            showrefresh: 'show-m',
                            rlatitude: "21.144644112601775",
                            rlongitude: "79.08860126768066",
                            alltrucks: markers,
                            onTripCounter: JSON.parse(response.data.apiData.body).gps_trucks
                        });
                        redirectURL.post("/weather/getPlants").then((rsp) => {
                            this.setState({
                                plantLocations: rsp.data,
                            });
                            //console.log(rsp.data);
                            this.renderMap();

                        })

                    }
                    else {
                        this.setState({
                            show: true, basicType: 'warning', basicTitle: "No Data found.",
                            loadshow: 'show-n',
                            showform: 'show-m',
                        });

                    }
                })
            .catch(function (error) {
                console.log(error);
            });
    }

    renderMap = () => {

        loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyBQUmk7hdajZEAYllxKhVPnUx5pXuK-j_Y&libraries=places,drawing&callback=initMap");
        window.initMap = this.initMap
    }

    initMap = () => {
        allcords = [];
        this.state.coordinates.map((marker) => {
            allcords.push(marker);
        });

        var contentdata = this.state.contentString;
        if (this.state.rlatitude == '' && this.state.rlongitude == '') {
            var lt = 21.144644112601775;
            var ln = 79.08860126768066;
        }
        else {
            var lt = this.state.rlatitude;
            var ln = this.state.rlongitude;
        }
        try {
            map = new window.google.maps.Map(document.getElementById('mobile_map_canvas'), {
                zoom: 5,
                center: new window.google.maps.LatLng(lt, ln),
                mapTypeId: window.google.maps.MapTypeId.ROADMAP,
                /*mapTypeControl: true,
                gestureHandling: 'greedy',
                mapTypeControlOptions: {
                    style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                    position: window.google.maps.ControlPosition.TOP_RIGHT
                  },
                disableDefaultUI: true,
                zoomControl: true*/
            });
        }
        catch (e) {
            map = new window.google.maps.Map(document.getElementById('mobile_map_canvas'), {
                zoom: 5,
                center: new window.google.maps.LatLng(parseFloat(21.144644112601775), parseFloat(79.08860126768066)),
                mapTypeId: window.google.maps.MapTypeId.ROADMAP,
                /*mapTypeControl: true,
                gestureHandling: 'greedy',
                mapTypeControlOptions: {
                    style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                    position: window.google.maps.ControlPosition.TOP_RIGHT
                  },
                disableDefaultUI: true,
                zoomControl: true*/
            });
        }

        circle = new window.google.maps.Circle({
            map: map
        });

        marker = new window.google.maps.Marker({
            map: map,
            draggable: true,
            animation: window.google.maps.Animation.DROP,
            //position: {lat: 28.48, lng: 77.06}
        });

        marker.addListener('click', function () {
            marker.setAnimation(window.google.maps.Animation.BOUNCE)
        });

        window.google.maps.event.addListener(map, 'click', function (event) {

            //document.getElementById("latitude").value = event.latLng.lat();
            //document.getElementById("longitude").value = event.latLng.lng();
            marker.setPosition(event.latLng);

        });
        circle.bindTo('center', marker, 'position');
        var markerLatlng1;
        //console.log("Radius ", this.state.radius)
        // console.log(allcords);
        var markers = allcords.map((marker) => {
            // console.log("marker ",marker);
            if (marker.status == 1) {
                var truckText = "Inside Plant";
                var color = "#333333";
                var image = require('../../assets/icons/truck_inside_24.png');
            }
            else if (marker.status == 2) {
                var truckText = "Going to Dealer";
                var color = "#333333";
                var image = require('../../assets/icons/truck_going_24.png');
            }
            else if (marker.status == 3 || marker.status == 4) {
                var truckText = "At Dealer Location";
                var color = "#333333";
                var image = require('../../assets/icons/truck_atdealer_24.png');
            }
            else if (marker.status == 5) {
                var truckText = "Left Destination Location";
                var color = "#333333";
                var image = require('../../assets/icons/truck_leftdealer_24.png');
            }
            else if (marker.status == 6) {
                var truckText = "Return to Plant";
                var color = "#333333";
                var image = require('../../assets/icons/truck_return_24.png');
            }
            else if (marker.status == 7) {
                var truckText = "Empty Truck Outside Plant";
                var color = "#333333";
                var image = require('../../assets/icons/truck_empty_24.png');
            }
            else {
                var truckText = marker.truck_no;
                var color = "#333333";
                var image = require('../../assets/icons/truck_na_24.png');
            }
            var image = require('../../assets/icons/truck_na_24.png');
            var contentarr = []
            var headerTitle = truckText
            //contentarr.push({"key":"Truck No", "value":marker.truck_no});
            contentarr.push({ "key": "Transporter Name", "value": marker.transporter_name });
            //contentarr.push({"key":"Distance from geofence", "value":marker.distance});
            contentarr.push({ "key": "GPS Provider", "value": marker.actual_lspuser });
            contentarr.push({ "key": "Last Packet Time", "value": getHyphenDDMMMYYYYHHMM(marker.timestamp) });
            contentarr.push({ "key": "Speed (km/h)", "value": Math.ceil(marker.speed) });
            contentarr.push({ "key": "Address", "value": marker.area + ", " + marker.state });

            var contentString = infoBox(image, headerTitle, contentarr, '')

            var infowindow = new window.google.maps.InfoWindow({
                content: contentString
            });

            var markerLatlng = new window.google.maps.LatLng(parseFloat(marker.latitude), parseFloat(marker.longitude));

            if (marker.status == 1) {
                var image = require('../../assets/icons/truck_inside_24.png');
            }
            else if (marker.status == 2) {
                var image = require('../../assets/icons/truck_going_24.png');
            }
            else if (marker.status == 3 || marker.status == 4) {
                var image = require('../../assets/icons/truck_atdealer_24.png');
            }
            else if (marker.status == 5) {
                var image = require('../../assets/icons/truck_leftdealer_24.png');
            }
            else if (marker.status == 6) {
                var image = require('../../assets/icons/truck_return_24.png');
            }
            else if (marker.status == 7) {
                var image = require('../../assets/icons/truck_empty_24.png');
            }
            else {
                var image = require('../../assets/icons/truck_na_24.png');
            }
            var image = require('../../assets/icons/truck_na_24.png');

            var mark = new window.google.maps.Marker({
                position: markerLatlng,
                map: map,
                title: "Status: " + truckText + " #" + marker.truck_no,
                icon: image
            });
            mark.addListener('click', function () {
                infowindow.open(map, mark);
            });
            return mark
            mark.setMap(map);
        });
        var eventtrigger = document.getElementsByClassName('truck');
        var markerCluster = new MarkerClusterer(map, markers,
            { imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m' });
        // var searchBox = new window.google.maps.places.SearchBox(eventtrigger);
        //map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(eventtrigger);
        if (this.state.plantLocations.length > 0) {
            this.state.plantLocations.map(function (params) {
                var plant = {
                    url: require("../../assets/icons/" + params.icon), // url
                    scaledSize: new window.google.maps.Size(32, 32), // scaled size
                    origin: new window.google.maps.Point(0, 0), // origin
                    anchor: new window.google.maps.Point(0, 0) // anchor
                };
                var coords = JSON.parse(params.coordinates);
                var plantmarker = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(coords.lat, coords.lng),
                    map: map,
                    icon: plant,
                    title: params.plant_name
                });
            })
        }
    }

    truckFormHandler = (e) => {
        // e.preventDefault();
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pageTitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventFormAction,
                "label": googleAnalytics.page.action.formSubmittedSearchByTruckNo
            }
            googleAnalytics.logEvent(eventOptions);
        }
        var truck = []
        var trucks = this.state.alltrucks;
        // console.log("trucks",this.state.truck_no)
        let truckNo = this.state.truck_no
        var filteredValue = trucks.filter(function (e) {
            return e.truck_no == truckNo
        });
        // console.log('filteredValue', filteredValue)
        if (filteredValue.length > 0) {
            console.log("Matched")
            var lat = parseFloat(filteredValue[0].latitude);
            var lng = parseFloat(filteredValue[0].longitude);
            var data = filteredValue[0];
            map.setCenter(new window.google.maps.LatLng(lat, lng));
            map.setZoom(22);
            var markerLatlng = new window.google.maps.LatLng(lat, lng);

            if (filteredValue[0].status == 1) {
                var truckText = "Inside Plant";
                var color = "#333333";
            }
            else if (filteredValue[0].status == 2) {
                var truckText = "Going to Dealer";
                var color = "#333333";
            }
            else if (filteredValue[0].status == 3 || filteredValue[0].status == 4) {
                var truckText = "At Dealer Location";
                var color = "#333333";
            }
            else if (filteredValue[0].status == 5) {
                var truckText = "Left Destination Location";
                var color = "#333333";
            }
            else if (filteredValue[0].status == 6) {
                var truckText = "Return to Plant";
                var color = "#333333";
            }
            else if (filteredValue[0].status == 7) {
                var truckText = "Empty Truck Outside Plant";
                var color = "#333333";
            }
            else {
                var truckText = "";
                var color = "#333333";
            }

            if (filteredValue[0].status == 1) {
                var image = require('../../assets/icons/truck_inside_24.png');
            }
            else if (filteredValue[0].status == 2) {
                var image = require('../../assets/icons/truck_going_24.png');
            }
            else if (filteredValue[0].status == 3 || filteredValue[0].status == 4) {
                var image = require('../../assets/icons/truck_atdealer_24.png');
            }
            else if (filteredValue[0].status == 5) {
                var image = require('../../assets/icons/truck_leftdealer_24.png');
            }
            else if (filteredValue[0].status == 6) {
                var image = require('../../assets/icons/truck_return_24.png');
            }
            else if (filteredValue[0].status == 7) {
                var image = require('../../assets/icons/truck_empty_24.png');
            }
            else {
                var image = require('../../assets/icons/truck_na_24.png');
            }
            var image = require('../../assets/icons/truck_na_24.png');
            var marker = new window.google.maps.Marker({
                position: markerLatlng,
                map: map,
                title: data.truck_no,
                icon: image
            });

            var contentarr = []
            var headerTitle = truckText
            contentarr.push({ "key": "Transporter Name", "value": filteredValue[0].transporter_name });
            //contentarr.push({"key":"Distance from geofence", "value":marker.distance});
            contentarr.push({ "key": "GPS Provider", "value": filteredValue[0].actual_lspuser });
            contentarr.push({ "key": "Last Packet Time", "value": getHyphenDDMMMYYYYHHMM(filteredValue[0].timestamp) });
            contentarr.push({ "key": "Speed (km/h)", "value": Math.ceil(filteredValue[0].speed) });
            contentarr.push({ "key": "Address", "value": filteredValue[0].area + ", " + filteredValue[0].state });

            var contentString = infoBox(image, headerTitle, contentarr, '')

            var contentString = infoBox(image, headerTitle, contentarr, '')

            var infowindow = new window.google.maps.InfoWindow({
                content: contentString
            });

            marker.setAnimation(window.google.maps.Animation.DROP)
            marker.addListener('click', function () {
                infowindow.open(map, marker);
            });

            marker.setMap(map);
        }
        else {
            this.setState({
                show: true, basicType: 'warning',
                basicTitle: "No Data found.",
                loadshow: 'show-n',
                showform: 'show-m',
            })
        }
    }

    render() {
        return (
            <div className="row">
                <div id="mobile_map_canvas" style={{ width: '100%', height: "100vh"}}></div>
            </div>
        )
    }
}

function loadScript(url) {
    var index = window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src = url
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}