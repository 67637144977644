import React, { Component } from 'react';
import ReactDOM from 'react-dom';
// import '../../../../node_modules/react-grid-layout/css/styles.css';
// import '../../../../node_modules/react-resizable/css/styles.css';

import { WidthProvider, Responsive } from "react-grid-layout";
import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';

import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import redirectURL from '../../redirectURL';
import { getDDMMYYYY, getDDMMYYYYHHMMSS,getHyphenDDMMMYYYYHHMM } from '../../common/utils';

import CountUp from 'react-countup';
var moment = require('moment');
var googleAnalytics = require("../../common/googleanalytics");

class GPSNRD extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			totalTrucks: [],
			showdata : this.displayData,
			sndshow:'show-n',
			modules: AllModules,
  	        open: false,
  	        defaultColDef: {
  	        sortable: true,
			  filter: true,
			  resizable: true

			},
			frameworkComponents: {
			},
            originalData:[],
  	        rowData: null,
  	        rowwithoutData: [],
			rowgpspacketData:null,
  	        rowModelhighchartoptionsType: 'enterprise',
  	        rowGroupPanelShow: 'always',
  	        animateRows: true,
  	        debug: true,
  	        showToolPanel: true,
  	        suppressAggFuncInHeader: true,
            statusBar: {
				statusPanels: [
				  {
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
				  },
				  {
					statusPanel: "agTotalRowCountComponent",
					align: "center"
				  },
				  { statusPanel: "agFilteredRowCountComponent" },
				  { statusPanel: "agSelectedRowCountComponent" },
				  { statusPanel: "agAggregationComponent" }
				]
			  },
            sideBar: {
				toolPanels: [
				  {
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
				  },
				  {
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
				  }
				]
            },
			usergridstate:[],
            loadshow:"show-n",			  
            overly:"show-n",
            pagetitle:"",
            t1to5days:[],
            t6to10days:[],
            t11to15days:[],
            t16to20days:[],
            t21to25days:[],
            t26to30days:[],
            t31to45days:[],
            t45days:[]
			
		};
		this.onShowGridData = this.onShowGridData.bind(this);
		// this.activeOntripsData = this.activeOntripsData.bind(this);
		this.cipher = this.cipher.bind(this);
	}

    componentDidMount(){
		//googleAnalytics.initGA();
		this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});
		var pagetitle=''
        pagetitle = "GPS NRD"
        
        let currentDepartmentCode = "SNDG";
        this.setState({
			deptvalue:currentDepartmentCode,
			currentDepartmentTitle:"Sales and Dispatch",
		});
		this.filterByDepartment();
	}

	cipher = salt => {
		const textToChars = text => text.split('').map(c => c.charCodeAt(0));
		const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
		const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
		try{
			return text => text.split('')
				.map(textToChars)
				.map(applySaltToChar)
				.map(byteHex)
				.join('');
		}
		catch(e){

		}

	}
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
    }
 onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;



	//    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;

	//    };

 };

onChartOptionsChanged(event) {
	  //console.log("where is this", event)
	    var chartOptions = event.chartOptions;
	    //savedLegendUserPreference = chartOptions.legend;
	   // savedUserPreferenceByChartType[event.chartType] = chartOptions;
}
onFirstDataRendered(params) {
    var createRangeChartParams = {
      cellRange: {
        columns: ["country", "sugar", "fat", "weight"]
      },
      chartContainer: document.querySelector("#myChart"),
      chartType: "bubble",
      suppressChartRanges: true
    };
   // currentChartRef = params.api.createRangeChart(createRangeChartParams);
}

 onGridState = () =>{
	 //console.log(this.gridApi);

	 /*Get  Current Columns State and Store in this.colState */
	   this.colState = this.gridColumnApi.getColumnState();

	 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
	   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

	 /*Get Current Pivot Columns State and Store in this.pivotColumns,
	  * User should enable the Pivot mode.*/
	   this.pivotColumns = this.gridColumnApi.getPivotColumns();

	 /*Get Current Filter State and Store in window.filterState */
	   window.filterState = this.gridApi.getFilterModel();
	   this.gridApi.setFilterModel(window.filterState);



 }

    onScatterHover(point) {
        return `<b>Date: </b>${point.x}<br /><b>Value: </b>${point.y}`;
    }
    createSelectItems() {
        let items = [];
        //console.log(this.state.alldepts);
        items.push(<option key='all' value='all'>All</option>);
        this.state.alldepts.map((item, key) => {
        	//console.log("Here "+item,key);
        	items.push(<option key={item.dept_code} value={item.dept_code}>{item.dept_name}</option>);
		});
        return items;
    }
    handleChange(e) {
    	//console.log("V",e.target.value);
        this.setState({ deptvalue: e.target.value });
	  }

    filterByDepartment = async () => {
		this.setState({
			loadshow:'show-m'
		})
    	//console.log("val is : ", this.state.deptvalue);
    	// if(this.state.deptvalue == 'all')
		// {
    	// 	window.location.reload();
        // }
        var dcode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
       var code = dcode('SNDG');
				
		let currentDepartmentCode = code;

        var defaulttab = ""

		var israil = 0;
		let layoutdata={
			//token:localStorage.getItem('token'),
			israil:israil,
			defaulttab:defaulttab
		}
		if (currentDepartmentCode) {

			layoutdata={
				//token:localStorage.getItem('token'),
				deptcode:currentDepartmentCode,
				defaulttab:defaulttab,
				israil:israil
			}
		}
		this.setState({ deptvalue: currentDepartmentCode});
		if(currentDepartmentCode == 'SNDG')
		{
			this.setState({
				sndshow:'show-m',
				departmentchrtshow:'show-n'
			});
		}
		else{
			this.setState({
				sndshow:'show-n',
				departmentchrtshow:'show-m'
			});
		}
    	
		let activeConsignmentsLayoutData = {
			//token:localStorage.getItem('token'),
			dept_code:currentDepartmentCode,
			defaulttab:defaulttab,
			israil:israil
		}

	     // Counters data for "Total Trucks" and "On Trip Trucks" under "Total Trucks"
        // redirectURL.post('/dashboard/gettrucks', activeConsignmentsLayoutData, {
        //     headers:{
        //         "Access-Control-Allow-Origin": "*",
        //         "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
        //     }
        // })
        // .then((response) => {
        //     let result = JSON.parse(JSON.stringify(response)).data;
            
        //     let uniqueActiveConsignments = (result.uniqueActiveConsignments) ? result.uniqueActiveConsignments : [];
        //     let uniqueActiveConsignmentsCount = (result.uniqueActiveConsignments) ? result.uniqueActiveConsignments.length : 0;
        //     let totalTrucks = []
        //     if(result.uniqueTrucks.length > 0)
        //     {
        //         result.uniqueTrucks.map((item) =>{
        //             if(item.actual_lspuser != "AXESTRACK_PRT")
        //             {
        //                 totalTrucks.push(item)
        //             }
        //         })
        //     }
        //     let totalTrucksCount = totalTrucks.length;
        //     // console.log("totalTrucks ", totalTrucks)
        //     this.setState({
        //         totalTrucks: totalTrucks,
        //         truckscnt: totalTrucksCount,
        //         uniqueActiveConsignments: uniqueActiveConsignments,
        //         totalActiveConsignmentsCount: uniqueActiveConsignmentsCount,
        //         //loadshow:'show-n'
        //     });
        //     this.onShowGridData();
        //     setTimeout(() => {
        //         // this.activeOntripsData();
        //     },2000)
		// 	})
		// 	.catch(function (error) {
		// 		console.log(error);
		// 	});


			/*Trucks with GPS Data*/
			// Counter for "Trucks with GPS Data"
			redirectURL.post('/dashboard/alltruckswithgpsdata', activeConsignmentsLayoutData, {
				headers:{
					"Access-Control-Allow-Origin": "*",
					"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
				}
			})
			.then((response) => {
				let result = JSON.parse(JSON.stringify(response)).data;
				// console.log("gettruckswithgpsdata, result = ", result);

				// let trucksWithGpsLocation = (result.trucksWithGpsLocation) ? result.trucksWithGpsLocation : [];
				// let trucksWithGpsLocationCount = (result.trucksWithGpsLocation) ? result.trucksWithGpsLocation.length : 0;
				let trucksWithGpsLocation = []
				if(result.trucksWithGpsLocation.length > 0)
				{
					result.trucksWithGpsLocation.map((item) => {
						if(item.actual_lspuser != "AXESTRACK_PRT")
						{
							trucksWithGpsLocation.push(item)
						}
					})
				}
				let trucksWithGpsLocationCount = trucksWithGpsLocation.length;

				let gpsDataNotReportedToday = trucksWithGpsLocation.filter(truck => {
					if (truck.timestamp) {
						let result = false;
						let consignmentDate = truck.timestamp;
                        let consignmentDateOnly = consignmentDate.toString().slice(0, 10);
                        // console.log("consignmentDateOnly ", consignmentDateOnly)
						let todayDateOnly = moment.parseZone().format("YYYY-MM-DD HH:mm:ss").toString().slice(0, 10);

							// console.log("consignmentDateOnly ", consignmentDateOnly)
							// console.log("todayDateOnly ", todayDateOnly)
						var s1 = moment.parseZone(new Date(consignmentDateOnly+" 00:00:00")).format("x")
						var s2 = moment.parseZone(new Date(todayDateOnly+" 23:59:59")).format("x")
                        // console.log("s1",s1)
                        // console.log("s2",s2)
                        result = true;
						return result;

					} else {
						//console.log("CONSIGNMENT WITHOUT TIMESTAMP = ", consignment);
					}
				});

				//console.log("gpsDataNotReportedToday")
				let gpsDataNotReportedTodayCount = (trucksWithGpsLocation) ? trucksWithGpsLocation.length : 0;
				let trucksWithGpsDataCount = (trucksWithGpsLocationCount && gpsDataNotReportedTodayCount) ? trucksWithGpsLocationCount - gpsDataNotReportedTodayCount : 0;

				this.setState({
					trucklocations: trucksWithGpsLocation.length,
					trucksWithGpsLocation:trucksWithGpsLocation,
					loadshow:'show-n'


				});
				this.onShowGridData();
				
			})
			.catch(function (error) {
				console.log(error);
			});
		
	}

	onShowGridData = async () =>{
		this.setState({
			rowwithoutData:''
		});
		try{
            // var totalTrucks = this.state.totalTrucks;
            // console.log("totalTrucks ", totalTrucks)
			// if(totalTrucks.length > 0)
			// {
                var cdate = moment.parseZone().format("x");
                var trucksWithGpsLocation = this.state.trucksWithGpsLocation;
                // console.log("cdate ",cdate);
                var result3 = []
                var t1to5days=[]
                var t6to10days=[]
                var t11to15days=[]
                var t16to20days=[]
                var t21to25days=[]
                var t26to30days=[]
                var t31to45days=[]
                var t45days=[]
				if(trucksWithGpsLocation.length > 0)
				{
					trucksWithGpsLocation.map((e)=>{
						// if(trucksWithGpsLocation.filter(k => k.truck_no == e.truck_no).length == 0)
						// {
                            var rdate = moment.parseZone(e.timestamp).format("x");
                            var diff = (cdate - rdate)/1000;
                            var seconds = Number(diff);
                            var d = Math.floor(seconds / (3600 * 24));
                            // var h = Math.floor((seconds % (3600 * 24)) / 3600);
                            // var m = Math.floor((seconds % 3600) / 60);
                            // var s = Math.floor(seconds % 60);
                            // console.log("days ",d)
                            if(d >= 1 && d <= 5)
                            {
                                t1to5days.push(e);
                                result3.push(e)
                            }
                            if(d >= 6 && d <= 10)
                            {
                                t6to10days.push(e);
                                result3.push(e)
                            }
                            if(d >= 11 && d <= 15)
                            {
                                t11to15days.push(e);
                                result3.push(e)
                            }
                            if(d >= 16 && d <= 20)
                            {
                                t16to20days.push(e);
                                result3.push(e)
                            }
                            if(d >= 21 && d <= 25)
                            {
                                t21to25days.push(e);
                                result3.push(e)
                            }
                            if(d >= 26 && d <= 30)
                            {
                                t26to30days.push(e);
                                result3.push(e)
                            }
                            if(d >= 31 && d <= 45)
                            {
                                t31to45days.push(e);
                                result3.push(e)
                            }
                            if(d >= 46)
                            {
                                t45days.push(e);
                                result3.push(e)
                            }
						// }
					})
				}
                console.log("result3 ", result3)
				this.setState({
                    rowwithoutData:result3,
                    originalData:result3,
                    t1to5days:t1to5days,
                    t6to10days:t6to10days,
                    t11to15days:t11to15days,
                    t16to20days:t16to20days,
                    t21to25days:t21to25days,
                    t26to30days:t26to30days,
                    t31to45days:t31to45days,
                    t45days:t45days
                });
			// }

		}
		catch(e){

		}
	
	}

	
	onGridReady = params => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		//this.gridApi.sizeColumnsToFit();
	}
	/*Truck Sidebar Component End*/
	onCloseUploadDiv = () => {
		this.setState({
			sliderTranslate:"",
			sliderTrucksTranslate:"",
			truckrowData: null,
			sidebartitle:"",
			overly:"show-n"
		});
	}
	
	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			sliderTranslate:"",
			sliderTrucksTranslate:"",
			truckrowData: null,
			sidebartitle:"",
			overly:"show-n"
		});

	}

	onClickShowTab = async(tab) =>{
		await this.setState({
			defaulttab:tab
		});
		await this.filterByDepartment()
	}
	
	onCloseUserManualModal = () => {
		this.setState({ usermanualmodal: false });
	};

  onClickShowUserManaul = () => {
		this.setState({ usermanualmodal: true });
	};

	onClickCounterShowData(params){
		this.setState({
			loadshow:"show-m"
		})
		//console.log("params ", params)
		setTimeout(() => {

			if(params == "0")
			{
				this.setState({
					rowwithoutData:this.state.originalData,
					loadshow:"show-n"
					// overly:"show-m",
					// sliderTrucksTranslate:"slider-translate-60p",
					// sidebartitle:"AXESTRACK Inactive Trucks"
				})
			}
			if(params == "1")
			{
				this.setState({
					rowwithoutData:this.state.t1to5days,
					loadshow:"show-n"
				})

			}
			if(params == "2")
			{
				this.setState({
					rowwithoutData:this.state.t6to10days,
					loadshow:"show-n"
				})

			}
			if(params == "3")
			{
				this.setState({
					rowwithoutData:this.state.t11to15days,
					loadshow:"show-n"
				})

			}
			if(params == "4")
			{
				this.setState({
					rowwithoutData:this.state.t16to20days,
					loadshow:"show-n"
				})

			}
			if(params == "5")
			{
				this.setState({
					rowwithoutData:this.state.t21to25days,
					loadshow:"show-n"
				})

			}
			if(params == "6")
			{
				this.setState({
					rowwithoutData:this.state.t26to30days,
					loadshow:"show-n"
				})

			}
			if(params == "7")
			{
				this.setState({
					rowwithoutData:this.state.t31to45days,
					loadshow:"show-n"
				})

			}
			if(params == "8")
			{
				this.setState({
					rowwithoutData:this.state.t45days,
					loadshow:"show-n"
				})

			}
		},1000)
		
	}
    render(){
		const statedeptcode = this.state.deptvalue;
		//console.log("statedeptcode ",statedeptcode)
		var columnwithDefs= [
			{
                headerName: "Truck No",
                field: "truck_no",
                width: 120,
  
            },
              {
                headerName: "LSP Code",
                field: "transporter_code",
                width: 120,
  
            },
              {
              headerName: "LSP Name",
              field: "transporter_name",
              width: 200,
  
            },
            
            {
              headerName: "GPS Provider",
              field: "actual_lspuser",
              width: 120,
  
            },
            {
                headerName: "Address",
                field: "last_known_address",
                width: 140,
				// bug id: 2872
				
            },
            // {
            //     headerName: "City",
            //     field: "area",
            //     width: 140,
            // },
            // {
            //   headerName: "State",
            //   field: "state",
            //   width: 140,
            // },
            {
                headerName: "Last Packet Time",
                field: "timestamp",
                width: 160,
                //cellRenderer:'dateFormater'
                valueGetter:function(params){
                return getHyphenDDMMMYYYYHHMM(params.data.timestamp)
                },
              
            },
            {
                headerName: "Data Received On",
                field: "modified_date",
                width: 160,
                //cellRenderer:'dateFormater'
                valueGetter:function(params){
                    return getHyphenDDMMMYYYYHHMM(params.data.modified_date)
                },
               
            }

        ];
		
    	let order_cnt=[];

    	rendJsonData(this.state.loadsData);

        //console.log("Default",localStorage.getItem('token'));
        const dStyles={
				width:'100%',
				height:'100%'
		}
		
		const {usermanualmodal} = this.state;
		const modalStyles  = {
		width:'1300px !important',
		}

        return (
            <div >
                {/*Container-fluid starts*/}
                <div className="container-fluid">
				
                	
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                            <div className="card">
                                <div className="card-body" id="dashboardCounterCardBody">
                                    <div className="crm-numbers pb-0">
                                        <div className="row">
                                            
                                            <div className="col cirlce-d " onClick={this.onClickCounterShowData.bind(this,0)} style={{cursor:"pointer"}}>
                                                <span className="f13">
                                                    <i className="icofont icofont-truck cus-i txt-info f24"></i>
                                                    <br /> Total Trucks
                                                </span>
                                                <h4 className="txt-info f50">
                                                    <span className="counter">
                                                        <CountUp end={this.state.originalData.length}/>
                                                    </span>
                                                </h4>
                                                {/* <div className=" f20">
                                                    <div className="counter txt-success-medium">
                                                    On Trip Trucks: &nbsp;
                                                        <CountUp end={(this.state.totalActiveConsignmentsCount != '')?this.state.totalActiveConsignmentsCount:0}/>
                                                    </div>
                                                </div> */}
                                            </div>
                                            <div className="col cirlce-d " onClick={this.onClickCounterShowData.bind(this,1)} style={{cursor:"pointer"}} >
                                                <span className="f13">
                                                    <i className="icofont icofont-truck cus-i txt-success f24"></i>
                                                    <br /> 1-5 Days
                                                </span>
                                                <h4 className="txt-success f50">
                                                    <span className="counter">
                                                        <CountUp end={this.state.t1to5days.length}/>
                                                    </span>
                                                </h4>
                                                {/* <div className=" f20">
                                                    <div className="counter txt-success-medium">
                                                    On Trip Trucks: &nbsp;
                                                        <CountUp end={(this.state.totalActiveConsignmentsCount != '')?this.state.totalActiveConsignmentsCount:0}/>
                                                    </div>
                                                </div> */}
                                            </div>
                                            
                                            <div className="col cirlce-d "  onClick={this.onClickCounterShowData.bind(this,2)} style={{cursor:"pointer"}}>
                                                <span className="f13">
                                                    <i className="icofont icofont-truck cus-i txt-danger f24"></i>
                                                    <br /> 6-10 Days
                                                </span>
                                                <h4 className="txt-danger f50">
                                                    <span className="counter">
                                                        <CountUp end={this.state.t6to10days.length}/>
                                                    </span>
                                                </h4>
                                                {/* <div className=" f20">
                                                    <div className="counter txt-success-medium">
                                                    On Trip Trucks: &nbsp;
                                                        <CountUp end={(this.state.totalActiveConsignmentsCount != '')?this.state.totalActiveConsignmentsCount:0}/>
                                                    </div>
                                                </div> */}
                                            </div>
                                            
                                            <div className="col cirlce-d "  onClick={this.onClickCounterShowData.bind(this,3)} style={{cursor:"pointer"}}>
                                                <span className="f13">
                                                    <i className="icofont icofont-truck cus-i txt-primary f24"></i>
                                                    <br /> 10-15 Days
                                                </span>
                                                <h4 className="txt-primary f50">
                                                    <span className="counter">
                                                        <CountUp end={this.state.t11to15days.length}/>
                                                    </span>
                                                </h4>
                                                {/* <div className=" f20">
                                                    <div className="counter txt-success-medium">
                                                    On Trip Trucks: &nbsp;
                                                        <CountUp end={(this.state.totalActiveConsignmentsCount != '')?this.state.totalActiveConsignmentsCount:0}/>
                                                    </div>
                                                </div> */}
                                            </div>
                                            
                                            <div className="col cirlce-d " onClick={this.onClickCounterShowData.bind(this,4)} style={{cursor:"pointer"}} >
                                                <span className="f13">
                                                    <i className="icofont icofont-truck cus-i txt-warning f24"></i>
                                                    <br /> 16-20 Days
                                                </span>
                                                <h4 className="txt-warning f50">
                                                    <span className="counter">
                                                        <CountUp end={this.state.t16to20days.length}/>
                                                    </span>
                                                </h4>
                                                {/* <div className=" f20">
                                                    <div className="counter txt-success-medium">
                                                    On Trip Trucks: &nbsp;
                                                        <CountUp end={(this.state.totalActiveConsignmentsCount != '')?this.state.totalActiveConsignmentsCount:0}/>
                                                    </div>
                                                </div> */}
                                            </div>


                                            <div className="col cirlce-d " onClick={this.onClickCounterShowData.bind(this,5)} style={{cursor:"pointer"}} >
                                                <span className="f13">
                                                    <i className="icofont icofont-truck cus-i txt-info f24"></i>
                                                    <br /> 21-25 Days
                                                </span>
                                                <h4 className="txt-info f50">
                                                    <span className="counter">
                                                        <CountUp end={this.state.t21to25days.length}/>
                                                    </span>
                                                </h4>
                                                {/* <div className=" f20">
                                                    <div className="counter txt-success-medium">
                                                    On Trip Trucks: &nbsp;
                                                        <CountUp end={(this.state.totalActiveConsignmentsCount != '')?this.state.totalActiveConsignmentsCount:0}/>
                                                    </div>
                                                </div> */}
                                            </div>
                                            
                                            <div className="col cirlce-d " onClick={this.onClickCounterShowData.bind(this,6)} style={{cursor:"pointer"}} >
                                                <span className="f13">
                                                    <i className="icofont icofont-truck cus-i txt-success f24"></i>
                                                    <br /> 26-30 Days
                                                </span>
                                                <h4 className="txt-success f50">
                                                    <span className="counter">
                                                        <CountUp end={this.state.t26to30days.length}/>
                                                    </span>
                                                </h4>
                                                {/* <div className=" f20">
                                                    <div className="counter txt-success-medium">
                                                    On Trip Trucks: &nbsp;
                                                        <CountUp end={(this.state.totalActiveConsignmentsCount != '')?this.state.totalActiveConsignmentsCount:0}/>
                                                    </div>
                                                </div> */}
                                            </div>

                                            <div className="col cirlce-d " onClick={this.onClickCounterShowData.bind(this,7)} style={{cursor:"pointer"}} >
                                                <span className="f13">
                                                    <i className="icofont icofont-truck cus-i txt-secondary f24"></i>
                                                    <br /> 31-45 Days
                                                </span>
                                                <h4 className="txt-secondary f50">
                                                    <span className="counter">
                                                        <CountUp end={this.state.t31to45days.length}/>
                                                    </span>
                                                </h4>
                                                {/* <div className=" f20">
                                                    <div className="counter txt-success-medium">
                                                    On Trip Trucks: &nbsp;
                                                        <CountUp end={(this.state.totalActiveConsignmentsCount != '')?this.state.totalActiveConsignmentsCount:0}/>
                                                    </div>
                                                </div> */}
                                            </div>
                                            
                                            <div className="col cirlce-d " onClick={this.onClickCounterShowData.bind(this,8)} style={{cursor:"pointer"}} >
                                                <span className="f13">
                                                    <i className="icofont icofont-truck cus-i txt-danger f24"></i>
                                                    <br /> &gt; 45 Days
                                                </span>
                                                <h4 className="txt-danger f50">
                                                    <span className="counter">
                                                        <CountUp end={this.state.t45days.length}/>
                                                    </span>
                                                </h4>
                                                {/* <div className=" f20">
                                                    <div className="counter txt-success-medium">
                                                    On Trip Trucks: &nbsp;
                                                        <CountUp end={(this.state.totalActiveConsignmentsCount != '')?this.state.totalActiveConsignmentsCount:0}/>
                                                    </div>
                                                </div> */}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                  
						<div className="col-xl-12 col-lg-12">
	                        <div className="card height-equal equal-height-lg">
                                <div className="card-header">
                                    <h5>
                                        <span><i className="icofont icofont-vehicle-delivery-van cus-i"></i>GPS Not Received Data  </span>
                                    </h5>
                                </div>
	                            <div className="card-body" style={{padding:'10px'}}>
	                                <div id="myGrid" style={{ height: "600px",width: "100%"}}  className="ag-theme-balham">
	    		          		          <AgGridReact
											context={this}
											// onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
	    		          		            modules={this.state.modules}
	    		          		            columnDefs={columnwithDefs}
	    		          		            defaultColDef={this.state.defaultColDef}
	    		          		            rowData={this.state.rowwithoutData}
	    		          		            enableCharts={false}
	    		          		            enableRangeSelection={true}
	    		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
	    		          		            frameworkComponents={this.state.frameworkComponents}
											onGridReady={this.onGridReady}
	    		          		            onGridState={this.onGridState}
											  statusBar={this.state.statusBar}
											  //sideBar={this.state.sideBar}
											  pagination={false}
	    		          		          />
	    		          		        </div>
	                            </div>

	                        </div>

	                    </div>

						
                    </div>

					<div className={"slide-r "+(this.state.sliderTranslate)} >

					 	{this.displayData}
				 	</div>


                </div>
                {/*Container-fluid Ends*/}

				
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>


				<div className={"slide-r "+(this.state.sliderTrucksTranslate)} >
					<div className="slide-r-title">
					<h4>
						{this.state.sidebartitle}
						<span
						className="float-right closebtn"
						style={{ marginRight: "25px" }}
						onClick={this.onCloseUploadDiv.bind(this)}
						>
						X
						</span>
					</h4>
					</div>
					
                    <div className="slide-r-body" style={{position:"relative"}}>
						<div id="myGrid6" style={{ height: "600px",width: "100%"}}  className="ag-theme-balham">
							<AgGridReact
							context={this}
							// onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
							modules={this.state.modules}
							columnDefs={columnwithDefs}
							defaultColDef={this.state.defaultColDef}
							rowData={this.state.rowData}
							enableCharts={false}
							enableRangeSelection={true}
							autoGroupColumnDef={this.state.autoGroupColumnDef}
							// frameworkComponents={this.state.frameworkComponents}
							onGridReady={this.onGridReady}
							onGridState={this.onGridState}
								statusBar={this.state.statusBar}
								//sideBar={this.state.sideBar}
								pagination={true}
							/>
						</div>
					</div>
				</div>

				<div className={"dataLoadpage " +(this.state.loadshow)}>
						</div>
						<div className={"dataLoadpageimg " +(this.state.loadshow)}>
							<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
						</div>

            </div>
        );
    }
}
	function rendJsonData(data)
	{
		
	}

	function dateComparator(date1, date2) {
		// console.log("dateComparator1");
		var date1Number = monthToComparableNumber(date1);
		var date2Number = monthToComparableNumber(date2);
		if (date1Number === null && date2Number === null) {
		  return 0;
		}
		if (date1Number === null) {
		  return -1;
		}
		if (date2Number === null) {
		  return 1;
		}
		return date1Number - date2Number;
	  }
	function monthToComparableNumber(date) {
		if (date === undefined || date === null || date.length !== 19) {
			return null;
		}
		var yearNumber = date.substring(6, 10);
		var monthNumber = date.substring(3, 5);
		var dayNumber = date.substring(0, 2);
		var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
		return result;
	}



export default GPSNRD;
