import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';

import Map from '../common/drawmap';

import redirectURL from '../redirectURL';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import MapComponent from './mapLocationComponent';
import Consignmentforceclose from '../manage/consignmentforceclose';
import $ from 'jquery';
// import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';

var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");


export default class TransshipmentTransporterComponent extends Component{
    constructor(props) {
        super(props);
        this.state={
            modules: AllModules,
            // truckData:this.props.truckInfo,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            
            truckNumList:"",
            loadshow:'show-n',
            truckLocation:"",
            replaced_date_time:"",
            route_details:"",
            rowData:"",
            modules:AllModules,
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:50,
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
            route_info:"",
			rowData: null,
			animateRows: true,
            consignment_details:"",
            dept_code:"",
            replacement_truck:"",
            pagetitle:"",
            showonlytransport:'show-m',
            reason:'',
            oldtruck:'',
        };
        this.cipher = this.cipher.bind(this);
    }
    cipher = (salt) => {
        const textToChars = text => text.split('').map(c => c.charCodeAt(0));
        const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
        const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
        try{
            return text => text.split('')
                .map(textToChars)
                .map(applySaltToChar)
                .map(byteHex)
                .join('');
        }
        catch(e){
    
        }
        
    }
        
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount(){
        loadDateTimeScript();
        var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
        $('#replacement-submit').prop("disabled",true);
        let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        var pagetitle='';
        if(this.props.match.path == "/prttranshipments"){
            var dept_code = "LOG-PRT";
            pagetitle="Transshipments (Spare Parts) "
        }
        if(this.props.match.path == "/sndtranshipments"){
            var dept_code = "SNDG"
            pagetitle="Transshipments (Sales and Dispatch) "
        }
        if(this.props.match.path == "/tnptranshipments"){
            var dept_code = "LOG-TNP"
            pagetitle="Transshipments (Train and Production) "
        }
        if(this.props.match.path == "/prttransshipments"){
            var dept_code = "LOG-PRT";
            pagetitle="Transshipments (Spare Parts) "
        }
        if(this.props.match.path == "/sndtransshipments"){
            var dept_code = "SNDG"
            pagetitle="Transshipments (Sales and Dispatch) "
        }
        if(this.props.match.path == "/tnptransshipments"){
            var dept_code = "LOG-TNP"
            pagetitle="Transshipments (Train and Production) "
        }
        this.setState({
            loadshow:'show-m',
            eventAction:eventAction,
            dept_code:dept_code,
            pagetitle:pagetitle
        });
        var params = {
            dept_code:encode(dept_code),
        }
        var userrols = localStorage.getItem("roles");
			var splitrole = userrols.split(",")
			//console.log("splitrole ",splitrole)
			if(splitrole.length > 0)
			{
				if(splitrole.includes("tpt_sndg") == true || splitrole.includes("tpt_prt") == true)
				{
                    var urlpath = '/consignments/transportertrucklists';
                    params.israil=0
                    params.transporter_code = localStorage.getItem("transportercode")
                    this.setState({
                        showonlytransport:'show-n'
                    })
                }
                else{
                    var urlpath = '/consignments/trucklists';
                }
            }
            else{
                var urlpath = '/consignments/trucklists';
            }
			
			
        // console.log(dept_code);
        redirectURL.post(urlpath, params)
		  .then((response) => {
            var consignments = response.data.consignments;
            // console.log(consignments);
            var truckNumList = [];
            consignments.map(function(param){
                    truckNumList.push(param.truck_no);
            });
            truckNumList = truckNumList.filter((item, i, ar) => ar.indexOf(item) === i);
            this.setState({
                truckNumList:truckNumList,
                rowData:[],
            });
			
		})
		.catch(function (error) {
		    console.log(error);
		});

    }

    // componentWillReceiveProps(newProps){
    //     if(newProps.rowData != this.state.rowData){
    //         this.setState({
    //             rowData : newProps.rowData,
    //         })
    //     }
    // }

    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
		   
	}
    selectTruckNum(){
        let arr=[];
        try{
            this.state.truckNumList.forEach(item =>{
            //console.log("pod ",item)
            arr.push({value:item,label:item}); 
        });
        return arr;
        }
        catch(e){

        }
        
    }
    getTruckConsignments(params){
        console.log("params",params)
        this.setState({
            rowData:[],
            oldtruck:params.value
        })
        try{
            if(params != null){
                console.log("@ss")
                
                var trucks=[];
                
                trucks.push(params.value)
                
                var reqParams = {
                    truck_no:trucks
                }
                console.log("Trucks ",reqParams) 
                redirectURL.post("/consignments/getTruckConsignments",reqParams)
                .then((response) =>{
                    console.log(response.data);
                    this.setState({
                        rowData:response.data.consignments
                    })
                }).catch(function (error){
                    console.log(error);
                });
                // loadDateTimeScript();
                $('#replacement-submit').prop("disabled",false);
            }
        }
        catch(e){

        }
    }
    changeHandler = (event) => {
        //console.log("Select", event.target.value);
        if(event.target.name != 'replaced_date_time')
        {
            let name = event.target.name;
            let value = event.target.value;
            this.setState({[name]:value});
        }
    	
    }
    onRowSelection(event){
		var containerslist=[];
		//console.log("rowselect", event)
		var rwCount = event.api.getSelectedNodes();
		console.log("rwCount ", rwCount)
		//if(event.node.selected == true)
		//{
			//var containerlist=[];
			console.log("rowselect", event.data)
			var rowCount =  event.data
			//console.log("rowCount ", rowCount)
			
			rwCount.map((item) => {
				containerslist.push(item.data.truck_no)
			});
			
			this.setState({
				containerslist:containerslist
			})
			 console.log("checkedlist ", containerslist)
		// }
		// else{
		// 	if(containerslist.length > 0)
		// 	{
		// 		containerslist=[]
		// 		this.setState({
		// 			containerslist:containerslist
		// 		})
		// 	}
		// }
		
	}
    saveReplacedData(event){
        event.preventDefault()
        var rwCount = this.gridApi.getSelectedNodes();
        //console.log("rwCount ",rwCount)
        var truckData=[]
        if(rwCount.length > 0)
        {
            rwCount.map((item)=>{
                truckData.push(item.data);
            });
        }
        var replacement_truck = this.state.replacement_truck;
        var replaced_date = document.getElementById("replaced_Date").value;
       //console.log("replaced_date ", replaced_date)
        if(this.state.reason.replace(/\s/g,"").length > 0)
        {
            if(replaced_date == "" || replaced_date == null || replaced_date == undefined || replaced_date == '__-__-____ __:__')
            {
                this.setState({
                    show:true,
                    basicTitle:"Replacement Date is Not given",
                    basicType:"warning",
                });
            }
            else
            {
                if(truckData.lenght == 0)
                {
                    this.setState({
                        show:true,
                        basicTitle:"Please select atleast one checkbox item from grid.",
                        basicType:"warning",
                    });
                }
                else
                {
                    //console.log("replaced_date ", replaced_date)
                    var rdt=replaced_date.split(" ");
                    var rd = rdt[0].split("-");
                    var rdate = rd[2]+"-"+rd[1]+"-"+rd[0]+" "+rdt[1];
                    // console.log(truckData);
                    // console.log(replacement_truck);
                    // console.log(replaced_date);
                    // console.log(this.state.dept_code);
                    if(this.state.showonlytransport == 'show-n')
                    {
                        var usetrucks = 'y'
                    }
                    else{
                        
                        var usetrucks = 'n'
                    }
                    
                    var reqParams = {
                        truckData : truckData,
                        replacement_truck : replacement_truck,
                        replaced_date : rdate,
                        dept_code : this.state.dept_code,
                        usetrucks:usetrucks,
                        reason:this.state.reason,
                        user_email:localStorage.getItem("email"),
                        old_truck_no : this.state.oldtruck,
                        transhipment_done_by:localStorage.getItem("email")
                    }
                    //console.log("reqParams " , reqParams)
                    redirectURL.post("/consignments/insertTranshipmentReplacedTruckData",reqParams)
                    .then((response)=>{
                        console.log(response.data);
                        if(response.data.statusMessage == "success"){
                            this.setState({
                                show:true,
                                basicTitle:"Replacement Success.",
                                basicType:"success",
                            });
                            
                            window.location.reload();
                        }
                    }).catch(function (error){
                        console.log(error);
                    });
                }
            }
          
        }
        else
        {
            this.setState({
                show:true,
                basicTitle:"Reason should not be empty",
                basicType:"danger",
            });
        }

    }
    closeAlert = () => {
        this.setState({
            show: false
        });
	}
    render(){
        const columnwithDefs = [
            {
                headerName: "Consignment Code",
                field: "consignment_code",
                pinned: 'left',
                filter: false,
                resizable: true,
                headerCheckboxSelection: false,
                //headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true
            },
            {
                headerName: "Consignee Code",
                width:140,
                field: "consignee_code",
                pinned: 'left',
                filter: false,
                resizable: true,
            },
            {
                headerName: "Consignee Name",
                field: "consignee_name",
                pinned: 'left',
                filter: false,
                resizable: true,
                valueGetter:function(params){
                    if(params.data.consignee_name == ""){
                        return "N/A";
                    }
                    else{
                        return params.data.consignee_name;
                    }
                }
            },
            {
                headerName: "Consignee City",
                field: "consignee_city",
                width:140,
                pinned: 'left',
                filter: false,
                resizable: true,
                valueGetter:function(params){
                    if(params.data.consignee_city == ""){
                        return "N/A";
                    }
                    else{
                        return params.data.consignee_city
                    }
                }
            },
            {
                headerName: "Gateout Time",
                field: "gate_out_time",
                pinned: 'left',
                filter: false,
                resizable: true,
                valueGetter:function(params){
                    if(params.data.gate_out_time != '' && params.data.gate_out_time != undefined)
                    {
                        return getDDMMYYYYHHMMSS(params.data.gate_out_time);
                    }
                    else{
                        return "NA";
                    }
              },

            },
            {
                headerName: "Invoice Time",
                field: "invoice_time",
                pinned: 'left',
                filter: false,
                resizable: true,
                valueGetter:function(params){
                    if(params.data.invoice_time != '' && params.data.invoice_time != undefined)
                    {
                        return getDDMMYYYYHHMMSS(params.data.invoice_time);
                    }
                    else{
                        return "NA";
                    }
              },
            },

        ]

        return(
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row col-xl-12 col-lg-12">
                        
                    <h5>
                        <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle} </span>
                        
                    </h5>
                    <form method="POST" className="form-theme col-xl-12 col-lg-12 row">
                        <div className="form-group col-xl-3 col-lg-3">
                            <label>Select Truck No: </label>
                            <Select 
                            placeholder={"Select Truck Number"}
                            closeMenuOnSelect={true}
                            onChange={this.getTruckConsignments.bind(this)} 
                            className={"border-radius-0"}
                            style={{borderRadius:"0px"}}
                            options={this.selectTruckNum()} />            
                        </div>                   
                    </form>
                </div> 
                
                <div className="col-xl-12 col-lg-12">
                {/* {this.state.rowData != null ? */}
                    <div id="myGrid" style={{width:"82%",height:"360px"}} className="ag-theme-balham">    <AgGridReact
                        modules={this.state.modules}
                        columnDefs={columnwithDefs}
                        defaultColDef={this.state.defaultColDef}
                        rowData={this.state.rowData}
                        // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                        enableCharts={true}
                        enableRangeSelection={true}
                        onGridReady={this.onGridReady}
                        onGridState={this.onGridState}
                        statusBar={this.state.statusBar}
                        sideBar={this.state.sideBar}
                        paginationPageSize={this.state.paginationPageSize}
                        pagination={true}
                        rowSelection={this.state.rowSelection}
                        // frameworkComponents={this.state.frameworkComponents}
                        // detailCellRendererParams={this.state.detailCellRendererParams}
                        //editType={this.state.editType}
                        stopEditingWhenGridLosesFocus={true}
                        //floatingFilter={true}
                        enableCellChangeFlash={true}
                        suppressCellFlash={true}
                        // rowClassRules={this.state.rowClassRules}
                        //onCellClicked={this.onRowClicked.bind(this)}
                        gridOptions={{
                            context: { componentParent: this }
                        }}
                        onRowSelected={this.onRowSelection.bind(this)}
                        suppressRowClickSelection={true}
                        />

                    </div>

                    <div className="row col-xl-12 col-lg-12 ml-0p n-p-0 mt-20p Transhipment-Edit-Row">
                        <form method="POST" className="row theme-form col-xl-12 col-lg-12" onSubmit={this.saveReplacedData.bind(this)}>
                            
                            <div className="col-xl-3 col-lg-3 form-group">
                                <label className="">Replacement Truck</label>
                                <input type="text" class="form-control" name="replacement_truck" id="replacement_truck" onChange={this.changeHandler.bind(this)} autoComplete="off" Placeholder="Truck No." required />
                            </div>

                            <div className={"col-xl-3 col-lg-3 form-group"}>
                                <label className="reason">Reason</label>
                                <input type="text" name="reason" value={this.state.reason} autoComplete="off" onChange={this.changeHandler.bind(this)} class="form-control" id="reason" />
                            </div>
                            <div className={"col-xl-3 col-lg-3 form-group "}>
                                <label className="replacement-time">Replacement Time</label>
                                {/* <Datetime value={this.state.replaced_date_time} 
                                    disableCloseOnClickOutside={false} 
                                    closeOnSelect={true} 
                                    className="transhipment-datetime"
                                    inputProps={{ placeholder: 'Replaced Date', name: 'replaced_Date_Time', autoComplete:'off' }}
                                    dateFormat="YYYY-MM-DD" 
                                    name="replaced_Date_Time" 
                                    onChange={this.handlerReplacedDate.bind(this)}
                                        /> */}
                                <input type="text"  name="replaced_date_time" autoComplete="off" class="form-control transhipment-datetime datetimepicker_mask" id="replaced_Date" />
                            </div>
                            <div className="col-xl-2 col-lg-2 form-group">
                                <label className="replacement-time"><br /></label>
                                <button type="submit" className="btn btn-warning mt-30p" id="replacement-submit">Submit</button>
                            </div>
                           
                            
                        </form>
                    </div>
                    
                </div>
                
                
            </div>
        )
    }
}

        
function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
	index.parentNode.insertBefore(script,index)
}