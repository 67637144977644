import React, { Component } from 'react';
import CountUp from 'react-countup';
import Select from "react-select";
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getHyphenDDMMMYYYYHHMM, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';

var redirectURL = require('../redirectURL');
var columnDefsforEmptyTrucks = [
    {
        headerName: "Truck No",
        field: "truck_no",
        width: 140,
    },
    {
        headerName: "LSP Code",
        field: "transporter_code",
        width: 200,
    },
    {
        headerName: "LSP Name",
        field: "transporter_name",
        width: 200,
    },

    {
        headerName: "Actual GPS Provider",
        field: "actual_lspuser",
        width: 200,
    },
    {
        headerName: "Plant Code",
        field: "plant_code",
        width: 120
    },
    {
        headerName: "Distance from Location (KM)",
        field: "distance_from_coordinates",
        width: 120,
    },
    {
        headerName: "Last Known City/Town",
        field: "area",
        width: 150,
    },
    {
        headerName: "Last Known State",
        field: "state",
        width: 150,
    },
    {
        headerName: "Last Packet Time",
        field: "timestamp",
        width: 150,
        valueGetter: function (params) {
            return getHyphenDDMMMYYYYHHMM(params.data.timestamp)
        }

    },
    {
        headerName: "Distance Range",
        field: "distance_range",
        width: 150,

    },
    {
        headerName: "Stoppage Duration",
        field: "elaped_time_from_last_moved_seconds",
        width: 150,
        valueGetter: param => {
            if (param.data.elaped_time_from_last_moved_seconds !== '' && param.data.elaped_time_from_last_moved_seconds !== undefined) {
                return secondsToHM(param.data.elaped_time_from_last_moved_seconds)
            }
        }
    },
    {
        headerName: "Distance From OEM",
        field: "distance_from_oem",
        width: 150,
        cellRenderer: function (params) {

            var htmloption = '<button class="btn btn-success">View</button>';
            return htmloption;
        }
    },

]

var columnDefsForAtdealerTrucks = [
    {
        headerName: "Truck No",
        field: "truck_no",
        width: 140,
    },

    {
        headerName: "Invoice No",
        field: "invoice_no",
        width: 140,
    },
    {
        headerName: "Invoice Time",
        field: "invoice_time",
        width: 150,
        // hide: showonlysnd,
        valueGetter: function (params) {
            return getHyphenDDMMMYYYYHHMM(params.data.invoice_time)
        },
    },
    {
        headerName: "Near Dealership At",
        field: "first_25_km_entry",
        width: 150,
        // hide: showonlysnd,
        valueGetter: function (params) {
            return getHyphenDDMMMYYYYHHMM(params.data.first_25_km_entry)
        },
    },
    {
        headerName: "Reached Destination At",
        field: "recent_dealer_reported",
        width: 150,
        // hide: showonlysnd,
        valueGetter: function (params) {
            return getHyphenDDMMMYYYYHHMM(params.data.recent_dealer_reported)
        },
    },
    {
        headerName: "Last Packet Time",
        field: "timestamp",
        width: 150,
        // hide: showonlysnd,
        valueGetter: function (params) {
            return getHyphenDDMMMYYYYHHMM(params.data.timestamp)
        },
    },
    {
        headerName: "Last Location",
        field: "area",
        width: 150,

    },
    {
        headerName: "Dist. From Destination (km)",
        field: "distance_from_dealer_location",
        width: 150,

    },
    {
        headerName: "Distance from Plant",
        field: "dealer_distance_from_plant",
        width: 150,

    },

]
class vehicleAvailabilityAtPlantComponent extends Component {
    constructor(props) {
        super(props);
    }
    state = {
        modules: AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            resizable: true

        },
        statusBar: {
            statusPanels: [
                {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left",
                },
                {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center",
                },
                { statusPanel: "agFilteredRowCountComponent" },
                { statusPanel: "agSelectedRowCountComponent" },
                { statusPanel: "agAggregationComponent" },
            ],
        },
        sideBar: {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel",
                },
            ],
        },
        counter_title: "Empty - Today Trucks",
        plant_codes: [{ label: "ALL", value: "ALL" }],
        plant_codes_optins: [{ label: "ALL", value: "ALL" }, { label: "Gurgaon", value: "HHHG" }, { label: "Dharuhera", value: "HHHD" }, { label: "Haridwar", value: "HHHU" }, { label: "Neemrana", value: "HM4N" }, { label: "Halol", value: "HM5V" }, { label: "Chittoor", value: "HM6C" },],
        rowData: [],
        loadshow: "show-n",
        overly: 'show-n',
        columnDefs: [],
        otheroemColDefs: [
            {
                headerName: "Truck No",
                field: "truck_no",
                width: 150,

            },
            {
                headerName: "Geofence Name",
                field: "geofence_location_name",
                width: 150,

            },
            {
                headerName: "Distance From OEM (km)",
                field: "distance",
                width: 150,

            },
            {
                headerName: "Reported at OEM",
                field: "",
                width: 150,
                valueGetter: function (params) {
                    if (params.data.distance <= 1) {
                        return "YES"
                    }
                    else {
                        return "NO"
                    }
                }

            },
        ],
        sliderForOEMData: ""

    }

    componentDidMount() {
        var plantcode = JSON.stringify(['HHHG', 'HHHD', 'HHHU', 'HM4N', 'HM5V', 'HM6C'])
        this.LoadData(plantcode)
    }

    LoadData = (plantcode) => {
        this.setState({
            loadshow: "show-m",
            overly: 'show-m'
        })
        redirectURL.post("dashboard/vehicleAvailabilityAtPlant", { plantcode: plantcode }).then(response => {

            try {
                var empty_trucks_today_count = response.data.empty_trucks_today_count || 0
                var empty_trucks_today_data = !["", null, undefined, "undefined"].includes(response.data.empty_trucks_today_data) ? JSON.parse(response.data.empty_trucks_today_data) : []

                var empty_trucks_tomorrow_count = response.data.empty_trucks_tomorrow_count || 0
                var empty_trucks_tomorrow_data = !["", null, undefined, "undefined"].includes(response.data.empty_trucks_tomorrow_data) ? JSON.parse(response.data.empty_trucks_tomorrow_data) : []

                var empty_trucks_day_after_tomorrow_count = response.data.empty_trucks_day_after_tomorrow_count || 0
                var empty_trucks_day_after_tomorrow_data = !["", null, undefined, "undefined"].includes(response.data.empty_trucks_day_after_tomorrow_data) ? JSON.parse(response.data.empty_trucks_day_after_tomorrow_data) : []

                var atdealer_trucks_today_count = response.data.atdealer_trucks_today_count || 0
                var atdealer_trucks_today_data = !["", null, undefined, "undefined"].includes(response.data.atdealer_trucks_today_data) ? JSON.parse(response.data.atdealer_trucks_today_data) : []

                var atdealer_trucks_tomorrow_count = response.data.atdealer_trucks_tomorrow_count || 0
                var atdealer_trucks_tomorrow_data = !["", null, undefined, "undefined"].includes(response.data.atdealer_trucks_tomorrow_data) ? JSON.parse(response.data.atdealer_trucks_tomorrow_data) : []

                var atdealer_day_after_tomorrow_count = response.data.atdealer_day_after_tomorrow_count || 0
                var atdealer_day_after_tomorrow_data = !["", null, undefined, "undefined"].includes(response.data.atdealer_day_after_tomorrow_data) ? JSON.parse(response.data.atdealer_day_after_tomorrow_data) : []

                var atdealer_today_trucks_cons_data = !["", null, undefined, "undefined"].includes(response.data.atdealer_today_trucks_cons_data) ? JSON.parse(response.data.atdealer_today_trucks_cons_data) : []
                var atdealer_tomorrow_trucks_cons_data = !["", null, undefined, "undefined"].includes(response.data.atdealer_tomorrow_trucks_cons_data) ? JSON.parse(response.data.atdealer_tomorrow_trucks_cons_data) : []
                var atdealer_dayafterTomorrow_trucks_cons_data = !["", null, undefined, "undefined"].includes(response.data.atdealer_dayafterTomorrow_trucks_cons_data) ? JSON.parse(response.data.atdealer_dayafterTomorrow_trucks_cons_data) : []

                // atdealer_trucks_today_data.map(e => {
                //     var filterDatacons = atdealer_today_trucks_cons_data.filter(f => e.truck_no == f.truck_no)
                //     var sortedfilteredcons = filterDatacons.sort(GetSortOrder("recent_dealer_reported"))
                // })


                var distinct_today_trucks = []
                var filted_atdealer_trucks_today_data = []
                atdealer_trucks_today_data.map(e => {
                    atdealer_today_trucks_cons_data.map(f => {
                        if (e.truck_no == f.truck_no && !distinct_today_trucks.includes(e.truck_no)) {
                            e.invoice_no = f.invoice_no
                            e.first_25_km_entry = f.first_25_km_entry
                            e.recent_dealer_reported = f.recent_dealer_reported
                            e.distance_from_dealer_location = f.distance_from_dealer_location
                            e.dealer_distance_from_plant = f.dealer_distance_from_plant
                            filted_atdealer_trucks_today_data.push(e)
                            distinct_today_trucks.push(e.truck_no)
                        }
                    })
                })

                var distinct_tomorrow_trucks = []
                var filted_atdealer_trucks_tomorrow_data = []
                atdealer_trucks_tomorrow_data.map(e => {
                    atdealer_tomorrow_trucks_cons_data.map(f => {
                        if (e.truck_no == f.truck_no && !distinct_tomorrow_trucks.includes(e.truck_no)) {
                            e.invoice_no = f.invoice_no
                            e.first_25_km_entry = f.first_25_km_entry
                            e.recent_dealer_reported = f.recent_dealer_reported
                            e.distance_from_dealer_location = f.distance_from_dealer_location
                            e.dealer_distance_from_plant = f.dealer_distance_from_plant
                            filted_atdealer_trucks_tomorrow_data.push(e)
                            distinct_tomorrow_trucks.push(e.truck_no)
                        }
                    })
                })

                var distinct_day_after_tomorrow_trucks = []
                var filted_atdealer_trucks_day_after_tomorrow_data = []
                atdealer_day_after_tomorrow_data.map(e => {
                    atdealer_dayafterTomorrow_trucks_cons_data.map(f => {
                        if (e.truck_no == f.truck_no && !distinct_day_after_tomorrow_trucks.includes(e.truck_no)) {
                            e.invoice_no = f.invoice_no
                            e.first_25_km_entry = f.first_25_km_entry
                            e.recent_dealer_reported = f.recent_dealer_reported
                            e.distance_from_dealer_location = f.distance_from_dealer_location
                            e.dealer_distance_from_plant = f.dealer_distance_from_plant
                            filted_atdealer_trucks_day_after_tomorrow_data.push(e)
                            distinct_day_after_tomorrow_trucks.push(e.truck_no)
                        }
                    })
                })

                this.setState({
                    rowData: [...empty_trucks_today_data, ...empty_trucks_tomorrow_data, ...empty_trucks_day_after_tomorrow_data],
                    empty_trucks_today_count: empty_trucks_today_count,
                    empty_trucks_today_data: empty_trucks_today_data,
                    empty_trucks_tomorrow_count: empty_trucks_tomorrow_count,
                    empty_trucks_tomorrow_data: empty_trucks_tomorrow_data,
                    empty_trucks_day_after_tomorrow_count: empty_trucks_day_after_tomorrow_count,
                    empty_trucks_day_after_tomorrow_data: empty_trucks_day_after_tomorrow_data,
                    atdealer_trucks_today_count: atdealer_trucks_today_count,
                    atdealer_trucks_today_data: filted_atdealer_trucks_today_data,
                    atdealer_trucks_tomorrow_count: atdealer_trucks_tomorrow_count,
                    atdealer_trucks_tomorrow_data: filted_atdealer_trucks_tomorrow_data,
                    atdealer_day_after_tomorrow_count: atdealer_day_after_tomorrow_count,
                    atdealer_day_after_tomorrow_data: filted_atdealer_trucks_day_after_tomorrow_data,
                    loadshow: "show-n",
                    overly: 'show-n',
                    columnDefs: columnDefsforEmptyTrucks
                })
            } catch (error) {
                console.log(error)
                this.setState({
                    empty_trucks_today_count: 0,
                    empty_trucks_today_data: [],
                    empty_trucks_tomorrow_count: 0,
                    empty_trucks_tomorrow_data: [],
                    empty_trucks_day_after_tomorrow_count: 0,
                    empty_trucks_day_after_tomorrow_data: [],
                    atdealer_trucks_today_count: 0,
                    atdealer_trucks_today_data: [],
                    atdealer_trucks_tomorrow_count: 0,
                    atdealer_trucks_tomorrow_data: [],
                    atdealer_day_after_tomorrow_count: 0,
                    atdealer_day_after_tomorrow_data: [],
                    loadshow: "show-n",
                    overly: 'show-n'

                })
            }


        })
    }

    onClickCounter(coutner) {
        if (coutner == "EmptyALLTrucks") {
            this.setState({
                rowData: [...this.state.empty_trucks_today_data, ...this.state.empty_trucks_tomorrow_data, ...this.state.empty_trucks_day_after_tomorrow_data],
                counter_title: "Empty - Today Trucks",
                columnDefs: columnDefsforEmptyTrucks
            })
        }
        else if (coutner == "EmptyTodayTrucks") {
            this.setState({
                rowData: this.state.empty_trucks_today_data,
                counter_title: "Empty - Today Trucks",
                columnDefs: columnDefsforEmptyTrucks
            })
        }
        else if (coutner == "EmptyTomorrowTrucks") {
            this.setState({
                rowData: this.state.empty_trucks_tomorrow_data,
                counter_title: "Empty - Tomorrow Trucks",
                columnDefs: columnDefsforEmptyTrucks
            })
        }
        else if (coutner == "EmptyDayAfterTomorrowTrucks") {
            this.setState({
                rowData: this.state.empty_trucks_day_after_tomorrow_data,
                counter_title: "Empty - Day After Tomorrow Trucks",
                columnDefs: columnDefsforEmptyTrucks
            })
        }
        else if(coutner == "AtDealerAllTrucks"){
            this.setState({
                rowData: [...this.state.atdealer_trucks_today_data,...this.state.atdealer_trucks_tomorrow_data,...this.state.atdealer_day_after_tomorrow_data],
                counter_title: "Empty - Today Trucks",
                columnDefs: columnDefsforEmptyTrucks
            })
        }
        else if (coutner == "AtDealerTodayTrucks") {
            this.setState({
                rowData: this.state.atdealer_trucks_today_data,
                counter_title: "At Dealer - Today Trucks",
                columnDefs: columnDefsForAtdealerTrucks
            })
        }
        else if (coutner == "AtDealerTomorrowTrucks") {
            this.setState({
                rowData: this.state.atdealer_trucks_tomorrow_data,
                counter_title: "At Dealer - Tomorrow Trucks",
                columnDefs: columnDefsForAtdealerTrucks
            })
        }
        else if (coutner == "AtDealerDayAfterTomorrowTrucks") {
            this.setState({
                rowData: this.state.atdealer_day_after_tomorrow_data,
                counter_title: "At Dealer - Day After Tomorrow Trucks",
                columnDefs: columnDefsForAtdealerTrucks
            })
        }
    }

    onCellClicked = (params) => {
        console.log(params)
        if (params.colDef.field == "distance_from_oem") {
            redirectURL.post("dashboard/getOtherOEMData", { truck_no: params.data.truck_no }).then(response => {
                this.setState({
                    otheroemRowData: response.data,
                    sliderForOEMData: "slider-translate-60p",
                    overly: "show-m"
                })
            })
        }

    }

    onClickHideAll = () => {
        this.setState({
            overly: "show-n",
            loadshow: "show-n",
            sliderForOEMData: ""
        })
    }

    render() {


        return (
            <div className="container-fluid">
                <div className="row">
                    <div className='col-12' style={{ float: "right", display: 'flex', alignItems: 'center', marginLeft: "-15px", marginBottom: "20px" }}>
                        <div className='col-4'>
                            <Select
                                closeMenuOnSelect={true}
                                name="plant_code"
                                isMulti={true}
                                placeholder="ALL"
                                // className="form-control"
                                value={this.state.plant_codes}
                                onChange={(e) => {
                                    this.setState({
                                        plant_codes: e
                                    })


                                }}
                                options={this.state.plant_codes_optins} />
                        </div>
                        <div className='col-4'>
                            <button className='btn btn-success' type="button" onClick={() => {
                                var selected_plant = this.state.plant_codes
                                var plant_codes = []
                                if (![undefined, "undefined", "", null].includes(selected_plant)) {
                                    selected_plant.map(plant => {

                                        plant_codes.push(plant.value)

                                    })
                                }
                                else {
                                    plant_codes = ['HHHG', 'HHHD', 'HHHU', 'HM4N', 'HM5V', 'HM6C']
                                }
                                if (plant_codes.includes("ALL")) plant_codes = ['HHHG', 'HHHD', 'HHHU', 'HM4N', 'HM5V', 'HM6C']
                                console.log(plant_codes, "selected plant")
                                this.LoadData(JSON.stringify(plant_codes))
                            }}>Submit</button>
                        </div>

                    </div>
                    <div className="col-xl-6 col-lg-6 col-sm-6 beffect">
                        <h5>EMPTY TRUCKS:</h5>
                        <div className="card">
                            <div className="card-body" id="dashboardCounterCardBody">
                                <div className="crm-numbers pb-0">
                                    <div className="row">
                                        <div className="col cirlce-d ">
                                            <span className="f13">
                                                <i className="icofont icofont-truck cus-i txt-info f24"></i>
                                                <br />Total Trucks
                                            </span>
                                            <h4 className="txt-info f50" style={{ cursor: "pointer" }} onClick={this.onClickCounter.bind(this, "EmptyALLTrucks")}>
                                                <span className="counter">
                                                    <CountUp end={this.state.empty_trucks_today_count + this.state.empty_trucks_tomorrow_count + this.state.empty_trucks_day_after_tomorrow_count || 0} />
                                                </span>
                                            </h4>
                                        </div>
                                        <div className="col cirlce-d ">
                                            <span className="f13">
                                                <i className="icofont icofont-truck cus-i txt-info f24"></i>
                                                <br />Today Trucks
                                            </span>
                                            <h4 className="txt-warning f50" style={{ cursor: "pointer" }} onClick={this.onClickCounter.bind(this, "EmptyTodayTrucks")}>
                                                <span className="counter">
                                                    <CountUp end={this.state.empty_trucks_today_count || 0} />
                                                </span>
                                            </h4>
                                        </div>

                                        <div className="col cirlce-d ">
                                            <span className="f13">
                                                <i className="icofont icofont-truck cus-i txt-success f24"></i>
                                                <br /> Tomorrow Trucks
                                            </span>
                                            <h4 className="txt-success f50" style={{ cursor: "pointer" }} onClick={this.onClickCounter.bind(this, "EmptyTomorrowTrucks")}>
                                                <span className="counter">
                                                    <CountUp end={this.state.empty_trucks_tomorrow_count || 0} />
                                                </span>
                                            </h4>
                                        </div>
                                        <div className="col cirlce-d " style={{ cursor: "pointer" }}>
                                            <span className="f13">
                                                <i className="icofont icofont-truck cus-i txt-danger f24"></i>
                                                <br />Day After Tomorrow Trucks
                                            </span>
                                            <h4 className="txt-danger f50" onClick={this.onClickCounter.bind(this, "EmptyDayAfterTomorrowTrucks")}>
                                                <span className="counter">
                                                    <CountUp end={this.state.empty_trucks_day_after_tomorrow_count || 0} />
                                                </span>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-sm-6 beffect">
                        <h5>AT DEALER:</h5>
                        <div className="card">
                            <div className="card-body" id="dashboardCounterCardBody">
                                <div className="crm-numbers pb-0">
                                    <div className="row">
                                        <div className="col cirlce-d ">
                                            <span className="f13">
                                                <i className="icofont icofont-truck cus-i txt-info f24"></i>
                                                <br />Total Trucks
                                            </span>
                                            <h4 className="txt-info f50" style={{ cursor: "pointer" }} onClick={this.onClickCounter.bind(this, "AtDealerAllTrucks")}>
                                                <span className="counter">
                                                    <CountUp end={this.state.atdealer_trucks_today_count + this.state.atdealer_trucks_tomorrow_count + this.state.atdealer_day_after_tomorrow_count || 0} />
                                                </span>
                                            </h4>
                                        </div>
                                        <div className="col cirlce-d ">
                                            <span className="f13">
                                                <i className="icofont icofont-truck cus-i txt-info f24"></i>
                                                <br /> Today Trucks
                                            </span>
                                            <h4 className="txt-warning f50" style={{ cursor: "pointer" }} onClick={this.onClickCounter.bind(this, "AtDealerTodayTrucks")}>
                                                <span className="counter">
                                                    <CountUp end={this.state.atdealer_trucks_today_count || 0} />
                                                </span>
                                            </h4>
                                        </div>

                                        <div className="col cirlce-d ">
                                            <span className="f13">
                                                <i className="icofont icofont-truck cus-i txt-success f24"></i>
                                                <br />Tomorrow Trucks
                                            </span>
                                            <h4 className="txt-success f50" style={{ cursor: "pointer" }} onClick={this.onClickCounter.bind(this, "AtDealerTomorrowTrucks")}>
                                                <span className="counter">
                                                    <CountUp end={this.state.atdealer_trucks_tomorrow_count || 0} />
                                                </span>
                                            </h4>
                                        </div>
                                        <div className="col cirlce-d " style={{ cursor: "pointer" }}>
                                            <span className="f13">
                                                <i className="icofont icofont-truck cus-i txt-danger f24"></i>
                                                <br />Day After Tomorrow Trucks
                                            </span>
                                            <h4 className="txt-danger f50" onClick={this.onClickCounter.bind(this, "AtDealerDayAfterTomorrowTrucks")}>
                                                <span className="counter">
                                                    <CountUp end={this.state.atdealer_day_after_tomorrow_count || 0} />
                                                </span>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card" >

                            <div className="card-header">
                                <h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i>
                                    {this.state.counter_title} Data

                                </h5>



                            </div>
                            <div className="card-body" style={{ padding: "10px" }}>
                                <div
                                    id="myGrid"
                                    style={{
                                        height: "425px",
                                        // width: "100%"
                                    }}
                                    className={"ag-theme-balham"}
                                >
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={this.state.columnDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        frameworkComponents={this.state.frameworkComponents}
                                        detailCellRendererParams={this.state.detailCellRendererParams}
                                        //editType={this.state.editType}
                                        stopEditingWhenGridLosesFocus={true}
                                        //floatingFilter={true}
                                        alwaysShowHorizontalScroll={true}
                                        alwaysShowVerticalScroll={true}
                                        enableCellChangeFlash={false}
                                        //suppressCellFlash={true}
                                        rowClassRules={this.state.rowClassRules}
                                        // onCellDoubleClicked={this.onRowClicked.bind(this)}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        masterDetail={true}
                                        // getRowHeight={this.state.getRowHeight}
                                        // onCellEditingStopped={this.onCellUpdateData.bind(this)}

                                        rowSelection={this.state.rowSelection}
                                        // onRowSelected={this.onRowSelection.bind(this)}
                                        suppressRowClickSelection={true}
                                        // suppressCellSelection= {true}
                                        overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                        excelStyles={this.state.excelStyles}
                                        onCellClicked={this.onCellClicked.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"slide-r " + (this.state.sliderForOEMData)} style={{ overflow: "auto" }}>
                    <div className="slide-r-title">
                        <h4>
                            Distance From Each OEM
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>

                        <div
                            id="myGrid"
                            style={{
                                height: "425px",
                                // width: "100%"
                            }}
                            className={"ag-theme-balham"}
                        >
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={this.state.otheroemColDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.otheroemRowData}
                                // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                enableCharts={true}
                                enableRangeSelection={true}
                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                frameworkComponents={this.state.frameworkComponents}
                                detailCellRendererParams={this.state.detailCellRendererParams}
                                //editType={this.state.editType}
                                stopEditingWhenGridLosesFocus={true}
                                //floatingFilter={true}
                                alwaysShowHorizontalScroll={true}
                                alwaysShowVerticalScroll={true}
                                enableCellChangeFlash={false}
                                //suppressCellFlash={true}
                                rowClassRules={this.state.rowClassRules}
                                // onCellDoubleClicked={this.onRowClicked.bind(this)}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                                masterDetail={true}
                                // getRowHeight={this.state.getRowHeight}
                                // onCellEditingStopped={this.onCellUpdateData.bind(this)}

                                rowSelection={this.state.rowSelection}
                                // onRowSelected={this.onRowSelection.bind(this)}
                                suppressRowClickSelection={true}
                                // suppressCellSelection= {true}
                                overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                excelStyles={this.state.excelStyles}
                            // onCellClicked={this.onCellClicked.bind(this)}
                            />
                        </div>
                    </div>
                </div>
                <div
                    className={"overlay-part " + this.state.overly}
                    onClick={this.onClickHideAll.bind(this)}
                ></div>
                <div className={"dataLoadpage " + this.state.loadshow}></div>
                <div className={"dataLoadpageimg " + this.state.loadshow}>
                    <div class="loader-box">
                        <div class="loader-box">
                            <div class="rotate dashed colored"></div>
                        </div>
                    </div>
                </div>

            </div>

        );
    }
}

export default vehicleAvailabilityAtPlantComponent;

function secondsToHM(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Min " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " Second" : " Seconds") : "";
    return dDisplay + hDisplay + mDisplay;


}

function GetSortOrder(prop) {
    return function (a, b) {
        if (a[prop] < b[prop]) {
            return 1;
        } else if (a[prop] > b[prop]) {
            return -1;
        }
        return 0;
    }
}