import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from 'lodash';
import $ from 'jquery';
// import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import download from 'js-file-download';

var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

var redirectURL  = require('../redirectURL');

export default class EditTransporterExportsCargoEvent extends Component{
    constructor(props){
        super(props);
        this.state = {
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            csvcontent:"",
            file : "",
            loadshow:'show-n',
            overly:'show-n',
            uploadYard : "",
            uploadDispatchDate : "",
            rowData:"",
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:50,
            geofencelist:[],
            geoFenceData:"",
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            pagetitle:"",
            bulkslide:"",
            cargoBookingId:"",
            airCargoTransporterId:"",
            other_docs:"",
            file:"",
            loadshow : "show-n",
            hiddenotherdocs : "",
            hiddeninvoice : "",
            pagetype : 1,
            pickupData : [{}],
            ringiData : [{}],
            //components : {datepicker:getDatePicker()}
        }
        this.changeHandler = this.changeHandler.bind(this);
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount(){
        loadDateTimeScript();
        if (googleAnalytics.page.enableGA) {
			this.logPageView();
		}

        var inputEventval = window.atob(this.props.match.params.eventname);
        var pagetype = this.props.match.params.pagetype;
        console.log(pagetype,"pagetype")
        this.setState({
            pagetype : pagetype
        })
		// console.log
		var cargoBookingId="";
		var reqparams = {
            eventID : inputEventval,
        }

        redirectURL.post("/consignments/getAirCargoExportsByRequestId",reqparams).then((response)=>{
            console.log(response.data);
            var cargoBookingId=response.data[0]._id;
			this.setState({
				event : response.data[0].event,
				cargoBookingId : response.data[0]._id,
                job_no : response.data[0].job_no,
            })

            if(response.data[0].pickup_plant != undefined && response.data[0].pickup_plant !='')
            {
                $("#pickup_plant").val(response.data[0].pickup_plant);
            }
            if(response.data[0].invoice_file != undefined && response.data[0].invoice_file !='')
            {
                this.setState({invoice_docs:response.data[0].invoice_file})
            }
            if(response.data[0].packing_list_file != undefined && response.data[0].packing_list_file !='')
            {
                this.setState({packing_docs:response.data[0].packing_list_file})
            }
            if(response.data[0].sqc_list_file != undefined && response.data[0].sqc_list_file !='')
            {
                this.setState({sqc_docs:response.data[0].sqc_list_file})
            }
            if(response.data[0].annex_file != undefined && response.data[0].annex_file !='')
            {
                this.setState({annex_docs:response.data[0].annex_file})
            }
            if(response.data[0].gr_wavier_file != undefined && response.data[0].gr_wavier_file !='')
            {
                this.setState({gr_docs:response.data[0].gr_wavier_file})
            }
            if(response.data[0].msds_file != undefined && response.data[0].msds_file !='')
            {
                this.setState({msds_docs:response.data[0].msds_file})
            }
            if(response.data[0].iip_file != undefined && response.data[0].iip_file !='')
            {
                this.setState({iip_docs:response.data[0].iip_file})
            }
            

            if(response.data[0].ff_code != undefined && response.data[0].ff_code !='')
            {
                $("#assign_to_ff").val(response.data[0].ff_code);
            }            
            if(response.data[0].cha_code != undefined && response.data[0].cha_code !='')
            {
                $("#assign_to_cha").val(response.data[0].cha_code);
            }            
            if(response.data[0].transporter_code != undefined && response.data[0].transporter_code !='')
            {
                $("#assign_to_transporter").val(response.data[0].transporter_code);
            }
            var pickupData=[{}];
            if(response.data[0].pickupData !=undefined && response.data[0].pickupData !='')
            {
                var pickupData = JSON.parse(response.data[0].pickupData);
            }
            var ringiData=[{}];
            if(response.data[0].ringiData !=undefined && response.data[0].ringiData !='')
            {
                var ringiData = JSON.parse(response.data[0].ringiData);
            }
            var date ="";
            if(response.data[0].date !=undefined && response.data[0].date !='')
            {
                date = response.data[0].date;
                if(date.includes("T") == true)
                {
                    date = date.split("T");
                    date = date[0];
                    date = date.split("-")[2]+"-"+date.split("-")[1]+"-"+date.split("-")[0];
                }
            }
            var cargo_date ="";
            if(response.data[0].cargo_date !=undefined && response.data[0].cargo_date !='')
            {
                cargo_date = response.data[0].cargo_date;
                if(cargo_date.includes("T") == true)
                {
                    cargo_date = cargo_date.split("T");
                    cargo_date = cargo_date[0];
                    cargo_date = cargo_date.split("-")[2]+"-"+cargo_date.split("-")[1]+"-"+cargo_date.split("-")[0];
                }
            }
            var eta_igi_airport_date = "";
            if(response.data[0].eta_igi_airport_date !=undefined && response.data[0].eta_igi_airport_date !='')
            {
                eta_igi_airport_date = response.data[0].eta_igi_airport_date;
                if(eta_igi_airport_date.includes("T") == true)
                {
                    eta_igi_airport_date = eta_igi_airport_date.split("T");
                    eta_igi_airport_date = eta_igi_airport_date[0];
                    eta_igi_airport_date = eta_igi_airport_date.split("-")[2]+"-"+eta_igi_airport_date.split("-")[1]+"-"+eta_igi_airport_date.split("-")[0];
                }
            }
            var commercial_invoice_date ="";
            if(response.data[0].commercial_invoice_date !=undefined && response.data[0].commercial_invoice_date !='')
            {
                commercial_invoice_date = response.data[0].commercial_invoice_date;
                if(commercial_invoice_date.includes("T") == true)
                {
                    commercial_invoice_date = commercial_invoice_date.split("T");
                    commercial_invoice_date = commercial_invoice_date[0];
                    commercial_invoice_date = commercial_invoice_date.split("-")[2]+"-"+commercial_invoice_date.split("-")[1]+"-"+commercial_invoice_date.split("-")[0];
                }
            }
            if(response.data[0].delivery_terms !=undefined && response.data[0].delivery_terms !='')
            {
                $("#delivery_terms").val(response.data[0].delivery_terms);
            }
            if(response.data[0].payment_terms !=undefined && response.data[0].payment_terms !='')
            {
                $("#payment_terms").val(response.data[0].payment_terms);
            }
            if(response.data[0].shipment_return !=undefined && response.data[0].shipment_return !='')
            {
                $("#shipment_return").val(response.data[0].shipment_return);
            }
            if(response.data[0].cert_of_origin !=undefined && response.data[0].cert_of_origin !='')
            {
                $("#cert_of_origin").val(response.data[0].cert_of_origin);
            }
            if(response.data[0].dg !=undefined && response.data[0].dg !='')
            {
                $("#dg").val(response.data[0].dg);
            }
            
			this.setState({
				event : response.data[0].event,
				editedEventID : response.data[0]._id,
				oldEvent : response.data[0].event,
				airport_name : response.data[0].airport_name,
                benefit_certificate : response.data[0].benefit_certificate,
                assign_to_ff : response.data[0].assign_to_ff,
                assign_to_cha : response.data[0].assign_to_cha,
                assign_to_transporter : response.data[0].assign_to_transporter,
                pickupData : pickupData,
				cargo_date : cargo_date,
				pickup_plant : response.data[0].pickup_plant,
                country : response.data[0].country,
                date : date,
                delivery_terms : response.data[0].delivery_terms,
                dept_name : response.data[0].dept_name,
                email : response.data[0].email,
                free_trade : response.data[0].free_trade,
                indenter_email : response.data[0].indenter_email,
                indenter_name : response.data[0].indenter_name,
                job_no : response.data[0].job_no,
                commercial_invoice : response.data[0].commercial_invoice,
                commercial_invoice_date : commercial_invoice_date,
                payment_terms : response.data[0].payment_terms,
                mobile : response.data[0].mobile,
                shipment_return : response.data[0].shipment_return,
                cert_of_origin : response.data[0].cert_of_origin,
                dg : response.data[0].dg,
                no_of_packets : response.data[0].no_of_packets,
                phone : response.data[0].phone,
                destination_airport : response.data[0].destination_airport,
                port : response.data[0].port,
                pick_up_address : response.data[0].pick_up_address,
                ringiData : ringiData,
                consignee_address : response.data[0].consignee_address,
                consignee_contact_person : response.data[0].consignee_contact_person,
                consignee_name : response.data[0].consignee_name,
                //ff_contact_person_details : response.data[0].ff_contact_person_details,
                //fta : response.data[0].fta,
                request_type : response.data[0].request_type,
            })

            var resparams = {
                cargoBookingId : cargoBookingId,
            }
            //console.log(resparams)
            
                var exp_pickup_from_msil ="";
                if(response.data[0].exp_pickup_from_msil !=undefined && response.data[0].exp_pickup_from_msil !='')
                {
                    exp_pickup_from_msil = response.data[0].exp_pickup_from_msil;
                    if(exp_pickup_from_msil.includes("T") == true)
                    {
                        exp_pickup_from_msil = exp_pickup_from_msil.split("T");
                        exp_pickup_from_msil = exp_pickup_from_msil[0];
                        exp_pickup_from_msil = exp_pickup_from_msil.split("-")[2]+"-"+exp_pickup_from_msil.split("-")[1]+"-"+exp_pickup_from_msil.split("-")[0];
                    }
                }
                var actual_pickup_from_msil ="";
                if(response.data[0].actual_pickup_from_msil !=undefined && response.data[0].actual_pickup_from_msil !='')
                {
                    actual_pickup_from_msil = response.data[0].actual_pickup_from_msil;
                    if(actual_pickup_from_msil.includes("T") == true)
                    {
                        actual_pickup_from_msil = actual_pickup_from_msil.split("T");
                        actual_pickup_from_msil = actual_pickup_from_msil[0];
                        actual_pickup_from_msil = actual_pickup_from_msil.split("-")[2]+"-"+actual_pickup_from_msil.split("-")[1]+"-"+actual_pickup_from_msil.split("-")[0];
                    }
                }
                var ata_igi_airport_date ="";
                if(response.data[0].ata_igi_airport_date !=undefined && response.data[0].ata_igi_airport_date !='')
                {
                    ata_igi_airport_date = response.data[0].ata_igi_airport_date;
                    if(ata_igi_airport_date.includes("T") == true)
                    {
                        ata_igi_airport_date = ata_igi_airport_date.split("T");
                        ata_igi_airport_date = ata_igi_airport_date[0];
                        ata_igi_airport_date = ata_igi_airport_date.split("-")[2]+"-"+ata_igi_airport_date.split("-")[1]+"-"+ata_igi_airport_date.split("-")[0];
                    }
                }
                $("#vehicle_no").val(response.data[0].vehicle_no);
                $("#exp_pickup_from_msil").val(exp_pickup_from_msil);
                $("#actual_pickup_from_msil").val(actual_pickup_from_msil);
                $("#gr_no").val(response.data[0].gr_no);
                $("#gst_no").val(response.data[0].gst_no);
                $("#ata_igi_airport_date").val(ata_igi_airport_date);

            
        }).catch(function(error){
            console.log(error);
		})
        
       
    }

    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
    }
    
closeAlert = () => {
    this.setState({
        show: false
    });
    var pagetype = this.state.pagetype;
    if(pagetype == 1)
    {
        window.location.href="/transportationaircargoexports";
    }
    else
    {
        window.location.href="/aircargoexports";
    }
}
changeHandler = (event) => {
    console.log("Select", event.target.value);
    let name = event.target.name;
    console.log(name);
    let value = event.target.value;
    //this.setState({[name]:value});
}
changeFileHandler = (e) => {
    console.log(e);
    this.setState({
        file : e.target.files[0]
    });
    console.log(e)
}

changeDocFileHandler = (e) => {
    console.log(e);
    this.setState({
        other_docs : e.target.files[0]
    });
    console.log(e)
}

formHandler(event){
    event.preventDefault();
    
    var exp_pickup_from_msil = $("#exp_pickup_from_msil").val();
    var actual_pickup_from_msil = $("#actual_pickup_from_msil").val();
    var vehicle_no = $("#vehicle_no").val();
    var gr_no = $("#gr_no").val();
    var gst_no = $("#gst_no").val();
    var ata_igi_airport_date = $("#ata_igi_airport_date").val();

    let saveData = new FormData();
  
    saveData.append("job_no",this.state.job_no);
    saveData.append("cargo_booking_id", this.state.cargoBookingId);
    
    saveData.append("exp_pickup_from_msil", exp_pickup_from_msil);
    saveData.append("actual_pickup_from_msil", actual_pickup_from_msil);
    saveData.append("vehicle_no", vehicle_no);
    saveData.append("gr_no", gr_no);
    saveData.append("gst_no", gst_no);
    saveData.append("ata_igi_airport_date", ata_igi_airport_date);
    saveData.append("airCargoTransporterId", this.state.airCargoTransporterId);
    saveData.append("updated_by", localStorage.getItem("email"));
    saveData.append("updated_user_id", localStorage.getItem("userid"));

    console.log(saveData);
    redirectURL.post("/consignments/savetransporterCargoExportsData",saveData,{
        headers:{
            'content-type': 'multipart/form-data'
        }
    }).then((response)=>{   
        this.setState({
            basicTitle:"Success",
            basicType : "success",
            show : true
        });
    }).catch((e)=>{
        console.log(e);
    })
        
    // }
    // else
    // { 
    //     this.setState({
    //         basicTitle:"Fields Should not be Empty",
    //         basicType : "warning",
    //         show : true
    //     })
    // }
}



uploadFormHandler(event){
    event.preventDefault();
    
    var formData = new FormData(event.target);
    formData.append('uploadFile',this.state.file);
    
    console.log('file',formData);
    redirectURL.post("/consignments/saveffCargoData",formData,{
        headers:{
            'content-type': 'multipart/form-data'
        }
    }).then((response)=>{   
        this.setState({
            basicTitle:"Success",
            basicType : "success",
            show : true
        });
        // window.location.reload();
    }).catch((e)=>{
        console.log(e);
    })
}

handleDownloadRingiFile = (idx) => {
    var ringiFile = $("#hiddenRingiFile_"+idx).val();
    //console.log(ringiFile)
    if(ringiFile !='')
    {
        var rngParam = {
            ringiFile : ringiFile,
        }
        redirectURL.post("/consignments/downloadExportsRingiFile",rngParam,{
            responseType: 'blob'
        }).then((response)=>{
            console.log("dowlad response ",response)
            download(response.data, ringiFile)
        }).catch(function(error){
            console.log(error);
        })
    }
}

handleDownloadFile = (ringiFile) => {
    //console.log(ringiFile)
    if(ringiFile !=undefined && ringiFile !='')
    {
        var rngParam = {
            ringiFile : ringiFile,
        }
        redirectURL.post("/consignments/downloadExportsRingiFile",rngParam,{
            responseType: 'blob'
        }).then((response)=>{
            console.log("dowlad response ",response)
            download(response.data, ringiFile)
        }).catch(function(error){
            console.log(error);
        })
    }
}

render(){
        
        return(
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Job No: {this.state.job_no}</span>
								</h5>
				   			</div>
                            <div className="card-body pt-15px">
                                <form class="aircargo-form" onSubmit={this.formHandler.bind(this)} encType="multipart/form-data">
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Date : </label>
                                            <input type="text" name="date" id="dateField" value={this.state.date} className="datetimepicker_date form-control" disabled onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Department Name : </label>
                                            <input type="text" name="dept_name" id="dept_name" className="form-control" value={this.state.dept_name} disabled onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Indenter Name : </label>
                                            <input type="text" name="indenter_name" id="indenter_name" className="form-control" value={this.state.indenter_name} disabled onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Mobile No : </label>
                                            <input type="tel" name="mobile" id="mobile" className="form-control" pattern="[0-9]{10}" value={this.state.mobile} disabled onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Indenter Email ID : </label>
                                            <input type="email" name="indenter_email" id="indenter_email" className="form-control" value={this.state.indenter_email} disabled onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    {this.state.ringiData.map((item, idx) => (
                                    <div className={"row col-xl-12 "} key={idx}>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Payment Ringi No (File): </label>

                                            {((item.ringi_file != "") && (item.ringi_file != undefined)) ?  
                                            <div className="row col-xl-12">
                                                <div className="col-xl-9">
                                                     <a className={"rnFile_"+idx}>{item.ringi_file}</a> 
                                                </div>
                                                <div className="col-xl-1">
                                                    <p className={"rnFile_"+idx} style={{cursor:"pointer"}} onClick={() => this.handleDownloadRingiFile(idx)}><i title="Download" className="fa fa-download"></i></p>
                                                    
                                                    {/* <a target="_blank" href={require("../../../../api/routes/uploads/"+(item.ringi_file))}>Test </a> */}
                                                </div>
                                                <input type="hidden" id={"hiddenRingiFile_"+idx} value={item.ringi_file} />
                                            </div>
                                            :
                                            ""
                                            }
                                            
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Payment Ringi No : </label>
                                            <input type="text" name={"ringiDesc_"+idx} id={"ringiDesc_"+idx} className="form-control" disabled value={item.ringi_desc} onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    ))}
                                    <input type="hidden" id="rngLength" value={this.state.ringiData.length}/>
                                    <div class={"hr-dashed "}></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Pickup Plant : </label>
                                            <input class="form-control col-xl-12 col-lg-12" name="pickup_plant" id="pickup_plant" disabled onChange={this.changeHandler} />
                                        </div>

                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">No Of Packets : </label>
                                            <input type="text" name="no_of_packets" id="no_of_packets" className="form-control num-cls" value={this.state.no_of_packets} disabled onBlur={this.packetsHandler}/>
                                        </div>
                                    </div>
                                    {this.state.pickupData.map((item, idx) => (
                                    <div className="row col-xl-12" key={idx}>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Item Description : </label>
                                            <input type="text" name={"itemDesc_"+idx} id={"itemDesc_"+idx}className="form-control" value={item.item_description} disabled onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Cargo Gross Weight : </label>
                                            <input type="text" name={"grossWt_"+idx} id={"grossWt_"+idx}className="form-control num-cls" value={item.cargo_gross_weight} disabled onChange={this.changeHandler} />
                                        </div>
                                        <div className={"col-xl-4 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">Dimensions (cms) : </label>
                                            <input type="text" name={"dimensions_"+idx} id={"dimensions_"+idx} placeholder="l x b x h" className="form-control requestFields" value={item.dimensions} disabled onChange={this.changeHandler} />
                                        </div>
                                        <div className={"col-xl-4 "+this.state.prepaidFields}>
                                            <label className="col-xl-12 col-lg-12">Cargo Chargeable Weight* : </label>
                                            <input type="text" name={"cargo_chargeable_weight_"+idx} id={"cargo_chargeable_weight_"+idx} className="form-control prepaidFields" value={item.cargo_chargeable_weight} disabled onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    ))}
                                    <div class="hr-dashed"></div>                                   
                                    <div className="row col-xl-12">
                                        <h4 style={{color: "#fff",width: "100%",backgroundColor: "#3d6db7",textAlign: "center",fontSize: "18px",padding: "6px 0"}}>Consignee Details</h4>
                                    </div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Consignee Name : </label>
                                            <input type="text" name="consignee_name" id="consignee_name" value={this.state.consignee_name} className="form-control" disabled onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Consignee Address {this.state.addresstxt}* : </label>
                                            <textarea name="consignee_address" id="consignee_address" value={this.state.consignee_address} className="form-control" disabled onChange={this.changeHandler} />
                                        </div>
                                        <div className={"col-xl-4 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">Consignee Contact Person : </label>
                                            <input type="text" name="consignee_contact_person" id="consignee_contact_person" value={this.state.consignee_contact_person} placeholder="" className="form-control requestFields" disabled onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Invoice* : </label>
                                            {((this.state.invoice_docs != "") && (this.state.invoice_docs != undefined)) ?  
                                                <div id="invoice_docs" className="row">
                                                    <div className="col-xl-9">
                                                        <a className={"rnFile"}>{this.state.invoice_docs}</a> 
                                                    </div>
                                                    <div className="col-xl-1">
                                                        <p className={"rnFile"} style={{cursor:"pointer"}} onClick={() => this.handleDownloadFile(this.state.invoice_docs)}><i title="Download" className="fa fa-download"></i></p>
                                                    </div>
                                                    <input type="hidden" id={"hidden_invoice_file"} value={this.state.invoice_docs} />
                                                </div>
                                                :
                                                ""
                                            }
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Packing List* : </label>
                                            {((this.state.packing_docs != "") && (this.state.packing_docs != undefined)) ?  
                                                <div id="packing_docs" className="row">
                                                    <div className="col-xl-9">
                                                        <a className={"rnFile"}>{this.state.packing_docs}</a> 
                                                    </div>
                                                    <div className="col-xl-1">
                                                        <p className={"rnFile"} style={{cursor:"pointer"}} onClick={() => this.handleDownloadFile(this.state.packing_docs)}><i title="Download" className="fa fa-download"></i></p>
                                                    </div>
                                                    <input type="hidden" id={"hidden_packing_file"} value={this.state.packing_docs} />
                                                </div>
                                                :
                                                ""
                                            }
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">SQC List : </label>
                                            {((this.state.sqc_docs != "") && (this.state.sqc_docs != undefined)) ?  
                                                <div id="sqc_docs" className="row">
                                                    <div className="col-xl-9">
                                                        <a className={"rnFile"}>{this.state.sqc_docs}</a> 
                                                    </div>
                                                    <div className="col-xl-1">
                                                        <p className={"rnFile"} style={{cursor:"pointer"}} onClick={() => this.handleDownloadFile(this.state.sqc_docs)}><i title="Download" className="fa fa-download"></i></p>
                                                    </div>
                                                    <input type="hidden" id={"hidden_sqc_file"} value={this.state.sqc_docs} />
                                                </div>
                                                :
                                                ""
                                            }
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Annex II : </label>
                                            {((this.state.annex_docs != "") && (this.state.annex_docs != undefined)) ?  
                                                <div id="annex_docs" className="row">
                                                    <div className="col-xl-9">
                                                        <a className={"rnFile"}>{this.state.annex_docs}</a> 
                                                    </div>
                                                    <div className="col-xl-1">
                                                        <p className={"rnFile"} style={{cursor:"pointer"}} onClick={() => this.handleDownloadFile(this.state.annex_docs)}><i title="Download" className="fa fa-download"></i></p>
                                                    </div>
                                                    <input type="hidden" id={"hidden_annex_file"} value={this.state.annex_docs} />
                                                </div>
                                                :
                                                ""
                                            }
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">GR wavier – in case of free of charge : </label>
                                            {((this.state.gr_docs != "") && (this.state.gr_docs != undefined)) ?  
                                                <div id="gr_docs" className="row">
                                                    <div className="col-xl-9">
                                                        <a className={"rnFile"}>{this.state.gr_docs}</a> 
                                                    </div>
                                                    <div className="col-xl-1">
                                                        <p className={"rnFile"} style={{cursor:"pointer"}} onClick={() => this.handleDownloadFile(this.state.gr_docs)}><i title="Download" className="fa fa-download"></i></p>
                                                    </div>
                                                    <input type="hidden" id={"hidden_gr_file"} value={this.state.gr_docs} />
                                                </div>
                                                :
                                                ""
                                            }
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">MSDS – in case of DG : </label>
                                            {((this.state.msds_docs != "") && (this.state.msds_docs != undefined)) ?  
                                                <div id="msds_docs" className="row">
                                                    <div className="col-xl-9">
                                                        <a className={"rnFile"}>{this.state.msds_docs}</a> 
                                                    </div>
                                                    <div className="col-xl-1">
                                                        <p className={"rnFile"} style={{cursor:"pointer"}} onClick={() => this.handleDownloadFile(this.state.msds_docs)}><i title="Download" className="fa fa-download"></i></p>
                                                    </div>
                                                    <input type="hidden" id={"hidden_msds_file"} value={this.state.msds_docs} />
                                                </div>
                                                :
                                                ""
                                            }
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">IIP certificate (UN packing) – in case of DG : </label>
                                            {((this.state.iip_docs != "") && (this.state.iip_docs != undefined)) ?  
                                                <div id="iip_docs" className="row">
                                                    <div className="col-xl-9">
                                                        <a className={"rnFile"}>{this.state.iip_docs}</a>
                                                    </div>
                                                    <div className="col-xl-1">
                                                        <p className={"rnFile"} style={{cursor:"pointer"}} onClick={() => this.handleDownloadFile(this.state.iip_docs)}><i title="Download" className="fa fa-download"></i></p>
                                                    </div>
                                                    <input type="hidden" id={"hidden_iip_file"} value={this.state.iip_docs} />
                                                </div>
                                                :
                                                ""
                                            }
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Country : </label>
                                            <input type="text" name="country" id="country" className="form-control requestFields" value={this.state.country} onChange={this.changeHandler} disabled/>
                                        </div>
                                        <div className={"col-xl-3 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">Destination Airport* : </label>
                                            <input type="text" name="destination_airport" id="destination_airport" className="form-control requestFields" value={this.state.destination_airport} onChange={this.changeHandler} disabled/>
                                        </div>
                                        <div className={"col-xl-3 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">Email ID : </label>
                                            <input type="email" name="email" id="email" className="form-control requestFields" value={this.state.email} disabled onChange={this.changeHandler} />
                                        </div>
                                        <div className={"col-xl-3 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">Phone No : </label>
                                            <input type="text" name="phone" id="phone" placeholder="" className="form-control requestFields" value={this.state.phone} disabled onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Delivery/Inco Terms* : </label>
                                            <input class="form-control col-xl-12 col-lg-12" name="delivery_terms" id="delivery_terms" disabled onChange={this.changeHandler} />
                                        </div>
                                        {(this.state.delivery_terms == "Delivery Duty Paid (DDP)" || this.state.delivery_terms == "Delivered Duty Unpaid (DDU)")?
                                            <div className="col-xl-4">
                                                <label className="col-xl-12 col-lg-12">Delivery Address* : </label>
                                                <textarea className="form-control dterms" name="pick_up_address" id="pick_up_address" onChange={this.changeHandler} disabled>{this.state.pick_up_address}</textarea>
                                            </div> : ""
                                            // :(this.state.delivery_terms == "FCA")?
                                            //     <div className="col-xl-4">
                                            //         <label className="col-xl-12 col-lg-12">Place / Port : </label>
                                            //         <input type="text" className="form-control dterms" name="port" id="port" onChange={this.changeHandler} required/>
                                            //     </div>
                                            // :
                                            //     <div className="col-xl-4">
                                            //         <label className="col-xl-12 col-lg-12">Airport Name : </label>
                                            //         <input type="text" className="form-control dterms" name="airportName" id="airportName"  onChange={this.changeHandler} required/>
                                            //     </div>
                                        }
                                        <div className={"col-xl-4 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">Proposed Cargo Readiness Date : </label>
                                            <input type="text" name="cargoDate" id="cargoDate" className="datetimepicker_date form-control requestFields" value={this.state.cargo_date} disabled onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    <div class={"hr-dashed "+this.state.requestFields}></div>
                                    <div className="row col-xl-12">
                                        
                                    <div className={"col-xl-3 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">Commercial Invoice* : </label>
                                            <input type="text" name="commercial_invoice" id="commercial_invoice" value={this.state.commercial_invoice} className="form-control requestFields" onChange={this.changeHandler} disabled/>
                                        </div>
                                        <div className={"col-xl-3 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">Commercial Invoice Date* : </label>
                                            <input type="text" name="commercial_invoice_date" id="commercial_invoice_date" value={this.state.commercial_invoice_date} className="datetimepicker_date form-control requestFields"  onChange={this.changeHandler} disabled/>
                                        </div>
                                        <div className={"col-xl-4 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">Payment terms* : </label>
                                            <input class="form-control col-xl-12 col-lg-12 requestFields" name="payment_terms" id="payment_terms" onChange={this.changeHandler} disabled />
                                        </div>
                                    </div>
                                    <div class={"hr-dashed "}></div>
                                    <div className="row col-xl-12">
                                    <div className={"col-xl-4"}>
                                            <label className="col-xl-12 col-lg-12">Shipment Return (Re-Import)* : </label>
                                            <input class="form-control col-xl-12 col-lg-12" name="shipment_return" id="shipment_return" onChange={this.changeHandler} disabled />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Certificate of origin* : </label>
                                            <input class="form-control col-xl-12 col-lg-12" name="cert_of_origin" id="cert_of_origin" onChange={this.changeHandler} disabled/>
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">DG* : </label>
                                            <input class="form-control col-xl-12 col-lg-12" name="dg" id="dg" onChange={this.changeHandler} disabled />
                                        </div>
                                    </div>
                                    <div class={"hr-dashed "}></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Vehicle No : </label>
                                            <input type="text" name="vehicle_no" id="vehicle_no" className="form-control" required onChange={this.changeHandler}/>
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Expected Pickup From MSIL : </label>
                                            <input type="text" name="exp_pickup_from_msil" id="exp_pickup_from_msil" className="datetimepicker_date form-control" onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Actual Pickup From MSIL : </label>
                                            <input type="text" name="actual_pickup_from_msil" id="actual_pickup_from_msil" className="datetimepicker_date form-control" onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    <div class={"hr-dashed "}></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">GR No : </label>
                                            <input type="text" name="gr_no" id="gr_no" className="form-control" onChange={this.changeHandler}/>
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">GST No : </label>
                                            <input type="text" name="gst_no" id="gst_no" className="form-control" onChange={this.changeHandler} />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">ATA IGI Airport : </label>
                                            <input type="text" name="ata_igi_airport_date" id="ata_igi_airport_date" className="datetimepicker_date form-control" onChange={this.changeHandler} />
                                        </div>
                                    </div>
                                    <div className="row col-xl-12 col-lg-12 form-group">
                                        <button type="submit" className="btn btn-success" style={{marginLeft:"45%",width:"192px",marginTop:"20px"}}>Submit</button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>           
            </div>
        )
    }
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

function cleanTruckNo(value){
    var result =  /\s/g.test(value);
    var cleanedValue="";
    try{
        if(result == true){
            cleanedValue = value.replace(/[^\w\s]/gi, '');
            cleanedValue = cleanedValue.replace(/\s/g, '').toUpperCase();
        }
        else{
            cleanedValue = value.toUpperCase();
        }
    }
    catch(e){  }
    return cleanedValue;
}


// function checkWhiteSpaces(value){
//     return /\s/g.test(value);
// }
// function removeWhiteSpace(value){
//     value = value.replace(/[^\w\s]/gi, '');
//     return value.replace(/\s/g, '').toUpperCase();
// }
function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		//mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		//mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
	index.parentNode.insertBefore(script,index)
}

