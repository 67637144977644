import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import _ from "lodash";
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";import { getDDMMYYYY, 
	getDDMMYYYYHHMMSS, 
	getYYYYMMDD, 
	getYYYYMMDDHHMMSS, 
	getHyphenYYYYMMDDHHMMSS, 
    getHyphenYYYYMMDD, 
    getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,
	getHyphenDDMMYYYYHHMMSS, 
	getHyphenDDMMYYYY } from '../common/utils';

import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import Select from 'react-select';
import $ from 'jquery';
// import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';

import ColumnDrilldownOnTime from './columndrilldownontime';
import OnTimeColumnChart from './ontimecolumnchart';
import OnTimeLineChart from "./ontimelinechart";
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');
var redirectURL = require('../redirectURL');
var map;
var infoBox = require('../common/google-infowindow');

export default class CityWiseDieselPrices extends Component {

    constructor(props) {
        super(props);
        this.state={
            modules:AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
                enablePivot: true,
                pivot : true,
                enableValue: true,
                enableRowGroup: true,
            },
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
            rowData: [],
            paginationPageSize:50,
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            loadshow:'show-n',
            overly:'show-n',
            startDate:'',
            eventGridAction: "gridAction",
            show: false,
            basicTitle:'',
            basicType:"default",
            pod_processed_counter_stt: [],
            sliderCity: "",
            city_name: "",
            edited_latitude: "",
            edited_longitude: "",
            city_options: [],
            endDate: "",
            filtered_city_options: [],
            filtered_city_name: {"label": "ALL", value: "ALL"}
        }
    }

    componentDidMount = async()=>{
		this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        this.setState({eventAction: eventAction});
        var startDate = moment.parseZone().format("YYYY-MM-DD");
        await this.setState({
            startDate: startDate,
            endDate: startDate
        })
        this.getDieselPrices();
    }
    getDieselPrices()
    {
        var formdata = {"startDate": this.state.startDate, "endDate": this.state.endDate}
        if(this.state.filtered_city_name != null)
        {
            if(Object.keys(this.state.filtered_city_name).length)
            {
                if(this.state.filtered_city_name.value != "ALL")
                {
                    formdata['city'] = this.state.filtered_city_name.value
                }
            }
        }
        redirectURL.post("/dashboard/getDieselPricesForSelectedDate", formdata).then((response)=>{
            var city_options = [];
            var filtered_city_options = [{"label": "ALL", value: "ALL"}]
            response.data.cities.map((e)=>{
                city_options.push({"label": e, "value": e})
            })
            response.data.available_cities.map((e)=>{
                filtered_city_options.push({"label": e, "value": e})
            })
            this.setState({
                rowData: response.data.rowdata,
                city_options: city_options,
                filtered_city_options: filtered_city_options,
                overly: "show-n",
                loadshow: "show-n"
            })
            this.renderMap()
        })
    }
    renderMap = () => {
		loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyBQUmk7hdajZEAYllxKhVPnUx5pXuK-j_Y&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
    }

    initMap = () => {
        // console.log("here slidebar")
        // $("#mapweather").empty();
        
        var bounds = new window.google.maps.LatLngBounds();
        var lat=28.4519751;
        var long=77.0310713;
        if(this.state.rowData.length)
        {
            lat = this.state.rowData[0]['latitude']   
            long = this.state.rowData[0]['longitude']   
        }
        console.log(lat, long, "Latitude & Loong")
        map = new window.google.maps.Map(document.getElementById('map'), {
                zoom: 8,
                center: new window.google.maps.LatLng(lat,long),
                mapTypeId: window.google.maps.MapTypeId.ROADMAP,
                mapTypeControl: true,
                mapTypeControlOptions: {
                    style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                    position: window.google.maps.ControlPosition.TOP_RIGHT
                },            
                disableDefaultUI: true,
                fullscreenControl: true,
                zoomControl: true
        });
        if(this.state.rowData.length)
        {
            this.state.rowData.map(function(e,index)
            {
                var marker = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(e.latitude, e.longitude),
                    map: map,
                    content: e,
                    icon: require("../../assets/icons/gas-station.png"),
                });
                var infowindow = new window.google.maps.InfoWindow();
                window.google.maps.event.addListener(marker, 'click', (function(marker) {
                    return function() {
                        var contentarr = []
                    var header = marker.content.city
                    contentarr.push({"key":"Service Provider ", "value": marker.content.service_provider})
                    contentarr.push({"key":"Diesel Price", "value": "Rs. "+marker.content.diesel_price.toString()})
                    contentarr.push({"key":"Change", "value": "Rs. "+marker.content.change_in_price.toString()})
                    
                    var contentString = infoBox("", header, contentarr,'')
                    
                    
                    infowindow.setContent(contentString);
                    var currentwindow = infowindow;
                    infowindow.open(map, marker);
                    //console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
                    }
                })(marker));
                bounds.extend(new window.google.maps.LatLng(e.latitude, e.longitude));
            });
            map.fitBounds(bounds)
        }
    }

    logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
    }

    handlerStartDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
        var mm = (d.getMonth() +1)
        if(mm <10)
        {
            var month = "0"+mm
        }
        else{
            var month = mm
        }
         
    	var startdate = d.getFullYear()+"-"+month+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
    	this.setState({
    		startDate:startdate
    	});
    	//let value = event.target.value;
        //this.setState({'startDate':value});
    }
    handlerEndDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
        var mm = (d.getMonth() +1)
        if(mm <10)
        {
            var month = "0"+mm
        }
        else{
            var month = mm
        }
         
    	var startdate = d.getFullYear()+"-"+month+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
    	this.setState({
    		endDate:startdate
    	});
    	//let value = event.target.value;
        //this.setState({'startDate':value});
    }

    showCitySlider(){
        this.setState({
            sliderCity: "slider-translate-30p",
            overly: "show-m"
        })
    }
    checkIsNumber(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }
     
    onSubmitNewCity(e){
        e.preventDefault();
        if(this.state.city_name != "")
        {
            var formdata = {
                city: this.state.city_name.charAt(0).toUpperCase() + this.state.city_name.slice(1),
                latitude: this.state.edited_latitude,
                longitude: this.state.edited_longitude
            }
            console.log(formdata, "formdata")
            redirectURL.post("/dashboard/saveNewCity",formdata).then((response)=>{
                this.setState({
                    basicTitle: "Successfully added new city",
                    basicType: "success",
                    show: true
                })
            })
        }
        else
        {
            this.setState({
                basicTitle: "Please select atleast one city",
                basicType: "warning",
                show: true
            })
        }
        
    }
    
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;

		
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
		   
    }

    
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
            sliderCity: ""
		});
		
    }
    
    closeAlert = () => {
        this.setState({
            show: false
        });
        window.location.reload();
    }
    onCloseRouteDiv(){
        this.setState({
            sliderCity: "",
            overly: "show-n"
        })
    }
    getFilteredData(e){
        e.preventDefault();
        this.setState({
            overly: "show-m",
            loadshow: "show-m"
        })
        this.getDieselPrices(this.state.startDate, this.state.endDate)
    }

    render(){

        var columnwithDefs = [
            {
                headerName:"Updated On",
                field:"updated_on",
                width:120,
                valueGetter: function(params){
                    return getHyphenDDMMYYYY(params.data.updated_on)
                }
            },
            {
                headerName:"City",
                field:"city",
                width:130
            },
            // {
            //     headerName:"Latitude",
            //     field:"latitude",
            //     width:130
            // },
            // {
            //     headerName:"Longitude",
            //     field:"longitude",
            //     width:130
            // },
            {
                headerName:"Service Provider",
                field:"service_provider",
                width:170
            },
            {
                headerName:"Diesel Price",
                field:"diesel_price",
                width:130,
                cellRenderer: (params)=>{
                    return "<i class='fa fa-inr'></i> "+params.data.diesel_price.toString()
                }
            },
            {
                headerName:"Change",
                field:"change_in_price",
                width:130,
                cellRenderer: (params)=>{
                    if(params.data.change_status == 1)
                    {
                        return "<i class='fa fa-inr'></i> "+params.data.change_in_price.toString() +" <i class='fa fa-arrow-up' style='color:green'></i>"
                    }
                    else if(params.data.change_status == -1)
                    {
                        return "<i class='fa fa-inr'></i> "+params.data.change_in_price.toString() +" <i class='fa fa-arrow-down' style='color:red'></i>"
                    }
                    else
                    {
                        return "<i class='fa fa-inr'></i> "+params.data.change_in_price.toString()

                    }
                }
            },
        ]
        
        return ( 
        		<div className="row pt-20px">
                    <SweetAlert
                        show={this.state.show}
                        type={this.state.basicType}
                        title={this.state.basicTitle}
                        onConfirm={this.closeAlert}
                    >
                    </SweetAlert>
                    <form onSubmit={this.getFilteredData.bind(this)} className="row col-xl-12 col-lg-12">                      
                        <div className="form-group col-xl-2 col-lg-2">
                            <label>From Date: </label>
                            <Datetime value={this.state.startDate} 
                            disableCloseOnClickOutside={false} 
                            closeOnSelect={true} 
                            inputProps={{ placeholder: 'Start Date', name: 'startDate', autoComplete:'off' }}
                            dateFormat="YYYY-MM-DD" 
                            name="startDate" 
                            onChange={this.handlerStartDateTime.bind(this)} />
                        </div>
                        <div className="form-group col-xl-2 col-lg-2">
                            <label>To Date: </label>
                            <Datetime value={this.state.endDate} 
                            disableCloseOnClickOutside={false} 
                            closeOnSelect={true} 
                            inputProps={{ placeholder: 'To Date', autoComplete:'off' }}
                            dateFormat="YYYY-MM-DD" 
                            onChange={this.handlerEndDateTime.bind(this)} />
                        </div>
                        <div className="form-group col-xl-2 col-lg-2">
                            <label>Select City: </label>
                            <Select 
                                //  isMulti={true}
                                value={this.state.filtered_city_name}
                                closeMenuOnSelect={true} 
                                className="border-radius-0" 
                                onChange={(e)=>{this.setState({filtered_city_name: e})}} 
                                style={{borderRadius:"0px"}} 
                                options={this.state.filtered_city_options} 
                                placeholder={"Select City"}
                            />
                        </div>
                        <div className="form-group col-xl-2 col-lg-2">
                            <label>&nbsp;</label><br />
                            <button type="submit" className="btn btn-success">Submit</button>
                        </div>
                    </form>
                               
                    <div className="row col-lg-12 col-sm-12 col-md-12 col-xl-12 card">
                        <div className="card-header col-lg-12 col-sm-12 col-md-12 col-xl-12">
                            <h5 className="float-left"><i className="fa fa-inr"></i> Diesel Prices</h5>
                            <button className="btn btn-success float-right" onClick={this.showCitySlider.bind(this)}>Add New City</button>
                        </div>
                        <div className="card-body col-lg-12 col-sm-12 col-md-12 col-xl-12">
                            <div id="myGrid" style={{width:"100%",height:"460px",float: "left",}}
                            className="ag-theme-balham col-xl-6 col-lg-6 col-md-6 col-sm-6" >    
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={columnwithDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.rowData}
                                    // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                    enableCharts={true}
                                    enableRangeSelection={true}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                    suppressRowClickSelection={true}
                                />
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6" id="map" style={{height:"460px", boxShadow:"1px 5px 10px 8px #ddd"}}></div>
                        </div>
                        
                    </div>
                    {this.state.sliderCity != ''?
                        <div className={"slide-r "+(this.state.sliderCity)} style={{overflow:"auto"}}>
                            <div className="slide-r-title">
                                <h4>
                                    Add New Locations
                                    <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
                                            
                                </h4>
                            </div>
                            <div className="slide-r-body" style={{position:"relative"}}>
                                
                                <form className="row col-xl-12 col-lg-12 ml-0p n-p-0" onSubmit={this.onSubmitNewCity.bind(this)}>
                                    <div className="form-group mt-20p col-xl-12 col-lg-12">
                                        <label>Select City: </label>
                                        <Select 
                                          //  isMulti={true}
                                            closeMenuOnSelect={true} 
                                            className="border-radius-0" 
                                            onChange={(e)=>{this.setState({city_name: e.value})}} 
                                            style={{borderRadius:"0px"}} 
                                            options={this.state.city_options} 
                                            placeholder={"Select City"}
                                        />
                                    </div>
                                    <div className="form-group mt-20p col-xl-12 col-lg-12">
                                        <label className="">Latitude</label> 
                                        <input type="text" onChange={(e)=>{
                                            var edited_value = e.target.value.replace(/\s/g, "").replace(/[a-zA-Z]/g,"");
                                            this.setState({"edited_latitude": edited_value})
                                        }}  className="form-control" required  />
                                    </div>
                                    <div className="form-group mt-20p col-xl-12 col-lg-12">
                                        <label className="">Longitude</label> 
                                        <input type="text" onChange={(e)=>{
                                            var edited_value = e.target.value.replace(/\s/g, "").replace(/[a-zA-Z]/g,"");
                                            this.setState({"edited_longitude": edited_value})
                                            
                                        }}  className="form-control" required  />
                                    </div>
                                    <div className="form-group mt-20p col-xl-12 col-lg-12">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                    </div>
                                </form>
                            </div>
                            
                        </div>
                    :""	
                    }
                   
                    <div className={"dataLoadpage " +(this.state.loadshow)}></div>
                    <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                        <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                    </div>
                    <div className={"overlay "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
                </div>

			);
		}
}

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}


function GetSortOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 


$(document).ready(function(){
    
    $(".loadclick").click(function(){
        var id = $(this).attr("id");
        $(".loadclick").removeClass("active");
        $("#"+id).addClass("active");
    })
})



function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
        // console.log(date1,date2);
    var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
    date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
    var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
    date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
    // console.log(date1,date2);
    var date1Number = monthToComparableNumber(date1);
    var date2Number = monthToComparableNumber(date2);
    // console.log(date1Number,date2Number);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
    }
  }
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName){
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
        ];
    var monthNum = months.indexOf(monthName)+1;
    if(monthNum < 10){
        monthNum = "0"+monthNum.toString();
    }
    return monthNum;
}

function arrayMin(arr) {
	return arr.reduce(function (p, v) {
	  return ( p < v ? p : v );
	});
  }
  
function arrayMax(arr) {
return arr.reduce(function (p, v) {
    return ( p > v ? p : v );
});
}

function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}



