import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import _ from "lodash";
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";import { getDDMMYYYY, 
	getDDMMYYYYHHMMSS, 
	getYYYYMMDD, 
	getYYYYMMDDHHMMSS, 
	getHyphenYYYYMMDDHHMMSS, 
    getHyphenYYYYMMDD, 
    getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,
	getHyphenDDMMYYYYHHMMSS, 
	getHyphenDDMMYYYY } from '../common/utils';

import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import Select from 'react-select';
import $ from 'jquery';
// import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';

import TransitLineChart from './transitdashboardlinechart';
import ColumnGroupTransit from './columngrouptransitchart';
import LineLoadingDashboard from './linedailyloading';
var googleAnalytics = require("../common/googleanalytics");
var redirectURL = require('../redirectURL');

export default class TransitData extends Component {

    constructor(props) {
        super(props);
        this.state={
            modules:AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
                enablePivot: true,
                pivot : true,
                // enableValue: true,
                enableRowGroup: true,
            },
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
            rowData: [],
            rowDataExcluded:[],
            paginationPageSize:50,
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            locations:[],
            transporters:[],
            transporter:{value:"all",label:"All"},
            location:{value:"all",label:"All"},
            dept_code:'',
            departmentarray:[],
            scatterdepartmentarray:[],
            categories:[],
            scattercategories:[],
            transporterdata:[],
            transdrilldown:[],
            transportergpsdata:[],
            transgpsdrilldown:[],
            showtranschart:'show-m',
            showgpschart:'show-n',
            loadcheck:true,
            gpsloadcheck:false,
            loadshow:'show-n',
            overly:'show-n',
            tripclosureallsummary:[],
            tripclosureprimarysummary:[],
            tripclosuresecondsummary:[],
            tripcategories:[],
            tripfcclosureallsummary:[],
            tripfcclosureprimarysummary:[],
            tripfcclosuresecondsummary:[],
            tripfccategories:[],
            monthlydataaall:[],
            monthlyprimarydata:[],
            monthlysecondarydata:[],
            shownormalprimary:'show-n',
            shownormalall:'show-m',
            shownormalsecond:'show-n',
            showfcprimary:'show-n',
            showfcsecond:'show-n',
            showfcall:'show-m',
            pchecked:false,
            schecked:false,
            achecked:true,
            afcchecked:false,
            sfcchecked:false,
            afcchecked:true,
            monthlyfcprimarydata:[],
            monthlyfcsecondarydata:[],
            monthlyfcalldata:[],
            dept_code:'',
            defaultsdate:'',
            defaultedate:'',
            usergridstate:[],
            screenurl:"",
            screentitle:"",
            pagetitle:"Transits Analytics",
            eventGridAction: "gridAction",
            
        }
        this.cipher = this.cipher.bind(this);
    }
    cipher = (salt) => {
        const textToChars = text => text.split('').map(c => c.charCodeAt(0));
        const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
        const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
        try{
            return text => text.split('')
                .map(textToChars)
                .map(applySaltToChar)
                .map(byteHex)
                .join('');
        }
        catch(e){
    
        }
        
    }
    componentWillReceiveProps(newprops){
        //console.log("newprops.dept_code ", newprops.dept_code)
        if(newprops.dept_code != this.state.dept_code)
        {
            this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});


            this.setState({
                loadshow:'show-m',
                overly:'show-m',
                transporter:{"value": "all", "label":'All'},
                location:{"value": "all", "label":'All'}
            });
            //console.log("newprops asd ",newprops)
            var edate = newprops.endDate;
            var sdate = newprops.startDate;
            if(newprops.dept_code == 'all')
            {
                var dept_code="SNDG";
            
            }
            else{
                var dept_code=newprops.dept_code;
            
            }
            var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
            //var locations=newprops.locations;
            //var transporters=newprops.transporters;
            var preqparams = {
                dept_code:encode(dept_code)
            }
            this.setState({
                dept_code:dept_code,
                startDate:sdate,
                endDate:edate,
                defaultsdate:sdate,
                defaultedate:edate,
                locations:[],
                transporters:[]
            })
            var preqparams = {
                dept_code:encode(dept_code)
            }
            redirectURL.post("/consignments/plants",preqparams)    
            .then((response) => {
              var records = response.data;
              //console.log("records ", records)
              var locations = [];
              if(records.length >0)
              {
                  records.map((item) => {
                    locations.push({
                        code:item.plant_code,
                        label:item.plant_name,
                    })
                  })
              }
              this.setState({
                  locations:locations
              })
            })
            .catch(function(e){
                console.log("Error ",e)
            })
    
            redirectURL.post("/masters/transportersbydept",{
                deptcode:encode(dept_code)
            })    
            .then((response) => {
              var records = response.data;
              var transporters = [];
              if(records.length >0)
              {
                  records.map((item) => {
                    transporters.push({
                        code:item.transporter_code,
                        label:item.transporter_name,
                    })
                  })
              }
              this.setState({
                transporters:transporters
              })
            })
            .catch(function(e){
                console.log("Error ",e)
            });
    
            //getPlantWiseLoadingDelayAnalysis
            //console.log(" this.state.transporter ",this.state.transporter)
            redirectURL.post("/dashboard/transitdashboarddata",{
                dept_code:encode(dept_code),
                startDate:sdate,
                endDate:edate,
                transporter:[{"value": "all", "label":'All'}],
                location:[{"value": "all", "label":'All'}]
            })
            .then((response) => {
               var all_trips_closure_count = response.data.all_trips_closure_count;
               var fc_reason_count =  response.data.fc_reason_count;
               var tripclosuresummarydata = JSON.parse(response.data.trip_type_closure_counts)
               var monthlytripclosuresummary = JSON.parse(response.data.monthly_trip_type_closure_counts)
               var tripfcclosuresummary = JSON.parse(response.data.trip_type_fc_reason_counts)
               var monthlytripfcclosuresummary = JSON.parse(response.data.monthly_trip_type_fc_reason_counts)
               

               var monthcategories = [];
                var monthlydataarr = [];
                var monthlydataaallarr = [];
                var monthlydatagroup = groupBy(monthlytripclosuresummary, rdata => rdata.trip_closure_type);
                var monthlydatasort = monthlytripclosuresummary.sort(GetSortOrder("invoice_gate_out_month_order"));
                var mncolgroups = groupBy(monthlydatasort, rdata => (rdata.invoice_gate_out_month+" "+rdata.invoice_gate_out_year));
               
                var alltypeclosures = []
               monthlydatagroup.forEach((item, key) =>{
                  
                    var itemdata = item.sort(GetSortOrder("invoice_gate_out_month_order"));
                    itemdata.map((val) => { 
                        alltypeclosures.push(val.trip_closure_type)
                    })
                })
                var uniquetypes = alltypeclosures.filter( function(value, index, self) { 
                    return self.indexOf(value) === index;
                } );

                //console.log("alltypeclosures ", uniquetypes)
                var existsmonths = []
                mncolgroups.forEach((mon,ky) => {
                    existsmonths.push(ky)
                })
                //console.log("existsmonths ", existsmonths)
                var monthgrparr=[];
                var monthgrpallarr = [];
                var monthgrpgpsarr = [];
                var monthlygpsdataarr=[]
                monthlydatagroup.forEach((item, key) =>{
                    var monthloadallarr = [];
                    var monthloadarr = [];
                    var monthgpsloadarr = [];
                    var itemdata = item.sort(GetSortOrder("invoice_gate_out_month_order"));
                    //console.log("ITEM ", item)
                    if(item.length == existsmonths.length)
                    {
                        itemdata.map((val) => {   
                            //console.log("VAL ",val); 
                            var yr = val.invoice_gate_out_month+" "+val.invoice_gate_out_year;
                            
                                if(key == val.trip_closure_type)
                                {
                                   // console.log("mncolgroups ",mncolgroups)
                                   monthloadallarr.push(val.All)
                                    monthloadarr.push(val.Primary)
                                    monthgpsloadarr.push(val.Secondary)
                                }
                           // })
                            
                        });
                        monthgrpallarr.push({
                            name:key,
                            data:monthloadallarr
                        })
                        monthgrparr.push({
                            name:key,
                            data:monthloadarr
                        })
                        monthgrpgpsarr.push({
                            name:key,
                            data:monthgpsloadarr
                        })
                    }
                    else{
                        existsmonths.forEach((mn, ky)=>{
                            //console.log(ky)
                            itemdata.map((val) => { 
                                var yr = val.invoice_gate_out_month+" "+val.invoice_gate_out_year;
                                
                                if(mn == yr)
                                {
                                    monthloadallarr.push(val.All)
                                    monthloadarr.push(val.Primary)
                                    monthgpsloadarr.push(val.Secondary)
                                }
                                else{
                                    monthloadarr.push(0)
                                    monthgpsloadarr.push(0)
                                }
                            })
                        })
                        
                        monthgrpallarr.push({
                            name:key,
                            data:monthloadallarr
                        })
                        monthgrparr.push({
                            name:key,
                            data:monthloadarr
                        })
                        monthgrpgpsarr.push({
                            name:key,
                            data:monthgpsloadarr
                        })
                    }
                })
               // mncolgroups.sort(GetSortOrder("month_order"));
               //console.log("mncolgroups ",mncolgroups)
                mncolgroups.forEach((it,ky) =>{
                    monthcategories.push(ky)
                })
                monthlydataaallarr.push({
                    categories:monthcategories,
                    lineseries:monthgrpallarr
                })
                monthlydataarr.push({
                    categories:monthcategories,
                    lineseries:monthgrparr
                })
                monthlygpsdataarr.push({
                    categories:monthcategories,
                    lineseries:monthgrpgpsarr
                })
               // console.log("monthlydataarr ", monthlydataarr)


                /*Monthly Force Closuer Chart*/
                var fcmonthcategories = [];
                var fcmonthlydataarr = [];
                var fcmonthlydatagroup = groupBy(monthlytripfcclosuresummary, rdata => rdata.force_closure_reason);
                var fcmonthlydatasort = monthlytripfcclosuresummary.sort(GetSortOrder("invoice_gate_out_month_order"));
                var fcmncolgroups = groupBy(fcmonthlydatasort, rdata => (rdata.invoice_gate_out_month+" "+rdata.invoice_gate_out_year));
               
                var fcalltypeclosures = []
                fcmonthlydatagroup.forEach((item, key) =>{
                  
                    var itemdata = item.sort(GetSortOrder("invoice_gate_out_month_order"));
                    itemdata.map((val) => { 
                        fcalltypeclosures.push(val.force_closure_reason)
                    })
                })
                var fcuniquetypes = fcalltypeclosures.filter( function(value, index, self) { 
                    return self.indexOf(value) === index;
                } );

                //console.log("alltypeclosures ", uniquetypes)
                var fcexistsmonths = []
                fcmncolgroups.forEach((mon,ky) => {
                    fcexistsmonths.push(ky)
                })
                //console.log("existsmonths ", existsmonths)
                var fcmonthgrparr=[];
                var fcmonthgrpallarr = []
                var fcmonthgrpgpsarr = [];
                var fcmonthlydataallarr = [];
                var fcmonthlydataarr = []
                var fcmonthlygpsdataarr=[]
                fcmonthlydatagroup.forEach((item, key) =>{
                    var fcmonthloadarr = [];
                    var fcmonthloadallarr=[];
                    var fcmonthgpsloadarr = [];
                    var itemdata = item.sort(GetSortOrder("invoice_gate_out_month_order"));
                    //console.log("ITEM ", item)
                    if(item.length == fcexistsmonths.length)
                    {
                        itemdata.map((val) => {   
                            //console.log("VAL ",val); 
                            var yr = val.invoice_gate_out_month+" "+val.invoice_gate_out_year;
                            
                                if(key == val.force_closure_reason)
                                {
                                   // console.log("mncolgroups ",mncolgroups)
                                    
                                   fcmonthloadallarr.push(val.All)
                                   fcmonthloadarr.push(val.Primary)
                                   fcmonthgpsloadarr.push(val.Secondary)
                                }
                           // })
                            
                        });
                        fcmonthgrpallarr.push({
                            name:key,
                            data:fcmonthloadallarr
                        })
                        fcmonthgrparr.push({
                            name:key,
                            data:fcmonthloadarr
                        })
                        fcmonthgrpgpsarr.push({
                            name:key,
                            data:fcmonthgpsloadarr
                        })
                    }
                    else{
                        fcexistsmonths.forEach((mn, ky)=>{
                           // console.log(ky)
                            itemdata.map((val) => { 
                                var yr = val.invoice_gate_out_month+" "+val.invoice_gate_out_year;
                                
                                if(mn == yr)
                                {
                                    fcmonthloadallarr.push(val.All)
                                    fcmonthloadarr.push(val.Primary)
                                    fcmonthgpsloadarr.push(val.Secondary)
                                }
                                // else{
                                //     fcmonthloadallarr.push(0)
                                //     fcmonthloadarr.push(0)
                                //     fcmonthgpsloadarr.push(0)
                                // }
                            })
                        })
                        
                        
                        fcmonthgrpallarr.push({
                            name:key,
                            data:fcmonthloadallarr
                        })
                        fcmonthgrparr.push({
                            name:key,
                            data:fcmonthloadarr
                        })
                        fcmonthgrpgpsarr.push({
                            name:key,
                            data:fcmonthgpsloadarr
                        })
                    }
                })
               // mncolgroups.sort(GetSortOrder("month_order"));
               //console.log("mncolgroups ",mncolgroups)
               fcmncolgroups.forEach((it,ky) =>{
                fcmonthcategories.push(ky)
                })
                fcmonthlydataallarr.push({
                    categories:fcmonthcategories,
                    lineseries:fcmonthgrpallarr
                })
                fcmonthlydataarr.push({
                    categories:fcmonthcategories,
                    lineseries:fcmonthgrparr
                })
                fcmonthlygpsdataarr.push({
                    categories:fcmonthcategories,
                    lineseries:fcmonthgrpgpsarr
                })
                console.log("PUBLIC_URL ",redirectURL.get())
                console.log("fcmonthlygpsdataarr ", fcmonthlygpsdataarr)

                var tripclosureallsummary = []
                var tripclosuresecondsummary = []
                var tripclosureprimarysummary = []
                var tripclosuresummarydataarr=[];
                var categories=[]
                var primaryarr= []
                var secondarr=[]
                var totalallrr=0
                var totalprimaryrr=0
                var totalsecondrr=0
                var allarr=[]
                
                categories.push("Total");
                primaryarr.push(0)
                allarr.push(0)
                secondarr.push(0)
              //var tripclosuresummary = groupBy(tripclosuresummarydata, rdata => rdata.trip_closure_type);
              tripclosuresummarydata.forEach((item, key) =>{
                    //console.log("item ", item)
                    categories.push(item.trip_closure_type)
                //    if(item.Primary > 0)
                //    {
                //        primary.push(item.Primary);
                //    }
                //    if(item.Secondary > 0)
                //    {
                //        second.push(item.Secondary);
                //    }
                    totalallrr=totalallrr+parseInt(item.All)
                    totalprimaryrr=totalprimaryrr+parseInt(item.Primary)
                    totalsecondrr=totalsecondrr+parseInt(item.Secondary)
                    primaryarr.push(item.Primary)

                    secondarr.push(item.Secondary)
                    allarr.push(item.All)
                    
                    //totalrr.push(item.Primary+item.Secondary)
                    // tripclosuresummarydataarr.push({
                    //     name:item.trip_closure_type,
                    //     data:[item.Primary,item.Secondary]
                    // })
                })
               // console.log('tripclosuresummarydataarr ', tripclosuresummarydataarr)
                //console.log("categories ", categories)
                //if(this.state.achecked == true)
                //{
                    tripclosureallsummary.push({
                        series:[{
                            name:"Total",
                            data:[totalallrr]
                        },{
                            name:"All",
                            data:allarr
                        }],
                        categories:categories
                    })
                //}
                
                //if(this.state.pchecked == true)
                //{
                    tripclosureprimarysummary.push({
                        series:[{
                            name:"Total",
                            data:[totalprimaryrr]
                        },{
                            name:"Primary",
                            data:primaryarr
                        }
                        ],
                        categories:categories
                    })
                //}
                
                //if(this.state.schecked == true)
               // {
                    tripclosuresecondsummary.push({
                        series:[{
                            name:"Total",
                            data:[totalsecondrr]
                        },{
                            name:"Secondary",
                            data:secondarr
                        }
                        ],
                        categories:categories
                    })
               // }
                
                /*Trip Type Force Close Reasons */
                 
                var tripfcclosureallsummary = []
                var tripfcclosureprimarysummary = []
                var tripfcclosuresecondsummary = []

                var tripfcclosuresummarydataarr=[];
                var fccategories=[]
                var fcallarr=[]
                var fcprimaryarr= []
                var fcsecondarr=[]
                var fctotl=[]
                var fctotalallrr=0
                var fctotalprimaryrr=0
                var fctotalsecondrr=0
                fcallarr.push(0)
                fcprimaryarr.push(0)
                fcsecondarr.push(0)
                fccategories.push("Total");
              //var tripclosuresummary = groupBy(tripclosuresummarydata, rdata => rdata.trip_closure_type);
              tripfcclosuresummary.forEach((item, key) =>{
                    //console.log("item ", item)
                    fccategories.push(item.force_closure_reason)


                        fctotalallrr=fctotalallrr+parseInt(item.All)
                        fctotalprimaryrr=fctotalprimaryrr+parseInt(item.Primary)
                        fctotalsecondrr=fctotalsecondrr+parseInt(item.Secondary)
                        fcallarr.push(item.All)
                        fcprimaryarr.push(item.Primary)

                        fcsecondarr.push(item.Secondary)
                        fctotl.push(item.Primary+item.Secondary)
                     tripfcclosuresummarydataarr.push({
                        name:item.trip_closure_type,
                        data:[item.Primary,item.Secondary]
                    })
                })
               // console.log('tripclosuresummarydataarr ', tripclosuresummarydataarr)
                //console.log("categories ", categories)
                
                
                    tripfcclosureallsummary.push({
                        series:[{
                            name:"Total",
                            data:[fctotalallrr]
                        },{
                            name:"All",
                            data:fcallarr
                        }],
                        categories:fccategories
                    })
                
                
                
                    tripfcclosureprimarysummary.push({
                        series:[{
                            name:"Total",
                            data:[fctotalprimaryrr]
                        },{
                            name:"Primary",
                            data:fcprimaryarr
                        }
                        ],
                        categories:fccategories
                    })
                
                
                
                    tripfcclosuresecondsummary.push({
                        series:[{
                            name:"Total",
                            data:[fctotalsecondrr]
                        },{
                            name:"Secondary",
                            data:fcsecondarr
                        }
                        ],
                        categories:fccategories
                    })
            

                // tripfcclosuresummarydata.push({
                //     series:[{
                //         name:"Total",
                //         data:[fc_reason_count]
                //     },{
                //         name:"Primary",
                //         data:fcprimaryarr
                //     },{
                //         name:"Secondary",
                //         data:fcsecondarr
                //     }
                    
                //     ],
                //     categories:categories
                // })
               
               this.setState({
                tripclosureallsummary:tripclosureallsummary,
                tripclosureprimarysummary:tripclosureprimarysummary,
                tripclosuresecondsummary:tripclosuresecondsummary,
                //tripfcclosuresummary:tripfcclosuresummarydata,
                tripfcclosureallsummary:tripfcclosureallsummary,
                tripfcclosureprimarysummary:tripfcclosureprimarysummary,
                tripfcclosuresecondsummary:tripfcclosuresecondsummary,
                //tripcategories:tripcategories,
                //tripfccategories:tripfccategories,
                monthlydataaall:monthlydataaallarr,
                monthlyprimarydata:monthlydataarr,
                monthlysecondarydata:monthlygpsdataarr,
                monthlyfcalldata:fcmonthlydataallarr,
                monthlyfcprimarydata:fcmonthlydataarr,
                monthlyfcsecondarydata:fcmonthlygpsdataarr,
                loadshow:'show-n',
                overly:'show-n',
               });
            })
            .catch(function(e){
                console.log("Error ",e)
            })
    
        }
        
    }


	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
    }

    onSelectLocation= location =>{
		this.setState(
			{ location },
			() => console.log(`Location Option selected:`, this.state.location)
		  );
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
    onSelectTransporter= transporter =>{
		this.setState(
			{ transporter },
			() => console.log(`Transporter Option selected:`, this.state.transporter)
		  );
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    locationoptions(){
        var items=[];
       // console.log("locations ",this.props.locations)
        var locations = this.state.locations;
        //console.log("locations ",locations)
        if(locations.length > 0){
            items.push({value:'all',label:'All'}); 
            locations.forEach(item =>{
                items.push({value:item.code,label:item.label}); 
            });
        }
        //console.log("Locas ", items)
        return items;
    }
    transporteroptions(){
        var items=[]
        var transporters = this.state.transporters;
        items.push({value:'all',label:'All'}); 
        if(transporters.length > 0){
            transporters.forEach(item=>{
                items.push({value:item.code,label:item.label}); 
            })
        }
        //console.log("Transps ", items)
        return items;
    }
    handlerStartDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
        var mm = (d.getMonth() +1)
        if(mm <10)
        {
            var month = "0"+mm
        }
        else{
            var month = mm
        }
         
    	var startdate = d.getFullYear()+"-"+month+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
    	this.setState({
    		startDate:startdate
    	});
    	//let value = event.target.value;
        //this.setState({'startDate':value});
    }
	
    handlerEndDateTime  = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
        var mm = (d.getMonth() +1)
        if(mm <10)
        {
            var month = "0"+mm
        }
        else{
            var month = mm
        }
         
    	var edate = d.getFullYear()+"-"+month+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
        this.setState({
    		endDate:edate
    	});
    }
    onClickGetLoadingData(event){
        event.preventDefault();
        if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Filters",
			}
			googleAnalytics.logEvent(eventOptions);
		}
        this.setState({
            loadshow:'show-m',
            overly:"show-m"
        })
        if(this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN")
        {
            var startDate = this.state.defaultsdate;
        }
        else{
            var startDate = this.state.startDate;
        }
        if(this.state.endDate == '' || this.state.endDate == "NaN-NaN-NaN")
        {
            var endDate = this.state.defaultedate;
        }
        else{
            var endDate = this.state.endDate;
        } 
        if(this.state.location == '' || this.state.location == null)
        {
            var location = [{"value": "all", "label": "All"}];
        }
        else
        {
            var location = this.state.location;
        } 
        if(this.state.transporter == '' || this.state.transporter == null)
        {
            var transporter = [{"value": "all", "label": "All"}];
        }
        else
        {
            var transporter = this.state.transporter;
        }
        
        if(Array.isArray(location) == true)
        {
            var locations = location
        }
        else{
            var locations = [location]
        }
        if(Array.isArray(transporter) == true)
        {
            var transporters = transporter
        }
        else{
            var transporters = [transporter]
        }
        var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
        var parms = {
            startDate:startDate,
            endDate:endDate,
            location:locations,
            transporter:transporters,
            dept_code:encode(this.state.dept_code)
        }
        
        
        redirectURL.post("/dashboard/transitdashboarddata",parms)
        .then((response) => {
            var all_trips_closure_count = response.data.all_trips_closure_count;
               var fc_reason_count =  response.data.fc_reason_count;
               var tripclosuresummarydata = JSON.parse(response.data.trip_type_closure_counts)
               var monthlytripclosuresummary = JSON.parse(response.data.monthly_trip_type_closure_counts)
               var tripfcclosuresummary = JSON.parse(response.data.trip_type_fc_reason_counts)
               var monthlytripfcclosuresummary = JSON.parse(response.data.monthly_trip_type_fc_reason_counts)
               

               var monthcategories = [];
                var monthlydataarr = [];
                var monthlydataaallarr = [];
                var monthlydatagroup = groupBy(monthlytripclosuresummary, rdata => rdata.trip_closure_type);
                var monthlydatasort = monthlytripclosuresummary.sort(GetSortOrder("invoice_gate_out_month_order"));
                var mncolgroups = groupBy(monthlydatasort, rdata => (rdata.invoice_gate_out_month+" "+rdata.invoice_gate_out_year));
               
                var alltypeclosures = []
               monthlydatagroup.forEach((item, key) =>{
                  
                    var itemdata = item.sort(GetSortOrder("invoice_gate_out_month_order"));
                    itemdata.map((val) => { 
                        alltypeclosures.push(val.trip_closure_type)
                    })
                })
                var uniquetypes = alltypeclosures.filter( function(value, index, self) { 
                    return self.indexOf(value) === index;
                } );

                //console.log("alltypeclosures ", uniquetypes)
                var existsmonths = []
                mncolgroups.forEach((mon,ky) => {
                    existsmonths.push(ky)
                })
                //console.log("existsmonths ", existsmonths)
                var monthgrparr=[];
                var monthgrpallarr = [];
                var monthgrpgpsarr = [];
                var monthlygpsdataarr=[]
                monthlydatagroup.forEach((item, key) =>{
                    var monthloadallarr = [];
                    var monthloadarr = [];
                    var monthgpsloadarr = [];
                    var itemdata = item.sort(GetSortOrder("invoice_gate_out_month_order"));
                    //console.log("ITEM ", item)
                    if(item.length == existsmonths.length)
                    {
                        itemdata.map((val) => {   
                            //console.log("VAL ",val); 
                            var yr = val.invoice_gate_out_month+" "+val.invoice_gate_out_year;
                            
                                if(key == val.trip_closure_type)
                                {
                                   // console.log("mncolgroups ",mncolgroups)
                                   monthloadallarr.push(val.All)
                                    monthloadarr.push(val.Primary)
                                    monthgpsloadarr.push(val.Secondary)
                                }
                           // })
                            
                        });
                        monthgrpallarr.push({
                            name:key,
                            data:monthloadallarr
                        })
                        monthgrparr.push({
                            name:key,
                            data:monthloadarr
                        })
                        monthgrpgpsarr.push({
                            name:key,
                            data:monthgpsloadarr
                        })
                    }
                    else{
                        existsmonths.forEach((mn, ky)=>{
                            //console.log(ky)
                            itemdata.map((val) => { 
                                var yr = val.invoice_gate_out_month+" "+val.invoice_gate_out_year;
                                
                                if(mn == yr)
                                {
                                    monthloadallarr.push(val.All)
                                    monthloadarr.push(val.Primary)
                                    monthgpsloadarr.push(val.Secondary)
                                }
                                else{
                                    monthloadarr.push(0)
                                    monthgpsloadarr.push(0)
                                }
                            })
                        })
                        
                        monthgrpallarr.push({
                            name:key,
                            data:monthloadallarr
                        })
                        monthgrparr.push({
                            name:key,
                            data:monthloadarr
                        })
                        monthgrpgpsarr.push({
                            name:key,
                            data:monthgpsloadarr
                        })
                    }
                })
               // mncolgroups.sort(GetSortOrder("month_order"));
               //console.log("mncolgroups ",mncolgroups)
                mncolgroups.forEach((it,ky) =>{
                    monthcategories.push(ky)
                })
                monthlydataaallarr.push({
                    categories:monthcategories,
                    lineseries:monthgrpallarr
                })
                monthlydataarr.push({
                    categories:monthcategories,
                    lineseries:monthgrparr
                })
                monthlygpsdataarr.push({
                    categories:monthcategories,
                    lineseries:monthgrpgpsarr
                })
               // console.log("monthlydataarr ", monthlydataarr)


                /*Monthly Force Closuer Chart*/
                var fcmonthcategories = [];
                var fcmonthlydataarr = [];
                var fcmonthlydatagroup = groupBy(monthlytripfcclosuresummary, rdata => rdata.force_closure_reason);
                var fcmonthlydatasort = monthlytripfcclosuresummary.sort(GetSortOrder("invoice_gate_out_month_order"));
                var fcmncolgroups = groupBy(fcmonthlydatasort, rdata => (rdata.invoice_gate_out_month+" "+rdata.invoice_gate_out_year));
               
                var fcalltypeclosures = []
                fcmonthlydatagroup.forEach((item, key) =>{
                  
                    var itemdata = item.sort(GetSortOrder("invoice_gate_out_month_order"));
                    itemdata.map((val) => { 
                        fcalltypeclosures.push(val.force_closure_reason)
                    })
                })
                var fcuniquetypes = fcalltypeclosures.filter( function(value, index, self) { 
                    return self.indexOf(value) === index;
                } );

                //console.log("alltypeclosures ", uniquetypes)
                var fcexistsmonths = []
                fcmncolgroups.forEach((mon,ky) => {
                    fcexistsmonths.push(ky)
                })
                //console.log("existsmonths ", existsmonths)
                var fcmonthgrparr=[];
                var fcmonthgrpallarr = []
                var fcmonthgrpgpsarr = [];
                var fcmonthlydataallarr = [];
                var fcmonthlydataarr = []
                var fcmonthlygpsdataarr=[]
                fcmonthlydatagroup.forEach((item, key) =>{
                    var fcmonthloadarr = [];
                    var fcmonthloadallarr=[];
                    var fcmonthgpsloadarr = [];
                    var itemdata = item.sort(GetSortOrder("invoice_gate_out_month_order"));
                    //console.log("ITEM ", item)
                    if(item.length == fcexistsmonths.length)
                    {
                        itemdata.map((val) => {   
                            //console.log("VAL ",val); 
                            var yr = val.invoice_gate_out_month+" "+val.invoice_gate_out_year;
                            
                                if(key == val.force_closure_reason)
                                {
                                   // console.log("mncolgroups ",mncolgroups)
                                    
                                   fcmonthloadallarr.push(val.All)
                                   fcmonthloadarr.push(val.Primary)
                                   fcmonthgpsloadarr.push(val.Secondary)
                                }
                           // })
                            
                        });
                        fcmonthgrpallarr.push({
                            name:key,
                            data:fcmonthloadallarr
                        })
                        fcmonthgrparr.push({
                            name:key,
                            data:fcmonthloadarr
                        })
                        fcmonthgrpgpsarr.push({
                            name:key,
                            data:fcmonthgpsloadarr
                        })
                    }
                    else{
                        fcexistsmonths.forEach((mn, ky)=>{
                           // console.log(ky)
                            itemdata.map((val) => { 
                                var yr = val.invoice_gate_out_month+" "+val.invoice_gate_out_year;
                                
                                if(mn == yr)
                                {
                                    fcmonthloadallarr.push(val.All)
                                    fcmonthloadarr.push(val.Primary)
                                    fcmonthgpsloadarr.push(val.Secondary)
                                }
                                // else{
                                //     fcmonthloadallarr.push(0)
                                //     fcmonthloadarr.push(0)
                                //     fcmonthgpsloadarr.push(0)
                                // }
                            })
                        })
                        
                        
                        fcmonthgrpallarr.push({
                            name:key,
                            data:fcmonthloadallarr
                        })
                        fcmonthgrparr.push({
                            name:key,
                            data:fcmonthloadarr
                        })
                        fcmonthgrpgpsarr.push({
                            name:key,
                            data:fcmonthgpsloadarr
                        })
                    }
                })
               // mncolgroups.sort(GetSortOrder("month_order"));
               //console.log("mncolgroups ",mncolgroups)
               fcmncolgroups.forEach((it,ky) =>{
                fcmonthcategories.push(ky)
                })
                fcmonthlydataallarr.push({
                    categories:fcmonthcategories,
                    lineseries:fcmonthgrpallarr
                })
                fcmonthlydataarr.push({
                    categories:fcmonthcategories,
                    lineseries:fcmonthgrparr
                })
                fcmonthlygpsdataarr.push({
                    categories:fcmonthcategories,
                    lineseries:fcmonthgrpgpsarr
                })
                //console.log("tripfcclosuresummary ", tripfcclosuresummary)

                var tripclosureallsummary = []
                var tripclosuresecondsummary = []
                var tripclosureprimarysummary = []
                var tripclosuresummarydataarr=[];
                var categories=[]
                var primaryarr= []
                var secondarr=[]
                var totalallrr=0
                var totalprimaryrr=0
                var totalsecondrr=0
                var allarr=[]
                
                categories.push("Total");
                primaryarr.push(0)
                allarr.push(0)
                secondarr.push(0)
              //var tripclosuresummary = groupBy(tripclosuresummarydata, rdata => rdata.trip_closure_type);
              tripclosuresummarydata.forEach((item, key) =>{
                    //console.log("item ", item)
                    categories.push(item.trip_closure_type)
                //    if(item.Primary > 0)
                //    {
                //        primary.push(item.Primary);
                //    }
                //    if(item.Secondary > 0)
                //    {
                //        second.push(item.Secondary);
                //    }
                    totalallrr=totalallrr+parseInt(item.All)
                    totalprimaryrr=totalprimaryrr+parseInt(item.Primary)
                    totalsecondrr=totalsecondrr+parseInt(item.Secondary)
                    primaryarr.push(item.Primary)

                    secondarr.push(item.Secondary)
                    allarr.push(item.All)
                    
                    //totalrr.push(item.Primary+item.Secondary)
                    // tripclosuresummarydataarr.push({
                    //     name:item.trip_closure_type,
                    //     data:[item.Primary,item.Secondary]
                    // })
                })
               // console.log('tripclosuresummarydataarr ', tripclosuresummarydataarr)
                //console.log("categories ", categories)
                //if(this.state.achecked == true)
                //{
                    tripclosureallsummary.push({
                        series:[{
                            name:"Total",
                            data:[totalallrr]
                        },{
                            name:"All",
                            data:allarr
                        }],
                        categories:categories
                    })
                //}
                
                //if(this.state.pchecked == true)
                //{
                    tripclosureprimarysummary.push({
                        series:[{
                            name:"Total",
                            data:[totalprimaryrr]
                        },{
                            name:"Primary",
                            data:primaryarr
                        }
                        ],
                        categories:categories
                    })
                //}
                
                //if(this.state.schecked == true)
               // {
                    tripclosuresecondsummary.push({
                        series:[{
                            name:"Total",
                            data:[totalsecondrr]
                        },{
                            name:"Secondary",
                            data:secondarr
                        }
                        ],
                        categories:categories
                    })
               // }
                
                /*Trip Type Force Close Reasons */
                 
                var tripfcclosureallsummary = []
                var tripfcclosureprimarysummary = []
                var tripfcclosuresecondsummary = []

                var tripfcclosuresummarydataarr=[];
                var fccategories=[]
                var fcallarr=[]
                var fcprimaryarr= []
                var fcsecondarr=[]
                var fctotl=[]
                var fctotalallrr=0
                var fctotalprimaryrr=0
                var fctotalsecondrr=0
                fcallarr.push(0)
                fcprimaryarr.push(0)
                fcsecondarr.push(0)
                fccategories.push("Total");
              //var tripclosuresummary = groupBy(tripclosuresummarydata, rdata => rdata.trip_closure_type);
              tripfcclosuresummary.forEach((item, key) =>{
                    //console.log("item ", item)
                    fccategories.push(item.force_closure_reason)


                        fctotalallrr=fctotalallrr+parseInt(item.All)
                        fctotalprimaryrr=fctotalprimaryrr+parseInt(item.Primary)
                        fctotalsecondrr=fctotalsecondrr+parseInt(item.Secondary)
                        fcallarr.push(item.All)
                        fcprimaryarr.push(item.Primary)

                        fcsecondarr.push(item.Secondary)
                        fctotl.push(item.Primary+item.Secondary)
                     tripfcclosuresummarydataarr.push({
                        name:item.trip_closure_type,
                        data:[item.Primary,item.Secondary]
                    })
                })
               // console.log('tripclosuresummarydataarr ', tripclosuresummarydataarr)
                //console.log("categories ", categories)
                
                
                    tripfcclosureallsummary.push({
                        series:[{
                            name:"Total",
                            data:[fctotalallrr]
                        },{
                            name:"All",
                            data:fcallarr
                        }],
                        categories:fccategories
                    })
                
                
                
                    tripfcclosureprimarysummary.push({
                        series:[{
                            name:"Total",
                            data:[fctotalprimaryrr]
                        },{
                            name:"Primary",
                            data:fcprimaryarr
                        }
                        ],
                        categories:fccategories
                    })
                
                
                
                    tripfcclosuresecondsummary.push({
                        series:[{
                            name:"Total",
                            data:[fctotalsecondrr]
                        },{
                            name:"Secondary",
                            data:fcsecondarr
                        }
                        ],
                        categories:fccategories
                    })
            

                // tripfcclosuresummarydata.push({
                //     series:[{
                //         name:"Total",
                //         data:[fc_reason_count]
                //     },{
                //         name:"Primary",
                //         data:fcprimaryarr
                //     },{
                //         name:"Secondary",
                //         data:fcsecondarr
                //     }
                    
                //     ],
                //     categories:categories
                // })
               
               this.setState({
                tripclosureallsummary:tripclosureallsummary,
                tripclosureprimarysummary:tripclosureprimarysummary,
                tripclosuresecondsummary:tripclosuresecondsummary,
                //tripfcclosuresummary:tripfcclosuresummarydata,
                tripfcclosureallsummary:tripfcclosureallsummary,
                tripfcclosureprimarysummary:tripfcclosureprimarysummary,
                tripfcclosuresecondsummary:tripfcclosuresecondsummary,
                //tripcategories:tripcategories,
                //tripfccategories:tripfccategories,
                monthlydataaall:monthlydataaallarr,
                monthlyprimarydata:monthlydataarr,
                monthlysecondarydata:monthlygpsdataarr,
                monthlyfcalldata:fcmonthlydataallarr,
                monthlyfcprimarydata:fcmonthlydataarr,
                monthlyfcsecondarydata:fcmonthlygpsdataarr,
                loadshow:'show-n',
                overly:'show-n',
               });
        })
        .catch(function(e){
            console.log("Error ",e)
        })
    }

    
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	
	   //    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	   
		
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
		   
    }

    onChangeChartView(params){
        if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Change Chart View",
			}
			googleAnalytics.logEvent(eventOptions);
		}
        if(params == 'primary')
        {
            this.setState({
                pchecked:true,
                schecked:false,
                achecked:false,
                shownormalprimary:'show-m',
                shownormalsecond:'show-n',
                shownormalall:'show-n'
            })
        }
        if(params == 'second')
        {
            this.setState({
                pchecked:false,
                schecked:true,
                achecked:false,
                shownormalprimary:'show-n',
                shownormalsecond:'show-m',
                shownormalall:'show-n'
            })
        }
        if(params == 'all')
        {
            this.setState({
                pchecked:false,
                schecked:false,
                achecked:true,
                shownormalprimary:'show-n',
                shownormalsecond:'show-n',
                shownormalall:'show-m'
            })
        }
    }
    onChangeFcChartView(parmas){
        if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Change Force Closure Chart View",
			}
			googleAnalytics.logEvent(eventOptions);
		}
        if(parmas == 'primary')
        {
            this.setState({
                pfcchecked:true,
                sfcchecked:false,
                afcchecked:false,
                showfcprimary:'show-m',
                showfcsecond:'show-n',
                showfcall:'show-n'
            })
        }
        if(parmas == 'second')
        {
            this.setState({
                pfcchecked:false,
                sfcchecked:true,
                afcchecked:false,
                showfcprimary:'show-n',
                showfcsecond:'show-m',
                showfcall:'show-n'
            })
        }
        if(parmas == 'all')
        {
            this.setState({
                pfcchecked:false,
                sfcchecked:false,
                afcchecked:true,
                showfcprimary:'show-n',
                showfcsecond:'show-n',
                showfcall:'show-m'
            })
        }
    }
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
           
		});
		
	}
    render(){
       
        return ( 
        		<div className="row pt-20px tab-brd">
                    <div className="row col-xl-12 col-lg-12">                        
                        <div className="form-group col-xl-2 col-lg-2">
                            <label>Start Date: </label>
                            <Datetime value={this.state.startDate} 
                            disableCloseOnClickOutside={false} 
                            closeOnSelect={true} 
                            inputProps={{ placeholder: 'Start Date', name: 'startDate', autoComplete:'off' }}
                            dateFormat="YYYY-MM-DD" 
                            name="startDate" 
                            onChange={this.handlerStartDateTime.bind(this)} />
                        </div>
                        <div className="form-group col-xl-2 col-lg-2">
                            <label>End Date: </label>
                            <Datetime value={this.state.endDate} 
                            disableCloseOnClickOutside={false} 
                            closeOnSelect={true} 
                            inputProps={{ placeholder: 'End Date', name: 'endDate', autoComplete:'off' }}
                            dateFormat="YYYY-MM-DD" 
                            name="endDate" 
                            onChange={this.handlerEndDateTime.bind(this)} />   
                        </div>
                        <div className="form-gplantwiseloadingroup col-xl-3 col-lg-3">
                            <label>Location: </label>
                                <Select placeholder={"All"} isMulti={true} value={this.state.location} className="border-radius-0" onChange={this.onSelectLocation.bind(this)} style={{borderRadius:"0px"}} options={this.locationoptions()} required />
                        </div>
                        <div className="form-group col-xl-3 col-lg-3">
                            <label>Transporter: </label>
                                <Select placeholder={"All"} isMulti={true} value={this.state.transporter} className="border-radius-0" onChange={this.onSelectTransporter.bind(this)} style={{borderRadius:"0px"}} options={this.transporteroptions()} required />
                        </div>
                        <div className="form-group col-xl-2 col-lg-2">
                            <label>&nbsp;</label><br />
                            <button type="button" className="btn btn-success" onClick={this.onClickGetLoadingData.bind(this)}>Submit</button>
                        </div>
                    </div>
                    <div className="row col-xl-12 col-lg-12">
                        <div className={"col-xl-12 col-lg-12 mt-40p optchrt"}>
                            <input type="radio" value="all" checked={this.state.achecked} onChange={this.onChangeChartView.bind(this, "all")} /> All
                            &nbsp;&nbsp;<input type="radio" value="primary" checked={this.state.pchecked} onChange={this.onChangeChartView.bind(this, "primary")} /> Primary
                            &nbsp;&nbsp;<input type="radio" value="second" checked={this.state.schecked} onChange={this.onChangeChartView.bind(this, "second")} /> Secondary
                        </div>
                        <div className="row col-xl-12 col-lg-12 mb-40p">
                        {/* {(this.state.tripclosuresummary.length > 0 && typeof this.state.tripclosuresummary != 'undefined')? */}
                        
                            {/* //this.state.tripclosuresummary.map(item =>  */}
                                <div className={"col-xl-6 col-lg-6 "+(this.state.shownormalall)}>
                                {this.state.tripclosureallsummary.length > 0?
                    
                                    this.state.tripclosureallsummary.map(item => 
                                        <ColumnGroupTransit
                                            title={"Trip Closure Summary (All)"}
                                            subtitle={""}
                                            context={this}
                                            series={item.series}
                                            //drildown = {item.chartdrildown}
                                            categories={item.categories}
                                            xaxistitle={"Closure Type"}
                                            yaxistitle={"Consignments"}
                                            dept_code={this.state.dept_code}
                                        />    
                                        )
                                    :""}
                                </div>
                                <div className={"col-xl-6 col-lg-6 "+(this.state.shownormalprimary)}>
                                    {this.state.tripclosureprimarysummary.length > 0?
                    
                                    this.state.tripclosureprimarysummary.map(item => 
                                        <ColumnGroupTransit
                                            title={"Trip Closure Summary (Primary)"}
                                            subtitle={""}
                                            context={this}
                                            series={item.series}
                                            //drildown = {item.chartdrildown}
                                            categories={item.categories}
                                            xaxistitle={"Closure Type"}
                                            yaxistitle={"Consignments"}
                                            dept_code={this.state.dept_code}
                                        />    
                                        )
                                    :""}
                                </div>
                                <div className={"col-xl-6 col-lg-6 "+(this.state.shownormalsecond)}>
                                    {this.state.tripclosuresecondsummary.length > 0?
                                    
                                    this.state.tripclosuresecondsummary.map(item => 
                                        <ColumnGroupTransit
                                            title={"Trip Closure Summary (Secondary)"}
                                            subtitle={""}
                                            context={this}
                                            series={item.series}
                                            //drildown = {item.chartdrildown}
                                            categories={item.categories}
                                            xaxistitle={"Closure Type"}
                                            yaxistitle={"Consignments"}
                                            dept_code={this.state.dept_code}
                                        />    
                                        )
                                    :""}
                                </div>

                                <div className={"col-xl-6 col-lg-6 "}>
                                    
                                
                                    {this.state.monthlydataaall.length > 0?
                    
                                        this.state.monthlydataaall.map(item => 
                                            <div className={"col-xl-12 col-lg-12 row "+(this.state.shownormalall)}>
                                                
                                                <TransitLineChart
                                                    title={"Monthly Trip Closure Summary (All)"}
                                                    subtitle={""}
                                                    context={this}
                                                    series={item.lineseries}
                                                    categories={item.categories}
                                                    xaxistitle={"Month-Year"}
                                                    yaxistitle={"Clouser type (%)"}
                                                    dept_code={this.state.dept_code}
                                                />
                                            </div>
                                        )
                                    :""}
                                    
                                    {this.state.monthlyprimarydata.length > 0?
                    
                                        this.state.monthlyprimarydata.map(item => 
                                            <div className={"col-xl-12 col-lg-12 row "+(this.state.shownormalprimary)}>
                                                
                                                <TransitLineChart
                                                    title={"Monthly Trip Closure Summary (Primary)"}
                                                    subtitle={""}
                                                    context={this}
                                                    series={item.lineseries}
                                                    categories={item.categories}
                                                    xaxistitle={"Month-Year"}
                                                    yaxistitle={"Clouser type (%)"}
                                                    dept_code={this.state.dept_code}
                                                />
                                            </div>
                                        )
                                    :""}

                                {this.state.monthlysecondarydata.length > 0?
                                        
                                        this.state.monthlysecondarydata.map(item =>
                                            <div className={"col-xl-12 col-lg-12 row "+(this.state.shownormalsecond)}>
                                                <TransitLineChart
                                                    title={"Monthly Trip Closure Summary (Secondary)"}
                                                    subtitle={""}
                                                    context={this}
                                                    series={item.lineseries}
                                                    categories={item.categories}
                                                    xaxistitle={"Month-Year"}
                                                    yaxistitle={"Clouser type (%)"}
                                                    dept_code={this.state.dept_code}
                                                />
                                            </div>
                                            )
                                :""}
                                </div>
                                    
                        </div>
                        <div className="row col-xl-12 col-lg-12 mb-40p">
                            {/* {(this.state.tripclosuresummary.length > 0 && typeof this.state.tripclosuresummary != 'undefined')? */}
                            
                                {/* //this.state.tripclosuresummary.map(item =>  */}
                                    <div className={"col-xl-6 col-lg-6 "+(this.state.shownormalall)}>


                                    {this.state.tripfcclosureallsummary.length > 0?
                    
                                        this.state.tripfcclosureallsummary.map(item => 
                                            <ColumnGroupTransit
                                                title={"Force Closure Summary (All)"}
                                                subtitle={""}
                                                context={this}
                                                series={item.series}
                                                //drildown = {item.chartdrildown}
                                                categories={item.categories}
                                                xaxistitle={"Closure Reason"}
                                                yaxistitle={"Consignments"}
                                                dept_code={this.state.dept_code}
                                            />    
                                            )
                                        :""}
                                    </div>
                                    <div className={"col-xl-6 col-lg-6 "+(this.state.shownormalprimary)}>
                                     {this.state.tripfcclosureprimarysummary.length > 0?

                                        this.state.tripfcclosureprimarysummary.map(item => 
                                            <ColumnGroupTransit
                                                title={"Force Closure Summary (Primary)"}
                                                subtitle={""}
                                                context={this}
                                                series={item.series}
                                                //drildown = {item.chartdrildown}
                                                categories={item.categories}
                                                xaxistitle={"Closure Reason"}
                                                yaxistitle={"Consignments"}
                                                dept_code={this.state.dept_code}
                                            />    
                                            )
                                        :""}
                                    </div>
                                    <div className={"col-xl-6 col-lg-6 "+(this.state.shownormalsecond)}>
                                        {this.state.tripfcclosuresecondsummary.length > 0?
                        
                                            this.state.tripfcclosuresecondsummary.map(item => 
                                                <ColumnGroupTransit
                                                    title={"Force Closure Summary (Secondary)"}
                                                    subtitle={""}
                                                    context={this}
                                                    series={item.series}
                                                    //drildown = {item.chartdrildown}
                                                    categories={item.categories}
                                                    xaxistitle={"Closure Reason"}
                                                    yaxistitle={"Consignments"}
                                                    dept_code={this.state.dept_code}
                                                />    
                                                )
                                            :""}
                                    </div>

                                    <div className={"col-xl-6 col-lg-6 "}>
                                       {this.state.monthlyfcalldata.length > 0?
                        
                                            this.state.monthlyfcalldata.map(item => 
                                                <div className={"col-xl-12 col-lg-12 row "+(this.state.shownormalall)}>
                                                    
                                                    <TransitLineChart
                                                        title={"Monthly Force Closure Summary (All)"}
                                                        subtitle={""}
                                                        context={this}
                                                        series={item.lineseries}
                                                        categories={item.categories}
                                                        xaxistitle={"Month-Year"}
                                                        yaxistitle={"Clouser type (%)"}
                                                        dept_code={this.state.dept_code}
                                                    />
                                                </div>
                                            )
                                        :""}
                                        {this.state.monthlyfcprimarydata.length > 0?
                        
                                            this.state.monthlyfcprimarydata.map(item => 
                                                <div className={"col-xl-12 col-lg-12 row "+(this.state.shownormalprimary)}>
                                                    
                                                    <TransitLineChart
                                                        title={"Monthly Force Closure Summary (Primary)"}
                                                        subtitle={""}
                                                        context={this}
                                                        series={item.lineseries}
                                                        categories={item.categories}
                                                        xaxistitle={"Month-Year"}
                                                        yaxistitle={"Clouser type (%)"}
                                                        dept_code={this.state.dept_code}
                                                    />
                                                </div>
                                            )
                                        :""}

                                    {this.state.monthlyfcsecondarydata.length > 0?
                                            
                                            this.state.monthlyfcsecondarydata.map(item =>
                                                <div className={"col-xl-12 col-lg-12 row "+(this.state.shownormalsecond)}>
                                                    <TransitLineChart
                                                        title={"Monthly Force Closure Summary (Secondary)"}
                                                        subtitle={""}
                                                        context={this}
                                                        series={item.lineseries}
                                                        categories={item.categories}
                                                        xaxistitle={"Month-Year"}
                                                        yaxistitle={"Clouser type (%)"}
                                                        dept_code={this.state.dept_code}
                                                    />
                                                </div>
                                                )
                                    :""}
                                    </div>
                            
                        </div>
                        
                        <div className={"dataLoadpage " +(this.state.loadshow)}></div>
                        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                        </div>
                        <div className={"overlay "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
                    
                    </div>
                   
                </div>

			);
		}
}

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}


function GetSortOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 


$(document).ready(function(){
    
    $(".loadclick").click(function(){
        var id = $(this).attr("id");
        $(".loadclick").removeClass("active");
        $("#"+id).addClass("active");
    })
})



function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
        // console.log(date1,date2);
    var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
    date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
    var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
    date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
    // console.log(date1,date2);
    var date1Number = monthToComparableNumber(date1);
    var date2Number = monthToComparableNumber(date2);
    // console.log(date1Number,date2Number);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
    }
  }
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName){
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
        ];
    var monthNum = months.indexOf(monthName)+1;
    if(monthNum < 10){
        monthNum = "0"+monthNum.toString();
    }
    return monthNum;
}





