import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import '../../../../node_modules/react-grid-layout/css/styles.css';
import '../../../../node_modules/react-resizable/css/styles.css';
import { WidthProvider, Responsive } from "react-grid-layout";
import axios from 'axios';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import _ from "lodash";
import $ from 'jquery';
// import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
// import TimePicker from "react-time-picker";
import datetimepicker from 'jquery-datetimepicker';
var moment = require('moment');
// import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../';

var redirectURL = require('../../redirectURL');

// dvarnl
export default class Overspeed extends Component{

	constructor(props)
	{
		super(props);
		this.state={
            starttime:'',
            endtime:'',
            dept_code:"",
            userid:localStorage.getItem('userid'),
            email:localStorage.getItem('email'),
            min_distance : 0,
            show:false,
            basicTitle:"",
            basicType:"default"
        };
        
		
    }
    componentDidMount(){
        loadDateTimeScript();
        
        var dept_code = this.props.deptCode;
        console.log(dept_code);
        var formdata = {
            config_type:'nightdriving',
            dept_code : dept_code
        };
        
        redirectURL.post('/manage/kpiConfigurations',formdata, {
            headers:{
                'Content-type':'application/json'
            }
        })
        .then((response) => {
            console.log(response.data);
            if(response.data.length > 0)
            {
                console.log(response.data[0].start_time);
                var starttime = response.data[0].start_time
                var endtime = response.data[0].end_time
                console.log(starttime,endtime);
                if(response.data[0].min_continuous_driving_dist != "")
                {
                this.setState({
                    starttime:starttime,
                    // time:(response.data[0].threshold_time/60),
                    endtime : endtime,
                    dept_code : dept_code,
                    min_distance:response.data[0].min_continuous_driving_dist
                });
                }
                else{
                    this.setState({
                        starttime:starttime,
                        // time:(response.data[0].threshold_time/60),
                        endtime : endtime,
                        dept_code : dept_code,
                    });
                }
                $("#startTime").val(starttime);
                $("#endTime").val(endtime);
            }
            this.setState({
                dept_code : dept_code
            });

        })
    }
    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    // changeInputHandler = (event) => {
    //     const re = /^[0-9\\:\b]+$/;
    //     if(re.test(event.target.value))
    //     {
    //         let name = event.target.name;
    //         let value = event.target.value;
    //         this.setState({[name]:value});
    //     }
    // }
	formHandler = (event) => {
        event.preventDefault();
        var start_time = document.getElementById("startTime").value;
        var end_time = document.getElementById("endTime").value;
        var min_distance = document.getElementById("continuous_distance").value;
        var flag = 0;
        // var new_start_time = start_time.split(" ")[0].split("-")[2]+"-"+start_time.split(" ")[0].split("-")[1]+"-"+start_time.split(" ")[0].split("-")[0]+" "+start_time.split(" ")[1]
        // var new_end_time = end_time.split(" ")[0].split("-")[2]+"-"+end_time.split(" ")[0].split("-")[1]+"-"+end_time.split(" ")[0].split("-")[0]+" "+end_time.split(" ")[1]
        // new_end_time = new Date(new_end_time);
        // new_start_time = new Date(new_start_time)
        // var diff = (new_end_time.getTime() - new_start_time.getTime());
        var check_start_time = start_time.split(":")
        var check_end_time = end_time.split(":");
        console.log(check_start_time,check_end_time);
        if(Number(check_start_time[0]) > Number(check_end_time[0])){
            flag = 1;
        }
        else if(Number(check_start_time[0]) === Number(check_end_time[0]))
        {
            flag = 1;
        }
        else
        {
            flag = 0;
        }
        if(flag == 0)
        {
            // var start_Date = moment.parseZone(new_start_time).format('DD-MM-YYYY HH:mm:ss');
            // var end_Date = moment.parseZone(new_end_time).format('DD-MM-YYYY HH:mm:ss');
            // console.log(start_Date);
            this.setState({
                starttime : start_time,
                endtime : end_time,
                min_distance:min_distance
            })
            var formdata = {
                userid:this.state.userid,
                user_email:localStorage.getItem('email'),
                dept_code:this.state.dept_code,
                start_time:start_time,
                kpitype:"nightdriving",
                end_time:end_time,
                min_distance:Number(min_distance)
                // threshold_time:(this.state.time*60)
            }
            console.log(formdata)
            redirectURL.post('/manage/saveNDKPIConfigurations',formdata, {
                headers:{
                    'Content-type':'application/json'
                }
            })
            .then((response) => {
                var res = response.data;
                if(res.result.result.ok == 1)
                {
                    this.props.context.showAlert();
                }
                else{
                    this.props.context.showErrorAlert();
                }
            })
        }
        else{
            this.setState({
                basicTitle:"End Time Should be Greater than Start Time",
                basicType:"warning",
                show:true
            })
        }
    }
    changeDept(params){
        this.setState({
            dept_code:params.value
        })
    }

    selectDept(){
        let arr=[];
        try{
            arr.push({value:"SNDG",label:"Sales and Dispatch"});
            arr.push({value:"LOG-PRT",label:"Spare Parts"});
            arr.push({value:"LOG-TNP",label:"Train and Production"});
            return arr;
        }
        catch(e){

        } 
    }
    handleminContinuousDist = (event) =>{
        this.setState({
            min_distance : event.target.value,
        })
    }

	 render() {
        return (
            <div className="row">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="col-xl-12 col-lg-12">
                    <h5>Night Driving Settings</h5>
                </div>
                <form className=" theme-form col-xl-6 col-lg-6" onSubmit={this.formHandler}>
                    <div className="col-xl-12 col-lg-12">
                        <div className="form-group">
                            {/* <label>Select Department : </label>
                            <Select 
                                placeholder={"Department"}
                                closeMenuOnSelect={true}
                                // isMulti="true"
                                // onChange={this.getRailConsginments.bind(this)} 
                                className={"border-radius-0"}
                                style={{borderRadius:"0px"}}
                                options={this.selectDept()}
                                onChange={this.changeDept.bind(this)}
                             />     */}
                        </div>        
                    </div>
                    <div className="col-xl-12 col-lg-12">
                        <div className="form-group">
                            <label className="c-lbl">Start Time :</label>
                            <input 
                                id="startTime"
                                type="text"
                                className = "form-control timepicker"
                                // className={classes.textField}
                                autoComplete="off"
                            />
                            {/* <input type="text" placeholder="Start Time" id="startTime" className="datetimepicker_mask form-control" value={this.state.starttime} autoComplete="off" required /> */}
                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12">
                        <div className="form-group">
                            <label className="c-lbl">End Time :</label>
                            <input 
                                id="endTime"
                                type="text"
                                className = "form-control timepicker"
                                // className={classes.textField}
                                autoComplete="off"
                            />
                            {/* <input type="text" placeholder="End Time" id="endTime" className="datetimepicker_mask form-control" value={this.state.endtime} autoComplete="off" required /> */}
                            {/* <input type="number" name="time" value={this.state.time} onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Time" required /> */}
                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12">
                        <div className="form-group">
                            <label className="c-lbl">Minimum Continuous Driving Distance (KM) :</label>
                            <input type="number" min="20" placeholder="" id="continuous_distance" className="form-control validateNumber" onChange={this.handleminContinuousDist.bind(this)} value={this.state.min_distance} required/>
                            {/* <input type="number" name="time" value={this.state.time} onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Time" required /> */}
                        </div>
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-success cs-btn" id="save">Save</button>
                    </div>
                </form>
            </div>
        );
    }
}

function loadDateTimeScript(){
	// alert("datetime");
	$('.timepicker').datetimepicker({
		datepicker: false,
        // minuteStepping:30,
        format: 'H:i',
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async=true
    script.defer = true
	index.parentNode.insertBefore(script,index)
	// alert("function called");
}

$(document).ready(function(){
    $(document).on('keyup',".validateNumber",function(){
        // alert("click");
        if(isNaN($(this).val()) == true){
            $(this).val($(this).val().slice(0,-1));
        }
    });
    $(document).on("keyup",".validateEmail",function(e){
        if(e.which === 32){
            $(this).val($(this).val()+", ");
        }
    });
    $(document).on("keyup","#continuous_distance",function(){
        if($(this).val() == 0){
            $(this).val(" ");
        }
    })
    // $("#startTime").timepicker();
});