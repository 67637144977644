import React, { Component } from 'react';
import { render } from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";
import Select from 'react-select';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import CustomLoadingCellRenderer from "./customLoadingCellRenderer";
import { getDDMMYYYY, getDDMMYYYYHHMMSS,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY } from '../common/utils'; 
import 'react-vertical-timeline-component/style.min.css';
import DrawMap from '../common/drawmap';
import CustomDateComponent from "../common/customdatepicker";
import $ from 'jquery';
// import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
 
var redirectURL = require('../redirectURL');
var moment = require('moment');
var infoBox = require('../common/google-infowindow');
var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);
var googleAnalytics = require("../common/googleanalytics");

var marker;
var markers = [];
var circle;
var selectlat;
var selectlng
var allcords=[];
var map;
var rad = function(x) {
	return x * Math.PI / 180;
  };
var rlatitude;
var rlongitude;

var fencingLength=0;
var geoFencingArea;

export default class CHACoilsImports extends Component {

	constructor(props){
		super(props);
		this.state={
			pageTitle: "Customs Entry (Kandla)",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			uploadDivWidth:'0%',
			sliderTranslate:'',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			overly:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
            basicTitle:'',
			basicType:"default",
			modules: AllModules,
			sidebarSubHeader:'',
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true

      	    },
      	    rowData: [],
      	      
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
			  overlayNoRowsTemplate: "",
			  frameworkComponents: {
				
				customLoadingCellRenderer: CustomLoadingCellRenderer
			},

      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
			rowClassRules: {
				"highlitRow": "data.transit_delay == 1",
				"green":"data.status >= 3"

            },
            transporterslist:[],
            container_no:'',
            updatesearatebtn:'show-n',
            searates:'',
            pipavaContainers:[],
            mundraContainers:[],
            containerslist:[],
            errorMessage:'',
            successMessage:'',
            transporter_code:'',
			components: { datePicker: getDatePicker() },
		}
		
		this.selectOptionsItems = this.selectOptionsItems.bind(this);
		this.selectContainersOptionsItems = this.selectContainersOptionsItems.bind(this);
	}
	componentDidMount(){
		this.setState({
			loadshow:'show-m'
		});
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}

		redirectURL.post("/imports/chacoilsimports",)
        .then((response) =>{
            var records = response.data;
            try{
                this.setState({
                    rowData:records,
                    loadshow:'show-n'
                })
            }
            catch(e){
                console.log("Response Error ", e)
            }
        })
        .catch(e => {
            console.log("Error ",e);
        })
	};
	
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
    }
	
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	
	//    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	   //console.log("Deptcode " ,this.state.deptcode)
	   
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
	}

	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:''
		});
		
	}
	closeAlert = () => {
        this.setState({
            show: false
        });
    }
   
   
	selectOptionsItems() {
        let items = [];     
        //console.log("transporterslist ",this.state.transporterslist);
        this.state.transporterslist.forEach(item => {
        	if(item != '' && item != null)
    		{
        		items.push({value:item.transporter_code,label:item.transporter_name}); 
    		}
        	
        });
        return items;
	}

	handlerForm = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
    }
    changeArrayHandler = async searates => {
    	
    	await this.setState(
	      { searates },
	      () => console.log(`Option selected:`, this.state.searates)
	    );
    	
		//await console.log("Selecton ",this.state.searates.value)
		
    }

    changeContainerArrayHandler = container_no =>{
        this.setState(
	      { container_no },
	      () => console.log(`Container Option selected:`, this.state.container_no)
        );
        
        this.setState({
			errorMessage:'',
			successMessage:''
        })
    }
    
    changeTransporterHandler = transporter_code => {
        this.setState(
            { transporter_code },
            () => console.log(`Container Option selected:`, this.state.transporter_code)
          );
    }

    selectContainersOptionsItems(){
        let items = [];     
        //console.log(this.state.trucks);
        if(this.state.containerslist.length > 0)
        {
            this.state.containerslist.forEach(item => {
                if(item != '' && item != null)
                {
                    items.push({value:item,label:item}); 
                }
                
            });
        }
        
        return items;
    }
    
   

    formAssignHandler = (event) => {
		event.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedUpdateNow,
			}
			googleAnalytics.logEvent(eventOptions);
		}
        this.setState({
            loadshow:'show-m'
        });
        //var formdata = new FormData(event.target);
		var itemsToUpdate = [];
		this.gridApi.forEachNodeAfterFilterAndSort(function(rowNode, index) {
			var data = rowNode.data;
			itemsToUpdate.push(data);
        });
        //console.log("itemsToUpdate ",itemsToUpdate);
		// var assignarr=[];
		// itemsToUpdate.map((item) => {
		// 	var cdate =new Date();
		// 	var currentDate = getDDMMYYYYHHMMSS(cdate);
		// 	assignarr.push({
		// 		"hbl_no" : item.hbl_no,
        //         "container_no":item.container_no,
        //         "document_received_date":item.document_received_date,
        //         "original_fta_received_date":item.original_fta_received_date,
        //         "msil_job_no":item.msil_job_no,
        //         "type_of_container":item.type_of_container,
        //         "inward_date":item.inward_date,
        //         "port_cfs_movement_date":item.port_cfs_movement_date,
        //         "destuffing_date":item.destuffing_date,
        //         "suppliers":item.suppliers,
        //         "description":item.description,
        //         "invoice_no":item.invoice_no,
        //         "invoice_value_currency":item.invoice_value_currency,
        //         "invoice_value":item.invoice_value,
        //         "ex_rate":item.ex_rate,
        //         "freight_amount_currency":item.freight_amount_currency,
        //         "freight_amount":item.freight_amount,
        //         "no_of_cas":item.no_of_cas,
        //         "gwt_kgs_per_bl":item.gwt_kgs_per_bl,
        //         "assesable_value_av":item.assesable_value_av,
        //         "be_no":item.be_no,
        //         "be_date":item.be_date,
        //         "rms_exam":item.rms_exam,
        //         "ooc_date":item.ooc_date,
        //         "handover_to_transporter":item.handover_to_transporter,
        //         "delivery_location":item.delivery_location,
        //         "remarks_for_delay":item.remarks_for_delay
		// 	});
		// })

	   // var res = this.gridApi.updateRowData({ update: itemsToUpdate });
	   //console.log("itemsToUpdate " , itemsToUpdate)
	   //console.log("assignarr " , assignarr)

	   var urlpath = '/imports/storeChaCoilsImportsData';
	   var reqparams = {
		   updateData:itemsToUpdate
	   } 
		redirectURL.post(urlpath,reqparams)
		.then((response) => {
			console.log("Response ", response)
			//if(response.data.status == 'Success')
			//{
				this.setState({
					loadshow:'show-n',
					successMessage:"Successfully updated"
					
				})
			//}
		})
		.catch(function (error) {
			console.log(error);
		});
		
	}
	
	applyDatePicker = (e) => {
		console.log("Datepicker ", e);
	}
	render(){
		//console.log("Rendere ",this.state.deptcode)
		const columnwithDefs = [
				
				{
					headerName: "Vessel Arrival Date ",
					field: "nyk_etd",
					width: 200,
					//filter: "agDateColumnFilter",
                    resizable: true,
                    editable:false,
                    valueGetter:function(params){
                        var splitdt = params.data.nyk_etd.split("/");
                        //console.log("splitdt ",splitdt);
                        if(splitdt[2] == "20")
                        {
                            var date = "2020-"+splitdt[1]+"-"+splitdt[0];
                        }
                        else
                        {
                            var date = params.data.nyk_etd
                        }
                        //console.log("params.data.nyk_bl_date ", date)
                        return getHyphenDDMMMYYYY(new Date(date));
					},
					//filter: "agDateColumnFilter",
					comparator: dateComparator1,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			else{
					// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			// console.log(cellValue,filterValue);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },
                },
                {
					headerName: "Vessel Name ",
					field: "nyk_ship_name",
					width: 200,
					filter: true,
                    resizable: true,
                    editable:false
				},
				{
					headerName: "Voyage No.",
					field: "nyk_voyage_no",
					width: 200,
					filter: true,
					resizable: true,
                    editable:false
					// cellRenderer: function(params) {
					// 	return  '<i class="fa fa-calendar-o" aria-hidden="true"></i>'+params.value;
					// },
				   
					//cellEditor: "datePicker"
				},
				{
					headerName: "Bl NO. ",
					field: "nyk_bl_no",
					width: 200,
					filter: true,
					resizable: true,
                    editable:false
					//cellEditor: "datePicker"
				},
				{
					headerName: "Bl Date ",
					field: "nyk_bl_date",
					width: 200,
					resizable: true,
                    editable:false,
                    valueGetter:function(params){
                        var splitdt = params.data.nyk_bl_date.split("/");
                        //console.log("splitdt ",splitdt);
                        if(splitdt[2] == "20")
                        {
                            var date = "2020-"+splitdt[1]+"-"+splitdt[0];
                        }
                        else
                        {
                            var date = params.data.nyk_bl_date
                        }
                        //console.log("params.data.nyk_bl_date ", date)
                        return getHyphenDDMMMYYYY(new Date(date));
					},
					//filter: "agDateColumnFilter",
					comparator: dateComparator1,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			else{
					// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			// console.log(cellValue,filterValue);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },
					//cellEditor: "datePicker"
				},
				{
					headerName: "No Of Coils",
					field: "nyk_no_coils",
					width: 200,
					filter: true,
					resizable: true,
                    editable:false
					//cellEditor: "datePicker"
				},
				// {
				// 	headerName: "Coil Size",
				// 	field: "nyk_coil_size",
				// 	width: 200,
				// 	filter: true,
				// 	resizable: true,
                //     editable:false
				// 	//cellEditor: "datePicker"
				// },
				// {
				// 	headerName: "Item Code",
				// 	field: "nyk_item_code",
				// 	width: 200,
				// 	filter: true,
				// 	resizable: true,
                //     editable:true
				// 	//cellEditor: "datePicker"
				// },
				// {
				// 	headerName: "Net Weight",
				// 	field: "nyk_net_weight",
				// 	width: 200,
				// 	filter: true,
				// 	resizable: true,
                //     editable:true
				// 	//cellEditor: "datePicker"
				// },
				{
					headerName: "Gross Weight",
					field: "nyk_gross_weight",
					width: 200,
					filter: true,
					resizable: true,
                    editable:false
					//cellEditor: "datePicker"
				},
				
				{
					headerName: "ATA Date",
					field: "nyk_ata_date",
					width: 200,
					//filter: "agDateColumnFilter",
					resizable: true,
					editable:true,
					cellEditor: DateEditor,
					valueGetter:function(params){
						//console.log("params ", params);
						if (params.data.nyk_ata_date != null)
						{
							if(moment(params.data.nyk_ata_date,'DD-MM-YYYY',true).isValid() == false)
							{
								return getHyphenDDMMMYYYY(params.data.nyk_ata_date);
							}
							else{
								return params.data.nyk_ata_date;
							}
							
						}
						else
						{
							return ""
						}

					},
					//filter: "agDateColumnFilter",
					comparator: dateComparator1,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			else{
					// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			// console.log(cellValue,filterValue);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },
                    //cellEditor: "datePicker"
				},
				{
					headerName: "ATB Date",
					field: "nyk_atb_date",
					width: 200,
					//filter: "agDateColumnFilter",
					resizable: true,
					editable:true,
					cellEditor: DateEditor,
					valueGetter:function(params){
						//console.log("params ", params);
						if (params.data.nyk_atb_date != null)
						{
							if(moment(params.data.nyk_atb_date,'DD-MM-YYYY',true).isValid() == false)
							{
								return getHyphenDDMMMYYYY(params.data.nyk_atb_date);
							}
							else{
								return params.data.nyk_atb_date;
							}
							
						}
						else
						{
							return ""
						}

					},
					//filter: "agDateColumnFilter",
					comparator: dateComparator1,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			else{
					// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			// console.log(cellValue,filterValue);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },
                    //cellEditor: "datePicker"
				},
				{
					headerName: "BOE Date",
					field: "nyk_boe_date",
					width: 200,
					//filter: "agDateColumnFilter",
					resizable: true,
					editable:true,
					cellEditor: DateEditor,
					valueGetter:function(params){
						//console.log("params ", params);
						if (params.data.nyk_boe_date != null)
						{
							if(moment(params.data.nyk_boe_date,'DD-MM-YYYY',true).isValid() == false)
							{
								return getHyphenDDMMMYYYY(params.data.nyk_boe_date);
							}
							else{
								return params.data.nyk_boe_date;
							}
							
						}
						else
						{
							return ""
						}

					},
					//filter: "agDateColumnFilter",
					comparator: dateComparator1,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			else{
					// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			// console.log(cellValue,filterValue);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },
                    //cellEditor: "datePicker"
				},
				{
					headerName: "BOE No.",
					field: "nyk_boe_no",
					width: 200,
					filter: true,
					resizable: true,
					editable:true,
					//cellEditor: "datePicker"
				},
				{
					headerName: "BOE Amount",
					field: "nyk_boe_amount",
					width: 200,
					filter: true,
					resizable: true,
					editable:true,
					//cellEditor: "datePicker"
				},
				{
					headerName: "SIMs Regestration Details (SIMS)",
					field: "nyk_sims_register",
					width: 200,
					filter: true,
					resizable: true,
					editable:true,
					//filter: "agDateColumnFilter",
					comparator: dateComparator1,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			else{
					// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			// console.log(cellValue,filterValue);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },
					//cellEditor: "datePicker"
				},
				{
					headerName: "COC Date ",
					field: "nyk_coc_date",
					width: 200,
					//filter: "agDateColumnFilter",
					resizable: true,
					editable:true,
					comparator: dateComparator1,
					cellEditor: DateEditor,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			else{
					// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			// console.log(cellValue,filterValue);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },
					//cellEditor: "datePicker"
				},
				{
					headerName: "Original Invoice Copy",
					field: "nyk_original_invoice",
					width: 200,
					filter: true,
					resizable: true,
					editable:true,
					//filter: "agDateColumnFilter",
					comparator: dateComparator1,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			else{
					// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			// console.log(cellValue,filterValue);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },
					//cellEditor: "datePicker"
				},
				{
					headerName: "Packing List",
					field: "nyk_packing_list",
					width: 200,
					filter: true,
					//filter: "agDateColumnFilter",
					comparator: dateComparator1,
					resizable: true,
					editable:true,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			else{
					// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			// console.log(cellValue,filterValue);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },
					//cellEditor: "datePicker"
				},
				{
					headerName: "Bl Copy ",
					field: "nyk_bl_copy",
					width: 200,
					filter: true,
					resizable: true,
					//filter: "agDateColumnFilter",
					comparator: dateComparator1,
					editable:true,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			else{
					// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			// console.log(cellValue,filterValue);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },
					//cellEditor: "datePicker"
				},
				{
					headerName: "CEPA/ FTA ",
					field: "nyk_cepa_fta",
					width: 200,
					filter: true,
					resizable: true,
					//filter: "agDateColumnFilter",
					comparator: dateComparator1,
					editable:true,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			else{
					// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			// console.log(cellValue,filterValue);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },
					//cellEditor: "datePicker"
				},
				{
					headerName: "FREIGHT BILL",
					field: "nyk_freight_bill",
					width: 200,
					filter: true,
					resizable: true,
					//filter: "agDateColumnFilter",
					comparator: dateComparator1,
					editable:true,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			else{
					// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			// console.log(cellValue,filterValue);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },
					//cellEditor: "datePicker"
				},
				{
					headerName: "Mill MTC ",
					field: "nyk_mill_mtc",
					width: 200,
					filter: true,
					//filter: "agDateColumnFilter",
					comparator: dateComparator1,
					resizable: true,
					editable:true,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			else{
					// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			// console.log(cellValue,filterValue);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },
					//cellEditor: "datePicker"
				},
				{
					headerName: "BIS Certificate",
					field: "nyk_bis_certificate",
					width: 200,
					filter: true,
					resizable: true,
					//filter: "agDateColumnFilter",
					comparator: dateComparator1,
					editable:true,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			else{
					// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			// console.log(cellValue,filterValue);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },
					//cellEditor: "datePicker"
				},
				{
					headerName: "Delivery Order",
					field: "delivery_order",
					width: 200,
					filter: true,
					resizable: true,
					editable : true
				}
				
			];
		const dStyles={
			width:this.props.width,
            height:'500px',	
            marginLeft:'0px !important'

	    }
        
       
		return(
			<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
				<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-ship cus-i"></i> <span>  {this.state.pageTitle} </span>
                                </h5>
				   			</div>				   			
		            		<div className="card-body">
                                <div className="row col-xl-12 col-lg-12">
                                    {/*<form className="row theme-form col-xl-12 col-lg-12" onSubmit={this.formHandler}>
                                        <div className="col-xl-3 col-lg-3 form-group">
											<select name="consignee_code" className="form-control" onChange={this.handlerForm.bind(this)} required>
												<option value="">Select Transporter</option>
												{this.selectOptionsItems()}
											</select>
										</div>
										
                                        <div className="col-xl-3 col-lg-3 form-group">
                                            <button type="submit" className="btn btn-success">Get Data</button>
                                        </div>
                                    </form>
                                    */}
                                    {(this.state.errorMessage != '')?
                                    <div className="row col-xl-12 xol-lg-12 alert alert-danger">
                                        {this.state.errorMessage}
                                    </div>
                                    
                                    :""}
									{(this.state.successMessage != '')?
                                    <div className="row col-xl-12 xol-lg-12 alert alert-success">
                                        {this.state.successMessage}
                                    </div>
                                    
                                    :""}
                                   
                                </div>
                                <div className="row col-xl-12 col-lg-12">
								    
									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
									</div>
										<div className="col-xl-12 col-lg-12">
											

											<div id="myGrid" style={{ height: "500px",width: "100%"}}  className="ag-theme-balham">
												<AgGridReact
													modules={this.state.modules}
													columnDefs={columnwithDefs}
													defaultColDef={this.state.defaultColDef}
													rowData={this.state.rowData}
													enableCharts={false}
													enableRangeSelection={true}
													autoGroupColumnDef={this.state.autoGroupColumnDef}
													onGridReady={this.onGridReady}
													onGridState={this.onGridState}
													statusBar={this.state.statusBar}
													sideBar={this.state.sideBar}
													paginationPageSize={this.state.paginationPageSize}
													pagination={true}
													
													components={this.state.components}
													
													//floatingFilter={true}
													//onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
												    //onCellClicked={this.popmarker}
												/>
											</div>
											
                                   			{(this.state.rowData.length > 0)?
											<button type="button" onClick={this.formAssignHandler.bind(this)} className="btn btn-primary float-right">Update Now</button>
											: ""}
										</div>
                                </div>
				   			</div>
				   		</div>	
				   	</div>
					   
				 </div>
				 
				
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
              	
		);
	}
}

function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}                 
function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = parseInt(hours);
	var minutes = (num-(rhours*3600)) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}                              

function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
function dateScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function distance(lat1, lon1, lat2, lon2, unit) {
	if ((lat1 == lat2) && (lon1 == lon2)) {
		return 0;
	}
	else {
		var radlat1 = Math.PI * lat1/180;
		var radlat2 = Math.PI * lat2/180;
		var theta = lon1-lon2;
		var radtheta = Math.PI * theta/180;
		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) {
			dist = 1;
		}
		dist = Math.acos(dist);
		dist = dist * 180/Math.PI;
		dist = dist * 60 * 1.1515;
		if (unit=="K") { dist = dist * 1.609344 }
		if (unit=="N") { dist = dist * 0.8684 }
		return dist;
	}
}
function getDatePicker() {
    function Datepicker() {}
    Datepicker.prototype.init = function(params) {
      this.eInput = document.createElement('input');
	  this.eInput.value = params.value;
	  this.eInput.setAttribute("class", "dateinput");
      window.$(this.eInput).datepicker({ dateFormat: 'dd/mm/yy' });
    };
    Datepicker.prototype.getGui = function() {
      return this.eInput;
    };
    Datepicker.prototype.afterGuiAttached = function() {
      this.eInput.focus();
      this.eInput.select();
    };
    Datepicker.prototype.getValue = function() {
      return this.eInput.value;
    };
    Datepicker.prototype.destroy = function() {};
    Datepicker.prototype.isPopup = function() {
      return false;
    };
    return Datepicker;
  }
  function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }

function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}
function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

