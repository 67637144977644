/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import ReactDOM from "react-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import store from '../../store/index'
import Select from "react-select";
import _, { toUpper } from "lodash";

import MarkerClusterer from "@google/markerclusterer";
import Modal from "react-responsive-modal";
import axios from "axios";
import { AgGridReact } from "@ag-grid-community/react";
import { AllModules } from "@ag-grid-enterprise/all-modules";
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import CustomLoadingCellRenderer from "./customLoadingCellRenderer";
import {
    getDDMMYYYY,
    getYYYYMMDD,
    getDDMMYYYYHHMMSS,
    getHyphenDDMMMYYYYHHMM,
    getHyphenDDMMMYYYY,
} from "../common/utils";
import "react-vertical-timeline-component/style.min.css";
import DrawMap from "../common/drawmap";
import ConsignmentActions from "../manage/ConsignmentActionsComponent";
import ParkingoutCarrier from "./parkingcarrierbtn";
import $ from "jquery";
// import "jquery-ui/ui/core";
import "jquery-ui/ui/widgets/datepicker";
import CSVFileValidator from 'csv-file-validator'
var infoBox = require("../common/google-infowindow");
var googleAnalytics = require("../common/googleanalytics");

var redirectURL = require("../redirectURL");
var moment = require("moment");


export default class BillingReport extends Component {
    state = {
        pageTitle: "",
        eventGridAction: "gridAction",
        eventCounterAction: "counterAction",
        eventFormAction: "formAction",
        uploadDivWidth: "0%",
        sliderTranslate: "",
        sliderRouteTranslate: "",
        tabsliderTranslate: "",
        loadshow: "show-n",
        showDiv: "show-n",
        alertshow: "fade",
        alertmg: "show-n",
        alerterrmg: "show-n",
        overly: "show-n",
        alerterrshow: "fade",
        alert: null,
        show: false,
        basicTitle: "",
        basicType: "default",
        show1: false,
        basicTitle1: "",
        basicType1: "default",
        modules: AllModules,
        sidebarSubHeader: "",
        defTransitCoords: "",
        csvcontent:[],
        consigner: [],
        defaultColDef: {
            sortable: true,
            filter: true,
            resizable: true,
        },
        rowData: [],
        monthSelected : "",
        yearSelected : "",
        firstMailSent : "",
        rowModelhighchartoptionsType: "enterprise",
        rowGroupPanelShow: "always",
        overlayNoRowsTemplate: "",

        animateRows: true,
        debug: true,
        showToolPanel: false,
        uppressAggFuncInHeader: true,

        childconfs: [],
        childrow: [],
        rowModelType: "serverSide",
        paginationPageSize: 50,
        cacheBlockSize: 10,
        maxBlocksInCache: 1,
        statusBar: {
            statusPanels: [
                {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left",
                },
                {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center",
                },
                { statusPanel: "agFilteredRowCountComponent" },
                { statusPanel: "agSelectedRowCountComponent" },
                { statusPanel: "agAggregationComponent" },
            ],
        },
        sideBar: {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel",
                },
            ],
        },
        detailCellRendererParams: {},
        rowClassRules: {
            // "highlitRow": "data.transit_delay == 1",
            // "green":"data.status >= 3"
        },
        startDate: "",
        endDate: "",
        mapinfo: "",
        defaultsdate: "",
        defaultedate: "",
        withdrawmodal: false,
        parkingyard: "show-m",
        truckinfo: "show-n",
        pact: "btn-warning",
        tact: "btn-defaultc",
        truckslist: [],
        truck_no: { value: "", label: "Select Truck" },
        dept_code: "",
        transporters: [],
        transporter_code: { value: "", label: "Select Transporter" },
        struckslist: [],
        struck_no: { value: "", label: "Select Truck" },
        radius: "5000",
        rlatitude: "",
        rlongitude: "",
        latitude: "",
        longitude: "",
        defaultradius: 0,
        coordinates: [],
        alltrucks: [],
        search_truck_no: "",
        search_transporter_name: "",
        search_gps_provider: "",
        search_speed: "",
        search_last_packet_time: "",
        search_gps_status: "",
        search_address: "",
        search_city: "",
        search_state: "",
        search_carrier_reported: "",
        reporttruck: 0,
        plants: [],
        plant_code: { value: "", label: "Select Parking Yard" },
        withdrawdata: "",
        withdraw_reason: "",
        gpsplant: "",
        gpsradius: 15,
        mwidth: "col-xl-5 col-lg-5",
        rowData1: [],
        nsearch: "show-n",
        selectedTabLat: "",
        loginplant: "",
        filteredData: [],
        consignment_code: '',
        showTrucksList: "show-n",
        manual_parking_out: "",
        parkingdata: "",
        slideerParkingOut: "",
        selectedMonth: moment.parseZone(new Date()).format("YYYY-MM"),
        uptime_sheet_1: [],
        uptime_sheet_2: [],
        uptime_sheet_3: [],
        uptime_sheet_4: [],
        date_keys: [],
        overall_btn: "btn-success",
        monthly_btn: "btn-default",
        daywise_btn: "btn-default",
        tptapproval_btn: "btn-default",
        tptremainder_btn:"btn-default",
        overall_data: "show-m",
        monthly_data: "show-n",
        daywise_data: 'show-n',
        tptapproval_data: 'show-n',
        tptremainder_data:'show-n',
        availabilityVsLoadingReport_btn:"btn-default",
        availabilityVsLoadingReport_to_emails:"",
        availabilityVsLoadingReport_cc_emails:"",
        LoadingData_csvcontent:[],
        ParkingListData_csvcontent:[],
        CalendarData_csvcontent:[],
        TransporterData_csvcontent:[],
        LoadingData:"",
        ParkingListData:"",
        CalendarData:"",
        TransporterData:"",
        TransporterData_headers:["TransporterName" ,"TransporterCode"],
        CalendarData_headers:["Dates", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"],
        ParkingListData_headers:["Date", "Vehicle#", "Code", "TPT Name", "Plants"],
        LoadingData_headers:["Inv Dt",  "Truck", "Transptr", "Name", "Plant"],
    };





    componentDidMount() {
        var date = new Date(this.state.selectedMonth)
        this.onLoadData(date)
    }

    onLoadData = (date) => {
        this.setState({
            loadshow: 'show-m',
            overly: 'show-m'
        })
        var params = {
            month: date.toLocaleString('default', { month: 'long' }),
            year: date.getFullYear()
        }
        redirectURL.post("/dashboard/getBillingReportData", params).then(response => {
            var records = response.data
            var date_keys = []
            if (records.uptime_sheet_2.length > 0) {
                var keys = Object.keys(records.uptime_sheet_2[0])
                date_keys = keys.filter(e => !["Transporter_Code", "Vehicle_Number", "month", "year", "Average", "Transporter_Name", "installation_date", "_id"].includes(e))
            }
            var currentTransporters = []
            records.uptime_sheet_4.map((e)=>{
                currentTransporters.push(e.transporter_code)

            })
            this.setState({
                uptime_sheet_1: records.uptime_sheet_1,
                uptime_sheet_2: records.uptime_sheet_2,
                uptime_sheet_3: records.uptime_sheet_3,
                uptime_sheet_4: records.uptime_sheet_4,
                currentTransporters:currentTransporters,
                date_keys: date_keys,
                loadshow: 'show-n',
                overly: 'show-n'
            })



        })
    }


    onGridReady = (params) => {
        this.gridApi = params.api;
        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };

    onGridState = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    };

    onClickHideAll() {
        this.setState({
            loadshow: "show-n",
            overly: "show-n",
            sliderRouteTranslate: "",
            tabsliderTranslate: "",
            slideAddManualForm: "",
            slideerParkingOut: "",
        });
    }
    closeAlert = async () => {
        await this.setState({
            show: false,
        });
    };

    handlerStartDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        var selDay = (d.getMonth() + 1);
        if (parseInt(selDay) < 10) {
            selDay = "0" + selDay;
        }
        var plan_month = d.getFullYear() + "-" + selDay;
        this.setState({
            selectedMonth: plan_month
        });
        this.onLoadData(new Date(plan_month))

    }

    OnClickAddTrucks = () => {
        var trucks = $("#add_truck").val()
        var trucks = trucks.toUpperCase()
        var truck_list = trucks.split(",")
        var current_date = new Date()
        var month_date_25th = moment.parseZone(new Date(current_date.getFullYear(), current_date.getMonth(), 25 )).format("YYYY-MM-DD HH:mm:ss")
        var month_date_28th = moment.parseZone(new Date( current_date.getFullYear(), current_date.getMonth() , 28 )).format("YYYY-MM-DD")
        var month_date_28th = moment.parseZone(new Date(month_date_28th + " 23:59:59")).format("YYYY-MM-DD HH:mm:ss")
        if(current_date.getTime() > new Date(month_date_25th).getTime() && current_date.getTime() < new Date(month_date_28th).getTime()){
            redirectURL.post("/dashboard/AddTrucksForBillingReport", { truck_list }).then(response => {
                if (response.data.status == 'success') {
                    this.setState({
                        show: true,
                        basicType: 'success',
                        basicTitle: 'Successfully Added Trucks'
                    })
                    $("#add_truck").val("")
                } else {
                    this.setState({
                        show: true,
                        basicType: 'danger',
                        basicTitle: 'Trucks not Updated'
                    })
                    $("#add_truck").val("")
                }
            })
        }else{
            this.setState({
                show:true,
                basicType:"info",
                basicTitle:"Trucks can be added only between 25th and 28th of Current Month"
            })
            $("#add_truck").val("")

        }
       
    }

    onUploadFile = (key) => {
        this.setState({
			uploadDivWidth: '30%',
			sliderTranslate: "slider-translate",
			showDiv: 'show-m',
			csvmessage: '',
			csverrmessage: '',
            slider_name: key
		});
    }

    onRemainderMails = (key) => {
        this.setState({
			uploadDivWidth: '30%',
			sliderTranslate: "slider-translate",
			showDiv: 'show-m',
			csvmessage: '',
			csverrmessage: '',
            slider_name: key
		});
    }

    onAddTransporters = (key) => {
        this.setState({
			uploadDivWidth: '40%',
			sliderTranslate: "slider-translate",
			showDiv: 'show-m',
			csvmessage: '',
			csverrmessage: '',
            slider_name: key
		});
    }

    resetUpload = () => {
		this.setState({
			uploadDivWidth: '0%',
			sliderTranslate: '',
			showDiv: 'show-n',
			uploadFile: '',
			file: ''
		});
		document.getElementById("upform").reset();
	}

    onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth: '0%',
			sliderTranslate: "",
			showDiv: 'show-n',
			uploadFile: '',
			file: '',
			csvmessage: '',
			csverrmessage: '',
		});
		document.getElementById("upform").reset();
	}

    addTransportersFormHandler = (event) => {
        event.preventDefault();
        var params = {
            transporter_code: parseInt(event.target.elements.code.value),
            transporter_name: event.target.elements.name.value.toUpperCase(),
            email: event.target.elements.email.value,
            mobile_no_of_contracting_party_two_for_estamp: event.target.elements.mobile.value,
            service_provider_name: event.target.elements.serviceprovidername.value,
            firm_type: event.target.elements.firmtype.value,
            contracting_party_two_address: event.target.elements.partyaddress.value,
            contrcating_party_two_authorized_signatory_name: event.target.elements.signatoryname.value
        }

        var transporter_exist = this.state.uptime_sheet_4.filter((item) => {
            if (item.transporter_code == event.target.elements.code.value) {
                return item.transporter_code
            }
        })
        if (transporter_exist.length == 0) {
            redirectURL.post("/dashboard/AddTransportersData", params).then(response => {
                var records = response.data
                if (records == "Success") {
                    this.setState({
                        show: true,
                        basicType: 'success',
                        basicTitle: "Transporter Added Successfully",
                        loadshow: 'show-n',
                    });
                }
                else {
                    this.setState({
                        show: true,
                        basicType: 'danger',
                        basicTitle: "Error Occured While Adding Transporter",
                        loadshow: 'show-n',
                    });
                }
            })
        }
        else {
            this.setState({
                show: true,
                basicType: 'danger',
                basicTitle: "Transporter Already Exist",
                loadshow: 'show-n',
            });
        }




    }

    handlermonthYear = (event) => {
        var d = new Date(event._d);
        var monthNames = [
          "January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ];
        var month = monthNames[d.getMonth()];
        var year = d.getFullYear();
        this.setState({
            monthSelected : month,
            yearSelected : year,
        })
    }

    handlerFirstMailSentOn = (event) => {
        var d = new Date(event._d);
        var firstMailSent = d.getFullYear()+"-"+((d.getMonth() +1)<10?"0"+(d.getMonth()+1):(d.getMonth()+1))+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate()) + " 00:00:00";
        this.setState({
            firstMailSent
        })
    }

    changeConsignerArrayHandler = consigner => {
		this.setState(
			{ consigner },
			// () => console.log(`Consigner Option selected:`, this.state.consigner)
		);

		// this.setState({
		// 	errorMessage: '',
		// 	successMessage: ''
		// })
	}

    selectConsignerOptionsItems = () => {
		let items = [];

		var transporter_codes = this.state.uptime_sheet_4;

		// items.push({ value: 'all', label: 'All' });
		if (transporter_codes.length > 0) {
			transporter_codes.forEach(item => {
				items.push({ value: item.transporter_code, label: item.transporter_code });
			});
		}

		return items;
	}

    changeFileHandler = e => {
        // console.log("e.target.files[0] ",e.target.files[0].name)
        // var check = verifyextcsv(e.target.files[0].name);
        // console.log("Check ", check)
        // if(check == true)
        // {
        // 	this.setState({file:e.target.files[0]});
        // }
        // else{
        // 	this.setState({
        // 		//csverrmessage:response.data.status,
        // 		show: true, basicType:'danger', basicTitle:"Invalid file extension",
        // 		uploadFile:'',
        // 		file:''
        // 	});
        // }
        const config = {
            headers: [

                {
                    name: 'Code',
                    inputName: 'Code',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'Transporter Name',
                    inputName: 'Transporter Name',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'Vehicle Number',
                    inputName: 'Vehicle Number',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'Uptime',
                    inputName: 'Uptime',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'Billing Status',
                    inputName: 'Billing Status',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'Billing Plant',
                    inputName: 'Billing Plant',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                }
            ]
        }
        let data = CSVFileValidator(e.target.files[0], config).then((csvData) => {
            // console.log("csvdata", csvData.data)
            this.setState({
                csvcontent: csvData.data
            });
        })
            .catch(err => { })
        // console.log("e.target.files[0]",e.target.result);
        var contentList = []

        var out = new Promise(function (reject, resolve) {
            var reader = new FileReader();
            reader.onload = async function (e) {
                var contents = await e.target.result;
                resolve(contents);
            };
            var tt = reader.readAsText(e.target.files[0]);
        });
        // console.log(contentList);
        this.setState({
            file: e.target.files[0]
        });
        // console.log("e.target.files[0].type ", e.target.files[0]);

        if (e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
            // console.log(fileData);
            // console.log(typeof(fileData))

        }
        else {
            e.target.value = null;
            this.setState({
                uploadFile: '',
                show: true,
                basicType: 'danger',
                basicTitle: 'Please upload file having extensions .csv only.',
            });
        }

    }

    AvailabilityVsLoadingReport_changeFileHandler = e => {
        var name = e.target.name
        var file = e.target.files[0]
        if(e.target.files.length>0){
            if (e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
                const reader = new FileReader()
                reader.onload = (e) => {
                    const text = e.target.result;
                    const rows = text.split('\n');
                    const data = rows.map(row => row.split(','));
                    // Assuming the first row contains headers
                    const headers = data[0];
                    const parsedData = data.slice(1).map(row => {
                        const obj = {};
                        row.forEach((cell, index) => {
                            if (name == "LoadingData") {
                                if (headers[index].trim() == "Inv Dt") {
                                    if (![undefined, null, "", "undefined"].includes(cell)) {
                                        try {
                                            var date = cell.trim()
                                            if(date.includes("-")&& date.split("-").length == 3){
                                                if(date.split("-")[2].length !== 4){
                                                    var year = new Date().getFullYear().toString()
                                                    date = date.split("-")[0] + "-" + date.split("-")[1] + "-" + year.slice(0,-2)+date.split("-")[2]
                                                    obj[headers[index].trim()] = date
                                                }
                                                else{
                                                    obj[headers[index].trim()] = cell.trim()
                                                }
                                            }
                                            else{
                                                check = "Inv Dt"
                                            }
                                        } catch (error) {
                                            check = "Inv Dt"
                                        }
                                    }
                                    else {
                                        check = "Inv Dt"
                                    }
                                } else {
                                    obj[headers[index].trim()] = cell.trim();
                                }
                            } else {
                                obj[headers[index].trim()] = cell.trim();
                            }
                        });
                        return obj
                    });
                    var check = false
                    if (parsedData.length > 0) {
                        var keys = Object.keys(parsedData[0])
                        var items = this.state[`${name}_headers`]
                        if (keys.length === items.length) {
                            for (let i in items) {
                                if (keys.includes(items[i])) {
                                } else {
                                    check = true
                                    break
                                }
                            }
                        } else {
                            check = true
                        }
                    } else {
                        check = true
                    }
    
                    if (check == true) {
                        this.setState({
                            show: true,
                            basicTitle: "Column Headers Doesn't Match",
                            basicType: 'danger'
                        })
                        $(`#${name}`).val("")
                    } else if(check == "Inv Dt"){
                        this.setState({
                            show: true,
                            basicTitle: "Please Check Date Format",
                            basicType: 'danger'
                        })
                        $(`#${name}`).val("")
                    }
                    else {
                        this.setState({
                            [`${name}_csvcontent`]: parsedData
                        });
                    }
                };
    
                reader.readAsText(file);
            }
            else {
                $(`#${name}`).val("")
                this.setState({
                    file: "",
                    show: true,
                    basicType: 'danger',
                    basicTitle: 'Please upload file having extensions .csv only.',
                });
            }
        }
        
       


    }

    onSubmitAvailabilityVsLoadingReport =(event) => {
        event.preventDefault()
        var LoadingData_csvcontent = this.state.LoadingData_csvcontent.filter(e => Object.keys(e).length > 0 && !["",undefined, null , "undefined"].includes(e['Inv Dt']))
        var ParkingListData_csvcontent = this.state.ParkingListData_csvcontent.filter(e => Object.keys(e).length > 0 && !["",undefined, null , "undefined"].includes(e['Date']))
        var CalendarData_csvcontent = this.state.CalendarData_csvcontent.filter(e => Object.keys(e).length > 0 && !["",undefined, null , "undefined"].includes(e['Dates']))
        var params = {
            loading_data: JSON.stringify(LoadingData_csvcontent),
            parking_list_data: JSON.stringify(ParkingListData_csvcontent),
            Calander: JSON.stringify(CalendarData_csvcontent),
            to_emails: JSON.stringify(this.state.availabilityVsLoadingReport_to_emails.split(",")),
            cc_emails: JSON.stringify(this.state.availabilityVsLoadingReport_cc_emails.split(","))
        }

        if(this.state.TransporterData_csvcontent.length>0){
            var TransporterData_csvcontent = this.state.TransporterData_csvcontent.filter(e => Object.keys(e).length > 0 && !["",undefined, null , "undefined"].includes(e['TransporterName']))
            params.tpt_names = JSON.stringify(TransporterData_csvcontent)
            params.tpt_names_data = 1
        }
        redirectURL.post("dashboard/availabilityVsLoadingReport",params).then(response =>{
            if(response.data.status == "success" ){
                this.setState({
                    show:true,
                    basicType:"success",
                    basicTitle:"Please Check your Email for the Report"
                })
            }
        })
    }


    uploadFormHandler = (event) => {
        event.preventDefault();

        let csvContent = this.state.csvcontent
        if (csvContent.length > 0) {
            var nonTransporters = []
            csvContent.map((e) => {
                if (this.state.currentTransporters.includes(parseInt(e.Code))) {
                    
                }
                else{
                    nonTransporters.push(parseInt(e.Code))
                }
            })
            if (nonTransporters.length == 0) {
                var rparams = {
                    // uploadFile: this.state.file,
                    // userId: localStorage.getItem('userid'),
                    // email: localStorage.getItem('email'),
                    monthSelected : this.state.monthSelected,
                    yearSelected: this.state.yearSelected,
                    csvcontent: csvContent
                }




                this.setState({
                    loadshow: 'show-m'
                });
                let url = "/manage/uploadTruckDetailsCSV"
                redirectURL.post(url, rparams,).then((response) => {
                    // console.log(response);
                    //console.log(response.data.status);
                    document.getElementById("upform").reset();
                    if (response.data.status == "Failure") {
                        this.setState({
                            //csverrmessage:response.data.status,
                            show: true, basicType: 'danger', basicTitle: response.data.message,
                            loadshow: 'show-n',
                        });

                    }
                    else {
                        this.setState({
                            show: true, basicType: 'success', basicTitle: "Successfully Uploaded",
                            loadshow: 'show-n',
                        });

                    }
                }).catch(function (error) {
                    console.log("error", error);
                });
            }
            else {
                this.setState({
                    //csverrmessage:response.data.status,
                    show: true,
                    basicType: 'danger',
                    basicTitle: `Please Add these Transporters to Proceed ${nonTransporters}`,
                    loadshow: 'show-n'
                });
            }
        }
        else {
            this.setState({
                //csverrmessage:response.data.status,
                show: true,
                basicType: 'danger',
                basicTitle: "Please Upload File",
                loadshow: 'show-n'
            });
        }

    }

    onSelectRemainderMailTransporters = (event) => {
        event.preventDefault();
        if(this.state.consigner.length>0 && this.state.firstMailSent != "" && this.state.monthSelected != "" && this.state.yearSelected != ""){
            var nonTransporters = []
            var nonEmails = []
            this.state.consigner.map((item)=>{
                if(this.state.currentTransporters.includes(item.value)){
                    this.state.uptime_sheet_4.map(items => {
                        if(items.transporter_code == item.value){
                            if(items.email == ""){
                                nonEmails.push(item.value)
                            }
                        }
                    })
                }
                else{
                    nonTransporters.push(item.value)
                }
            })
            if (nonTransporters.length == 0 && nonEmails.length == 0) {
                var rparams = {
                    monthSelected : this.state.monthSelected,
                    yearSelected: this.state.yearSelected,
                    firstMailSent : this.state.firstMailSent,
                    transporter_list : this.state.consigner.map(item => item.value)
                }

                // console.log(rparams, "rparams")
                this.setState({
                    loadshow: 'show-m'
                });
                let url = "/manage/tptRemainderMails"
                redirectURL.post(url, rparams,).then((response) => {
                    document.getElementById("upform").reset();
                    if (response.data.status == "Failure") {
                        this.setState({
                            //csverrmessage:response.data.status,
                            show: true, basicType: 'danger', basicTitle: response.data.message,
                            loadshow: 'show-n',
                        });

                    }
                    else {
                        this.setState({
                            show: true, basicType: 'success', basicTitle: "Successfully Sent Remainder Mails",
                            loadshow: 'show-n',
                        });

                    }
                }).catch(function (error) {
                    console.log("error", error);
                });
            }
            else if(nonTransporters.length > 0){
                this.setState({
                    //csverrmessage:response.data.status,
                    show: true,
                    basicType: 'danger',
                    basicTitle: `Please Add these Transporters to Proceed ${nonTransporters}`,
                    loadshow: 'show-n'
                });
            }
            else{
                this.setState({
                    show: true,
                    basicType: 'danger',
                    basicTitle: `Please Add emails to this transporters to proceed ${nonEmails}`,
                    loadshow: 'show-n'
                });
            }
        }
        else{
            this.setState({
                show: true,
                basicType: 'danger',
                basicTitle: "All fields are required",
                loadshow: 'show-n'
            }); 
        }
    }
    onCLickChangeTab = (tab) => {
        // console.log(tab,"tab")
        if (tab == 'overall') {
            this.setState({
                overall_btn: "btn-success",
                monthly_btn: "btn-default",
                daywise_btn: "btn-default",
                tptapproval_btn: "btn-default",
                tptremainder_btn: "btn-default",
                availabilityVsLoadingReport_btn:"btn-default",
                overall_data: "show-m",
                monthly_data: "show-n",
                daywise_data: 'show-n',
                tptapproval_data: 'show-n',
                tptremainder_data:'show-n',
                
            })
        }
        else if (tab == 'monthly') {
            this.setState({
                overall_btn: "btn-default",
                monthly_btn: "btn-success",
                daywise_btn: "btn-default",
                tptapproval_btn: "btn-default",
                tptremainder_btn: "btn-default",
                availabilityVsLoadingReport_btn:"btn-default",
                overall_data: "show-n",
                monthly_data: "show-m",
                daywise_data: 'show-n',
                tptapproval_data: 'show-n',
                tptremainder_data:'show-n'
            })
        } else if (tab == 'daywise') {
            this.setState({
                overall_btn: "btn-default",
                monthly_btn: "btn-default",
                daywise_btn: "btn-success",
                tptapproval_btn: "btn-default",
                tptremainder_btn: "btn-default",
                availabilityVsLoadingReport_btn:"btn-default",
                overall_data: "show-n",
                monthly_data: "show-n",
                daywise_data: 'show-m',
                tptapproval_data: 'show-n',
                tptremainder_data:'show-n'
            })
        } else if (tab == 'tptapproval') {
            this.setState({
                overall_btn: "btn-default",
                monthly_btn: "btn-default",
                daywise_btn: "btn-default",
                tptapproval_btn: "btn-success",
                tptremainder_btn: "btn-default",
                availabilityVsLoadingReport_btn:"btn-default",
                overall_data: "show-n",
                monthly_data: "show-n",
                daywise_data: 'show-n',
                tptapproval_data: 'show-m',
                tptremainder_data:'show-n'
            })
        }
        else if (tab == 'tptremaindermails') {
            this.setState({
                overall_btn: "btn-default",
                monthly_btn: "btn-default",
                daywise_btn: "btn-default",
                tptapproval_btn: "btn-default",
                tptremainder_btn:"btn-success",
                availabilityVsLoadingReport_btn:"btn-default",
                overall_data: "show-n",
                monthly_data: "show-n",
                daywise_data: 'show-n',
                tptapproval_data: 'show-n',
                tptremainder_data:'show-m'
                
            })
        }
        else{
            this.setState({
                overall_btn: "btn-default",
                monthly_btn: "btn-default",
                daywise_btn: "btn-default",
                tptapproval_btn: "btn-default",
                tptremainder_btn:"btn-default",
                availabilityVsLoadingReport_btn:"btn-success",
                overall_data: "show-n",
                monthly_data: "show-n",
                daywise_data: 'show-n',
                tptapproval_data: 'show-n',
                tptremainder_data:'show-n'
                
            })
        }
    }
    //tptremaindermails
    render() {

        var columnwithDefs1 = [
            {
                headerName: "Truck no",
                field: "Vehicle_Number",

                filter: true,
                resizable: true,
            },
            {
                headerName: "Total Monthly Avg",
                field: "Total_Monthly_Avg",

                filter: true,
                resizable: true,
            },

        ]

        var columnwithDefs2 = [
            {
                headerName: "Truck no",
                field: "Vehicle_Number",

                filter: true,
                resizable: true,
            },
            {
                headerName: "Transporter Name",
                field: "Transporter_Name",

                filter: true,
                resizable: true,
            },
            {
                headerName: "Transporter Code",
                field: "Transporter_Code",

                filter: true,
                resizable: true,
            },
            {
                headerName: "Installation Date",
                field: "installation_date",

                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    return getHyphenDDMMMYYYYHHMM(params.data.installation_date);
                },
            },
            {
                headerName: "Average",
                field: "Average",

                filter: true,
                resizable: true,
            }
        ]

        this.state.date_keys.map(e => {
            columnwithDefs2.push({
                headerName: `${e.substring(0, 10)}`,
                field: `${e}`,

                filter: true,
                resizable: true,
            })
        })


        var columnwithDefs3 = [
            {
                headerName: "Truck no",
                field: "Truck_No",

                filter: true,
                resizable: true,
            },
            {
                headerName: "Transporter Name",
                field: "Transporter_Name",

                filter: true,
                resizable: true,
            },
            {
                headerName: "Transporter Code",
                field: "Transporter_Code",

                filter: true,
                resizable: true,
            },
            {
                headerName: "Report Date",
                field: "Report_Date",

                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    return getHyphenDDMMMYYYYHHMM(params.data.Report_Date);
                },
            },
            {
                headerName: "Installation Date",
                field: "Installation_Date",

                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    return getHyphenDDMMMYYYYHHMM(params.data.Installation_Date);
                },
            },
            {
                headerName: "0",
                field: "0",
                filter: true,
                resizable: true,
            },
            {
                headerName: "1",
                field: "1",

                filter: true,
                resizable: true,
            },
            {
                headerName: "2",
                field: "2",

                filter: true,
                resizable: true,
            },
            {
                headerName: "3",
                field: "3",

                filter: true,
                resizable: true,
            },
            {
                headerName: "4",
                field: "4",

                filter: true,
                resizable: true,
            },
            {
                headerName: "5",
                field: "5",

                filter: true,
                resizable: true,
            },
            {
                headerName: "6",
                field: "6",

                filter: true,
                resizable: true,
            },
            {
                headerName: "7",
                field: "7",

                filter: true,
                resizable: true,
            },
            {
                headerName: "8",
                field: "8",

                filter: true,
                resizable: true,
            },
            {
                headerName: "9",
                field: "9",

                filter: true,
                resizable: true,
            },
            {
                headerName: "10",
                field: "10",

                filter: true,
                resizable: true,
            },
            {
                headerName: "11",
                field: "11",

                filter: true,
                resizable: true,
            },
            {
                headerName: "12",
                field: "12",

                filter: true,
                resizable: true,
            },
            {
                headerName: "13",
                field: "13",

                filter: true,
                resizable: true,
            },
            {
                headerName: "14",
                field: "14",

                filter: true,
                resizable: true,
            },
            {
                headerName: "15",
                field: "15",

                filter: true,
                resizable: true,
            },
            {
                headerName: "16",
                field: "16",

                filter: true,
                resizable: true,
            },
            {
                headerName: "17",
                field: "17",

                filter: true,
                resizable: true,
            },
            {
                headerName: "18",
                field: "18",

                filter: true,
                resizable: true,
            },
            {
                headerName: "19",
                field: "19",

                filter: true,
                resizable: true,
            },
            {
                headerName: "20",
                field: "20",

                filter: true,
                resizable: true,
            },
            {
                headerName: "21",
                field: "21",

                filter: true,
                resizable: true,
            },
            {
                headerName: "22",
                field: "22",

                filter: true,
                resizable: true,
            },
            {
                headerName: "23",
                field: "23",

                filter: true,
                resizable: true,
            },
            {
                headerName: "Daywise Manual Percentage",
                field: "Daywise_Manual_Percentage",

                filter: true,
                resizable: true,
            },

        ]

        var columnwithDefs4 = [
            {
                headerName: "Transporter Code",
                field: "transporter_code",

                filter: true,
                resizable: true,
            },
            {
                headerName: "Transporter Name",
                field: "transporter_name",

                filter: true,
                resizable: true,
            },
            {
                headerName: "Service Provider Name",
                field: "service_provider_name",

                filter: true,
                resizable: true,
            },
            // {
            //     headerName: "Name",
            //     field: "name",

            //     filter: true,
            //     resizable: true,
            // },
            {
                headerName: "Mobile No",
                field: "mobile_no_of_contracting_party_two_for_estamp",

                filter: true,
                resizable: true,
            },
            {
                headerName: "Firm Type",
                field: "firm_type",

                filter: true,
                resizable: true,
            },
            {
                headerName: "Email",
                field: "email",

                filter: true,
                resizable: true,
            },
            {
                headerName: "Contracting Party Address",
                field: "contracting_party_two_address",

                filter: true,
                resizable: true,
            },
        ]

        return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                ></SweetAlert>
                <div className="row">
                    <div className={'col-xl-12 col-lg-12'} >
                        <button className={'btn ' + this.state.overall_btn} style={{ paddingLeft: "15px" }} onClick={this.onCLickChangeTab.bind(this, "overall")} >Overall Average Summary</button>
                        <button className={'btn ' + this.state.monthly_btn} onClick={this.onCLickChangeTab.bind(this, "monthly")} >Monthly Analysis Data</button>
                        <button className={'btn ' + this.state.daywise_btn} onClick={this.onCLickChangeTab.bind(this, "daywise")} >Day Wise Data</button>
                        <button className={'btn ' + this.state.tptapproval_btn} onClick={this.onCLickChangeTab.bind(this, "tptapproval")} >TPT Approval Data</button>
                        <button className={'btn ' + this.state.tptremainder_btn} onClick={this.onCLickChangeTab.bind(this, "tptremaindermails")} >TPT Remainder Mails</button>
                        <button className={'btn ' + this.state.availabilityVsLoadingReport_btn} onClick={this.onCLickChangeTab.bind(this, "availabilityVsLoadingReport")} >Availability vs Loading Report</button>
                    </div>
                    {this.state.availabilityVsLoadingReport_btn == "btn-success" ? 
                        <div className="col-xl-12 col-lg-12">
                            <div className="card" style={{ minHeight: "100vh" }}>
                                <div className="card-header">
                                    <h5>
                                        Availability vs Loading Report
                                    </h5>
                                </div>
                                <div className={"card-body "} >
                                    <form className="row ml-3 mt-3" onSubmit={this.onSubmitAvailabilityVsLoadingReport}>
                                        <div className="col-xl-3 col-lg-3 form-group mb-5" >
                                            <label className="">Upload Loading Data *</label>
                                            <input type="file" name="LoadingData" id="LoadingData" onChange={this.AvailabilityVsLoadingReport_changeFileHandler} className="form-control" required />
                                        </div>
                                        <div className="col-xl-3 col-lg-3 form-group mb-5">
                                            <label className="col-xl-12 col-lg-12">&nbsp;&nbsp;</label>

                                            <a className="btn btn-primary" href={require('../../assets/json/loading_data_sample_templete - Sheet1.csv')} target="_blank">Sample Template</a>
                                        </div>
                                        <div className="col-xl-3 col-lg-3 form-group mb-5">
                                            <label className="">Upload Parking List Data *</label>
                                            <input type="file" name="ParkingListData" id="ParkingListData" onChange={this.AvailabilityVsLoadingReport_changeFileHandler} className="form-control" required />
                                        </div>
                                        <div className="col-xl-3 col-lg-3 form-group mb-5">
                                            <label className="col-xl-12 col-lg-12">&nbsp;&nbsp;</label>
                                            <a className="btn btn-primary" href={require('../../assets/json/parking_list_sample_template - Sheet1.csv')} target="_blank">Sample Template</a>
                                        </div>
                                        <div className="col-xl-3 col-lg-3 form-group mb-5">
                                            <label className="">Upload Calendar Data *</label>
                                            <input type="file" name="CalendarData" id="CalendarData" onChange={this.AvailabilityVsLoadingReport_changeFileHandler} className="form-control" required />
                                        </div>
                                        <div className="col-xl-3 col-lg-3 form-group mb-5">
                                            <label className="col-xl-12 col-lg-12">&nbsp;&nbsp;</label>
                                            <a className="btn btn-primary" href={require('../../assets/json/calendar_sample_templete - Sheet1.csv')} target="_blank">Sample Template</a>
                                        </div>
                                        <div className="col-xl-3 col-lg-3 form-group mb-5">
                                            <label className="">Upload Transporter Data</label>
                                            <input type="file" name="TransporterData" id="TransporterData" onChange={this.AvailabilityVsLoadingReport_changeFileHandler} className="form-control" />
                                        </div>
                                        <div className="col-xl-3 col-lg-3 form-group mb-5">
                                            <label className="col-xl-12 col-lg-12">&nbsp;&nbsp;</label>

                                            <a className="btn btn-primary" href={require('../../assets/json/transporters_sample_data - Sheet1.csv')} target="_blank">Sample Template</a>
                                        </div>
                                        <div className="col-xl-3 col-lg-3 form-group">
                                            <label className="">To Emails *</label>
                                            <input type="text" value= {this.state.availabilityVsLoadingReport_to_emails} onChange={(e)=>{
                                                
                                                    this.setState({availabilityVsLoadingReport_to_emails:e.target.value})
                                                
                                            }} className="form-control" required/>
                                        </div>
                                        <div className="col-xl-3 col-lg-3 form-group">
                                            <label className="">CC Emails *</label>
                                            <input type="text" value= {this.state.availabilityVsLoadingReport_cc_emails} onChange={(e)=>{
                                                
                                                    this.setState({availabilityVsLoadingReport_cc_emails:e.target.value})
                                                
                                            }} className="form-control" required/>
                                        </div>
                                        <div className="col-xl-3 col-lg-3 form-group">
                                            <label className="col-xl-12 col-lg-12">&nbsp;&nbsp;</label>
                                            <button className="btn btn-success" type="submit" >Submit</button>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div> 
                        :
                         <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            {(this.state.tptapproval_btn != 'btn-success' && this.state.tptremainder_btn == 'btn-default') || (this.state.tptremainder_btn != 'btn-success' && this.state.tptapproval_btn == 'btn-default')?
                            <>
                                <div className="card-header">
                                    <h5>
                                        Gps Uptime Report
                                        <span style={{ color: 'red' , float:"right" , fontWeight:"200" , fontSize:"16px" }}>* Current Month data will be visible after 25th of current Month</span>
                                    </h5>
                                </div>
                                
                                <div className="row ml-3 mt-3">
                                    <div className="col-xl-3 col-lg-3 form-group">
                                        <label>Month:</label>
                                        <Datetime
                                            value={this.state.selectedMonth}
                                            disableCloseOnClickOutside={false}
                                            closeOnSelect={true}
                                            inputProps={{
                                                placeholder: 'Select Month',
                                                name: 'plan_month',
                                                autoComplete: 'off'
                                            }}
                                            dateFormat="YYYY-MM" // Use 'MM' to display only the month
                                            viewMode="months" // Set view mode to 'months' to start with the month view
                                            name="plan_month"
                                            onChange={this.handlerStartDateTime.bind(this)}
                                        // isValidDate={current => {
                                        //     // Disable future months
                                        //     const today = new Date();
                                        //     return current.isBefore(today, 'month');
                                        // }}
                                        />
                                    </div>
                                    <div className="col-xl-5 col-lg-5 form-group">

                                    </div>
                                    <div className="col-xl-2 col-lg-2 form-group">
                                        <label>Add Trucks:</label>
                                        <input className="form-control" id="add_truck" required />
                                    </div>
                                    <div className="col-xl-2 col-lg-2 form-group">
                                        <label className="col-xl-12 col-lg-12">&nbsp;&nbsp;</label>
                                        <button className="btn btn-success" type="button" onClick={this.OnClickAddTrucks}>Submit</button>
                                    </div>


                                </div>
                            </>:
                            <>
                             <div className="card-header">
                                <h5>
                                {this.state.tptapproval_btn == 'btn-success'?"TPT Approval Data":"TPT Remainder Mails"}
                                </h5>
                            </div>
                            <div className="col-xl-12 col-lg-12" style={{float:"right"}}>
                                {this.state.tptapproval_btn == 'btn-success'?
                                <div style={{float:"right"}}>
                                    <label className="col-xl-12 col-lg-12">&nbsp;&nbsp;</label>
                                    <button className="btn btn-success" type="button" onClick={this.onUploadFile.bind(this,"uploadfile")}>Upload File</button>
                                </div>:
                                <div style={{float:"right"}}>
                                <label className="col-xl-12 col-lg-12">&nbsp;&nbsp;</label>
                                <button className="btn btn-success" type="button" onClick={this.onRemainderMails.bind(this,"transporterremaindermails")}>Transporters</button>
                                </div>
                                }       
                                <div style={{float:"right"}}>
                                    <label className="col-xl-12 col-lg-12">&nbsp;&nbsp;</label>
                                    <button className="btn btn-success" type="button" onClick={this.onAddTransporters.bind(this,"addtruck")}>Add Transporters</button>
                                </div>
                            </div>
                            </> 
                            }
                            <div className={"card-body "+this.state.overall_data}>
                                
                                <div className={"card-header"}>
                                    <h5>
                                        Overall Average Summary
                                    </h5>
                                </div>
                                <div
                                    id="myGrid"
                                    style={{ height: "580px", width: "100%" }}
                                    className={"ag-theme-balham "}
                                >
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs1}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.uptime_sheet_1}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        frameworkComponents={this.state.frameworkComponents}
                                        detailCellRendererParams={
                                            this.state.detailCellRendererParams
                                        }
                                        //editType={editType}
                                        stopEditingWhenGridLosesFocus={true}
                                        //floatingFilter={true}
                                        //enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        rowClassRules={this.state.rowClassRules}
                                        //onCellClicked={this.onRowClicked.bind(this)}
                                        gridOptions={{
                                            context: { componentParent: this },
                                        }}
                                        masterDetail={true}
                                        overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                    />
                                </div>
                            </div>
                            <div className={"card-body "+this.state.monthly_data}>
                                
                                <div className={"card-header"}>
                                    <h5>
                                        Monthly Analysis Sheet
                                    </h5>
                                </div>
                                <div
                                    id="myGrid"
                                    style={{ height: "580px", width: "100%" }}
                                    className={"ag-theme-balham "}
                                >
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs2}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.uptime_sheet_2}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        frameworkComponents={this.state.frameworkComponents}
                                        detailCellRendererParams={
                                            this.state.detailCellRendererParams
                                        }
                                        //editType={editType}
                                        stopEditingWhenGridLosesFocus={true}
                                        //floatingFilter={true}
                                        //enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        rowClassRules={this.state.rowClassRules}
                                        //onCellClicked={this.onRowClicked.bind(this)}
                                        gridOptions={{
                                            context: { componentParent: this },
                                        }}
                                        masterDetail={true}
                                        overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                    />
                                </div>

                            </div>
                            <div className={"card-body "+this.state.daywise_data}>
                                <div className={"card-header"}>
                                    <h5>
                                        Day Wise Sheet
                                    </h5>
                                </div>
                                <div
                                    id="myGrid"
                                    style={{ height: "580px", width: "100%" }}
                                    className={"ag-theme-balham "}
                                >
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs3}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.uptime_sheet_3}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        frameworkComponents={this.state.frameworkComponents}
                                        detailCellRendererParams={
                                            this.state.detailCellRendererParams
                                        }
                                        //editType={editType}
                                        stopEditingWhenGridLosesFocus={true}
                                        //floatingFilter={true}
                                        //enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        rowClassRules={this.state.rowClassRules}
                                        //onCellClicked={this.onRowClicked.bind(this)}
                                        gridOptions={{
                                            context: { componentParent: this },
                                        }}
                                        masterDetail={true}
                                        overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                    />
                                </div>
                            </div>
                            <div className={"card-body "+this.state.tptapproval_data}>
                                <div
                                    id="myGrid"
                                    style={{ height: "580px", width: "100%" }}
                                    className={"ag-theme-balham "}
                                >
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs4}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.uptime_sheet_4}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        frameworkComponents={this.state.frameworkComponents}
                                        detailCellRendererParams={
                                            this.state.detailCellRendererParams
                                        }
                                        //editType={editType}
                                        stopEditingWhenGridLosesFocus={true}
                                        //floatingFilter={true}
                                        //enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        rowClassRules={this.state.rowClassRules}
                                        //onCellClicked={this.onRowClicked.bind(this)}
                                        gridOptions={{
                                            context: { componentParent: this },
                                        }}
                                        masterDetail={true}
                                        overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                    />
                                </div>
                            </div>
                            <div className={"card-body "+this.state.tptremainder_data}>
                                <div
                                    id="myGrid"
                                    style={{ height: "580px", width: "100%" }}
                                    className={"ag-theme-balham "}
                                >
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs4}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.uptime_sheet_4}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        frameworkComponents={this.state.frameworkComponents}
                                        detailCellRendererParams={
                                            this.state.detailCellRendererParams
                                        }
                                        //editType={editType}
                                        stopEditingWhenGridLosesFocus={true}
                                        //floatingFilter={true}
                                        //enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        rowClassRules={this.state.rowClassRules}
                                        //onCellClicked={this.onRowClicked.bind(this)}
                                        gridOptions={{
                                            context: { componentParent: this },
                                        }}
                                        masterDetail={true}
                                        overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                    />
                                </div>
                            </div>
                        </div>
                    </div> }
                   
                </div>
                {this.state.slider_name == "uploadfile" ?
                <div className={"slide-r " + (this.state.sliderTranslate)} >

					<div className="slide-r-title">
						<h4>
							Upload File
							{/* <span onClick={this.onCloseUploadDiv.bind(this)} className={"btn btn-warning c-btn pull-right "+(this.state.sliderTranslate)}>X</span> */}
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseUploadDiv.bind(this)} >X</span>
						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className={"dataLoad " + (this.state.loadshow)}>
							<div style={{ position: "absolute", left: "38%" }}>
								<div class="loader-box">
									<div class="loader-box">
										<div class="rotate dashed colored"></div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-12 col-lg-12">
							<div className={"col-xl-12 col-lg-12 alert alert-light uploadcscmsg " + (this.state.alerterrshow) + " " + (this.state.alerterrmg)}>{this.state.csverrmessage}</div>

							<form id="upform" className="theme-form" onSubmit={this.uploadFormHandler}>
                            {/* <div className="col-xl-3 col-lg-3 form-group">
								<input type="text" name="truck_no" placeholder="Truck No" className="form-control" value="" onChange="" />
	                    	</div> */}
                                <div className="form-group">
                                <label className="">Date</label>
                                    <Datetime inputProps={{ placeholder: 'Date', name: 'date', autoComplete:'off' }} dateFormat="MMMM YYYY" timeFormat={false} onChange={this.handlermonthYear} required/>
                                </div>

                                <div className="form-group mt-20p">
									<label className="">Upload File</label>
									<input type="file" name="uploadFile" onChange={this.changeFileHandler} className="form-control" required />
								</div>
								<div className="form-group">
									<button type="submit" className="btn btn-success">Submit</button>
									<button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
								</div>
								<div className="form-group">
									<a className="btn btn-primary" href={require('../../assets/json/TPT Approval Data - Sample.csv')} target="_blank">Sample Template</a>
								</div>
							</form>
							{/* <div className="form-group mt-50p">
			 					<a href={require('../../assets/json/truck_template.csv')} target="_blank" className="btn btn-info">Download Template</a>
								</div> */ }
						</div>
					</div>
				</div>:
                this.state.slider_name == "transporterremaindermails" ?
                <div className={"slide-r " + (this.state.sliderTranslate)} >

					<div className="slide-r-title">
						<h4>
							Select Transporters
							{/* <span onClick={this.onCloseUploadDiv.bind(this)} className={"btn btn-warning c-btn pull-right "+(this.state.sliderTranslate)}>X</span> */}
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseUploadDiv.bind(this)} >X</span>
						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className={"dataLoad " + (this.state.loadshow)}>
							<div style={{ position: "absolute", left: "38%" }}>
								<div class="loader-box">
									<div class="loader-box">
										<div class="rotate dashed colored"></div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-12 col-lg-12">
							<div className={"col-xl-12 col-lg-12 alert alert-light uploadcscmsg " + (this.state.alerterrshow) + " " + (this.state.alerterrmg)}>{this.state.csverrmessage}</div>

							<form id="upform" className="theme-form" onSubmit={this.onSelectRemainderMailTransporters}>
                            {/* <div className="col-xl-3 col-lg-3 form-group">
								<input type="text" name="truck_no" placeholder="Truck No" className="form-control" value="" onChange="" />
	                    	</div> */}
                                <div className="form-group">
                                <label className="">Date</label>
                                    <Datetime inputProps={{ placeholder: 'Date', name: 'date', autoComplete:'off' }} dateFormat="MMMM YYYY" timeFormat={false} onChange={this.handlermonthYear} required/>
                                </div>
                                <div className="form-group">
                                <label className="">First Mail Sent On</label>
                                    <Datetime inputProps={{ placeholder: 'Date', name: 'date', autoComplete:'off' }} dateFormat="YYYY-MM-DD" timeFormat={false} onChange={this.handlerFirstMailSentOn} required/>
                                </div>
                                <div className="form-group">
                                    <label>Select Consigner: </label>
                                    <Select placeholder={"Select Transporters"} closeMenuOnSelect={false} value={this.state.consigner} isMulti="true" className="border-radius-0" onChange={this.changeConsignerArrayHandler.bind(this)} style={{ borderRadius: "0px" }} options={this.selectConsignerOptionsItems()} required />
                                </div>
                                {/* <div className="form-group mt-20p">
									<label className="">Upload File</label>
									<input type="file" name="uploadFile" onChange={this.changeFileHandler} className="form-control" required />
								</div> */}
								<div className="form-group">
									<button type="submit" className="btn btn-success">Submit</button>
									{/* <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button> */}
								</div>
								{/* <div className="form-group">
									<a className="btn btn-primary" href={require('../../assets/json/TPT Approval Data - Sample.csv')} target="_blank">Sample Template</a>
								</div> */}
							</form>
							{/* <div className="form-group mt-50p">
			 					<a href={require('../../assets/json/truck_template.csv')} target="_blank" className="btn btn-info">Download Template</a>
								</div> */ }
						</div>
					</div>
				</div>
                :<div className={"slide-r " + (this.state.sliderTranslate)} >

					<div className="slide-r-title">
						<h4>
							Add Trucks
							{/* <span onClick={this.onCloseUploadDiv.bind(this)} className={"btn btn-warning c-btn pull-right "+(this.state.sliderTranslate)}>X</span> */}
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseUploadDiv.bind(this)} >X</span>
						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className={"dataLoad " + (this.state.loadshow)}>
							<div style={{ position: "absolute", left: "38%" }}>
								<div class="loader-box">
									<div class="loader-box">
										<div class="rotate dashed colored"></div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-12 col-lg-12">
							<div className={"col-xl-12 col-lg-12 alert alert-light uploadcscmsg " + (this.state.alerterrshow) + " " + (this.state.alerterrmg)}>{this.state.csverrmessage}</div>

							<form id="upform" className="theme-form" onSubmit={this.addTransportersFormHandler}>
                                {/* <div className="form-group mt-20p">
									<label className="">Upload File</label>
									<input type="file" name="uploadFile" onChange={this.changeFileHandler} className="form-control" required />
								</div> */}
                                <div className="form-group mt-20p">
									<label className="">Transporter Code*</label>
									<input type="number" name="code" id="code" onChange="" className="form-control" required />
								</div>
                                <div className="form-group mt-20p">
									<label className="">Transporter Name*</label>
									<input type="text" name="name" id="name" onChange="" className="form-control" required />
								</div>
                                <div className="form-group mt-20p">
									<label className="">Email*</label>
									<input type="email" name="email" id="email" onChange="" className="form-control" required />
								</div>
                                <div className="form-group mt-20p">
									<label className="">Mobile No</label>
									<input type="number" name="mobile" id="mobile" onChange="" className="form-control" />
								</div>
                                <div className="form-group mt-20p">
									<label className="">Service Provider Name</label>
									<input type="text" name="serviceprovidername" id="serviceprovidername" onChange="" className="form-control"  />
								</div>
                                <div className="form-group mt-20p">
									<label className="">Firm Type</label>
									<input type="text" name="firmtype" id="firmtype" onChange="" className="form-control"  />
								</div>
                                <div className="form-group mt-20p">
									<label className="">Contracting Party Address</label>
									<input type="text" name="partyaddress" id="partyaddress" onChange="" className="form-control"/>
								</div>
                                <div className="form-group mt-20p">
									<label className="">contracting Party Authorized Signatory Name</label>
									<input type="text" name="signatoryname" id="signatoryname" onChange="" className="form-control"  />
								</div>
								<div className="form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
							</form>
							{/* <div className="form-group mt-50p">
			 					<a href={require('../../assets/json/truck_template.csv')} target="_blank" className="btn btn-info">Download Template</a>
								</div> */ }
						</div>
					</div>
				</div>}
                <div
                    className={"overlay-part " + this.state.overly}
                    onClick={this.onClickHideAll.bind(this)}
                ></div>
                <div className={"dataLoadpage " + this.state.loadshow}></div>
                <div className={"dataLoadpageimg " + this.state.loadshow}>
                    <div class="loader-box">
                        <div class="loader-box">
                            <div class="rotate dashed colored"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function loadScript(url) {
    var index = window.document.getElementsByTagName("script")[0];
    var script = window.document.createElement("script");
    script.src = url;
    script.async = true;
    script.defer = true;
    index.parentNode.insertBefore(script, index);
}


function ConvertSeconds(n) {
    var days = Math.round(parseInt(n / (24 * 3600)));

    n = n % (24 * 3600);
    var hours = Math.round(parseInt(n / 3600));

    n %= 3600;
    var minutes = Math.round(n / 60);

    n %= 60;
    var seconds = Math.round(n);

    return (
        days + " Day(s) " + hours + " Hrs " + minutes + " mins " + seconds + " secs"
    );
}

function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
    }
    return true;
}
function timeConvert(n) {
    var num = n;
    var hours = num / 3600;
    var rhours = parseInt(hours);
    var minutes = (num - rhours * 3600) / 60;
    var rminutes = Math.round(minutes);
    return rhours + " hour(s) and " + rminutes + " minute(s).";
}
function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}
function loadDatepicker() {
    $(".datepicker").datepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
    });
}

window.onload = function () {
    var today = new Date();
    var time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    $("#manual_reported_on").datetimepicker({
        mask: "39-19-9999 29:59",
        format: "d-m-Y H:i",
        onShow: false,
        step: 5,
        minTime: time,
    });
    $("#manual_parking_out").datetimepicker({
        mask: "39-19-9999 29:59",
        format: "d-m-Y H:i",
        onShow: false,
        step: 5,
        minTime: time,
    });

    $(".styles_modal__gNwvD").css({ width: "450px" });
};
document.addEventListener("turbolinks:before-render", () => {
    loadDateTimeScript();
});

function loadDateTimeScript() {
    var today = new Date();
    var time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    $("#manual_reported_on").datetimepicker({
        mask: "39-19-9999 29:59",
        format: "d-m-Y H:i",
        onShow: false,
        step: 5,
        minTime: time,
    });
    $("#manual_parking_out").datetimepicker({
        mask: "39-19-9999 29:59",
        format: "d-m-Y H:i",
        onShow: false,
        step: 5,
        minTime: time,
    });

    var index = window.document.getElementsByTagName("script")[1];
    var script = window.document.createElement("script");
    script.src =
        "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js";
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true;
    script.defer = true;
    index.parentNode.insertBefore(script, index);
}

function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor((seconds % (3600 * 24)) / 3600);
    var m = Math.floor((seconds % 3600) / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}

$(document).ready(function () {
    $(document).on("click", ".dropdown-truck-no", function () {
        var vals = $(this).text();
        $("#manual_truck").val(vals);
        $(".trucks-dropdown").removeClass("show-m");
        $(".trucks-dropdown").addClass("show-n");
    });

    $("body").on("click", function (e) {
        var container = $("#manual_truck");
        if (!container.is(e.target)) {
            try {
                if (
                    $(".trucks-dropdown")
                        .attr("class")
                        .includes("show-m") ||
                    $(".trucks-dropdown").attr("class") == "trucks-dropdown"
                ) {
                    $(".trucks-dropdown").removeClass("show-m");
                    $(".trucks-dropdown").addClass("show-n");
                }
            } catch (e) { }
        }
    });
});

function secondsToHM(seconds) {
    seconds = Number(seconds);
    // var d = Math.floor(seconds / (3600*24));
    // var h = Math.floor(seconds % (3600*24) / 3600);
    // var m = Math.floor(seconds % 3600 / 60);
    // var s = Math.floor(seconds % 60);

    // var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    // var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    // var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    // return dDisplay + hDisplay + mDisplay;

    var hh = Math.floor((seconds / 3600));
    var hrr = Math.floor(seconds - (hh * 3600));
    var mm = Math.floor(hrr / 60)
    if (mm < 10) {
        var mins = "0" + mm;
    }
    else {
        mins = mm;
    }

    if (hh < 10) {
        var hrs = "0" + hh;
    }
    else {
        hrs = hh;
    }
    return hrs + ":" + mins;
}