import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules, AllEnterpriseModules} from '@ag-grid-enterprise/all-modules';
// import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import CSVFileValidator from 'csv-file-validator';
import Map from '../common/drawmap';
import FileBase64 from 'react-file-base64';
import redirectURL from '../redirectURL';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import MapComponent from './mapLocationComponent';
import Consignmentforceclose from '../manage/consignmentforceclose';
import $ from 'jquery';
// import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';

var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

var childJson = []

export default class AirCargoExportForm extends Component{
        constructor(props){
        super(props);
        this.state = {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            csvcontent:"",
            file : "",
            loadshow:'show-n',
            overly:'show-n',
            uploadYard : "",
            uploadDispatchDate : "",
            rowData:"",
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:50,
            geofencelist:[],
            geoFenceData:"",
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            dept_code:"",
            pagetitle:"",
            bulkslide:"",
            certOfOrigin:"",
            dg:"",
            date:"",
            deptName:"",
            indenterName:"",
            mobile:"",
            indenterEmail:"",
            ringiNo:"",
            ringiDesc:"",
            cargoDeliveryPlant:"",
            itemDesc:"",
            grossWt:"",
            dimensions:"",
            cargo_chargeable_weight:"",
            supplierName:"",
            supplierAddr:"",
            supplierCnctPerson:"",
            country:"",
            destinationAirport:"",
            emailID:"",
            phone:"",
            deliveryTerms:"Ex Works",
            pickUpAddr:"",
            cargoDate:"",
            commercialInvoiceDate:"",
            commercialInvoice:"",
            nature : "",
            payment_terms : "",
            export_incentive : "",
            shipmentReturn : "",
            deliveryLabel : "Pick Up Address",
            airportName:"",
            port : "",
            cargo_date:"",
            rows: [{}],
            ringirows: [{}],
            packetrows: [{}],
            brequest:"activet",
            prepaid:"",
            track:"",
            requestData:"show-m",
            trackData:"show-n",
            trackResData : 'show-n',
            ffsuppliers: [],
            requestFields:"show-m",
            prepaidFields:"show-n",
            addresstxt:"",
            request_type:1,
            pagetitle:"Booking Request (Air Exports)",
            invoice_file : "",
            packing_list_file : "",
            sqc_list_file : "",
            annex_file : "",
            gr_wavier_file : "",
            msds_file : "",
            iip_file : "",
            //components : {datepicker:getDatePicker()}
        }
        this.changeHandler = this.changeHandler.bind(this);
        this.packetsHandler = this.packetsHandler.bind(this);
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount(){
        loadDateTimeScript();
        var pagetitle = "Booking Request (Air Exports)";
        //$(".requestFields").attr("required",true);
        this.setState({
            pagetitle : pagetitle,
        }) 
        $(".prepaidFields").removeAttr("required",true);
        $("#no_of_packets").val(1);
        var d = moment.parseZone(new Date()).utcOffset("+05:30")._d;
        var date = moment.parseZone(d).format("DD-MM-YYYY");
        $("#dateField").val(date);
        $("#addMrERp").hide();
        redirectURL.post("/consignments/aircargosuppliers").then((response)=>{
            var ffsuppliers = response.data.ffsuppliers;
            console.log(ffsuppliers,"ffsuppliers")
            this.setState({
                ffsuppliers : ffsuppliers,
            }) 
        }).catch((e)=>{
            console.log(e);
        })
    }

    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
    }
    
onClickHideAll(){
    this.setState({
        loadshow:'show-n',
        overly:'show-n',
        bulkslide:''
    });
    
}
closeAlert = () => {
    this.setState({
        show: false
    });
}

changeHandler = (event) => {
    console.log("Select", event.target.value);
    let name = event.target.name;
    console.log(name);
    let value = event.target.value;
    this.setState({[name]:value});

    // if(name == "deliveryTerms")
    // {
    //     $(".dterms").removeAttr("required",true);
    //     if(value == "Ex Works")
    //     {
    //         $("#pickUpAddr").prop("required",true);
    //     }
    //     else if(value == "FCA")
    //     {
    //         $("#port").prop("required",true);
    //     }
    //     else if(value == "FOB")
    //     {
    //         $("#airportName").prop("required",true);
    //     }
    // }
    if(name == "payment_terms")
    {
        if(value == "Free of Cost (FOC)")
        {
            this.setState({export_incentive:"no"});
            $("#export_incentive").val("no")
        }
    }
}
dateValidation = (currentDate) => {
    return currentDate.isBefore(moment(this.state.deliverymaxDate)) && currentDate.isAfter(moment(this.state.deliveryminDate));
};
changeFileHandler = async (e) => {
    console.log(e.target.name)
    this.setState({ [e.target.name]: e.target.files[0] });
}
formHandler(event){
    event.preventDefault();
    var eventTarget = event.target;
    //console.log(this.state.file,"this.state.file");
    var request_type = this.state.request_type;
    var dateField=$("#dateField").val();
    //console.log(dateField);
    if(dateField == "__-__-____")
    {
        dateField = "";
    }
    
    var cargoDate=$("#cargoDate").val();
    if(cargoDate == "__-__-____")
    {
        cargoDate = "";
    }

    var commercialInvoiceDate=$("#commercialInvoiceDate").val();
    if(commercialInvoiceDate == "__-__-____")
    {
        commercialInvoiceDate = "";
    }

    var eta_igi_airport_date=$("#eta_igi_airport_date").val();

    var rngLength=$("#rngLength").val();
    var ringiData=[];
    for(var r=0;r<rngLength;r++)
    {
        var ringiFile=$("#ringi_file_"+r).val();
        ringiFile = ringiFile.substring(ringiFile.lastIndexOf("\\") + 1, ringiFile.length);
        var ringiDesc=$("#ringiDesc_"+r).val();
        if(ringiFile !='' || ringiDesc !='')
        {
            var rngtemp = {"ringi_file":ringiFile,"ringi_desc":ringiDesc}
            ringiData.push(rngtemp)
        }
    }
    var pktLength=$("#no_of_packets").val();
    var cargoData=[];
    for(var p=0;p<pktLength;p++)
    {
        var itemDesc=$("#itemDesc_"+p).val();
        var grossWt=$("#grossWt_"+p).val();
        var dimensions=$("#dimensions_"+p).val();
        var ccw=$("#cargo_chargeable_weight_"+p).val();
        if(itemDesc !='' || grossWt !='' || dimensions !='' || ccw !='')
        {
            var temp = {"item_description":itemDesc,"cargo_gross_weight":grossWt,"dimensions":dimensions,"cargo_chargeable_weight":ccw}
            cargoData.push(temp)
        }
    }
    cargoData=JSON.stringify(cargoData);
    ringiData=JSON.stringify(ringiData);
    var formfields = {
        date : dateField,
        dept_name : this.state.deptName,
        indenter_name : this.state.indenterName,
        mobile : $("#mobile").val(),
        indenter_email : this.state.indenterEmail,
        cargo_delivery_plant : this.state.cargoDeliveryPlant,
        supplier_name : this.state.supplierName,
        supplier_address : this.state.supplierAddr,
        supplier_contact_person : this.state.supplierCnctPerson,
        country : this.state.country,
        destinationAirport : this.state.destinationAirport,
        email : this.state.emailID,
        phone : this.state.phone,
        delivery_terms : this.state.deliveryTerms,
        pick_up_address : this.state.pickUpAddr,
        port : this.state.port,
        airport_name : this.state.airportName,
        cargo_date : cargoDate,
        commercialInvoiceDate : commercialInvoiceDate,
        commercialInvoice : this.state.commercialInvoice,
        nature : this.state.nature,
        payment_terms : this.state.payment_terms,
        export_incentive : this.state.export_incentive,
        shipmentReturn : this.state.shipmentReturn,
        certOfOrigin : this.state.certOfOrigin,
        dg : this.state.dg
    }
    
    var flag= 0;
    var emailflag = 0;
    for(let key in formfields)
    {
        if(request_type == 1)
        {
            if(key != "no_of_packets" && key !="port" && key !="airport_name" && key !="mobile" && key !="pick_up_address" && key !="nature")
            {
                if(formfields[key] !=undefined && formfields[key] !='')
                {                
                    if(formfields[key].replace(/\s/g,"").length == 0)
                    {
                        flag = 1;
                        console.log(key,formfields[key],"required");
                    }
                    
                    // if(key == "mobile")
                    // {
                    //     if(formfields[key].toString().length !=10)
                    //     {
                    //         flag = 3;
                    //         console.log(key,formfields[key]);
                    //     }
                    // }
                }
                else
                {
                    flag = 1;
                    console.log(key,formfields[key],"required");
                }
            }
            else if(key == "indenter_email")
            {   
                // console.log(formfields[key].split("@"))
                if(formfields[key].split("@")[1] != "maruti.co.in") 
                {
                    emailflag = 1;
                }
            }
            else
            {
            }
        }
        else
        {
            if(key == "indenter_email")
            {   
                // console.log(formfields[key].split("@"))
                if(formfields[key].split("@")[1] != "maruti.co.in") 
                {
                    emailflag = 1;
                }
            }
        }
    }
    if(flag == 0)
    {
        if(emailflag == 0)
        {
            this.setState({
                loadshow:'show-m',
                overly:'show-m',
            })
            // console.log(ringiNo)
            let saveData = new FormData();
            saveData.append("request_type", request_type);
            saveData.append("rngLength", rngLength);
            for(var rn=0;rn<rngLength;rn++)
            {
                var ringiFile="ringi_file_"+rn;
                
                saveData.append(ringiFile,this.state[ringiFile]);
                console.log(this.state[ringiFile]);
            }
            saveData.append("invoice_file",this.state.invoice_file);
            saveData.append("packing_list_file",this.state.packing_list_file);
            saveData.append("sqc_list_file",this.state.sqc_list_file);
            saveData.append("annex_file",this.state.annex_file);
            saveData.append("gr_wavier_file",this.state.gr_wavier_file);
            saveData.append("msds_file",this.state.msds_file);
            saveData.append("iip_file",this.state.iip_file);
            var no_of_packets=$("#no_of_packets").val();
            saveData.append("date",dateField);
            saveData.append("dept_name",this.state.deptName);
            saveData.append("indenter_name",this.state.indenterName);
            saveData.append("mobile",$("#mobile").val());
            saveData.append("indenter_email",this.state.indenterEmail);
            saveData.append("ringiData",ringiData);
            saveData.append("pickup_plant",this.state.cargoDeliveryPlant);
            saveData.append("no_of_packets",no_of_packets);
            saveData.append("cargoData",cargoData);
            saveData.append("consignee_name",this.state.supplierName);
            saveData.append("consignee_address",this.state.supplierAddr);
            saveData.append("consignee_contact_person",this.state.supplierCnctPerson);
            saveData.append("country",this.state.country);
            saveData.append("destinationAirport",this.state.destinationAirport);
            saveData.append("email",this.state.emailID);
            saveData.append("phone",this.state.phone);
            saveData.append("delivery_terms",this.state.deliveryTerms);
            saveData.append("pick_up_address",this.state.pickUpAddr);
            saveData.append("port",this.state.port);
            saveData.append("airport_name",this.state.airportName);
            saveData.append("cargo_date",cargoDate);
            saveData.append("commercialInvoice",this.state.commercialInvoice);
            saveData.append("commercialInvoiceDate",commercialInvoiceDate);
            saveData.append("nature",this.state.nature);
            saveData.append("payment_terms",this.state.payment_terms);
            saveData.append("export_incentive",this.state.export_incentive);
            saveData.append("shipmentReturn",this.state.shipmentReturn);
            saveData.append("certOfOrigin",this.state.certOfOrigin);
            saveData.append("dg",this.state.dg);
            saveData.append("eta_igi_airport_date",eta_igi_airport_date);
            //var ff_code=$("#assign_to_ff").val();
            //var assign_to_ff=$("#assign_to_ff_custom").val();
            //saveData.append("ff_code",ff_code);
            saveData.append("assign_to_ff",this.state.assign_to_ff_custom);
            saveData.append("ff_contact_person_details",this.state.ff_contact_person_details);
            saveData.append("created_by",localStorage.getItem("email"));
            saveData.append("created_by_user",localStorage.getItem("username"));
            // saveData.append("ringiNo",this.state.ringiNo);
            
            
            // var saveData={
            //     "formfields" : formfields,
            //     "ringiNo" : this.state.ringiNo,  
            // }
            
            redirectURL.post("/consignments/cargoExportsFormData",saveData,{
                headers:{
                    'content-type': 'multipart/form-data',
                    'Access-Control-Allow-Origin':"*",
                    'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, DELETE',
                    'Access-Control-Allow-Headers': 'Origin, Content-Type'
                }
            }).then((response)=>{   
                this.setState({
                    basicTitle:"Success",
                    basicType : "success",
                    show : true,
                    loadshow:'show-n',
                    overly:'show-n',
                });
                window.location.reload();
            }).catch((e)=>{
                console.log(e);
            })
        }
        else{
            this.setState({
                basicTitle:"Invalid Email. Must be maruti.co.in",
                basicType : "warning",
                show : true
            })
        }        
    }
    else if(flag = 1)
    {
        this.setState({
            basicTitle:"Fields Should not be Empty",
            basicType : "warning",
            show : true
        })
    }
    else if(flag = 2)
    {
        this.setState({
            basicTitle:"No Of Packets should be greater than 0",
            basicType : "warning",
            show : true
        })
    }
    else if(flag = 3)
    {
        this.setState({
            basicTitle:"Mobile Number should be 10 Digits",
            basicType : "warning",
            show : true
        })
    }
}

handleRemoveRow = (idx) => {
    this.state.rows.splice(idx, 1);
    this.setState({ rows: this.state.rows });
};

handleAddRingiRow = () => {
    const item = {
        ringi_file: "",
        ringiDesc: "",
    };
    this.setState({
        ringirows: [...this.state.ringirows, item]
    });
};
handleRemoveRingiRow = (idx) => {
    this.state.ringirows.splice(idx, 1);
    this.setState({ ringirows: this.state.ringirows });
};

packetsHandler = (e)=> {
    var noOfPackets = e.target.value;
    if(parseInt(noOfPackets) >= 1)
    {
        var lespcks = parseInt(noOfPackets)-1;
        var bhanu=[{}];
        for(var n=0;n<lespcks;n++)
        {
            const item = {
                itemDesc: "",
                grossWt: "",
                dimensions: "",
                cargo_chargeable_weight:"",
            };
            this.setState({
                packetrows: [...bhanu, item]
            });
            var bhanu=[...bhanu, item];
        }
    }
    else
    {
        alert("No of packets should be greater than 0");
        $("#no_of_packets").val(1);
        const item = {
            itemDesc: "",
            grossWt: "",
            dimensions: "",
            cargo_chargeable_weight:"",
        };
        this.setState({
            packetrows: [item]
        });
    }
}

onClickTab(pageType){
    if(pageType == "brequest")
    {
        var brequest = "activet";
        var ffupdates = "";
        var track = "";
        var requestData = "show-m";
        var trackData = "show-n";
        var requestFields = "show-m";
        var prepaidFields = "show-n";
        var addresstxt = "";
        var request_type = 1;
        var pagetitle = "Booking Request (Air Exports)";
        //$(".requestFields").attr("required",true);
        $(".prepaidFields").removeAttr("required",true);
    }
    else if(pageType == "prepaid")
    {
        var brequest = "";
        var prepaid = "activet";
        var track = "";
        var requestData = "show-m";
        var trackData = "show-n";
        var requestFields = "show-n";
        var prepaidFields = "show-m";
        var addresstxt = "(To be mentioned on BOE)";
        var request_type = 2;
        var pagetitle = "Booking Request For Prepaid Shipments (Air Exports)";
        $(".requestFields").removeAttr("required",true);
        $(".prepaidFields").attr("required",true);
    }
    else if(pageType == "track")
    {
        var brequest = "";
        var prepaid = "";
        var track = "activet";
        var requestData = "show-n";
        var trackData = "show-m";
        var requestFields = "show-n";
        var prepaidFields = "show-n";
        var addresstxt = "";
        var request_type = "";
        var pagetitle ="";
    }
    this.setState({
        brequest:brequest,
        prepaid:prepaid,
        track:track,
        requestData:requestData,
        trackData:trackData,
        requestFields:requestFields,
        prepaidFields:prepaidFields,
        addresstxt:addresstxt,
        request_type:request_type,
        pagetitle:pagetitle
    })
}

trackHandler(event){
    event.preventDefault();
    var invoice_no = $("#invoice_no").val();
    if(invoice_no !=undefined && invoice_no !='')
    {
        this.setState({
            loadshow:'show-m',
            overly:'show-m',
        })
        var params = {
            invoice_no : invoice_no
        }
        redirectURL.post("/consignments/getAirCargoExportsStatus",params)    
        .then((response) => {
            var records = response.data;
            this.setState({
                rowData : records,
                trackResData : 'show-m',
                loadshow:'show-n',
                overly:'show-n',
            })
        })
        .catch(function(e){
            console.log("Error ",e)
        })
    }
    else
    {
        this.setState({
            basicTitle:"Please enter Indenter Email",
            basicType : "warning",
            show : true
        })
    }
}

render(){
    var columnwithDefs = [
        {
            headerName: "Job No",
            field: "job_no",
            width:130,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Date",
            field: "date",
            width:130,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.date !=undefined && params.data.date !='')
                {
                    return moment.parseZone(params.data.date).format("DD-MM-YYYY")
                }
            }
        },
        {
            headerName: "Department Name",
            field: "dept_name",
            width:150,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Indenter Name",
            field: "indenter_name",
            width:150,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Mobile No",
            field: "mobile",
            width:100,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "Indenter Email",
            field: "indenter_email",
            width:200,
            filter: true,
            resizable: true,           
        },
        {
            headerName: "ETA IGI Airport Date",
            field: "eta_igi_airport_date",
            width:150,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.eta_igi_airport_date !=undefined && params.data.eta_igi_airport_date !="")
                {
                    return moment.parseZone(params.data.eta_igi_airport_date).format("DD-MM-YYYY") 
                }
                else
                {
                    return "N/A";
                }
            }
        },
        {
            headerName: "ATA IGI Airport Date",
            field: "ata_igi_airport_date",
            width:150,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.ata_igi_airport_date !=undefined && params.data.ata_igi_airport_date !="")
                {
                    return moment.parseZone(params.data.ata_igi_airport_date).format("DD-MM-YYYY") 
                }
                else
                {
                    return "N/A";
                }
            }
        },
        {
            headerName: "OOC Date",
            field: "ooc_date",
            width:150,
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.ooc_date !=undefined && params.data.ooc_date !="")
                {
                    return moment.parseZone(params.data.ooc_date).format("DD-MM-YYYY") 
                }
                else
                {
                    return "N/A";
                }
            }
        },
        {
            headerName: "Status",
            field: "status_text",
            width:250,
            pinned:"right",
            filter: true,
            resizable: true,
            valueGetter: function(params){
                if(params.data.rejected == 1)
                {
                    return "Rejected";
                }
                else if(params.data.actual_delivery_at_msil !=undefined && params.data.actual_delivery_at_msil !="")
                {
                    return "Delivered To MSIL";
                }
                else if(params.data.ooc_date !=undefined && params.data.ooc_date !="")
                {
                    return "Custom Cleared";
                }
                else if(params.data.ata_igi_airport_date !=undefined && params.data.ata_igi_airport_date !="")
                {
                    return "Flight Arrived At Destination Airport";
                }
                else if(params.data.eta_igi_airport_date !=undefined && params.data.eta_igi_airport_date !="")
                {
                    return "Flight Booked";
                }
                else if(params.data.ff_code == undefined || params.data.ff_code == '')
                {
                    return "Pending At Imports";
                }
                else
                {
                    return "Pending From Freight Forwarder";
                }
            }          
        }
    ]
        return(
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="f16" style={{marginBottom:"10px"}}>
                            <ul className="d-tabs">
                                
                                <li onClick={this.onClickTab.bind(this,"brequest")} className={"lttabs "+(this.state.brequest)}>
                                    <button type="button" className="btn">Air Exports</button>
                                </li>

                                <li onClick={this.onClickTab.bind(this,"track")} className={"lttabs "+(this.state.track)}>
                                    <button type="button" className="btn">Booking Status</button>
                                </li>
                            </ul>
                        </div>
                    </div>
            		<div className={"col-xl-12 col-lg-12 "+this.state.requestData}>
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle}</span>
								</h5>
				   			</div>
                            <div className="card-body pt-15px">
                                <form class="aircargo-form" onSubmit={this.formHandler.bind(this)} encType="multipart/form-data">
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Date : </label>
                                            <input type="text" name="date" id="dateField" className="datetimepicker_date form-control" disabled/>
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Department Name* : </label>
                                            <input type="text" name="deptName" className="form-control"  onChange={this.changeHandler} required/>
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Indenter Name* : </label>
                                            <input type="text" name="indenterName" className="form-control"  onChange={this.changeHandler} required/>
                                        </div>
                                    </div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Mobile No* : </label>
                                            <input type="tel" name="mobile" id="mobile" className="form-control num-cls" pattern="[0-9]{10}"  onChange={this.changeHandler} required/>
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Indenter Email ID* : </label>
                                            <input type="email" name="indenterEmail" id="indenterEmail" className="form-control"  onChange={this.changeHandler} required/>
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    {this.state.ringirows.map((item, idx) => (
                                    <div className={"row col-xl-12 "} key={idx}>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Payment Ringi No (File)* : </label>
                                            <input type="file" name={"ringi_file_"+idx} id={"ringi_file_"+idx} className="form-control requestFields" onChange={this.changeFileHandler} required />
                                            {/* <FileBase64
                                            onDone={ this.changeFileHandler.bind(this) } /> */}
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Payment Ringi No* : </label>
                                            <input type="text" name={"ringiDesc_"+idx} id={"ringiDesc_"+idx} className="form-control" onChange={this.changeHandler} required/>
                                        </div>
                                        <div className="col-xl-4" style={{display: (idx != 0) ? 'block' : 'none'}}>
                                            <h6 style={{marginTop:"2.5em"}} onClick={() => this.handleRemoveRingiRow(idx)}>Clear</h6>
                                        </div>
                                    </div>
                                    ))}
                                    <input type="hidden" id="rngLength" value={this.state.ringirows.length}/>
                                    <div class={"row col-sm-12 "}>
                                        <div className="row col-xl-12 m-top-1">
                                            <div class="col-xl-12">
                                                <button class="btn btn-primary" type="button" onClick={this.handleAddRingiRow}>Add More Fields +</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class={"hr-dashed "}></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Pickup Plant* : </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="cargoDeliveryPlant" required onChange={this.changeHandler}>
                                                <option value="">-- Select Cargo Pickup Plant --</option>
                                                <option value="MSIL Gurgaon">MSIL Gurgaon</option>
                                                <option value="MSIL Manesar">MSIL Manesar</option>
                                                <option value="MSIL MPT">MSIL MPT</option>
                                                <option value="MSIL casting plant">MSIL casting plant</option>
                                            </select>
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">No Of Packets : </label>
                                            <input type="text" name="no_of_packets" id="no_of_packets" className="form-control num-cls" onBlur={this.packetsHandler} required/>
                                        </div>
                                        
                                    </div>
                                    {this.state.packetrows.map((item, idx) => (
                                    <div className="row col-xl-12" key={idx}>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Item Description* : </label>
                                            <input type="text" name={"itemDesc_"+idx} id={"itemDesc_"+idx}className="form-control"  onChange={this.changeHandler} required/>
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Cargo Gross Weight* : </label>
                                            <input type="text" name={"grossWt_"+idx} id={"grossWt_"+idx}className="form-control num-cls"  onChange={this.changeHandler} required/>
                                        </div>
                                        <div className={"col-xl-4 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">Dimensions (cms)* : </label>
                                            <input type="text" name={"dimensions_"+idx} id={"dimensions_"+idx} placeholder="l x b x h" className="form-control requestFields"  onChange={this.changeHandler} required/>
                                        </div>
                                    </div>
                                    ))}
                                    <div class="hr-dashed"></div>                                   
                                    <div className="row col-xl-12">
                                        <h4 style={{color: "#fff",width: "100%",backgroundColor: "#3d6db7",textAlign: "center",fontSize: "18px",padding: "6px 0"}}>Consignee Details</h4>
                                    </div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Consignee Name* : </label>
                                            <input type="text" name="supplierName" className="form-control"  onChange={this.changeHandler} required/>
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Consignee Address {this.state.addresstxt}* : </label>
                                            <textarea name="supplierAddr" className="form-control"  onChange={this.changeHandler} required/>
                                        </div>
                                        <div className={"col-xl-4 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">Consignee Contact Person* : </label>
                                            <input type="text" name="supplierCnctPerson" placeholder="" className="form-control requestFields"  onChange={this.changeHandler} required/>
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Country* : </label>
                                            <select name="country" className="form-control"  onChange={this.changeHandler} required>
                                                <option value="">Select Country</option>
                                                <option value="Algeria">Algeria</option>
                                                <option value="Australia">Australia</option>
                                                <option value="Austria">Austria</option>
                                                <option value="Belgium">Belgium</option>
                                                <option value="Canada">Canada</option>
                                                <option value="Chile">Chile</option>
                                                <option value="Czech Republic">Czech Republic</option>
                                                <option value="China">China</option>
                                                <option value="Denmark">Denmark</option>
                                                <option value="Dubai">Dubai</option>
                                                <option value="France">France</option>
                                                <option value="Germany">Germany</option>
                                                <option value="Hong Kong">Hong Kong</option>
                                                <option value="Hungary">Hungary</option>
                                                <option value="Indonesia">Indonesia</option>
                                                <option value="Italy">Italy</option>
                                                <option value="Japan">Japan</option>
                                                <option value="Malaysia">Malaysia</option>
                                                <option value="Myanmar">Myanmar</option>
                                                <option value="Nigeria">Nigeria</option>
                                                <option value="Netherlands">Netherlands</option>
                                                <option value="Poland">Poland</option>
                                                <option value="Romania">Romania</option>
                                                <option value="S. Korea">S. Korea</option>
                                                <option value="Serbia">Serbia</option>
                                                <option value="Singapore">Singapore</option>
                                                <option value="Slovakia">Slovakia</option>
                                                <option value="South Africa">South Africa</option>
                                                <option value="Spain">Spain</option>
                                                <option value="Sweden">Sweden</option>
                                                <option value="Switzerland">Switzerland</option>
                                                <option value="Taiwan">Taiwan</option>
                                                <option value="Thailand">Thailand</option>
                                                <option value="Turkey">Turkey</option>
                                                <option value="UK">UK</option>
                                                <option value="USA">USA</option>
                                                <option value="Vietnam">Vietnam</option>
                                            </select> 
                                        </div>
                                        <div className={"col-xl-3 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">Destination Airport* : </label>
                                            <input type="text" name="destinationAirport" className="form-control requestFields"  onChange={this.changeHandler} required/>
                                        </div>
                                        <div className={"col-xl-3 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">Email ID* : </label>
                                            <input type="email" name="emailID" className="form-control requestFields"  onChange={this.changeHandler} required/>
                                        </div>
                                        <div className={"col-xl-3 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">Phone No* : </label>
                                            <input type="text" name="phone" placeholder="" className="form-control requestFields"  onChange={this.changeHandler} required/>
                                        </div>                                    
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Delivery/Inco Terms* : </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="deliveryTerms"  onChange={this.changeHandler}  required>changeHandler
                                                <option value="">-- Select Delivery Terms --</option>
                                                <option value="Free on Board (FOB)">Free on Board (FOB)</option>
                                                <option value="Cost and Freight (CFR)">Cost and Freight (CFR)</option>
                                                <option value="Cost Insurance Freight (CIF)">Cost Insurance Freight (CIF)</option>
                                                <option value="Delivered at Place (DAP)">Delivered at Place (DAP)</option>
                                                <option value="Delivery Duty Paid (DDP)">Delivery Duty Paid (DDP)</option>
                                                <option value="Delivered Duty Unpaid (DDU)">Delivered Duty Unpaid (DDU)</option>
                                            </select>
                                        </div>
                                        {(this.state.deliveryTerms == "Delivery Duty Paid (DDP)" || this.state.deliveryTerms == "Delivered Duty Unpaid (DDU)")?
                                            <div className="col-xl-4">
                                                <label className="col-xl-12 col-lg-12">Delivery Address* : </label>
                                                <textarea className="form-control dterms" name="pickUpAddr" id="pickUpAddr" onChange={this.changeHandler} required/>
                                            </div> : ""
                                            // :(this.state.deliveryTerms == "FCA")?
                                            //     <div className="col-xl-4">
                                            //         <label className="col-xl-12 col-lg-12">Place / Port : </label>
                                            //         <input type="text" className="form-control dterms" name="port" id="port" onChange={this.changeHandler} required/>
                                            //     </div>
                                            // :
                                            //     <div className="col-xl-4">
                                            //         <label className="col-xl-12 col-lg-12">Airport Name : </label>
                                            //         <input type="text" className="form-control dterms" name="airportName" id="airportName"  onChange={this.changeHandler} required/>
                                            //     </div>
                                        }
                                        <div className={"col-xl-4 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">Proposed Cargo Readiness Date* : </label>
                                            <input type="text" name="cargoDate" id="cargoDate" className="datetimepicker_date form-control requestFields"  onChange={this.changeHandler} required/>
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Invoice* : </label>
                                            <input type="file" name={"invoice_file"} id={"invoice_file"} className="form-control requestFields" onChange={this.changeFileHandler} required />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Packing List* : </label>
                                            <input type="file" name={"packing_list_file"} id={"packing_list_file"} className="form-control requestFields" onChange={this.changeFileHandler} required />
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">SQC List* : </label>
                                            <input type="file" name={"sqc_list_file"} id={"sqc_list_file"} className="form-control requestFields" onChange={this.changeFileHandler} required/>
                                        </div>
                                        <div className="col-xl-3">
                                            <label className="col-xl-12 col-lg-12">Part I : </label>
                                            <input type="file" name={"annex_file"} id={"annex_file"} className="form-control requestFields" onChange={this.changeFileHandler} />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">GR wavier – in case of free of charge : </label>
                                            <input type="file" name={"gr_wavier_file"} id={"gr_wavier_file"} className="form-control requestFields" onChange={this.changeFileHandler} />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">MSDS – in case of DG : </label>
                                            <input type="file" name={"msds_file"} id={"msds_file"} className="form-control requestFields" onChange={this.changeFileHandler} />
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">IIP certificate (UN packing) – in case of DG : </label>
                                            <input type="file" name={"iip_file"} id={"iip_file"} className="form-control requestFields" onChange={this.changeFileHandler} />
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className={"row col-xl-12"}>
                                        <div className={"col-xl-3 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">Commercial Invoice* : </label>
                                            <input type="text" name="commercialInvoice" id="commercialInvoice" className="form-control requestFields" onChange={this.changeHandler} required/>
                                        </div>
                                        <div className={"col-xl-3 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">Commercial Invoice Date* : </label>
                                            <input type="text" name="commercialInvoiceDate" id="commercialInvoiceDate" className="datetimepicker_date form-control requestFields"  onChange={this.changeHandler} required/>
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className={"row col-xl-12"}>
                                        <div className={"col-xl-4 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">Payment terms* : </label>
                                            <select class="form-control col-xl-12 col-lg-12 requestFields" name="payment_terms"  onChange={this.changeHandler} required>
                                                <option value="">-- Select Payment Terms --</option>
                                                <option value="Advance">Advance</option>
                                                <option value="Free of Cost (FOC)">Free of Cost (FOC)</option>
                                                <option value="Telegraphic Transfer (TTC)">Telegraphic Transfer (TTC)</option>
                                                <option value="Letter of credit (LC)">Letter of credit (LC)</option>
                                            </select>
                                        </div>
                                        <div className={"col-xl-4 "+this.state.requestFields}>
                                            <label className="col-xl-12 col-lg-12">Export Incentive* : </label>
                                            <select class="form-control col-xl-12 col-lg-12 requestFields" name="export_incentive" id="export_incentive" onChange={this.changeHandler} required>
                                                <option value="">-- Select Option --</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="hr-dashed"></div>
                                    <div className="row col-xl-12">
                                        <div className={"col-xl-4"}>
                                            <label className="col-xl-12 col-lg-12">Shipment Return (Re-Import)* : </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="shipmentReturn"  onChange={this.changeHandler} required>
                                                <option value="">-- Select Shipment Return --</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">Certificate of origin* : </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="certOfOrigin"  onChange={this.changeHandler} required>
                                                <option value="">-- Select Benefit Certificate --</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </div>
                                        <div className="col-xl-4">
                                            <label className="col-xl-12 col-lg-12">DG* : </label>
                                            <select class="form-control col-xl-12 col-lg-12" name="dg"  onChange={this.changeHandler} required>
                                                <option value="">-- Select DG/Non DG --</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row col-xl-12 col-lg-12 form-group">
                                        <button type="submit" className="btn btn-success" style={{marginLeft:"45%",width:"192px",marginTop:"20px"}}>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className={"col-xl-12 col-lg-12 "+this.state.trackData}>
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
								    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Booking Status</span>
								</h5>
				   			</div>
                            <div className="card-body">
                                <form className="row" onSubmit={this.trackHandler.bind(this)} encType="multipart/form-data">
                                    <div className="col-xl-4">
                                        <input type="text" name="invoice_no" id="invoice_no" className="form-control" placeholder="Commercial Invoice No/Job No" required/>
                                    </div>
                                    <div className="col-xl-4 col-lg-4">
                                        <button type="submit" className="btn btn-info">Track Status</button>
                                    </div>
                                </form>
                            </div>
                            <div className={"col-xl-12 col-lg-12 "+this.state.trackResData}>
                                <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham"}>    
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={false}
                                        // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        frameworkComponents={this.state.frameworkComponents}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        stopEditingWhenGridLosesFocus= {true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        // components={this.state.components}
                                        enableRangeSelection= {true}
                                        // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                        />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>           
            </div>
        )
    }
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_date').datetimepicker({
		//mask:'39-19-9999',
        format:'d-m-Y',
        //minDate:true,
		timepicker:false,
    });
    
    $('.cargo_date').datetimepicker({
		//mask:'39-19-9999',
        format:'d-m-Y',
        minDate:true,
		timepicker:false,
	});

    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    script.crossOrigin = "anonymous"
	index.parentNode.insertBefore(script,index)
}

// $('body').on('focus',".datetimepicker_date", function(){
//     $(this).datetimepicker({
// 		//mask:'39-19-9999',
//         format:'Y-m-d',
//         minDate:true,
//         timepicker:false
// 	});
// });

// $('body').on('focus',".cargo_date", function(){
//     $(this).datetimepicker({
// 		//mask:'39-19-9999',
//         format:'Y-m-d',
//         minDate:true,
//         timepicker:false,

// 	});
// });

$(document).on("input", ".num-cls", function() {
    this.value = this.value.replace(/\D/g,'');
});

$(document).on("change", "#cargoDate", function(e) {
    e.preventDefault();
    var bookingDate=$("#dateField").val();
    var cargoDate=$("#cargoDate").val();
    var bookingDate = bookingDate.split("-")[2]+"-"+bookingDate.split("-")[1]+"-"+bookingDate.split("-")[0]
    var cargoDate = cargoDate.split("-")[2]+"-"+cargoDate.split("-")[1]+"-"+cargoDate.split("-")[0]

    var startDate = new Date(bookingDate);
    var endDate = new Date(cargoDate);
    var startTime = startDate.getTime();
    var endTime = endDate.getTime();
    console.log(startTime,endTime);
    if(startTime >= endTime)
    {
        $("#cargoDate").val("");
        alert("Date should be greater than Booking Date");
    }
})

$(document).on("keypress", "#commercialInvoice", function(e) {
    var regex = new RegExp("^[0-9-!@#$%*?]");
    var key = String.fromCharCode(e.charCode ? e.which : e.charCode);
    if (!regex.test(key)) {
        e.preventDefault();
        return false;
    }
});