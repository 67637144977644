import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import $ from "jquery";
import _ from "lodash";
import MarkerClusterer from "@google/markerclusterer";
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import CountUp from 'react-countup';
import Modal from 'react-responsive-modal';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import "@grapecity/wijmo.styles/wijmo.css";
import * as pdf from "@grapecity/wijmo.pdf";
import * as wijmo from "@grapecity/wijmo";

import { getDDMMYYYY, 
	getDDMMYYYYHHMMSS, 
	getYYYYMMDD, 
	getYYYYMMDDHHMMSS, 
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD, 
	getHyphenDDMMYYYYHHMMSS, 
	getHyphenDDMMYYYY,
	getDDMMMYYYYHHMMDefault } from '../common/utils'; 
var redirectURL = require('../redirectURL');

var infoBox = require('../common/google-infowindow');
var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);
var googleAnalytics = require("../common/googleanalytics");

var marker;
var markers = [];
var circle;
var selectlat;
var selectlng
var allcords=[];
var map;
var rad = function(x) {
	return x * Math.PI / 180;
  };
var rlatitude;
var rlongitude;

var fencingLength=0;
var geoFencingArea;
export default class ManageNearByTransporterTrucks extends Component {

    constructor(props) {
		super(props);
		this.colWidth = 100;
    	this.rowHeight = 18;
		// console.log(this.props.match.url)
		this.displayData = [];
        this.state = {
			pageTitle: "",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			showform:'show-m',
			loadshow:'show-n',
			showrefresh:'show-n',
			sliderTranslate:'',
			showdata:this.displayData,
			radius:'5000',
			rlatitude:'',
			rlongitude:'',
			latitude:'',
			longitude:'',
			defaultradius:0,
			coordinates:[],
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			contentString:"testing",
			mwidth:"col-xl-12 col-lg-12",
			alldepts:[],
			transportervalue:'',
			alltransporters:[],
			truckstatus:'0',
			kpicode:'0',
			deptcode:'',
			truckNoList:[],
			alltrucks:[],
			truckno:'',
			showTrucksList : "show-n",
			filteredData : [],
			triptype :{"value":1,"label":"ALL"},
			plantLocations : [],
			totalTrucksCount : 0,
			onTripCounter : 0,
			usermanualmodal:false,
			transporterTrucks : [],
			defaultColDef: {
				sortable: true,
			  filter: true,
			  resizable: true,
			  pivot : true,
			  enableValue: true,
			  enableRowGroup: true,
			},
			tpttrucks:[]
		};
		this.popmarker=this.popmarker.bind(this);
		this.goBack = this.goBack.bind(this);
		
	}
	/*Alert Popups*/
	closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onRecieveInput = (value) => {
        this.setState({
            alert: (
                <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
                    You wrote: {value}
                </SweetAlert>
            )
        });
    }
	hideAlert = () => {
        this.setState({
            alert: null
        });
	}
	
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
		
    componentDidMount(){
		var tptCode = localStorage.getItem('transportercode');
		var vehicle_group = localStorage.getItem("vehicle_group");
		var tptrucks = []
		if(vehicle_group != "")
		{
			var tparms = {
				transporter_code:tptCode,
				region:vehicle_group
			}
			redirectURL.post("/dashboard/tpregiontrucksdata",tparms)
			.then((response) => {
			   var records = response.data.records;
				// console.log("records ", records)
				this.setState({
					tpttrucks:records
				})
			})
		}
		//console.log(this.props.match.)
		var is_enmovil_transporter = localStorage.getItem("is_enmovil_transporter");
        if(is_enmovil_transporter == 1)
        {
			this.logPageView(); // Log page view to GA
		
			this.setState({
				loadshow:'show-m',
				coordinates:[],
				rowData:[]
			});
			var dept = "SNDG";
			console.log("dept_code is ",JSON.parse(localStorage.getItem("dept_code")))
			if(localStorage.getItem("dept_code") !=undefined)
			{         
				var depmntCodeJns = JSON.parse(localStorage.getItem("dept_code"));
				if(depmntCodeJns != "")
				{
					dept = depmntCodeJns[0];
				}
				else
				{
					dept = "SNDG";
				}
				
			}
			let pageTitle = "Carrier Location on Map";
			var transporter_code = "";
			if(localStorage.getItem('transportercode') !=undefined)
			{
				var tptCode = JSON.parse(localStorage.getItem("transportercode"));
				//var transporter_code = tptCode[0];
			}
			this.setState({
				deptcode:dept,
				transportervalue:tptCode,
				pageTitle: pageTitle
			});
			var formdata = {
				radius:3000,
				latitude:"20.73568784876339",
				longitude:"79.38999444931353",
				dept_code:dept,
				transporter_code:JSON.stringify(tptCode),
				status:this.state.truckstatus,
				trip_type : 1
			}
			rlatitude = "20.73568784876339";
			rlongitude = "79.38999444931353";
			console.log("formdata ", formdata)
			//http://autometrics.in:5000/getNearByTrucks
			redirectURL.post('/vicinity/getRadiusCoordinates',formdata,{
				headers:{
					'Content-Type':'application/json',
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Methods': 'PUT, GET, POST, DELETE, OPTIONS',
					'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept, Authorization"

				} 
			})
			.then(
				(response) =>{
				//console.log(response)
				//console.log("response", JSON.parse(response.data.body));
				// console.log(response.data.body)
				var mrkers=JSON.parse(JSON.parse(response.data.body).trucks_data);
				//console.log(mrkers,"markers");
				//console.log(response.data.body,"resppp");
				redirectURL.post("/dashboard/devices").then(async (response1)=>{
					
					var transporterTrucks = [];
					var transptrucklist = [];
					response1.data.devices.map(function(e){
						if(tptCode.indexOf(e.transporter_code) > -1 )
						{
							transporterTrucks.push(e)
							transptrucklist.push(e.truck_no);
						}
					})
					
					if(mrkers.length > 0)
					{
						console.log(transporterTrucks,"transporterTrucks")
						var markers = [];
						if(transporterTrucks.length > 0)
						{
							// mrkers.map(function(t){
							// 	if(transporterTrucks.indexOf(t.truck_no) > -1 )
							// 	{
							// 		markers.push(t)
							// 	}
							// })

							markers =await mrkers.filter(f =>
								transporterTrucks.filter((d )=> {
									if(d.truck_no == f.truck_no){
										// console.log("API ", d)
										if(d.registereddevices != undefined)
										{
											if(d.registereddevices.length > 0)
											{
												f.device_address = d.registereddevices[0].address
												f.device_city = d.registereddevices[0].city
												f.device_state = d.registereddevices[0].state
											}
										}
										return f;
									}
								})
							);
						}
						console.log(markers,"markersfilter")
						this.setState({
							defaultradius:formdata.radius,
							coordinates:markers,
							totalTrucksCount : markers.length,
							mwidth:"col-xl-12 col-lg-12",
							rowData:markers,
							radius:5000,
							loadshow:'show-n',
							showform:'show-n',
							showrefresh:'show-m',
							rlatitude:"21.144644112601775",
							rlongitude:"79.08860126768066",
							alltrucks:markers,
							//onTripCounter : JSON.parse(response.data.body).gps_trucks,
							onTripCounter : markers.length,
							transporterTrucks : transporterTrucks,
							truckNoList :transptrucklist
						});
						redirectURL.post("/weather/getPlants").then((rsp)=>{
							this.setState({
							plantLocations : rsp.data,
							});
							//console.log(rsp.data);
							this.renderMap();
				
						})								
						
					}
					else{
						this.setState({
							show: true, basicType:'warning', basicTitle:"No Data found.",
							loadshow:'show-n',
							showform:'show-m',
						});
						
					}
				})
				
			})
			.catch(function(error){
				console.log(error);
			});
			var tptParams = {
				transporter_code : localStorage.getItem("transportercode")
			}
			// redirectURL.post("consignments/getTransportersTrucks",tptParams).then((response)=>{
			// 	var trucks = response.data;
			// 	try{
			// 		//console.log("trucks ", trucks)
			// 		var trucks = trucks.map(function(e){
			// 			if(e.truck_no != "")
			// 			{
			// 				return e.truck_no;
			// 			}
			// 		})
			// 		this.setState({
			// 				truckNoList :trucks
			// 		});
			// 	}catch(e){}
				
			// 	//console.log(trucks.length,"trucks");
			// });
			

			this.renderMap();
		}
		else
		{
			window.location.href="/";
		}
	}
	createSelectTransportersItems() {
		 let items = []; 
		 items.push({ value: 'ALL', label: "All Transporters" });
    	this.state.alltransporters.map(item=>{
    		items.push({ value: item.transporter_code, label: item.transporter_name });
    		//items.push(<option key={item.dept_code} value={item.dept_code}>{item.dept_name}</option>); 
    	});
    	return items;
	} 
	/*
	createSelectItems() {
        let items = [];         
        //console.log(this.state.alldepts);
        items.push(<option key='all' value='all'>All Departments </option>);  
        this.state.alldepts.map((item, key) => {
        	//console.log("Here "+item,key);
        	items.push(<option key={item.dept_code} value={item.dept_code}>{item.dept_name}</option>);  
		});
        return items;
	} 
	
	*/


    changeHandler = truckstatus => {

    	this.setState(
	      { truckstatus },
	      () => console.log(`Option selected:`, this.state.truckstatus)
	    );
	}	

    changeKpiHandler = kpicode => {

    	this.setState(
	      { kpicode },
	      () => console.log(`Option selected:`, this.state.kpicode)
	    );
	}	

	setTripType = trip =>{
		this.setState({
			triptype : trip
		})
	}
	handleChange = transportervalue => {
    	this.setState(
			{ transportervalue },
			() => console.log(`Option selected:`, this.state.transportervalue)
		  );
	}
	/*handleTruckStatusChange(e){
		this.setState({ truckstatus: e.target.value });
	}*/
	initalList =(event)=>{
        $(".trucks-dropdown").removeClass("show-n");
        var dataset = this.state.truckNoList;
		dataset = dataset.slice(0,100);
		var tpttrucks = this.state.tpttrucks;
        var tptunqrecords = []
        if(tpttrucks.length > 0)
        {
            dataset.filter(f =>
                tpttrucks.filter((d )=> {
                    if(d.truck_no == f){									
                        tptunqrecords.push(f)
                    }
                })
            );
        }
        else
        {
            tptunqrecords = dataset
        }
        this.setState({
            filteredData : tptunqrecords,
            showTrucksList : "show-m",
        })
    }
    handlerForm = (event) => {
		try
		{
			$(".trucks-dropdown").removeClass("show-n");
			var dataset = this.state.truckNoList;

			var tpttrucks = this.state.tpttrucks;
			var tptunqrecords = []
			if(tpttrucks.length > 0)
			{
				dataset.filter(f =>
					tpttrucks.filter((d )=> {
						if(d.truck_no == f){									
							tptunqrecords.push(f)
						}
					})
				);
			}
			else
			{
				tptunqrecords = dataset
			}
			
			var filteredResult = tptunqrecords.filter(function(e){
				if(e != "" && e != null)
				{
					return e.toString().toLowerCase().includes($("#inputTruck").val().toLocaleLowerCase());
				}
				
			});
			filteredResult = filteredResult.slice(0,100);
			this.setState({
				filteredData : filteredResult,
				showTrucksList : "show-m",
			});
		}catch(e){}
    }
    onClickTruck(event)
    {
         $("#inputTruck").val($(this).text());
    }
	handleFilter(e){
    	console.log("filter clicked");
		e.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedSearchByTransporter,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.setState({
			loadshow:'show-m',
		});
		//console.log("Here ",this.props.match.url);
	
		//console.log("Checking ",this.state.transportervalue);
		var transcode=[];
		var tstatus;
		console.log(this.state.transportervalue);
		
		
		
		if(this.state.truckstatus == '0')
		{
			tstatus=this.state.truckstatus;
		}
		else{
			tstatus=this.state.truckstatus.value;
		}
		var trip_type = this.state.triptype.value;
		var transporter_code = "";
		if(localStorage.getItem('transportercode') !=undefined)
		{
			var tptCode = JSON.parse(localStorage.getItem("transportercode"));
			//var transporter_code = tptCode[0];
		}
		var formdata = {
			radius:3000,
			latitude:"20.73568784876339",
			longitude:"79.38999444931353",
			dept_code:this.state.deptcode,
			transporter_code:JSON.stringify(tptCode),
			status:tstatus,
			trip_type : trip_type
		}
		console.log(formdata);
		rlatitude="20.73568784876339";
		rlongitude="79.38999444931353";
		redirectURL.post('/vicinity/getRadiusCoordinates',formdata,{
			headers:{
				'Content-Type':'application/json',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'PUT, GET, POST, DELETE, OPTIONS',
				'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept, Authorization"

			}
		})
		.then(
			async (response) =>{
			//console.log("response", JSON.parse(response.data.body));
		
			var mrkers = JSON.parse(JSON.parse(response.data.body).trucks_data);
			//console.log(JSON.parse(JSON.parse(response.data.body).trucks_data))

			if(mrkers.length > 0)
			{
				var markers = [];
				var transporterTrucks = this.state.transporterTrucks;
				if(transporterTrucks.length > 0)
				{
					// mrkers.map(function(t){
					// 	if(transporterTrucks.indexOf(t.truck_no) > -1 )
					// 	{
					// 		markers.push(t)
					// 	}
					// })

					markers =await mrkers.filter(f =>
						transporterTrucks.filter((d )=> {
							if(d.truck_no == f.truck_no){
								// console.log("API ", d)
								if(d.registereddevices != undefined)
								{
									if(d.registereddevices.length > 0)
									{
										f.device_address = d.registereddevices[0].address
										f.device_city = d.registereddevices[0].city
										f.device_state = d.registereddevices[0].state
									}
								}
								return f;
							}
						})
					);

				}
				//console.log(markers,"markersfilter")
				await this.setState({
					defaultradius:formdata.radius,
					coordinates:markers,
					//onTripCounter : JSON.parse(response.data.body).gps_trucks,
					onTripCounter : markers.length,
					mwidth:"col-xl-12 col-lg-12",
					rowData:markers,
					radius:formdata.radius,
					loadshow:'show-n',
					showform:'show-n',
					showrefresh:'show-m',
					rlatitude:formdata.latitude,
					rlongitude:formdata.longitude,
				});
				await redirectURL.post("/weather/getPlants").then((rsp)=>{
					this.setState({
						plantLocations : rsp.data,
					});
					console.log(rsp.data);
					this.renderMap();
				})
				
			}
			else{
				this.setState({
					show: true, basicType:'warning', basicTitle:"No Data found.",
					loadshow:'show-n',
					showform:'show-m',
				});
				
			}
		})
		.catch(function(error){
			console.log(error);
		});
		
		this.renderMap();
	}
	renderMap = () => {
    	
		loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyBQUmk7hdajZEAYllxKhVPnUx5pXuK-j_Y&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
 	}
	
	 initMap = () => {
		allcords=[];
		this.state.coordinates.map((marker) => {
			allcords.push(marker);
		});
		
		var contentdata = this.state.contentString;
		if(this.state.rlatitude == '' && this.state.rlongitude == '')
		{
			var lt=21.144644112601775;
			var ln=79.08860126768066;
		}
		else{
			var lt=this.state.rlatitude;
			var ln=this.state.rlongitude;
		}
		try{
			map = new window.google.maps.Map(document.getElementById('map_canvas'), {
				zoom: 5,
				center: new window.google.maps.LatLng(lt,ln),
				mapTypeId: window.google.maps.MapTypeId.ROADMAP,
				/*mapTypeControl: true,
				gestureHandling: 'greedy',
				mapTypeControlOptions: {
					style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
					position: window.google.maps.ControlPosition.TOP_RIGHT
				  },            
				disableDefaultUI: true,
				zoomControl: true*/
			});
		}
		catch(e){
			// map = new window.google.maps.Map(document.getElementById('map_canvas'), {
			// 	zoom: 5,
			// 	center: new window.google.maps.LatLng(parseFloat(21.144644112601775),parseFloat(79.08860126768066)),
			// 	mapTypeId: window.google.maps.MapTypeId.ROADMAP,
			// 	/*mapTypeControl: true,
			// 	gestureHandling: 'greedy',
			// 	mapTypeControlOptions: {
			// 		style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
			// 		position: window.google.maps.ControlPosition.TOP_RIGHT
			// 	  },            
			// 	disableDefaultUI: true,
			// 	zoomControl: true*/
			// });
		}
    	
		circle = new window.google.maps.Circle({
            map: map
		});
		
		marker = new window.google.maps.Marker({
			map: map,
			draggable: true,
			animation: window.google.maps.Animation.DROP,
			//position: {lat: 28.48, lng: 77.06}
		});
		
		marker.addListener('click', function(){
			marker.setAnimation(window.google.maps.Animation.BOUNCE)			
		});
		
  		window.google.maps.event.addListener(map, 'click', function(event) {
			
			//document.getElementById("latitude").value = event.latLng.lat();
			//document.getElementById("longitude").value = event.latLng.lng();
			marker.setPosition(event.latLng);
			
		});
		circle.bindTo('center', marker, 'position');
		var markerLatlng1;
		//console.log("Radius ", this.state.radius)
		// console.log(allcords);
		var markers = allcords.map((marker) => {
			// if(marker.truck_no = "HR46E1297")
			// {
			// 	console.log("marker123 ",marker);
			// }
			
			if(marker.status == 1)
			{
				var truckText =  "Inside Plant";
				var color =  "#333333";
				var image =  require('../../assets/icons/truck_inside_24.png');
			}
			else if(marker.status == 2)
			{
				var truckText =  "Going to Dealer";
				var color =  "#333333";
				var image =  require('../../assets/icons/truck_going_24.png');
			}
			else if(marker.status == 3 || marker.status == 4) 
			{
				var truckText =  "At Dealer Location";
				var color =  "#333333";
				var image =  require('../../assets/icons/truck_atdealer_24.png');
			}
			else if(marker.status == 5)
			{
				var truckText =  "Left Destination Location";
				var color =  "#333333";
				var image =  require('../../assets/icons/truck_leftdealer_24.png');
			}
			else if(marker.status == 6)
			{
				var truckText =  "Return to Plant";
				var color =  "#333333";
				var image =  require('../../assets/icons/truck_return_24.png');
			}
			else if(marker.status == 7)
			{
				var truckText =  "Empty Truck Outside Plant";
				var color =  "#333333";
				var image =  require('../../assets/icons/truck_empty_24.png');
			}
			else{
				var truckText =  marker.truck_no;
				var color =  "#333333";
				var image =  require('../../assets/icons/truck_na_24.png');
			}
			var image =  require('../../assets/icons/truck_na_24.png');
			var contentarr = []
			var headerTitle = marker.truck_no;
			contentarr.push({"key":"Truck No", "value":marker.truck_no});
			contentarr.push({"key":"Transporter Name", "value":marker.transporter_name});
			//contentarr.push({"key":"Distance from geofence", "value":marker.distance});
			//contentarr.push({"key":"GPS Provider", "value":marker.actual_lspuser});
			contentarr.push({"key":"GPS Provider", "value":"Enmovil"});
			contentarr.push({"key":"Speed (km/h)", "value":Math.ceil(marker.speed)});
			contentarr.push({"key":"Last Packet Time", "value":getHyphenDDMMMYYYYHHMM(marker.timestamp)});
			if(marker.truck_travelling_status == 0)
			{
				var trvalstat = "Travelling"
			}
			else
			{
				var rrr = ConvertHHMMSeconds(marker.elaped_time_from_last_moved_seconds)
				var trvalstat = "Idle: "+rrr
			}
			contentarr.push({"key":"Travelling Status", "value":trvalstat});
			contentarr.push({"key":"Address", "value":marker.device_address});
			contentarr.push({"key":"City", "value":marker.device_city});
			contentarr.push({"key":"State", "value":marker.device_state});
			var contentString = infoBox(image, headerTitle, contentarr, '')

			//console.log(contentString,"contentString");
			// var contentString = "<table><tr><th>Status: </th><td style='color:"+color+";font-weight:600;text-align: right'>"+truckText+"</td></tr><tr><th>Distance from geofence: </th><td style='text-align: right'>";
			// contentString = contentString + marker.distance+"</td></tr><th>Truck No: </th><td style='text-align: right'>"+marker.truck_no+"</td></tr><tr><th>Name: </th><td style='text-align: right'>";
			// contentString = contentString + marker.transporter_name+"</td></tr><tr><th> LSP Name:</th><td style='text-align: right'>"+marker.lsp_name+"</td></tr><tr><th>Address:</th><td style='text-align: right'>";
			// contentString = contentString  +marker.address+"</td></tr></table>";
			
			//var contentString = "Here "+marker.truck_no;

			//var contentString = infoBox(require('../../assets/icons/truck_inside_24.png'), header, contentarr)

			var infowindow = new window.google.maps.InfoWindow({
				content: contentString
			});
			
			var markerLatlng = new window.google.maps.LatLng(parseFloat(marker.latitude), parseFloat(marker.longitude));
			
			if(marker.status == 1)
			{
				var image =  require('../../assets/icons/truck_inside_24.png');
			}
			else if(marker.status == 2)
			{
				var image =  require('../../assets/icons/truck_going_24.png');
			}
			else if(marker.status == 3 || marker.status == 4) 
			{
				var image =  require('../../assets/icons/truck_atdealer_24.png');
			}
			else if(marker.status == 5)
			{
				var image =  require('../../assets/icons/truck_leftdealer_24.png');
			}
			else if(marker.status == 6)
			{
				var image =  require('../../assets/icons/truck_return_24.png');
			}
			else if(marker.status == 7)
			{
				var image =  require('../../assets/icons/truck_empty_24.png');
			}
			else{
				var image =  require('../../assets/icons/truck_na_24.png');
			}
			var image =  require('../../assets/icons/truck_na_24.png');
			/*
			if(marker.overspeeding_exception == 1)
			{
				var image =  require('../../assets/icons/truck_overspeed.png');
			}
			if(marker.nightdriving_exception == 1)
			{
				var image =  require('../../assets/icons/truck-end.png');
			}
			*/
			
			
			var mark = new window.google.maps.Marker({
                position: markerLatlng,
				map: map,
				title:"Status: "+truckText+" #"+marker.truck_no,
				icon: image
            });
			mark.addListener('click', function() {
				infowindow.open(map, mark);
			  });
			return mark
            mark.setMap(map);
		});
		var eventtrigger = document.getElementsByClassName('truck');
		var markerCluster = new MarkerClusterer(map, markers,
            {imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'});
		  // var searchBox = new window.google.maps.places.SearchBox(eventtrigger);
		  //map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(eventtrigger);
		if(this.state.plantLocations.length > 0){
			this.state.plantLocations.map(function(params){
				var plant = {
				url: require("../../assets/icons/"+params.icon), // url
				scaledSize: new window.google.maps.Size(32,32), // scaled size
				origin: new window.google.maps.Point(0,0), // origin
				anchor: new window.google.maps.Point(0, 0) // anchor
				};
				var coords = JSON.parse(params.coordinates);
				var plantmarker = new window.google.maps.Marker({
					position: new window.google.maps.LatLng(coords.lat,coords.lng),
					map: map,
					icon: plant,
					title : params.plant_name
				});
				// var cityCircle = new window.google.maps.Circle({
				// strokeColor: '#9fc9b9',
				// strokeOpacity: 0.3,
				// strokeWeight: 2,
				// fillColor: '#9fc9b9',
				// fillOpacity: 0.6,
				// map: map,
				// center:coords,
				// radius: 50 * 1000
				// });
			})
		}
		 
	}
	toggleBounce() {
		if (marker.getAnimation() !== null) {
		  marker.setAnimation(null);
		} else {
		  marker.setAnimation(window.google.maps.Animation.BOUNCE);
		}
		
	  }
	
	changeInputHandler = (event) => {
    	let name = event.target.name;
		let value = event.target.value;
    	this.setState({[name]:value});
    }
    
	changeRadiusHandler = (event) => {
    	let name = event.target.name;
		let value = event.target.value;
		this.setState({[name]:value});
		circle.setRadius(value);
    }
    
	
	goBack(){
		window.location.reload();
	}
	getDistance = (p1, p2) => {
		//console.log("p1",p1);
		//console.log("p2",p2);
		var R = 6378137; // Earth’s mean radius in meter
		var dLat = rad(p2.lat - p1.lat);
		var dLong = rad(p2.lng - p1.lng);
		var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
		var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		var d = R * c;
		return d/1000; // returns the distance in meter
	  };
	onShowInfo = (e) =>{
		console.log(JSON.parse(e.target.id));
		this.setState({
			contentString:"Here Success"
		});
		var ourMarker = e.target.id;
 			window.google.maps.event.trigger(ourMarker, 'click');
		var infowindow = new window.google.maps.InfoWindow({
			content: "Here Success"
		  });
		infowindow.open(map, marker);

	}
	popmarker(e)
	{
		var truckNo = e.data.truck_no;
		var truck = [];
		var trucks = this.state.alltrucks;
		console.log(truckNo,"input truck");
		var filteredValue = trucks.filter(function(e){
			return e.truck_no == truckNo
		});
		if(filteredValue.length > 0)
		{
			console.log("Matched")
			var lat = parseFloat(filteredValue[0].latitude);
				var lng =parseFloat(filteredValue[0].longitude);
				var data = filteredValue[0];
				map.setCenter(new window.google.maps.LatLng(lat,lng));
				map.setZoom(22);
				var markerLatlng = new window.google.maps.LatLng(lat, lng);
				
				if(filteredValue[0].status == 1)
				{
					var truckText =  "Inside Plant";
					var color =  "#333333";
				}
				else if(filteredValue[0].status == 2)
				{
					var truckText =  "Going to Dealer";
					var color =  "#333333";
				}
				else if(filteredValue[0].status == 3 || filteredValue[0].status == 4) 
				{
					var truckText =  "At Dealer Location";
					var color =  "#333333";
				}
				else if(filteredValue[0].status == 5)
				{
					var truckText =  "Left Destination Location";
					var color =  "#333333";
				}
				else if(filteredValue[0].status == 6)
				{
					var truckText =  "Return to Plant";
					var color =  "#333333";
				}
				else if(filteredValue[0].status == 7)
				{
					var truckText =  "Empty Truck Outside Plant";
					var color =  "#333333";
				}
				else{
					var truckText =  "N/A";
					var color =  "#333333";
				}
				
				if(filteredValue[0].status == 1)
				{
					var image =  require('../../assets/icons/truck_inside_24.png');
				}
				else if(filteredValue[0].status == 2)
				{
					var image =  require('../../assets/icons/truck_going_24.png');
				}
				else if(filteredValue[0].status == 3 || filteredValue[0].status == 4) 
				{
					var image =  require('../../assets/icons/truck_atdealer_24.png');
				}
				else if(filteredValue[0].status == 5)
				{
					var image =  require('../../assets/icons/truck_leftdealer_24.png');
				}
				else if(filteredValue[0].status == 6)
				{
					var image =  require('../../assets/icons/truck_return_24.png');
				}
				else if(filteredValue[0].status == 7)
				{
					var image =  require('../../assets/icons/truck_empty_24.png');
				}
				else{
					var image =  require('../../assets/icons/truck_na_24.png');
				}
				var image =  require('../../assets/icons/truck_na_24.png');
				var marker = new window.google.maps.Marker({
					position: markerLatlng,
					map: map,
					title:data.truck_no,
					icon:image
				});


				var contentarr = []
				var headerTitle = filteredValue[0].truck_no;
				contentarr.push({"key":"Truck No", "value":filteredValue[0].truck_no});
				contentarr.push({"key":"Transporter Name", "value":filteredValue[0].transporter_name});
				//contentarr.push({"key":"Distance from geofence", "value":trucks[i].distance});
				//contentarr.push({"key":"GPS Provider", "value":filteredValue[0].actual_lspuser});
				contentarr.push({"key":"GPS Provider", "value":"Enmovil"});
				contentarr.push({"key":"Speed (KMPH)", "value":filteredValue[0].speed});
				contentarr.push({"key":"Last Packet Time", "value":getHyphenDDMMMYYYYHHMM(filteredValue[0].timestamp)});
				// contentarr.push({"key":"Address", "value":filteredValue[0].area+", "+filteredValue[0].state});
				if(filteredValue[0].truck_travelling_status == 0)
				{
					var trvalstat = "Travelling"
				}
				else
				{
					var rrr = ConvertHHMMSeconds(filteredValue[0].elaped_time_from_last_moved_seconds)
					var trvalstat = "Idle: "+rrr
				}
				contentarr.push({"key":"Travelling Status", "value":trvalstat});
				contentarr.push({"key":"Address", "value":filteredValue[0].device_address});
				contentarr.push({"key":"City", "value":filteredValue[0].device_city});
				contentarr.push({"key":"State", "value":filteredValue[0].device_state});
				
				var contentString = infoBox(image, headerTitle, contentarr, '')
				//console.log(contentarr,"contentarr")
				var infowindow = new window.google.maps.InfoWindow({
					content: contentString
				});
				
				marker.setAnimation(window.google.maps.Animation.DROP)
				marker.addListener('click', function() {
					infowindow.open(map, marker);
				});
				
				marker.setMap(map);
		}
		else{
			this.setState({
				show: true, basicType:'warning',
				basicTitle:"No Data found.",
				loadshow:'show-n',
				showform:'show-m',
			})
		}
		console.log(filteredValue);
		
	}
	onShowUploadDiv = () => {
		//console.log("Click Upload Button")
		this.setState({
			uploadDivWidth:'30%',
			sliderTranslate:"slider-translate",
			showDiv:'show-m',
			csvmessage:'',
			csverrmessage:'',
		});
	}
	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
		});
	}

	onSelectTrucks(){
		var items = []
		this.state.alltrucks.map((item) => {
			items.push({ value: item, label: item.truck_no });
		})
		return items;
	}
	changeTruckHandler(event){
		let name = event.target.name;
		let value = event.target.value;
    	this.setState({[name]:value});
	}
	truckFormHandler(e){
		e.preventDefault();
		var inputTruck = $("#inputTruck").val().toUpperCase();
        var dataset = this.state.truckNoList;
        var truckCheck = 0;
        if(dataset.length > 0)
        {
            for(var d=0;d<dataset.length;d++)
            {
                if(dataset[d] == inputTruck)
                {
                    truckCheck = 1;
                    break;
                }
            }
		}
		if(truckCheck == 1)
		{
			if (googleAnalytics.page.enableGA) {
				let pageTitle = this.state.pageTitle;
				let eventOptions = {
					"category": pageTitle,
					"action": this.state.eventFormAction,
					"label": googleAnalytics.page.action.formSubmittedSearchByTruckNo
				}
				googleAnalytics.logEvent(eventOptions);
			}
			var truck = []
			var trucks = this.state.alltrucks;
			console.log($("#inputTruck").val(),"input truck");
			var filteredValue = trucks.filter(function(e){
				return e.truck_no == $("#inputTruck").val()
			});
			console.log(filteredValue,"filteredValue")
			if(filteredValue.length > 0)
			{
				console.log("Matched")
				var lat = parseFloat(filteredValue[0].latitude);
					var lng =parseFloat(filteredValue[0].longitude);
					var data = filteredValue[0];
					map.setCenter(new window.google.maps.LatLng(lat,lng));
					map.setZoom(22);
					var markerLatlng = new window.google.maps.LatLng(lat, lng);
					
					if(filteredValue[0].status == 1)
					{
						var truckText =  "Inside Plant";
						var color =  "#333333";
					}
					else if(filteredValue[0].status == 2)
					{
						var truckText =  "Going to Dealer";
						var color =  "#333333";
					}
					else if(filteredValue[0].status == 3 || filteredValue[0].status == 4) 
					{
						var truckText =  "At Dealer Location";
						var color =  "#333333";
					}
					else if(filteredValue[0].status == 5)
					{
						var truckText =  "Left Destination Location";
						var color =  "#333333";
					}
					else if(filteredValue[0].status == 6)
					{
						var truckText =  "Return to Plant";
						var color =  "#333333";
					}
					else if(filteredValue[0].status == 7)
					{
						var truckText =  "Empty Truck Outside Plant";
						var color =  "#333333";
					}
					else{
						var truckText =  "N/A";
						var color =  "#333333";
					}
					
					if(filteredValue[0].status == 1)
					{
						var image =  require('../../assets/icons/truck_inside_24.png');
					}
					else if(filteredValue[0].status == 2)
					{
						var image =  require('../../assets/icons/truck_going_24.png');
					}
					else if(filteredValue[0].status == 3 || filteredValue[0].status == 4) 
					{
						var image =  require('../../assets/icons/truck_atdealer_24.png');
					}
					else if(filteredValue[0].status == 5)
					{
						var image =  require('../../assets/icons/truck_leftdealer_24.png');
					}
					else if(filteredValue[0].status == 6)
					{
						var image =  require('../../assets/icons/truck_return_24.png');
					}
					else if(filteredValue[0].status == 7)
					{
						var image =  require('../../assets/icons/truck_empty_24.png');
					}
					else{
						var image =  require('../../assets/icons/truck_na_24.png');
					}
					var image =  require('../../assets/icons/truck_na_24.png');
					var marker = new window.google.maps.Marker({
						position: markerLatlng,
						map: map,
						title:data.truck_no,
						icon:image
					});



					var contentarr = []
					var headerTitle = filteredValue[0].truck_no;
					// contentarr.push({"key":"Truck No", "value":filteredValue[0].truck_no});
					contentarr.push({"key":"Transporter Name", "value":filteredValue[0].transporter_name});
					//contentarr.push({"key":"Distance from geofence", "value":trucks[i].distance});
					//contentarr.push({"key":"GPS Provider", "value":filteredValue[0].actual_lspuser});
					contentarr.push({"key":"GPS Provider", "value":"Enmovil"});
					contentarr.push({"key":"Speed (KMPH)", "value":filteredValue[0].speed});
					contentarr.push({"key":"Last Packet Time", "value":getHyphenDDMMMYYYYHHMM(filteredValue[0].timestamp)});
					if(filteredValue[0].truck_travelling_status == 0)
					{
						var trvalstat = "Travelling"
					}
					else
					{
						var rrr = ConvertHHMMSeconds(filteredValue[0].elaped_time_from_last_moved_seconds)
						var trvalstat = "Idle: "+rrr
					}
					contentarr.push({"key":"Travelling Status", "value":trvalstat});
					// contentarr.push({"key":"Address", "value":filteredValue[0].area+", "+filteredValue[0].state});
					contentarr.push({"key":"Address", "value":filteredValue[0].device_address});
					contentarr.push({"key":"City", "value":filteredValue[0].device_city});
					contentarr.push({"key":"State", "value":filteredValue[0].device_state});
					
					var contentString = infoBox(image, headerTitle, contentarr, '')

				



					// var contentString = "<table><tr><th>Status: </th><td style='color:"+color+";font-weight:600;text-align: right'>"+truckText+"</td></tr><tr><th>Distance from geofence: </th><td style='text-align: right'>";
					// contentString = contentString + trucks[i].distance+"</td></tr><th>Truck No: </th><td style='text-align: right'>"+trucks[i].truck_no+"</td></tr><tr><th>Name: </th><td style='text-align: right'>";
					// contentString = contentString + trucks[i].transporter_name+"</td></tr><tr><th> LSP Name:</th><td style='text-align: right'>"+trucks[i].lsp_name+"</td></tr><tr><th>Address:</th><td style='text-align: right'>";
					// contentString = contentString  +trucks[i].address+"</td></tr></table>";
				
					
					var infowindow = new window.google.maps.InfoWindow({
						content: contentString
					});
					
					marker.setAnimation(window.google.maps.Animation.DROP)
					marker.addListener('click', function() {
						infowindow.open(map, marker);
					});
					
					marker.setMap(map);
			}
			else{
				this.setState({
					show: true, basicType:'warning',
					basicTitle:"No Data found.",
					loadshow:'show-n',
					showform:'show-m',
				})
			}
			console.log(filteredValue);
			// for (var i = 0; i < trucks.length; i++){
			// 	// look for the entry with a matching `code` value
			// 	if (trucks[i].truck_no == $("#inputTruck").val()){
			// 	   // we found it
			// 	  // trucks[i].name is the matched result
				
			// 	}
			//   }
			/*
					var lat = parseFloat(e.value.latitude);
					var lng =parseFloat(e.value.longitude);
					var data =e.value;
					map.setCenter(new window.google.maps.LatLng(lat,lng));
					map.setZoom(22);
					var markerLatlng = new window.google.maps.LatLng(lat, lng);
					
					if(e.value.status == 1)
					{
						var image =  require('../../assets/icons/truck_inside_24.png');
					}
					else if(e.value.status == 2)
					{
						var image =  require('../../assets/icons/truck_going_24.png');
					}
					else if(e.value.status == 3 || e.value.status == 4) 
					{
						var image =  require('../../assets/icons/truck_atdealer_24.png');
					}
					else if(e.value.status == 5)
					{
						var image =  require('../../assets/icons/truck_leftdealer_24.png');
					}
					else if(e.value.status == 6)
					{
						var image =  require('../../assets/icons/truck_return_24.png');
					}
					else if(e.value.status == 7)
					{
						var image =  require('../../assets/icons/truck_empty_24.png');
					}
					else{
						var image =  require('../../assets/icons/truck_na_24.png');
					}
					
					var marker = new window.google.maps.Marker({
						position: markerLatlng,
						map: map,
						title:data.truck_no,
						icon:image
					});
					var contentString = "Truck No: "+data.truck_no+"<br />Name: "+data.transporter_name+" LSP Name:"+data.lsp_name+"<br />Address:"+data.address;
					var contentString = "<table><tr><th>Status: </th><td style='text-align: right'>"+data.status;
					contentString = contentString +"</td></tr><tr><th>Distance from geofence: </th><td style='text-align: right'>"+data.distance+" (KM)</td></tr><th>Truck No: </th><td style='text-align: right'>"+data.truck_no;
					contentString = contentString + "</td></tr><tr><th>Name: </th><td style='text-align: right'>"+data.transporter_name+"</td></tr><tr><th> LSP Name:</th><td style='text-align: right'>"+data.lsp_name;
					contentString = contentString + "</td></tr><tr><th>Address:</th><td style='text-align: right'>"+data.address+"</td></tr></table>";
					
					
					var infowindow = new window.google.maps.InfoWindow({
						content: contentString
					});
					
					marker.setAnimation(window.google.maps.Animation.DROP)
					marker.addListener('click', function() {
						infowindow.open(map, marker);
					});
					
					marker.setMap(map);
			*/
		}
		else{
			this.setState({
				show: true, basicType:'warning',
				basicTitle:"Enter Valid Truck Number",
				loadshow:'show-n',
				showform:'show-m',
			})
		}
	}
	onCloseUserManualModal = () => {
		this.setState({ usermanualmodal: false });
	};

	onClickShowUserManaul = () => {
		this.setState({ usermanualmodal: true });
	};
	exportPDF() {
		let doc = new pdf.PdfDocument({
			header: {
				declarative: {
					text: 'Carrier Location on Map\t&[Page]\\&[Pages]',
					font: new pdf.PdfFont('times', 12),
					brush: '#bfc1c2'
				}
			},
			lineGap: 2,
			pageSettings: {
				margins: {
					left: 10,
					right: 10,
					top: 10,
					bottom: 10
				}
			},
			pageAdded: function(s, e) {
                let image = require('../../assets/icons/logo.png');
                let enmovilImage = require('../../assets/icons/enmovil.png');
                //
				s.drawImage(image, null, null, {width: 100,
					height: 25,
					stretchProportionally: true,
					align: pdf.PdfImageHorizontalAlign.Center
				});
				s.drawImage(enmovilImage, 480, 0, {width: 100,
					height: 25,
					stretchProportionally: true,
					align: pdf.PdfImageHorizontalAlign.Right
				});
            },
			ended: (sender, args) => pdf.saveBlob(args.blob, 'Document.pdf')
		});
		
		doc.moveDown();
		var consignees = this.state.rowData;
		console.log(consignees,"consignees")
		consignees.map(function(c){
			try{
				if(c.truck_travelling_status == 0)
				{
					c.truck_travelling_status = "Travelling";
				}
				else
				{
					c.truck_travelling_status = "Idle: "+ConvertHHMMSeconds(c.elaped_time_from_last_moved_seconds)
				}
			}
			catch(e){
				c.truck_travelling_status = "";
			}
			if(c.segment == "P")
			{
				c.segment = "Primary"
			}
			else{
				c.segment = "Secondary"
			}
		});
		if(consignees.length > 35)
		{
			var totalPages = (consignees.length/35).toString();
			var lastRemainder = parseInt(totalPages.split(".")[1])
			totalPages = parseInt(totalPages.split(".")[0])
			//console.log(totalPages,"totalPages")
			//console.log(lastRemainder,"lastRemainder")
			var endPage = 35
			var startPage = 0
			for(var i=0;i<totalPages;i++)
			{
				//console.log(startPage, endPage);
				var sen = consignees.slice(startPage,endPage);
				this.$_drawEmployee(doc, sen);
				
				if((i+1) == totalPages)
				{
					var sen1 = consignees.slice(endPage+1,lastRemainder);
					this.$_drawEmployee(doc, sen1);
				}
				else
				{
					
					startPage = endPage + 1;
					endPage = endPage + 35;
				}
				
			}
		}
		else
		{
			this.$_drawEmployee(doc, consignees);
		}
		//this.$_drawEmployee(doc, consignees);
		// consignees.forEach((employee, i, arr) => {
		//     this.$_drawEmployee(doc, employee);
		//     if (i < arr.length - 1) {
		//         doc.addPage();
		//     }
		// });
		doc.end();
	}
	//
	$_drawEmployee(doc, consignees) {
		//let tot = employee.expenses.totals;
		let expenses = consignees;
		if(expenses.length > 0)
        {
			let columns = [
				{ header: 'Truck No', binding: 'truck_no', format: 'c'},
				//{ header: 'Segment', binding: 'segment', format: 'c' },
				{ header: 'Address', binding: 'device_address', format: 'c'},
				{ header: 'City', binding: 'device_city', format: 'c'},
				{ header: 'State', binding: 'device_state', format: 'c'},
				{ header: 'Speed', binding: 'speed', format: 'c'},
				{ header: 'Travelling Status', binding: 'truck_travelling_status', format: 'c'},
			], bold = new pdf.PdfFont('times', 10, 'normal', 'bold'), colWidth = this.colWidth, rowHeight = this.rowHeight;
			
			let y = doc.y;
			
			doc.moveDown(2);
		
			// * draw table *
			doc.saveState();
			
			y = 0;
			let scale = doc.width / (columns.length * colWidth), docY = doc.y;
			
			if (scale > 1) {
				scale = 1;
			}
			
			doc.scale(scale, scale, new wijmo.Point(0, docY));
			doc.translate(0, docY);
			
			// header
			this.$_renderRow(doc, y, columns, column => column.header, null, bold, '#fad9cd');
			
			y += rowHeight;
			
			// body
			expenses.forEach(item => {
				//this.$_checkLineAvailable(doc);  
				this.$_renderRow(doc, y, columns, column => item[column.binding], column => column.format);
				y += rowHeight; 
			});
			doc.addPage(doc.currentPageSettings);
			
		
		}
	}
	//
	$_checkLineAvailable(doc) {
		if (doc.height - doc.y < doc.lineHeight() + doc.lineGap) {
			doc.addPage();
		}
	}
	//
	$_renderRow(doc, y, values, valueGetter, formatGetter, font, brush) {
		let colWidth = this.colWidth, rowHeight = this.rowHeight;
		//
		values.forEach((v, idx) => {
			let x = idx * colWidth;
			//
			doc.paths
				.rect(x, y, colWidth, rowHeight)
				//.fill(brush || '#eaeaea')
				.stroke();
			//
			let value = valueGetter != null ? valueGetter(v) : v || '';
			let format = formatGetter != null ? formatGetter(v) : '';
			//
			if (value !== 'Total') {
				value = wijmo.changeType(value, wijmo.DataType.String, format);
			}
			//
			doc.drawText(value, x + 2, y + 2, {
				font: font,
				height: rowHeight,
				width: colWidth
			});
		});
	}
    render(){
		const modalStyles  = {
			width:'1300px !important',
		}
		
		const {usermanualmodal} = this.state;			
		const { truckstatus } =this.state;
		const truckstatusoptions = [
			{ value: '0', label: 'All Status' },
			{ value: '1', label: 'Inside Plant' },
			{ value: '2', label: 'Going to Destination' },
			{ value: '3', label: 'At Destination Location' },
			{ value: '5', label: 'Left Destination Location' },
			/*{ value: '6', label: 'Return to Plant' },*/
			{ value: '7', label: 'Empty Truck Outside Plant' },
		  ];
		  const kpioptions = [
			  { value: '0', label: 'All Status' },
			  { value: '1', label: 'Overspeed' },
			  { value: '2', label: 'Transit Time Delay' },
			  { value: '3', label: 'Night Driving' },
			  { value: '4', label: 'Enroute Stoppages' }
			];
    	const dStyles={
			width:'100%',
			marginLeft:'0px',
			paddingLeft:'0px',
			height:'500px'
		}
		const btstye={
			marginBottom:"10px"
		}
		var listData = []
        try{
            this.state.filteredData.map(function(e){
                listData.push(<li className="dropdown-truck-no">{e}</li>)
                })
		}catch(e){console.log(e)}
		var showonlysnd = true;
		var showonlyprt = true;
		var hidecol = true;
		var hideDistance = true;
		var columnDefs= [
			{
				headerName: "",
				field: "map",
				width: 60,
				cellRenderer: function(params){
					return "<i class='icofont icofont-map-pins f25'></i>"
				},
				editable: false
			},
			{
				headerName: "Truck No",
				field: "truck_no",
				width: 120,
				filter: true
			},
			// {
			// 	headerName: "Transporter Name",
			// 	field: "transporter_name",
			// 	width: 130,
			// 	filter: true
			// },
			// {
			// 	headerName: "Plant Code",
			// 	field: "plant_code",
			// 	width: 100,
			// 	filter: true
			// },
			{
				headerName: "Segment",
				field: "segment",
				width: 110,
				filter: true,
				hide: showonlysnd,
				valueGetter: function(params){
					if(params.data.segment == "P")
					{
						return "Primary"
					}
					else{
						return "Secondary"
					}
				}
			},
			// {
			// 	headerName: "Distance From Co-ordinates (KMS)",
			// 	field: "distance_from_coordinates",
			// 	width: 160,
			// 	filter: true,
			// 	valueGetter: function(params){
			// 		return Math.round(params.data.distance_from_coordinates)
			// 	}
			// },
			// {
			// 	headerName: "Actual LSP User",
			// 	field: "actual_lspuser",
			// 	width: 130,
			// 	filter: true
			// },
			
			// {
			// 	headerName: "Area",
			// 	field: "area",
			// 	width: 130,
			// 	filter: true
			// },
			// {
			// 	headerName: "State",
			// 	field: "state",
			// 	width: 130,
			// 	filter: true
			// },

			{
				headerName: "Address",
				field: "device_address",
				width: 200,
				filter: true
			},
			{
				headerName: "City",
				field: "device_city",
				width: 130,
				filter: true
			},
			
			{
				headerName: "State",
				field: "device_state",
				width: 130,
				filter: true
			},
			
			{
				headerName: "Speed",
				field: "speed",
				width: 130,
				filter: true,
				valueGetter : function(params){
					if(params.data.speed !=undefined && params.data.speed !="")
					{
						return Math.round(params.data.speed)
					}
				}
			},
			
			{
				headerName: "Travelling Status",
				field: "truck_travelling_status",
				width: 180,
				filter: true,
				resizable:true,
				valueGetter : function(params){
					try{
						if(params.data.truck_travelling_status == 0)
						{
							return "Travelling";
						}
						else
						{
							return "Idle: "+ConvertHHMMSeconds(params.data.elaped_time_from_last_moved_seconds)
						}
					}
					catch(e){
						return "";
					}
				}
			}
			 
		  ];

        return (
            
    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        > 
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
		            	
			                <div className="card-header">
				       			<h5>
				       				{/* <i className="icofont icofont-truck cus-i"></i> <span> Trucks Locations ( {hText} ) </span> */}
				       				<i className="icofont icofont-truck cus-i"></i> <span> {this.state.pageTitle} </span>
				       				<button className="btn btn-danger" style={{float:"right",marginRight:"10px"}} onClick={this.exportPDF.bind(this)}>Export To PDF</button>
								</h5>
				   			</div>
				   			<div className="card-body row">
							   <div className="col-xl-12 col-lg-12 col-sm-12 row ">
							   		<form className=" theme-form col-xl-4 col-lg-4" onSubmit={this.handleFilter.bind(this)}>
										<div className="col-xl-8 col-lg-8 col-sm-8" style={{paddingRight:'0px',marginBottom:"0.2em"}}>
											<label>Search by Trip Type:</label>
											<Select placeholder="Select Trip Type"
											isMulti={false}
											value={this.state.triptype}
											onChange={this.setTripType.bind(this)} 
											options={(this.state.deptcode == "LOG-SC")?[{"value":1,"label":"ALL"}]:
											[{"value":1,"label":"ALL"},{"value":2,"label":"On Trip"},{"value":5,"label":"Waiting at Dealer"},{"value":3,"label":"Empty / Non-MSIL trip"}]
											} 
											required/>
										</div>
										<div className="col-xl-2 col-lg-2 col-sm-2" style={{padding:"0"}}>
											<button name="go" id="go"style={{height:"38px",margin:"10px 15px"}} className="btn btn-primary" type="submit">Go</button>
										</div>
									</form>
									<div className="col-xl-2 col-lg-2">
										<label className=""><i className="icofont icofont-vehicle-delivery-van cus-i"></i> Total Carriers : </label>
									</div>
									<div className="col-xl-2 col-lg-2">
										<h6 className="txt-info f20" style={{paddingTop:"0.2em",marginLeft:"-2em"}}>
											<span className="counter">
												<CountUp end={(this.state.onTripCounter != '')?this.state.onTripCounter:0}/>
											</span>
										</h6>
									</div>
									<div className={"trucks-dropdown "+this.state.showTrucksList} style={{top: "72px",left: "60em", width: "20.3em"}}>
										<ul>
											{listData}
										</ul>
									</div>
									<form className=" theme-form col-xl-4 col-lg-4" onSubmit={this.truckFormHandler.bind(this)}>
										<div className="col-xl-12 col-lg-12 col-sm-12" style={{paddingRight:'0px'}}>
											<label>Search by Truck No:</label>
											<input type="text" name="truck_no" id="inputTruck"  placeholder="Truck No" autoComplete="off" className="form-control" onFocus={this.initalList.bind(this)} onChange={this.handlerForm.bind(this)} />
										</div>
										<div className="col-xl-12 col-lg-12 col-sm-12" style={{padding:"0"}}>
											<button name="go" id="go"style={{height:"38px",margin:"10px 15px"}} className="btn btn-primary" type="submit">Go</button>
										</div>
									</form>
								</div>
								<div className="col-xl-12 col-lg-12 col-sm-12 row ">
									<div className="col-xl-6 col-lg-6">
										<div id="myGrid" style={{ height: "540px",width: "100%"}}  className="ag-theme-balham">
											<AgGridReact
												modules={this.state.modules}
												columnDefs={columnDefs}
												defaultColDef={this.state.defaultColDef}
												rowData={this.state.rowData}
												enableCharts={false}
												enableRangeSelection={false}
												autoGroupColumnDef={this.state.autoGroupColumnDef}
												onGridReady={this.onGridReady}
												onGridState={this.onGridState}
												statusBar={this.state.statusBar}
												sideBar={this.state.sideBar}
												paginationPageSize={this.state.paginationPageSize}
												pagination={true}
												enableRangeSelection={false}
												floatingFilter={false}
												onCellClicked={this.popmarker}
											/>
										</div>
									</div>
									<div className="col-xl-6 col-lg-6">
										
										<div className={"dataLoadpage " +(this.state.loadshow)}>
										</div>
										<div className={"dataLoadpageimg " +(this.state.loadshow)}>
											<div className="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
										</div>
										
										<div id="map_canvas" className={this.state.mwidth} style={dStyles}></div>
										
									</div>
								</div>							   		
                            </div>
							<div className="card-header">
				       			<h6>
				       				<i className="fa fa-info-circle" style={{color:"cornflowerblue"}}></i> <span> Note : Only trucks with GPS data in the last 24 hours are considered </span>
				       				
								</h6>
				   			</div>
							
				   		</div>	
				   	</div>
					   
				 </div>
				
				 
				 <div className={"slide-r "+(this.state.sliderTranslate)} >
				 	
					
				 	<div className="slide-r-body" style={{position:"relative"}}>
						<div className={"dataLoad " +(this.state.loadshow)}>
							<img src={require("../../assets/icons/loader.gif")} />
						</div>
				 		<div className="col-xl-12 col-lg-12">
				 			 {this.displayData}
						</div>
					</div>
				 </div>
				
				<span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span>
          		<Modal open={usermanualmodal} onClose={this.onCloseUserManualModal.bind(this)} styles={modalStyles}>
					<div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<h5 className="model-title">Truck Location (Map)</h5>
						<div className="col-xl-12 col-lg-12 mt-20p">
							<div className="col-xl-12 col-lg-12">
								This utility provides pictorial representation of entire fleet (departmental) present at various locations throughout India. 
							</div>
							<div className="col-xl-12 col-lg-12">
								Through this utility option user can find vehicle details either for individual truck or for selected transporter fleet for in transit and Empty/non MSIL trips
							</div>
							<div className="col-xl-12 col-lg-12">
								Image here
							</div>
						</div>
					</div>
				</Modal>

            </div>
              
        );
    }
}


function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function getPolygonCoords()
{
	
	 var len = geoFencingArea.getPath().getLength();
	 console.log(len);
	 var htmlStr = "";
	 var coordinates=[];
	 for (var i = 0; i < len; i++) {
		// var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
		// coordinates.push()
		 var strlatlng = geoFencingArea.getPath().getAt(i).toUrlValue(5);
		 var spliting = strlatlng.split(",");
		 var latlngd = {"lat":parseFloat(spliting[0]),"lng":parseFloat(spliting[1])};
		 coordinates.push(latlngd);
	  // htmlStr += "new google.maps.LatLng(" + geoFencingArea.getPath().getAt(i).toUrlValue(5) + "), ";
	   //Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
	   //htmlStr += "" + geoFencingArea.getPath().getAt(i).toUrlValue(5);
	 }
	// console.log("htmlStr", coordinates);
	 
}
function ConvertSeconds(totalSeconds)
	{
		// var days = Math.floor(num/86400);
		// var hours = Math.floor(num/3600)
		// var minutes = Math.floor(num/60);
		var minutes = Math.round((totalSeconds % 3600) / 60);
		var hours = Math.round((totalSeconds % 86400) / 3600);
		var days = Math.round((totalSeconds % (86400 * 30)) / 86400);
		// let d = (new Date(t0)) - (new Date(t1));
		// let weekdays     = Math.floor(d/1000/60/60/24/7);
		// let days         = Math.floor(d/1000/60/60/24 - weekdays*7);
		// let hours        = Math.floor(d/1000/60/60    - weekdays*7*24            - days*24);
		// let minutes      = Math.ceil(d/1000/60       - weekdays*7*24*60         - days*24*60         - hours*60);
		return days+" Day(s) "+hours+" Hrs "+minutes+" mins"
	}
	function ConvertHHMMSeconds(n)
	{
		// console.log(totalSeconds,"totalSeconds")
		// var seconds = Math.round((totalSeconds % 3600));
		// var minutes = Math.round((totalSeconds % 3600) / 60);
		// var hours = Math.round((totalSeconds % 86400) / 3600);
		// var days = Math.round((totalSeconds % (86400 * 30)) / 86400);

		var days =Math.round(parseInt( n / (24 * 3600)));
 
        n = n % (24 * 3600);
        var hours = Math.round(parseInt(n / 3600));
 
        n %= 3600;
        var minutes = Math.round(n / 60);
 
        n %= 60;
        var seconds = Math.round(n);

		return days+" Day(s) "+hours+" Hrs "+minutes+" mins "+ seconds+" secs"
	}

$(document).ready(function(){
    $(document).on("click",".dropdown-truck-no",function(){
        var vals = $(this).text();
         $("#inputTruck").val(vals);
         $(".trucks-dropdown").removeClass("show-m");
         $(".trucks-dropdown").addClass("show-n");
    });

    $("body").on("click",function(e){
        var container = $("#inputTruck");
        if(!container.is(e.target))
        {
            try{
                if($(".trucks-dropdown").attr("class").includes("show-m") || $(".trucks-dropdown").attr("class") == "trucks-dropdown")
                {
                    $(".trucks-dropdown").removeClass("show-m");
                    $(".trucks-dropdown").addClass("show-n");
                }
            }catch(e){}
            
        }
        
        
    })
})