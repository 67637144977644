'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import * as Datetime from 'react-datetime';
import $ from 'jquery';
import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 
import Modal from 'react-responsive-modal';
import WeatherMapComponent from "./weathermap";
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

export default class ManagementDashboard extends Component {
  constructor(props) {
    super(props);
      this.state = {

        eventAction: null,
        show: false,
        
        basicTitle:'',
        basicType:"default",
        overly:"show-n",
        loadshow:"show-n",
        eventGridAction: "gridAction",
        optionlist:[],
        sliderRouteTranslate: ""
        
    }
    
  }

    componentDidMount(){
        var options = []
        if(this.props.match.path == "/managementdashboard")
        {
            
            options.push({
                title:"Imports Vessels Status",
                icon:"bar-chart.png",
                url:"/msilvesselschedule"
            });
            options.push({
                title:"Rail Stock Status",
                icon:"train-msil.png",
                url:"/railstockstatus"
            });
            options.push({
                title:"SND Planning Status",
                icon:"marketing-strategy.png",
                url:"/sndplanningstatusmgmt"
            });

            options.push({
                title:"Carrier Arrival Report (SND)",
                icon:"truckmsil.png",
                url:"/sndemptytruckvicinitymgmt"
            });
            options.push({
                title:"Truck Location (Maps)",
                icon:"truck_inside.png",
                url:"/livetrucksinfo"
            });
            options.push({
                title:"On Time Performance",
                icon:"performance.png",
                url:"/dashboardsummary"
            });

            options.push({
                title:"Safety Report (All Locations)",
                icon:"cloudy.png",
                url:"/safetyreportmgmt"
            });
            options.push({
                title:"Weather Forecast",
                icon:"weather.png",
                url:"weathermap"
            });
            options.push({
                title:"Logistics Health App Status",
                icon:"heartbeat.png",
                url:"/divisionhealthreport"
            })
            options.push({
                title:"BRM Analytics",
                icon:"trending.png",
                url:"/brmdashboardmgmt"
            })
        }
        if(this.props.match.path == "/sndmanagementdashboard")
        {
            options.push({
                title:"Sales and Dispatch Dashboard",
                icon:"performance.png",
                url:"/snddashboardtemplate"
            });
            options.push({
                title:"Daily Running",
                icon:"delivery_truck.png",
                url:"/dailyrunningdashboard"
            });
            options.push({
                title:"Loading Dashboard",
                icon:"truckmsil.png",
                url:"/sndloadingdashboard"
            });
            options.push({
                title:"Unloading Dashboard",
                icon:"truck_outside_.png",
                url:"/sndunloadingdashboard"
            });
            options.push({
                title:"Transit Analytics",
                icon:"bar-chart.png",
                url:"/sndtransitanalytics"
            });
            options.push({
                title:"Transporter Daily Running",
                icon:"delivery_truck.png",
                url:"/sndtransportdashboard"
            });
            options.push({
                title:"GPS Dashboard",
                icon:"truck_inside.png",
                url:"/sndgpsdashboard"
            });
            options.push({
                title:"On Time Performance",
                icon:"clock.png",
                url:"/ontimedashboard"
            });
        }
        if(this.props.match.path == "/prtmanagementdashboard")
        {
            options.push({
                title:"Spare Parts Dashboard",
                icon:"performance.png",
                url:"/prtdashboardtemplate"
            });
            options.push({
                title:"Daily Running",
                icon:"delivery_truck.png",
                url:"/prtdailyrunningdashboard"
            });
            options.push({
                title:"Loading Dashboard",
                icon:"truckmsil.png",
                url:"/prtloadingdashboard"
            });
            options.push({
                title:"Unloading Dashboard",
                icon:"truck_outside_.png",
                url:"/prtunloadingdashboard"
            });
            options.push({
                title:"Transit Analytics",
                icon:"bar-chart.png",
                url:"/prttransitanalytics"
            });
            options.push({
                title:"GPS Dashboard",
                icon:"truck_inside.png",
                url:"/prtgpsdashboard"
            });
        }
        if(this.props.match.path == "/tnpmanagementdashboard")
        {
            options.push({
                title:"Trin and Production Dashboard",
                icon:"performance.png",
                url:"/tnpdashboardtemplate"
            });
            options.push({
                title:"Loading Dashboard",
                icon:"truckmsil.png",
                url:"/tnploadingdashboard"
            });
            options.push({
                title:"Unloading Dashboard",
                icon:"truck_outside_.png",
                url:"/tnpunloadingdashboard"
            });
            options.push({
                title:"Transit Analytics",
                icon:"bar-chart.png",
                url:"/tnptransitanalytics"
            });
            options.push({
                title:"GPS Dashboard",
                icon:"truck_inside.png",
                url:"/tnpgpsdashboard"
            });
        }
        if(this.props.match.path == "/scmanagementdashboard")
        {
            
            options.push({
                title:"Supply Chain Dashboard",
                icon:"performance.png",
                url:"/scdashboardtemplate"
            });
        }

        this.setState({
            optionlist:options
        })
    }
   
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
	}
    onCloseRouteDiv = (event) => {
        this.setState({
            sliderRouteTranslate : "",
            overly : "show-n"
        })
    }
    changeHandler = (event) =>{
        this.setState({
            sliderRouteTranslate : "slider-translate-60p",
            overly : "show-m"
        })
    }

    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onClickHideAll(){
        this.setState({
        loadshow:'show-n',
        overly:'show-n',
        bulkslide:"",
        
        });
        
    }


    render(){
        return (
            <div class="container-fluid">   
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="card col-xl-12 col-lg-12 col-md-12">
                    <div className="card-header">
                        <h5>MIS Dashboard : Logistics Division</h5>
                    </div>
                    <div className="card-body">
                        {/* <div className="row col-xl-12 col-lg-12" style={{marginBottom:"2em",cursor:"pointer"}}>
                            <div className="col-xl-4 col-md-4 col-lg-4" style={{paddingLeft:"5em"}} >
                                <img src={require("../../assets/icons/clock.png")} style={{paddingLeft:"4em",paddingBottom:"2em"}}></img>
                                <h5><a href="/ontimedashboard">On Time Performance</a></h5>
                            </div>
                            <div className="col-xl-4 col-md-4 col-lg-4" style={{paddingLeft:"5em"}} >
                                <img src={require("../../assets/icons/bar-chart.png")} style={{paddingLeft:"4em",paddingBottom:"2em"}}></img>
                                <h5><a href="/msilvesselschedule">Imports Vessels Status</a></h5>
                            </div>
                            <div className="col-xl-4 col-md-4 col-lg-4" style={{paddingLeft:"5em"}} >
                                <img src={require("../../assets/icons/cloudy.png")} style={{paddingLeft:"4em",paddingBottom:"2em"}}></img>
                                <h5>Safety Report (All Locations)</h5>
                            </div>
                        </div>
                        <div className="row col-xl-12 col-lg-12" style={{marginBottom:"2em",cursor:"pointer"}}>  
                            <div className="col-xl-4 col-md-4 col-lg-4" style={{paddingLeft:"5em"}} >
                                <img src={require("../../assets/icons/marketing-strategy.png")} style={{paddingLeft:"4em",paddingBottom:"2em"}}></img>
                                <h5>SND Planning Status</h5>
                            </div>
                            <div className="col-xl-4 col-md-4 col-lg-4" style={{paddingLeft:"5em"}} >
                                <img src={require("../../assets/icons/train-msil.png")} style={{paddingLeft:"4em",paddingBottom:"2em"}}></img>
                                <h5>Rail Stock Status</h5>
                            </div>
                            <div className="col-xl-4 col-md-4 col-lg-4" style={{paddingLeft:"5em"}} >
                                <img src={require("../../assets/icons/truckmsil.png")} style={{paddingLeft:"4em",paddingBottom:"2em"}}></img>
                                <h5><a href="/sndemptytruckvicinity">Carrier Arrival Report (SND)</a></h5>
                            </div>
                        </div>
                        <div className="row col-xl-12 col-lg-12" style={{cursor:"pointer"}}>  
                            <div className="col-xl-4 col-md-4 col-lg-4" style={{paddingLeft:"5em"}} >
                                <img src={require("../../assets/icons/heartbeat.png")} style={{paddingLeft:"4em",paddingBottom:"2em"}}></img>
                                <h5>Logistics Health App Status</h5>
                            </div>
                        </div> */}  

                        <div className="row col-xl-12 col-lg-12">
                            {(this.state.optionlist.length > 0)?
                            (this.state.optionlist.map((item)=>
                                <div className="col-xl-3 col-md-3 col-lg-3 mt-20p mb-20p ml-40p mr-40p" style={{textAlign:"center",background:"#eeeeee",padding:"20px", cursor: "pointer"}}>
                                    {(item.url != "weathermap")?
                                        <a href={item.url} style={{color:"#666666"}}>
                                            <div>
                                                <img src={require("../../assets/icons/"+item.icon)} style={{width:"48px"}} />
                                            </div>
                                            <div className="f16">
                                                {item.title}
                                            </div>
                                        </a>
                                    :
                                        <div onClick={this.changeHandler.bind(this)} style={{color:"#666666"}}>
                                            <div>
                                                <img src={require("../../assets/icons/"+item.icon)} style={{width:"48px"}} />
                                            </div>
                                            <div className="f16">
                                                {item.title}
                                            </div>
                                        </div>
                                    }
                                    
                                </div> 
                            ))
                            
                        :""}
                        {this.state.sliderRouteTranslate != ''?
                            <WeatherMapComponent context={this} sliderValue={this.state.sliderRouteTranslate} onCloseRouteDiv={this.onCloseRouteDiv.bind(this)}/>                    
                        :""	
                        }
                           
                        </div>

                    </div>
                </div>

            </div>
        );
  }
}


function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};