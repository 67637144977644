import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import _ from "lodash";
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';

import axios from 'axios';
import Multiselect from 'multiselect-dropdown-react';
import Breadcrumb from '../common/breadcrumb.component';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
// import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';


$.datetimepicker.setLocale('en');
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');

var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749','#F73E3E', '#B7B3B3' ];
var selectedColor;
var colorButtons = {};
var fencingLength=0;
var mapZoom=12;
var coordinates=[];
const fenchtypeoptions = [
	  { value: 'Plant', label: 'Plant' },
	  { value: 'TVP', label: 'TVP' },
	  { value: 'Stockyard', label: 'Stockyard' },
	  { value: 'Railyard', label: 'Railyard' },
	  { value: 'Port', label: 'Port' },
	  { value: 'Vendor', label: 'Vendor' },
	  { value: 'MSP', label: 'MSP' },
	];
    var marker;
    var circle;
    var map;
    var rlatitude;
    var rlongitude;
  
export default class EditLocation extends Component {

    constructor(props) {
        super(props);

        this.state = {
			pageTitle: "Edit",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			currentDepartmentCode: null,
			currentDepartmentTitle: null,
        	routemap:'',
        	departments:[],
        	dept_code:"",
        	erroMessage:'',
            redirectpage:'',
            backtomain:"",
            latitude:"",
            longitude:"",
            truck_no:"",
            alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            defaultlat:'',
			defaultlng:'',
			timestamp:''
    	      
        };
        this.showMap = this.showMap.bind(this);
        this.checkHandler = this.checkHandler.bind(this);
	}
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
    componentDidMount = async () =>{
		loadDateTimeScript();
		if (googleAnalytics.page.enableGA) {
			this.logPageView();
        }
        //console.log(this.props.match.params.id)
		// get department code to filter records by department
		let currentDepartmentCode = await this.getCurrentDepartmentCode(this.props.match.path);

        var parameters = {
            rowid:this.props.match.params.id
        }
        await redirectURL.post('/consignments/manuallocationitem', parameters)
        .then((response) => {
            var records = response.data;
            document.getElementById("latitude").value = records[0].latitude
			document.getElementById("longitude").value = records[0].longitude
			var timestamp = moment.parseZone(records[0].timestamp).format("DD-MM-YYYY HH:mm");
			
			document.getElementById("timestamp").value = timestamp
			this.setState({
                truck_no:records[0].truck_no,
                defaultlat:records[0].latitude,
				defaultlng:records[0].longitude,
				timestamp:timestamp
            });
            
        })
        .catch(function(error){
            console.log(error)
        });

    	await this.showMap();
    }
   
    showMap =() =>{
    	this.renderMap();
    }
    renderMap = () => {
    	
           loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyBQUmk7hdajZEAYllxKhVPnUx5pXuK-j_Y&libraries=places,drawing&callback=initMap");
           window.initMap = this.initMap
	}
	getCurrentDepartmentCode(pathToMatch = null) {
		let departmentCode = null;
		let departmentName = null;
        let departmentTitle = null;
        var backtomain = '';
		switch(this.props.match.params.dept) {
			case "SNDG":
				departmentCode='SNDG';
				departmentName = "Sales and Dispatch";
                departmentTitle = " (Sales and Dispatch) ";
                backtomain = "/sndmanuallocations";
				break;
			case "LOG-PRT":
				departmentCode='LOG-PRT';
				departmentName = "Spare Parts";
				departmentTitle = " (Spare Parts) ";
                backtomain = "/prtmanuallocations";
				break;
			case "LOG-TNP":
				departmentCode='LOG-TNP';
				departmentName = "Train and Production";
				departmentTitle = " (Train and Production) ";
                backtomain = "/tnpmanuallocations";
				break;
			case "LOG-SC":
				departmentCode='LOG-SC';
				departmentName = "Supply Chain";
				departmentTitle = " (Supply Chain) ";
                backtomain = "/scmanuallocations";
				break;
			default:
				console.log("IN getDepartmentFilter, default, params = ", pathToMatch);
		}
		this.setState({
			currentDepartmentTitle:departmentTitle,
			currentDepartmentCode:departmentCode,
			departments: [{"dept_code": departmentCode,"dept_name": departmentName}],
            dept_code: departmentCode,
            backtomain:backtomain
		});
		return departmentCode;
	}
   
    initMap = () => {
    	var lt=this.state.defaultlat;
        var ln=this.state.defaultlng;
        console.log("lt ", lt)
        console.log("ln ", ln)
    	map = new window.google.maps.Map(document.getElementById('map_canvas'), {
            zoom: 10,
            center: new window.google.maps.LatLng(lt,ln),
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
			/*mapTypeControl: true,
			gestureHandling: 'greedy',
            mapTypeControlOptions: {
                style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: window.google.maps.ControlPosition.TOP_RIGHT
              },            
            disableDefaultUI: true,
            zoomControl: true*/
		});

		var input = document.getElementById('search');
		var searchBox =new window.google.maps.places.SearchBox(input);
		map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

		// Bias the SearchBox results towards current map's viewport.
		map.addListener('bounds_changed', function() {

		  searchBox.setBounds(map.getBounds());
		});
		
		var markers = [];
		//console.log("searchBox",searchBox);
		searchBox.addListener('places_changed', function() 
		{
		  var places = searchBox.getPlaces();
			
		  if (places.length == 0) {
			return;
		  }

		  // Clear out the old markers.
		  markers.forEach(function(marker) {
			  console.log("Search Marker ", marker)
			marker.setMap(null);
		  });
		  markers = [];
			// For each place, get the icon, name and location.
		  var bounds = new window.google.maps.LatLngBounds();
		  places.forEach(function(place) {
			if (!place.geometry) {
			  console.log("Returned place contains no geometry");
			  return;
			}
			var icon = {
			  url: place.icon,
			  size: new window.google.maps.Size(71, 71),
			  origin: new window.google.maps.Point(0, 0),
			  anchor: new window.google.maps.Point(17, 34),
			  scaledSize: new window.google.maps.Size(25, 25)
			};

			// Create a marker for each place.
            //console.log("Plae Lat", place.geometry.location.lat());
            //document.getElementById("latitude").value =place.geometry.location.lat();

			//document.getElementById("longitude").value =place.geometry.location.lng();
			
			markers.push(new window.google.maps.Marker({
			  map: map,
			  icon: icon,
			  title: place.name,
			  position: place.geometry.location
			}));

			if (place.geometry.viewport) {
			  // Only geocodes have viewport.
			  bounds.union(place.geometry.viewport);
			} else {
			  bounds.extend(place.geometry.location);
			}
		  });
		  
		  map.fitBounds(bounds);
		});
		
		 circle = new window.google.maps.Circle({
            map: map
		});
		var rlat = document.getElementById("latitude").value
        var rlan = document.getElementById("longitude").value
        
      
		 marker = new window.google.maps.Marker({
			map: map,
			animation: window.google.maps.Animation.DROP,
            position: new window.google.maps.LatLng(rlat, rlan),
		});
        
		marker.addListener('click', function(){
			marker.setAnimation(window.google.maps.Animation.BOUNCE)			
		});
		
        window.google.maps.event.addListener(map, 'click', function(event) {
			
			document.getElementById("latitude").value = event.latLng.lat();
			document.getElementById("longitude").value = event.latLng.lng();
            marker.setPosition(event.latLng);
            rlatitude = event.latLng.lat();
            rlongitude = event.latLng.lng();
            
		});
		circle.bindTo('center', marker, 'position');
		
		
		
		var eventtrigger = document.getElementsByClassName('truck');
       
	}
    
    checkHandler = (event) => {
    	
    	let name = event.target.name;
		let value = event.target.value;
    	this.setState({[name]:value});
    }
	formHandler = (event) => {
			
		event.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmitted,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		var latitude = document.getElementById("latitude").value;
        var longitude = document.getElementById("longitude").value;
		var timestamp = document.getElementById("timestamp").value;
		if(this.state.truck_no != '' && latitude != '' && longitude != '' && timestamp != '')
		{
			var formdata ={
					truck_no: this.state.truck_no,
					latitude:latitude,
					longitude:longitude,
					rowid:this.props.match.params.id,
					timestamp:timestamp
			} 
			redirectURL.post("/consignments/updatetrucklocation", formdata)
			.then((response) => {
				//console.log("resss ", response.data)
				this.setState({
					show:true,
					basicTitle:"Successfully update data",
					basicType:"success"
				})
			})
			.catch(function(e){
				console.log("Error ", e)
			})
		}
		else
		{
			this.setState({
				show:true,
				basicTitle:"All fields are mandatory",
				basicType:"danger"
			})
		}
	}
    closeAlert = async () => {
        await this.setState({
            show: false
        });
        await window.location.reload()
    }

	hideAlert = () => {
        this.setState({
            alert: null
        });
	}
	
    render(){
		if(this.state.redirectpage == 'yes')
		{
			return <Redirect to={this.state.backtomain} push={true} />
		}
    	let order_cnt=[];

    	const dStyles={
				width:'100%',
				height:'500px'
		}
    	 const { dept_code } = this.state;
    	 const { geo_type } = this.state;
        
        return (
            <div className="container-fluid">
                <SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
		            	
			                <div className="card-header">
				       			<h5>
				       				<i className="icon-map cus-i"></i> <span>{this.state.pageTitle}</span>
                                    <a href={this.state.backtomain} className="btn btn-primary float-right cs-btn">Back</a>
					               			
                                </h5>
				   			</div>
				   			<div className="row card-body">
				   			
							<form className=" theme-form col-xl-4 col-lg-4" onSubmit={this.formHandler}>
	                    			<div className="col-xl-12 col-lg-12">
					               		<div className="form-group">
					               			<label className="c-lbl">Truck No *:</label>
					               			<input type="text" name="truck_no" value={this.state.truck_no} onChange={this.checkHandler.bind(this)} className="form-control" placeholder="Truck No" required />
					               		</div>
                                        <div className="form-group">
					               			<label className="c-lbl">Latitude *:</label>
					               			<input type="text" readonly={true} id="latitude" name="latitude"  onChange={this.checkHandler.bind(this)} className="validateNumber form-control" placeholder="Latitude" required />
					               		</div>
				               		
                                        <div className="form-group">
					               			<label className="c-lbl">Longitude *:</label>
					               			<input type="text" readonly={true} id="longitude" name="longitude" onChange={this.checkHandler.bind(this)} className="validateNumber form-control" placeholder="Longitude" required />
					               		</div>

										<div className="form-group">
					               			<label className="c-lbl">Timestamp *:</label>
					               			<input autoComplete="Off" type="text" id="timestamp" name="timestamp" value={this.state.timestamp} onChange={this.checkHandler.bind(this)} className="datetimepicker_mask form-control" placeholder="Timestamp" required />
					               		</div>
				               		
										<div className="form-group">
					               			<button type="submit" className="btn btn-success cs-btn">Save</button>
					               			<a href={this.state.backtomain} className="btn btn-info cs-btn">Cancel</a>
					               			
					               		</div>
									</div>
									
				           		</form>
				           		<div className="col-xl-8 col-lg-8">
								
									{this.state.erroMessage == '' ? '':
											<div className="alert alert-danger"></div>
									}
									  <input type="text" name="search" className="col-xl-6 col-lg-6 form-control mt-5p" id="search" placeholder="Enter Address to search" />
				
				
				               		<div id="map_canvas" className="col-xl-12 col-lg-12" style={dStyles}></div>
				
				               		
			               		</div>
			           		</div>
		           		</div>
	           		</div>
                </div>
            </div>
                
        );
    }
}
function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
                   
function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = Math.floor(hours);
	var minutes = (n) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}                              
var currentinfowindow = null;
function getTimeInfo(marker,timedet,coords)
{
	var timeinfo = new window.google.maps.InfoWindow({
          content: "Time at this location: " + timedet
        });

	marker.addListener('click', function() {
		
				if (currentinfowindow != null) {
					currentinfowindow.close();
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				else
				{
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				
        });
}


 function clearSelection() {
	    if (selectedShape) {
	      selectedShape.setEditable(false);
	      selectedShape = null;
	    }
	  }

	 function setSelection(shape) {
	    clearSelection();
	    selectedShape = shape;
	    //console.log("selectedShape",selectedShape);
	    shape.setEditable(true);
	    
	    selectColor(shape.get('fillColor') || shape.get('strokeColor'));
	  }

  function deleteSelectedShape() {
		fencingLength=0;  
	    if (selectedShape) {
	      selectedShape.setMap(null);
	    }
	    // To show:
	     drawingManager.setOptions({
	       drawingControl: true
	     });
	  }

	  
	 function selectColor(color) {
	      selectedColor = color;
	      for (var i = 0; i < colors.length; ++i) {
	        var currColor = colors[i];
	        //console.log(colorButtons[currColor]);
	        //colorButtons[currColor].style.border = currColor == color ? '1px solid #789' : '1px solid #fff';
	      }

	      // Retrieves the current options from the drawing manager and replaces the
	      // stroke or fill color as appropriate.
	      var polylineOptions = drawingManager.get('polylineOptions');
	      polylineOptions.strokeColor = color;
	      drawingManager.set('polylineOptions', polylineOptions);

	      var rectangleOptions = drawingManager.get('rectangleOptions');
	      rectangleOptions.fillColor = color;
	      drawingManager.set('rectangleOptions', rectangleOptions);

	      var circleOptions = drawingManager.get('circleOptions');
	      circleOptions.fillColor = color;
	      drawingManager.set('circleOptions', circleOptions);

	      var polygonOptions = drawingManager.get('polygonOptions');
	      polygonOptions.fillColor = color;
	      drawingManager.set('polygonOptions', polygonOptions);
	    }

	function setSelectedShapeColor(color) {
	      if (selectedShape) {
	        if (selectedShape.type == window.google.maps.drawing.OverlayType.POLYLINE) {
	          selectedShape.set('strokeColor', color);
	        } else {
	          selectedShape.set('fillColor', color);
	        }
	      }
	    }

	  function makeColorButton(color) {
	      var button = document.createElement('span');
	      button.className = 'color-button';
	      button.style.backgroundColor = color;
	      window.google.maps.event.addDomListener(button, 'click', function() {
	        selectColor(color);
	        setSelectedShapeColor(color);
	      });

	      return button;
	    }

	  function buildColorPalette() {
	       var colorPalette = document.getElementById('color-palette');
	       for (var i = 0; i < colors.length; ++i) {
	         var currColor = colors[i];
	         var colorButton = makeColorButton(currColor);
	         colorPalette.appendChild(colorButton);
	         colorButtons[currColor] = colorButton;
	       }
	       selectColor(colors[0]);
	     }  
	  
	 function getSelectedShape(coords){
		var lt=[]
		//console.log("coordsLatlngs ",coords);
		for (let value of Object.values(coords)) {
		  //console.log("Val is ",value); // John, then 30
		  lt.push(value)
		}
		//console.log("Combine ",lt);
		document.getElementById('coords').value=lt;
		//return lt;
	}



	function loadDateTimeScript(){

		$('.datetimepicker_mask').datetimepicker({
			mask:'39-19-9999 29:59',
			format:'d-m-Y H:i'
		});
		var index  =window.document.getElementsByTagName("script")[1]
		var script = window.document.createElement("script")
		script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
		script.async=true
		script.defer = true
		index.parentNode.insertBefore(script,index)
	}
		

$(document).ready(function(){
    $(document).on('keyup',".validateNumber",function(){
        // alert("click");
        if(isNaN($(this).val()) == true){
            $(this).val($(this).val().slice(0,-1));
        }
    });
});
	
	