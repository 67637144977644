/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import _ from "lodash";
import $ from 'jquery';
// import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import axios from 'axios';

import Grid from '../layouts/gridUpdateComponent';
import DateFormater from '../layouts/dateComponent';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getHyphenDDMMMYYYYHHMM,getHyphenYYYYMMDDHHMMSS } from '../common/utils';
import Modal from 'react-responsive-modal';
import { verifyextcsv } from "../common/verifyext";

import CSVFileValidator from 'csv-file-validator'
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");


export default class MonthlyQueryGenerationReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pageTitle: "Manage Trucks",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
            departmentCode: null,
            currentDepartmentTitle: null,
            rowData: [],
            uploadDivWidth: '0%',
            sliderTranslate: '',
            showDiv: 'show-n',
            uploadFile: '',
            truck_no: '',
            transporter_code: '',
            dept_code: '',
            file: '',
            alltransporters: [],
            alltransporter_codes: [],
            allDepts: [],
            csvmessage: '',
            csverrmessage: '',
            alertshow: 'fade',
            alertmg: 'show-n',
            alerterrmg: 'show-n',
            alerterrshow: 'fade',
            loadshow: 'show-n',
            alert: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            usermanualmodal: false,
            csvcontent: [],
            subject:"",
            defaultColDef: {
				sortable: true,
				filter: true,
				resizable: true,
				pivot: true,
				enableValue: true,
				enableRowGroup: true,
			},
            columnwithDefs :[],
            statusBar: {
				statusPanels: [
					{
						statusPanel: "agTotalAndFilteredRowCountComponent",
						align: "left"
					},
					{
						statusPanel: "agTotalRowCountComponent",
						align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
						id: "columns",
						labelDefault: "Columns",
						labelKey: "columns",
						iconKey: "columns",
						toolPanel: "agColumnsToolPanel"
					},
					{
						id: "filters",
						labelDefault: "Filters",
						labelKey: "filters",
						iconKey: "filter",
						toolPanel: "agFiltersToolPanel"
					}
				]
			},

        }
    }

    componentDidMount() {



    };

    /*Alert Popups*/
    closeAlert = () => {
        this.setState({
            show: false
        });
    }



    hideAlert = () => {
        this.setState({
            alert: null
        });
    }

    /*End*/

    onShowUploadDiv = () => {
        console.log("Click Upload Button")
        this.setState({
            uploadDivWidth: '30%',
            sliderTranslate: "slider-translate",
            showDiv: 'show-m',
            csvmessage: '',
            csverrmessage: '',
        });
    }

    onCloseUploadDiv = () => {
        this.setState({
            uploadDivWidth: '0%',
            sliderTranslate: "",
            showDiv: 'show-n',
            uploadFile: '',
            file: '',
            csvmessage: '',
            csverrmessage: '',
        });
        document.getElementById("upform").reset();
    }

    resetUpload = () => {
        this.setState({
            uploadDivWidth: '0%',
            sliderTranslate: '',
            showDiv: 'show-n',
            uploadFile: '',
            file: ''
        });
        document.getElementById("upform").reset();
    }

    changeSubject = e => {
        this.setState({subject:e.target.value})
    }


    changeFileHandler = e => {
        const config = {
            headers: [

                {
                    name: 'Dealer Name',
                    inputName: 'Dealer Name',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'Dealer City',
                    inputName: 'Dealer City',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'Dealer Code',
                    inputName: 'Dealer Code',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'Truck No',
                    inputName: 'Truck No',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'Invoice No',
                    inputName: 'Invoice No',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'Invoice Time',
                    inputName: 'Invoice Time',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'start_date_time',
                    inputName: 'start_date_time',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'end_date_time',
                    inputName: 'end_date_time',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
            ]
        }

        let data = CSVFileValidator(e.target.files[0], config).then((csvData) => {

            var keys = Object.keys(csvData.data[0])
            var items = ['Dealer Name', 'Dealer City', 'Dealer Code', 'Truck No', 'Invoice No', 'Invoice Time', 'start_date_time', 'end_date_time']
            var check = false
            if (csvData.inValidMessages.length === 0) {
                for (let i in keys) {
                    if (items.includes(keys[i])) {
                        check = true
                    } else {
                        check = false
                        break
                    }
                }
            }
            else {
                console.log("invalid", csvData.inValidMessages)
                check = false

            }
            if (check === false) {
                $("#uploadFile").val("")
                this.setState({
                    show: true,
                    basicTitle: 'dont upload other fields',
                    basicType: 'danger'
                });

            } else {
                this.setState({
                    csvcontent: csvData.data
                });
            }
        })
            .catch(err => { })
        var contentList = []

        var out = new Promise(function (reject, resolve) {
            var reader = new FileReader();
            reader.onload = async function (e) {
                var contents = await e.target.result;
                resolve(contents);
            };
            var tt = reader.readAsText(e.target.files[0]);
        });
        this.setState({
            file: e.target.files[0]
        });

        if (e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {


        }
        else {
            e.target.value = null;
            this.setState({
                uploadFile: '',
                show: true,
                basicType: 'danger',
                basicTitle: 'Please upload file having extensions .csv only.',
            });
        }

    }


    uploadFormHandler = (event) => {
        this.setState({
            loadshow : 'show-m'
        })
        event.preventDefault();
        let csvContent = this.state.csvcontent
        let subject = this.state.subject
        var rparams = {
            email: localStorage.getItem('email'),
            csvcontent: csvContent,
            subject: subject
        }

        redirectURL.post("/dashboard/uploadMonthlyQueryGenerateReport", rparams).then(response => {
            this.setState({
                show: true,
                basicType: 'success',
                basicTitle: response.data.message,
                uploadDivWidth: '0%',
                sliderTranslate: '',
                showDiv: 'show-n',
                uploadFile: '',
                loadshow: 'show-n',
                file: '',
                // rowData : data,
                // columnwithDefs : columns
            })
        })


    }

    onCloseUploadDiv = () => {
        this.setState({
            uploadDivWidth: '0%',
            sliderTranslate: "",
            showDiv: 'show-n',
            uploadFile: '',
            file: '',
            csvmessage: '',
            csverrmessage: '',
        });
        document.getElementById("upform").reset();
    }

    render() {
        return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                {this.state.alert}
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">

                            <div className="card-header">
                                <h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i>
                                    Monthly Query Generation Report
                                    <button type="button" onClick={this.onShowUploadDiv} className="btn btn-success pull-right cs-btn"> <i className="icofont icofont-upload-alt"></i> Upload CSV</button>
                                </h5>
                            </div>
                            <div className="card-body">
                                <div
                                    id="myGrid"
                                    style={{
                                        height: "425px",
                                        // width: "100%"
                                    }}
                                    className={"ag-theme-balham"}
                                >
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={this.state.columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        statusBar={this.state.statusBar}
										sideBar={this.state.sideBar}
                                        groupUseEntireRow={false}
                                        enableRangeSelection={true}
                                        groupRowAggNodes={this.groupRowAggNodes}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        // pinnedBottomRowData={}
                                        rowData={this.state.rowData}
                                        groupHeaderHeight={this.state.groupHeaderHeight}
                                        headerHeight={this.state.headerHeight}
                                        floatingFiltersHeight={this.state.floatingFiltersHeight}
                                        pivotGroupHeaderHeight={this.state.pivotGroupHeaderHeight}
                                        pivotHeaderHeight={this.state.pivotHeaderHeight}
                                        rowHeight={this.state.rowHeight}
                                        // frameworkComponents={this.state.frameworkComponents}
                                        // popupParent={this.state.popupParent}
                                        enableCharts={true}
                                        // onFirstDataRendered={this.onFirstDataRendered}
                                        suppressChartRanges={true}
                                        //defaultGroupSortComparator={this.state.defaultGroupSortComparator}
                                        onRowGroupOpened={this.onRowGroupOpened}
                                    //suppressCount={true}
                                    // onCellClicked={this.onShowLegs}
                                    // detailCellRendererParams={this.state.detailCellRendererParams}
                                    // masterDetail={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"slide-r " + (this.state.sliderTranslate)} >

                    <div className="slide-r-title">
                        <h4>
                            Upload File
                            <span onClick={this.onCloseUploadDiv} className={"btn btn-warning c-btn pull-right " + (this.state.sliderTranslate)}>X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className={"dataLoad " + (this.state.loadshow)}>
                            <div style={{ position: "absolute", left: "38%" }}>
                                <div class="loader-box">
                                    <div class="loader-box">
                                        <div class="rotate dashed colored"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12">
                            <div className={"col-xl-12 col-lg-12 alert alert-light uploadcscmsg " + (this.state.alerterrshow) + " " + (this.state.alerterrmg)}>{this.state.csverrmessage}</div>

                            <form id="upform" className="theme-form" onSubmit={this.uploadFormHandler}>

                                <div className="form-group mt-20p">
                                    <label className="">Upload File</label>
                                    <input type="file" name="uploadFile" id="uploadFile" onChange={this.changeFileHandler} className="form-control" required />
                                    <label className="mt-2">Subject</label>
                                    <input type="text" name="subject" id="subject" onChange={this.changeSubject} className='form-control' required />
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                    <button type="button" onClick={this.resetUpload} className="btn btn-default">Cancel</button>
                                </div>
                                <div className="form-group">
                                    <a className="btn btn-primary" href={require('../../assets/json/monthly_query_report_generation_sample.csv')} target="_blank">Sample Template</a>
                                </div>
                            </form>
                            {/* <div className="form-group mt-50p">
			 					<a href={require('../../assets/json/truck_template.csv')} target="_blank" className="btn btn-info">Download Template</a>
								</div> */ }
                        </div>
                    </div>
                </div>

                <span className="usermanual f22" onClick={this.onClickShowUserManaul}><i className="icofont icofont-read-book-alt"></i></span>
            </div>

        );
    }
}

function alltransporters() {

    /*Truck Transporters*/
    var arr = [];
    redirectURL.post('/masters/truckTransporters')
        .then((response) => {
            // console.log(response.data)
            return response.data;
        });

}

// function dateComparator(date1, date2) {
// 	// console.log("dateComparator");
// 	var date1Number = monthToComparableNumber(date1);
// 	var date2Number = monthToComparableNumber(date2);
// 	console.log(date1,date2);
// 	console.log(date1Number,date2Number);
// 	if (date1Number === null && date2Number === null) {
// 	  return 0;
// 	}
// 	if (date1Number === null) {
// 	  return -1;
// 	}
// 	if (date2Number === null) {
// 	  return 1;
// 	}
// 	return date1Number - date2Number;
//   }
// function monthToComparableNumber(date) {
// 	console.log(date);

// 	if (date === undefined || date === null) {
// 		return null;
// 	}
// 	if(date.search("T") == -1 )
// 	{
// 		if(date.length !== 19)
// 		{
// 			return null;
// 		}

// 	}

// 	var yearNumber = date.substring(0, 4);
// 	var monthNumber = date.substring(5,7);
// 	var dayNumber = date.substring(8,10);
// 	// console.log(yearNumber,monthNumber,dayNumber);
// 	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
// 	return result;
// }
function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}
