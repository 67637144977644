import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
//import Grid from '../layouts/gridnosidebarComponent';

import CustomLoadingCellRenderer from "../layouts/customLoadingCellRenderer";
import ConsignmentActions from './routemapComponent';
import ConsignmentNoActions from './routemapNoComponent';
import ExportsHighSeasAction from './exportshighseasaction';
import ConsignmentImportsNoEventsAction from './consignmentimportsnoEventAction';

import ExportsPortAction from './exportsinportaction';
import ExportsIcdToPortAction from './exportsicdtoportaction';
import ExportsIcdToMsilAction from './exportsicdtomsilaction';
import ExportsMsilToIcdAction from './exportsmsiltoicdaction';
import ExportsInIcdAction from './exportsinicdaction';
import ImportsEmptyContainerAction from './importsemptycontaineraction';
//import Counters from '../layouts/consignmentimportscountersComponent';
import ExportsConsignmentsCounters from '../layouts/exportsdeliveredconsignmentscountersComponent';
import 'react-vertical-timeline-component/style.min.css';
import { getDDMMYYYY, getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY } from '../common/utils'; 
import DrawMap from '../common/drawmap';
import Breadcrumb from '../common/breadcrumb.component';

import 'react-datetime/css/react-datetime.css';
 
var redirectURL = require('../redirectURL');

var infoBox = require('../common/google-infowindow');

var googleAnalytics = require("../common/googleanalytics");


export default class ExportsPRTDeliveredConsignments extends Component {

	constructor(props){
		super(props);
		this.state={
			pageTitle: "Container Exports (Spare Parts)",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			
			totalConsignmentsCount: 0,

			inTransitToMsilData: null,
			inTransitToMsilCount: 0,

			inTransitToIcdData: null,
			inTransitToIcdCount:0,

			insideIcdData: null,
			insideIcdCount: 0,

			inTransitToPortData: null,
			inTransitToPortCount: 0,

			highSeasData: null,
			highSeasCount: 0,

			insidePortData: null,
			insidePortCount: 0,
			consignmentStatus: null,
			statusTexts: [],

			uploadDivWidth:'0%',
			sliderTranslate:'',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			timelineinfo:'',
			consignment_code:'',
			mapinfo:'',
			loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			alerterrshow:'fade',
			overly:'show-n',
			alert:null,
            show: false,
            basicTitle:'',
			basicType:"default",
			modules: AllModules,
			filterstatess:{},
      	    columnsdatas:{},
      	    rowgroupdcols:[],
      	    valuecolumns:[],
      	    pivotmodeis:false,
			pivotcols:[],
			defTransitCoords:'',
			allRowData:0,
			highseascount:0,
			inportcount:0,
			

			inicdcount:0,
			transitmsilcount:0,
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true,
				

      	    },
      	    rowData: null,
			headerHeight: 40,
			groupHeaderHeight:60,
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
			  frameworkComponents: {
                customLoadingCellRenderer: CustomLoadingCellRenderer,
				consignmentActions:ConsignmentActions,
				consignmentNoActions:ConsignmentNoActions,
				exportsHighSeasAction: ExportsHighSeasAction,
				ConsignmentImportsNoEventsAction:ConsignmentImportsNoEventsAction,
				exportsPortAction:ExportsPortAction,
				exportsIcdToPortAction:ExportsIcdToPortAction,
				exportsIcdToMsilAction:ExportsIcdToMsilAction,
				exportsMsilToIcdAction:ExportsMsilToIcdAction,
				exportsInIcdAction:ExportsInIcdAction,
				importsEmptyContainerAction:ImportsEmptyContainerAction,
			},

      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,
				
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:100,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			rowClassRules: {
				"highlitRow": "data.transit_delay == 1",
				"cellStyleGrid":"columnApi.columnController.columnDefs[0].headerName == 'High Sea Details'",
				//"d-none": "data.last_status == 'Empty Returned'"
			},
			podlist:[],
			pod:'',
			startDate:'',
			endDate:'',
			criteria:'',
			errStyCriteria:'',
			errStyPod:'',
			errStyPol:'',
			errStyStartDate:'',
			errStyEndDate:'',
			pollist:[],
			pol:''
				
			
		}
	
		this.onShowRouteMap = this.onShowRouteMap.bind(this);
		this.compare_dates = this.compare_dates.bind(this);
		
	}

	getStatusText(status) {
		let consignmentStatus = null;
		switch(status) {
			case 1:
				//console.log("CASE 1 = ", status);
				consignmentStatus = this.state.statusTexts[status];
				break;
			case 3:
				consignmentStatus = this.state.statusTexts[status];
				break;
			case 4:
				consignmentStatus = this.state.statusTexts[status];
				break;
			case 5:
				consignmentStatus = this.state.statusTexts[status];
				break;
			case 6:
				consignmentStatus = this.state.statusTexts[status];
				break;
			case 7:
				consignmentStatus = this.state.statusTexts[status];
				break;
			case 8:
				consignmentStatus = this.state.statusTexts[status];
				break;
			default:
				console.log("CASE Default = ", status);
				consignmentStatus = "All"
				break;
		}

		//console.log("CASE consignmentStatus = ", consignmentStatus);
		
		return consignmentStatus;
	}
	componentDidMount(){
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
		

		let statusTexts = {
			"1": "ICD to MSIL",
			"3": "MSIL to ICD",
			"4": "Inside ICD",
			"5": "ICD to Port",
			"6": "Inside Port",
			"7": "High Seas",
			"8": "Delivered",
		}
		this.setState({
			statusTexts:statusTexts,
		});
		 /*Consignments List*/	

		 var urlpath;
		
		 if(this.props.match.path == "/exports/deliveredconsignments")
		 {
			 urlpath = '/exports/deliveredconsignments';
		 }
		 if(this.props.match.path == "/exports/prtdeliveredconsignments")
		 {
			 urlpath = '/exports/prtdeliveredconsignments';
			 
		 }
		//urlpath = '/exports/deliveredconsignments';
		
		
		redirectURL.post(urlpath)
		    .then((response) => {
				var records = response.data;
				records.map(rec => {
					if (rec.icd_source) {
						rec.icd_source = rec.icd_source.toUpperCase();
					}  
					if (rec.status) {
						rec.statusText = this.getStatusText(rec.status);
						//console.log("IN  rec.statusText = ",rec.statusText);
					}  
				})
				//console.log("IN EXPORTS , records = ", records, records.length);
				this.setState({
					rowData:records,
					allRowData:records,
				});

				// calculate counters
				let totalConsignmentsCount  = 0;

				let inTransitToMsilData  = null;
				let inTransitToMsilCount  = 0;

				let inTransitToIcdData  = null;
				let inTransitToIcdCount  = 0;

				let insideIcdData  = null;
				let insideIcdCount  = 0;

				let inTransitToPortData  = null;
				let inTransitToPortCount  = 0;

				let insidePortData  = null;
				let insidePortCount  = 0;

				let highSeasData  = null;
				let highSeasCount  = 0;

				totalConsignmentsCount = (records) ? records.length : 0;

				inTransitToMsilData = records.filter(rec => rec.status == 1);
				inTransitToMsilCount = (inTransitToMsilData) ? inTransitToMsilData.length : 0;

				inTransitToIcdData = records.filter(rec => rec.status == 3);
				inTransitToIcdCount = (inTransitToIcdData) ? inTransitToIcdData.length : 0;

				insideIcdData = records.filter(rec => rec.status == 4);
				insideIcdCount = (insideIcdData) ? insideIcdData.length : 0;

				inTransitToPortData = records.filter(rec => rec.status == 5);
				inTransitToPortCount = (inTransitToPortData) ? inTransitToPortData.length : 0;

				insidePortData = records.filter(rec => rec.status == 6);
				insidePortCount = (insidePortData) ? insidePortData.length : 0;

				highSeasData = records.filter(rec => rec.status == 7);
				highSeasCount = (highSeasData) ? highSeasData.length : 0;

				//console.log("inTransitToMsilData = ", inTransitToMsilData, inTransitToMsilCount);

				this.setState({
					totalConsignmentsCount: totalConsignmentsCount,

					inTransitToMsilData: inTransitToMsilData,
					inTransitToMsilCount: inTransitToMsilCount,

					inTransitToIcdData: inTransitToIcdData,
					inTransitToIcdCount: inTransitToIcdCount,
		
					insideIcdData: insideIcdData,
					insideIcdCount: insideIcdCount,
		
					inTransitToPortData: inTransitToPortData,
					inTransitToPortCount: inTransitToPortCount,
		
					insidePortData: insidePortData,
					insidePortCount: insidePortCount,

					highSeasData: highSeasData,
					highSeasCount: highSeasCount,
				});

				//console.log("This state = ", this.state);


			})
		    .catch(function(error) {
		    	console.log(error);
			});
			
	   
	};
	
	


	
	onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
	//    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   event.data.chartService.rangeController.cellRanges;
		   
	//    };
	   
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
	}

	renderMap = () => {    	
		//loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyBQUmk7hdajZEAYllxKhVPnUx5pXuK-j_Y&libraries=places,drawing&callback=initMap");
		//window.initMap = this.initMap
	}
	
	initMap = () => {
    	//console.log(this.state.allmarkers);
    	var currentwindow;
    	var jsondata = this.state.mapinfo;
    	var locations = jsondata.points;
        var arr = jsondata.route;
		//console.log("Here",arr);
		var lt;
		var ln
		if(arr.length > 0)
		{
			lt=arr[0].lat;
			ln=arr[0].lng;
		}
		else{
			//console.log("defTransitCoords ", JSON.parse(this.state.defTransitCoords));
			lt=JSON.parse(this.state.defTransitCoords).lat;
			ln=JSON.parse(this.state.defTransitCoords).lng;
		}
    	 var mapOptions = {
    			zoom: 7,
				zoomControl: true,
				mapTypeControl: true,
				scaleControl: true,
				streetViewControl: true,
				rotateControl: true,
				fullscreenControl: true,
    	          labels:true,
    	          mapTypeControlOptions: {
    	            mapTypeIds: ['hybrid', 'roadmap'],
    	          },
    	          center: new window.google.maps.LatLng(lt,ln),
    	          mapTypeId: window.google.maps.MapTypeId.ROADMAP
    	        };
    	        
    	        var map = new window.google.maps.Map(document.getElementById('map'),
    	            mapOptions);

				var bounds = new window.google.maps.LatLngBounds();
				bounds.extend(new window.google.maps.LatLng(lt,ln));
    			 // Create our info window content
    			//var currentinfowindow = null;
    	       var line=new window.google.maps.Polyline(
    	              {
    	                map:map,
    									strokeColor: '#157254',
    									strokeOpacity: 1.0,
    									strokeWeight: 2.5,
    	                		icons: [{
    	                          icon: {
    	                                  path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    	                                  strokeColor:'#ff8c52',
    	                                  fillColor:'#ff8c52',
    	                                  fillOpacity:1,
    									  strokeWeight: 2
    	                                },
    	                          repeat:'100px',
    	                          path:[]
    	                       }]
    	                 });

			if(arr.length > 0)
			{	
    		  for (let i = 0; i < arr.length; i++) {
    				var path=line.getPath().getArray();
    					
    						let latLng=new window.google.maps.LatLng(arr[i].lat,arr[i].lng);
    						path.push(latLng);
    	          			line.setPath(path);
    					

    				  
    		  
    	          //Change line color based on map type
    	        window.google.maps.event.addListener( map, 'maptypeid_changed', function() {
    	            if (map.getMapTypeId() =='hybrid' || map.getMapTypeId() =='satellite')
    	            {
    	                var line=new window.google.maps.Polyline(
    	                  {
    	                    map:map,
    	                    strokeColor: '#FFFFFF',
    	                    strokeOpacity: 1.0,
    	                    strokeWeight: 2.5,
    	                    icons: [{
    	                              icon: {
    	                                      path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    	                                      strokeColor:'#ff8c52',
    	                                      fillColor:'#ff8c52',
    	                                      fillOpacity:1,
    	                                      strokeWeight: 2
    	                                    },
    	                              repeat:'100px',
    	                              path:[]
    	                           }]
    	                     });                
    	            }
    	            else
    	            {
    	                var line=new window.google.maps.Polyline(
    	                  {
    	                    map:map,
    	                    strokeColor: '#157254',
    	                    strokeOpacity: 1.0,
    	                    strokeWeight: 2.5,
    	                    icons: [{
    	                              icon: {
    	                                      path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    	                                      strokeColor:'#ff8c52',
    	                                      fillColor:'#ff8c52',
    	                                      fillOpacity:1,
    	                                      strokeWeight: 2
    	                                    },
    	                              repeat:'100px',
    	                              path:[]
    	                           }]
    	                     });

    	            }

    	              /*for (i = 0; i < arr.length; i++) {
    	                    var path=line.getPath().getArray();
    	                    let latLng=new window.google.maps.LatLng(arr[i].lat,arr[i].lng);
    						path.push(latLng);
    	          			line.setPath(path);
    	                            //map.setCenter(latLng);
						  }
						*/
    	                            
    	             var x = map.getZoom();
    	             var c = map.getCenter();
    	             window.google.maps.event.trigger(map, 'resize');
    	             map.setZoom(x);
					 map.setCenter(c);
					 
					 var path=line.getPath().getArray();
					let latLng=new window.google.maps.LatLng(arr[i].lat,arr[i].lng);
					path.push(latLng);
					line.setPath(path);
				} );
				
				bounds.extend(new window.google.maps.LatLng(arr[i].lat,arr[i].lng));
    		  }

		  var infowindow = new window.google.maps.InfoWindow();
			var marker, l;
			  //console.log("locations.length ", locations.length)
			  var lastorigin='';
			if(locations.length > 0)
			{
				for (l = 0; l < locations.length; l++) {
					if(arr[0].lat != locations[l].coordinates.lat && arr[0].lng != locations[l].coordinates.lmg)
					{
						marker = new window.google.maps.Marker({
						position: new window.google.maps.LatLng(locations[l].coordinates.lat, locations[l].coordinates.lng),
						icon:require('../../assets/icons/boat.png'),
						map: map,
						});

						window.google.maps.event.addListener(marker, 'click', (function(marker, l) {
							return function() {

								var contentarr = []
								var headerTitle = locations[l].name+", "+locations[l].country;
								//var contentString = "<p class='maptitle'>"+"<B> "+locations[l].name+", "+locations[l].country+"</B></p>";
								var contentStringData='';
								if(locations[l].events.length > 0)
								{
									contentStringData = contentStringData+"<table class='mt-8p table table-border' style='background:#f5f5f5;border:1px solid #dee2e6'>";
									contentStringData = contentStringData + "<thead><tr><th>Date</th><th>Event</th><th>Transport Mode</th></tr></thead><tbody>";
									for(var e=0;e<locations[l].events.length;e++)
									{
										var typemode;
										if(locations[l].events[e].type == 1){
											typemode='Rail/Truck';
										}
										if(locations[l].events[e].type == 2){
											typemode='Air';
										}
										if(locations[l].events[e].type == 3){
											typemode='Sea';
										}
										if(locations[l].events[e].type == 4){
											typemode='Rail/Truck';
										}
										var d = locations[l].events[e].event_datetime_ist;
										var dat = d.split(" ");				
										
										contentStringData = contentStringData + "<tr><td class='custb'><span class='tbmap'><i class='icofont icofont-disc'></i> <span class='tdmapsp'>"+ dat[0];
										contentStringData = contentStringData + "</span></td><td>" + locations[l].events[e].description + "</span></td><td style='text-align: right'>" + typemode + "</span></td></tr>";
										
				
									}
									contentStringData = contentStringData+"</tbody></table>";
									
								}
								contentarr.push(contentStringData);
							//lastorigin=contentString;
							//console.log("1", headerTitle)
							var contentString = infoBox(marker.icon, headerTitle, contentarr, 'table')
				

							infowindow.setContent(contentString);
							currentwindow = infowindow;
							infowindow.open(map, marker);
							//console.log(marker.position.toJSON());
							//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
							}
						})(marker, l));
					}
					else{
						marker = new window.google.maps.Marker({
							position: new window.google.maps.LatLng(locations[l].coordinates.lat, locations[l].coordinates.lng),
							icon:require('../../assets/icons/start_port.png'),
							map: map,
							});
	
							window.google.maps.event.addListener(marker, 'click', (function(marker, l) {
								return function() {
									//var contentString = "<p class='maptitle'>"+"<B> "+locations[l].name+", "+locations[l].country+"</B></p>";
									var contentarr = []
									var headerTitle = locations[l].name+", "+locations[l].country;
									var contentStringData='';
									if(locations[l].events.length > 0)
									{
										contentStringData = contentStringData+"<table class='mt-8p table table-border' style='background:#f5f5f5;border:1px solid #dee2e6'>";
										contentStringData = contentStringData + "<thead><tr><th>Date</th><th>Event</th><th>Transport Mode</th></tr></thead><tbody>";
										for(var e=0;e<locations[l].events.length;e++)
										{
											var typemode;
											if(locations[l].events[e].type == 1){
												typemode='Rail/Truck';
											}
											if(locations[l].events[e].type == 2){
												typemode='Air';
											}
											if(locations[l].events[e].type == 3){
												typemode='Sea';
											}
											if(locations[l].events[e].type == 4){
												typemode='Rail/Truck';
											}
											var d = locations[l].events[e].event_datetime_ist;
											var dat = d.split(" ");											
											
											contentStringData = contentStringData + "<tr><td class='custb'><span class='tbmap'><i class='icofont icofont-disc'></i> <span class='tdmapsp'>"+ dat[0];
											contentStringData = contentStringData + "</span></td><td>" + locations[l].events[e].description + "</span></td><td style='text-align: right'>" + typemode + "</span></td></tr>";
	
										}
										contentStringData = contentStringData+"</tbody></table>";
										
									}
									contentarr.push(contentStringData);	
									//console.log("2", headerTitle)
									var contentString = infoBox(marker.icon, headerTitle, contentarr, 'table')
					
								infowindow.setContent(contentString);
								currentwindow = infowindow;
								infowindow.open(map, marker);
								//console.log(marker.position.toJSON());
								//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
								}
							})(marker, l));
					}
				}
			}
			if (jsondata.hasOwnProperty('last_known_location') == true && jsondata.last_known_location != null)
			{
		   	    marker = new window.google.maps.Marker({
					position: new window.google.maps.LatLng(jsondata.last_known_location.lat, jsondata.last_known_location.lng),
					icon:require('../../assets/icons/crane.png'),
					map: map,	
				 
			  });
			

		   window.google.maps.event.addListener(marker, 'click', (function(marker) {
		        return function() {
		        	//console.log("jsondata", jsondata);
					var contentarr = []
					var headerTitle = "Last known location";
					var contentStringData='';
					var typemode;
					if(jsondata.last_event.type == 1){
						typemode='Rail/Truck';
					}
					if(jsondata.last_event.type == 2){
						typemode='Air';
					}
					if(jsondata.last_event.type == 3){
						typemode='Sea';
					}
					if(jsondata.last_event.type == 4){
						typemode='Rail/Truck';
					}
					var d = jsondata.last_event.event_datetime_ist;
					//var dat = d.split(" ");
					
					contentStringData = contentStringData+"<table class='mt-8p table table-border' style='background:#f5f5f5;border:1px solid #dee2e6'>";
					contentStringData = contentStringData + "<thead><tr><th>Last Updated Date</th><th>Transport Mode</th></tr></thead><tbody>";
					
					contentStringData = contentStringData + "<tr><td class='custb'><span class='tbmap'><i class='icofont icofont-disc'></i> <span class='tdmapsp'>"+ jsondata.last_known_time;
					contentStringData = contentStringData + "</span></td><td style='text-align: right'>" + typemode + "</td></tr>";
					contentStringData = contentStringData+"</tbody></table>";	
					
					contentarr.push(contentStringData);	
					var contentString = infoBox(marker.icon, headerTitle, contentarr, 'table')
					

				  infowindow.setContent(contentString);
				  currentwindow = infowindow;
				  infowindow.open(map, marker);
				 // console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
				}
			})(marker));
			}
		}
		map.fitBounds(bounds);

    }



	
	onShowRouteMap(rownode){
		//console.log("rownode = ", rownode);
		if (googleAnalytics.page.enableGA) {
			let eventOptions = {
				"category": this.state.pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		

		if(rownode != 0)
		{
			var params = { 
				//container_no:"FSCU9842333"
				container_no:rownode.container_no
			}
			this.setState({
				loadshow:'show-m'
			})
			 redirectURL.post('/imports/exportssearates',params)
			  .then((response) => {
			  
			   var records = JSON.parse(response.data.body);
				console.log("Here /imports/exportssearates, records.status = ",records.status);
				if(records.status == 'Failure')
				{
					this.setState({
						show: true, 
						basicType:'danger', 
						basicTitle:"No data found",
							
					});
				}
				else{
					console.log(records);
					this.setState({
						mapinfo:records,
						sliderRouteTranslate:"slider-translate-75p",
						consignment_code:rownode.container_no,
						show: false,
						overly:'show-m'
					});
					//this.renderMap();
					
				}
				this.setState({
					loadshow:'show-n',
				})
			  /* this.setState({
					mapinfo:response.data,
					sliderRouteTranslate:"slider-translate-50p",
					consignment_code:rownode.truck_no
			   });
				   this.renderMap();
			
				*/
			  })
			  .catch(function (error) {
				console.log(error);
			  });
			  
		}
		
	}
	onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderRouteTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n'
		});
	}
	closeAlert = () => {
        this.setState({
			show: false,
			overly:'show-n'
        });
	}
	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:''
		});
		
	}
	compare_dates = (date1,date2) => {
		if (date1>date2) return 1; 
	 }

	onClickCounterShowData_OLD(params){
		console.log("Params Here ", params);

		if(params == 0)
		{
			this.setState({
				rowData:this.state.allRowData
			});
		}
		if(params == 1)
		{
			this.setState({
				rowData:this.state.highseascount
			});
		}
		if(params == 2)
		{
			this.setState({
				rowData:this.state.inportcount
			});
		}
		if(params == 3)
		{
			this.setState({
				rowData:this.state.inTransitToIcdCount
			});
		}
		if(params == 4)
		{
			this.setState({
				rowData:this.state.inicdcount
			});
		}
		if(params == 5)
		{
			this.setState({
				rowData:this.state.transitmsilcount
			});
		}
	}
	
	logCounterClickGA(params) {
		if (googleAnalytics.page.enableGA) {
			let statusText = this.getStatusText(params);
			let eventOptions = {
				"category": this.state.pageTitle,
				"action": this.state.eventCounterAction,
				"label": statusText,
			}
			googleAnalytics.logEvent(eventOptions);
		}
	}
	onClickCounterShowData(params){
		console.log("Counter Params Here ", params);
		this.logCounterClickGA(params); // Log Counter Actions for Google Analytics
		let selectedFilterData = null;
		switch(params) {
			case 1:
				selectedFilterData = this.state.inTransitToMsilData;
				//console.log("Case 1 params,", params, selectedFilterData);
				break;
			case 3:
				selectedFilterData = this.state.inTransitToIcdData;
				break;
			case 4:
				selectedFilterData = this.state.insideIcdData;
				break;
			case 5:
				selectedFilterData = this.state.inTransitToPortData;
				break;
			case 6:
				selectedFilterData = this.state.insidePortData;
				break;
			case 7:
				selectedFilterData = this.state.highSeasData;
				break;
			default:
				selectedFilterData = this.state.allRowData;
				break;
		}
		this.setState({
			rowData: selectedFilterData
		});

	}



	getPolListItems = pol =>{
        this.setState(
	      { pol },
	      () => console.log(this.state.pol)
        );
        
        this.setState({
			errorMessage:'',
			successMessage:''
        })
	}


	selectPolOptionsItems(){
        let items = [];     
		//items.push({value:'all',label:'All'}); 
		this.state.pollist.forEach(item =>{
			//console.log("pod ",item)
			items.push({value:item,label:item}); 
		}); 
        return items;
	}


	getPodListItems = pod =>{
        this.setState(
	      { pod },
	      () => console.log(this.state.pod)
        );
        
        this.setState({
			errorMessage:'',
			successMessage:''
        })
	}


	selectPodOptionsItems(){
        let items = [];     
		//items.push({value:'all',label:'All'}); 
		this.state.podlist.forEach(item =>{
			//console.log("pod ",item)
			items.push({value:item,label:item}); 
		});
        //items.push({value:"DEENDAYAL",label:"DEENDAYAL"}); 
        return items;
	}

	getCriteriaListItems = criteria =>{
        this.setState(
	      { criteria },
	      () => console.log(this.state.criteria)
        );
        
        this.setState({
			errorMessage:'',
			successMessage:''
        })
	}


	selectCriteriaOptionsItems(){
        let items = [
			//{value:'all',label:'All'},
			{value:'leo_date',label:'Customs Cleared On'},
			{value:'handover_date',label:'Handover Date'},
			{value:'port_entry_date',label:'Port Entry On'},
			{value:'pol_atd',label:'Port Out On'},
			{value:'pod_ata',label:'Delivered On'}
			
		];      
		
        
        return items;
	}
	
	
	handlerStartDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
    	this.setState({
    		startDate:startdate
    	});
    	//let value = event.target.value;
        //this.setState({'startDate':value});
    }

    handlerEndDateTime  = (event) => {
    	var d = new Date(event._d);
    	
        var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate())
        this.setState({
    		endDate:edate
    	});
	}

	onClickGetData = (event) =>{
		console.log("Event ",event)
	}
	render(){
		const columnwithDefs = [
			
		//	{
				// headerName: "",
				// headerClass:["cellstylegrid","cellTopHeader"],			
				//children: [
					{
						headerName: "Route",
						//headerClass:"cellstylegrid",
						field: "status",
						width: 40,
						pinned: 'left',
						
						cellRendererSelector:function(params){
							var map_available = 0

							if(params.data.hasOwnProperty('status'))
							{
								if (params.data.status >= 5)
								{
									map_available = 1
									//return { component: 'consignmentActions'}
								}
							}
							if(params.data.hasOwnProperty('icd_gate_out'))
							{
								map_available =1
							}

							//else{
								//return { component: 'consignmentNoActions'}
							//}
							if (map_available == 1)
							{
								return { component: 'consignmentActions'}
							}
							else{
								return { component: 'consignmentNoActions'}
							}

						},
						filter: false,resizable: true,
						// cellStyle:function(params){
						// 	return {background:"rgb(241, 251, 255)",color:"#333"}
						// }
						
						//cellClass: function(params) { return 'cellStyleGrid'; }
					},
					{
						headerName: "",
						//headerClass:"cellstylegrid",
						field: "msil_transit_delay",
						width: 40,
						pinned: 'left',
						cellRendererSelector:function(params){
							return { component: 'exportsIcdToMsilAction'};
						},
						filter: true,resizable: true,
						// cellStyle:function(params){
						// 	return {background:"rgb(241, 251, 255)",color:"#333"}
						// }
					},
					{
						headerName: "",
						//headerClass:"cellstylegrid",
						field: "msil_to_icd_transit_delay",
						width: 40,
						pinned: 'left',
						cellRendererSelector:function(params){
							return { component: 'exportsMsilToIcdAction'};
						},
						filter: true,resizable: true,
						// cellStyle:function(params){
						// 	return {background:"rgb(241, 251, 255)",color:"#333"}
						// }
					},
					{
						headerName: "",
						//headerClass:"cellstylegrid",
						field: "icd_delay",
						width: 40,
						pinned: 'left',
						cellRendererSelector:function(params){
							return { component: 'exportsInIcdAction'};
						},
						filter: true,resizable: true,
						// cellStyle:function(params){
						// 	return {background:"rgb(241, 251, 255)",color:"#333"}
						// }
					},
					{
						headerName: "",
						//headerClass:"cellstylegrid",
						field: "rail_transit_delay",
						width: 40,
						pinned: 'left',
						cellRendererSelector:function(params){
							return { component: 'exportsIcdToPortAction'};
						},
						filter: true,resizable: true,
						// cellStyle:function(params){
						// 	return {background:"rgb(241, 251, 255)",color:"#333"}
						// }
					},
					{
						headerName: "",
						//headerClass:"cellstylegrid",
						field: "port_delay",
						width: 40,
						pinned: 'left',
						cellRendererSelector:function(params){
							return { component: 'exportsPortAction'};
						},
						filter: true,resizable: true,
						// cellStyle:function(params){
						// 	return {background:"rgb(241, 251, 255)",color:"#333"}
						// }
					},
					{
						headerName: "",
						//headerClass:"cellstylegrid",
						field: "sea_transit_delay",
						width: 40,
						pinned: 'left',
						cellRendererSelector:function(params){
						
							return { component: 'exportsHighSeasAction'};
							
						},
						
						filter: true,resizable: true,
						// cellStyle:function(params){
						// 	return {background:"rgb(241, 251, 255)",color:"#333"}
						// }
					},
					{
						headerName: "Container No",
						//headerClass:"cellstylegrid",
						field: "container_no",
						width: 120,
						pinned: 'left',
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						//cellClass:'cellstylegrid'
					},
					{
						headerName: "Status",
						//headerClass:"cellstylegrid",
						field: "statusText",
						width: 120,
						pinned: 'left',
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						//cellClass:'cellstylegrid'
					},
					{ 
						headerName: "Booking Id",
						//headerClass:"cellstylegrid",
						field: "booking_id",
						width: 120,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						
					},
					{
						headerName: "Shipping Line",
						field: "shipping_line",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						
					},
					{
						headerName: "Plant Name",
						field: "plant_name",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						
					},
					{
						headerName: "ICD Name (source)",
						//headerClass:"cellstylegrid",
						field: "icd_source",
						width: 120,
						editable:false,
						filter: "agSetColumnFilter",
						resizable: true,
						
					},
					{
						headerName: "Container Size",
						//headerClass:"cellstylegrid",
						field: "container_size",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						
					},
					{
						headerName: "Cargo Type",
						//headerClass:"cellstylegrid",
						field: "cargo_type",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						
					},
					{
						headerName: "Trailer No",
						field: "trailer_no",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						
					},
					{
						headerName: "Invoice Date",
						field: "invoice_date",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						
					},
					// {
					// 	headerName: "Empty Container to MSIL",
					// 	field: "empty_container_to_msil",
					// 	width: 150,
					// 	editable:false,
					// 	filter: "agSetColumnFilter",resizable: true,
						
					// },
					{
						headerName: "MSIL Gate In",
						field: "msil_gate_in",
						width: 150,
						editable:false,
						resizable: true,
						valueGetter:function(params){
							return getHyphenDDMMMYYYY(params.data.msil_gate_in);
						},
						//filter: "agDateColumnFilter",
						comparator: dateComparator1,
						// filterParams: {
						// browserDatePicker: true,
						// filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
						// 	// provide comparator function
						// 	comparator: function(filteredDate,cellValue,secondDate) {
						// 		cellValue = cellValue.replace(/\//g,"-")
						// 		if(cellValue != "" && cellValue != " "){
						// 			cellValue = cellValue.split(" ")[0].split("-");
						// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
						// 			var cellDate = new Date(cellValue);
						// 			if(filteredDate.getMonth() < 10){
						// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			else{
						// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			var filteredDate = new Date(filterValue);
						// 			cellDate = cellDate.getTime();
						// 			filteredDate = filteredDate.getTime();
						// 			// console.log(cellDate,filteredDate);
						// 			// console.log(cellValue,filterValue);
						// 			if(cellDate === filteredDate)
						// 			{
						// 				return 0;
						// 			}
						// 			if(cellDate < filteredDate){
						// 				return -1;
						// 			}
						// 			if(cellDate > filteredDate)
						// 			{
						// 				return 1;
						// 			}
						// 		}
						// 	}
						// },
					},
					{
						headerName: "MSIL Gate Out",
						field: "msil_gate_out",
						width: 150,
						editable:false,
						resizable: true,
						valueGetter:function(params){
							return getHyphenDDMMMYYYY(params.data.msil_gate_out);
						},
						//filter: "agDateColumnFilter",
						comparator: dateComparator1,
						// filterParams: {
						// browserDatePicker: true,
						// filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
						// 	// provide comparator function
						// 	comparator: function(filteredDate,cellValue,secondDate) {
						// 		cellValue = cellValue.replace(/\//g,"-")
						// 		if(cellValue != "" && cellValue != " "){
						// 			cellValue = cellValue.split(" ")[0].split("-");
						// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
						// 			var cellDate = new Date(cellValue);
						// 			if(filteredDate.getMonth() < 10){
						// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			else{
						// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			var filteredDate = new Date(filterValue);
						// 			cellDate = cellDate.getTime();
						// 			filteredDate = filteredDate.getTime();
						// 			// console.log(cellDate,filteredDate);
						// 			// console.log(cellValue,filterValue);
						// 			if(cellDate === filteredDate)
						// 			{
						// 				return 0;
						// 			}
						// 			if(cellDate < filteredDate){
						// 				return -1;
						// 			}
						// 			if(cellDate > filteredDate)
						// 			{
						// 				return 1;
						// 			}
						// 		}
						// 	}
						// },
					},
					{
						headerName: "ICD Arrival Date",
						field: "icd_arrival_date",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
						
					},
					{
						headerName: "Customs Cleared On",
						field: "leo_date",
						width: 150,
						editable:false,
						resizable: true,
						//filter: "agDateColumnFilter",
						valueGetter:function(params){
							return getHyphenDDMMMYYYY(params.data.leo_date);
						},
						comparator: dateComparator1,
						// filterParams: {
						// browserDatePicker: true,
						// filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
						// 	// provide comparator function
						// 	comparator: function(filteredDate,cellValue,secondDate) {
						// 		cellValue = cellValue.replace(/\//g,"-")
						// 		if(cellValue != "" && cellValue != " "){
						// 			cellValue = cellValue.split(" ")[0].split("-");
						// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
						// 			var cellDate = new Date(cellValue);
						// 			if(filteredDate.getMonth() < 10){
						// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			else{
						// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			var filteredDate = new Date(filterValue);
						// 			cellDate = cellDate.getTime();
						// 			filteredDate = filteredDate.getTime();
						// 			// console.log(cellDate,filteredDate);
						// 			// console.log(cellValue,filterValue);
						// 			if(cellDate === filteredDate)
						// 			{
						// 				return 0;
						// 			}
						// 			if(cellDate < filteredDate){
						// 				return -1;
						// 			}
						// 			if(cellDate > filteredDate)
						// 			{
						// 				return 1;
						// 			}
						// 		}
						// 	}
						// },
					},
					{
						headerName: "Handover Date",
						field: "handover_date",
						width: 150,
						editable:false,
						resizable: true,
						//filter: "agDateColumnFilter",
						comparator: dateComparator1,
						valueGetter:function(params){
							return getHyphenDDMMMYYYY(params.data.handover_date);
						},
						// filterParams: {
						// browserDatePicker: true,
						// filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
						// 	// provide comparator function
						// 	comparator: function(filteredDate,cellValue,secondDate) {
						// 		cellValue = cellValue.replace(/\//g,"-")
						// 		if(cellValue != "" && cellValue != " "){
						// 			cellValue = cellValue.split(" ")[0].split("-");
						// 			cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
						// 			var cellDate = new Date(cellValue);
						// 			if(filteredDate.getMonth() < 10){
						// 			var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			else{
						// 				var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
						// 			}
						// 			var filteredDate = new Date(filterValue);
						// 			cellDate = cellDate.getTime();
						// 			filteredDate = filteredDate.getTime();
						// 			// console.log(cellDate,filteredDate);
						// 			// console.log(cellValue,filterValue);
						// 			if(cellDate === filteredDate)
						// 			{
						// 				return 0;
						// 			}
						// 			if(cellDate < filteredDate){
						// 				return -1;
						// 			}
						// 			if(cellDate > filteredDate)
						// 			{
						// 				return 1;
						// 			}
						// 		}
						// 	}
						// },
					},
					{
						headerName: "Rail Out Date",
						field: "rail_out_date",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
					},
					{
						headerName: "Port of Loading",
						field: "port_of_export",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
					},
					{
						headerName: "Train/Truck No",
						field: "line_no",
						width: 150,
						editable:false,
						filter: "agSetColumnFilter",resizable: true,
					},
					{
						headerName: "Port Entry On",
						field: "port_entry_date",
						width: 150,
						editable:false,
						resizable: true,
						valueGetter:function(params){
							if(params.data.pol_atd != "" && params.data.pol_atd != undefined ){
								return getHyphenDDMMMYYYYHHMM(params.data.port_entry_date);
							}
							else{
								return "";
							}
							
						},
						//filter: "agDateColumnFilter",
						comparator: dateComparator,
						// filterParams: {
						// 	browserDatePicker: true,
						// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
						// 	// provide comparator function
						// 	comparator: function(filteredDate,cellValue,secondDate) {
						// 		cellValue = cellValue.replace(/\//g,"-")
						// 		if(cellValue != "" && cellValue != " "){
						// 			cellValue = cellValue.split(" ")[0].split("-");
						// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
						// 			// console.log(cellValue);
						// 			var cellDate = new Date(cellValue);
						// 			// console.log(cellDate);
						// 			if(filteredDate.getMonth() < 10){
						// 				var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
						// 			}
						// 			else{
						// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
						// 			}
						// 			// console.log(filterValue.split("-")[2]);
						// 			if(Number(filterValue.split("-")[2]) < 10 ){
						// 				filterValue = filterValue.split("-")[0]+"-"+filterValue.split("-")[1]+"-"+"0"+filterValue.split("-")[2];
	
						// 			}
						// 			console.log(filterValue);
						// 			var filteredDate = new Date(filterValue);
						// 			// console.log(filterValue);
						// 			// console.log(filteredDate);
						// 			cellDate = cellDate.getTime();
						// 			filteredDate = filteredDate.getTime();
						// 			console.log(cellDate,filteredDate);
						// 			if(cellDate === filteredDate)
						// 			{
						// 				return 0;
						// 			}
						// 			if(cellDate < filteredDate){
						// 				return -1;
						// 			}
						// 			if(cellDate > filteredDate)
						// 			{
						// 				return 1;
						// 			}
						// 		}
						// 	}
						// },
					},
					{
						headerName: "Port Out On",
						field: "pol_atd",
						width: 150,
						editable:false,
						resizable: true,
						//filter: "agDateColumnFilter",
						comparator: dateComparator,
						
						valueGetter:function(params){
							if(params.data.pol_atd != "" && params.data.pol_atd != undefined ){
								var delivered = params.data.pol_atd;
								delivered = delivered.split(" ")[0].split("-")[1]+"-"+delivered.split(" ")[0].split("-")[0]+"-"+delivered.split(" ")[0].split("-")[2]+" "+delivered.split(" ")[1];
								return getHyphenDDMMMYYYYHHMM(delivered);
							}
							else{
								return "";
							}
							
						},
						// filterParams: {
						// 	browserDatePicker: true,
						// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
						// 	// provide comparator function
						// 	comparator: function(filteredDate,cellValue,secondDate) {
						// 		cellValue = cellValue.replace(/\//g,"-")
						// 		if(cellValue != "" && cellValue != " "){
						// 			cellValue = cellValue.split(" ")[0].split("-");
						// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
						// 			var cellDate = new Date(cellValue);
						// 			if(filteredDate.getMonth() < 10){
						// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
						// 			}
						// 			else{
						// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
						// 			}
						// 			if(Number(filterValue.split("-")[2]) < 10 ){
						// 				filterValue = filterValue.split("-")[0]+"-"+filterValue.split("-")[1]+"-"+"0"+filterValue.split("-")[2];
						// 			}
						// 			var filteredDate = new Date(filterValue);
						// 			cellDate = cellDate.getTime();
						// 			filteredDate = filteredDate.getTime();
						// 			// console.log(cellDate,filteredDate);
						// 			if(cellDate === filteredDate)
						// 			{
						// 				return 0;
						// 			}
						// 			if(cellDate < filteredDate){
						// 				return -1;
						// 			}
						// 			if(cellDate > filteredDate)
						// 			{
						// 				return 1;
						// 			}
						// 		}
						// 	}
						// },
					},
					{
						headerName: "Delivered On",
						field: "pod_ata",
						width: 150,
						editable:false,
						resizable: true,
						//filter: "agDateColumnFilter",
						comparator: dateComparator,
						valueGetter:function(params){
							if(params.data.pod_ata != "" && params.data.pod_ata != undefined ){
								var delivered = params.data.pod_ata;
								delivered = delivered.split(" ")[0].split("-")[1]+"-"+delivered.split(" ")[0].split("-")[0]+"-"+delivered.split(" ")[0].split("-")[2]+" "+delivered.split(" ")[1];
								return getHyphenDDMMMYYYYHHMM(new Date(delivered));
							}
							else{
								return "";
							}
							
						},
						// filterParams: {
						// 	browserDatePicker: true,
						// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
						// 	// provide comparator function
						// 	comparator: function(filteredDate,cellValue,secondDate) {
						// 		cellValue = cellValue.replace(/\//g,"-")
						// 		if(cellValue != "" && cellValue != " "){
						// 			cellValue = cellValue.split(" ")[0].split("-");
						// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
						// 			var cellDate = new Date(cellValue);
						// 			if(filteredDate.getMonth() < 10){
						// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
						// 			}
						// 			else{
						// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
						// 			}
						// 			if(Number(filterValue.split("-")[2]) < 10 ){
						// 				filterValue = filterValue.split("-")[0]+"-"+filterValue.split("-")[1]+"-"+"0"+filterValue.split("-")[2];
						// 			}
						// 			var filteredDate = new Date(filterValue);
						// 			cellDate = cellDate.getTime();
						// 			filteredDate = filteredDate.getTime();
						// 			// console.log(cellDate,filteredDate);
						// 			if(cellDate === filteredDate)
						// 			{
						// 				return 0;
						// 			}
						// 			if(cellDate < filteredDate){
						// 				return -1;
						// 			}
						// 			if(cellDate > filteredDate)
						// 			{
						// 				return 1;
						// 			}
						// 		}
						// 	}
						// },
					},
				//]
			//},

		];
		
		
		return(
			<div className="container-fluid">
				<Breadcrumb parent="Container" title="Exports" />
			<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
{/* 
				<div className="row col-xl-12 col-lg-12">
					<form method="POST" className="form-theme col-xl-12 col-lg-12 row">
					<div className="form-group col-xl-2 col-lg-2">
						<label>Select POL: </label>
						<Select placeholder={"Select POL"} closeMenuOnSelect={false} value={this.state.pod} isMulti="true" className={"border-radius-0 "+(this.state.errStyPod)} onChange={this.getPodListItems.bind(this)} style={{borderRadius:"0px"}} options={this.selectPodOptionsItems()} />
                                       
					</div>
					<div className="form-group col-xl-2 col-lg-2" style={{borderRight:"1px solid #aaa"}}>
						<label>Select POD: </label>
						<Select placeholder={"Select POD"} closeMenuOnSelect={false} value={this.state.pol} isMulti="true" className={"border-radius-0 "+(this.state.errStyPol)} onChange={this.getPolListItems.bind(this)} style={{borderRadius:"0px"}} options={this.selectPolOptionsItems()} />
                                       
					</div>
					<div className="form-group col-xl-2 col-lg-2">
						<label>Select Criteria: </label>
						<Select placeholder={"Select Criteria"} closeMenuOnSelect={false} value={this.state.criteria} className={"border-radius-0 "+(this.state.errStyCriteria)} onChange={this.getCriteriaListItems.bind(this)} style={{borderRadius:"0px"}} options={this.selectCriteriaOptionsItems()} />
                                       
					</div>
					<div className="col-xl-2 col-lg-2 form-group">
						<label>From Date</label>
						<Datetime className={this.state.errStyStartDate} value={this.state.startDate} disableCloseOnClickOutside={false} closeOnSelect={true} inputProps={{ placeholder: 'From', name: 'startDate', autoComplete:'off', required:true }} dateFormat="YYYY-MM-DD"  name="startDate" onChange={this.handlerStartDateTime.bind(this)} />
					</div>

					<div className="col-xl-2 col-lg-2 form-group">
						<label>To Date</label>
						<Datetime className={this.state.errStyEndDate} value={this.state.endDate} disableCloseOnClickOutside={false} closeOnSelect={true} inputProps={{ placeholder: 'To', name: 'endDate', autoComplete:'off', required:true}} dateFormat="YYYY-MM-DD" onChange={this.handlerEndDateTime.bind(this)} />
					</div>
					
					<div className="form-group col-xl-2 col-lg-2">
						<label>&nbsp;</label><br />
						<button type="button" className="btn btn-success" style={{padding:"5px 10px"}} onClick={this.onClickGetData.bind(this)}>Submit</button>
						<a href="/exports/consignments" className="btn btn-primary" style={{padding:"5px 10px"}}>Reset</a>
					
					</div>
					</form>
				</div> */}

				<ExportsConsignmentsCounters 
					context={this}
					allRowDatacount={this.state.allRowData.length}
					totalConsignmentsCount={this.state.totalConsignmentsCount}
					
				 />
            	<div className="row">					
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pageTitle} </span>
									<span className="float-right f13">
										<i className="icofont icofont-truck-alt f24"></i> - ICD To MSIL &nbsp; &nbsp;
										<i className="icofont icofont-van-alt f25"></i> - MSIL to ICD &nbsp; &nbsp;
										<i className="icofont icofont-ui-home f20"></i> - Inside ICD &nbsp; &nbsp;
										<i className="icofont icofont-train-line f20"></i> - ICD to Port &nbsp; &nbsp;
										<i className="icofont icofont-ship f20"></i> - Inside Port &nbsp; &nbsp;
										<i className="icofont icofont-ship-alt f20"></i> - High Seas &nbsp; &nbsp;
									</span>
								</h5>
				   			</div>				   			
		            		<div className="card-body">
								
								 <div id="myGrid" style={{ height: "580px",width: "100%"}}  className="ag-theme-balham">
		          		          <AgGridReact
		          		            modules={this.state.modules}
		          		            columnDefs={columnwithDefs}
		          		            defaultColDef={this.state.defaultColDef}
		          		            rowData={this.state.rowData}
		          		            enableCharts={true}
		          		            enableRangeSelection={true}
		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
		          		            onGridReady={this.onGridReady}
		          		            onGridState={this.onGridState}
		          		            statusBar={this.state.statusBar}
		          	                sideBar={this.state.sideBar}
									paginationPageSize={this.state.paginationPageSize}
									pagination={true}
									frameworkComponents={this.state.frameworkComponents}
									detailCellRendererParams={this.state.detailCellRendererParams}
									//editType={this.state.editType}
									stopEditingWhenGridLosesFocus={true}
									floatingFilter={false}
									enableCellChangeFlash={true}
									suppressCellFlash={true}
									rowClassRules={this.state.rowClassRules}
									//onCellClicked={this.onRowClicked.bind(this)}
									gridOptions={{
										context: { componentParent: this }
									}}
									masterDetail={true}
									headerHeight={this.state.headerHeight}
									//groupHeaderHeight={this.state.groupHeaderHeight}
		          		          />

									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
									</div>

		          		        </div>
				   			</div>
				   		</div>	
				   	</div>
				 </div>
				
				
				 {this.state.sliderRouteTranslate != ''?
					 <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								Container Routes #{this.state.consignment_code}
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
							
							<div className="row col-xl-12 col-lg-12">
								<img alt="Start Port" src={require('../../assets/icons/start_port.png')} className="mapico2" /> - Starting Point &nbsp;
								<img alt="Change Location" src={require('../../assets/icons/boat.png')} className="mapico1" /> - Port/Change Over location &nbsp; 
								<img alt="Last known location" src={require('../../assets/icons/crane.png')} className="mapico" /> - Last Known location &nbsp;
								
								<DrawMap mapFor={"importsconsignment"} mapinfo={this.state.mapinfo} defTransitCoords={this.state.defTransitCoords} />
									{/*
								<div id="map" className="" style={{width:'100%',height:"90vh",marginTop:"15px"}}></div>
									*/}
							</div>
						</div>
					</div>
				:""	
				}
				
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            
            </div>
              	
		);
    }


    
    onShowConsignmentExportsEventsClick = async (params) => {
		if (googleAnalytics.page.enableGA) {
			let eventLabel = null;
			if (params) {
				let columnFieldName = (params.colDef) ? params.colDef.field : null;
				//console.log("columnFieldName = ", columnFieldName);
				eventLabel = googleAnalytics.page.colDef.exportsConsignments[columnFieldName];
				//console.log("eventLabel = ", eventLabel);
			}
			
			let eventOptions = {
				"category": this.state.pageTitle,
				"action": this.state.eventGridAction,
				"label": eventLabel,
			}
			googleAnalytics.logEvent(eventOptions);
		}


		if(params != 0)
		{
			var eventsData=[];
			// await redirectURL.post('trackBlDeepSea/getcontainertrackingdetails', {
            //     container: params.data.container_no,
            //     sealine: params.data.shipping_line_code,
            //     apiKey: "O1TX-DPHE-WYN6-53MI"
            // })
			// .then(async (response) =>{
			// 	console.log("Step 11 ",response.data)
				
				
			// 	eventsData = await parseEventsData(response.data);
			// 	//params.successCallback(eventsData);
					
			// 	console.log("Step 12 ",response.data)
				
			// });

			//console.log("Step 3 GOING TO SET STATE");
			
			await this.setState({
				//detailCellRenderer: "<div>Test</div>",
				detailCellRendererParams:{
					detailGridOptions: {
						columnDefs: [							
							{ headerName: "Event",field: "description", filter:true,
								cellClass:function(params){
									//console.log("In ", params)
									if(params.data.event_completed == 1 && params.data.leg_delayed == 0)
									{
										return 'green';
									}
									else if(params.data.event_completed == 1 && params.data.leg_delayed == 1)
									{
										return 'green';
									}
									else if(params.data.event_completed == 0 && params.data.leg_delayed == 1)
									{
										return '';
									}
									else{
										return '';
									}
								},resizable: true
							},
							
							{ headerName: "Place",field: "pod", filter:true,
								cellClass:function(params){
									if(params.data.event_completed == 1 && params.data.leg_delayed == 0)
									{
										return 'green';
									}
									else if(params.data.event_completed == 1 && params.data.leg_delayed == 1)
									{
										return 'green';
									}
									else if(params.data.event_completed == 0 && params.data.leg_delayed == 1)
									{
										return '';
									}
									else{
										return '';
									}
								},
								valueGetter:function(params){
									//	return getDDMMYYYY(params.data.initial_event_date);
									//(params.data.initial_event_date) 
									return (params.data.pod) ? params.data.pod.toUpperCase() : "";
								},resizable: true
							 },	
							{ 
								headerName: "Initial Estimated Date", field: "initial_event_date", filter:true ,
								cellClass:function(params){
									if(params.data.event_completed == 1 && params.data.leg_delayed == 0)
									{
										return 'green';
									}
									else if(params.data.event_completed == 1 && params.data.leg_delayed == 1)
									{
										return 'green';
									}
									else if(params.data.event_completed == 0 && params.data.leg_delayed == 1)
									{
										return '';
									}
									else{
										return '';
									}
								},
								valueGetter:function(params){
									return getDDMMYYYY(params.data.initial_event_date);

									//return (params.data.initial_event_date) ?
									//moment.parseZone(params.data.initial_event_date.substring(0,10)).format('DD-MM-YYYY') : "";
								},resizable: true,
								
							},
							{ 
								headerName: "Previous Revised Date", field: "previous_event_date", filter:true ,
								cellClass:function(params){
									if(params.data.event_completed == 1 && params.data.leg_delayed == 0)
									{
										return 'green';
									}
									else if(params.data.event_completed == 1 && params.data.leg_delayed == 1)
									{
										return 'green';
									}
									else if(params.data.event_completed == 0 && params.data.leg_delayed == 1)
									{
										return '';
									}
									else{
										return '';
									}
								},
								valueGetter:function(params){
									return getDDMMYYYY(params.data.previous_event_date);
								},resizable: true
							},						
							{ 
								headerName: "Actual Event Date", field: "actual_event_date", filter:true ,
								cellClass:function(params){
									if(params.data.event_completed == 1 && params.data.leg_delayed == 0)
									{
										return 'green';
									}
									else if(params.data.event_completed == 1 && params.data.leg_delayed == 1)
									{
										return 'green';
									}
									else if(params.data.event_completed == 0 && params.data.leg_delayed == 1)
									{
										return '';
									}
									else{
										return '';
									}
								},
								valueGetter:function(params){
									return getDDMMYYYY(params.data.actual_event_date);
								},resizable: true,
								//comparator: dateComparator,
								//sort: "asc"
							}
							// { 
							// 	headerName: "Trip Stage", field: "trip_stage", filter:true ,
							// 	cellClass:function(params){
							// 		if(params.data.event_completed == 1 && params.data.leg_delayed == 0)
							// 		{
							// 			return 'green';
							// 		}
							// 		else if(params.data.event_completed == 1 && params.data.leg_delayed == 1)
							// 		{
							// 			return 'green';
							// 		}
							// 		else if(params.data.event_completed == 0 && params.data.leg_delayed == 1)
							// 		{
							// 			return '';
							// 		}
							// 		else{
							// 			return '';
							// 		}
							// 	},resizable: true,
							// 	valueGetter:function(params){
							// 		if(params.data.trip_stage == 1)
							// 		{
							// 			return 'High Seas';
							// 		}

							// 		if(params.data.trip_stage == 2)
							// 		{
							// 			return 'Port In';
							// 		}

							// 		if(params.data.trip_stage == 3)
							// 		{
							// 			return 'Port Out';
							// 		}

							// 		if(params.data.trip_stage == 4)
							// 		{
							// 			return 'ICD In';
							// 		}

							// 		if(params.data.trip_stage == 5)
							// 		{
							// 			return 'ICD Out';
							// 		}
							// 	}
							// }
							
						],
										
					},
					getDetailRowData: async function(params) {
						console.log("Step 4 eventsData =",eventsData);
						//console.log("Step 5 params = ",params);
						redirectURL.post('/trackBlDeepSea/exports', {
							container: params.data.container_no,
							sealine: params.data.shipping_line_code,
							apiKey: "O1TX-DPHE-WYN6-53MI"
						})
						.then(async (response) =>{
							console.log("Event Step 1 ",response.data)
							//eventsData = await parseEventsData(response.data);
							var nonempty = [];
							var emptyrows = [];
							response.data.map((item) => {
								if(item.hasOwnProperty("actual_event_date"))
								{
									nonempty.push(item)
								}
								else{
									emptyrows.push(item);
								}
							})
							nonempty.sort(function(a, b) {
								var c = new Date(a.actual_event_date);
								var d = new Date(b.actual_event_date);
								return c-d;
							});
							var records = nonempty.concat(emptyrows);
							//console.log("Records ", records);
							params.successCallback(records);
						});
					},

					
					masterDetail: true,
				}
			});
			//console.log("H ", params.column.colDef.field)
			//console.log(params.node.expanded);
			if(params.column.colDef.field == 'sea_transit_delay')
			// || params.column.colId == 'port_delay' || params.column.colId == 'rail_transit_delay' || params.column.colId == 'icd_delay' || params.column.colId == '_id' || params.column.colId == 'msil_transit_delay')
			{
				//console.log("detailCellRendererParams 1111 = ", this.state.detailCellRendererParams, params.column.colId);
				params.node.setExpanded(!params.node.expanded);
				//console.log("params.node.expanded =", params.node.expanded);
			}
			else{
				//console.log("detailCellRendererParams 2222 = ", this.state.detailCellRendererParams, params.column.colId);
				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
			
		} 
		
	}
}
// function loadScript(url){
//     var index  =window.document.getElementsByTagName("script")[0]
//     var script = window.document.createElement("script")
//     script.src=url
//     script.async=true
//     script.defer = true
//     index.parentNode.insertBefore(script,index)
// }
                   
// function timeConvert(n) {
// 	var num = n;
// 	var hours = (num / (3600));
// 	var rhours = Math.floor(hours);
// 	var minutes = (n) / (60);
// 	var rminutes = Math.round(minutes);
// 	return rhours + " hour(s) and " + rminutes + " minute(s).";
// }           
                   
var currentinfowindow = null;
// function getTimeInfo(marker,timedet,coords)
// {
// 	var timeinfo = new window.google.maps.InfoWindow({
//           content: "Time at this location: " + timedet
//         });

// 	marker.addListener('click', function() {
		
// 				if (currentinfowindow != null) {
// 					currentinfowindow.close();
// 					currentinfowindow = timeinfo;
// 					timeinfo.open(marker.get('map'), marker);
// 				}
// 				else
// 				{
// 					currentinfowindow = timeinfo;
// 					timeinfo.open(marker.get('map'), marker);
// 				}
				
//         });
// }

// function parseEventsData2 (jsonData) {
//     console.info('parsing data IN parseEventsData');
//     console.info('parsing data IN parseConsignemntDetailsSafexpress', jsonData);
   
//     let points = jsonData.points;
//    // console.log('points = ', points);
//     let eventList = [];
//     let eventInfo = {};
//     points.forEach(function(point) {
//         eventInfo.name = point.name;
//         eventInfo.country = point.country;
//       // traversing events
//       if (point.events) {
//         console.log('coming in events');
//         point.events.forEach(function(eventObj) { 
//             eventInfo.event_datetime_ist = eventObj.event_datetime_ist;
//             eventInfo.description = eventObj.description;
//         });
//         eventList.push(eventInfo);
//       } else {
//         console.log('coming in ELSE point.events ');

//       }
      
//     });
//     console.info('parsing points');

//     return eventList;
//   }
  
// async function parseEventsData (jsonData) {
// //console.info('parsing data IN parseEventsData');
// //console.info('parsing data IN parseConsignemntDetailsSafexpress', jsonData);

// let points = jsonData.points;
// // console.log('points = ', points);
// let eventList = [];
// let eventInfo = [];

// points.forEach(function(point) {
// 	let pointName = point.name;
// 	let pointCountry = point.country;
// 	let pointTransportTypeName = point.transport_type_name;
// 	let pointTransportType = point.type;
// 	if (pointTransportType === 1 || pointTransportType === 4) {
// 		pointTransportTypeName = "Rail/Truck";
// 	}
// 	if (!pointTransportTypeName) {
// 		pointTransportTypeName = "Data unavailable"
// 	}
// 	// traversing events
// 	if (point.events) {
// 		//console.log('coming in events');
// 		point.events.forEach(function(eventObj) { 
// 			let eventDatetime = eventObj.event_datetime_ist;
// 			let eventDescription = eventObj.description;
			
// 			let initial_event_datetime_ist = eventObj.initial_event_datetime_ist;
//             let previous_event_datetime_ist = eventObj.previous_event_datetime_ist;
// 			let actual_event_datetime_ist	= eventObj.actual_event_datetime_ist;
			

// 			eventInfo = {
// 				"name": pointName,
// 				"country": pointCountry,
// 				"event_datetime_ist": eventDatetime,
// 				"description": eventDescription,
// 				"transport_type_name": pointTransportTypeName,
// 				"initial_event_datetime_ist": initial_event_datetime_ist,
// 				"previous_event_datetime_ist": previous_event_datetime_ist,
// 				"actual_event_datetime_ist": actual_event_datetime_ist,
// 			}
// 			eventList.push(eventInfo);
// 		});
// 	} else {
// 		console.log('coming in ELSE point.events '); // do nothing
// 	}
// });
// //console.log('eventList', eventList);
// return eventList;
// }


function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}
function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
