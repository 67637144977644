import React, { Component } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { getHyphenDDMMMYYYYHHMM} from '../common/utils';

import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import Select from 'react-select';
import $ from 'jquery';
// import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';

import ColumnChartUNLoadingDashboard from './columnchartunloadingdashboard';
import ColumnBasicChart from './columnbasicchart';
var googleAnalytics = require("../common/googleanalytics");
var redirectURL = require('../redirectURL');

export default class UnloadColumnchart extends Component {

    constructor(props) {
        super(props);
        this.state={
            modules:AllModules,
            defaultColDef: {
                sortable: true,
                enablePivot: true,
                pivot : true,
                enableValue: true,
                enableRowGroup: true,
                filter: true,
                resizable: true,
            },
            rowData: [],
            originalrowData: [],
            originalcityrowData: [],
            gpsrowData: [],
            paginationPageSize:50,
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
            locations:[],
            transporters:[],
            transporter:{value:"all",label:"All"},
            location:{value:"all",label:"All"},
            dept_code:'SNDG',
            departmentarray:[],
            scatterdepartmentarray:[],
            categories:[],
            scattercategories:[],
            transporterdata:[],
            transdrilldown:[],
            transportergpsdata:[],
            transgpsdrilldown:[],
            showtranschart:'show-m',
            showgpschart:'show-n',
            loadcheck:true,
            gpsloadcheck:false,
            loadshow:'show-n',
            overly:'show-n',
            timeslotdata:[],
            timeslotweeklydata:[],
            timeslotgpsdata:[],
            monthlydata:[],
            monthlygpsdata:[],
            timeslotgateout:[],
            timeslotgpsgateout:[],
            unloaddrilldown:[],
            allstates:[],
            allstatecities:[],
            selectstate:{value:"All", label:"All States"},
            selectcity:{value:"All", label:"All Cities"},
            citylist:[],
            defaultsdate:'',
            defaultedate:'',
            usergridstate:[],
            screenurl:"",
            screentitle:"",
            pagetitle:"Unload Dashboard",
            eventGridAction: "gridAction",
            
        }
    }

    componentWillReceiveProps(newprops){
        if(newprops.dept_code != this.props.dept_code)
        {
            this.logPageView(); // Log page view to GA
            let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
            this.setState({eventAction: eventAction});

            this.setState({
                loadshow:'show-m',
                overly:'show-m',
                transporter:{"value": "all", "label":'All'},
                location:{"value": "all", "label":'All'}
            });
            //console.log("newprops asd ",newprops)
            var edate = newprops.endDate;
            var sdate = newprops.startDate;
            var dept_code=newprops.dept_code;
            //var locations=newprops.locations;
            //var transporters=newprops.transporters;
            var preqparams = {
                dept_code:dept_code
            }
            this.setState({
                dept_code:dept_code,
                startDate:sdate,
                endDate:edate,
                locations:[],
                transporters:[]
            })
            
            redirectURL.post("/consignments/plants",preqparams)    
            .then((response) => {
              var records = response.data;
              //console.log("records ", records)
              var locations = [];
              if(records.length >0)
              {
                  records.map((item) => {
                    locations.push({
                        code:item.plant_code,
                        label:item.plant_name,
                    })
                  })
              }
              this.setState({
                  locations:locations
              })
            })
            .catch(function(e){
                console.log("Error ",e)
            })
    
            redirectURL.post("/masters/transportersbydept",{
                deptcode:dept_code
            })    
            .then((response) => {
              var records = response.data;
              var transporters = [];
              if(records.length > 0)
              {
                  records.map((item) => {
                    transporters.push({
                        code:item.transporter_code,
                        label:item.transporter_name,
                    })
                  })
              }
              this.setState({
                transporters:transporters
              })
            })
            .catch(function(e){
                console.log("Error ",e)
            });
    
            //getPlantWiseLoadingDelayAnalysis
            //console.log(" this.state.transporter ",this.state.transporter)
            redirectURL.post("/dashboard/plantwiseunloading",{
                dept_code:dept_code,
                startDate:sdate,
                endDate:edate,
                transporter:[{"value": "all", "label":'All'}],
                location:[{"value": "all", "label":'All'}]
            })
            .then((response) => {
                //console.log("response.data ", response.data)
                //var records = JSON.parse(response.data.consignee_uld_data);
                //var scaterrecords = JSON.parse(response.data.complete_data);
                //var transrecords = JSON.parse(response.data.consignee_trans_uld_data);
                //console.log("records ", records);
                //var deptcodedata = groupBy(records, rdata => rdata.consignee_name);
                //var ldcounts = response.data.ld_counts;
                var top10data = JSON.parse(response.data.top_10_state_uld_data);
                top10data = top10data.sort(GetSortDescOrder("unloading_delay_in_hrs"));
                var top10citydata = JSON.parse(response.data.top_10_city_uld_data);
                top10citydata = top10citydata.sort(GetSortDescOrder("unloading_delay_in_hrs"));
               
                var top_10_state_uld_data = JSON.parse(response.data.state_uld_data)
                var top_10_city_uld_data = JSON.parse(response.data.city_uld_data)
                var timeslotdata = JSON.parse(response.data.pan_time_slot_uld_data); 
                var pan_weekday_uld_data = JSON.parse(response.data.pan_weekday_uld_data);
              //console.log("timeslotdata ", timeslotdata)
              var departmentarray=[];
             var transdrildownarr = [];
              var categories=[];
            //   records.forEach((item, key) =>{
            //       //var categories = [];
            //       categories.push(item.consignee_name)
            //       if(item.unloading_delay_in_hrs > 0)
            //       {
            //           //loadingarr.push(Math.round(item.unloading_delay_in_hrs));
            //       }               
            //   })

            //console.log("top10data ", top10data)

            //   var route_trans_wise_data = route_trans_wise_data.sort(GetSortDescOrder("actual_average_days"));
            var scats = []
            var allstatecitiesarr = []
            var unloadingtopdataarr= []
            try{
                top_10_state_uld_data = groupBy(top_10_state_uld_data, rdata => rdata.consignee_state);
                //console.log("top_10_state_uld_data ", top_10_state_uld_data)
                
                top_10_state_uld_data.forEach((item, key) => {
                    //console.log("key ", key+":: "+item)
                    scats.push(key);

                    var totalloadinghrs = 0
                    if(item.length > 0)
                    {
                        item.map((itm) => {
                            totalloadinghrs = totalloadinghrs+itm.unloading_delay_in_hrs
                            unloadingtopdataarr.push({
                                name:itm.consignee_state,
                                y: itm.unloading_delay_in_hrs,
                                drilldown:itm.consignee_state,
                                trips:itm.no_of_trips,
                                unloadtext:itm.unloading_delay_in_hrs_text
                            })
                        })
                        
                        
                    }
                    // unloadingtopdataarr.push({
                    //     name:key,                                
                    //     y:totalloadinghrs,
                    //     drilldown:key
                    // })
                })
            }
            catch(e){

            }
            
              departmentarray.push({                  
                  series:unloadingtopdataarr,
                  categories:categories,
                  yaxistitle:"Avg. Unloading Time (Hrs)",
                  //chartdrildown:transdrildownarr,
              }) 
              
               //DrilldownData

               var transdrildownarr = [];
               var transscatterdeptcodedata = top_10_city_uld_data.sort(GetSortDescOrder("unloading_delay_in_hrs"));
               var transscatterdeptcodedata = groupBy(transscatterdeptcodedata, rdata => rdata.consignee_state);
               //console.log("transscatterdeptcodedata ", transscatterdeptcodedata)
              
               transscatterdeptcodedata.forEach((item, key) =>{
                //console.log("item ", item)             
                // console.log("item ", key)
               
                   
                   var trandrilseries = [];
                   if(item.length > 0)
                   {
                       item.map((itm) => {
                          // console.log("itm ", itm)
                           if(key == itm.consignee_state)
                           {
                               allstatecitiesarr.push({
                                   statename:key,
                                   city:itm.consignee_city
                               })
                            trandrilseries.push([itm.consignee_city,itm.unloading_delay_in_hrs])
                           }
                           
                       })
                   }
                   transdrildownarr.push({
                       name:key,
                       id:key,
                       data:trandrilseries
                   })
                   
               })
               
                //console.log("transdrildownarr ",transdrildownarr )
                var top10dataarr = [];
                var sn = 1;
                top10data.map((item) => {
                    if(sn > 10)
                    {

                    }
                    else{
                        top10dataarr.push(item)
                    }
                    sn = sn+1
                    //console.log("sn ", sn)
                })

                var timecategories = [];
                var timeslotarrdata = [];
               // var timeslotarrgpsdata = [];
                var timeslotdatasort = timeslotdata.sort(GetSortOrder("display_order"));
                var timeslotdatagroup = groupBy(timeslotdatasort, rdata => rdata.reaching_hour_ranges);
                var colgroups = groupBy(timeslotdata, rdata => rdata.reaching_hour_ranges);
               // console.log("colgroups ", colgroups)
                //console.log("timeslotdatagroup  ", timeslotdatagroup)
                var timeslotgrparr=[]
               // var timeslotgrpgpsarr=[]
                timeslotdatagroup.forEach((item, key) =>{
                 //   var timeloadarr = [];
                 //   var timegpsloadarr = [];
                    item.map((val) => {                        
                        //colgroups.forEach((ci,ky) => {
                         //   console.log("CI ",ky)
                            if(key == val.reaching_hour_ranges)
                            {
                                timeslotgrparr.push(val.unloading_delay_in_hrs)
                            }
                       // })
                        
                    });
                    timecategories.push(key)
                    //console.log(key);
                    //console.log(item);
                    //console.log("timeloadarr ", timeloadarr)
                    // timeslotgrparr.push({
                    //     name:"Unlading Time",
                    //     data:timeloadarr
                    // })
                })
                colgroups.forEach((it,ky) =>{
                    //timecategories.push(ky)
                })
                console.log("timeslotarrdata ", timeslotarrdata)
               // console.log("colgroups ", colgroups)
                timeslotarrdata.push({
                    categories:timecategories,
                    lineseries:[{
                        name:"Unloading Time",
                        data:timeslotgrparr
                    }]
                })


                var timecategories = [];
                var timeslotweekarrdata = [];
                //var timeslotarrgpsdata = [];
                var pan_weekday_uld_datasort = pan_weekday_uld_data.sort(GetSortOrder("display_order"));
                var pan_weekday_uld_datagroup = groupBy(pan_weekday_uld_datasort, rdata => rdata.reaching_day_of_week);
                var weeklycolgroups = groupBy(pan_weekday_uld_data, rdata => rdata.reaching_day_of_week);
      
                var timeslotweekgrparr=[]
                pan_weekday_uld_datagroup.forEach((item, key) =>{
                  //  var timeweekloadarr = [];
                    item.map((val) => {                        
                        //colgroups.forEach((ci,ky) => {
                         //   console.log("CI ",ky)
                            if(key == val.reaching_day_of_week)
                            {
                                timeslotweekgrparr.push(val.unloading_delay_in_hrs)
                            }
                       // })
                        
                    });
                    timecategories.push(key)
                    //console.log(key);
                    //console.log(item);
                    //console.log("timeloadarr ", timeloadarr)
                    // timeslotweekgrparr.push({
                    //     name:"Unloading Time",
                    //     data:timeweekloadarr
                    // })
                })
              //  console.log("timeslotweekgrparr ", timeslotweekgrparr)
                weeklycolgroups.forEach((it,ky) =>{
                    //timecategories.push(ky)
                })
                //console.log("timecategories ", timecategories)
                timeslotweekarrdata.push({
                    categories:timecategories,
                    lineseries:[{
                        name:"Unloading Time",
                        data:timeslotweekgrparr
                    }]
                })
              //  console.log("timeslotweekarrdata ", timeslotweekarrdata)
                
                this.setState({
                    departmentarray:departmentarray,
                    unloaddrilldown:transdrildownarr,
                    rowData:top10dataarr,
                    originalrowData:top10data,
                    originalcityrowData:top10citydata,
                    timeslotdata:timeslotarrdata,
                    timeslotweeklydata:timeslotweekarrdata,
                    loadshow:'show-n',
                    overly:'show-n',
                    allstates:scats,
                    allstatecities:allstatecitiesarr
                });
            })
            .catch(function(e){
                console.log("Error ",e)
            })
    
        }
        
    }

	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
    }
    onSelectLocation= location =>{
		this.setState(
			{ location },
			() => console.log(`Location Option selected:`, this.state.location)
		  );
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
    onSelectTransporter= transporter =>{
		this.setState(
			{ transporter },
			() => console.log(`Transporter Option selected:`, this.state.transporter)
		  );
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    locationoptions(){
        var items=[];
       // console.log("locations ",this.props.locations)
        var locations = this.state.locations;
        //console.log("locations ",locations)
        if(locations.length > 0){
            items.push({value:'all',label:'All'}); 
            locations.forEach(item =>{
                items.push({value:item.code,label:item.label}); 
            });
        }
        //console.log("Locas ", items)
        return items;
    }
    transporteroptions(){
        var items=[]
        var transporters = this.state.transporters;
        items.push({value:'all',label:'All'}); 
        if(transporters.length > 0){
            transporters.forEach(item=>{
                items.push({value:item.code,label:item.label}); 
            })
        }
        //console.log("Transps ", items)
        return items;
    }
    handlerStartDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
        var mm = (d.getMonth() +1)
        if(mm <10)
        {
            var month = "0"+mm
        }
        else{
            var month = mm
        }
         
    	var startdate = d.getFullYear()+"-"+month+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
    	this.setState({
    		startDate:startdate
    	});
    	//let value = event.target.value;
        //this.setState({'startDate':value});
    }
	
    handlerEndDateTime  = (event) => {
    	var d = new Date(event._d);
        var mm = (d.getMonth() +1)
        if(mm <10)
        {
            var month = "0"+mm
        }
        else{
            var month = mm
        }
         
    	var edate = d.getFullYear()+"-"+month+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
        this.setState({
    		endDate:edate
    	});
    }
    onClickGetLoadingData(event){
        event.preventDefault();
        if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Filters",
			}
			googleAnalytics.logEvent(eventOptions);
		}
        this.setState({
            loadshow:'show-m',
            overly:"show-m",
            departmentarray:[]
        })
        if(this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN")
        {
            var startDate = this.state.defaultsdate;
        }
        else{
            var startDate = this.state.startDate;
        }
        if(this.state.endDate == '' || this.state.endDate == "NaN-NaN-NaN")
        {
            var endDate = this.state.defaultedate;
        }
        else{
            var endDate = this.state.endDate;
        } 
        if(this.state.location == '' || this.state.location == null)
        {
            var location = [{"value": "all", "label": "All"}];
        }
        else
        {
            var location = this.state.location;
        } 
        if(this.state.transporter == '' || this.state.transporter == null)
        {
            var transporter = [{"value": "all", "label": "All"}];
        }
        else
        {
            var transporter = this.state.transporter;
        }
        
        if(Array.isArray(location) == true)
        {
            var locations = location
        }
        else{
            var locations = [location]
        }
        if(Array.isArray(transporter) == true)
        {
            var transporters = transporter
        }
        else{
            var transporters = [transporter]
        }
        if(Array.isArray(location) == true)
        {
            var locations = location
        }
        else{
            var locations = [location]
        }
        if(Array.isArray(transporter) == true)
        {
            var transporters = transporter
        }
        else{
            var transporters = [transporter]
        }
        var parms = {
            startDate:startDate,
            endDate:endDate,
            location:locations,
            transporter:transporters,
            dept_code:this.state.dept_code
        }
        //getPlantWiseLoadingDelayAnalysis
        redirectURL.post("/dashboard/plantwiseunloading",parms)
        .then((response) => {
            var top10data = JSON.parse(response.data.top_10_state_uld_data);
                top10data = top10data.sort(GetSortDescOrder("unloading_delay_in_hrs"));
                var top10citydata = JSON.parse(response.data.top_10_city_uld_data);
                top10citydata = top10citydata.sort(GetSortDescOrder("unloading_delay_in_hrs"));
               
                var top_10_state_uld_data = JSON.parse(response.data.state_uld_data)
                var top_10_city_uld_data = JSON.parse(response.data.city_uld_data)
              
              var departmentarray=[];
             // var loadingarr = [];
             // var gpsloadingarr = [];
              var transdrildownarr = [];
              var categories=[];
            //   records.forEach((item, key) =>{
            //       //var categories = [];
            //       categories.push(item.consignee_name)
            //       if(item.unloading_delay_in_hrs > 0)
            //       {
            //           //loadingarr.push(Math.round(item.unloading_delay_in_hrs));
            //       }               
            //   })

            //console.log("top10data ", top10data)

            //   var route_trans_wise_data = route_trans_wise_data.sort(GetSortDescOrder("actual_average_days"));
            var scats = []
            var allstatecitiesarr = []
            var unloadingtopdataarr= []
            try{
                top_10_state_uld_data = groupBy(top_10_state_uld_data, rdata => rdata.consignee_state);
                //console.log("top_10_state_uld_data ", top_10_state_uld_data)
                
                top_10_state_uld_data.forEach((item, key) => {
                    //console.log("key ", key+":: "+item)
                    scats.push(key);

                  //  var unloadseries = [];
                    var totalloadinghrs = 0
                    if(item.length > 0)
                    {
                        item.map((itm) => {
                            totalloadinghrs = totalloadinghrs+itm.unloading_delay_in_hrs
                            unloadingtopdataarr.push({
                                name:itm.consignee_state,
                                y: itm.unloading_delay_in_hrs,
                                drilldown:itm.consignee_state,
                                trips:itm.no_of_trips,
                                unloadtext:itm.unloading_delay_in_hrs_text
                            })
                        })
                        
                        
                    }
                    // unloadingtopdataarr.push({
                    //     name:key,                                
                    //     y:totalloadinghrs,
                    //     drilldown:key
                    // })
                })
            }
            catch(e){

            }
            
              departmentarray.push({                  
                  series:unloadingtopdataarr,
                  categories:categories,
                  yaxistitle:"Avg. Unloading Time (Hrs)",
                  //chartdrildown:transdrildownarr,
              }) 
              
               //DrilldownData

               var transdrildownarr = [];
               var transscatterdeptcodedata = top_10_city_uld_data.sort(GetSortDescOrder("unloading_delay_in_hrs"));
               var transscatterdeptcodedata = groupBy(transscatterdeptcodedata, rdata => rdata.consignee_state);
               //console.log("transscatterdeptcodedata ", transscatterdeptcodedata)
              
               transscatterdeptcodedata.forEach((item, key) =>{
                console.log("item ", item)             
                // console.log("item ", key)
               
                   
                   var trandrilseries = [];
                  //var trangpsdrilseries = [];
                   if(item.length > 0)
                   {
                       item.map((itm) => {
                           console.log("itm ", itm)
                           if(key == itm.consignee_state)
                           {
                               allstatecitiesarr.push({
                                   statename:key,
                                   city:itm.consignee_city
                               })
                            trandrilseries.push([itm.consignee_city,itm.unloading_delay_in_hrs])
                           }
                           
                       })
                   }
                   transdrildownarr.push({
                       name:key,
                       id:key,
                       data:trandrilseries
                   })
                   
               })
               
                //console.log("transdrildownarr ",transdrildownarr )
                var top10dataarr = [];
                var sn = 1;
                top10data.map((item) => {
                    if(sn > 10)
                    {

                    }
                    else{
                        top10dataarr.push(item)
                    }
                    sn = sn+1
                    //console.log("sn ", sn)
                })
                this.setState({
                    departmentarray:departmentarray,
                    unloaddrilldown:transdrildownarr,
                    rowData:top10dataarr,
                    originalrowData:top10data,
                    originalcityrowData:top10citydata,
                    loadshow:'show-n',
                    overly:'show-n',
                    allstates:scats,
                    allstatecities:allstatecitiesarr
                });
        })
        .catch(function(e){
            console.log("Error ",e)
        })
    }

    
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	
	//    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	   
		
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
		   
    }

    onChangeChartView(params){
        if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Change Chart View",
			}
			googleAnalytics.logEvent(eventOptions);
		}
        if(params == 'gpsloading')
        {
            this.setState({
                gpsloadcheck:true,
                loadcheck:false,
                showgpschart:'show-m',
                showtranschart:'show-n'
            })
        }
        if(params == 'loading')
        {
            this.setState({
                gpsloadcheck:false,
                loadcheck:true,
                showgpschart:'show-n',
                showtranschart:'show-m'
            })
        }
    }
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
           
		});
		
    }
    
    stateoptions(){
        var items=[];
        var states = this.state.allstates;
        //console.log("states ",states)
        if(states.length > 0){
            items.push({value:'All',label:'All States'}); 
            states.forEach(item =>{
                items.push({value:item,label:item}); 
            });
        }
        return items;
    }
    onChangeSelectState = async (selectstate) =>{
		await this.setState(
			{ selectstate },
			() => console.log(`State Option selected:`, this.state.selectstate)
          );
          this.getStateCity(selectstate.value)
          this.getStateCityData(this.state.selectcity.value)
          await this.setState({
                errorMessage:'',
                successMessage:''
            })
    }
    getStateCity(statename){
        var allstatecities =  this.state.allstatecities;
        var citylist= [];
        if(allstatecities.length > 0)
        {
          var allstatecitiesarr =   groupBy(allstatecities, rdata => rdata.statename);
          //try{
               allstatecitiesarr.forEach((item,key) => {
                    if(key == statename)
                    {
                        if(item.length > 0)
                        {
                            item.map((itm) => {
                                citylist.push(itm.city)
                            })
                        }
                     
                    }
                    else{
                        if(statename == 'All')
                        {
                            if(item.length > 0)
                            {
                                item.map((itm) => {
                                    citylist.push(itm.city)
                                })
                            }
                        }
                        
                    }
              })
        //   }
        //   catch(e){
        //       citylist=[]
        //   }
            //console.log("citylist ",citylist)
        }
         this.setState({
            errorMessage:'',
            successMessage:'',
            citylist:citylist
        })
    }

    cityoptions(){
        var items=[];
        if(this.state.selectcity.value == 'All')
        {
            var city = this.state.allstatecities;
            //console.log("locations ",locations)
            if(city.length > 0){
                items.push({value:'All',label:'All Cities'}); 
                city.forEach(item =>{
                    items.push({value:item.city,label:item.city}); 
                });
            }
        }
        else{
            if(this.state.selectcity.value != 'All' && this.state.selectstate.value == 'All')
            {
                var city = this.state.allstatecities;
                //console.log("locations ",locations)
                if(city.length > 0){
                    items.push({value:'All',label:'All Cities'}); 
                    city.forEach(item =>{
                        items.push({value:item.city,label:item.city}); 
                    });
                }
            }
            else{
                var city = this.state.citylist;
                //console.log("locations ",locations)
                if(city.length > 0){
                    items.push({value:'All',label:'All Cities'}); 
                    city.forEach(item =>{
                        items.push({value:item,label:item}); 
                    });
                }
            }
        }
       
        return items;
    }
    onChangeSelectCity = selectcity =>{
		this.setState(
			{ selectcity },
			() => console.log(`City Option selected:`, this.state.selectcity)
		  );
		this.getStateCityData(selectcity.value) 
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    getStateCityData = async (city) =>{
        var statename = this.state.selectstate.value;
        //console.log("City ", city)
        //console.log("Statename ", statename)
        // if(this.state.selectcity.value != '' && this.state.selectcity.value != "All")
        // {
        //     var cityname = this.state.selectcity.value;
        // }
        // else{
            var cityname = city;
        //}
        var gridData=[]
        if(this.state.originalrowData.length  > 0)
        {
            if(statename != 'All' && cityname == 'All')
            {
                var citiesdata = this.state.originalrowData.sort(GetSortDescOrder("unloading_delay_in_hrs"));
                var sn=0;
                citiesdata.map((item) => {
                    if(sn > 9){

                    }
                    else{
                        if(item.consignee_state == statename)
                        {
                            gridData.push(item)
                        }
                    }
                   
                })
            }
            else{
                if(statename == 'All' && cityname == 'All')
                {
                    var citiesdata = this.state.originalrowData.sort(GetSortDescOrder("unloading_delay_in_hrs"));
                    var sn =0;
                    citiesdata.map((item) => {
                        if(sn > 9)
                        {
                        }
                        else{
                            gridData.push(item)
                        }
                        sn=sn+1
                        console.log("sn ", sn)
                    })
                }
                else{
                    if(cityname == 'All')
                    {
                        var citiesdata = this.state.originalcityrowData.sort(GetSortDescOrder("unloading_delay_in_hrs"));
                    
                        //await this.state.originalrowData.map((item) => {
                        citiesdata.map((item) => {
                            gridData.push(item)
                        })
                    }
                    else{
                        var citiesdata = this.state.originalcityrowData.sort(GetSortDescOrder("unloading_delay_in_hrs"));
                    
                        //await this.state.originalrowData.map((item) => {
                        citiesdata.map((item) => {
                            if(item.consignee_city == cityname)
                            {
                                gridData.push(item)
                            }
                        })
                    }
                    
                }
            }
                
        }
                
           //})
        
        //console.log("gridData ", gridData)
        await this.setState({
            rowData:gridData
        });
    }
    render(){
        var hideinvoice = (this.state.dept_code == 'LOG-PRT')?true:false;
        var hidemsilcol = (this.state.showtranschart == 'show-n')?true:false 
        var hidegpscol = (this.state.showtranschart == 'show-n')?false:true
        var columnwithDefs= [
            
            {
                headerName:"Truck No",
                field:"truck_no",
                width:120
            },
            {
                headerName:"Plant Name",
                field:"plant_location",
                width:150
            },
            {
                headerName:"Transporter Name",
                field:"transporter_name",
                width:200
            },
            
            {
                headerName:"Consignee Name",
                field:"consignee_name",
                width:160
            },
            
            {
                headerName:"Consignee State",
                field:"consignee_state",
                width:160
            },
            {
                headerName:"Consignee City",
                field:"consignee_city",
                width:160
            },
            {
                headerName:"Unloading Time (Hrs)",
                field:"unloading_delay_in_hrs",
                width:100
            },

            {
                headerName:"Invoice Time ",
                field:"invoice_time",
                width:160,
                valueGetter:function(params){
                    return getHyphenDDMMMYYYYHHMM(params.data.invoice_time)
                }
            },
            {
                headerName:"Gate In ",
                field:"gate_in_time",
                width:160,
                valueGetter:function(params){
                    return getHyphenDDMMMYYYYHHMM(params.data.gate_in_time)
                }
            },
            
            {
                headerName:"Gate Out",
                field:"trip_started_on",
                width:160,
                valueGetter:function(params){
                    return getHyphenDDMMMYYYYHHMM(params.data.trip_started_on)
                }
            },

            {
                headerName:"Reached Destination At",
                field:"trip_ended_on",
                width:160,
                valueGetter:function(params){
                    return getHyphenDDMMMYYYYHHMM(params.data.trip_ended_on)
                }
            },
            {
                headerName:"Left Destination At",
                field:"truck_left_dealer_on",
                width:160,
                valueGetter:function(params){
                    return getHyphenDDMMMYYYYHHMM(params.data.truck_left_dealer_on)
                }
            },
            
        ]
        
        return ( 
        		<div className="row pt-20px tab-brd">
                    <div className="row col-xl-12 col-lg-12">                        
                        <div className="form-group col-xl-2 col-lg-2">
                            <label>Start Date: </label>
                            <Datetime value={this.state.startDate} 
                            disableCloseOnClickOutside={false} 
                            closeOnSelect={true} 
                            inputProps={{ placeholder: 'Start Date', name: 'startDate', autoComplete:'off' }}
                            dateFormat="YYYY-MM-DD" 
                            name="startDate" 
                            onChange={this.handlerStartDateTime.bind(this)} />
                        </div>
                        <div className="form-group col-xl-2 col-lg-2">
                            <label>End Date: </label>
                            <Datetime value={this.state.endDate} 
                            disableCloseOnClickOutside={false} 
                            closeOnSelect={true} 
                            inputProps={{ placeholder: 'End Date', name: 'endDate', autoComplete:'off' }}
                            dateFormat="YYYY-MM-DD" 
                            name="endDate" 
                            onChange={this.handlerEndDateTime.bind(this)} />   
                        </div>
                        <div className="form-gplantwiseloadingroup col-xl-3 col-lg-3">
                            <label>Location: </label>
                                <Select placeholder={"All"} isMulti={true} value={this.state.location} className="border-radius-0" onChange={this.onSelectLocation.bind(this)} style={{borderRadius:"0px"}} options={this.locationoptions()} required />
                        </div>
                        <div className="form-group col-xl-3 col-lg-3">
                            <label>Transporter: </label>
                                <Select placeholder={"All"} isMulti={true} value={this.state.transporter} className="border-radius-0" onChange={this.onSelectTransporter.bind(this)} style={{borderRadius:"0px"}} options={this.transporteroptions()} required />
                        </div>
                        <div className="form-group col-xl-2 col-lg-2">
                            <label>&nbsp;</label><br />
                            <button type="button" className="btn btn-success" onClick={this.onClickGetLoadingData.bind(this)}>Submit</button>
                        </div>
                    </div>
                    <div className="row col-xl-12 col-lg-12">
                        <div className="row col-xl-12 col-lg-12 mb-40p">
                            {this.state.departmentarray.length > 0?
                            
                                this.state.departmentarray.map(item => 
                                    <div className="col-xl-12 col-lg-12">
                                        <ColumnChartUNLoadingDashboard
                                            title={"Avg. UnLoading Time / plant"}
                                            subtitle={"Avg. time spent by the carrier at the dealer location for unloading"}
                                            context={this}
                                            series={item.series}
                                            categories={item.categories}
                                            yaxistitle={item.yaxistitle}
                                            drilldownseries={this.state.unloaddrilldown}
                                            dept_code={this.state.dept_code}
                                        />
                                    </div>
                                    
                                )
                            :""}
                            

                        </div>

                            {this.state.timeslotdata.length > 0?
                            
                                this.state.timeslotdata.map(item => 
                                    <div className={"col-xl-6 col-lg-6 "}>
                                        <ColumnBasicChart
                                            title={'Hourly Unloading Avg. Time'}
                                            subtitle={""}
                                            context={this}
                                            series={item.lineseries}
                                            categories={item.categories}
                                            xaxistitle={""}
                                            yaxistitle={"Avg. Unloading Time (Hrs)"}
                                            pointpostfix={"Hrs"}
                                            dept_code={this.state.dept_code}
                                        />
                                    </div>
                                    
                                )
                            :""}
                            
                            
                            {this.state.timeslotweeklydata.length > 0?
                            
                                this.state.timeslotweeklydata.map(item => 
                                    <div className={"col-xl-6 col-lg-6 "}>
                                        <ColumnBasicChart
                                            title={'Weekly Unloading Avg. Time'}
                                            subtitle={""}
                                            context={this}
                                            series={item.lineseries}
                                            categories={item.categories}
                                            xaxistitle={""}
                                            yaxistitle={"Avg. Unloading Time (Hrs)"}
                                            pointpostfix={"Hrs"}
                                            dept_code={this.state.dept_code}
                                        />
                                    </div>
                                    
                                )
                            :""}

                            
                         {/* <div className="row col-xl-6 col-lg-6 mb-40p">
                            {this.state.departmentarray.length > 0?
                            
                                this.state.departmentarray.map(item => 
                                    <div className="col-xl-12 col-lg-12">
                                        <LineLoadingDashboard
                                            title={'Hourly Loading Avg. Time (MSIL Data)'}
                                            subtitle={""}
                                            context={this}
                                            series={item.lineseries}
                                            categories={item.categories}
                                            xaxistitle={"Gate In Hour"}
                                            yaxistitle={"Avg. Loading Time (Hrs)"}
                                            pointpostfix={"Hrs"}
                                            dept_code={this.state.dept_code}
                                        />
                                    </div>
                                    
                                )
                            :""}
                            

                        </div> */}
                        <div className="col-xl-12 col-lg-12">
                            <span className="col-xl-2 float-right">
                                <Select placeholder={"All Cities"} value={this.state.selectcity} className="border-radius-0" onChange={this.onChangeSelectCity.bind(this)} style={{borderRadius:"0px"}} options={this.cityoptions()} />
                            </span>
                            <span className="col-xl-2 float-right ">
                                <Select placeholder={"All States"} value={this.state.selectstate} className="border-radius-0" onChange={this.onChangeSelectState.bind(this)} style={{borderRadius:"0px"}} options={this.stateoptions()} />                        
                            </span>
                        </div>
                        <div className={"row col-xl-12 col-lg-12 "+(this.state.showtranschart)}>
                            <h5>Top 10 Unloading longest waiting</h5>
                            <div id="myGrid" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.rowData}
                                enableCharts={true}
                                enableRangeSelection={true}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                />
                                
                            </div>
                        </div>

                        <div className={"dataLoadpage " +(this.state.loadshow)}></div>
                        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                        </div>
                        <div className={"overlay "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
                    
                    </div>
                   
                </div>

			);
		}
}

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}


function GetSortOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 


$(document).ready(function(){
    
    $(".loadclick").click(function(){
        var id = $(this).attr("id");
        $(".loadclick").removeClass("active");
        $("#"+id).addClass("active");
    })
})

function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
        // console.log(date1,date2);
    var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
    date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
    var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
    date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
    // console.log(date1,date2);
    var date1Number = monthToComparableNumber(date1);
    var date2Number = monthToComparableNumber(date2);
    // console.log(date1Number,date2Number);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
    }
  }
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName){
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
        ];
    var monthNum = months.indexOf(monthName)+1;
    if(monthNum < 10){
        monthNum = "0"+monthNum.toString();
    }
    return monthNum;
}







