import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import {AllModules} from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Select from 'react-select';
import CSVFileValidator from 'csv-file-validator';
import CountUp from 'react-countup';
import AlloyBarForceClose from '../layouts/alloybarForceClosureSideBar';
import DrawMap from '../common/drawmap';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import { getDDMMYYYY, getDDMMYYYYHHMMSS,getHyphenDDMMYYYYHHMMSS,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY , getYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS } from '../common/utils';

import Map from '../common/drawmap';

import redirectURL from '../redirectURL';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import MapComponent from './mapLocationComponent';
import Consignmentforceclose from '../manage/consignmentforceclose';
import $ from 'jquery';
// import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';

var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");


export default class AlloybarComponent extends Component{
    constructor(props) {
        super(props);
        this.state={
            modules: AllModules,
            // truckData:this.props.truckInfo,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            eventAction: null,
            show: false,
            mapinfo:"",
            basicTitle:'',
            msil_gate_in : "",
            basicType:"default",
            loadshow:'show-m',
            dealer : [],
            maptruckno:"",
            defTransitCoords:[],
            rowData:[],
            rownode:"",
            googelRoutes:"",
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:50,
            frameworkComponents: {
                consignmentActions:ConsignmentActions,
                Consignmentforceclose : Consignmentforceclose
            },
            sliderRouteTranslate:"",
            forceclosedata:"",
            forceCloseRowNode : "",
            sliderForceCloseTranslate:"",
            overly:'show-m',
            reasonforceclose:"",
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			animateRows: true,
            dept_code:"",
            insideICD : "",
            inTransitMSIL : "",
            usergridstate:[],
            screenurl:"",
            screentitle:"",
            pagetitle:"",
            eventGridAction: "gridAction",
            sliderBulkForceClose : "",
            csvcontent : ""

        };
        this.handleInput = this.handleInput.bind(this);
        this.onClickForceClose = this.onClickForceClose.bind(this);
        this.onShowGoogleRoute = this.onShowGoogleRoute.bind(this);
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount(){
        loadDateTimeScript();
        this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});


		redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname})
		.then( async (resp) => {
			await this.setState({
				usergridstate:resp.data
			});
			await this.restoreGridStates();
		})
        redirectURL.post("/importscoils/getAlloybars").then((response)=>{
            console.log(response.data);
            this.setState({
                rowData : response.data
            })
            var data = response.data;
            var icd = data.filter(function(eachRec){
                if(eachRec.dispatch_date == null || eachRec.dispatch_date == "" || eachRec.dispatch_date == undefined){
                    return eachRec
                }
            })
            var msil = data.filter(function(eachRec){
                // console.log(eachR)
                if(eachRec.dispatch_date != null && eachRec.dispatch_date != "" && eachRec.dispatch_date != undefined){
                    var date1 = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
                    var date2 = eachRec.dispatch_date;
                    var time1 = moment(date1).format('YYYY-MM-DD');
                    var time2 = moment(date2).format('YYYY-MM-DD');
                    if(time2 >= time1){
                        return eachRec
                    }
                }
            })
            console.log(icd);
            console.log(msil);
            this.setState({
                insideICD : icd,
                inTransitMSIL : msil,
                overly : "show-n",
                loadshow : "show-n"
            })
        })

    }

    
    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
		   
	}
    onShowGoogleRoute(trailerno, sdate,edate){
		// console.log("GOogle params ", consignmentcode+", "+ consigneecode+", "+legno)
		var reqData = {
			leg_no:0,
			truck_no:trailerno
		}
		redirectURL.post('/consignments/googlemaproutes',reqData, {
            headers:{
                'content-type':'application/json'
            }
        })
		.then((response) => {
			console.log("Google ", response)
			//var gdt = '[{	"lat": 28.367420000000003,	"lng": 76.90442},{	"lat": 28.379795,	"lng": 76.903625},{	"lat": 28.380361999999998,	"lng": 76.899445},{	"lat": 28.383162,	"lng": 76.90275},{	"lat": 28.384619,	"lng": 76.900024},{	"lat": 28.385069,	"lng": 76.900024},{	"lat": 28.384990000000002,	"lng": 76.8998}]' 
			this.setState({
				googelRoutes:response.data.googleroute
			})
		})
		.catch(function(e){
			console.log(e)
		})
	}
    onCloseUploadDiv = () => {
		this.setState({
			sliderRouteTranslate:"",
			overly:'show-n',
			loadshow:'show-n'
		});
    }
    onCloseRouteDiv = () => {
		this.setState({
			sliderRouteTranslate:"",
            overly:'show-n',
            loadshow:'show-n'
		});
    }
    resetUpload = () => {
		this.setState({
			uploadDivWidth:'0%',
			bulkForceCloseSlide:'',
			showDiv:'show-n',
			overly:'show-n',
			file:''
		});
		document.getElementById("upform").reset();
	}
    uploadBulkForceFormHandler(event)
	{
		event.preventDefault();
	
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.formAction,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		var bulkData = this.state.csvcontent;
		
        var reqParams = {
            email : localStorage.getItem("email"),
            csvData : bulkData
        }
        
        redirectURL.post("/importscoils/bulkUploadAlloyForceClose",reqParams).then((response)=>{
            this.setState({
                basicTitle : "Success",
                basicType : "success",
                show : true,
                file : "",
                bulkForceCloseSlide:"",
                overly:"show-n",
                loadshow : "show-n"
            })
            // window.location.reload();
        }).catch((e)=>{
            console.log(e);
        })
	}
    changeFileHandler = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		const config = {
			headers: [
				{ 
					name: 'container_no',
					inputName: 'container_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'dispatch_date',
					inputName: 'dispatch_date',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				

			
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			this.setState({
				csvcontent:csvData.data
			});
		})
		.catch(err => {})
		// console.log("e.target.files[0]",e.target.result);
		var contentList = []
		
		var out = new Promise(function(reject, resolve){
			var reader =  new FileReader();
			reader.onload = async function(e) {
				var contents = await e.target.result;
				resolve(contents);
			};
			var tt =  reader.readAsText(e.target.files[0]);
		});
		console.log(contentList);
		this.setState({
			file:e.target.files[0]
		});
		// console.log("e.target.files[0].type ", e.target.files[0]);

		if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
		{
			// console.log(fileData);
			// console.log(typeof(fileData))
			
		}
		else{
			e.target.value = null;
			this.setState({
				uploadFile:'',
				file:"",
				show: true, 
				basicType:'danger', 
				basicTitle:'Please upload file having extensions .csv only.',
			});
		}
		
	}
    onShowRouteDiv = (e) => {
		if (googleAnalytics.page.enableGA) {
            let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		
		console.log("IN onShowRouteDiv, Rute params ", e)
		this.setState({
			loadshow:'show-m',
			maptruckno:e.trailer_no
		});
        var from_date = getHyphenYYYYMMDDHHMMSS(e.dispatch_date);
        var end_date = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
		var reqData = {
            truck_no:e.trailer_no,
			gate_out_time:from_date,
			seconddate:end_date,
			screen:""
        }
        console.log("reqData: ", reqData);
		redirectURL.post('/consignments/maproutes',reqData, {
            headers:{
                'content-type':'application/json'
            }
        })
		.then((response) => {
			var records = response.data;
			console.log("Props data ", e)

			this.setState({
				loadshow:'show-m'
			});
			if(records.coords.length == 0)
			{
				//console.log("records", records.coords);
				this.setState({
					show: true,
					basicTitle:'No Route data available',
					basicType:"danger",
					loadshow:'show-n'
				});
			}
			else{
				if(records != '')
				{
					var sdate = e.gate_out_time;
					var edate =moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
					
					//console.log("End Date ", edate)
					this.setState({
						sliderRouteTranslate:"slider-translate-60p",
						showDiv:'show-m',
						mapinfo:records,
						// dealer:e.consignee_code,
						// consignment_code:"Consignment : "+e.consignment_code,
						maptruckno:e.trailer_no,
						routeTruck:	{"truck_no":e.trailer_no,"startTime":sdate,"endTime": edate},
						loadshow:'show-n',
						// sidebarSubHeader:"Consignment Information",
						overly:'show-m',
						rownode:e,
						leg_no:0
					});
					this.onShowGoogleRoute(e.trailer_no,sdate,edate)
					this.renderMap();

				}
			}
			
			
		})
		.catch(function (error) {
			console.log(error);
		});
	}
    changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
    }
    handleInput(val) {
        console.log(val);
		this.setState({ reasonforceclose: val[0],msil_gate_in : val[1] });
		// console.log(val)
	  }

    onClickCounter(params){
        if(params == "icd")
        {
            this.gridApi.setRowData(this.state.insideICD);
		    this.gridApi.onFilterChanged();
        }
        if(params == "msil"){
            this.gridApi.setRowData(this.state.inTransitMSIL);
		    this.gridApi.onFilterChanged();
        }
        
    }
    onClickForceClose = (params) =>{
        //console.log("Pareas ", params)
        this.setState({
            forceclosedata:params.data,
            forceCloseRowNode : params,
            sliderForceCloseTranslate:"slider-translate-40p",
            overly:'show-m',
            reasonforceclose:""
        })
    }
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
            sliderRouteTranslate:'',
            sliderForceCloseTranslate:"",
            sliderBulkForceClose : ""
        })
    }
    formForceCloseHandler(event){
        event.preventDefault()
        // console.log(this.state.forceclosedata);
        var reqParams = {
            "msil" : this.state.msil_gate_in,
            "reasonforclose" : this.state.reasonforceclose,
            "rowID" : this.state.forceclosedata._id
        }
        if(reqParams.reasonforclose.replace(/\s/g,"").length)
        {
            redirectURL.post("/importscoils/saveForceClose",reqParams).then((response)=>{
                console.log(response);
                if(response.data.message == "success"){
                    this.setState({
                        basicType : "success",
                        basicTitle : "Force Closed Successfully",
                        show : true,
                        sliderForceCloseTranslate : "",
                        overly : "show-n",
                        loadshow : "show-n"
                    })
                }
            }).catch((e)=>{
                console.log("Error");
            })
        }
        else{
            this.setState({
                basicType : "warning",
                basicTitle : "Reason Cant be Empty",
                show : true,
            })
        }
        
    }
    closeAlert = async () => {
        await this.setState({
            show: false
        });
        await window.location.reload();
	}
    saveEditedData = (event) =>{
        event.preventDefault();
        // var cellDefs = event.gridOptions.api.getEditingCells();
        if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedUpdateNow,
			}
			googleAnalytics.logEvent(eventOptions);
		}

        var itemsToUpdate = [];
		this.gridApi.forEachNodeAfterFilterAndSort(function(rowNode, index) {
			var data = rowNode.data;
			itemsToUpdate.push(data);
        });
        //console.log("itemsToUpdate ", itemsToUpdate)
		var assignarr=[];
		itemsToUpdate.map((item) => {
            var stuffing_date = item.stuffing_date
            var dispatch_date = item.dispatch_date
            var domestic_container_no = item.domestic_container_no
            var seal_no = item.seal_no
            var trailer_no = item.trailer_no
            // if(stuffing_date != null && (dispatch_date != null || dispatch_date != undefined) && domestic_container_no != null
            //     && seal_no != null && (trailer_no != null || trailer_no != undefined))
            // {
                if(stuffing_date != "" && stuffing_date != undefined)
                {
                    if(stuffing_date.indexOf("T") >= 0)
                    {
                        var stuffingdate = stuffing_date.split("T")
                        var stuffdate = stuffingdate[0]
                    }
                    else{
                        var stuffingdate = stuffing_date.split(" ")
                        var stuffdate = stuffingdate[0].replace(/\//g,"-")
                    }
                }
                else{
                    var stuffdate = "";
                }
                
                if(dispatch_date != "" && dispatch_date != undefined)
                {
                    if(dispatch_date.indexOf("T") >= 0)
                    {
                        var dispatchdate = dispatch_date.split("T")
                        var dispdate = dispatchdate[0]
                    }
                    else{
                        var dispatchdate = dispatch_date.split(" ")
                        var dispdate = dispatchdate[0].replace(/\//g,"-")
                    }
                }

                else{
                    var dispdate = "";
                }
               // var stdate = moment.parseZone(new Date(stuffdate+" 00:00:00")).utcOffset("+05:30")._d;
                assignarr.push({
                    "rowid":item._id, 
                    "container_no":item.container_no, 
                    "bl_no":item.bl_no, 
                    "stuffing_date":stuffdate,
                    "dispatch_date":dispdate,
                    "domestic_container_no":domestic_container_no,
                    "seal_no":seal_no,
                    "trailer_no":trailer_no
                });
        //    }
        })
        //console.log("assignarr ", assignarr)
        if(assignarr.length > 0)
        {
            redirectURL.post("/importscoils/saveAlloybars",assignarr).then((response)=>{
                // 
               // console.log(response.data);
                var resp = response.data;
                if(resp.status == "success")
                {
                    this.setState({
                        show:true,
                        basicTitle:"Successfully update data",
                        basicType:"success"
                    })
                }
                else{
                    
                    this.setState({
                        show:true,
                        basicTitle:"Failed to update data",
                        basicType:"danger"
                    })
                }
            })
        }
        else{
            this.setState({
                show:true,
                basicTitle:"No data found.",
                basicType:"danger"
            })
        }
        
       // var editedRow = event.data;
       // console.log("event.data ",event);
        //console.log(editedRow);
        // if(event.colDef.field == "dispatch_date")
        // {
        //     if(editedRow.dispatch_date != "" && editedRow.dispatch_date != null && editedRow.dispatch_date != undefined )
        //     {
        //         var dispatchDate = editedRow.dispatch_date.split(" ")[0].replace(/\//g,"-")
        //     }
        //     else{
        //         var dispatchDate = event.value;
        //     }
        //     stuffingDate = editedRow.stuffing_date
        // }
        // if(event.colDef.field == "stuffing_date")
        // {
        //     if(editedRow.stuffing_date != "" && editedRow.stuffing_date != null && editedRow.stuffing_date != undefined){
        //        // console.log(editedRow.stuffing_date)
        //         var stuffingDate = editedRow.stuffing_date.split(" ")[0].replace(/\//g,"-");
        //        //console.log(stuffingDate)
        //     }
        //     else{
        //         var stuffingDate = event.value;
        //     }
        //     dispatchDate = editedRow.dispatch_date
        // }
        // if(event.colDef.field == "trailer_no")
        // {
        //     if(editedRow.trailer_no.replace(/\s/g,"").length)
        //     {
        //         var trailerno = editedRow.trailer_no.replace(/\s/g,"")
        //     }
        //     else
        //     {
        //         var trailerno = event.value;
        //         // this.setState({
        //         //     basicTitle : "empty truk",
        //         //     basicType : "danger",
        //         //     show : true
        //         // })
        //     }
            
        //     stuffingDate = editedRow.stuffing_date
        //     dispatchDate = editedRow.dispatch_date
        // }
    //    if(event.colDef.field == "dispatch_date" || event.colDef.field == "stuffing_date"
    //    || event.colDef.field == "trailer_no")
    //    {
            // var reqParams = {
            //     recordId : editedRow._id,
            //     bl_no:editedRow.bl_no,
            //     container_no : editedRow.container_no,
            //     dispatch_date : dispatchDate,
            //     domestic_container_no : editedRow.domestic_container_no,
            //     seal_no : editedRow.seal_no,
            //     stuffing_date : stuffingDate,
            //     trailer_no : removeSplChars(trailerno)
            // }
            // console.log("Alloy bard params ",assignarr)
            // redirectURL.post("/importscoils/saveAlloybars",assignarr).then((response)=>{
            //     // 
            //     console.log(response.message);
            // })
        //}
    }
   
    onClickSaveGridState(){
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Save Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		
		let screenpage = 'Alloy bars dispatch';
		

		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates()
	{
		try{


			if(this.state.usergridstate.length > 0)
			{
				var windowstates = this.state.usergridstate;
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
			
			}
		}
		catch(e){}
	}
    showBulkUpload()
    {
        this.setState({
            sliderBulkForceClose : "slider-translate-40p",
            overly :"show-m"
        })
    }
	resetState = () => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Reset Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

	};


    render(){
        const columnwithDefs = [
            {
                headerName: "",
                field: "",
                pinned: 'left',
                width:50,
                
                resizable: true,
                editable : false,
                cellRenderer : "consignmentActions",
                cellClass : function(params){                    
                    if(params.data.dispatch_date != "" && params.data.dispatch_date != null && params.data.dispatch_date != undefined && params.data.trailer_no != "" && params.data.trailer_no != null && params.data.trailer_no != undefined)
                    {   
                        return "blue";
                    }
                    else{
                        return "lightgrey";
                    }
                }
            },
            {
                headerName: "Container No",
                field: "container_no",
                pinned: 'left',
                width:110,
                filter: true,
                resizable: true,
                editable : false
            },
            {
                headerName: "Date of Arrival",
                width:120,
                field: "grlf_arrival_date",
                // pinned: 'left',
                pinned: 'left',
                width:120,
                
                resizable: true,
                editable : false,
                valueGetter : function(params){
                    if(params.data.grlf_arrival_date != "" && params.data.grlf_arrival_date != null && params.data.grlf_arrival_date != undefined)
                    {
                        return getHyphenDDMMMYYYY(params.data.grlf_arrival_date);
                    }
                    else{
                        return ""
                    }
                }
            },
            {
                headerName: "BOE No",
                field: "boe_no",
                // pinned: 'left',
                
                pinned: 'left',
                width:80,
                resizable: true,
                editable : false
            },
            {
                headerName: "BOE Date",
                field: "boe_date",
                width:140,
                // pinned: 'left',
                pinned: 'left',
                width:100,
                
                editable : false,
                resizable: true,
                valueGetter : function(params){

                try{
                    var t = params.data.boe_date;
                    if(t.includes("T") == true)
                    {
                        var d = getHyphenDDMMMYYYY(params.data.boe_date);
                        //console.log("d ",d)
                        return d;
                    }
                    else
                    {
                        var s = t.split("/")
                        if(s.length > 0)
                        {
                            var dt = s[2]+"-"+s[1]+"-"+s[0];
                            //console.log("DT ",dt);
                            var d = moment.parseZone(new Date(dt)).utcOffset("+05:30")._d;
                            //console.log("dt ",d)
                            return getHyphenDDMMMYYYY(d);
                        }
                        else
                        {
                            var d = getHyphenDDMMMYYYY(params.data.boe_date);
                            //console.log("d ",d)
                            return d;
                        }
                    }
                }
                catch(e)
                {
                    console.log(e,"boe date")
                }

                }
            },
            {
                headerName: "BL/No",
                field: "bl_no",
                // pinned: 'left',
                
                pinned: 'left',
                width:140,
                resizable: true,
                editable : false
            },
            {
                headerName: "Delivery Type",
                field: "delivery_type",
                // pinned: 'left',
                
                // pinned: 'left',
                width:100,
                resizable: true,
                editable : false
            },
            {
                headerName: "Container Destuffing Date",
                field: "destuffing_date",
                // pinned: 'left',
                
                // pinned: 'left',
                width:140,
                // cellEditor: DateEditor,
                editable : false,
                resizable: true,
                valueGetter : function(params){
                    if(params.data.destuffing_date != "" && params.data.destuffing_date != null && params.data.destuffing_date != undefined)
                    {
                        return getHyphenDDMMMYYYY(params.data.destuffing_date);
                    }
                    else{
                        return "";
                    }
                }
            },
            
            {
                headerName: "Empty Container Deposition Date",
                field: "empty_container_deposit_date",
                // pinned: 'left',
                
                // pinned: 'left',
                width:140,
                resizable: true,
                editable : false,
                valueGetter : function(params){
                    if(params.data.empty_container_deposit_date != "" && 
                        params.data.empty_container_deposit_date != null && 
                        params.data.empty_container_deposit_date != undefined && 
                        params.data.empty_container_deposit_date != "Invalid date")
                    {
                        try{
                            var t = params.data.empty_container_deposit_date;
                            if(t.includes("T") == true)
                            {
                                var d = getHyphenDDMMMYYYY(params.data.empty_container_deposit_date);
                                //console.log("d ",d)
                                return d;
                            }
                            else
                            {
                                var s = t.split("/")
                                if(s.length > 0)
                                {
                                    var dt = s[2]+"-"+s[1]+"-"+s[0];
                                    //console.log("DT ",dt);
                                    var d = moment.parseZone(new Date(dt)).utcOffset("+05:30")._d;
                                    //console.log("dt ",d)
                                    return getHyphenDDMMMYYYY(d);
                                }
                                else
                                {
                                    var d = getHyphenDDMMMYYYY(params.data.empty_container_deposit_date);
                                    //console.log("d ",d)
                                    return d;
                                }
                            }
                        }
                        catch(e)
                        {
                            console.log(e,"empty_container_deposit_date")
                        }                        
                    }
                    else{
                        return "";
                    }
                } 
            },
            {
                headerName: "Stuffing Date",
                field: "stuffing_date",
                // pinned: 'left',
                
                width:100,
                resizable: true,
                cellEditor: DateEditor,
                valueGetter : function(params){
                    if(params.data.stuffing_date != "" && params.data.stuffing_date != null && params.data.stuffing_date != undefined && params.data.stuffing_date.toString().split("T")[0].split("-")[0] != "1970")
                    {
                        return getHyphenDDMMMYYYY(params.data.stuffing_date);
                    }
                    else{
                        return "";
                    }
                }
            },
            {
                headerName: "Domestic Container No",
                field: "domestic_container_no",
                
                editable : true,
                width:140,
                resizable: true,
            },
            {
                headerName: "Seal No",
                field: "seal_no",
                // pinned: 'left',
                
                editable : true,
                width:85,
                resizable: true,
            },
            {
                headerName: "Dispatch Date",
                field: "dispatch_date",
                // pinned: 'left',
                filter: true,
                resizable: true,
                width:110,
                editable : true,
                cellEditor: DateEditor,
                valueGetter : function(params){
                    if(params.data.dispatch_date != "" && params.data.dispatch_date != null && params.data.dispatch_date != undefined && params.data.dispatch_date.toString().split("T")[0].split("-")[0] != "1970")
                    {
                        return getHyphenDDMMMYYYY(params.data.dispatch_date);
                    }
                    else{
                        return "";
                    }
                }
            },
            {
                headerName: "Trailer No",
                field: "trailer_no",
                // pinned: 'left',
                
                width:140,
                editable : true,
                resizable: true,
                valueGetter : function(params){
                    if(params.data.trailer_no != "" && params.data.trailer_no != null && params.data.trailer_no != undefined){
                        return removeSplChars(params.data.trailer_no)
                    }
                    else{
                        return ""
                    }
                    
                }
            },
            {
                headerName: "Force Close",
                field: "",
                width: 80,
                cellRendererSelector:function(params){					
                    var rendComponent = {
                        component: "Consignmentforceclose"
                    };
                    return rendComponent;
                },
                resizable: true,
                
                
            },
            

        ]

        return(
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                 
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-sm-12 beffect" style={{}}>
                        <div className="card">
                            <div className="card-body" id="counterCardBody">
                                <div className="crm-numbers pb-0">
                                    <div className="row">
                                        <div className="col cirlce-d cursorPointer"  onClick={this.onClickCounter.bind(this,"icd")}>
                                            <span className="f13"><i className="icofont icofont-ui-home f24 txt-secondary"></i><br /> Inside ICD</span>
                                            <h4 className="txt-info f40"><span className="counter"><CountUp end={this.state.insideICD.length}/></span></h4> 
                                        </div>
                                        <div className="col cirlce-d cursorPointer"  onClick={this.onClickCounter.bind(this,"msil")}>
                                            <span className="f13"><i className="icofont icofont-truck-alt f22 txt-success"></i><br /> In Transit to MSIL</span>
                                            <h4 className="txt-info f40"><span className="counter"><CountUp end={this.state.inTransitMSIL.length}/></span></h4> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h5 style={{paddingLeft:"40px"}}>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Alloy Bars Dispatch</span>
                                    
                                    <span className="layoutbtns float-right">
                                        <button className="float-right custom-btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
                                            <i className="icofont icofont-save"></i> Save Grid Layout
                                        </button>
                                        <button className="float-right custom-btn white btn-info" style={{marginRight:"10px"}} onClick={this.resetState.bind(this)}>
                                            <i className="icofont icofont-refresh"></i> Reset Default Layout
                                        </button>
                                        {/* <button className="float-right custom-btn white btn-info" style={{marginRight:"10px"}} onClick={this.showBulkUpload.bind(this)}>
                                            Bulk Force Close
                                        </button> */}
                                    </span>
                                </h5>
                            </div>
                            
                            <div className="card-body">
                            {/* {this.state.rowData != null ? */}
                                <div id="myGrid" style={{width:"100%",height:"460px"}} className="ag-theme-balham">    
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        frameworkComponents = {this.state.frameworkComponents}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        rowSelection={this.state.rowSelection}
                                        // frameworkComponents={this.state.frameworkComponents}
                                        // detailCellRendererParams={this.state.detailCellRendererParams}
                                        //editType={this.state.editType}
                                        stopEditingWhenGridLosesFocus={true}
                                        //floatingFilter={true}
                                        // enableCellChangeFlash={true}
                                        // suppressCellFlash={true}
                                        // rowClassRules={this.state.rowClassRules}
                                        //onCellClicked={this.onRowClicked.bind(this)}
                                        
                                        // onRowSelected={this.onRowSelection.bind(this)}
                                        // suppressRowClickSelection={true}
                                        //onCellEditingStopped={this.saveEditedData.bind(this)}
                                        />
                                </div>  
                                <button type="button" onClick={this.saveEditedData.bind(this)} className="btn btn-primary float-right">Update Now</button>
										
                            </div>
                        </div>
                    </div>
                </div>
                    {this.state.sliderRouteTranslate != ''?
                        <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
                            <div className="slide-r-title">
                                <h4>
                                    {(this.state.consignment_code)}
                                    <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
                                            
                                </h4>
                            </div>
                            <div className="slide-r-body" style={{position:"relative"}}>
                                
                                <div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
                                    <DrawMap context={this} rownode={this.state.rownode} mapFor={"consignment"} googleroutes={this.state.googelRoutes} truckno={this.state.maptruckno} dealer={this.state.dealer} mapinfo={this.state.mapinfo} defTransitCoords={this.state.defTransitCoords} />
                                </div>
                            </div>
                            
                        </div>
                    :""	
                    }
                    <div className={"slide-r "+(this.state.sliderBulkForceClose)} style={{overflow:"hidden"}}>
                        <h3 className="subH">Bulk Force Close</h3>
                                
                        <div className="slide-r-body" style={{position:"relative"}}>
                            
                            <div className="container-fluid">
                                <form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkForceFormHandler.bind(this)}>

                                <div className="form-group mt-20p">
                                    <label className="">Upload File</label> 
                                    <input type="file" name="uploadFile" id="bulkUploadID" onChange={this.changeFileHandler}  className="form-control" required  />
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                    <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                                </div>
                                </form>
                                <div className="form-group">
                                    <p style={{color:"#ff0000"}}>* Note : All fields related to Force close are mandatory and cannot be empty</p>
                                    {/* <p style={{color:"green"}}>* Please use the below fields as headers while uploading</p>
                                    <p style={{color:"cornflowerblue"}}> -> consignment_code</p>
                                    <p style={{color:"cornflowerblue"}}> -> reason</p> */}
                            
                                </div>
                                <div className="form-group">
                                    <a className="btn btn-primary" href={require('../../assets/json/bulk_couriers_force_close_sample.csv')} target="_blank">Sample Template</a>
                                </div>
                                <div className="form-group">
                                    {/* <a className="btn btn-primary" href={require('../../assets/json/Bulk_tickets_update_file.csv')} target="_blank">Sample Template</a> */}
                                
                                </div>		
                            </div>
                        </div>
                    </div>
                    <div className={"slide-r "+(this.state.sliderForceCloseTranslate)} style={{overflow:"auto"}}>
                        <div className="slide-r-title">
                            <h4>
                            Force Closure
                                <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                                        
                            </h4>
                        </div>
                        <div className="slide-r-body" style={{position:"relative"}}>
                            
                            <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', overflow: 'hidden',zIndex:'9999',height:"302px"}} >
                            <form className="theme-form col-xl-12 col-lg-12" onSubmit={this.formForceCloseHandler.bind(this)}>
                                <AlloyBarForceClose context={this} handleInput = {this.handleInput} />
                            </form>
                            </div>
                        </div>
                    </div>
                    <div className={"dataLoadpage " +(this.state.loadshow)}>
                    </div>
                    <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                        <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                    </div>
                    <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
                
            </div>
        )
    }
}

        
function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
	index.parentNode.insertBefore(script,index)
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        timepicker:false
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

function removeSplChars(inputString)
{
    // console.log(inputString);
    if(inputString != "" && inputString != null && inputString != undefined )
    {
        return inputString.replace(/[^\w]/g,"");
    }
    else{
        return "";
    }
    
}


function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}