'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import CommentActions from "../layouts/commentsComponent";
import ForceCloseSideBar from "./forceclosuresidebarcomponent";
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import MarkDeliver from "../layouts/markDeliveredButton.js";
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
import Consignmentforceclose from '../manage/consignmentforceclose';
import UpdateButton from "./updatelmstates";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import CellGraphComponent from './cellGraphComponent';
import $ from 'jquery';
import ColumnGroup from './columngroupComponent';
import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { HardwareDesktopWindows } from 'material-ui/svg-icons';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from "@fullcalendar/timegrid";
import download from 'js-file-download';

// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent="";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;




export default class LmLoadSummary extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
          consignmentforceclose:Consignmentforceclose,
          consignmentActions:ConsignmentActions,
          UpdateButton:UpdateButton,
          MarkDeliver : MarkDeliver,
          commentActions : CommentActions
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        bulkslide:"",
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'multiple',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:200,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-n",
        loadshow:"show-n",
        holidayEvents : [],
        showCalendar : "show-m",
        showGridData : "show-m",
        loadFile : "",
        processErrMsg : "",
        processErrShow : 0,
        transactionId : "",
        comp_code : "",
        lpNames : [],
        loading_port_code : "",
        //components : {datepicker:getDatePicker()}
    }
    this.changeFileHandler = this.changeFileHandler.bind(this);
    this.uploadBulkFormHandler = this.uploadBulkFormHandler.bind(this);
}
componentDidMount(){
    this.pageAccess();
}
async pageAccess(){
    this.setState({
        loadshow:'show-m',
        overly:'show-m',
    })
    var currentUrl = this.props.match.path;
    var is_dept_admin = localStorage.getItem("is_department_admin");
    var role = localStorage.getItem("roles");
    if(role.indexOf("lm_site_admin") >= 0 || is_dept_admin == 1 || role.indexOf("lm_admin") >= 0)
    {
        this.setState({
            pageAccess : 3,
        })
        redirectURL.post("/lmconsignees/getLmLoadingPorts").then(async (response2)=>{
            //console.log(response1.data,"LLPN");
            var lpData = response2.data;
            var lpNames = [];
            if(lpData.length > 0)
            {
                lpData.map((item) => {
                    if(item.comp_short_name != '' && item.comp_short_name != undefined)
                    {
                        lpNames.push({
                            loading_port_code:item.loading_port_code,
                            loading_port:item.comp_short_name,
                        })
                    }
                })
                var selloading_port_code = this.state.loading_port_code;
                console.log(selloading_port_code);
                
                var comp_code = "";
                if(selloading_port_code !=undefined && selloading_port_code !='')
                {
                    var loading_port_code = selloading_port_code;
                    var result1 = lpData.filter(obj => {
                        return obj.loading_port_code == loading_port_code
                    })
                    if(result1.length > 0)
                    {
                        comp_code = result1[0].comp_code;
                    }
                }
                else
                {
                    var loading_port_code = lpData[0].loading_port_code;
                    comp_code = lpData[0].comp_code;
                }
                this.setState({
                    comp_code : comp_code,
                    loading_port_code : loading_port_code,
                    loadshow:'show-n',
                    overly:'show-n',
                    lpNames:lpNames
                });
                console.log(lpData[0].loading_port_code,"lpData[0].loading_port_code")
                await this.onLoadPageData();
            }
        }).catch((e)=>{
            console.log(e);
        })
    }
    else
    {
        var query = {
            "userid" : localStorage.getItem("userid"),
            "menu_url" : currentUrl,
        }
        redirectURL.post("/lmconsignees/checkUserCurrentPageAccess",query).then(async (response)=>{
            var result = response.data;
            if(result.length > 0)
            {                
                var pageAccess = 2;
                var lpNames = [];
                await result.map(async function(item){
                    if(item.loading_port_name != '' && item.loading_port_name != undefined)
                    {
                        lpNames.push({
                            loading_port_code:item.loading_port_code,
                            loading_port:item.loading_port_name,
                        })
                    }
                })
                
                var selloading_port_code = this.state.loading_port_code;
                console.log(selloading_port_code);
                var comp_code = "";
                if(selloading_port_code !=undefined && selloading_port_code !='')
                {
                    var loading_port_code = selloading_port_code;
                    var result1 = result.filter(obj => {
                        return obj.loading_port_code == loading_port_code
                    })
                    if(result1.length > 0)
                    {                        
                        if(result1[0].access !='' && result1[0].access !=undefined)
                        {
                            var pageAccess = result1[0].access;                    
                        }
                        comp_code = result1[0].comp_code;
                    }
                }
                else
                {
                    if(result[0].access !='' && result[0].access !=undefined)
                    {
                        var pageAccess = result[0].access;                    
                    }
                    var loading_port_code = result[0].loading_port_code;
                    comp_code = result[0].comp_code;
                }
                //console.log(lpNames,"lpNames")
                this.setState({
                    pageAccess : pageAccess,
                    loading_port_code : loading_port_code,
                    comp_code : comp_code,
                    lpNames : lpNames,
                    loadshow:'show-n',
                    overly:'show-n',
                })
                console.log(loading_port_code,"loading_port_code")
                await this.onLoadPageData();
            }
            else
            {
                alert("You Don't have access to this screen, Please request access")
                window.location.href="/lmpageaccess";
            }
        }).catch((e)=>{
            console.log(e);
        })
    }
}
onLoadPageData(){
    this.setState({
        overly:"show-m",
        loadshow:"show-m",
    })
    var loading_port_code = this.state.loading_port_code;
    var params = {
        loading_port_code : loading_port_code
    }
    redirectURL.post("/lmconsignees/getLoadProcessRunDetails",params).then((response)=>{
        console.log(response.data,"loadprocess");
        this.setState({
            rowData : response.data,
            overly:"show-n",
            loadshow:"show-n",
        })
    }).catch((e)=>{
        console.log(e);
    })
}

showBulkUpload(){
    this.setState({
        overly :"show-m",
        bulkslide : "slider-translate"
    })
}
resetUpload = () => {
    this.setState({
        bulkslide:'',
        overly:'show-n',
        loadFile:''
    });
    $("#notify_email").val("");
}
fixProcess = () => {
    this.setState({
        processErrMsg : "",
        processErrShow : 0,
        loadFile:''
    });
    $("#notify_email").val("");
}
ignoreProcessError = () => {
    var transactionId = this.state.transactionId;
    console.log("transactionId",transactionId)
    var params = {
        transactionId : transactionId,
    }
    redirectURL.post("/lmconsignees/generateLoadByTransactionId",params).then((response)=>{
        //console.log(response.data,"response")
        var statusCode = response.data.status;
        if(statusCode == 0)
        {
            this.setState({                    
                basicTitle : response.data.message,
                basicType : "success",
                show : true,
            });
        }
        else
        {
            this.setState({
                processErrMsg : response.data.message,
                processErrShow : 1,
                transactionId : response.data.transactionId,
            });
        }
        //window.location.reload();
    });
}
changeFileHandler = async (e) => {
    console.log(e);
    this.setState({
        loadFile : e.target.files[0]
    });    
}

uploadBulkFormHandler(event){
    event.preventDefault();
    var flag = 0;
    let saveData = new FormData();
    if(flag == 0)
    {      
        saveData.append("loadFile", this.state.loadFile);
        var notify_email = $("#notify_email").val();
        saveData.append("notify_email", notify_email);
        var loading_port_code = this.state.loading_port_code;
        saveData.append("loading_port_code", loading_port_code)
        redirectURL.post("/lmconsignees/generateLoad",saveData).then((response)=>{
            console.log(response.data,"response")
            var statusCode = response.data.statusCode;
            if(statusCode == 0)
            {
                this.setState({
                    basicTitle : response.data.message,
                    basicType : "success",
                    show : true,
                });
            }
            else
            {
                this.setState({
                    processErrMsg : response.data.message,
                    processErrShow : 1,
                    transactionId : response.data.transactionId,
                });
            }
            //window.location.reload();
        });
    }
    else
    {
        this.setState({
            basicTitle : "Please upload the file",
            basicType : "warning",
            show : true,
        })
    }
};
    
onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
};
onGridState = () =>{
    //console.log(this.gridApi);

    /*Get  Current Columns State and Store in this.colState */
    this.colState = this.gridColumnApi.getColumnState();

    /*Get Current RowGroup Columns State and Store in this.rowGroupState */
    this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

    /*Get Current Pivot Columns State and Store in this.pivotColumns, 
    * User should enable the Pivot mode.*/
    this.pivotColumns = this.gridColumnApi.getPivotColumns();
            
    /*Get Current Filter State and Store in window.filterState */
    window.filterState = this.gridApi.getFilterModel();
    this.gridApi.setFilterModel(window.filterState);
}



closeAlert = () => {
    this.setState({
        show: false
    });
}

onCellClickedGrid(e)
{
    if(e.colDef.field == "filename")
    {
        var inputFile = e.data.filename;
        console.log(inputFile)
        if(inputFile !='')
        {
            var rngParam = {
                inputFile : inputFile,
            }
            redirectURL.post("/lmconsignees/downloadInputFile",rngParam).then((response)=>{
                console.log("download response ",response)
                download(response.data, inputFile)
                if(response.data.message == "success")
                {
                    alert("success");
                }
                
            }).catch(function(error){
                console.log(error);
            })
        }
    }
}

onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide:"",
    
    });
    
}
showGrid(){
    this.setState({
        showCalendar : "show-n",
        showGridData  : "show-m"
    })
}
onChangeLoadingPlant = (e) =>{
    this.setState({
        loadshow:'show-m',
        overly:'show-m',
    })
    var selectedPlant = $("#loadingPlant").val();
    var params = {
        selectedPlant : selectedPlant,
    }
    redirectURL.post("/lmconsignees/getLoadingPlantDetails",params)    
    .then((response) => {
        var res = response.data;
        this.setState({
            loading_port_code : selectedPlant,
            comp_code : res[0].comp_code,
            loadshow:'show-n',
            overly:'show-n',
        })
        this.pageAccess();
    })
    .catch(function(e){
        console.log("Error ",e)
    })
} 
  render() {
    var showActions = false;
    var showDelete = true;
    if(this.state.pageAccess == 2)
    {
        var showActions = true;
    }
    if(this.state.pageAccess == 3)
    {
        var showDelete = false;
    }
    var columnwithDefs = [      
        {
            headerName: "Generated On",
            field: "createDate",
            width:180,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter:function(params){
                if(params.data.createdon != ''){
                return getHyphenDDMMMYYYYHHMM(params.data.createDate);
                }
                else{
                return "";
                }
            },
        },
        {
            headerName: "File Name",
            field: "filename",
            width:250,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            headerName: "Status",
            field: "data_processed",
            width:250,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter:function(params){
                if(params.data.data_processed == 0)
                {
                    return "Processing";
                }
                else if(params.data.data_processed == 1)
                {
                    return "SOLVER_COMPLETE";
                }
                else if(params.data.data_processed == 2)
                {
                    return "SOLVER_RECO_PROCESSED";
                }
                else if(params.data.data_processed == 3)
                {
                    return "SOLVER_SOB_DONE";
                }
                else if(params.data.data_processed == 4)
                {
                    return "SOLVER_INVALID_INPUT_FILE_NAME";
                }
                else if(params.data.data_processed == 5)
                {
                    return "SOLVER_NO_ASSIGNMENT_FILE";
                }
                else if(params.data.data_processed == 6)
                {
                    return "SOLVER_INVALID_CPP_EXE_FILE";
                }
                else if(params.data.data_processed == 7)
                {
                    return "SOLVER_INPUT_DEALERS_NOT_VALID";
                }
                else if(params.data.data_processed == 8)
                {
                    return "SOLVER_FIX_DROPED_DEALERS";
                }
                else if(params.data.data_processed == 9)
                {
                    return "SOLVER_VEHICLE_DETAILS_MISSING";
                }
                else if(params.data.data_processed == 10)
                {
                    return "SOLVER_INPUT_FILE_DATA_ERROR";
                }
                else if(params.data.data_processed == 11)
                {
                    return "SOLVER_INVALID_FILE_TEMPLATE";
                }
                else if(params.data.data_processed == 12)
                {
                    return "PROCESS_TIMED_OUT";
                }
            }
        },
        {
            headerName: "Remarks",
            field: "remarks",
            width:300,
            filter: true,
            resizable: true,
            editable:false
        },
        {
            pinned:"left",
            headerName : "View Details",
            field:"transaction_id",
            width:150,
            resizable : true,
            editable : false,
            cellRenderer:function(params){
                let basestring = params.data.transaction_id;
                let encryptedstring = window.btoa(basestring);
                var htmloption = '<a href="/viewLoadSummaryDetails/'+encryptedstring+'" class="custom-btn label label-success"><i class="icofont icofont-eye"></i> View</a>';
                if(params.data.data_processed == 1 || params.data.data_processed == 2 || params.data.data_processed == 3)
                {
                    return htmloption;
                }
                else
                {                    
                    return "N/A";
                }
            } 
        }
    ]
    return (
      <div class="container-fluid">
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>

        <div className="card col-xl-12">
            <div class="card-header">
                <h5>
                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Load Summary</span>
                    {(this.state.pageAccess != 2) ?
                    <a href="/lmloadmanager" className="btn btn-info" style={{float:"right"}}>Plan New Dispatch</a>
                    : ""}
                </h5>
            </div>
            {(this.state.lpNames.length > 0) ? 
                <div className="row col-xl-12 col-lg-12" style={{marginTop:"2%"}}>
                    <div className="col-xl-12 col-lg-12">
                    {this.state.lpNames.length == 1 ?
                    <span style={{fontSize:"16px"}}>Loading Plant : {(this.state.lpNames[0].loading_port)}</span>
                    :
                    <span style={{fontSize:"16px"}}>Loading Plant : 
                        <select id="loadingPlant" onChange={this.onChangeLoadingPlant.bind(this)}>
                            {(this.state.lpNames).map(function(obj){
                                return <option value={obj.loading_port_code}>{obj.loading_port}</option>
                            })}                                           
                        </select>
                    </span>
                    }
                    </div>
                </div>
            :""}
            <div className="card-body col-xl-12 col-lg-12">
                <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham "+this.state.showGridData}>    
                <AgGridReact
                    modules={this.state.modules}
                    columnDefs={columnwithDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.rowData}
                    enableCharts={false}
                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                    onGridReady={this.onGridReady}
                    onGridState={this.onGridState}
                    frameworkComponents={this.state.frameworkComponents}
                    statusBar={this.state.statusBar}
                    sideBar={this.state.sideBar}
                    stopEditingWhenGridLosesFocus= {true}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    gridOptions={{
                        context: { componentParent: this }
                    }}
                    // components={this.state.components}
                    enableRangeSelection= {true}
                    onCellClicked={this.onCellClickedGrid.bind(this)}
                    />
            </div>
            </div>
        </div>
        {/* <button className="btn btn-success" onClick={this.formAssignHandler.bind(this)} style={{marginLeft:"91em"}}>Insert Exports</button> */}
        <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
            <h3 className="subH">Imports Orders Data</h3>
            <div className="slide-r-body" style={{position:"relative"}}>                
                <div className="container-fluid">
                    {this.state.processErrShow == 0 ?
                        <form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkFormHandler}>
                        
                        <div className="form-group mt-20p">
                            <label className="">Upload File</label> 
                            <input type="file" name="uploadFile" id="uploadFile" onChange={this.changeFileHandler}  className="form-control" required  />
                        </div>
                        <div className="form-group mt-20p">
                            <label className="">Notify Email Users After Process (comma seperated)</label> 
                            <textarea name="notify_email" id="notify_email" className="form-control"></textarea>
                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn btn-success">Generate Load</button>
                            <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                        </div>
                        </form>
                    :
                    <div className="" style={{marginTop:"1em"}}>
                        <p style={{marginBottom:"1em"}}>{this.state.processErrMsg}</p>
                        <button type="button"  onClick={this.fixProcess.bind(this)} className="btn btn-success">Fix and Reupload</button>
                        <button type="button" onClick={this.ignoreProcessError.bind(this)} className="btn btn-default">Ignore</button>
                    </div>
                    }
                </div>
            </div>
        </div>
        <div className={"dataLoadpage " +(this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
        </div>

    );
  }
}