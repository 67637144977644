import React, { Component } from "react";
import axios from 'axios';
import {Redirect} from 'react-router-dom';
import redirectURL from '../redirectURL'; // Assuming redirectURL is an axios instance
import './ErrorMessage.css'; // Import CSS file for styling

class EnmUserLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {successLogin:0};
    }

    async componentDidMount() {
        const token = this.props.match.params.token;
        var ppwwdd = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		var usrtoken = ppwwdd(token)
        const query = {
            token: token
        };
        try {
            const response = await redirectURL.post("/authenticateUserToken", query);
            console.log("After login ", response);

            if (response.status === 200) {
                if (response.data.auth) {
                    if (response.data.otpAuthentication === 1) {
                        this.setState({
                            otpAuthentication: response.data.otpAuthentication,
                            otpMessage: "OTP has been sent to your Email",
                            userData: response.data,
                            successLogin: 0,
                        });
                    } else {
                        this.setState({
                            "successLogin" : 1,
                        })
                        await redirectURL.post("/manage/saveuserlogged", {
                            token: response.data.token,
                            userId: response.data.userid
                        });
                        
                        localStorage.setItem('token', response.data.token);
                        localStorage.setItem('userid', response.data.userid);
                        let deptCode = JSON.stringify(response.data.user.dept_code);
                        //console.log("In  LoginForm, response.data = ", response.data);
                        localStorage.setItem('dept_code', deptCode);
                        localStorage.setItem('is_admin', response.data.user.is_admin);
                        localStorage.setItem('is_dept_admin', response.data.user.is_dept_admin);
                        localStorage.setItem('is_global', response.data.user.is_global);
                        localStorage.setItem('firstname', response.data.user.firstname);
                        localStorage.setItem('username', response.data.user.username);
                        localStorage.setItem('lastname', response.data.user.lastname);
                        // console.log("response.data.user.email ", response.data.user.email)
                        var useremailat = response.data.user.email.replace("[at]", "@")
                        var useremail = useremailat.replace("[dot]", ".")

                        localStorage.setItem('email', useremail);
                        localStorage.setItem('user_type', response.data.user.user_type);
                        localStorage.setItem('usermenus', JSON.stringify(response.data.menus));
                        localStorage.setItem('usermenucodes', JSON.stringify(response.data.usermenucodes));
                        localStorage.setItem('transportercode', JSON.stringify(response.data.user.transporter_code));
                        try{
                            if(response.data.user.hero_user_type !=undefined && response.data.user.hero_user_type !="")
                            {
                                localStorage.setItem('login_type', response.data.user.hero_user_type);
                            }
                            else
                            {
                                localStorage.setItem('login_type', 1);
                            }
                        }catch(e){
                            localStorage.setItem('login_type', 1);
                        }
                        localStorage.setItem('roles', response.data.user.roles);
                        localStorage.setItem('system_password_change', response.data.user.system_password_change);
                        var md = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
                        var m = md(JSON.stringify(response.data.user))
                        localStorage.setItem('m', m);
                        sessionStorage.setItem('ref', 1);

                        this.setState({
                            username: '',
                            email: '',
                            password: '',
                            loginMessage: response.data.msg
                        });
                    }
                } else {
                    this.setState({
                        username: '',
                        email: '',
                        password: '',
                        loginMessage: response.data.msg
                    });
                }
            } else {
                this.setState({
                    username: '',
                    email: '',
                    password: '',
                    loginMessage: response.data.msg
                });
            }
        } catch (error) {
            console.error("Error authenticating user:", error);
        }
    }

    getUserDashboardUrl(pathToMatch = null) {
        var dashboardUrl = "prtconsignments";
		console.log("dashboardUrl Login", dashboardUrl)
		return dashboardUrl;
	}

	cipher = salt => {
		const textToChars = text => text.split('').map(c => c.charCodeAt(0));
		const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
		const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
	
		return text => text.split('')
			.map(textToChars)
			.map(applySaltToChar)
			.map(byteHex)
			.join('');
	}
    render() {
		if(localStorage.getItem('token') != '' && localStorage.getItem('token') != null)
		{
			var successLogin = this.state.successLogin;
			if(successLogin == 1)
			{
				let dashboardUrl = this.getUserDashboardUrl();
				console.log(dashboardUrl,"dashboardUrl1143");
				//let dashboardUrl = this.state.landingpage;
				// return <Redirect to="dashboard/snddefault" push={true} />

				return <Redirect to={"/"+dashboardUrl} push={true} />
			}
		}
        return (
            <div>
                {/* Loader ends */}
                {(this.state.loginMessage)?
                <div className="error-container">
                    {/* SVG for error icon */}
                    <svg
                    className="error-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path
                        fill="red"
                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-12h2v8h-2zm0 10h2v2h-2z"
                    />
                    </svg>
                    {/* Error message */}
                    <p className="error-message">{this.state.loginMessage}</p>
                </div>
                :
                <div className="loader-wrapper">
                    <div className="loader bg-white">
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <h4>Authenticating... <span>&#x263A;</span></h4>
                    </div>
                </div>
                }
            </div>
        );
    }
}

export default EnmUserLogin;