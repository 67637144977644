/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import Autosuggest from 'react-autosuggest';

import Grid from '../layouts/gridActionComponent';
import Breadcrumb from '../common/breadcrumb.component';
import EditItem from '../layouts/editActionComponent';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

export default class GeoFenceList extends Component {

    constructor(props) {
        super(props);

        this.state = {
			lists:[],
			departmentCode: null,
			currentDepartmentTitle: null,
			currentAddGeoFenceLink: null,
			usermanualmodal:false

        };
	}
	getCurrentDepartmentCode(pathToMatch = null) {
		let department = null;
		let departmentName = null;
		let currentAddGeoFenceLink = "/manage/addgeofence";
		switch(pathToMatch) {
			case "/manage/sndgeofences":
				department='SNDG';
				departmentName = " (Sales and Dispatch) ";
				currentAddGeoFenceLink = "/manage/sndaddgeofence";
				break;
			case "/manage/prtgeofences":
				department='LOG-PRT';
				departmentName = " (Spare Parts) ";
				currentAddGeoFenceLink = "/manage/prtaddgeofence";
				break;
			case "/manage/tnpgeofences":
				department='LOG-TNP';
				departmentName = " (Train and Production) ";
				currentAddGeoFenceLink = "/manage/tnpaddgeofence";
				break;
			case "/manage/scgeofences":
				department='LOG-SC';
				departmentName = " (Supply Chain) ";
				currentAddGeoFenceLink = "/manage/scaddgeofence";
				break;
			case "/manage/mllsndgeofences":
				department='SNDG';
				departmentName = " ";
				currentAddGeoFenceLink = "/manage/mllsndaddgeofence";
				break;
			default:
				console.log("IN getDepartmentFilter, default, params = ", pathToMatch);
		}
		this.setState({
			currentDepartmentTitle:departmentName,
			currentAddGeoFenceLink:currentAddGeoFenceLink
		});
		return department;
	}
    componentDidMount(){
		if (googleAnalytics.page.enableGA) {
			googleAnalytics.logPageView();
		}
		// get department code to filter records by department
		let currentDepartmentCode = this.getCurrentDepartmentCode(this.props.match.path);
		this.setState({
			departmentCode:currentDepartmentCode
		});
		var params = {}
		var login_type = localStorage.getItem("login_type")
		params.login_type = login_type
    	redirectURL.post("/geofence/lists" , params).then(
			(response)=>{
				let records = (response.data) ? response.data : null;
				//console.log("/geofence/lists response.data = ", response.data);
				if (records) {
					if (this.state.departmentCode) {
						records = records.filter(rec => {
							if (rec.dept_code.length) {
								return rec.dept_code[0]["value"] === this.state.departmentCode
							}
						});
					}
				}

				//console.log("/geofence/lists records = ", records);
				this.setState({
					lists:records
				});
			}
		)
		.catch(function(error){
			console.log(error);
		});
    }

    getDepartmentCodes = (params) => {
    	var depts = params.value;
    	var departments=[];
		var deptname='';

    	for(var item in depts)
    	{
    		deptname = deptname + depts[item].value +", ";
    		//console.log(depts[item].dept_code);
    		departments.push(depts[item].value);
    	}
    	//console.log("departments",deptname);
    	//console.log("departments",departments);
    	return deptname;
    }

    onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };
    render(){
		const modalStyles  = {
			width:'1300px !important',
		}
		// console.log(this.props.match.path.indexOf("mllsndgeofences"));
		if(this.props.match.path.indexOf("mllsndgeofences") >= 0)
		{
			var mllhide = false;
			var nrmhide = true;
		}
		else
		{
			var mllhide = true;
			var nrmhide = false;
		}

		const {usermanualmodal} = this.state;
    	const columnwithDefs = [
	  	        {
	    	          headerName: "GeoFence Name",
	    	          field: "geofence_name",
	    	          width: 200,

    	        },
    	    //     {
	  	    //       headerName: "Department",
	  	    //       field: "dept_code",
	  	    //       width: 200,
          //
				  // valueGetter: function(params) {
					//   var depts = params.data.dept_code;
					//   var departments=[];
					//   for(var item in depts)
					// 	{
					// 		departments.push(depts[item].value);
					// 	}
					// return departments;
				  // }
	  	    //     },
	    	    {
	  	          headerName: "Geo Fence Type",
	  	          field: "", //
	  	          width: 150,
	  	        //   cellRenderer:function(params){
				// 		console.log("params",params)
	  	        // 	  var gtype= params.value.value;
				// 	  return gtype;
				//    },
				   valueGetter : params =>{
					//    console.log("params",params)
					return params.data.geofence_type.value
				}
	  	        },
		        {
	  	          headerName: "Fence Loading Type",
	  	          field: "fence_loading_type",
	  	          width: 200,

	  	        },
		        {
		          headerName: "Notify Me",
		          field: "notify_me",
		          width: 150,

		        },
		        {
	  	        	field:'_id',
					headerName:'',
					width: 72,
					hide:mllhide,
					cellRenderer:function(params){
						var geoname = params.data.geofence_name;
						var modifiedName  = geoname.replace(/ /g,"_")
						var htmloption = '<a href="/mlleditGeofence/'+modifiedName+'" class="custom-btn label label-success"><i class="icofont icofont-edit"></i> Edit</a>';
						return htmloption;
					}

					//cellRenderer:['editItem', 'deleteItem']

    	        },
		        {
	  	        	field:'_id',
					headerName:'',
					width: 72,
					hide:nrmhide,
					cellRenderer:function(params){
						var geoname = params.data.geofence_name;
						var modifiedName  = geoname.replace(/ /g,"_")
						var htmloption = '<a href="/editGeofence/'+modifiedName+'" class="custom-btn label label-success"><i class="icofont icofont-edit"></i> Edit</a>';
						return htmloption;
					}

					//cellRenderer:['editItem', 'deleteItem']

    	        },
		        /*{
	  	        	field:'_id',
					headerName:'',
					width: 80,
					cellRenderer:'deleteItem',
					cellRendererParams: {
			            actionCall: "geofence",
			            actionMethod:"deleteGeo"
			        }

					//cellRenderer:['editItem', 'deleteItem']

				}
				*/

	      ];
        return (

    		<div className="container-fluid">
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">

			                <div className="card-header">
				       			<h5>
				       				<i className="icon-map cus-i"></i> <span>Geo Fences {this.state.currentDepartmentTitle} </span>
				       				{/* <a href="/manage/sndaddgeofence" className="btn btn-success pull-right cs-btn"> <i className="icon-plus"></i> Add Geo Fence</a> */}
				       				<a href={this.state.currentAddGeoFenceLink} className="btn btn-success pull-right cs-btn"> <i className="icon-plus"></i> Add Geo Fence</a>
				       			</h5>
				   			</div>
				   			<div className="card-body">
				   				<Grid actionMethod={"deleteGeo"} screenurl={"/manage/geofences"} actionCall={"geofence"} columnwithDefs={columnwithDefs} rowData={this.state.lists} />
				   			</div>
				   		</div>
				   	</div>
				 </div>
				<span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span>
				<Modal open={usermanualmodal} onClose={this.onCloseUserManualModal.bind(this)} styles={modalStyles}>
					<div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<h5 className="model-title">Geofences</h5>
						<div className="col-xl-12 col-lg-12 mt-20p">
							<div className="col-xl-12 col-lg-12">
								This screen consists of all Origin points along with fixed destination points where unloading happens and which belongs to MSIL like the ports used for exports, stockyards etc.
							</div>

							<div className="col-xl-12 col-lg-12">
								Image Here
							</div>
							<div className="col-xl-12 col-lg-12">
								After clicking on Add Geo fence button below screen will be prompted for user to enter required details. The entered details will be reflected over Geo Fences screen.
							</div>
							<div className="col-xl-12 col-lg-12">
								Image Here
							</div>
							<div className="col-xl-12 col-lg-12">
								Image Here
							</div>
						</div>
					</div>
				</Modal>
            </div>

        );
    }
}
