/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import CountUp from 'react-countup';
import axios from 'axios';
var redirectURL = require('../redirectURL');

export default class ConsignmentCountersComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            records: [],
            active_consignments: 0,
            overspeeding: 0,
            night_driving: 0,
            transit_delay: 0,
            unloading_dealer: 0,
            left_dealer: 0,
            no_gps_data_available: 0,
            nogpslifetime: 0,
            is_critical: 0,
            is_priority: 0,
            deptcode: this.props.deptcode,
            triptype: this.props.triptype,
            active_trucks: 0,
            loading_delay: 0,
            unloading_delay: 0,
            enroute_stoppage: 0,
            probableAccident: 0,
            hidecol: true,
            probableAccidentconfirm: 0,
            cancelledCount: 0,
            pod_count: 0,
            trip_closed_by_pod_count: 0,
            returnConsignments: 0,
            counttype: "all",
            truckReportedDate: "",
            deviated_routes: [],
            withoutTruckGateOutTimeData: [],
            forceClosed : 0
        }

    }
    componentWillReceiveProps = async (newprops) => {

        if (newprops.deptcode != this.state.deptcode) {
            //console.log("Conters ",newprops.deptcode)
            if (newprops.triptype && newprops.triptype != 0) {
                var layoutdata = {
                    token: localStorage.getItem('token'),
                    dept_code: newprops.deptcode,
                    trip_type: newprops.triptype
                }
            }
            else {
                var layoutdata = {
                    token: localStorage.getItem('token'),
                    dept_code: newprops.deptcode
                }
            }

            /*Venkatesh Code*/
            var transitdelaysarr = [];
            var gpsdatanaarr = [];
            var overspeedtrucksarr = [];
            var nightdrivearr = [];
            var enroutearr = [];
            var loadingarr = [];
            var unloadingarr = [];
            var iscriticalarr = [];
            //console.log("newprops.gridData ", newprops.gridData)
            if (newprops.gridData.length > 0) {

                /*New Code for Counters*/
                var counter = newprops.countersjson;
                // console.log("counter: => ",counter)
                let withoutTruckGateOutTimeData = 0
                var overspdcnt = 0;
                var nightdrvcnt = 0;
                var transdly = 0;
                var npgs = 0;
                var nogpslifetime = 0;
                var lodelay = 0;
                var unlodelay = 0;
                var enrote = 0;
                var critical = 0;
                var priority = 0;
                var probableAccident = 0;
                var probableAccidentConfirmCount = 0;
                var cancelledCount = 0;
                var pod_count = counter.pod_received_data;
                var trip_closed_by_pod_count = counter.trip_closed_by_pod;
                var forceClosed = 0;
                if (counter.cancelled_count != "Not Applicable") {
                    cancelledCount = counter.cancelled_count
                }
                if (counter.overspeeding.length > 0) {
                    var overspdcnt = counter.overspeeding[0].overspeeding_exception_count;
                }
                try {
                    if (counter.probableAccident.length > 0) {
                        var probableAccident = counter.probableAccident[0].probableAccidentCount;
                    }
                } catch (e) { }

                try {
                    if (counter.probableAccidentConfirm.length > 0) {
                        var probableAccidentConfirmCount = counter.probableAccidentConfirm[0].probableAccidentConfirmCount;
                    }
                } catch (e) { }
                try {
                    if (counter.criticalCount.length > 0) {
                        critical = counter.criticalCount[0].criticalCount;
                    }
                }
                catch (e) { }
                try {
                    if (counter.priorityCount.length > 0) {
                        priority = counter.priorityCount[0].priorityCount;
                    }
                }
                catch (e) { }
                if (counter.night_drive.length > 0) {
                    var nightdrvcnt = counter.night_drive[0].nightdriving_exception_count;
                }
                try{
                    if (counter.forceClosed.length > 0) {
                        var forceClosed = counter.forceClosed[0].forceClosedCount;
                    }
                }catch (e) { }
                if (counter.transit_delay.length > 0) {
                    var transdly = counter.transit_delay[0].transit_delay_count;
                }

                if (counter.no_gps.length > 0) {
                    var npgs = counter.no_gps[0].no_gps_data_count;
                }
                //console.log("newprops.hide ",newprops.hide)
                // if(newprops.hide == true)
                // {
                try {
                    if (counter.nogpslifetime.length > 0) {
                        var nogpslifetime = counter.nogpslifetime[0].no_gps_data_lifetime_count;
                    }
                } catch (e) { }
                // }
                if (counter.loading_delay.length > 0) {
                    var lodelay = counter.loading_delay[0].loading_delay_exception_count;
                }

                if (counter.unloading_delay.length > 0) {
                    var unlodelay = counter.unloading_delay[0].unloading_delay_exception_count;
                }

                if (counter.enroute_stoppage.length > 0) {
                    var enrote = counter.enroute_stoppage[0].enroute_exception_count;
                }
                let grid_data = []
                if(this.props.path == "/prtdeliveredconsignments"){
                    grid_data = newprops.gridData.filter(item  => item.status !==6 )
                }else{
                    grid_data = newprops.gridData
                }
                //console.log("nogpslifetime ",nogpslifetime)
                await this.setState({
                    active_consignments: grid_data.length,
                    overspeeding: overspdcnt,
                    is_critical: critical,
                    is_priority: priority,
                    night_driving: nightdrvcnt,
                    transit_delay: transdly,
                    no_gps_data_available: npgs,
                    nogpslifetime: nogpslifetime,
                    loading_delay: lodelay,
                    unloading_delay: unlodelay,
                    enroute_stoppage: enrote,
                    probableAccident: probableAccident,
                    probableAccidentconfirm: probableAccidentConfirmCount,
                    cancelledCount: cancelledCount,
                    pod_count: pod_count,
                    trip_closed_by_pod_count: trip_closed_by_pod_count,
                    returnConsignments: newprops.returnConsignments,
                    counttype: newprops.counttype,
                    deviated_routes: counter.deviated_routes,
                    withoutTruckGateOutTimeData: newprops.withoutTruckGateOutTimeData.length,
                    forceClosed
                });
            }
            else {
                await this.setState({
                    active_consignments: 0,
                    overspeeding: 0,
                    night_driving: 0,
                    transit_delay: 0,
                    is_critical: 0,
                    is_priority: 0,
                    //unloading_dealer:counterdata.unloading_dealer,
                    //left_dealer:counterdata.left_dealer,
                    no_gps_data_available: 0,
                    nogpslifetime: 0,
                    //active_trucks:counterdata.active_trucks,
                    loading_delay: 0,
                    unloading_delay: 0,
                    enroute_stoppage: 0,
                    probableAccident: 0,
                    probableAccidentconfirm: 0,
                    returnConsignments: newprops.returnConsignments,
                    deviated_routes: [],
                });
            }
            this.setState({
                hidecol: newprops.hide,
                truckReportedDate: this.props.truckReportedDate
            });
        }


    }

    onClickCounter(data) {
        this.props.context.onClickCounterShowData(data)
    }
    render() {
        var activeshow = (this.state.counttype == 'all' || this.state.counttype == 'active') ? 'show-m' : 'show-n'
        // console.log("ths.state", this.state.deptcode, '\n this.prop', this.props)

        return (
            <div className="row">

                <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                    <div className="card">
                        <div className="card-body" id="counterCardBody">
                            <div className="crm-numbers pb-0">
                                {(this.state.returnConsignments == false) ?
                                    <div className="row">
                                        <div className="col cirlce-d cursorPointer" onClick={this.onClickCounter.bind(this, "all")}>
                                            <span className="f13"><i className="icofont icofont-ui-browser f24 greenfont"></i><br /> Consignments</span>
                                            <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.active_consignments != '') ? this.state.active_consignments : 0} /></span></h4>

                                        </div>
                                        {/* <div className="col">
                                        <span className="f13"><i className="icofont icofont-truck f24 greenfont"></i><br /> Active Trucks</span>
                                        <h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.active_trucks != '')?this.state.active_trucks:0}/></span></h4>

                                    </div> */}
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "transit_delay")}>
                                            <span className="f13"><i className="icofont icofont-clock-time f22 redfont"></i><br /> Transit Delays</span>
                                            <h4 className="txt-danger f40"><span className="counter"><CountUp end={(this.state.transit_delay != '') ? this.state.transit_delay : 0} /></span></h4>

                                        </div>
                                        {/*
                                    <div className="col cursorPointer">
                                        <span className="f13"><i className="icofont icofont-social-google-map f24 txt-danger"></i><br /> GPS Data Not Available</span>
                                        <h4 className="txt-secondary f40">

                                            <span className="counter"  onClick={this.onClickCounter.bind(this,"no_gps_data")}>

                                                <CountUp className="txt-danger" end={(this.state.no_gps_data_available != '')?this.state.no_gps_data_available:0}/>
                                            </span>
                                            <span className="f24">&nbsp;/&nbsp;</span>
                                            <span className="counter" onClick={this.onClickCounter.bind(this,"no_gps_data_lifetime")}>
                                                <CountUp  className="txt-secondary" end={(this.state.nogpslifetime != '')?this.state.nogpslifetime:0}/>
                                            </span>

                                        </h4>

                                    </div>
                                    */}


                                        {/* <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"no_gps_data")}>
                                        <span className="f13"><i className="icofont icofont-social-google-map f24 txt-secondary"></i><br /> GPS Data NA</span>
                                        <h4 className="txt-secondary f40"><span className="counter"><CountUp end={(this.state.no_gps_data_available != '')?this.state.no_gps_data_available:0}/></span></h4>

                                    </div> */}

                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "overspeeding_exception")}>
                                            <span className="f13"><i className="fa fa-tachometer f22 txt-success"></i><br /> Overspeeding</span>
                                            <h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.overspeeding != '') ? this.state.overspeeding : 0} /></span></h4>

                                        </div>
                                        {(this.props.deptcode == 'LOG-PRT') ? "" :
                                            <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "nightdriving_exception")}>
                                                <span className="f13"><i className="icofont icofont-full-night f24 txt-primary"></i><br /> Night Driving</span>
                                                <h4 className="txt-primary f40"><span className="counter"><CountUp end={(this.state.night_driving != '') ? this.state.night_driving : 0} /></span></h4>

                                            </div>
                                        }
                                        {(this.props.deptcode == 'LOG-PRT') ? "" :
                                            <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "loading_delay_exception")} >
                                                <span className="f13"><i className="icofont icofont-truck-loaded f24 darkorange"></i><br /> Loading Delay</span>
                                                <h4 className="darkorange f40"><span className="counter"><CountUp end={(this.state.loading_delay != '') ? this.state.loading_delay : 0} /></span></h4>
                                            </div>
                                        }
                                        {(this.props.deptcode == 'LOG-PRT' || (this.props.unloadshow == true)) ? "" :
                                            <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "unloading_delay_exception")} >
                                                <span className="f13"><i className="icofont icofont-vehicle-delivery-van f24 mildgreen"></i><br /> Unloading Delay</span>
                                                <h4 className="mildgreen f40"><span className="counter"><CountUp end={(this.state.unloading_delay != '') ? this.state.unloading_delay : 0} /></span></h4>
                                            </div>
                                        }
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "enroute_exception")}>
                                            <span className="f13"><i className="icofont icofont-pause f22 maroon"></i><br /> Stoppages</span>
                                            <h4 className="maroon f40"><span className="counter"><CountUp end={(this.state.enroute_stoppage != '') ? this.state.enroute_stoppage : 0} /></span></h4>
                                        </div>
                                        {(this.props.deptcode.includes("PRT")) ?                                        
                                                <div className="col cursorPointer">
                                                    <span className="f13"><i className="icofont icofont-map-pins f22 txt-danger"></i><br />Deviated Routes</span>
                                                    <h4 className="txt-secondary f40">
                                                        <span className="counter" onClick={this.onClickCounter.bind(this, "deviation")}>
                                                            <CountUp className="txt-danger" end={(this.state.deviated_routes != '' && this.state.deviated_routes != undefined) ? this.state.deviated_routes.length : 0} />
                                                        </span>
                                                    </h4>
                                                </div>                                           
                                            : ""}

                                        {(this.props.priority_counter_display == 1) ?
                                            <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "is_priority")}>
                                                <span className="f13"><i className="icofont icofont-check f22 green"></i><br /> Priority</span>
                                                <h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.is_priority != '') ? this.state.is_priority : 0} /></span></h4>

                                            </div>
                                            : ""}

                                        {(this.props.path == "/prtallconsignments" || this.props.path == "/prtdeliveredconsignments") ?
                                            <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "cancelled")}>
                                                <span className="f13"><i className="fa fa-window-close-o f22 maroon"></i><br /> Cancelled</span>
                                                <h4 className="maroon f40"><span className="counter"><CountUp end={(this.state.cancelledCount != '') ? this.state.cancelledCount : 0} /></span></h4>
                                            </div>
                                            : ""}
                                            {(this.props.path == "/prtdeliveredconsignments") ?
                                            <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "force_closed")}>
                                                <span className="f13"><i className="fa fa-ban f22 maroon"></i><br /> Force closed</span>
                                                <h4 className="maroon f40"><span className="counter"><CountUp end={(this.state.forceClosed != '') ? this.state.forceClosed : 0} /></span></h4>
                                            </div>
                                            : ""}
                                        {
                                            (this.props.path == '/prtconsignments') ?
                                            <>
                                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "no_gate_out_time")}>
                                                    <span className="f13"><i className="fa fa-ban f22 maroon"></i><br /> No GateOut Time</span>
                                                    <h4 className="maroon f40"><span className="counter"><CountUp end={(this.state.withoutTruckGateOutTimeData != '') ? this.state.withoutTruckGateOutTimeData : 0} /></span></h4>
                                                </div>
                                                <div className="col cursorPointer">
                                                    <span className="f13"><i className="icofont icofont-social-google-map f24 txt-danger"></i><br /> GPS Not Available</span>
                                                    <h4 className="txt-secondary f40">

                                                        <span className="counter" onClick={this.onClickCounter.bind(this, "no_gps_data")}>

                                                            <CountUp className="txt-danger" end={(this.state.no_gps_data_available != '') ? this.state.no_gps_data_available : 0} />
                                                        </span>
                                                        {/* <span className="f24">&nbsp;/&nbsp;</span>
                                                        <span className="counter" onClick={this.onClickCounter.bind(this, "no_gps_data_lifetime")}>
                                                            <CountUp className="txt-secondary" end={(this.state.nogpslifetime != '') ? this.state.nogpslifetime : 0} />
                                                        </span> */}
                                                    </h4>
                                                </div>
                                            </>
                                                 : ''
                                        }

                                        {
                                    /*(this.props.path == "/prtallconsignments" || this.props.path == "/prtconsignments"
                                        || this.props.path == "/prtdeliveredconsignments")?
                                    // <div className="">

                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"pod")}>
                                            <span className="f13"><i className="icofont icofont-vehicle-delivery-van cus-i f22 txt-success"></i><br /> POD Received</span>
                                            <h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.pod_count != '')?this.state.pod_count:0}/></span></h4>

                                        </div>
                                    // </div>
                                    :""
                                    */}
                                        {/* {(this.props.path == "/prtallconsignments"
                                        || this.props.path == "/prtdeliveredconsignments")?
                                    // <div className="">

                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"trip_closed")}>
                                            <span className="f13"><i className="icofont icofont-vehicle-delivery-van cus-i f22 txt-warning"></i><br /> Trip Closed By POD</span>
                                            <h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.trip_closed_by_pod_count != '')?this.state.trip_closed_by_pod_count:0}/></span></h4>

                                        </div>
                                    // </div>
                                    :""} */}
                                        {
                                     /*(this.props.path == "/prtallconsignments" || this.props.path == "/prtconsignments"
                                        || this.props.path == "/prtdeliveredconsignments")?
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"truck_reporting_date")}>
                                        <span className="f13"><i className="icofont icofont-vehicle-delivery-van cus-i f22 txt-warning"></i><br /> Trucks Reported</span>
                                        <h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.truckReportedDate != '')?this.state.truckReportedDate:0}/></span></h4>

                                    </div>
                                    :""
                                    */}

                                    </div>
                                    :
                                    <div className="row">
                                        <div className="col cirlce-d cursorPointer" onClick={this.onClickCounter.bind(this, "all")}>
                                            <span className="f13"><i className="icofont icofont-ui-browser f24 greenfont"></i><br /> Consignments</span>
                                            <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.active_consignments != '') ? this.state.active_consignments : 0} /></span></h4>

                                        </div>

                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "transit_delay")}>
                                            <span className="f13"><i className="icofont icofont-clock-time f22 redfont"></i><br /> Transit Delays</span>
                                            <h4 className="txt-danger f40"><span className="counter"><CountUp end={(this.state.transit_delay != '') ? this.state.transit_delay : 0} /></span></h4>

                                        </div>
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "enroute_exception")}>
                                            <span className="f13"><i className="icofont icofont-pause f22 maroon"></i><br /> Stoppages</span>
                                            <h4 className="maroon f40"><span className="counter"><CountUp end={(this.state.enroute_stoppage != '') ? this.state.enroute_stoppage : 0} /></span></h4>
                                        </div>

                                        {/* <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"unloading_delay_exception")} >
                                        <span className="f13"><i className="icofont icofont-vehicle-delivery-van f24 mildgreen"></i><br /> Unloading Delay</span>
                                        <h4 className="mildgreen f40"><span className="counter"><CountUp end={(this.state.unloading_delay != '')?this.state.unloading_delay:0}/></span></h4>
                                    </div>

                                    {(this.props.counterScreen == "")?"":
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"is_critical")}>
                                            <span className="f13"><i className="icofont icofont-minus-circle f22 maroon"></i><br /> Critical</span>
                                            <h4 className="maroon f40"><span className="counter"><CountUp end={(this.state.is_critical != '')?this.state.is_critical:0}/></span></h4>
                                        </div>
                                    } */}


                                    </div>
                                }

                            </div>

                        </div>
                    </div>
                </div>

            </div>
        );
    }
}
