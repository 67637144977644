/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import ReactDOM from "react-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import store from '../../store/index'
import Select from "react-select";
import _, { toUpper } from "lodash";

import MarkerClusterer from "@google/markerclusterer";
import Modal from "react-responsive-modal";
import axios from "axios";
import { AgGridReact } from "@ag-grid-community/react";
import { AllModules } from "@ag-grid-enterprise/all-modules";
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import CustomLoadingCellRenderer from "./customLoadingCellRenderer";
import {
    getDDMMYYYY,
    getYYYYMMDD,
    getDDMMYYYYHHMMSS,
    getHyphenDDMMMYYYYHHMM,
    getHyphenDDMMMYYYY,
} from "../common/utils";
import "react-vertical-timeline-component/style.min.css";
import DrawMap from "../common/drawmap";
import ConsignmentActions from "../manage/ConsignmentActionsComponent";
import ParkingoutCarrier from "./parkingcarrierbtn";
import $ from "jquery";
// import "jquery-ui/ui/core";
import "jquery-ui/ui/widgets/datepicker";
import e from "jquery-datetimepicker";
var infoBox = require("../common/google-infowindow");
var googleAnalytics = require("../common/googleanalytics");

var redirectURL = require("../redirectURL");
var moment = require("moment");


export default class TransporterDealerMapping extends Component {
    state = {
        pageTitle: "",
        eventGridAction: "gridAction",
        eventCounterAction: "counterAction",
        eventFormAction: "formAction",
        uploadDivWidth: "0%",
        sliderTranslate: "",
        sliderRouteTranslate: "",
        tabsliderTranslate: "",
        loadshow: "show-n",
        showDiv: "show-n",
        alertshow: "fade",
        alertmg: "show-n",
        alerterrmg: "show-n",
        overly: "show-n",
        alerterrshow: "fade",
        alert: null,
        show: false,
        basicTitle: "",
        basicType: "default",
        show1: false,
        basicTitle1: "",
        basicType1: "default",
        modules: AllModules,
        sidebarSubHeader: "",
        defTransitCoords: "",
        defaultColDef: {
            sortable: true,
            filter: true,
            resizable: true,
        },
        rowData: [],

        rowModelhighchartoptionsType: "enterprise",
        rowGroupPanelShow: "always",
        overlayNoRowsTemplate: "",

        animateRows: true,
        debug: true,
        showToolPanel: false,
        uppressAggFuncInHeader: true,

        childconfs: [],
        childrow: [],
        rowModelType: "serverSide",
        paginationPageSize: 50,
        cacheBlockSize: 10,
        maxBlocksInCache: 1,
        statusBar: {
            statusPanels: [
                {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left",
                },
                {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center",
                },
                { statusPanel: "agFilteredRowCountComponent" },
                { statusPanel: "agSelectedRowCountComponent" },
                { statusPanel: "agAggregationComponent" },
            ],
        },
        sideBar: {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel",
                },
            ],
        },
        detailCellRendererParams: {},
        rowClassRules: {
            // "highlitRow": "data.transit_delay == 1",
            // "green":"data.status >= 3"
        },
        startDate: "",
        endDate: "",
        mapinfo: "",
        defaultsdate: "",
        defaultedate: "",
        withdrawmodal: false,
        parkingyard: "show-m",
        truckinfo: "show-n",
        pact: "btn-warning",
        tact: "btn-defaultc",
        truckslist: [],
        truck_no: { value: "", label: "Select Truck" },
        dept_code: "",
        transporters: [],
        transporter_code: { value: "", label: "Select Transporter" },
        struckslist: [],
        struck_no: { value: "", label: "Select Truck" },
        radius: "5000",
        rlatitude: "",
        rlongitude: "",
        latitude: "",
        longitude: "",
        defaultradius: 0,
        coordinates: [],
        alltrucks: [],
        search_truck_no: "",
        search_transporter_name: "",
        search_gps_provider: "",
        search_speed: "",
        search_last_packet_time: "",
        search_gps_status: "",
        search_address: "",
        search_city: "",
        search_state: "",
        search_carrier_reported: "",
        reporttruck: 0,
        plants: [],
        plant_code: { value: "", label: "Select Parking Yard" },
        withdrawdata: "",
        withdraw_reason: "",
        gpsplant: "",
        gpsradius: 15,
        mwidth: "col-xl-5 col-lg-5",
        rowData1: [],
        nsearch: "show-n",
        selectedTabLat: "",
        loginplant: "",
        filteredData: [],
        consignment_code: '',
        showTrucksList: "show-n",
        manual_parking_out: "",
        parkingdata: "",
        slideerParkingOut: "",
        selectedMonth: moment.parseZone(new Date()).format("YYYY-MM"),
        uptime_sheet_1: [],
        uptime_sheet_2: [],
        uptime_sheet_3: [],
        date_keys: [],
        overall_btn: "btn-success",
        monthly_btn: "btn-default",
        daywise_btn: "btn-default",
        overall_data: "show-m",
        monthly_data: "show-n",
        daywise_data: 'show-n',
        dealerOptions: [],
        transporterOptions: [],
        dealerValue: '',
        transporterValue: ''
    };



    onLoadData = () => {
        this.setState({
            loadshow: 'show-m',
            overly: 'show-m'
        })
        var params = {
        }
        redirectURL.post("/dashboard/getTransporterDealerDataIBL", params).then(response => {
            var records = response.data.records
            var dealerData = response.data.dealerData
            var transporterData = response.data.transporterData
            var dealerOptions = []
            dealerData.map((e) => {
                dealerOptions.push({ label: e.consignee_code, value: e.consignee_name })
            })
            var transporterOptions = []
            transporterData.map((e) => {
                transporterOptions.push({ label: e.transporter_name + "-" + e.transporter_code, value: e.transporter_code, key: e.transporter_name })
            })
            this.setState({
                rowData: records,
                loadshow: 'show-n',
                overly: 'show-n',
                dealerOptions: dealerOptions,
                transporterOptions: transporterOptions,
            })
        })
    }

    componentDidMount() {
        this.onLoadData()
    }

    onGridReady = (params) => {
        this.gridApi = params.api;
        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };

    onGridState = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    };

    onClickHideAll() {
        this.setState({
            loadshow: "show-n",
            overly: "show-n",
            sliderRouteTranslate: "",
            tabsliderTranslate: "",
            slideAddManualForm: "",
            slideerParkingOut: "",
            sliderTranslate: "",
            dealerValue: "",
            transporterValue: ""
        });
    }

    closeAlert = async () => {
        await this.setState({
            show: false,
        });
    };

    onClickShowSliderForTransporterAddition = () => {
        this.setState({
            sliderTranslate: "slider-translate-30p",
            loadshow: 'show-n',
            overly: 'show-m',
            showDiv: 'show-m',
        })

    }

    addDealerAndTransporterDetails = (e) => {
        e.preventDefault()
        if (this.state.dealerValue != "" && this.state.transporterValue != "") {
            var check = this.state.rowData.filter(e => e.consignee_code == this.state.dealerValue.label && e.transporter_code == this.state.transporterValue.value)
            if (check.length == 0) {
                let params = {
                    consignee_code: this.state.dealerValue.label,
                    consignee_name: this.state.dealerValue.value,
                    transporter_code: this.state.transporterValue.value,
                    transporter_name: this.state.transporterValue.key,
                    dept_code: "SNDG"
                }
                redirectURL.post("/dashboard/insertNewTransporter", params).then(response => {

                    if (response.data.status == "success") {
                        this.setState({
                            dealerValue: "",
                            transporterValue: ""
                        })
                        this.setState({
                            basicTitle: "Inserted Successfully.",
                            basicType: "success",
                            show: true
                        })
                    } else {
                        this.setState({
                            basicTitle: "Failed",
                            basicType: "warning",
                            show: true
                        })
                    }

                })
            }
            else {
                this.setState({
                    basicTitle: "Data Already Exists",
                    basicType: "warning",
                    show: true
                })
            }

        }
        else if (this.state.dealerValue == "" && this.state.transporterValue == "") {
            this.setState({
                basicTitle: "Please Select Dealer and Transporter",
                basicType: "warning",
                show: true
            })
        }
        else if (this.state.dealerValue == "") {
            this.setState({
                basicTitle: "Please Select Dealer",
                basicType: "warning",
                show: true
            })
        }
        else if (this.state.transporterValue == "") {
            this.setState({
                basicTitle: "Please Select Transporter",
                basicType: "warning",
                show: true
            })
        }

    }

    render() {

        var columnwithDefs = [
            {
                headerName: "Dealer Code",
                field: "consignee_code",
                // width:150,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Dealer Name",
                field: "consignee_name",
                // width:150,
                filter: true,
                resizable: true,
            },
            // {
            //     headerName: "Department Code",
            //     field: "dept_code",
            //     // width:150,
            //     filter: true,
            //     resizable: true,
            // },
            {
                headerName: "Transporter Name",
                field: "transporter_name",
                // width:150,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Transporter Code",
                field: "transporter_code",
                // width:150,
                filter: true,
                resizable: true,
            },
        ]

        return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                ></SweetAlert>
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="mb-4">
                                    Transporter Dealer Mapping
                                    <button onClick={this.onClickShowSliderForTransporterAddition} className={"btn btn-warning float-right"}>Map Transporter Dealer</button>
                                </h5>
                                <div
                                    id="myGrid"
                                    style={{ height: "580px", width: "100%" }}
                                    className={"ag-theme-balham "}
                                >
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        frameworkComponents={this.state.frameworkComponents}
                                        detailCellRendererParams={
                                            this.state.detailCellRendererParams
                                        }
                                        //editType={editType}
                                        stopEditingWhenGridLosesFocus={true}
                                        //floatingFilter={true}
                                        //enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        rowClassRules={this.state.rowClassRules}
                                        //onCellClicked={this.onRowClicked.bind(this)}
                                        gridOptions={{
                                            context: { componentParent: this },
                                        }}
                                        masterDetail={true}
                                        overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                    />
                                </div>
                                <div className={"slide-r " + (this.state.sliderTranslate)} >
                                    <div className="slide-r-title">
                                        <h4>
                                            Map Dealer Transporter
                                            <span className="float-right fclose" style={{ marginRight: "5px" }} onClick={this.onClickHideAll.bind(this)}>x</span>

                                        </h4>
                                    </div>
                                    <div className="slide-r-body" style={{ position: "relative" }}>
                                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                                            <form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.addDealerAndTransporterDetails}>
                                                <div className="form-group mt-20p">
                                                    <label className="">Dealer</label>
                                                    <div className="col-lg-12 col-xl-12 p-0">
                                                        <Select
                                                            className="hdrop"
                                                            isMulti={false}
                                                            id="dealer"
                                                            style={{ borderRadius: "0px" }}
                                                            value={this.state.dealerValue}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    dealerValue: e
                                                                })
                                                            }}
                                                            options={this.state.dealerOptions}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group mt-20p">
                                                    <label className="">Transporter</label>
                                                    <div className="col-lg-12 col-xl-12 p-0">
                                                        <Select
                                                            className="hdrop"
                                                            isMulti={false}
                                                            id="transporter"
                                                            style={{ borderRadius: "0px" }}
                                                            value={this.state.transporterValue}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    transporterValue: e
                                                                })
                                                            }}
                                                            options={this.state.transporterOptions}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-xl-12 col-lg-12 form-group">
                                                    <button type="submit" className="btn btn-success">Submit</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={"overlay-part " + this.state.overly}
                    onClick={this.onClickHideAll.bind(this)}
                ></div>
                <div className={"dataLoadpage " + this.state.loadshow}></div>
                <div className={"dataLoadpageimg " + this.state.loadshow}>
                    <div class="loader-box">
                        <div class="loader-box">
                            <div class="rotate dashed colored"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}