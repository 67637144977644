'use strict';

import React, { Component } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';

import { AllModules } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import CellGraphComponent from './cellGraphComponent';
import $ from 'jquery';
import Consignmentforceclose from '../manage/consignmentforceclose';
import ColumnGroup from './columngroupComponent';
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var CELL_DIMENSION_SIZE = 90;
export default class DashboardTickets extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // modules: [
      //   ClientSideRowModelModule,
      //   RowGroupingModule,
      //   MenuModule,
      //   ColumnsToolPanelModule,
      //   FiltersToolPanelModule,
      //   SetFilterModule,
      //   AllModules
      // ],

			loadshow:'show-n',
			overly:'show-n',
      modules: AllModules,
      defaultColDef: {
        //flex: 1,
        minWidth: 50,
        filter: true,
        sortable: true,
      },
      sortingOrder: ['asc', 'asc', null],
      autoGroupColumnDef: {
        headerName: 'Department',
        field: 'dept_name',
        minWidth: 50,
        width:240
      },
      rowData: null,
      popupParent: document.body,
      groupHeaderHeight: 50,
      headerHeight: 70,
      floatingFiltersHeight: 50,
      pivotGroupHeaderHeight: 30,
      pivotHeaderHeight: 50,
      rowHeight:50,
      startDate:'',
      endDate:'',
      frameworkComponents:{
        cellGraphComponent:CellGraphComponent
      },
      defaultGroupSortComparator: function(nodeA, nodeB) {
        if (parseInt(nodeA.key) < parseInt(nodeB.key)) {
            return -1;
          } else if (parseInt(nodeA.key) > parseInt(nodeB.key)) {
            return 1;
          } else {
            return 0;
          }
       
      },
    };
  }

	componentDidMount(){
    var defaultdate = moment.parseZone().subtract(1, 'days').format('YYYY-MM-DD');
    this.setState({
      startDate:defaultdate,
      endDate:defaultdate,
      loadshow:'show-m',
      overly:'show-m',
    });
    var reqparms = {
      startDate:defaultdate,
      endDate:defaultdate
    }
        redirectURL.post("/consignments/dashboardtemplatedata", reqparms)
        .then((response) => {
          var recordsarr = response.data.message;
          var records=[];
          recordsarr.map((item) => {
            var zerodays;
            var onedays;
            var twodays;
            var activezerodays;
            var activeonedays;
            var activetwodays;
            //console.log("item.transit_delay_days ", typeof parseInt(item.transit_delay_days))
             // if(parseInt(item.transit_delay_days) == 0 || 
            // parseInt(item.transit_delay_days) == 1 ||
            // parseInt(item.transit_delay_days) == 2)
            // {
              var set;
              var status;
              var activeset;
              var consoletxt = parseInt(item.transit_delay_days)+" "+item.dept_name+" "+item.status+" "+item.set
              //console.log("container Imports ", consoletxt)
                if(parseInt(item.transit_delay_days) == 0 && parseInt(item.set) == 1 && (parseInt(item.status) == 2 || item.status == "In ICD Ghari"))
                {
                      zerodays=1
                }
      
                if(parseInt(item.transit_delay_days) == 1 && parseInt(item.set) == 1 && (parseInt(item.status) == 2 || item.status == "In ICD Ghari"))
                {
                      onedays=1
                }
      
                if(parseInt(item.transit_delay_days) == 2 && parseInt(item.set) == 1 && (parseInt(item.status) == 2 || item.status == "In ICD Ghari"))
                {
                      twodays=1
                }

                if(parseInt(item.set) == 1 && parseInt(item.status) != -1)
                {
                    set=1;
                }
                else{
                    set=0;
                }
                if(parseInt(item.status) != -1)
                {
                  if(parseInt(item.status) == 2 && parseInt(item.set) == 1)
                  {
                      status=1;
                  }
                  else{
                      status=0;
                  }
                }
                if(parseInt(item.set) == 2 && parseInt(item.status) != -1)
                {
                    activeset=1;
                }
                else{
                  activeset=0;
                }

                
                if(parseInt(item.transit_delay_days) == 0 && parseInt(item.set) == 2 && (parseInt(item.status) == 2 || item.status == "In ICD Ghari"))
                {
                      activezerodays=1
                }
      
                if(parseInt(item.transit_delay_days) == 1 && parseInt(item.set) == 2 && (parseInt(item.status) == 2 || item.status == "In ICD Ghari"))
                {
                    activeonedays=1
                }
      
                if(parseInt(item.transit_delay_days) == 2 && parseInt(item.set) == 2 && (parseInt(item.status) == 2 || item.status == "In ICD Ghari"))
                {
                    activetwodays=1
                }
                //console.log("item.delivery_order ", item.delivery_order)
                if(item.dept_name != "-1" && item.dept_name != "NA")
                {
                  var itemdata= {
                    "consigner_code": item.consigner_code,
                    "consignment_code": item.consignment_code,
                    "dept_code": item.dept_code,
                    "dept_name": item.dept_name,
                    "invoice_date": item.invoice_date,
                    "transit_delay_days_0": (typeof zerodays == 'undefined' || zerodays == -1)?0:zerodays,
                    "transit_delay_days_1": (typeof onedays == 'undefined' || onedays == -1)?0:onedays,
                    "transit_delay_days_2": (typeof twodays == 'undefined' || twodays == -1)?0:twodays,
                    "uom": item.uom,
                    "delivery_order": item.delivery_order,
                    "set": (typeof set == 'undefined')?0:set,
                    "status": (typeof status == 'undefined')?0:status,
                    "activeset": (typeof activeset == 'undefined')?0:activeset,
                    "active_transit_delay_days_0": (typeof activezerodays == 'undefined' ||   activezerodays == -1)?0:activezerodays,
                    "active_transit_delay_days_1": (typeof activeonedays == 'undefined' || activeonedays == -1)?0:activeonedays,
                    "active_transit_delay_days_2": (typeof activetwodays == 'undefined' || activetwodays == -1)?0:activetwodays,
                };
                records.push(itemdata)
              }
                
            
            
            
          })
          //console.log("Respons ", JSON.stringify(recordsarr))
          //updateData(records);
          this.setState({
            rowData:records,
            loadshow:'show-n',
            overly:'show-n',
          });
        })
        .catch(function(e){
          console.log("Error ", e)
          
        })
        
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

  };

  expandAll = () => {
    this.gridApi.expandAll();
  };

  collapseAll = () => {
    this.gridApi.collapseAll();
  };

  groupRowAggNodes = nodes => {
    var result = {
        transit_delay_days_0: 0,
        transit_delay_days_1: 0,
        transit_delay_days_2: 0,
        active_transit_delay_days_0: 0,
        active_transit_delay_days_1: 0,
        active_transit_delay_days_2: 0,
        set: 0,
        status: 0,
        activeset:0
    };
    var st=[];
    //console.log("se ",nodes)
    nodes.forEach(function(node) {
      var data = node.group ? node.aggData : node.data;
   // console.log("node ",data)
     // console.log("nodedata ",typeof data.active_transit_delay_days_0)
      if (typeof data.transit_delay_days_0 === 'number') {
        result.transit_delay_days_0 += data.transit_delay_days_0;
      }
      if (typeof data.transit_delay_days_1 === 'number') {
        result.transit_delay_days_1 += data.transit_delay_days_1;
      }
      if (typeof data.transit_delay_days_2 === 'number') {
        result.transit_delay_days_2 += data.transit_delay_days_2;
      }
      
      if (typeof data.set === 'number') {
        if(data.set != -1)
        {
          result.set += data.set;
        }
      }
      if (typeof data.activeset === 'number') {
        if(data.activeset != -1)
        {
          result.activeset += data.activeset;
        }
      }
      if (typeof data.status === 'number') {
        if(data.status != -1 && data.status != 0)
        {
          result.status += data.status;
          st.push("a")
        }
        
      }

      if (typeof data.active_transit_delay_days_0 === 'number') {
        result.active_transit_delay_days_0 += data.active_transit_delay_days_0;
      }
      if (typeof data.active_transit_delay_days_1 === 'number') {
        result.active_transit_delay_days_1 += data.active_transit_delay_days_1;
      }
      if (typeof data.active_transit_delay_days_2 === 'number') {
        result.active_transit_delay_days_2 += data.active_transit_delay_days_2;
      }
    });
    //console.log(st.length)
    //console.log("asda ", result)
    return result;
  };
  onFirstDataRendered = params => {
    console.log("onFIrst chart,", params)
    var createRangeChartParams = {
      // cellRange: {
      //   rowStartIndex: 0,
      //   rowEndIndex: 79,
      //   columns: ['dept_name', 'transit_delay_days_0', 'transit_delay_days_1', 'transit_delay_days_2'],
      // },
      cellRange: {
        rowStartIndex: params.firstRow,
        rowEndIndex: params.lastRow,
        //columns: ['dept_name', 'active_transit_delay_days_0', 'active_transit_delay_days_1', 'active_transit_delay_days_2'],
        columns: ['ag-Grid-AutoColumn', , 'transit_delay_days_0', 'transit_delay_days_1', 'transit_delay_days_2'],
      },
      chartType: 'groupedColumn',
      chartContainer: document.querySelector('#chart'),
      suppressChartRanges: true,
      aggFunc: 'sum',
      processChartOptions: function(params) {
        var opts = params.options;
        //console.log("opts",opts)
        opts.title.enabled = true;
        opts.title.text = 'Delay Meter for selected period';
        opts.legend.position = 'top';
        opts.legend.enabled = true;
        //opts.axes[0].title.enabled = false;
        opts.yAxis.label = {
            rotation:90,
          formatter: function (params) {
              // if the data contains values that are not valid numbers,
              // the formatter's `value` will be `undefined`
              return params.value === undefined ? '' : params.value.toFixed(0);
          }
      };
        opts.seriesDefaults.tooltip.enabled = true;
        opts.seriesDefaults.fill.colors = ['#5defb6', '#feef94', '#ff90a3'];
        opts.seriesDefaults.stroke.colors = [ '#5a7088', '#7f637a', '#5d8692'];

        opts.seriesDefaults.tooltip.renderer = function(params) {
          var titleStyle = params.color
            ? ' style="color: #333; background-color:' + params.color + '"'
            : '';
            //console.log("params.title ", params.title)
          var title = params.title
            ? '<div class="ag-chart-tooltip-title"' +
              titleStyle +
              '>' +
              params.title +
              '</div>'
            : '';
          var value = params.datum[params.yKey]
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
          return (
            title +
            '<div class="ag-chart-tooltip-content" style="text-align: center">' +
            value +
            '</div>'
          );
        };
        if (opts.xAxis) {
          opts.xAxis.label.rotation = 0;
        }
       // console.log("opts.yAxis ", opts.yAxis)
        if (opts.yAxis) {
          opts.yAxis.label.rotation = 0;
        }
        return opts;
      }
    };

    //console.log("Chart from Start ", createRangeChartParams)
    params.api.createRangeChart(createRangeChartParams);


    var createRangeChartParams1 = {
      cellRange: {
        //rowStartIndex: 0,
        //rowEndIndex: 79,
        //columns: ['dept_name', 'active_transit_delay_days_0', 'active_transit_delay_days_1', 'active_transit_delay_days_2'],
        columns: ['ag-Grid-AutoColumn', , 'active_transit_delay_days_0', 'active_transit_delay_days_1', 'active_transit_delay_days_2'],
      },
      chartType: 'groupedColumn',
      chartContainer: document.querySelector('#chartactive'),
      //suppressChartRanges: true,
      aggFunc: 'sum',
      processChartOptions: function(params) {
        var opts = params.options;
        //console.log("opts",opts)
        opts.title.enabled = true;
        opts.title.text = 'Delay Meter as on Date (Cummulative)';
        opts.legend.position = 'top';
        opts.legend.enabled = true;
        opts.yAxis.title.enabled = true;
       // opts.yAxis.title.text = 'Rating';
        opts.yAxis.label = {
          formatter: function (params) {
              // if the data contains values that are not valid numbers,
              // the formatter's `value` will be `undefined`
              return params.value === undefined ? '' : params.value.toFixed(0);
          }
      };
        //opts.axes[0].title.enabled = false;
       // opts.yAxis.label.formatter = yAxisLabelFormatter;
        opts.seriesDefaults.tooltip.enabled = true;
        opts.seriesDefaults.fill.colors = ['#5defb6', '#feef94', '#ff90a3'];
        opts.seriesDefaults.stroke.colors = [ '#5a7088', '#7f637a', '#5d8692'];

        opts.seriesDefaults.tooltip.renderer = function(params) {
          var titleStyle = params.color
            ? ' style="color: #333; background-color:' + params.color + '"'
            : '';
            //console.log("params.title ", params.yKey)
          var title = params.title
            ? '<div class="ag-chart-tooltip-title"' +
              titleStyle +
              '>' +
              params.title +
              '</div>'
            : '';
          var value = params.datum[params.yKey]
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
          return (
            title +
            '<div class="ag-chart-tooltip-content" style="text-align: center">' +
            value +
            '</div>'
          );
        };
        if (opts.xAxis) {
          opts.xAxis.label.rotation = 0;
        }
       // console.log("opts.yAxis ", opts.yAxis)
        if (opts.yAxis) {
          opts.yAxis.label.rotation = 0;
        }
        return opts;
      }
    };

    //console.log("Chart from Expand ", createRangeChartParams1)
    params.api.createRangeChart(createRangeChartParams1);

  
      
  };

	handlerStartDateTime = (event, currentDate, selectedDate) => {
    var d = new Date(event._d);
    
    var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    
    this.setState({
      startDate:startdate
    });
    //let value = event.target.value;
      //this.setState({'startDate':value});
  }

  handlerEndDateTime  = (event, currentDate, selectedDate) => {
    var d = new Date(event._d);
    
      var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate())
      this.setState({
      endDate:edate
    });
}
  onClickSummaryData(event){
    this.setState({
      loadshow:'show-m',
      overly:'show-m',
    });
    var reqparms = {
      startDate:this.state.startDate,
      endDate:this.state.endDate
    }
        redirectURL.post("/consignments/dashboardtemplatedata", reqparms)
        .then((response) => {
          var recordsarr = response.data.message;
          var records=[];
          recordsarr.map((item) => {
            var zerodays;
            var onedays;
            var twodays;
            var activezerodays;
            var activeonedays;
            var activetwodays;
            //console.log("item.transit_delay_days ", typeof parseInt(item.transit_delay_days))
             // if(parseInt(item.transit_delay_days) == 0 || 
            // parseInt(item.transit_delay_days) == 1 ||
            // parseInt(item.transit_delay_days) == 2)
            // {
              var set;
              var status;
              var activeset;
              var consoletxt = parseInt(item.transit_delay_days)+" "+item.dept_name+" "+item.status+" "+item.set
             // console.log("container Imports ", consoletxt)
                if(parseInt(item.transit_delay_days) == 0 && parseInt(item.set) == 1 && (parseInt(item.status) == 2 || item.status == "In ICD Ghari"))
                {
                      zerodays=1
                }
      
                if(parseInt(item.transit_delay_days) == 1 && parseInt(item.set) == 1 && (parseInt(item.status) == 2 || item.status == "In ICD Ghari"))
                {
                      onedays=1
                }
      
                if(parseInt(item.transit_delay_days) == 2 && parseInt(item.set) == 1 && (parseInt(item.status) == 2 || item.status == "In ICD Ghari"))
                {
                      twodays=1
                }

                if(parseInt(item.set) == 1 && parseInt(item.status) != -1)
                {
                    set=1;
                }
                else{
                    set=0;
                }
                if(parseInt(item.status) != -1)
                {
                  if(parseInt(item.status) == 2 && parseInt(item.set) == 1)
                  {
                      status=1;
                  }
                  else{
                      status=0;
                  }
                }
                if(parseInt(item.set) == 2 && parseInt(item.status) != -1)
                {
                    activeset=1;
                }
                else{
                  activeset=0;
                }


                if(parseInt(item.transit_delay_days) == 0 && parseInt(item.set) == 2 && (parseInt(item.status) == 2 || item.status == "In ICD Ghari"))
                {
                      activezerodays=1
                }
      
                if(parseInt(item.transit_delay_days) == 1 && parseInt(item.set) == 2 && (parseInt(item.status) == 2 || item.status == "In ICD Ghari"))
                {
                    activeonedays=1
                }
      
                if(parseInt(item.transit_delay_days) == 2 && parseInt(item.set) == 2 && (parseInt(item.status) == 2 || item.status == "In ICD Ghari"))
                {
                    activetwodays=1
                }
                //console.log("item.dept_name ", item.dept_name)
                if(item.dept_name != "-1" && item.dept_name != "NA")
                {
                  var itemdata= {
                    "consigner_code": item.consigner_code,
                    "consignment_code": item.consignment_code,
                    "dept_code": item.dept_code,
                    "dept_name": item.dept_name,
                    "invoice_date": item.invoice_date,
                    "transit_delay_days_0": (typeof zerodays == 'undefined' || zerodays == -1)?0:zerodays,
                    "transit_delay_days_1": (typeof onedays == 'undefined' || onedays == -1)?0:onedays,
                    "transit_delay_days_2": (typeof twodays == 'undefined' || twodays == -1)?0:twodays,
                    "uom": item.uom,
                    "delivery_order": item.delivery_order,
                    "set": (typeof set == 'undefined')?0:set,
                    "status": (typeof status == 'undefined')?0:status,
                    "activeset": (typeof activeset == 'undefined')?0:activeset,
                    "active_transit_delay_days_0": (typeof activezerodays == 'undefined' ||   activezerodays == -1)?0:activezerodays,
                    "active_transit_delay_days_1": (typeof activeonedays == 'undefined' || activeonedays == -1)?0:activeonedays,
                    "active_transit_delay_days_2": (typeof activetwodays == 'undefined' || activetwodays == -1)?0:activetwodays,
                };
                records.push(itemdata)
              }
                
            
            
            
          })
          //console.log("Respons ", records)
          //updateData(records);
          this.setState({
            rowData:records,
            loadshow:'show-n',
            overly:'show-n',
          });
        })
        .catch(function(e){
          console.log("Error ", e)
          
        })
        
  }

  
  getChartToolbarItems = params => {
    return [];
  };
  onRowGroupOpened = event => {
    console.log("checkc ", event.node)
    
    //console.log("sss ", event.node.rowIndex)
    //console.log("le ",event.node.childrenAfterGroup.length)
    if(event.node.expanded == true)
    {
      //console.log("t")
      var start = event.node.rowIndex+1
      var end = (start+event.node.childrenAfterGroup.length)-1;
    }
    else
    {
      //console.log("f")
      // var start = event.node.rowIndex
      // var end = start+event.node.childrenAfterGroup.length;

      if(event.node.rowIndex == 0)
      {
        var start = event.node.rowIndex
        var end = 0;
      }
      else
      {
        var start = event.node.rowIndex
        var end = (start+event.node.childrenAfterGroup.length);
      }
     
    }
    
    
    console.log(start+", "+end)
    var createRangeChartParams = {
      // cellRange: {
      //   rowStartIndex: start,
      //   rowEndIndex: end,
      //   columns: ['dept_name', 'transit_delay_days_0', 'transit_delay_days_1', 'transit_delay_days_2'],
      // },
      cellRange: {
        rowStartIndex: start,
        rowEndIndex: end,
        //columns: ['dept_name', 'active_transit_delay_days_0', 'active_transit_delay_days_1', 'active_transit_delay_days_2'],
        columns: ['ag-Grid-AutoColumn', , 'transit_delay_days_0', 'transit_delay_days_1', 'transit_delay_days_2'],
      },
      chartType: 'groupedColumn',
      chartContainer: document.querySelector('#chart'),
      //suppressChartRanges: true,
      aggFunc: 'sum',
      label: {
          formatter: function (params) {
              // if the data contains values that are not valid numbers,
              // the formatter's `value` will be `undefined`
              return params.value === undefined ? '' : params.value.toFixed(0);
          }
      },
      processChartOptions: function(params) {
        var opts = params.options;
        //console.log("opts",opts)
        opts.title.enabled = true;
        opts.title.text = 'Delay Meter for selected period';
        opts.legend.position = 'top';
        opts.legend.enabled = true;
        opts.axes = [{type: 'category',  position: 'bottom'}];
       // opts.yAxis.label.formatter = yAxisLabelFormatter;
       opts.yAxis.label = {
        formatter: function (params) {
            // if the data contains values that are not valid numbers,
            // the formatter's `value` will be `undefined`
            return params.value === undefined ? '' : params.value.toFixed(0);
        }
    };
        opts.seriesDefaults.tooltip.enabled = true;
        opts.seriesDefaults.fill.colors = ['#5defb6', '#feef94', '#ff90a3'];
        opts.seriesDefaults.stroke.colors = [ '#5a7088', '#7f637a', '#5d8692'];

        opts.seriesDefaults.tooltip.renderer = function(params) {
          var titleStyle = params.color
            ? ' style="color: #333; background-color:' + params.color + '"'
            : '';
            //console.log("params.title ", params.title)
          var title = params.title
            ? '<div class="ag-chart-tooltip-title"' +
              titleStyle +
              '>' +
              params.title +
              '</div>'
            : '';
          var value = params.datum[params.yKey]
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
          return (
            title +
            '<div class="ag-chart-tooltip-content" style="text-align: center">' +
            value +
            '</div>'
          );
        };
        if (opts.xAxis) {
          opts.xAxis.label.rotation = 0;
        }
       // console.log("opts.yAxis ", opts.yAxis)
        if (opts.yAxis) {
          opts.yAxis.label.rotation = 0;
        }
        return opts;
      }
    };

    console.log("Chart from Expand ", createRangeChartParams)
    event.api.createRangeChart(createRangeChartParams);


    //Active consignments
    var createRangeChartParams1 = {
      // cellRange: {
      //   rowStartIndex: start,
      //   rowEndIndex: end,
      //   columns: ['dept_name', 'active_transit_delay_days_0', 'active_transit_delay_days_1', 'active_transit_delay_days_2'],
      // },
      cellRange: {
        //rowStartIndex: 0,
        //rowEndIndex: 79,
        //columns: ['dept_name', 'active_transit_delay_days_0', 'active_transit_delay_days_1', 'active_transit_delay_days_2'],
        columns: ['ag-Grid-AutoColumn', , 'active_transit_delay_days_0', 'active_transit_delay_days_1', 'active_transit_delay_days_2'],
      },
      chartType: 'groupedColumn',
      chartContainer: document.querySelector('#chartactive'),
      //suppressChartRanges: true,
      aggFunc: 'sum',
      processChartOptions: function(params) {
        var opts = params.options;
        //console.log("opts",opts)
        opts.title.enabled = true;
        opts.title.text = 'Delay Meter as on Date (Cummulative)';
        opts.legend.position = 'top';
        opts.legend.enabled = true;
        //opts.axes[0].title.enabled = false;
       // opts.yAxis.label.formatter = yAxisLabelFormatter;
       opts.yAxis.label = {
        formatter: function (params) {
            // if the data contains values that are not valid numbers,
            // the formatter's `value` will be `undefined`
            return params.value === undefined ? '' : params.value.toFixed(0);
        }
    };
        opts.seriesDefaults.tooltip.enabled = true;
        opts.seriesDefaults.fill.colors = ['#5defb6', '#feef94', '#ff90a3'];
        opts.seriesDefaults.stroke.colors = [ '#5a7088', '#7f637a', '#5d8692'];

        opts.seriesDefaults.tooltip.renderer = function(params) {
          var titleStyle = params.color
            ? ' style="color: #333; background-color:' + params.color + '"'
            : '';
            //console.log("params.title ", params.title)
          var title = params.title
            ? '<div class="ag-chart-tooltip-title"' +
              titleStyle +
              '>' +
              params.title +
              '</div>'
            : '';
          var value = params.datum[params.yKey]
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
          return (
            title +
            '<div class="ag-chart-tooltip-content" style="text-align: center">' +
            value +
            '</div>'
          );
        };
        if (opts.xAxis) {
          opts.xAxis.label.rotation = 0;
        }
       // console.log("opts.yAxis ", opts.yAxis)
        if (opts.yAxis) {
          opts.yAxis.label.rotation = 0;
        }
        return opts;
      }
    };

    console.log("Chart from Expand ", createRangeChartParams1)
    event.api.createRangeChart(createRangeChartParams1);



  }
  render() {
    //console.log("rowData ",this.state.rowData)
    var startdate = this.state.startDate;
    var enddate = this.state.endDate;
    let { startDate } = this.state;
    var consignmentgenerate = "Consignment Generated ( "+startDate+")";
    var columnDefs = [
      {
          headerClass:["cellTopHeaderTemplate"],
          headerName:"Department",	
          field: 'dept_name',
          rowGroup: true,
          width:300,
          cellClass:"cellTopHeaderTemplate16",
          //pivot:true,
          hide:true,
          suppressSorting:true,
          chartDataType: 'category',
          cellRendererParams: {
              suppressCount: true, // turn off the row count
          },
          // comparator:function(params){
          //   console.log("delivery_order ",params.data.delivery_order);
          //   if (parseInt(params.data.delivery_order) < parseInt(params.data.delivery_order)) {
          //     return -1;
          //   } else if (parseInt(params.data.delivery_order) > parseInt(params.data.delivery_order)) {
          //     return 1;
          //   } else {
          //     return 0;
          //   }
          // }
        },
        {
          field: 'consigner_code',
          rowGroup: true,
          cellClass:"cellTopHeaderTemplate14",
          hide:true,
          chartDataType: 'category',
        },
        {
          field: 'invoice_date',
          rowGroup: true,
          cellClass:"cellTopHeaderTemplate14",
          hide:true,
          chartDataType: 'category',
        },
        {
          field: 'consignment_code',
          rowGroup: true,
          cellClass:"cellTopHeaderTemplate14",
          hide:true,
          chartDataType: 'category',
        },
        {
          headerName: 'UOM',
          field: 'uom',
          width:120,
          cellClass:["cellTopHeaderTemplatenb"],
          chartDataType: 'excluded',
          valueGetter:function(params){
           // console.log(params.node.key);
            // if(typeof params.data == 'undefined')
            // {
            //   return "Trucks";
            // }
            // else{
              if(params.node.key == "Sales & Dispatch")
              {
                return "Trucks";
              }
              else if(params.node.key == "Spare Parts (Domestic)")
              {
                return "Consignments";
              }
              else if(params.node.key == "Spare Parts (Courier)")
              {
                return "Consignments";
              }
              else if(params.node.key == "Spare Parts (Container Exports)" || params.node.key == "Spares (Container Exports)")
              {
                return "Containers";
              }
              else if(params.node.key == "Production Parts")
              {
                return "Trucks";
              }
              else if(params.node.key == "Container Imports")
              {
                return "Containers";
              }
              else if(params.node.key == "Container Exports (KDO)")
              {
                return "Containers";
              }
              else if(params.node.key == "Coil Imports")
              {
                return "Coils";
              }
            // }
            
          }
          //hide:true
        },
        
        {
          headerName: "For This Period",
          headerClass:["cellTopHeaderTemplateH","cellstylegrid"],			
          children: [
                      {
                        headerName: "Total for selected period",
                        headerClass:["cellstylegrid"],	
                        field: "set",
                        cellClass:["cellTopHeaderTemplate16","cellstylegrid"],
                       // chartDataType: 'series',
                        width:110,
                        chartDataType: 'excluded',
                        //hide:true
                      },
                      {
                        headerName: "Active for selected period",
                        headerClass:["cellstylegrid"],	
                        field: "status",
                        width:110,
                        chartDataType: 'excluded',
                        cellClass:["cellTopHeaderTemplate16","cellstylegrid"],
                        //chartDataType: 'series',
                        //hide:true
                      },
                    ]
            },
            {
              headerName: "Delay Meter",
              headerClass:["cellTopHeaderTemplateH","cellstylegrid"],			
              children: [
                      {
                        headerClass:["cellTopHeaderTemplate14","cellstylegrid"],
                        headerName:"On Time",	
                        field: 'transit_delay_days_0',
                        width:90,
                        cellClass:["cellTopHeaderTemplate16","bgColorSuccessLight","cellstylegrid"],
                        chartDataType: 'series',
                      },
                      {
                        headerClass:["cellTopHeaderTemplate14","cellstylegrid"],
                        headerName:"1 Day",	
                        field: 'transit_delay_days_1',
                        width:80,
                        cellClass:["cellTopHeaderTemplate16","cellstylegridDY","cellstylegrid"],
                        chartDataType: 'series',
                      },
                      {
                        headerClass:["cellTopHeaderTemplate14","cellstylegrid"],
                        headerName:">1 Day",
                        field: 'transit_delay_days_2',
                        width:80,
                        cellClass:["cellTopHeaderTemplate16","bgColorDangerMedium","cellstylegrid"],
                        chartDataType: 'series',
                      }
            ]
        },
        

        {
          headerName: "Cummulative As On Date",
          headerClass:["cellTopHeaderTemplateH","cellstylegridB"],			
          children: [

                      {
                        headerClass:["cellstylegridB"],
                        headerName: "Active as on date (Cummulative)",
                        field: "activeset",
                        width:140,
                        cellClass:["cellTopHeaderTemplate16","cellstylegridB"],
                        chartDataType: 'category',
                        //hide:true
                      },

                      {
                        headerClass:["cellTopHeaderTemplate14","cellstylegridB"],
                        headerName:"On Time",	
                        field: 'active_transit_delay_days_0',
                        width:90,
                        cellClass:["cellTopHeaderTemplate16","bgColorSuccessLight","cellstylegridB"],
                        chartDataType: 'series',
                      },
                      {
                        headerClass:["cellTopHeaderTemplate14","cellstylegridB"],
                        headerName:"1 Day",	
                        field: 'active_transit_delay_days_1',
                        width:80,
                        cellClass:["cellTopHeaderTemplate16","cellstylegridDY","cellstylegridB"],
                        chartDataType: 'series',
                      },
                      {
                        headerClass:["cellTopHeaderTemplate14","cellstylegridB"],
                        headerName:">1 Day",
                        field: 'active_transit_delay_days_2',
                        width:80,
                        cellClass:["cellTopHeaderTemplate16","bgColorDangerMedium","cellstylegridB"],
                        chartDataType: 'series',
                      }
            ],
            
        },
        // {
        //   headerClass:["cellTopHeaderTemplate"],
        //   headerName:"order",
        //   width:150,
        //   field: 'delivery_order',
        //   sort:"asc",
        //   sortable:true,
        //   //hide:true
        // },
    ];
    return (
      <div style={{ width: '100%', height: '100%' }}>
       <div className="row col-xl-12 col-lg-12">
          
						<div className="col-xl-2 col-lg-2 form-group">
							<label>Select Date Period:<br />From Date</label>
							<Datetime value={this.state.startDate} disableCloseOnClickOutside={false} closeOnSelect={true} inputProps={{ placeholder: 'From', name: 'startDate', autoComplete:'off' }} dateFormat="YYYY-MM-DD"  name="startDate" onChange={this.handlerStartDateTime.bind(this)} />
						</div>

						<div className="col-xl-2 col-lg-2 form-group">
							<label><br />To Date</label>
							<Datetime value={this.state.endDate} disableCloseOnClickOutside={false} closeOnSelect={true} inputProps={{ placeholder: 'To', name: 'endDate', autoComplete:'off'}} dateFormat="YYYY-MM-DD" onChange={this.handlerEndDateTime.bind(this)} />
						</div>
						<div className="form-group col-xl-2 col-lg-2">
							<label><br />&nbsp;</label><br />
							<button type="button" className="btn btn-success" onClick={this.onClickSummaryData.bind(this)}>Submit</button>
						</div>
						
				</div>

        <div
          id="myGrid"
          style={{
            height: '550px',
            width: '100%'
          }}
          className="ag-theme-balham"
        >
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            defaultColDef={this.state.defaultColDef}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            sideBar={false}
            groupUseEntireRow={false}
            enableRangeSelection={true}
            groupRowAggNodes={this.groupRowAggNodes}
            onGridReady={this.onGridReady}
            rowData={this.state.rowData}
            groupHeaderHeight={this.state.groupHeaderHeight}
            headerHeight={this.state.headerHeight}
            floatingFiltersHeight={this.state.floatingFiltersHeight}
            pivotGroupHeaderHeight={this.state.pivotGroupHeaderHeight}
            pivotHeaderHeight={this.state.pivotHeaderHeight}
            rowHeight={this.state.rowHeight}
            frameworkComponents={this.state.frameworkComponents}
            popupParent={this.state.popupParent}
            enableCharts={true}
            processChartOptions={this.state.processChartOptions}
            onFirstDataRendered={this.onFirstDataRendered.bind(this)}
            getChartToolbarItems={this.getChartToolbarItems}
            suppressChartRanges={true}
            defaultGroupSortComparator={this.state.defaultGroupSortComparator}
            onRowGroupOpened={this.onRowGroupOpened.bind(this)}
            suppressCount={true}
            delivery_orderSort={true}
          />
          <div id="chart" style={{ float: 'left', overflow: 'hidden', width:"50%",height: '500px' }}></div>
          <div id="chartactive" style={{ float: 'left', overflow: 'hidden',width:"50%", height: '500px' }}></div>
           {/* <div id="chart2" style={{ flex: '1 1 auto', overflow: 'hidden', width: '50%' }}></div>
          <div id="chart3" style={{ flex: '1 1 auto', overflow: 'hidden', width: '50%' }}></div> */}
            <ColumnGroup 
            />
        </div>
        <div className={"dataLoadpage " +(this.state.loadshow)}></div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)}></div>
			
      </div>
    );
  }
}
function yAxisLabelFormatter(params) {
    var n = params.value;
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
}
// function BarChartLineRenderer() {
// }

// BarChartLineRenderer.prototype.init = function(params) {
//     var eGui = document.createElement('div');
//     this.eGui = eGui;

//     // sparklines requires the eGui to be in the dom - so we put into a timeout to allow
//     // the grid to complete it's job of placing the cell into the browser.
//     setTimeout(function() {
//       console.log(params.value);
//         var values = params.value
//             //.sort(function(a, b) { return a.transit_delay_days_0 - b.transit_delay_days_2; })
//             .map(function(datum) { return datum.AverageVolume.toFixed(); });
//         $(eGui).sparkline(values, {
//             type: 'bar',
//             barColor: 'green',
//             chartRangeMin: 1000000,
//             barWidth: 11,
//             height: CELL_DIMENSION_SIZE,
//             width: CELL_DIMENSION_SIZE
//         });
//     }, 0);
// };

// BarChartLineRenderer.prototype.getGui = function() {
//     return this.eGui;
// };