/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 

import Highcharts from 'highcharts';
import drilldown from 'highcharts-drilldown';
import HighchartsReact from 'highcharts-react-official';
import $ from 'jquery';
require("highcharts/highcharts-more")(Highcharts);

drilldown(Highcharts);
window.jQuery = $;
window.$ = $;

var redirectURL = require('../redirectURL');
var highchartoptions;
var criticaldata=[]
export default class DamagesColumnChart extends Component {

    constructor(props) {
		super(props);
		this.state = {
			showgrid:'display:none',
			showtransgrid:'display:none',
			rowdata:[],
			test:"TEstng",
			drilldowndata:[]
		}

	}
	componentDidMount = async () =>{
		
		$(".grid-"+this.props.code).hide();
		$(".grid-trans-"+this.props.code).hide();
		$(".dataLoadpagesum"+this.props.code).hide()
		$(".dataLoadpageimgsum"+this.props.code).hide()
		$(".overlay-partsum"+this.props.code).hide()
		
	}
	componentWillReceiveProps (newprops){
        //if(newprops.title != this.props.dept_code)
        //{
			this.setState({
				drilldowndata:newprops.drildown
			})
            //console.log("newprops ",newprops);
		//}

		
	}
	hideGrid(params){
		//console.log(params)
		hideGrid(params)
	}
	hideTransGrid(params){
		hideTransGrid(params)
    }
    
    render(){
        // console.log("categories ",this.props.categories);
        // console.log("newprops ",this.props.series);
        const dStyles={
				width:'100%',
				height:'100%'
		}
		var stacking = false
		if(this.props.title == "Rail Mode Dispatches")
		{
			stacking = "normal"
		}
		highchartoptions = {
            chart: {
                zoomType: 'xy'
            },
            title: {
                text: this.props.title
            },
            subtitle: {
                text: this.props.subtitle
            },
            xAxis: [{
                categories:this.props.categories,
                crosshair: true
            }],
            yAxis: [{ // Primary yAxis
                title: {
                    text: this.props.ytitle,
                    style: {
                        color: Highcharts.getOptions().colors[1]
                    }
                },labels: {
                    format: '{value}',
                    style: {
                        color: Highcharts.getOptions().colors[1]
                    }
                }
                
            }, { // Secondary yAxis
                title: {
                    text: this.props.xtitle,
                    style: {
                        color: Highcharts.getOptions().colors[0]
                    }
                },
                labels: {
                    format: '{value} ',
                    style: {
                        color: Highcharts.getOptions().colors[0]
                    }
                },
                opposite: true
			}],
			plotOptions: {
                column: {
                    stacking: stacking,
                    dataLabels: {
						// inside: true,
						// useHTML:true,
						// align: 'center',
                        enabled: true
					},
				},
				line:{
					dataLabels:{
						// inside: true,
						// useHTML:true,
						// align: 'center',
						enabled:true
					}
				}
			},
            tooltip: {
                formatter: function() {
                    return '<b>'+this.series.name+'</b> : '+this.point.name+'<br /><b>Total No. of Chasis : </b>'+ this.point.total_count +'<br/>'+
                            '<b>Total No. of Chasis Damage: </b>'+ this.point.damages_count +'<br/>'+
                            '<b>Damage %: </b>'+ this.y +'<br/>'
                }
            },
            // legend: {
            //     layout: 'vertical',
            //     align: 'left',
            //     x: 120,
            //     verticalAlign: 'top',
            //     y: 100,
            //     floating: true,
            //     backgroundColor:
            //         Highcharts.defaultOptions.legend.backgroundColor || // theme
            //         'rgba(255,255,255,0.25)'
			// },
			 colors:[ '#7f84e9', '#f35e82','#FF5370', '#f15c80', '#92A8CD','#A47D7C','#f3d800',  '#B5CA92','#2b908f', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
		
            series: this.props.series,
			credits:false
        };
        
        return ( 
			<div>
               <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} />
			</div>
			);
		}
}


function hideGrid(id)
{
	console.log("id ",id)
	$(".grid-"+id).fadeOut()
}

function hideTransGrid(id)
{
	console.log("id ",id)
	$(".gridtrans-"+id).fadeOut()
}


function GetSortOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
  } 

  function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}

function arrayMin(arr) {
	return arr.reduce(function (p, v) {
	  return ( p < v ? p : v );
	});
  }
  
  function arrayMax(arr) {
	return arr.reduce(function (p, v) {
	  return ( p > v ? p : v );
	});
  }

// function getData(){
// 	alert("cons")
// }

$(document).ready(function(){
	
})

$(document).on('click',".clicktransport", function(){
	var criticaldata=[];
	var transporter = $(this).attr("transporter")
	var deptcode = $(this).attr("deptcode");
	var transporterdata = $(this).attr("data")
	$(".dataLoadpagesum"+deptcode).show()
	$(".dataLoadpageimgsum"+deptcode).show()		
	$(".overlay-partsum"+deptcode).show()	
	var dept_code = $(this).attr("dept_code");
	var rqparams={
		dept_code:dept_code
	}
	//console.log("dept_code ",dept_code);
	if(dept_code == "LOG-PRT (Courier)")
	{
		var blocktitle='Consignment Code';
	}
	else{
		var blocktitle='Truck No';
	}
	redirectURL.post("/consignments/summarysecondleveldata",rqparams)
	.then(async (res) =>{
		criticaldata=res.data.message[0].critical_data;
		$(".dataLoadpagesum"+deptcode).hide()
		$(".dataLoadpageimgsum"+deptcode).hide()
		$(".overlay-partsum"+deptcode).hide();	
		var criticaldataarr=[]
		if(criticaldata.length > 0)
		{
			criticaldata.map((item) =>{
				if(dept_code == 'LOG-SC')
				{
					criticaldataarr.push({
						container_no:item.container_no,
						country_code:item.country_code,
						critical_msil_eta:item.critical_msil_eta,
						delay_reason:item.delay_reason,
						delay_type:item.delay_type,
						dept_code:item.dept_code,
						dept_name:item.dept_name,
						spoc_contact_name:item.spoc_contact_name,
						spoc_contact_number:item.spoc_contact_number,
						status:item.status,
						transit_delay_days:parseInt(item.transit_delay_days),
						transporters_name:item.transporters_name,
						supplier_code:(item.supplier_code == "")?"NA":item.supplier_code,
						uom:item.uom,
					})
				}
				else
				{
					criticaldataarr.push({
						consignee_city:item.consignee_city,
						consignee_code:item.consignee_code,
						consignee_name:item.consignee_name,
						consigner_code:item.consigner_code,
						consignment_code:item.consignment_code,
						delay_type:item.delay_type,
						dept_code:item.dept_code,
						dept_name:item.dept_name,
						delay_reason:item.delay_reason,
						expected_trip_end:item.expected_trip_end,
						invoice_date:item.invoice_date,
						plant_location:item.plant_location,
						spoc_contact_name:item.spoc_contact_name,
						spoc_contact_number:item.spoc_contact_number,
						status:parseInt(item.status),
						transit_delay_days:parseInt(item.transit_delay_days),
						transporter_name:item.transporter_name,
						transporters_name:item.transporters_name,
						uom:item.uom,
					})
				}
			})
		}
		//alert(transporter)
		//console.log("criticaldata here,", criticaldata)
		criticaldataarr.sort(GetSortOrder("transit_delay_days"));

		var filterdata=[]
		var containerimprt=[]
		if(criticaldataarr.length > 0)
		{
			criticaldataarr.map((item)=>{
				if(item.dept_code == 'LOG-SC')
				{
					console.log("Itemm ", item)
					if(item.transporters_name == transporter){
						containerimprt.push(item)
					}
				}
				else
				{
					if(item.transporter_name == transporter){
						filterdata.push(item)
					}
				}
			})
		}
		console.log("containerimprt ",containerimprt)
		var html = '<div class="col-xl-12 col-lg-12 mt-10p"><div class="col-xl-12 col-lg-12 n-p-0"><h4 class="summary-grid-title">'+transporter+'</h4></div>';
		if(filterdata.length > 0)
		{
			var i=0;
			filterdata.map((itm) =>{
				if(i%2 == 0)
				{
					var classtxt=''
				}
				else{
					var classtxt='oddbg'
				}
				html += '<div class="col-xl-12 col-lg-12 row form-group bordergray pl-0px pr-0px ml-0p">';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 summary-lbl"><span class="f11">'+blocktitle+'</span><br /><span class="f16">'+itm.consignment_code+'</span></label></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 summary-lbl"><span class="f11">Loading Plant</span><br /><span class="f16">'+itm.consigner_code+'</span></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray  n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">Consignee Name</span><br /><span class="f16">'+itm.consignee_name+'</span></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">Destination City</span><br /><span class="f16">'+itm.consignee_city+'</span></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">Original ETA</span><br /><span class="f16">'+getDDMMYYYY(itm.expected_trip_end)+'</span></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">Transit Delay (Days)</span><br /><span class="f16">'+itm.transit_delay_days+'</span></div>';
				// html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">SPOC Name</span><br /><span class="f16">'+itm.spoc_contact_name+'</span></div>';
				// html += '<div class="col-xl-6 col-lg-6 brd-right n-p-0 f16 brd-btm-gray  textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">SPOC Contact Number</span><br /><span class="f16">'+itm.spoc_contact_number+'</span></div>';
				html += '<div class="col-xl-12 col-lg-12 brd-right n-p-0 f16"><label class="col-xl-12 col-lg-12 "><span class="f11">Remarks</span><br /><span class="f14">'+itm.delay_reason+'</span></div>';
				html += '</div>';
				i=i+1
			})
		}

		/*LOG-SC Container Imports Critical Delay view*/
		if(containerimprt.length > 0)
		{
			var n=0;
			containerimprt.map((itm) =>{
				if(n%2 == 0)
				{
					var classtxt=''
				}
				else{
					var classtxt='oddbg'
				}
				html += '<div class="col-xl-12 col-lg-12 row form-group bordergray pl-0px pr-0px ml-0p">';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 summary-lbl"><span class="f11">Container No</span><br /><span class="f16">'+itm.container_no+'</span></label></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 summary-lbl"><span class="f11">POL/Country of Origin</span><br /><span class="f16">'+itm.country_code+'</span></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray  n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">Supplier</span><br /><span class="f16">'+itm.supplier_code+'</span></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">Current Status </span><br /><span class="f16">'+itm.status+'</span></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">MSIL Requirement Date</span><br /><span class="f16">'+getDDMMYYYY(itm.critical_msil_eta)+'</span></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">Transit Delay (Days)</span><br /><span class="f16">'+itm.transit_delay_days+'</span></div>';
				// html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">SPOC Name</span><br /><span class="f16">'+itm.spoc_contact_name+'</span></div>';
				// html += '<div class="col-xl-6 col-lg-6 brd-right n-p-0 f16 brd-btm-gray  textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">SPOC Contact Number</span><br /><span class="f16">'+itm.spoc_contact_number+'</span></div>';
				html += '<div class="col-xl-12 col-lg-12 brd-right n-p-0 f16"><label class="col-xl-12 col-lg-12 "><span class="f11">Remarks</span><br /><span class="f14">'+itm.delay_reason+'</span></div>';
				html += '</div>';
				i=i+1
			})
		}
		html += '</div>'
		//console.log(html)
		//console.log("deptcode ",deptcode)
		$(".grid-"+deptcode).hide();
		$(".grid-trans-"+deptcode).show();
		$("#gridtrans"+deptcode).html(html);
	})
	.catch(function(e){
		console.log("Error ",e)
	})

});


$(document).on('click',".clickconsigner", function(){
	var criticaldata=[];
	var consigner = $(this).attr("consigner")
	var transporter = $(this).attr("transporter")
	var deptcode = $(this).attr("deptcode");
	$(".dataLoadpagesum"+deptcode).show()
	$(".dataLoadpageimgsum"+deptcode).show()		
	$(".overlay-partsum"+deptcode).show()	
	var dept_code = $(this).attr("dept_code");
	var rqparams={
		dept_code:dept_code
	}
	if(dept_code == "LOG-PRT (Courier)")
	{
		var blocktitle='Consignment Code';
	}
	else{
		var blocktitle='Truck No';
	}
	//console.log("rqparams ",rqparams);
	redirectURL.post("/consignments/summarysecondleveldata",rqparams)
	.then(async (res) =>{
		//console.log("res.data.message[0] ",res.data.message[0])
		criticaldata=res.data.message[0].critical_data;
		var dept_code=res.data.message[0].dept_code;
		$(".dataLoadpagesum"+deptcode).hide()
		$(".dataLoadpageimgsum"+deptcode).hide()
		$(".overlay-partsum"+deptcode).hide();	
	

		var criticaldataarr=[]
		if(criticaldata.length > 0)
		{
			criticaldata.map((item) =>{
				if(dept_code == 'LOG-SC')
				{
					criticaldataarr.push({
						container_no:item.container_no,
						country_code:item.country_code,
						critical_msil_eta:item.critical_msil_eta,
						delay_reason:item.delay_reason,
						delay_type:item.delay_type,
						dept_code:item.dept_code,
						dept_name:item.dept_name,
						spoc_contact_name:item.spoc_contact_name,
						spoc_contact_number:item.spoc_contact_number,
						status:item.status,
						transit_delay_days:parseInt(item.transit_delay_days),
						transporters_name:item.transporters_name,
						supplier_code:(item.supplier_code == "")?"NA":item.supplier_code,
						uom:item.uom,
					})
				}
				else
				{
					criticaldataarr.push({
						consignee_city:item.consignee_city,
						consignee_code:item.consignee_code,
						consignee_name:item.consignee_name,
						consigner_code:item.consigner_code,
						consignment_code:item.consignment_code,
						delay_type:item.delay_type,
						dept_code:item.dept_code,
						dept_name:item.dept_name,
						delay_reason:item.delay_reason,
						expected_trip_end:item.expected_trip_end,
						invoice_date:item.invoice_date,
						plant_location:item.plant_location,
						spoc_contact_name:item.spoc_contact_name,
						spoc_contact_number:item.spoc_contact_number,
						status:parseInt(item.status),
						transit_delay_days:parseInt(item.transit_delay_days),
						transporter_name:item.transporter_name,
						transporters_name:item.transporters_name,
						uom:item.uom,
					})
				}
			})
		}
		//alert(transporter)
		console.log("criticaldata here,", criticaldata)
		criticaldataarr.sort(GetSortOrder("transit_delay_days"));

		var filterdata=[]
		var containerimprt= []
		if(criticaldataarr.length > 0)
		{
			criticaldataarr.map((item)=>{
				if(item.dept_code == 'LOG-SC')
				{
					if(item.country_code == consigner && item.transporters_name == transporter)
					{
						containerimprt.push(item)
					}
				}
				else
				{
					if(item.consigner_code == consigner && item.transporter_name == transporter){
						filterdata.push(item)
					}
				}
			})
		}
		var html = '<div class="col-xl-12 col-lg-12 mt-10p"><div class="col-xl-12 col-lg-12 n-p-0"><h4 class="summary-grid-title">'+transporter+" - "+consigner+'</h4></div>';
		if(filterdata.length > 0)
		{
			var i=0;
			filterdata.map((itm) =>{
				if(i%2 == 0)
				{
					var classtxt=''
				}
				else{
					var classtxt='oddbg'
				}
				html += '<div class="col-xl-12 col-lg-12 row form-group bordergray pl-0px pr-0px ml-0p">';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 summary-lbl"><span class="f11">'+blocktitle+'</span><br /><span class="f16">'+itm.consignment_code+'</span></label></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 summary-lbl"><span class="f11">Loading Plant</span><br /><span class="f16">'+itm.consigner_code+'</span></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray  n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">Consignee Name</span><br /><span class="f16">'+itm.consignee_name+'</span></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">Destination City</span><br /><span class="f16">'+itm.consignee_city+'</span></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">Original ETA</span><br /><span class="f16">'+getDDMMYYYY(itm.expected_trip_end)+'</span></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">Transit Delay (Days)</span><br /><span class="f16">'+itm.transit_delay_days+'</span></div>';
				// html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">SPOC Name</span><br /><span class="f16">'+itm.spoc_contact_name+'</span></div>';
				// html += '<div class="col-xl-6 col-lg-6 brd-right n-p-0 f16 brd-btm-gray  textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">SPOC Contact Number</span><br /><span class="f16">'+itm.spoc_contact_number+'</span></div>';
				html += '<div class="col-xl-12 col-lg-12 brd-right n-p-0 f16"><label class="col-xl-12 col-lg-12 "><span class="f11">Remarks</span><br /><span class="f14">'+itm.delay_reason+'</span></div>';
				html += '</div>';
				i=i+1
			})
		}

		/*LOG-SC Container Imports Critical Delay view*/
		if(containerimprt.length > 0)
		{
			var n=0;
			containerimprt.map((itm) =>{
				if(n%2 == 0)
				{
					var classtxt=''
				}
				else{
					var classtxt='oddbg'
				}
				html += '<div class="col-xl-12 col-lg-12 row form-group bordergray pl-0px pr-0px ml-0p">';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 summary-lbl"><span class="f11">Container No</span><br /><span class="f16">'+itm.container_no+'</span></label></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 summary-lbl"><span class="f11">POL/Country of Origin</span><br /><span class="f16">'+itm.country_code+'</span></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray  n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">Supplier</span><br /><span class="f16">'+itm.supplier_code+'</span></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">Current Status</span><br /><span class="f16">'+itm.status+'</span></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">MSIL Requirement Date</span><br /><span class="f16">'+getDDMMYYYY(itm.critical_msil_eta)+'</span></div>';
				html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">Transit Delay (Days)</span><br /><span class="f16">'+itm.transit_delay_days+'</span></div>';
				// html += '<div class="col-xl-6 col-lg-6 brd-right brd-btm-gray n-p-0 f16 textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">SPOC Name</span><br /><span class="f16">'+itm.spoc_contact_name+'</span></div>';
				// html += '<div class="col-xl-6 col-lg-6 brd-right n-p-0 f16 brd-btm-gray  textAlignCenter"><label class="col-xl-12 col-lg-12 "><span class="f11">SPOC Contact Number</span><br /><span class="f16">'+itm.spoc_contact_number+'</span></div>';
				html += '<div class="col-xl-12 col-lg-12 brd-right n-p-0 f16"><label class="col-xl-12 col-lg-12 "><span class="f11">Remarks</span><br /><span class="f14">'+itm.delay_reason+'</span></div>';
				html += '</div>';
				i=i+1
			})
		}

		html += '</div>'
		//console.log(html)
		//console.log("deptcode ",deptcode)
		$(".grid-"+deptcode).hide();
		$(".grid-trans-"+deptcode).show();
		$("#gridtrans"+deptcode).html(html);
	})
	.catch(function(e){
		console.log("Error ",e)
	})

	
   //alert(transporter)
	//console.log("criticaldata here,", criticaldata)
	
});

$(document).on('click',".hidetransport", function(){
	var id = $(this).attr("id")
	$(".grid-"+id).show();
	$(".grid-trans-"+id).hide();
});