/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import _ from "lodash";
import SweetAlert from 'react-bootstrap-sweetalert';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getHyphenDDMMMYYYYHHMM } from '../common/utils';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import ConsolidateReport from '../dashboard/default/conslidatereportComponent';
var redirectURL = require('../redirectURL');
var moment = require('moment');

const decipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
    return encoded => encoded.match(/.{1,2}/g)
        .map(hex => parseInt(hex, 16))
        .map(applySaltToChar)
        .map(charCode => String.fromCharCode(charCode))
        .join('');
}
var decode = decipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

export default class UserDealerMapping extends Component {
    state = {
        dealers: [],
        users: [],
        user_val: { value: '', label: '' },
        dealer_val: [],
        show: '',
        pageTitle: "",
        loadshow: "show-n",
        showDiv: "show-n",
        overly: "show-n",
        basicTitle: "",
        basicType: "default",
        defaultColDef: {
            sortable: true,
            filter: true,
            resizable: true,
        },
        rowData: [],
        rowModelhighchartoptionsType: "enterprise",
        rowGroupPanelShow: "always",
        frameworkComponents: {
        },
        statusBar: {
            statusPanels: [
                {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left",
                },
                {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center",
                },
                { statusPanel: "agFilteredRowCountComponent" },
                { statusPanel: "agSelectedRowCountComponent" },
                { statusPanel: "agAggregationComponent" },
            ],
        },
        sideBar: {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel",
                },
            ],
        },
        detailCellRendererParams: {},
        startDate: "",
        endDate: "",


    }

    componentDidMount() {
        this.setState({
            loadshow: 'show-m',
            overly: 'show-m'
        })
        this.getUsersAndDealers()
    }


    cipher = salt => {
        try {
            const textToChars = text => text.split('').map(c => c.charCodeAt(0));
            const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
            const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);

            return text => text.split('')
                .map(textToChars)
                .map(applySaltToChar)
                .map(byteHex)
                .join('');
        }
        // eslint-disable-next-line no-unreachable
        catch (e) { }
    }

    getUsersAndDealers = () => {
        const encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

        const dept_code = localStorage.getItem('dept_code')
        // console.log('dept_code',dept_code)
        const params = {
            // dept_code : (dept_code)
            'dept_code': 'LOG-PRT'
        }

        redirectURL.post('/trucks/getUsersAndDealers', params).then(resp => {
            // console.log('resp',resp.data)
            const uniqueConsignees = resp.data.uniqueConsignees
            const usersList = resp.data.usersList
            this.setState({
                dealers: uniqueConsignees,
                users: usersList,
                rowData: resp.data.userDealerList,
                loadshow: 'show-n',
                overly: 'show-n'
            })

        })
    }

    mapUserDealerInfo = e => {
        e.preventDefault()
        const params = {}
        const user_val = this.state.user_val.value
        const dealer = this.state.dealer_val

        const dealerList = []
        dealer.map(e => {
            if (e.value !== "") {
                dealerList.push(e.value)
            }
        })
        if (user_val !== "" && dealerList.length !== 0) {
            params.user = user_val
            params.dealer = dealerList
            console.log("params", params)

            redirectURL.post('/trucks/addDealersListToUsers', params).then(resp => {
                console.log('resp', resp)
                if (resp) {
                    this.setState({
                        show: true,
                        basicType: "success",
                        basicTitle: "Successfully updated user with dealers"
                    })
                }
            })

        } else {
            this.setState({
                show: true,
                basicType: "danger",
                basicTitle: "Please Select Values"
            })
        }
    }

    onChangeUser = (user_val) => {
        // console.log('user_val',user_val)
        this.setState({ user_val },
            // () =>console.log(`Name  Option selected:`, this.state.user_val)
        );
    }

    onChangeDealer = (dealer_val) => {
        // console.log('dealer_val',dealer_val)
        this.setState({ dealer_val },
            // () => console.log(`Name  Option selected:`, this.state.dealer_val)
        );
    }

    closeAlert = () => {
        this.setState({
            show: false,
        });
        //window.location.reload();
    };

    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    }

    onGridState = () => {
        //console.log(this.gridApi);
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();
        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }
    onClickHideAll = () =>{
		this.setState({
			loadshow: 'show-n',
			overly: 'show-n',			
		});

	}

    render() {

        const columnwithdefs = [
            {
                headerName: "User",
                headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                field: "username",
                width: 400,
                // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
            },
            {
                headerName: "Dealers",
                headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                field: "consignee_code",
                width: 450,
                // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
            },
        ]
        return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="card col-xl-12 col-lg-12">
                    <div className="card-header" style={{ padding: '10px' }}>
                        <h4>User Dealer Mapping</h4>
                    </div>
                    <div className='card-body col-xl-12 col-lg-12'>
                        <form className="row" onSubmit={this.mapUserDealerInfo}>
                            <div className={"form-group col-xl-5 col-lg-5"}>
                                <label>Select User :  </label>
                                <Select
                                    isMulti={false}
                                    id="selectedFilter"
                                    options={this.state.users}
                                    placeholder={"Select User"}
                                    closeMenuOnSelect={true}
                                    onChange={this.onChangeUser}
                                    className={"col-xl-12 col-lg-12 border-radius-0"}
                                    style={{ borderRadius: "0px" }}
                                    name="user_info"
                                    value={this.state.user_val}
                                    required />
                            </div>
                            <div className={"form-group col-xl-5 col-lg-5"}>
                                <label>Select Dealer :  </label>
                                <Select
                                    isMulti={true}
                                    id="selectedFilter"
                                    options={this.state.dealers}
                                    placeholder={"Select Dealer"}
                                    closeMenuOnSelect={true}
                                    onChange={this.onChangeDealer}
                                    className={"col-xl-12 col-lg-12 border-radius-0"}
                                    style={{ borderRadius: "0px" }}
                                    name="dealer_info"
                                    value={this.state.dealer_val}
                                    required />
                            </div>
                            <div className="col-xl-2 col-lg-2 form-group pt-4">
                                <button type="submit" className="btn btn-success">
                                    Submit Data
                                </button>
                            </div>
                        </form>

                        <div className='row'>
                            <div className="col-xl-12 col-lg-12">
                                <div className="card-header" style={{ padding: '10px' }}>
                                    <h5>User Dealer MappedList</h5>
                                </div>
                                <div id="myGrid" style={{ height: "400px" }} className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithdefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={false}
                                        enableRangeSelection={true}
                                        statusBar={this.state.statusBar}
                                        pagination={true}
                                        groupHeaderHeight={40}
                                        headerHeight={40}
                                        floatingFiltersHeight={50}
                                        pivotGroupHeaderHeight={30}
                                        pivotHeaderHeight={50}
                                        rowHeight={40}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                    //sideBar={this.state.sideBar}
                                    // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll}></div>
            </div>
        )
    }

}