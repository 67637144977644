import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
//import Grid from '../layouts/gridnosidebarComponent';
import CSVFileValidator from 'csv-file-validator'
import Select from 'react-select';
import { getDDMMYYYY,
	getDDMMYYYYHHMMSS,
	getYYYYMMDD,
	getYYYYMMDDHHMMSS,
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD,
	getHyphenDDMMYYYYHHMMSS,
	getHyphenDDMMYYYY,
	getDDMMMYYYYHHMMDefault } from '../common/utils';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
// import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';

$.datetimepicker.setLocale('en');
var infoBox = require('../common/google-infowindow');

var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");
export default class ManageConsignments extends Component {

	constructor(props){
		super(props);
		this.displayData=[];
		this.state={
			deptcode:"",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
			eventAction: null,
			forceCloseRowNode : "",
			allRowData: [],
			activeFilter: null,
			uploadDivWidth:'0%',
			sliderTranslate:'',
			sliderBulkUpdate:'',
			tabsliderTranslate:'',
			timelineinfo:'',
			consignment_code:'',
			mapinfo:'',
			commentsRowData:[],
			dealer:'',
			maptruckno:'',
			loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			overly:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
			basicTitle:'',
			rownode:'',
			leg_no:'',
			basicType:"default",
			modules: AllModules,
			filterstatess:{},
      	    columnsdatas:{},
      	    rowgroupdcols:[],
			valuecolumns:[],
			activeconsignemnt:[],
			activetrucks:[],
			transitdelays:[],
			gpsdatana:[],
			overspeedtrucks:[],
			nightdrive:[],
			open: false,
			openforceclose: false,
      	    pivotmodeis:false,
			pivotcols:[],
			defTransitCoords:'',
			routeTruck:{},
			sidebarSubHeader:'',
			googelRoutes:'',
			plants:[],
			bulkPrioritySlide : "",
			hideTransporterBtns : "show-m",
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true,
				pivot : true,
				enableValue: true,
    			enableRowGroup: true,
      	    },
      	    rowData: [],
			  maprowData:null,
			rowSelection:'multiple',
	    rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			overlayNoRowsTemplate: "",
			frameworkComponents: {
			},

      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,

			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
			rowClassRules: {
				//"highlitRow": "data.transit_delay == 1",
				//"green":"data.status >= 3"

			},
			pagetitle:'Create Consignment',
			truck_no:{"value":"Select Plant","label":"Select Truck"},
			plant_code:{"value":"Select Plant","label":"Select Plant"},
			grno_consignment_code:"",
			invoice_no:"",
			invoice_date:"",
			gate_out_time:"",
			consignee_code:"",
			trucklist:[],
			plantlist:[],
			dept_code:"",
			consignees:[]

		}
		this.changeHandler = this.changeHandler.bind(this);
		this.onLoadGetConsignmentsList = this.onLoadGetConsignmentsList.bind(this);
		this.cipher = this.cipher.bind(this);
		this.changeFileHandler = this.changeFileHandler.bind(this);
		this.saveBulkConsignmentData = this.saveBulkConsignmentData.bind(this);
	}
	cipher = (salt) => {
		const textToChars = text => text.split('').map(c => c.charCodeAt(0));
		const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
		const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
		try{
			return text => text.split('')
				.map(textToChars)
				.map(applySaltToChar)
				.map(byteHex)
				.join('');
		}
		catch(e){

		}

	}


	componentDidCatch(error, info)
	{
		console.log("Error here ", error)
		console.log("Error Info here ", info)
	}
	logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
	componentDidMount(){
		loadDateTimeScript();
		var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		// console.log("path is ",this.props.match)
		var dept_code = "";
		var urlpath = "";
		var urlpathplant = "";
		var consignmenturlpath = "";
		if(this.props.match.path == "/prtcreateconsignment")
		{
			dept_code = "LOG-PRT";
			urlpath = "/dashboard/prtdepttrucks";
			urlpathplant = "/dashboard/prtdeptplants";
			consignmenturlpath="/dashboard/prtdeptconsignments";
		}
		this.setState({
			dept_code:dept_code
		});
		var parameters = {
			dept_code:encode(dept_code)
		}
		if(urlpath != "")
		{
			redirectURL.post(urlpath,parameters)
			.then((response) => {
				var records = response.data.records;
				var trucks = []
				if(records.length > 0)
				{
					records.map((item) => {
						trucks.push({
							value:item.truck_no,
							label:item.truck_no
						})
					})
				}
				this.setState({
					trucklist:trucks
				})
			})
		}
		if(urlpathplant != "")
		{
			redirectURL.post(urlpathplant,parameters)
			.then((response) => {
				var records = response.data.records;
				var plants = []
				if(records.length > 0)
				{
					records.map((item) => {
						plants.push({
							value:item.plant_code,
							label:item.plant_name
						})
					})
				}
				this.setState({
					plantlist:plants
				})
			})
		}
		var consignData = {
			dept_code:encode(dept_code)
		}
		this.onLoadGetConsignmentsList(consignmenturlpath, consignData);
		redirectURL.post("/dashboard/prtconsignees")
		.then((response) => {
			var records = response.data.records;
			var consignees = []
			if(records.length > 0)
			{
				records.map((item) => {
					consignees.push({
						value:item.consignee_code,
						label:item.consignee_name
					})
				})
			}
			this.setState({
				consignees:consignees
			})
		})

	};


	onLoadGetConsignmentsList(urlpath, parameters)
	{
		this.setState({
			loadshow:"show-m",
			overly:"show-m",
			rowData:[]
		})
		if(urlpath != "")
		{
			redirectURL.post(urlpath,parameters)
			.then((response) => {
				var records = response.data.records;
				this.setState({
					loadshow:"show-n",
					overly:"show-n",
					rowData:records
				})
			})
		}
		else {
			this.setState({
				rowData:[]
			})
		}
	}


	onGridReady = params => {
		this.gridApi = params.api;

	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;

	   //    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;

	//    };



	};


	onGridState = () =>{
		 //console.log(this.gridApi);

		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns,
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();

		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);



	}

	onClickShowForm(){
		this.setState({
			overly:"show-m",
			sliderForceCloseTranslate:"slider-translate"
		});
	}

	onClickBulkForm(){
		this.setState({
			sliderBulkUpdate:"slider-translate",
			overly:"show-m"
		})
	}
	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderForceCloseTranslate:'',
			sliderBulkUpdate:'',
		});

	}
	changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
	}
	changeFileHandler = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		const config = {
			headers: [
				{
					name: 'truck_no',
					inputName: 'truck_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'grno',
					inputName: 'grno',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'Invoice_no',
					inputName: 'invoice_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'invoice_date',
					inputName: 'invoice_date',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'trip_started_on',
					inputName: 'trip_started_on',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'plant_code',
					inputName: 'plant_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'plant_name',
					inputName: 'plant_name',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'pd_location',
					inputName: 'pd_location',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				}
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			this.setState({
				csvcontent:csvData.data
			});
		})
		.catch(err => {})

		var out = new Promise(function(reject, resolve){
			var reader =  new FileReader();
			reader.onload = async function(e) {
				var contents = await e.target.result;
				console.log("contents ", contents )
				resolve(contents);
			};
			var tt =  reader.readAsText(e.target.files[0]);
			console.log("tt ",tt)
		});
		//console.log("Out ", out);

		this.setState({
			file:e.target.files[0]
		});
		//console.log("e.target.files[0].type ", e.target.files[0].type);

		if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
		{
		}
		else{
			e.target.value = null;
			this.setState({
				uploadFile:'',
				show: true,
				basicType:'danger',
				basicTitle:'Please upload file having extensions .csv only.',
			});
		}

	}
	saveConsignmentData = async (event) => {
		event.preventDefault();
		var truck_no = this.state.truck_no.value;
		var plant_code = this.state.plant_code.value;
		var plant_name = this.state.plant_code.label;
		var grno_consignment_code = this.state.grno_consignment_code;
		var invoice_no = this.state.invoice_no;
		var invoice_date = $("#invoice_date").val();

		var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

		var gate_out_time = $("#gate_out_time").val();
		if(this.state.consignee_code != "")
		{
			var consignee_code = this.state.consignee_code.value;
				var consignee_name = this.state.consignee_code.label;
		}
		else {
			var consignee_code = "";
			var consignee_name = "";
		}
		if(truck_no != "" && plant_code != "" && grno_consignment_code != ""
		&& invoice_no != "" && invoice_date != "" && invoice_date != "__-__-____ __:__"
		&& gate_out_time != "" && consignee_code != "")
		{
			var parameters = {
				truck_no:truck_no,
				plant_code:plant_code,
				plant_name:plant_name,
				consignment_code:grno_consignment_code,
				invoice_no:invoice_no,
				invoice_date:invoice_date,
				gate_out_time:gate_out_time,
				consignee_code:consignee_code,
				consignee_name:consignee_name,
				dept_code:encode(this.state.dept_code)
			}
				//console.log("paraneters ", parameters);

				redirectURL.post("/dashboard/saveConsignmentData",{params:parameters})
				.then((response) => {
					console.log("save response ", response.data)
					if(response.data.status == "success")
					{
						this.setState({
							show:true,
							basicType:"success",
							basicTitle:"Successfully created consignment"
						})
					}
					else {
						if(response.data.message == "Special characters are not allowed")
						{
							this.setState({
								show:true,
								basicType:"danger",
								basicTitle:"Invalid Data"
							})
						}
						else{
							this.setState({
								show:true,
								basicType:"danger",
								basicTitle:"Failed to create consignment"
							})
						}

					}
				})
		}
	}


	saveBulkConsignmentData = async (event) => {
		event.preventDefault();
		var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

			var parameters = {
				csvcontent:this.state.csvcontent,
				dept_code:encode(this.state.dept_code)
			}

				redirectURL.post("/dashboard/savebulkconsignment",parameters)
				.then((response) => {
					console.log("save response ", response.data)
					if(response.data.status == "success")
					{
						this.setState({
							show:true,
							basicType:"success",
							basicTitle:"Successfully created consignment"
						})
					}
					else {
						if(response.data.message == "Special characters are not allowed")
						{
							this.setState({
								show:true,
								basicType:"danger",
								basicTitle:"Invalid Data"
							})
						}
						else{
							this.setState({
								show:true,
								basicType:"danger",
								basicTitle:"Failed to create consignment"
							})
						}

					}
				})

	}
	handlerDeliverDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);

    	var deliverdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());

    	this.setState({
    		deliverdate:deliverdate
    	});
    }
	onChangeTruckItem(truck_no){
		this.setState(
			{ truck_no },
			() => console.log(`Truck  Option selected:`, this.state.truck_no)
		  );

		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}

onChangeConsigneeItem(consignee_code){
	this.setState(
		{ consignee_code },
		() => console.log(`Code  Option selected:`, this.state.consignee_code)
		);

		this.setState({
			errorMessage:'',
			successMessage:''
		})
}
	onChangePlantItem(plant_code){
	this.setState(
		{ plant_code },
		() => console.log(`Plant Option selected:`, this.state.plant_code)
		);

		this.setState({
			errorMessage:'',
			successMessage:''
		})
	}
	closeAlert = async() => {
        await this.setState({
            show: false
        });
        await window.location.reload();
	}
	render(){
		const { open } = this.state;
		var columnwithDefs = [
			{
				headerName: "Consignment Code",
				field: "consignment_code",
				width: 120,
				pinned: 'left',
				editable:false,
				filter: "agSetColumnFilter",resizable: true
				//cellRenderer: "agGroupCellRenderer"
			},
			{
				headerName: "Truck No",
				field: "truck_no",
				width: 120,
				pinned: 'left',
				editable:false,
				filter: "agSetColumnFilter",resizable: true
				//cellRenderer: "agGroupCellRenderer"
			},
			{
				headerName: "Plant Code",
				field: "plant_code",
				width: 120,
				editable:false,
				filter: "agSetColumnFilter",resizable: true
				//cellRenderer: "agGroupCellRenderer"
			},
			{
				headerName: "Plant",
				field: "plant_name",
				width: 140,
				editable:false,
				filter: "agSetColumnFilter",resizable: true
				//cellRenderer: "agGroupCellRenderer"
			},
			{
				headerName: "PD Location",
				field: "consignee_code",
				width: 120,
				editable:false,
				filter: "agSetColumnFilter",
				resizable: true
				//cellRenderer: "agGroupCellRenderer"
			},
			{
				headerName: "Invoice_no",
				field: "invoice_no",
				width: 120,
				editable:false,
				filter: "agSetColumnFilter",resizable: true
				//cellRenderer: "agGroupCellRenderer"
			},
			{
				headerName: "Invoice Date",
				field: "invoice_date",
				width: 160,
				editable:false,
				filter: "agSetColumnFilter",
				resizable: true,
				valueGetter:function(params){
					try{
						if(params.data.invoice_date != "" && params.data.invoice_date != undefined)
						{
								return getHyphenDDMMMYYYYHHMM(params.data.invoice_date);
						}
						else {
							return "";
						}
					}
					catch(e){}
				}
			},
			{
				headerName: "Trip Starter On",
				field: "gate_out_time",
				width: 160,
				editable:false,
				filter: "agSetColumnFilter",
				resizable: true,
				valueGetter:function(params){
					try{
						if(params.data.gate_out_time != "" && params.data.gate_out_time != undefined)
						{
								return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
						}
						else {
							return "";
						}
					}
					catch(e){}
				}
			}
		]
		return(
			<div className="container-fluid">
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}>
	        	</SweetAlert>

				<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
									<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle} </span>
									<button type="button" className="btn btn-warning float-right" onClick={this.onClickShowForm.bind(this)}>Add Consignment</button>
									{/*<button type="button" className="btn btn-danger float-right" onClick={this.onClickBulkForm.bind(this)}>Bulk Upload</button>
									*/}
								</h5>
				   			</div>
		            		<div className="card-body pt-10px" >

								 <div id="myGrid" style={{ height: "580px",width: "100%"}}  className="ag-theme-balham">
		          		          <AgGridReact
		          		            modules={this.state.modules}
		          		            columnDefs={columnwithDefs}
		          		            defaultColDef={this.state.defaultColDef}
									rowData={this.state.rowData}
									// onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
		          		            enableCharts={true}
		          		            enableRangeSelection={true}
		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
		          		            onGridReady={this.onGridReady}
		          		            onGridState={this.onGridState}
		          		            statusBar={this.state.statusBar}
									sideBar={this.state.sideBar}
									paginationPageSize={this.state.paginationPageSize}
									pagination={true}
									frameworkComponents={this.state.frameworkComponents}
									detailCellRendererParams={this.state.detailCellRendererParams}
									stopEditingWhenGridLosesFocus={true}
									enableCellChangeFlash={false}
									rowClassRules={this.state.rowClassRules}
									gridOptions={{
										context: { componentParent: this }
									}}
									masterDetail={true}

									rowSelection={this.state.rowSelection}
									suppressRowClickSelection={true}
    								// suppressCellSelection= {true}
									overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
		          		          />

									{/*
									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
									</div> */}

		          		        </div>
				   			</div>
				   		</div>
				   	</div>

				 </div>




				<div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>

				<div className={"slide-r "+(this.state.sliderForceCloseTranslate)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Add Consignment
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>

                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>

                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div>
						<div className={"row"} style={{padding:'5px 0px', width: '100%', overflow: 'hidden',zIndex:'9999',height:"100em"}} >
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.saveConsignmentData.bind(this)}>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Select Truck :</label>

									<Select
									placeholder={"Select Truck"}
									closeMenuOnSelect={true}
									onChange={this.onChangeTruckItem.bind(this)}
									className={"col-xl-12 col-lg-12 border-radius-0"}
									style={{borderRadius:"0px"}}
									name="truck_no"
									value={this.state.truck_no}
									options={this.state.trucklist} />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">GR No/Consignment Code :</label>
									<input
										type="text"
										className="form-control"
										onChange={this.changeHandler}
										value={this.state.grno_consignment_code}
										name="grno_consignment_code"
									/>
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Invoice No :</label>
									<input
										type="text"
										className="form-control"
										onChange={this.changeHandler}
										value={this.state.invoice_no}
										name="invoice_no"
									/>
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Invoice Date :</label>
									<input
										type="text"
										className="form-control datetimepicker_mask"
										id="invoice_date"
										name="invoice_date"
									/>
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Trip Started On :</label>
									<input
										type="text"
										className="form-control datetimepicker_mask"
										id="gate_out_time"
										name="gate_out_time"
									/>
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Select Plant :</label>

									<Select
									placeholder={"Select Plant"}
									closeMenuOnSelect={true}
									onChange={this.onChangePlantItem.bind(this)}
									className={"col-xl-12 col-lg-12 border-radius-0"}
									style={{borderRadius:"0px"}}
									name="plant_code"
									value={this.state.plant_code}
									options={this.state.plantlist} />
								</div>

								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">PD Location :</label>

									<Select
									placeholder={"Select PD Location"}
									closeMenuOnSelect={true}
									onChange={this.onChangeConsigneeItem.bind(this)}
									className={"col-xl-12 col-lg-12 border-radius-0"}
									style={{borderRadius:"0px"}}
									name="consignee_code"
									value={this.state.consignee_code}
									options={this.state.consignees} />
								</div>

								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
							</form>
                        </div>
					</div>
				</div>

				<div className={"slide-r "+(this.state.sliderBulkUpdate)} style={{overflow:"auto"}}>
					<div className="slide-r-title">
						<h4>
							Bulk Upload - Consignments
							<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
						</h4>
					</div>
					<div className="slide-r-body" style={{position:"relative"}}>

						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div>
						<div className={"row"} style={{padding:'5px 0px', width: '100%', overflow: 'hidden',zIndex:'9999',height:"100em"}} >
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.saveBulkConsignmentData}>
									<div className="col-xl-12 col-lg-12 row form-group">
										<label className="col-xl-12 col-lg-12">Upload File :</label>
										<input type="file"
											onChange={this.changeFileHandler}
											name="uploadFile"
											className="form-control"
											 />
									</div>

									<div className="col-xl-12 col-lg-12 form-group">
										<button type="submit" className="btn btn-success">Submit</button>
									</div>
							</form>
						</div>
					</div>
				</div>


			</div>

		);
	}
}


window.onload = function (){
	// $(".datepicker").datepicker({
    //     dateFormat: "dd-mm-yy",
    //     changeMonth: true,
    //     changeYear: true
	// });
	$('#invoice_date').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('#gate_out_time').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});

	$(".styles_modal__gNwvD").css({width:"450px"})
};
document.addEventListener('turbolinks:before-render', () => {
	loadDateTimeScript()
  });

function loadDateTimeScript(){

	$('#invoice_date').datetimepicker({
		//mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
		$('#gate_out_time').datetimepicker({
			//mask:'39-19-9999 29:59',
			format:'d-m-Y H:i'
		});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
