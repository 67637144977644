import React, { Component } from 'react';
import CountUp from 'react-countup';
import axios from 'axios';
var redirectURL = require('../redirectURL');

export default class CountersComponent extends Component {
    constructor(props){
    	super(props);
    	
    	this.state={
    		records:[],
    		truckscnt:0,
    		trucklocations:0,
    		notrans:0
    	}
    
    }
    componentDidMount(){
    
        const layoutdata={
            token:localStorage.getItem('token'),
            dept_code:this.props.deptcode
        }
        redirectURL.post('/layouts/counters', layoutdata,{
            headers:{
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
            }
        })
        .then((response) => {
            //console.log("totalTrucksCount",response);
            var trucks = JSON.parse(JSON.stringify(response)).data;
           //  console.log("1 ",trucks[0]);
            this.setState({
                records:trucks[0],
                truckscnt:trucks[0].totalTrucksCnt,
                trucklocations:trucks[0].trucksTodayDataCount,
                notrans:trucks[0].trucksWithNoTransportersDataCount
            });
        });

    }
    render(){
        return (
            <div className="row">
                <div className="col-xl-3 col-lg-6 col-sm-6 beffect">
                    <div className="card">
                        <div className="card-body">
                			<div className="brd brd-txt1"></div>
                            <div className="stat-widget-dashboard">
                                <div className="media">
                                    <div className="media-body text-left">
                                        <h4 className="mt-0 fnt3"><CountUp className="font-primary" end={this.state.truckscnt} /></h4>
                                        
                                    </div>
                                    <img className="mr-3" src={require('../../assets/images/dashboard-icons/document.png')} alt="Generic placeholder image" />
                                	   
                                </div>
                                    <div className="col-lg-12 col-sm-12 n-p-0 f15">Total Trucks</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-6 col-sm-6 beffect">
                    <div className="card">
                        <div className="card-body">
	                        <div className="brd brd-txt2"></div>
	                        <div className="stat-widget-dashboard">
                                <div className="media">
                                	<div className="media-body text-left">
                                        <h4 className="mt-0 fnt3"><CountUp className="font-secondary" end={this.state.trucklocations} /></h4>
                               
                                    </div>
                                    <img className="mr-3" src={require('../../assets/images/dashboard-icons/operator.png')} alt="Generic placeholder image" />
                                        
                                </div>
                                    <div className="col-lg-12 col-sm-12 n-p-0 f15">Trucks with GPS Data</div>
                            </div>
                        </div>
                    </div>
                </div>
	            
	            
                <div className="col-xl-3 col-lg-6 col-sm-6 beffect">
                    <div className="card">
                        <div className="card-body">
                        	<div className="brd brd-txt4"></div>
                            <div className="stat-widget-dashboard">
                                <div className="media">
                                    <div className="media-body text-left">
                                        <h4 className="mt-0 fnt3"><CountUp className="font-info" end={this.state.truckscnt-this.state.trucklocations} /></h4>
                            
                                    </div>
                                    <img className="mr-3" src={require('../../assets/images/dashboard-icons/like.png')} alt="Generic placeholder image"/>
  
                                </div>
                                    <div className="col-lg-12 col-sm-12 n-p-0 f15">Trucks with No GPS Data</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-6 col-sm-6 beffect">
	                <div className="card">
	                    <div className="card-body">
	                    	<div className="brd brd-txt3"></div>
	                        <div className="stat-widget-dashboard">
	                            <div className="media">
	                                <div className="media-body text-left">
	                                    <h4 className="mt-0 fnt3 counter font-success"><CountUp className="font-success" end={this.state.notrans} /></h4>
	                                    
	                                </div>
	                                <img className="mr-3" src={require('../../assets/images/dashboard-icons/chat.png')} alt="Generic placeholder image"/>
	
	                            </div>
	
	                               <div className="col-lg-12 col-sm-12 n-p-0 f15">Trucks with No Transporters</div>
	                        </div>
	                    </div>
	                </div>
	            </div>
                
            </div>
        );
    }
}






