/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import $ from 'jquery'
import _ from "lodash";

import axios from 'axios';

import Grid from '../layouts/gridUpdateComponent';
import DateFormater from '../layouts/dateComponent';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getHyphenDDMMMYYYYHHMM } from '../common/utils';
import Modal from 'react-responsive-modal';
import { verifyextcsv } from "../common/verifyext";

import CSVFileValidator from 'csv-file-validator'
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');

export default class UnloadingTrucksDetentionReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            overly: "show-n",
            loadshow: "show-n",
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            show: false,
            basicType: '',
            basicTitle: '',
            ytd_amount_calculated_last_month: {},
            ytd_amount_given_in_current_month: {},
            original_ytd_amount_given_in_current_month: {},
            mtdamount: [],
            mtdamount_group_by_year: [],
            months: [
                "January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ],
            current_month_mtdamount: {},
            original_current_month_mtdamount: {},
            // last_month_mtdamount:{},
            // original_last_month_mtdamount:{},
            fymtd: [],
            last_month_fymtd: {},
            current_month_fymtd: {},
            original_current_month_fymtd: {},
            fymtd_central: "",
            fymtd_south: "",
            fymtd_east: "",
            fymtd_north: "",
            fymtd_west: "",

        }
    }

    componentDidMount() {
        redirectURL.post("dashboard/getYTDDententionAmountData").then(response => {
            var ytddetentionamount = response.data.ytddetentionamount
            if (ytddetentionamount.length > 0) {
                this.setState({
                    ytd_amount_calculated_last_month: ytddetentionamount[0].ytd_amount_calculated_last_month,
                    ytd_amount_given_in_current_month: ytddetentionamount[0].ytd_amount_given_in_current_month,
                    original_ytd_amount_given_in_current_month: ytddetentionamount[0].ytd_amount_given_in_current_month,
                    ytd_amount_updated : ytddetentionamount.ytd_amount_updated == 1 ? "YES" : "NO"
                })
            }
            var mtdamount = response.data.mtdamount
            if (mtdamount.length > 0) {
                var group_by_year = groupBy(mtdamount, data => data.year)
                var data = {}
                group_by_year.forEach((values, keys) => {
                    data[keys] = values
                })
                // var year = new Date().getFullYear()
                // var month = new Date().toLocaleString('en-US', { month: 'long' });
                // var current_month_mtdamount = mtdamount.filter(e => e.year == year && e.month == month)
                var last_month_date = moment(new Date()).subtract(1 , "month")
                var last_month_year = (new Date(last_month_date)).getFullYear()
                var last_month = new Date(last_month_date).toLocaleString('en-US', { month: 'long' });
                var last_month_mtdamount = mtdamount.filter(e => e.year == last_month_year && e.month == last_month)
                this.setState({
                    mtdamount: mtdamount,
                    mtdamount_group_by_year: data,
                    current_month_mtdamount: last_month_mtdamount[0],
                    original_current_month_mtdamount: last_month_mtdamount[0],
                })
            }
            var fymtd = response.data.fymtd
            if (fymtd.length > 0) {
                this.setState({
                    fymtd: fymtd
                })
            }
            var fymtdlastmonth = response.data.fymtdlastmonth
            if (fymtdlastmonth.length > 0) {
                this.setState({
                    last_month_fymtd: fymtdlastmonth[0].last_month_fymtd,
                    current_month_fymtd: fymtdlastmonth[0].current_month_fymtd,
                    original_current_month_fymtd: fymtdlastmonth[0].current_month_fymtd,
                    fy_amount_updated : fymtdlastmonth[0].fy_amount_updated == 1 ? "YES" : "NO"
                    
                })
            }
        })
    }

    updateYtdAmountCurrentMonthData = () => {
        var original_ytd_amount_given_in_current_month = this.state.original_ytd_amount_given_in_current_month
        var ytd_amount_given_in_current_month = this.state.ytd_amount_given_in_current_month
        if (original_ytd_amount_given_in_current_month["Grand Total "] != ytd_amount_given_in_current_month["Grand Total "]) {
            var keys = Object.keys(ytd_amount_given_in_current_month)
            keys.map(e => {
                ytd_amount_given_in_current_month[e] = parseFloat(ytd_amount_given_in_current_month[e])
            })
            redirectURL.post("dashboard/updateYtdAmountCurrentMonthData", { original_ytd_amount_given_in_current_month, ytd_amount_given_in_current_month ,email : localStorage.getItem("email")}).then(response => {
                if (response.data.status == "success") {
                    this.setState({
                        show: true,
                        basicType: "success",
                        basicTitle: "Updated Current Month YTD Current Month Data"
                    })
                } else {
                    this.setState({
                        show: true,
                        basicType: "danger",
                        basicTitle: "Current Month Data is not Updated"
                    })
                }
            })
        }
        else {
            this.setState({
                show: true,
                basicType: "info",
                basicTitle: "No Changes Made"
            })
        }
    }

    updateCurrentMonthMTDAmount = () => {
        var original_current_month_mtdamount = this.state.original_current_month_mtdamount
        var current_month_mtdamount = this.state.current_month_mtdamount
        if (this.state.original_current_month_mtdamount.mtd != this.state.current_month_mtdamount.mtd) {
            current_month_mtdamount.mtd = parseFloat(current_month_mtdamount.mtd)
            redirectURL.post("dashboard/updateCurrentMonthMTDAmount", { original_current_month_mtdamount, current_month_mtdamount,email : localStorage.getItem("email") }).then(response => {
                if (response.data.status == "success") {
                    this.setState({
                        show: true,
                        basicType: "success",
                        basicTitle: "Updated Current Month YTD Current Month Data"
                    })
                } else {
                    this.setState({
                        show: true,
                        basicType: "danger",
                        basicTitle: "Current Month Data is not Updated"
                    })
                }
            })
        }
        else {
            this.setState({
                show: true,
                basicType: "info",
                basicTitle: "No Changes Made"
            })
        }
    }

    updateCurrentMonthFYMTDData = () => {
        var original_current_month_fymtd = this.state.original_current_month_fymtd
        var current_month_fymtd = this.state.current_month_fymtd
        if (original_current_month_fymtd["Grand Total "] != current_month_fymtd["Grand Total "]) {
            var keys = Object.keys(current_month_fymtd)
            keys.map(e => {
                current_month_fymtd[e] = parseFloat(current_month_fymtd[e])
            })
            redirectURL.post("dashboard/updateCurrentMonthFYMTDData", { original_current_month_fymtd, current_month_fymtd ,email : localStorage.getItem("email")}).then(response => {
                if (response.data.status == "success") {
                    this.setState({
                        show: true,
                        basicType: "success",
                        basicTitle: "Updated Current Month YTD Current Month Data"
                    })
                } else {
                    this.setState({
                        show: true,
                        basicType: "danger",
                        basicTitle: "Current Month Data is not Updated"
                    })
                }
            })
        }
        else {
            this.setState({
                show: true,
                basicType: "info",
                basicTitle: "No Changes Made"
            })
        }
    }

    addItem = () => {
        this.setState({
            slider: "slider-translate-30p",
            overly: "show-m"
        })
    }

    onClickAddFYMTDAmountDetails = (e) => {
        e.preventDefault()
        var month = parseInt($("#month_year").val().split("-")[1])
        var year = parseInt($("#month_year").val().split("-")[0])
        var central = parseFloat($("#central").val())
        var south = parseFloat($("#south").val())
        var north = parseFloat($("#north").val())
        var east = parseFloat($("#east").val())
        var west = parseFloat($("#west").val())

        var formData = {data : 
            {
                central: central,
                north: north,
                south: south,
                east: east,
                west: west,
                total: central + north + south + east + west,
                month: this.state.months[month - 1],
                year: year
            },
            email : localStorage.getItem("email")
        }

        redirectURL.post("dashboard/addFYMTDAmountDetails", formData).then(response => {
            if (response.data.status == "success") {
                this.setState({
                    show: true,
                    basicType: "success",
                    basicTitle: "Updated Current Month YTD Current Month Data",
                    slider: "",
                    overly: 'show-n',
                    fymtd_central: "",
                    fymtd_south: "",
                    fymtd_east: "",
                    fymtd_north: "",
                    fymtd_west: "",
                })

            } else {
                this.setState({
                    show: true,
                    basicType: "danger",
                    basicTitle: "Current Month Data is not Updated"
                })
            }
        })



    }

    onClickHideAll = () => {
        this.setState({
            overly: "show-n",
            loadshow: "show-n",
            slider: "",
            fymtd_central: "",
            fymtd_south: "",
            fymtd_east: "",
            fymtd_north: "",
            fymtd_west: "",
        })
    }

    render() {
        var columnwithdefsForfymtd = [
            {
                headerName: "Central",
                field: "central",
                width: 120
            },
            {
                headerName: "East",
                field: "east",
                width: 120
            },
            {
                headerName: "North",
                field: "north",
                width: 120
            },
            {
                headerName: "South",
                field: "south",
                width: 120
            },
            {
                headerName: "West",
                field: "west",
                width: 120
            },
            {
                headerName: "Total",
                field: "total",
                width: 120
            },
            {
                headerName: "Month",
                field: "month",
                width: 120
            },

            {
                headerName: "Year",
                field: "year",
                width: 120
            },
        ]
        return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={() => this.setState({ show: false })}
                >
                </SweetAlert>
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">

                            <div className="card-header">
                                <h5>
                                    YTD Detention Amount
                                    <button type="button" className="btn btn-warning float-right" onClick={this.updateYtdAmountCurrentMonthData} >Update</button>
                                    <p className='float-right mr-5'>Is YTD Detention Amount Updated :{this.state.ytd_amount_updated || "NO"}</p>

                                </h5>
                            </div>
                            <div className="row card-body">
                                <div className='col-12'>
                                    {/* <h6>Current Month Data</h6> */}
                                    {Object.keys(this.state.ytd_amount_given_in_current_month).length > 0 ? (
                                        <table className='table table-bordered tptinfo'>
                                            <thead>
                                                <tr>
                                                    <th>Zone</th>
                                                    <th>Last Month</th>
                                                    <th>Current Month</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Object.keys(this.state.ytd_amount_given_in_current_month).map(e => (
                                                    <tr key={e}>
                                                        <td>{e}</td>
                                                        <td>{this.state.ytd_amount_calculated_last_month[e]}</td>
                                                        {e == "Grand Total " ?
                                                            <td>{this.state.ytd_amount_given_in_current_month[e]}</td>
                                                            :
                                                            <td style={{ display: 'flex', justifyContent: "space-between" }}>
                                                                <input
                                                                    className='form-control'
                                                                    type="text"
                                                                    value={this.state.ytd_amount_given_in_current_month[e]}
                                                                    onChange={(f) => {
                                                                        var value = f.target.value ? f.target.value : "0";
                                                                        var regex = /^[0-9.]+$/;
                                                                        if (regex.test(value) && value.split(".").length < 3) {
                                                                            value = value[0] === "0" ? (parseFloat(value)).toString() : value
                                                                            const updatedYtdAmount = { ...this.state.ytd_amount_given_in_current_month, [e]: value, "Grand Total ": this.state.ytd_amount_given_in_current_month["Grand Total "] - parseFloat(this.state.ytd_amount_given_in_current_month[e]) + parseFloat(value) };
                                                                            this.setState({ ytd_amount_given_in_current_month: updatedYtdAmount });
                                                                            // this.EditCurrentData("ytd_amount_given_in_current_month", e, value);
                                                                        }
                                                                    }}
                                                                    style={{ border: 'none', outline: 'none' }}
                                                                />
                                                                <span><i className="icofont icofont-edit"></i></span>
                                                            </td>}


                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    ) : null}
                                </div>



                            </div>

                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 mt-5">
                        <div className="card">

                            <div className="card-header">
                                <h5>
                                    MTD Amount Details
                                    <button type="button" className="btn btn-warning float-right" onClick={this.updateCurrentMonthMTDAmount} >Update</button>

                                </h5>
                            </div>
                            <div className="row card-body">
                                <div className='col-12'>
                                    {/* <h6></h6> */}
                                    {this.state.mtdamount.length > 0 ? (
                                        <table className='table table-bordered tptinfo'>
                                            <thead>
                                                <tr>
                                                    <th>Year</th>
                                                    {this.state.months.map(e => {
                                                        return <th>{e}</th>
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Object.keys(this.state.mtdamount_group_by_year).map((e) => (
                                                    <tr key={e} >
                                                        <td>{e}</td>
                                                        {this.state.months.map((month) => {
                                                            var filterData = this.state.mtdamount_group_by_year[e].filter(f => f.month == month)
                                                            if (filterData.length > 0) {

                                                                if (e == this.state.current_month_mtdamount.year && this.state.current_month_mtdamount.month == filterData[0].month) {
                                                                    return <td style={{ display: 'flex', justifyContent: "space-between" }}>
                                                                        <input
                                                                            className='form-control'
                                                                            type="text"
                                                                            value={this.state.current_month_mtdamount.mtd}
                                                                            onChange={(f) => {
                                                                                var value = f.target.value ? f.target.value : "0";
                                                                                var regex = /^[0-9.]+$/;
                                                                                if (regex.test(value) && value.split(".").length < 3) {
                                                                                    value = value[0] === "0" ? (parseFloat(value)).toString() : value
                                                                                    const updatedYtdAmount = {
                                                                                        ...this.state.current_month_mtdamount,
                                                                                        ...{
                                                                                            mtd: value
                                                                                        }
                                                                                    }
                                                                                    this.setState({ current_month_mtdamount: updatedYtdAmount });
                                                                                    // this.EditCurrentData("ytd_amount_given_in_current_month", e, value);
                                                                                }
                                                                            }}
                                                                            style={{ border: 'none', outline: 'none' }}
                                                                        />
                                                                        <span><i className="icofont icofont-edit"></i></span>
                                                                    </td>
                                                                }
                                                                else {
                                                                    return filterData.length > 0 ? (<td>{filterData[0].mtd}</td>) : <td>0</td>
                                                                }
                                                            } else {
                                                                return filterData.length > 0 ? (<td>{filterData[0].mtd}</td>) : <td>0</td>
                                                            }


                                                        })}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    ) : null}
                                </div>



                            </div>

                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 mt-5">
                        <div className="card">

                            <div className="card-header">
                                <h5>
                                    FYMTD Last Month Details
                                    <button type="button" className="btn btn-warning float-right" onClick={this.updateCurrentMonthFYMTDData} >Update</button>
                                    <p className='float-right mr-5'>Is FYMTD Last Month Details Updated :{this.state.fy_amount_updated || "NO"}</p>
                                </h5>
                            </div>
                            <div className="row card-body">
                                <div className='col-12'>
                                    {/* <h6>Current Month Data</h6> */}
                                    {Object.keys(this.state.last_month_fymtd).length > 0 ? (
                                        <table className='table table-bordered tptinfo'>
                                            <thead>
                                                <tr>
                                                    <th>Zone</th>
                                                    <th>Last Month</th>
                                                    <th>Current Month</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Object.keys(this.state.current_month_fymtd).map(e => (
                                                    <tr key={e}>
                                                        <td>{e}</td>
                                                        <td>{this.state.last_month_fymtd[e]}</td>
                                                        {e == "Grand Total " ?
                                                            <td>{this.state.current_month_fymtd[e]}</td>
                                                            :
                                                            <td style={{ display: 'flex', justifyContent: "space-between" }}>
                                                                <input
                                                                    className='form-control'
                                                                    type="text"
                                                                    value={this.state.current_month_fymtd[e]}
                                                                    onChange={(f) => {
                                                                        var value = f.target.value ? f.target.value : "0";
                                                                        var regex = /^[0-9.]+$/;
                                                                        if (regex.test(value) && value.split(".").length < 3) {
                                                                            value = value[0] === "0" ? (parseFloat(value)).toString() : value
                                                                            const updatedYtdAmount = { ...this.state.current_month_fymtd, [e]: (value), "Grand Total ": this.state.current_month_fymtd["Grand Total "] - parseFloat(this.state.current_month_fymtd[e]) + parseFloat(value) };
                                                                            this.setState({ current_month_fymtd: updatedYtdAmount });
                                                                            // this.EditCurrentData("ytd_amount_given_in_current_month", e, value);
                                                                        }
                                                                    }}
                                                                    style={{ border: 'none', outline: 'none' }}
                                                                />
                                                                <span><i className="icofont icofont-edit"></i></span>
                                                            </td>}


                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    ) : null}
                                </div>



                            </div>

                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 mt-5">
                        <div className="card">

                            <div className="card-header">
                                <h5>
                                    FYMTD Amount Details
                                    <button type="button" className="btn btn-warning float-right" onClick={this.addItem} >Add</button>

                                </h5>
                            </div>
                            <div className="row card-body">
                                <div className='col-12'>
                                    <div id="myGrid" style={{ height: "350px", width: "100%" }} className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnwithdefsForfymtd}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.fymtd}
                                            groupHeaderHeight={40}
                                            headerHeight={60}
                                            floatingFiltersHeight={50}
                                            pivotGroupHeaderHeight={30}
                                            pivotHeaderHeight={50}
                                            rowHeight={40}
                                            // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            frameworkComponents={this.state.frameworkComponents}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            //editType={this.state.editType}
                                            stopEditingWhenGridLosesFocus={true}
                                            //floatingFilter={true}
                                            alwaysShowHorizontalScroll={true}
                                            alwaysShowVerticalScroll={true}
                                            enableCellChangeFlash={false}
                                            //suppressCellFlash={true}
                                            rowClassRules={this.state.rowClassRules}
                                            // onCellDoubleClicked={this.onRowClicked.bind(this)}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            masterDetail={true}
                                            // getRowHeight={this.state.getRowHeight}
                                            // onCellEditingStopped={this.onCellUpdateData.bind(this)}

                                            rowSelection={this.state.rowSelection}
                                            // onRowSelected={this.onRowSelection.bind(this)}
                                            suppressRowClickSelection={true}
                                            // suppressCellSelection= {true}
                                            overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                            excelStyles={this.state.excelStyles}
                                        // onCellClicked={this.onCellClicked.bind(this)}
                                        />

                                    </div>
                                </div>



                            </div>

                        </div>
                    </div>
                    <div className={"slide-r " + (this.state.slider)} style={{ overflow: "auto" }}>
                        <div className="slide-r-title">
                            <h4>
                                Add FYMTD Amount Details
                                <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                            </h4>
                        </div>
                        <div className="slide-r-body" style={{ position: "relative" }}>
                            <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                                <form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onClickAddFYMTDAmountDetails} >
                                    <div className="col-xl-12 col-lg-12 row form-group">
                                        <div className="col-xl-12 col-lg-12 row form-group">
                                            <label className="col-xl-12 col-lg-12">Select Month </label>
                                            <input
                                                type="month"
                                                // name="uploadfile"
                                                id="month_year"
                                                className="form-control"
                                                required
                                            />
                                        </div>
                                        <label className="col-xl-12 col-lg-12">Central </label>
                                        <input
                                            type="text"
                                            // name="uploadfile"
                                            id="central"
                                            className="form-control"
                                            required
                                            onChange={(e) => {
                                                var regex = /^[0-9.]+$/
                                                var value = e.target.value
                                                if (regex.test(value) || value == "") {
                                                    this.setState({
                                                        fymtd_central: value
                                                    })
                                                }
                                            }}
                                            value={this.state.fymtd_central}


                                        />
                                    </div>
                                    <div className="col-xl-12 col-lg-12 row form-group">
                                        <label className="col-xl-12 col-lg-12">East </label>
                                        <input
                                            type="text"
                                            // name="uploadfile"
                                            id="east"
                                            className="form-control"
                                            required
                                            onChange={(e) => {
                                                var regex = /^[0-9.]+$/
                                                var value = e.target.value
                                                if (regex.test(value) || value == "") {
                                                    this.setState({
                                                        fymtd_east: value
                                                    })
                                                }
                                            }}
                                            value={this.state.fymtd_east}

                                        />
                                    </div>
                                    <div className="col-xl-12 col-lg-12 row form-group">
                                        <label className="col-xl-12 col-lg-12">West </label>
                                        <input
                                            type="text"
                                            // name="uploadfile"
                                            id="west"
                                            className="form-control"
                                            required
                                            onChange={(e) => {
                                                var regex = /^[0-9.]+$/
                                                var value = e.target.value
                                                if (regex.test(value) || value == "") {
                                                    this.setState({
                                                        fymtd_west: value
                                                    })
                                                }
                                            }}
                                            value={this.state.fymtd_west}

                                        />
                                    </div>
                                    <div className="col-xl-12 col-lg-12 row form-group">
                                        <label className="col-xl-12 col-lg-12">North </label>
                                        <input
                                            type="text"
                                            // name="uploadfile"
                                            id="north"
                                            className="form-control"
                                            required
                                            onChange={(e) => {
                                                var regex = /^[0-9.]+$/
                                                var value = e.target.value
                                                if (regex.test(value) || value == "") {
                                                    this.setState({
                                                        fymtd_north: value
                                                    })
                                                }
                                            }}
                                            value={this.state.fymtd_north}

                                        />
                                    </div>
                                    <div className="col-xl-12 col-lg-12 row form-group">
                                        <label className="col-xl-12 col-lg-12">South </label>
                                        <input
                                            type="text"
                                            // name="uploadfile"
                                            id="south"
                                            className="form-control"
                                            required
                                            onChange={(e) => {
                                                var regex = /^[0-9.]+$/
                                                var value = e.target.value
                                                if (regex.test(value) || value == "") {
                                                    this.setState({
                                                        fymtd_south: value
                                                    })
                                                }
                                            }}
                                            value={this.state.fymtd_south}

                                        />
                                    </div>


                                    <div className="col-xl-12 col-lg-12 form-group">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                    </div>


                                </form>
                            </div>
                        </div>
                    </div>
                    <div className={"dataLoadpage " + (this.state.loadshow)}>
                    </div>
                    <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                        <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                    </div>
                    <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll}></div>
                </div>
            </div>

        );
    }
}

function groupBy(list, keyGetter) {
    const map11 = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map11.get(key);
        if (!collection) {
            map11.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map11;
}