import React, { Component } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import $ from "jquery";
import SweetAlert from 'react-bootstrap-sweetalert';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { getHyphenDDMMMYYYYHHMM } from '../common/utils';
import CreatableSelect from 'react-select/creatable';
// import Map from './mapLocationComponent';
import Select from "react-select";
import redirectURL from '../redirectURL';
import DrawMap from '../common/drawmap';

var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");
var infoBox = require('../common/google-infowindow');
var map;

export default class RouteDeviationMap extends Component {

      constructor(props) {
          super(props);
          this.state={
            modules: AllModules,
            basicTitle:"",
            basicType:"success",
            show:false,
            loadshow:"show-n",
            overly : "show-n",
            screen:"",
            screentitle:"",
            pagetitle:"",
            eventGridAction: "gridAction",
            selected_consignments : [],
            defaultColDef : {
                sortable : true,
                filter : true
            },
            consignments_list: [],
            route_coords : [],
            breaks_list : [],
          };
      }
      
      

componentDidMount = async()=>{
        // this.setState({
        //     loadshow : "show-m",
        //     overly: "show-m"
        // })
        if(this.props.match.path == "/newroutedeviation")
        {
            var screen = "new";
        }
        else{
            var screen = "existing";
        }
        this.setState({
            screen : screen
        })
        this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        this.setState({eventAction: eventAction});
        // redirectURL.post("/dashboard/getRouteDeviationInitialData").then((response)=>{
        //     var consignments_list = [];
        //     response.data.map(function(e,index){
        //         if( index <= 100)
        //         {
        //             consignments_list.push({"label":e,"value":e})
        //         }
                
        //     })
        //     this.setState({
        //         consignments_list : consignments_list,
        //         loadshow : "show-n",
        //         overly: "show-n",
        //     })
        // }).catch((e)=>{

        // })
}
logPageView = () => {
    try {
        if (googleAnalytics.page.enableGA) {
            googleAnalytics.logPageView();
            let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
            let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
            let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
            this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
        } else {
            console.log("GA disabled...");
        }
    } catch(error) {
        console.error("Error occured while logging to GA, error = ", error);
    }
}  
setConsignmentValue(e){
    console.log(e)
    this.setState({
        selected_consignments : e,
    });
}


onClickSubmit(event)
{
    this.setState({
        loadshow: "show-m",
        overly: "show-m",
        route_coords : [],
        breaks_list : [],
    })
    event.preventDefault();
    var consignment_codes_list = []
    this.state.selected_consignments.map(function(e){
        consignment_codes_list.push(e.value);
    })
    if(consignment_codes_list.length < 4)
    {
        redirectURL.post("/dashboard/getConsignmentRoutes",
                    {"consignment_codes":consignment_codes_list,"screen": this.state.screen}
        ).then((response)=>{
            var coordsdata = response.data;
            var route_coords = [];
            var breaks_list = [];
            coordsdata.map(function(e){
                route_coords.push(eval(e.route_coords));
                breaks_list.push(e.breaks)
            });
            console.log(route_coords)
            console.log(breaks_list)
            this.setState({
                route_coords : route_coords,
                breaks_list : breaks_list,
                loadshow: "show-n",
                 overly: "show-n"
            })
        }).catch((e)=>{
            console.log(e)
        })
    }
    else{
        this.setState({
            show : true,
            loadshow: "show-n",
            overly: "show-n",
            basicTitle : "Consignment codes must not be exceed more than 3.",
            basicType : "warning"
        })
    }
}
onClickHideAll(){
    this.setState({
        loadshow : "show-n",
        overly: "show-n"
    })
}
closeAlert(){
    this.setState({
        show : false
    })
}
render(){
        
        
        return(
            
            <div style={{maxWidth:"100%",height:"100%",}}>
                <SweetAlert
                show={this.state.show}
                type={this.state.basicType}
                title={this.state.basicTitle}
                onConfirm={this.closeAlert.bind(this)}
                >
                </SweetAlert>
                <form method="post" className='row col-xl-12 col-lg-12' onSubmit={this.onClickSubmit.bind(this)}>
                    <div className="col-xl-4 col-lg-4 form-group">
                        <label>Enter consignment codes</label>
                        <CreatableSelect
                            isMulti={true}
                            isClearable
                            onChange={this.setConsignmentValue.bind(this)}
                            // onInputChange={this.setConsignmentValue.bind(this)}
                            // options={this.state.consignments_list}
                        />
                    </div>
                    <div className="form-group col-xl-2 col-lg-2">
                        <label>&nbsp;</label><br />
                        <button type="submit" className="btn btn-success" autoComplete="off">Submit</button>
                    </div>
                </form>
                {((this.state.breaks_list.length > 0) && (this.state.selected_consignments != null))?
                    <div className="card col-xl-12 col-lg-12">
                        <div class="card-body">
                            <DrawMap mapFor={"route_deviation"} coords={this.state.route_coords} consignments_list={this.state.selected_consignments} breaks={this.state.breaks_list} />
                        </div>
                    </div>
                    :""
                }
                
                <div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>  
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
        )
      }
    }

    function loadScriptWeather(url){
        var index  =window.document.getElementsByTagName("script")[1]
          var script = window.document.createElement("script")
          script.src=url
          script.async=true
          script.defer = true
          index.parentNode.insertBefore(script,index)
      }

    function loadDateTimeScript(){
        // alert("timeDate");
        $('.datetimepicker_mask').datetimepicker({
            mask:'39-19-9999 29:59',
            format:'d-m-Y H:i',
        });
        $('.datetimepicker_date').datetimepicker({
            mask:'39-19-9999',
            format:'d-m-Y',
            timepicker:false
        });
        var index  =window.document.getElementsByTagName("script")[1]
        var script = window.document.createElement("script")
        script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
        //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
        script.async=true
        script.defer = true
        index.parentNode.insertBefore(script,index)
}


function ConvertMinutes(num){
    var d = Math.floor(num/1440); // 60*24
    var h = Math.floor((num-(d*1440))/60);
    var m = Math.round(num%60);
    return d+" Day(s) "+h+" Hrs "+m+" mins"
}

